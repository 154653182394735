import {
  GroupMemberStateEnum,
  UserProfileSnsTagPresentation,
} from '@community-group/api/lib/group/models';

import SnsTagMySection from '@/features/GroupProfile/SnsTagSection/SnsTagMySection';
import SnsTagOtherMemberSection from '@/features/GroupProfile/SnsTagSection/SnsTagOtherMemberSection';

type Props = {
  snsTags: UserProfileSnsTagPresentation[];
  isMyProfile?: boolean;
  nickname: string;
  userState: GroupMemberStateEnum;
};

const SnsTagSection = ({ snsTags, isMyProfile, nickname, userState }: Props) => {
  if (isMyProfile) {
    return <SnsTagMySection snsTags={snsTags} nickname={nickname} />;
  }

  return <SnsTagOtherMemberSection snsTags={snsTags} userState={userState} />;
};

export default SnsTagSection;
