import { ChallengeCalendarResponse } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';

import Calendar, { CalendarDateItemDataType } from '@/components/common/Calendar';

import ChallengeCalendarDayItem from './ChallengeCalendarDayItem';
import * as s from './MyChallengeCalendar.css';

type Props = {
  calendarData: ChallengeCalendarResponse;
  groupId: number;
};

const MyChallengeCalendar = ({ calendarData, groupId }: Props) => {
  const { calendarDates, certifyIntervalTargetCount } = calendarData;
  const startDate = useMemo(() => dayjs(calendarDates[0].date).toDate(), [calendarDates]);
  const endDate = useMemo(
    () => dayjs(calendarDates[calendarDates.length - 1].date).toDate(),
    [calendarDates]
  );

  const renderDateItem = useCallback(
    (date: Date, data: CalendarDateItemDataType) => {
      const calendarDate = calendarDates.find(
        (d) => dayjs(d.date).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
      );

      const isBeforeStartDate = data.isBeforeStartDate;
      const isAfterEndDate = data.isAfterEndDate;

      if (isBeforeStartDate || isAfterEndDate)
        return (
          <div className={s.DisabledDate}>
            <Typography typography="subtitle2Regular" color="gray400">
              {date.getDate()}
            </Typography>
          </div>
        );

      if (!calendarDate) return null;

      return (
        <ChallengeCalendarDayItem
          groupId={Number(groupId)}
          date={calendarDate}
          certifyIntervalTargetCount={certifyIntervalTargetCount}
        />
      );
    },
    [calendarDates, certifyIntervalTargetCount, groupId]
  );

  return (
    <Calendar
      startDate={startDate}
      endDate={endDate}
      renderDateItem={renderDateItem}
      initialShowMonth={new Date()}
    />
  );
};

export default MyChallengeCalendar;
