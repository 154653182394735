import { Typography, useEffectOnce } from '@community-group/components';
import { IconCalendarLine, IconChevronDownLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { receive } from '@stackflow/compat-await-push';
import parse from 'date-fns/parse/index';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { useMemo } from 'react';
import { DayModifiers } from 'react-day-picker';
import { Path, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { GroupMeetupFormListItem } from '@/components/group/Meetup/Form/components/ListItem';
import { MeetupDatePicker } from '@/components/group/Meetup/Form/components/MeetupDatePicker';
import { useFlow } from '@/stackflow';
import { formatDate, KARROT_DATE_FORMAT } from '@/utils/date';

import { SEOUL_RUN_PROMOTION_END_DATE, SEOUL_RUN_PROMOTION_START_DATE } from '../MeetupCalendar';
import * as s from './MeetupDateTime.css';

dayjs.locale('ko');
dayjs.extend(weekday);

interface Props<T extends object> extends React.InputHTMLAttributes<HTMLInputElement> {
  dateName: Path<T>;
  onlyDateName: Path<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
}

export const SeoulRunGroupMeetupFormMeetupDateTimeField = <T extends object>({
  dateName,
  onlyDateName,
  watch,
  setValue,
  ...props
}: Props<T>) => {
  const { push } = useFlow();

  const hasMeetupTimeAtOnlyDate = watch(onlyDateName);

  const today = new Date();
  const meetupDateTime = useMemo(() => new Date(watch(dateName)), [watch(dateName)]);
  const currentDateString = useMemo(
    () => formatDate(meetupDateTime, 'yyyy-MM-dd'),
    [meetupDateTime]
  );
  const currentTimeString = useMemo(
    () => formatDate(meetupDateTime, 'HH:mm:ss.SSSXXX'),
    [meetupDateTime]
  );

  const promotionStartDate = useMemo(() => SEOUL_RUN_PROMOTION_START_DATE.toDate(), []);
  const promotionEndDate = useMemo(() => SEOUL_RUN_PROMOTION_END_DATE.toDate(), []);

  useEffectOnce(() => {
    const isBeforePromotionStart = dayjs(today).isBefore(SEOUL_RUN_PROMOTION_START_DATE);

    if (isBeforePromotionStart) {
      const defaultDate = parse(
        `${formatDate(promotionStartDate, 'yyyy-MM-dd')}T${currentTimeString}`,
        KARROT_DATE_FORMAT,
        new Date()
      );

      setValue(onlyDateName, false);
      setValue(dateName, formatDate(defaultDate, KARROT_DATE_FORMAT));
    }
  });

  const handleSelectDate = (day: Date, { disabled }: DayModifiers) => {
    if (disabled) return;

    const changedDate = parse(
      `${formatDate(day, 'yyyy-MM-dd')}T${currentTimeString}`,
      KARROT_DATE_FORMAT,
      new Date()
    );

    setValue(onlyDateName, false);
    setValue(dateName, formatDate(changedDate, KARROT_DATE_FORMAT));
  };

  const handleSelectTime = async () => {
    const data: {
      time: string;
    } = await receive(
      push('BottomSheet/TimePickerBottomSheet', {
        defaultValue: meetupDateTime.toISOString(),
      })
    );

    if (!data?.time) return;

    const time = formatDate(new Date(data.time), 'HH:mm');
    const changedTime = parse(
      `${currentDateString}T${time}:00.000+09:00`,
      KARROT_DATE_FORMAT,
      new Date()
    );

    setValue(onlyDateName, false);
    setValue(dateName, formatDate(changedTime, KARROT_DATE_FORMAT));
  };

  return (
    <GroupMeetupFormListItem
      {...props}
      fieldIcon={<IconCalendarLine size={24} color={vars.$scale.color.gray900} />}
      fieldLabel="날짜 및 시간"
      fieldName={dateName}
      fieldValue={
        <Typography textAlign="right" typography="label2Regular" color="gray900">
          {formatDate(
            meetupDateTime,
            hasMeetupTimeAtOnlyDate ? 'M월 d일 (ccc)' : 'M월 d일 (ccc) aaa h:mm'
          )}
        </Typography>
      }
    >
      <div className={s.Wrapper}>
        <MeetupDatePicker
          disabled={[{ before: promotionStartDate }, { after: promotionEndDate }]}
          fromMonth={promotionStartDate}
          toDate={promotionEndDate}
          selected={meetupDateTime}
          onDayClick={handleSelectDate}
        />
        <GroupMeetupFormListItem
          fieldLabel="시간"
          fieldName="onlyDateName"
          fieldValue={
            <div className={s.TimeBox} onClick={handleSelectTime}>
              <Typography className={s.TimeText} typography="label2Regular" color="gray900">
                {formatDate(meetupDateTime, 'aaa h:mm')}
              </Typography>
              <IconChevronDownLine size={24} color={vars.$scale.color.gray900} />
            </div>
          }
        />
      </div>
    </GroupMeetupFormListItem>
  );
};
