import { activityPages } from './pages';

type Routes = Record<keyof typeof activityPages, string>;

const groupMeetupRoutes = {
  GroupMeetupNewPage: '/group/:groupId/meetups/new',
  GroupMeetupNewOptionsPage: '/group/:groupId/meetups/new/options',
  GroupMeetupEditPage: '/group/:groupId/meetups/:meetupId/edit',
  GroupMeetupEditOptionsPage: '/group/:groupId/meetups/:meetupId/edit/options',
  GroupMeetupDetailPage: '/group/:groupId/meetups/:meetupId',
  GroupMeetupDetailMemberListPage: '/group/:groupId/meetups/:meetupId/member-list',
  GroupMeetupDetailAttendancePage: '/group/:groupId/meetups/:meetupId/attendance',
  GroupMeetupDetailQRCheckPage: '/group/:groupId/meetups/:meetupId/qr-check/:code',
  GroupMeetupDetailLikeListPage: '/group/:groupId/meetups/:meetupId/like-list',
  GroupMeetupCalendarPage: '/group/meetups/calendar',
  GroupMeetupCreateReviewPage: '/group/:groupId/meetups/:meetupId/review/new',
  GroupMeetupCreateDoneReviewPage: '/group/meetups/review/done',

  // 서울 한강런 프로모션 페이지

  SeoulRunMeetupCalendarPage: '/meetups/seoul-run/calendar',
  SeoulRunMeetupNewPage: '/meetups/seoul-run/new',
  SeoulRunMeetupNewOptionsPage: '/meetups/seoul-run/new/options',
  SeoulRunLocationListPage: '/seoul-run/location-list',
  SeoulRunMeetupEditPage: '/group/:groupId/meetups/:meetupId/seoul-run/edit',
  SeoulRunMeetupEditOptionsPage: '/group/:groupId/meetups/:meetupId/seoul-run/edit/options',
  SeoulRunPromotionGuidePage: '/group/:groupId/seoul-run/promotion-guide',
  SeoulRunQRCheckPromotionPage: '/seoul-run/qr-check-promotion/:placeId',
  OpenTreasureBoxPage: '/seoul-run/open-treasure-box/:placeId',
  'BottomSheet/SelectCreateMeetupInMyGroupBottomSheet':
    '/bottom-sheet/select-create-meetup-in-my-group',
  'BottomSheet/CreatedSeoulRunMeetupGroupBottomSheet':
    '/bottom-sheet/created-seoul-run-meetup-group',
  'BottomSheet/GroupMeetupCreateBottomSheet': '/bottom-sheet/group/meetups/create',
  'BottomSheet/CreateSeoulRunGroupMeetupSelectBottomSheet':
    '/bottom-sheet/create-seoul-run-group-meetup-select/:groupId',
};

const groupChallengeRoutes = {
  GroupChallengeNewTemplatePage: '/challenge/new/templates',
  GroupChallengeNewPage: '/challenge/new',
  GroupChallengeDetailPage: '/group/:groupId/challenge/:challengeId',
  GroupChallengeListPage: '/group/:groupId/challenge/list',
  ChallengeListPage: '/challenge/list',
  ChallengeEditPage: '/group/:groupId/challenge/:challengeId/edit',

  ChallengePayRejectPage: '/challenge/pay-reject',
  ChallengeCertifyFeedsPage: '/group/:groupId/challenge/:challengeId/certify-feeds',
  ChallengeMyDailyCertifyResultPage:
    '/group/:groupId/post/:postId/challenge/:challengeId/my-daily-certify-result',
  'BottomSheet/CreatedChallengeGroupBottomSheet': '/bottom-sheet/created-challenge-group',
  'BottomSheet/ChallengePromotionBottomSheet': '/bottom-sheet/challenge/promotion',
  'BottomSheet/ChallengeCertifyPromotionGuideBottomSheet':
    '/bottom-sheet/challenge/certify/promotion-guide',
};

const groupSettingRoutes = {
  GroupSettingPage: '/setting/:groupId',
  GroupNotificationSettingPage: '/setting/:groupId/notification',
  GroupRegularMeetupPlaceSettingPage: '/setting/:groupId/regular-meetup-place',
  GroupJoinSettingPage: '/setting/:groupId/join',
  GroupMaxMemberSettingPage: '/setting/:groupId/max-member',
  GroupAgeRangeSettingPage: '/setting/:groupId/age-range',
  GroupGuideBookSettingPage: '/setting/:groupId/guide-book',
  GroupManagerSettingPage: '/setting/:groupId/manager',
  GroupSettingMemberGradePage: '/setting/member/grade/:groupId',
  GroupSettingMemberDelegateSuperHostPage: '/setting/member/delegate-super-host/:groupId',
  GroupSettingJoinableRegionRangePage: '/setting/joinable-region-range/:groupId',
  GroupSettingPermissionPage: '/setting/permission/:groupId',
  GroupSettingEditSubNicknamePage: '/setting/edit-subnickname/:groupId',
  GroupSettingNotificationPage: '/group/:groupId/setting/notification',
  BoardCategorySetting: '/setting/board-category/:groupId',
  GroupSettingEditGroupDescription: '/setting/:groupId/edit-group-description',
  GroupSettingEditGroupInfoPage: '/setting/:groupId/edit-group-info',
  GroupSettingEditVerification: '/setting/:groupId/edit-verification',
  GroupSettingGroupBotPage: '/setting/:groupId/group-bot',
  GroupSettingEditGroupBotPage: '/setting/:groupId/edit-group-bot',
  'BottomSheet/GroupSettingApplicationApprovalBottomSheet':
    '/bottom-sheet/setting/:groupId/application-approval',
  'BottomSheet/GroupSettingApplicationQuestionBottomSheet':
    '/bottom-sheet/setting/:groupId/application-question',
  'BottomSheet/GroupSettingEditMaximumMemberBottomSheet':
    '/bottom-sheet/setting/:groupId/edit-maximum-member',
  'BottomSheet/GroupSettingEditGroupAgeRangeBottomSheet':
    '/bottom-sheet/setting/:groupId/edit-group-age-range',
  'BottomSheet/GroupSettingCreateBoardCategoryBottomSheet':
    '/bottom-sheet/setting/:groupId/crate-board-category',
  'BottomSheet/GroupSettingEditBoardCategoryNameBottomSheet':
    '/bottom-sheet/setting/:groupId/edit/board-category-name',
  'BottomSheet/GroupSettingEditSubNicknameQuestionBottomSheet':
    '/bottom-sheet/setting/:groupId/edit/subnickname/question',
  'BottomSheet/GroupSettingEditPermissionBottomSheet':
    '/bottom-sheet/setting/:groupId/edit-permission',
  'BottomSheet/NoticePublishGroupBottomSheet': '/bottom-sheet/notification/publish-group/:groupId',
  'BottomSheet/TurnOnNotificationBottomSheet': '/bottom-sheet/notification/:groupId',
};

const groupCreateRoutes = {
  GroupNewSetGroupCategoryPage: '/new/set-category',
  GroupNewSetGroupCategoryRecommendPage: '/new/set-category-v2',
  GroupNewSetGroupNamePage: '/new/set-name',
  GroupNewSetApprovalPage: '/new/set-approval',
  GroupNewSetRecruitmentConditionPage: '/new/set-recruitment-condition',
  GroupNewSetGroupRegionRangePage: '/new/set-group-region-range',
  GroupNewSetSubNicknamePage: '/new/subnickname',
  GroupNewSetVerificationPage: '/new/set-verification',
  GroupNewSetProfileImagePage: '/new/set-profile-image',
};

const growthEventRoutes = {
  // 러닝그로스 완료 바텀시트
  'BottomSheet/RunningGrowthMissionCompleteBottomSheet':
    '/bottom-sheet/group/:groupId/running-growth-mission-complete/:step',
};

const ProfileBottomSheetRoutes = {
  'BottomSheet/SnsTagSetBottomSheet': '/bottom-sheet/sns-set-tag',
};

const NotMemberDetailRoutes = {
  GroupDetailNotMemberPage: '/group/detail/:groupId/not-member', // 디버깅용 Path, 실 배포시 삭제
  GroupDetailNotMemberMeetupReviewListPage: '/group/:groupId/meetup-review-list',
};

export const routes: Routes = {
  ...NotMemberDetailRoutes,
  ...groupMeetupRoutes,
  ...groupSettingRoutes,
  ...groupCreateRoutes,
  ...growthEventRoutes,
  ...ProfileBottomSheetRoutes,
  ...groupChallengeRoutes,
  MaintenancePage: '/maintenance',
  NotFoundPage: '/not-found',
  HomePage: '/home',
  JustStartedGroupMorePage: '/more/just-started-group',
  SearchHomePage: '/search/home',
  SearchResultPage: '/search/result/:query',
  GroupDetailPage: '/group/:groupId',
  // NOTE: GroupDetailPageLegacy
  // 모임 상세 V1에서 사용하던 URL로 알림, 채팅 등에서 정적으로 박혀있는 경로라 삭제하지 않아요.
  // 실제로 랜딩되는 페이지는 GroupDetailPage와 같아요.
  GroupDetailPageLegacy: '/group/detail/:groupId',
  GroupDetailAlbumPage: '/group/:groupId/album',
  GroupDetailSummaryPage: '/group/:groupId/summary',
  GroupDetailChatRoomPage: '/group/:groupId/chat-room',
  GroupDetailPreviewPage: '/group/:groupId/preview',
  GroupDetailPreviewPageLegacy: '/group/:groupId/preview-legacy',
  GroupProfileApprovalPage: '/profile/approval/:groupId',
  GroupProfileCreatePage: '/profile/create',
  GroupProfileDetailPage: '/group/profile/detail',
  GroupDetailMeetupPage: '/group/:groupId/meetups',
  GroupDetailMeetupListPage: '/group/:groupId/meetup-list',
  GroupDetailFeedListPage: '/group/:groupId/feed-list',

  MainProfileMyInfoRequestDirectChatsPage: '/main-profile/my-info/request-direct-chats',
  GroupProfileRequestGroupDirectChatNewPage:
    '/group-profile/:groupId/:userId/request-group-direct-chat/new',
  GroupPayTransferDirectChatPage: '/group-pay/transfer/direct-chat/:chatChannelId',

  ProfileMeetupMemberReviewMessagesPage: '/profile/:userId/meetup-member-review-messages',

  GroupOnboardProfilePage: '/new/onboard/set-profile/:groupId',
  GroupOnBoardDescriptionPage: '/new/set-publish/:groupId',
  GroupMemberListPage: '/member-list/:groupId',
  GroupMemberListForHostPage: '/member-list/host/:groupId',
  GroupMemberRejectMessagePage: '/member-list/host/:groupId/reject',
  GroupMemberGradeDetailPage: '/group/:groupId/member/:userId/grade',
  GroupMemberGradeApplicationRejectMessagePage: '/group/:groupId/member/grade/reject-message',
  GroupPostDetailPage: '/post/detail/:groupId/posts/:postId',
  GroupPostNewPage: '/group/:groupId/posts/new',
  GroupPostWritePage: '/group/:groupId/posts/write',
  GroupPostEditPage: '/group/:groupId/posts/:postId/edit',
  GroupUserProfileDetailPage: '/profile/detail/:groupId/:userId', // 이후 통합 프로필로 넘어가면 삭제
  GroupProfileDetailV2Page: '/group/:groupId/profile/:userId/detail',
  GroupProfileMemberManagePage: '/group/:groupId/profile/:userId/manage',
  GroupProfileMemberJoinedMeetupPage: '/group/:groupId/profile/:userId/joined-meetups',
  GroupProfileEditPage: '/profile/edit',
  GroupEditMemberSubNicknamePage: '/group/:groupId/member/edit-subnickname',
  // GroupCreateProfileAndJoinGroupPage: '/profile/create-profile-join-group/:groupId',
  PostDetailLikeListPage: '/like-list/:groupId/:postId',

  CommentDetailPage: '/group/:groupId/:relatedContentType/:relatedId/comment/:commentId',
  CommentEditPage: '/group/:groupId/:relatedContentType/:relatedId/comment/:commentId/edit',
  CommentListPage: '/group/:groupId/:relatedContentType/:relatedId/comment-list',
  CommentEmotionListPage:
    '/group/:groupId/:relatedContentType/:relatedId/comment/:commentId/emotion-list',

  TalkRoomInviteMemberPage: '/talk/:groupId/room/:roomId/invite-member',
  TalkRoomInviteQRPage: '/talk/:groupId/room/:roomId/invite-qr',
  TalkRoomNewInformationPage: '/talk/:groupId/room/new/information',
  TalkRoomNewApprovalPage: '/talk/:groupId/room/new/approval',

  MyInfoLikedGroupMeetupListPage: '/my-info/group/meetups/liked-list',
  GroupDetailNoticeMorePage: '/group/:groupId/notice',
  GroupPollFormPage: '/group/:groupId/poll/form',
  GroupPollVotedMembersPage: '/group/:groupId/posts/:postId/poll/voted-members',

  GroupCurationWithKeywordPage: '/group/curation/with-keyword',

  GroupNotificationSelectPage: '/group/notification/select',
  TokenSettingPage: '/token-setting',
  GroupSettingFAQVerificationPage: '/group/setting/faq-verification',
  GroupSettingHeroImageSelectorPage: '/group/:groupId/setting/hero-image-selector',

  AccessStatusServiceHandlingPage: '/access-status-service-handling',
  ImageViewerPage: '/image-viewer',

  SimpleAlertDialog: '/alert',

  InternalToolPage: '/internal-tool',

  GroupLevelDetailPage: '/group/:groupId/level/detail',
  GroupLeaderBoard: '/leader-board',

  ParentingGroupKidProfileCreatePage: '/parenting-group/:groupId/kid/profile/create',
  ParentingGroupKidProfileEditPage: '/parenting-group/:groupId/kid/:kidId/profile/edit',
  ParentingGroupKidProfileListPage: '/parenting-group/:groupId/kid/profile/list',

  ParentingGroupFreshMemberKidProfileCreatePage:
    '/parenting-group/:groupId/fresh-member/kid/profile/create',
  ParentingGroupMemberKidProfileCreatePage: '/parenting-group/:groupId/member/kid/profile/create',

  KarrotPaymentsCreateAccountPage: '/karrot-payments/create-account',
  KarrotPaymentsCreateAccountSuccessPage: '/karrot-payments/create-account/success',

  'BottomSheet/GroupLeaderBoardGuideBottomSheet': '/bottom-sheet/leader-board/guide',

  'BottomSheet/GroupEntryPointGuideBottomSheet': '/bottom-sheet/group/entry-point-guide',
  'BottomSheet/RestrictGroupBottomSheet': '/bottom-sheet/admin/:groupId/restrict-group',

  'BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet':
    '/bottom-sheet/:groupId/set-member-profile-subnickname',
  'BottomSheet/GroupMoveBoardCategoryBottomSheet':
    '/group/:groupId/posts/:postId/move/board-category',
  'BottomSheet/GroupChatRoomPrivateBottomSheet':
    '/bottom-sheet/group/:groupId/chat-room/private/:roomId',
  'BottomSheet/CongratsManagerRoleBottomSheet': '/bottom-sheet/congrats-manager-role',
  'BottomSheet/VerificationInduceBottomSheet': '/bottom-sheet/induce-verification',
  'BottomSheet/TimePickerBottomSheet': '/bottom-sheet/time-picker',
  'BottomSheet/GroupSetNewSubnicknameQuestion': '/bottom-sheet/new/subnickname/question',
  'BottomSheet/CheckCanViewVerifiedUserInfoBottomSheet':
    '/bottom-sheet/check-can-view-verified-user-info',
  'BottomSheet/MemberRejectMenuBottomSheet': '/bottom-sheet/member/reject-menu/:groupId',
  'BottomSheet/ApplicationRejectedCommentBottomSheet':
    '/bottom-sheet/application/rejected/:groupId/:userId',
  'BottomSheet/MemberGradeApplicationRejectedCommentBottomSheet':
    '/bottom-sheet/member/grade/application/rejected/:groupId/:memberGradeApplicationId',

  'BottomSheet/SelectGroupBeforeVerificationChange': '/bottom-sheet/select-group-verification',
  // 러닝그로스 완료 바텀시트
  'BottomSheet/RunningGrowthMissionCompleteBottomSheet':
    '/bottom-sheet/group/:groupId/running-growth-mission-complete/:step',
  'BottomSheet/GuardForbiddenWordGroupBottomSheet': '/bottom-sheet/guard-forbidden-word-group',

  'BottomSheet/SharePostBottomSheet': '/bottom-sheet/share/content',
  'BottomSheet/CommentMoreMenuBottomSheet': '/bottom-sheet/comment/more-menu',
  'Modal/CommentDeleteModal': '/modal/comment/delete',

  // 모임장 - 모임 레벨 기능 안내 바텀시트
  'BottomSheet/NoticeGroupLevelBottomSheet': '/bottom-sheet/:groupId/notice/group-level',

  // 레벨업 알림 바텀시트
  'BottomSheet/LevelUpAlertBottomSheet': '/bottom-sheet/:groupId/level-up-alert',
  'BottomSheet/InviteMemberShareBottomSheet': '/bottom-sheet/:groupId/invite-member/share',

  // 모임 나가기 확인 바텀시트
  'BottomSheet/LeaveGroupBottomSheet': '/bottom-sheet/:groupId/leave-group',

  // 모임 등업 조건 설정 바텀시트
  'BottomSheet/RequirementCountSelectorBottomSheet': '/bottom-sheet/requirement-count-selector',
};
