import { GroupCurrentUser } from '@community-group/api/lib/group/models';
import { isMember } from '@community-group/components';

import { useReadGroupChallengeCertifyFeeds } from '@/domain/Challenge/hooks/useReadGroupChallengeCertifyFeeds';
import ChallengeCertifyList from '@/features/ChallengeDetail/ParticipantsStatusSection/ChallengeCertifyList';

type Props = {
  groupId: number;
  challengeId: number;
  groupMe: GroupCurrentUser;
};

const CertifyList = ({ groupMe, groupId, challengeId }: Props) => {
  const { data: challengeCertifyFeeds } = useReadGroupChallengeCertifyFeeds({
    groupId: Number(groupId),
    challengeId: Number(challengeId),
  });

  // 이미지 있는 아이템만 노출 + 멤버 또는 공개 인증만 노출
  const filteredCertifyFeeds = challengeCertifyFeeds
    .filter((el) => el.medias.length !== 0 && el.medias.some((media) => media.type === 'image'))
    .filter((el) => isMember(groupMe.role) || el.publishType === 'public');

  return (
    <ChallengeCertifyList
      challengeCertifyFeeds={filteredCertifyFeeds ?? []}
      groupId={Number(groupId)}
      challengeId={Number(challengeId)}
    />
  );
};

export default CertifyList;
