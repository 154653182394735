import {
  AsyncBoundary,
  Divider,
  Render,
  Spacing,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';

import { useQueryGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupLevel } from '@/domain/GroupLevel/hooks/useReadGroupLevel';
import { useQueryIsSeoulRunMeetupPromotion } from '@/domain/SeoulRunPromotion/hook/useReadIsSeoulRunMeetupPromotion';
import { useEnterLocationEventLog } from '@/hooks/useEnterLocationEventLog';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithThrowAccessStatusPage } from '@/hooks/useHandleErrorWithThrowAccessStatusPage';
import { useHandleRestrictedUser } from '@/hooks/useHandleRestrictedUser';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useReferQueryParams } from '@/stackflow/hooks/useReferQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { extendAppsflyerLoggerType } from '@/utils/analytics';
import { isNotMember } from '@/utils/role';

import { GroupMeetupDetailAppScreen } from '../components/GroupMeetupDetailAppScreen';
import GroupMeetupDetailCommentList from '../components/GroupMeetupDetailCommentList';
import { GroupMeetupInfoSection } from '../components/GroupMeetupInfoSection';
import { GroupMeetupMemberListSection } from '../components/GroupMeetupMemberListSection';
import { GroupSummarySection } from '../components/GroupSummarySection';
import RecommendGroupMeetupSection from '../components/RecommendGroupMeetupSection';
import { isCanceledGroupMeetup, isHostDeletedGroupMeetup } from '../utils/progressStatus';
import * as s from './index.css';

type GroupMeetupDetailPageParams = Pick<PageParams, 'groupId' | 'meetupId' | 'from'>;

const GroupMeetupDetailPage: ActivityComponentType<GroupMeetupDetailPageParams> = () => {
  const { groupId, meetupId } = usePathParams();
  const handleErrorWithThrowErrorStatusPage = useHandleErrorWithThrowAccessStatusPage();

  return (
    <GroupMeetupDetailAppScreen>
      <AsyncBoundary
        pendingFallback={<ViewLoader />}
        rejectedFallback={<ViewError />}
        onError={(error) =>
          handleErrorWithThrowErrorStatusPage({ error, serviceType: 'meetup', groupId, meetupId })
        }
      >
        <GroupMeetupDetailView />
      </AsyncBoundary>
    </GroupMeetupDetailAppScreen>
  );
};

const GroupMeetupDetailView = () => {
  const { groupId, meetupId } = usePathParams();
  const { from } = useQueryParams();

  const [
    { data: meetup, isLoading, error },
    { data: group },
    { data: level },
    { data: isSeoulRunMeetupPromotion },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupMeetupDetail({ groupId, meetupId }),
      useQueryGroupDetail(groupId),
      useQueryGroupLevel(groupId),
      useQueryIsSeoulRunMeetupPromotion({ groupId: Number(groupId), meetupId: Number(meetupId) }),
    ],
  });

  const handleErrorWithThrowErrorStatusPage = useHandleErrorWithThrowAccessStatusPage();
  if (error && !isLoading) {
    handleErrorWithThrowErrorStatusPage({
      error,
      serviceType: 'meetup',
      groupId,
      meetupId,
    });
  }

  useHandleRestrictedUser({
    serviceType: 'meetup',
    groupId,
    meetupId,
  });

  const isNotGroupMember = isNotMember(meetup.currentUserInfo.groupRole);

  // TODO: groupCategoryName groupInfo에 category 추가 요청
  const referParams = useReferQueryParams();

  useEnterTrackEvent({
    event: 'enter_group_meetup_detail',
    params: {
      ...referParams,
      groupId,
      meetupId,
      from,
      progressStatus: meetup.progressStatus.status,
      meetupType: meetup.meetupType,
      meetupHostId: meetup.host?.userId,
      meetupTimeAt: meetup.meetupTimeAt ?? '',
      meetupLocation: meetup.poi?.name ?? meetup.location ?? '',
      meetupHostRegionId: meetup.host?.region.id,
      hasMeetupPoi: !!meetup.poi?.name,
      currentParticipantsNumber: meetup.currentParticipantsNumber,
      maximumParticipantsNumber: meetup.maximumParticipantsNumber,
      isLikedGroupMeetup: meetup.currentUserInfo.like, // 관심 등록 여부
      userId: meetup.currentUserInfo.userId.toString(),
      userMeetupRole: meetup.currentUserInfo.role,
      role: meetup.currentUserInfo.groupRole,
      currentLevel: level.currentLevel,
      currentProgressPercentage: level.forLevelUp.percentage,
      categoryId: group.category.id,
      categoryName: group.category.name,
    },
    sample: true,
    loggerType: extendAppsflyerLoggerType,
  });

  useEnterLocationEventLog({
    eventName: 'enter_group_meetup_detail',
    groupId: parseInt(groupId),
  });

  if (!meetup) return <ViewLoader />;

  if (isCanceledGroupMeetup(meetup.progressStatus.status) && meetup.currentUserInfo.role !== 'HOST')
    return <ViewError description="앗! 죄송해요.\n게시글이 삭제되어 볼 수 없어요." />;
  if (isHostDeletedGroupMeetup(meetup.progressStatus.status))
    return <ViewError description="앗! 죄송해요.\n게시글이 삭제되어 볼 수 없어요." />;

  return (
    <div className={s.Container}>
      <GroupMeetupInfoSection groupId={groupId} meetupId={meetupId} />
      <Divider />
      <GroupMeetupMemberListSection groupId={groupId} meetupId={meetupId} />
      <GroupMeetupDetailCommentList
        groupId={groupId}
        meetupId={meetupId}
        isNotMember={isNotGroupMember}
        totalCommentCount={meetup.commentCount}
      />
      <Render condition={isNotGroupMember}>
        <Divider />
        <GroupSummarySection groupId={groupId} meetupId={meetupId} />
        <RecommendGroupMeetupSection />
      </Render>
      <Spacing size={80} />
    </div>
  );
};

export default GroupMeetupDetailPage;
