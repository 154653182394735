import { GroupChallengeCreateForm } from '@community-group/api/lib/group/models';
import { ChipRadio, ChipRadioGroup, Typography } from '@community-group/components';
import { IconCheckmarkChatbubbleLeftLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import React, { useMemo } from 'react';

import { getCertificationIntervalText } from '@/features/Challenge/utils/certification';

import { GroupChallengeFormProps } from '../../types';
import { GroupChallengeFormFieldWrapper } from '../FieldWrapper';
import * as s from './style.css';

type Props = GroupChallengeFormProps & {
  dateFieldName: keyof GroupChallengeCreateForm;
  onClick?: () => void;
};

const GroupChallengeFormFrequencyField = ({ formHandler, dateFieldName, onClick }: Props) => {
  const frequencyDisplayText = useMemo(() => {
    const frequency = Number(formHandler.watch(dateFieldName) ?? 7);
    return getCertificationIntervalText(frequency, '일');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHandler.watch(dateFieldName)]);

  return (
    <GroupChallengeFormFieldWrapper
      onClick={onClick}
      fieldIcon={<IconCheckmarkChatbubbleLeftLine size={24} color={vars.$scale.color.gray900} />}
      fieldLabel="인증 빈도"
      fieldName={dateFieldName}
      fieldValue={
        <Typography textAlign="right" typography="label2Regular" color="gray900">
          {frequencyDisplayText}
        </Typography>
      }
    >
      <div className={s.Wrapper}>
        <ChipRadioGroup
          value={formHandler.watch(dateFieldName)?.toString()}
          onChange={(value) => {
            formHandler.setValue(dateFieldName, Number(value));
          }}
        >
          <ChipRadio value="7">매일</ChipRadio>
          <ChipRadio value="6">주 6일</ChipRadio>
          <ChipRadio value="5">주 5일</ChipRadio>
          <ChipRadio value="4">주 4일</ChipRadio>
          <ChipRadio value="3">주 3일</ChipRadio>
          <ChipRadio value="2">주 2일</ChipRadio>
          <ChipRadio value="1">주 1일</ChipRadio>
        </ChipRadioGroup>
      </div>
    </GroupChallengeFormFieldWrapper>
  );
};

export default GroupChallengeFormFrequencyField;
