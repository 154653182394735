import { Spacing } from '@community-group/components';
import { FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import Chip from '@/components/common/base/Chip';
import FormBadgeRadio from '@/components/common/base/Input/Radio/Badge';
import { BadgeRadioWrapper } from '@/components/common/base/Input/Radio/FilterBadge';
import { FormContainer } from '@/components/common/Form';

import MaximumMemberInput from './components/maximum-member-input';
import { MAXIMUM_MEMBER_OPTIONS } from './constants/maximum-member-constants';
import * as s from './maximumMemberChips.css';

export type MaximumMemberHandlerProps = {
  id: number;
  value: number | undefined;
};

type Props = {
  alwaysShowInput?: boolean;
  selectedChip: number;
  setSelectedChip: (value: number) => void;
  watch: UseFormWatch<FieldValues>;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
};

export const MaximumMemberChips = ({ selectedChip, setSelectedChip, watch, setValue }: Props) => {
  const maximumMemberChipHandler = ({ id, value }: MaximumMemberHandlerProps) => {
    setSelectedChip(id);
    setValue('maximumMember', value, { shouldDirty: true });
  };

  const isRenderInput = () => {
    return MAXIMUM_MEMBER_OPTIONS[selectedChip].showCustomInput;
  };

  return (
    <FormContainer>
      <div className={s.formWrapper}>
        <BadgeRadioWrapper>
          {MAXIMUM_MEMBER_OPTIONS.map((item) => (
            <FormBadgeRadio
              key={item.id}
              value={item.id}
              name="maximumMember"
              onClick={() => maximumMemberChipHandler({ id: item.id, value: item.value })}
              checked={item.id === selectedChip}
            >
              <Chip text={item.name} />
            </FormBadgeRadio>
          ))}
        </BadgeRadioWrapper>
      </div>

      {isRenderInput() ? (
        <>
          <Spacing size={8} />
          <MaximumMemberInput
            watch={watch}
            setValue={setValue}
            disable={!MAXIMUM_MEMBER_OPTIONS[selectedChip].showCustomInput}
          />
        </>
      ) : (
        <Spacing size={56} />
      )}
    </FormContainer>
  );
};

export default MaximumMemberChips;
