import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { useEffect, useRef, useState } from 'react';

import GroupDetailBulletinBanner from './GroupDetailBulletinBanner';
import { useGroupDetailContext } from './GroupDetailContext';
import GroupDetailFeed from './GroupDetailFeed';
import GroupDetailFloatingButton from './GroupDetailFloatingButton';
import GroupDetailMainBanner from './GroupDetailMainBanner';
import { useScroll } from './GroupDetailScrollContext';
import GroupDetailShortcutMenu from './GroupDetailShortcutMenu';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

const GroupDetailForMember = ({ group, currentUser }: Props) => {
  const { selectedBoardKey } = useGroupDetailContext();
  const { isHeaderVisible } = useScroll();
  const [isShowFloatingButton, setIsShowFloatingButton] = useState(true);
  useEffect(() => {
    // 1번은 '모임일정' 게시판으로 '일정 만들기' 버튼과 중복되어 FAB 버튼을 숨김
    const isHideFloatingButton = selectedBoardKey === 1;
    setIsShowFloatingButton(!isHideFloatingButton);
  }, [selectedBoardKey]);

  return (
    <>
      <GroupDetailShortcutMenu group={group} currentUserRole={currentUser.role} />
      <GroupDetailMainBanner group={group} currentUser={currentUser} />
      <GroupDetailBulletinBanner group={group} currentUserRole={currentUser.role} />
      <GroupDetailFeed group={group} currentUser={currentUser} />
      {/* 개선된 UI에선 탭이 없기 때문에 home을 전달 */}
      {isShowFloatingButton && (
        <GroupDetailFloatingButton activeTabKey="home" hideText={!isHeaderVisible} />
      )}
    </>
  );
};

export default GroupDetailForMember;
