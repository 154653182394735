import { Divider, Render, TextButton, useBottomSheet } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useBridge } from '@/contexts/Bridge';
import { usePutGroup } from '@/domain/Group/hooks/usePutGroup';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import * as stackflow from '@/stackflow';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';

import * as s from './style.css';

type Props = {
  groupId: string;
};

const GroupJoinManageSettingSection = ({ groupId }: Props) => {
  const stack = stackflow?.useFlow();
  const push = stack?.push;
  const { bridge } = useBridge();
  const { data: groupDetail } = useReadGroupDetail(groupId);
  const { mutate: putGroup, isPending } = usePutGroup({
    onSuccess: () => refetchGroupSetting({ groupId }),
  });

  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();
  const shownApproval = groupDetail.joinType === 'approval';
  const question = groupDetail.applicationConfig?.questions[0]?.question;
  const initialQuestionRequired = groupDetail.applicationConfig?.questions[0]?.required;

  const handleChangeJoinQuestion = async () => {
    await closeBottomSheet();
    push('BottomSheet/GroupSettingApplicationQuestionBottomSheet', {
      groupId,
      initialQuestionRequired: (initialQuestionRequired?.toString() ?? 'false') as 'true' | 'false',
      initialQuestionValue: question ?? '',
    });
  };

  const handleDeleteJoinQuestion = async () => {
    if (isPending) return;
    await closeBottomSheet();

    putGroup({
      id: Number(groupId),
      groupModifyForm: { applicationConfig: { haveQuestion: false, questions: [] } },
    });
    bridge.openToast({ toast: { body: '가입 질문을 삭제했어요.' } });
  };

  const handleOpenBottomSheet = () => {
    if (!question) {
      push('BottomSheet/GroupSettingApplicationQuestionBottomSheet', {
        groupId,
        initialQuestionRequired: 'false',
        initialQuestionValue: '',
      });
      return;
    }
    openBottomSheet({
      element: (
        <div className={s.Wrapper}>
          <div className={s.Item} onClick={handleChangeJoinQuestion}>
            <TextButton size="medium" variant="secondary">
              수정
            </TextButton>
          </div>
          <Divider padding={16} />
          <div className={s.Item} onClick={handleDeleteJoinQuestion}>
            <TextButton size="medium" variant="danger">
              삭제
            </TextButton>
          </div>
        </div>
      ),
    });
  };

  return (
    <SettingList.Wrapper>
      <SettingList.Item
        title="가입 방식"
        renderRight={groupDetail.joinType === 'free' ? <p> 바로 가입</p> : <p>승인 후 가입</p>}
        onClick={() => {
          push('BottomSheet/GroupSettingApplicationApprovalBottomSheet', {
            groupId,
            initialJoinType: groupDetail.joinType,
          });
        }}
      />
      <Render condition={shownApproval}>
        <SettingList.Item
          title="가입 질문"
          description={question}
          onClick={handleOpenBottomSheet}
          renderRight={
            question ? (
              <IconChevronRightLine size={20} color={vars.$scale.color.gray600} />
            ) : (
              <p>입력하기</p>
            )
          }
        />
      </Render>
    </SettingList.Wrapper>
  );
};

export default GroupJoinManageSettingSection;
