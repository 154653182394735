import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { Divider } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconChevronRightLine, IconHeadsetFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { XpropertyData } from '@/api/base/xproperty';
import { useCustomerServiceSettingHandler } from '@/features/GroupSetting/hooks/useCustomerServiceSettingHandler';
import SettingItemTitle from '@/features/GroupSetting/SettingItemTitle';
import { groupCustomerServiceSettingConfig } from '@/features/GroupSetting/util/groupSettingConfig';

type Props = {
  role: GroupMemberRoleEnum;
  xProperty: XpropertyData;
};

const GroupCustomerServiceSettingSection = ({ role, xProperty }: Props) => {
  const handleItemClick = useCustomerServiceSettingHandler({ role, xProperty });

  return (
    <>
      <SettingList.Wrapper
        renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
      >
        <SettingItemTitle icon={<IconHeadsetFill size={16} />} title="고객센터" />
        {groupCustomerServiceSettingConfig.map((item) => (
          <SettingList.Item
            key={item.displayText}
            title={item.displayText}
            onClick={() => handleItemClick(item)}
          />
        ))}
      </SettingList.Wrapper>
      <Divider size={8} />
    </>
  );
};

export default GroupCustomerServiceSettingSection;
