import { Typography, withAsyncBoundary } from '@community-group/components';
import { useSuspenseQueries } from '@tanstack/react-query';

import { useQueryChallengeDetail } from '@/domain/Challenge/hooks/useReadChallengeDetail';

type Props = {
  groupId: string;
  challengeId: string;
};

const ChallengeCertifyFeedsTitle = ({ groupId, challengeId }: Props) => {
  const [{ data: challengeDetail }] = useSuspenseQueries({
    queries: [useQueryChallengeDetail({ groupId, challengeId })],
  });

  return (
    <Typography typography="title3Bold" ellipsisAfterLines={1}>
      {challengeDetail?.name}
    </Typography>
  );
};

export default withAsyncBoundary(ChallengeCertifyFeedsTitle, {
  rejectedFallback: <></>,
  pendingFallback: <></>,
});
