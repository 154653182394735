import { ImpressionArea } from '@community-group/components';
import { QueryFromType } from '@daangn/search-log-group';

import { ORDER_HOME } from '@/api/base/group';
import { useGetHomeList } from '@/api/hooks/useGetHomeList';
import useKarrotSearchLogger, { mappedSearchItemParams } from '@/hooks/useKarrotSearchLogger';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { useJuniorGroupExperiment } from '@/utils/analytics/experiment/experiments/useJuniorGroupExperiment';

import { SearchTabType } from '../Page/Result';
import * as s from './PopularityGroupListSection.css';
import { SearchResultGroupFilterProps } from './SearchResultGroupFilter';
import { SearchResultItem } from './SearchResultList/SearchResultItem';

type Props = Partial<
  Pick<
    SearchResultGroupFilterProps,
    'selectedCategoryList' | 'selectedRegionRange' | 'selectedAgeRange' | 'selectedSorting'
  >
> & {
  currentTab: SearchTabType;
  startIndex?: number;
};

const PopularityGroupListSection = ({
  selectedSorting,
  selectedRegionRange,
  selectedAgeRange,
  selectedCategoryList = [],
  currentTab,
  startIndex = 0,
}: Props) => {
  const { push } = useFlow();
  const { query = '' } = usePathParams();
  const { queryFrom = 'unknown', queryId } = useQueryParams();

  const searchLogger = useKarrotSearchLogger();

  const selectedCategoryIdList = (selectedCategoryList ?? []).map(({ id }) => id);
  const currentCategoryIdList =
    selectedCategoryIdList.length > 0 ? selectedCategoryIdList : undefined;

  const { data } = useGetHomeList({
    order: ORDER_HOME.RECOMMEND_SCORE_DESC,
    category: currentCategoryIdList,
  });
  const { isTreatment: enabledJuniorGroupExperiment } = useJuniorGroupExperiment();

  const groupList = data?.pages[0].data.groups;
  const hidePopularityGroupList = !groupList;

  if (hidePopularityGroupList) return <></>;

  return (
    <>
      <div className={s.Spacing} />
      <div className={s.Wrapper}>
        <div className={s.TitleWrapper}>
          <h3 className={s.Title}>추천 모임</h3>
        </div>
        <div className={s.GroupListWrapper}>
          {groupList.map((group, index) => {
            const searchParams = mappedSearchItemParams({
              collection: 'group',
              documentId: group.id.toString(),
              queryId: queryId,
              index: startIndex + index,
              query: query,
              queryFrom: queryFrom as QueryFromType,
              filterParams: {
                filterSort: selectedSorting,
                filterCategoryIds: selectedCategoryList?.map(({ id }) => id),
                filterRegionRange: selectedRegionRange,
                filterMinAge: selectedAgeRange ? Number(selectedAgeRange) : undefined,
                filterMaxAge: selectedAgeRange ? Number(selectedAgeRange) + 9 : undefined,
              },
              tab: currentTab,
            });

            return (
              <ImpressionArea
                key={JSON.stringify(searchParams)}
                onLogging={() => {
                  searchLogger.impressionGroupSearchItemV1(searchParams);
                }}
                exposureTime={300}
                threshold={0.99}
              >
                <SearchResultItem
                  key={group?.id}
                  result={{
                    ...group,
                    region: group?.region ?? {
                      id: 0,
                      name: '',
                      province: '',
                      city: '',
                      town: '',
                    },
                    displayRegionName: group?.region?.town ?? '',
                    someOfMemberProfileImages: group?.memberProfileImages,
                  }}
                  onClick={() => {
                    searchLogger.clickedGroupSearchItemV1(searchParams);

                    trackEvent({
                      event: 'click_popularity_group',
                      params: {
                        groupId: group?.id,
                        groupName: group?.name,
                        groupCategoryName: group?.category?.name,
                        listItemIndex: index,
                      },
                    });

                    push('GroupDetailPage', {
                      groupId: group?.id.toString(),
                    });
                  }}
                  enabledJuniorGroupExperiment={enabledJuniorGroupExperiment}
                />
              </ImpressionArea>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PopularityGroupListSection;
