import { lazy } from 'react';

import CommentMoreMenuBottomSheet from '@/activities/BottomSheet/CommentMoreMenu';
import GroupSetMemberProfileSubNicknameBottomSheet from '@/activities/BottomSheet/GroupSetMemberProfileSubNickname';
import ChallengePayRejectPage from '@/activities/Challenge/ChallengePayReject';
import CreatedChallengeGroupBottomSheet from '@/activities/Challenge/CreatedChallengeGroupBottomSheet';
import ChallengeListPage from '@/activities/Challenge/List/ChallengeListPage';
import ChallengeMyDailyCertifyResultPage from '@/activities/Challenge/MyDailyCertifyResult';
import GroupChallengeNewPage from '@/activities/Challenge/New';
import GroupChallengeNewTemplatePage from '@/activities/Challenge/New/Templates';
import ChallengeCertifyFeedsPage from '@/activities/Group/[groupId]/Challenge/[challengeId]/certify-feeds';
import GroupChallengeDetailPage from '@/activities/Group/[groupId]/Challenge/[challengeId]/Detail/index';
import ChallengeEditPage from '@/activities/Group/[groupId]/Challenge/Edit';
import GroupChallengeListPage from '@/activities/Group/[groupId]/Challenge/List';
import GroupMeetupCalendarPage from '@/activities/Group/[groupId]/Meetups/Calendar';
import GroupMemberGradeDetailPage from '@/activities/Group/[groupId]/MemberGrade/[userId]/Detail';
import GroupMemberGradeApplicationRejectMessagePage from '@/activities/Group/[groupId]/MemberGrade/GroupMemberGradeApplicationRejectMessage';
import GroupPostWritePage from '@/activities/Group/[groupId]/Post/Write';
import GroupProfileDetailV2Page from '@/activities/Group/[groupId]/Profile/[userId]/Detail';
import GroupProfileMemberJoinedMeetupPage from '@/activities/Group/[groupId]/Profile/[userId]/JoinedMeetups';
import GroupProfileMemberManagePage from '@/activities/Group/[groupId]/Profile/[userId]/Manage';
import GroupSettingPage from '@/activities/Group/[groupId]/Setting';
import GroupAgeRangeSettingPage from '@/activities/Group/[groupId]/Setting/AgeRange';
import GroupGuideBookSettingPage from '@/activities/Group/[groupId]/Setting/GuideBook';
import GroupJoinSettingPage from '@/activities/Group/[groupId]/Setting/Join';
import GroupManagerSettingPage from '@/activities/Group/[groupId]/Setting/Manager';
import GroupMaxMemberSettingPage from '@/activities/Group/[groupId]/Setting/MaxMember';
import GroupNotificationSettingPage from '@/activities/Group/[groupId]/Setting/Notification';
import GroupRegularMeetupPlaceSettingPage from '@/activities/Group/[groupId]/Setting/RegularMeetupPlace';
import KarrotPaymentsCreateAccountPage from '@/activities/KarrotPayments/CreateAccount';
import KarrotPaymentsCreateAccountSuccessPage from '@/activities/KarrotPayments/CreateAccountSuccess';
import SeoulRunMeetupCalendarPage from '@/activities/Meetup/SeoulRun/Calendar';
import CreatedSeoulRunMeetupGroupBottomSheet from '@/activities/Meetup/SeoulRun/CreatedSeoulRunMeetupGroupBottomSheet';
import CreateSeoulRunGroupMeetupSelectBottomSheet from '@/activities/Meetup/SeoulRun/CreateSeoulRunGroupMeetupSelectBottomSheet';
import SeoulRunMeetupEditPage from '@/activities/Meetup/SeoulRun/Edit';
import SeoulRunMeetupEditOptionsPage from '@/activities/Meetup/SeoulRun/Edit/Options';
import SeoulRunMeetupNewPage from '@/activities/Meetup/SeoulRun/New';
import SeoulRunMeetupNewOptionsPage from '@/activities/Meetup/SeoulRun/New/Options';
import SeoulRunLocationListPage from '@/activities/Meetup/SeoulRun/SeoulRunLocationList';
import SeoulRunPromotionGuidePage from '@/activities/Meetup/SeoulRun/SeoulRunPromotionGuidePage';
import CommentDeleteModal from '@/activities/Modal/CommentDelete';
import OpenTreasureBoxPage from '@/activities/Promotion/SeoulRun/OpenTreasureBoxPage';
import SeoulRunQRCheckPromotionPage from '@/activities/Promotion/SeoulRun/QRCheckPromotion';
import SimpleAlertDialog from '@/components/common/SimpleAlertDialog';
import AccessStatusServiceHandlingPage from '@/components/group/AccessStatus/pages/AccessStatusServiceHandling';
import CommentDetailPage from '@/components/group/CommentDetail/pages';
import CommentEditPage from '@/components/group/CommentEdit/pages';
import CommentEmotionListPage from '@/components/group/CommentEmotionList/pages';
import CommentListPage from '@/components/group/CommentList/pages';
import GroupCurationWithKeywordPage from '@/components/group/Curation/Page/GroupCurationWithKeyword';
import JustStartedGroupMorePage from '@/components/group/Curation/Page/JustStartedGroup';
import ApplicationRejectedCommentBottomSheet from '@/components/group/Detail/components/ApplicationRejectedCommentBottomSheet';
import CongratsManagerRoleBottomSheet from '@/components/group/Detail/components/CongratsManagerRoleBottomSheet';
import LevelUpAlertBottomSheet from '@/components/group/Detail/components/LevelUpAlertBottomSheet';
import MemberGradeApplicationRejectedCommentBottomSheet from '@/components/group/Detail/components/MemberGradeApplicationRejectedCommentBottomSheet';
import NoticeGroupLevelBottomSheet from '@/components/group/Detail/components/NoticeGroupLevelBottomSheet';
import NoticePublishGroupBottomSheet from '@/components/group/Detail/components/NoticePublishGroupBottomSheet';
import { RestrictGroupBottomSheet } from '@/components/group/Detail/components/RestrictGroupBottomSheet';
import GroupChatRoomPrivateBottomSheet from '@/components/group/Detail/components/RoomList/components/GroupChatRoomPrivateBottomSheet';
import RunningGrowthMissionCompleteBottomSheet from '@/components/group/Detail/components/RunningGrowth/RunningGrowthMissionCompleteBottomSheet';
import TurnOnNotificationBottomSheet from '@/components/group/Detail/components/TurnOnNotificationBottomSheet';
import GroupDetailNoticeMorePage from '@/components/group/Detail/pages/GroupDetailNoticeMore';
import GroupDetailNotMemberMeetupReviewListPage from '@/components/group/DetailNotMember/pages/GroupDetailNotMemberMeetupReviewListPage';
import GroupDetailNotMemberPage from '@/components/group/DetailNotMember/pages/GroupDetailNotMemberPage';
import GroupDetailPage from '@/components/group/DetailV2/pages';
import GroupDetailAlbumPage from '@/components/group/DetailV2/pages/GroupDetailAlbumPage';
import GroupDetailChatRoomPage from '@/components/group/DetailV2/pages/GroupDetailChatRoomPage';
import GroupDetailFeedListPage from '@/components/group/DetailV2/pages/GroupDetailFeedListPage';
import GroupDetailMeetupListPage from '@/components/group/DetailV2/pages/GroupDetailMeetupListPage';
import GroupDetailMeetupPage from '@/components/group/DetailV2/pages/GroupDetailMeetupPage';
import GroupDetailPreviewPage from '@/components/group/DetailV2/pages/GroupDetailPreviewPage';
import GroupDetailSummaryPage from '@/components/group/DetailV2/pages/GroupDetailSummaryPage';
import GroupLeaderBoard from '@/components/group/GroupLeaderBoard/page';
import GroupLeaderBoardGuideBottomSheet from '@/components/group/GroupLeaderBoard/page/GroupLeaderBoardGuideBottomSheet';
import GroupLevelDetailPage from '@/components/group/GroupLevel/Pages/detail';
import InviteMemberShareBottomSheet from '@/components/group/GroupLevel/Pages/InviteMemberShareBottomSheet';
import { GroupEntryPointGuideBottomSheet } from '@/components/group/Home/components/BottomSheet/GroupEntryPointGuideBottomSheet';
import GroupMeetupCreateBottomSheet from '@/components/group/Home/components/BottomSheet/GroupMeetupCreateBottomSheet';
import HomePage from '@/components/group/Home/pages';
import ImageViewerPage from '@/components/group/ImageViewerPage';
import InternalToolPage from '@/components/group/InternalToolPage';
import GroupMeetupDetailPage from '@/components/group/Meetup/Detail/pages';
import GroupMeetupDetailAttendancePage from '@/components/group/Meetup/Detail/pages/Attendance';
import GroupMeetupDetailLikeListPage from '@/components/group/Meetup/Detail/pages/MeetupDetailLikeListPage';
import GroupMeetupDetailMemberListPage from '@/components/group/Meetup/Detail/pages/MemberList';
import TimePickerBottomSheet from '@/components/group/Meetup/Form/components/Field/TimePickerBottomSheet';
import GroupMeetupEditPage from '@/components/group/Meetup/Form/pages/Edit';
import GroupMeetupEditOptionsPage from '@/components/group/Meetup/Form/pages/Edit/Options';
import GroupMeetupNewPage from '@/components/group/Meetup/Form/pages/New';
import GroupMeetupNewOptionsPage from '@/components/group/Meetup/Form/pages/New/Options';
import GroupMeetupCreateReviewPage from '@/components/group/Meetup/Review/pages/Create';
import GroupMeetupCreateDoneReviewPage from '@/components/group/Meetup/Review/pages/GroupMeetupCreateDoneReviewPage';
import GroupMemberListPage from '@/components/group/memberList/pages';
import MemberRejectMenuBottomSheet from '@/components/group/memberList/pages/BottomSheet/MemberRejectMenuBottomSheet';
import GroupMemberListForHostPage from '@/components/group/memberList/pages/MemberListForHost';
import GroupMemberRejectMessagePage from '@/components/group/memberList/pages/MemberRejectMessage';
import MyInfoLikedGroupMeetupListPage from '@/components/group/MyInfo/LikedMeetupList';
import GroupNewSetApprovalPage from '@/components/group/new/Approval';
import GroupNewSetGroupNamePage from '@/components/group/new/Name';
import { GuardForbiddenWordGroupBottomSheet } from '@/components/group/new/Name/bottomSheet/GuardForbiddenWordGroupBottomSheet';
import GroupNewSetProfileImagePage from '@/components/group/new/ProfileImage';
import GroupNewSetGroupCategoryRecommendPage from '@/components/group/new/RecommendCategory';
import GroupNewSetRecruitmentConditionPage from '@/components/group/new/RecruitmentCondition/pages';
import GroupNewSetGroupRegionRangePage from '@/components/group/new/RegionRange';
import GroupNewSetSubNicknamePage from '@/components/group/new/SubNickname';
import NewGroupSubnicknameQuestionBottomSheet from '@/components/group/new/SubNickname/components/NewGroupSubnicknameQuestion';
import { CheckCanViewVerifiedUserInfoBottomSheet } from '@/components/group/new/Verification/bottomSheets/CheckCanViewVerifiedUserInfoBottomSheet';
import { VerificationInduceBottomSheet } from '@/components/group/new/Verification/bottomSheets/VerificationInduceBottomSheet';
import GroupNewSetVerificationPage from '@/components/group/new/Verification/pages';
import GroupNotificationSelectPage from '@/components/group/Notification/pages';
import GroupOnBoardDescriptionPage from '@/components/group/OnBoard/OnBoardDescription';
import GroupOnboardProfilePage from '@/components/group/OnBoard/Profile';
import ParentingGroupFreshMemberKidProfileCreatePage from '@/components/group/ParentingGroup/pages/ParentingGroupFreshMemberKidProfileCreatePage';
import ParentingGroupKidProfileCreatePage from '@/components/group/ParentingGroup/pages/ParentingGroupKidProfileCreatePage';
import ParentingGroupKidProfileEditPage from '@/components/group/ParentingGroup/pages/ParentingGroupKidProfileEditPage';
import ParentingGroupKidProfileListPage from '@/components/group/ParentingGroup/pages/ParentingGroupKidProfileListPage';
import ParentingGroupMemberKidProfileCreatePage from '@/components/group/ParentingGroup/pages/ParentingGroupMemberKidProfileCreatePage';
import { GroupPayTransferDirectChatPage } from '@/components/group/Pay/GroupPayTransferDirectChat';
import GroupPollFormPage from '@/components/group/Poll/pages';
import GroupPollVotedMembersPage from '@/components/group/Poll/pages/GroupPollVotedMembersPage';
import GroupPostDetailPage from '@/components/group/PostDetail/pages';
import GroupMoveBoardCategoryBottomSheet from '@/components/group/PostDetail/pages/bottomSheets/GroupMovePostBoardCategory';
import PostDetailLikeListPage from '@/components/group/PostDetail/pages/PostDetailLikeListPage';
import GroupPostEditPage from '@/components/group/PostForm/page/GroupPostEditPage';
import GroupPostNewPage from '@/components/group/PostForm/page/GroupPostNewPage';
import GroupProfileApprovalPage from '@/components/group/profile/Approval';
import GroupProfileCreatePage from '@/components/group/profile/GroupProfile/Create/pages';
import { SnsTagSetBottomSheet } from '@/components/group/profile/GroupProfile/Detail/bottomSheets/SnsTagSetBottomSheet';
import GroupProfileDetailPage from '@/components/group/profile/GroupProfile/Detail/pages';
import GroupProfileEditPage from '@/components/group/profile/GroupProfile/Edit/pages';
import GroupProfileRequestGroupDirectChatNewPage from '@/components/group/profile/GroupProfile/User/RequestGroupDirectChat/pages/new';
import MainProfileMyInfoRequestDirectChatsPage from '@/components/group/profile/MainProfile/MyInfo/RequestDirectChats/pages';
import GroupEditMemberSubNicknamePage from '@/components/group/profile/MemberProfileDetail/pages/GroupEditMySubNicknamePage';
import ProfileMeetupMemberReviewMessagesPage from '@/components/group/profile/MemberProfileDetail/pages/ProfileMeetupMemberReviewMessagesPage';
import SearchHomePage from '@/components/group/Search/Page/Home';
import SearchResultPage from '@/components/group/Search/Page/Result';
import GroupSettingCreateBoardCategoryBottomSheet from '@/components/group/Setting/components/CreateBoardCategoryBottomSheet';
import RequirementCountSelectorBottomSheet from '@/components/group/Setting/components/SettingMemberGrade/RequirementCountSelectorBottomSheet';
import BoardCategorySetting from '@/components/group/Setting/page/BoardCategorySetting';
import GroupSettingApplicationApprovalBottomSheet from '@/components/group/Setting/page/BottomSheet/ApplicationApproval';
import GroupSettingApplicationQuestionBottomSheet from '@/components/group/Setting/page/BottomSheet/ApplicationQuestion';
import GroupSettingEditGroupAgeRangeBottomSheet from '@/components/group/Setting/page/BottomSheet/EditGroupAgeRange';
import GroupSettingEditBoardCategoryNameBottomSheet from '@/components/group/Setting/page/BottomSheet/EditGroupBoardCategoryName';
import EditGroupSubnicknameQuestionBottomSheet from '@/components/group/Setting/page/BottomSheet/EditGroupSubnicknameQuestion';
import GroupSettingEditMaximumMemberBottomSheet from '@/components/group/Setting/page/BottomSheet/EditMaximumMember';
import GroupSettingEditPermissionBottomSheet from '@/components/group/Setting/page/BottomSheet/EditPermission';
import LeaveGroupBottomSheet from '@/components/group/Setting/page/BottomSheet/LeaveGroupBottomSheet';
import SelectGroupBeforeVerificationChange from '@/components/group/Setting/page/BottomSheet/SelectGroupBeforeVerificationChange';
import GroupSettingEditGroupBotPage from '@/components/group/Setting/page/GroupSettingEditGroupBotPage';
import GroupSettingEditGroupDescription from '@/components/group/Setting/page/GroupSettingEditGroupDescription';
import GroupSettingEditGroupInfoPage from '@/components/group/Setting/page/GroupSettingEditGroupInfoPage';
import GroupSettingEditSubNicknamePage from '@/components/group/Setting/page/GroupSettingEditSubNicknamePage';
import GroupSettingEditVerificationPage from '@/components/group/Setting/page/GroupSettingEditVerification';
import GroupSettingFAQVerificationPage from '@/components/group/Setting/page/GroupSettingFAQVerificationPage';
import GroupSettingGroupBotPage from '@/components/group/Setting/page/GroupSettingGroupBotPage';
import GroupSettingHeroImageSelectorPage from '@/components/group/Setting/page/GroupSettingHeroImageSelectorPage';
import GroupSettingMemberGradePage from '@/components/group/Setting/page/GroupSettingMemberGradePage';
import GroupSettingNotificationPage from '@/components/group/Setting/page/GroupSettingNotification';
import GroupSettingPermissionPage from '@/components/group/Setting/page/GroupSettingPermissionPage';
import { GroupSettingJoinableRegionRangePage } from '@/components/group/Setting/page/JoinableRegionRange';
import GroupSettingMemberDelegateSuperHostPage from '@/components/group/Setting/page/MemberDelegateSuperHost';
import SharePostBottomSheet from '@/components/group/SharePost';
import TalkRoomInviteQRPage from '@/components/group/TalkRoom/InviteMember/pages/InviteQR';
import TalkRoomNewApprovalPage from '@/components/group/TalkRoom/New/approval';
import TalkRoomNewInformationPage from '@/components/group/TalkRoom/New/Information';
import TokenSettingPage from '@/components/group/Token/TokenSettingPage';
import SelectCreateMeetupInMyGroupBottomSheet from '@/features/SeoulRunPromotion/LocationListSection/SelectCreateMeetupInMyGroupBottomSheet/SelectCreateMeetupInMyGroupBottomSheet';

import NotFoundPage from '../pages/NotFound';

export const activityPages = {
  TokenSettingPage,
  MaintenancePage: lazy(() => import('../pages/Maintenance')),
  NotFoundPage,
  HomePage,
  JustStartedGroupMorePage,
  SearchHomePage,
  SearchResultPage,
  GroupDetailPage: GroupDetailPage,
  GroupDetailPageLegacy: GroupDetailPage,
  GroupDetailAlbumPage,
  GroupDetailSummaryPage,
  GroupDetailChatRoomPage,
  GroupDetailPreviewPage,
  GroupDetailPreviewPageLegacy: GroupDetailPreviewPage,
  GroupDetailMeetupPage,
  GroupProfileApprovalPage,
  GroupProfileCreatePage,
  GroupSettingPage,
  GroupNotificationSettingPage,
  GroupJoinSettingPage,
  GroupRegularMeetupPlaceSettingPage,
  GroupMaxMemberSettingPage,
  GroupAgeRangeSettingPage,
  GroupGuideBookSettingPage,
  GroupManagerSettingPage,
  GroupSettingMemberDelegateSuperHostPage,
  GroupSettingJoinableRegionRangePage,
  GroupSettingPermissionPage,
  GroupSettingEditSubNicknamePage,
  GroupSettingMemberGradePage,
  // 본인인증 전환 관련 바텀시트
  GroupSettingEditVerification: GroupSettingEditVerificationPage,
  GroupNewSetGroupCategoryRecommendPage,
  GroupNewSetGroupNamePage,
  GroupNewSetApprovalPage,
  GroupNewSetRecruitmentConditionPage,
  GroupNewSetGroupRegionRangePage,
  GroupNewSetSubNicknamePage,
  GroupNewSetVerificationPage,
  GroupOnboardProfilePage,
  GroupMemberListPage,
  GroupMemberListForHostPage,
  GroupMemberRejectMessagePage,
  GroupMemberGradeApplicationRejectMessagePage,
  GroupMemberGradeDetailPage,
  GroupPostDetailPage,
  GroupPostNewPage,
  GroupPostWritePage,
  GroupPostEditPage,
  GroupProfileDetailPage,
  GroupUserProfileDetailPage: GroupProfileDetailV2Page,
  GroupProfileDetailV2Page,
  GroupProfileMemberManagePage,
  GroupProfileMemberJoinedMeetupPage,
  GroupDetailFeedListPage,
  GroupDetailMeetupListPage,

  MainProfileMyInfoRequestDirectChatsPage,
  GroupProfileRequestGroupDirectChatNewPage,
  GroupPayTransferDirectChatPage,

  GroupProfileEditPage,
  GroupEditMemberSubNicknamePage,
  // GroupCreateProfileAndJoinGroupPage,
  PostDetailLikeListPage,

  CommentDetailPage,
  CommentEditPage,
  CommentListPage,
  CommentEmotionListPage,

  TalkRoomInviteMemberPage: lazy(() => import('@/components/group/TalkRoom/InviteMember/pages')),
  TalkRoomInviteQRPage,
  TalkRoomNewInformationPage,
  TalkRoomNewApprovalPage,
  BoardCategorySetting,
  GroupMeetupNewPage,
  GroupMeetupNewOptionsPage,
  GroupMeetupEditPage,
  GroupMeetupEditOptionsPage,
  GroupMeetupDetailPage,
  GroupMeetupDetailLikeListPage,
  ProfileMeetupMemberReviewMessagesPage,
  GroupMeetupDetailMemberListPage,
  GroupMeetupDetailAttendancePage,
  GroupMeetupDetailQRCheckPage: lazy(
    () => import('@/components/group/Meetup/Detail/pages/QRCheck')
  ),
  GroupDetailNoticeMorePage,
  MyInfoLikedGroupMeetupListPage,
  GroupPollFormPage,
  GroupPollVotedMembersPage,
  GroupOnBoardDescriptionPage,
  GroupNewSetProfileImagePage,

  GroupMeetupCalendarPage,
  GroupNotificationSelectPage,
  GroupSettingNotificationPage,
  GroupSettingEditGroupDescription,
  GroupSettingGroupBotPage,
  GroupSettingEditGroupBotPage,

  GroupMeetupCreateReviewPage,
  GroupMeetupCreateDoneReviewPage,
  GroupSettingEditGroupInfoPage,
  GroupCurationWithKeywordPage,

  GroupSettingFAQVerificationPage,
  AccessStatusServiceHandlingPage,
  ImageViewerPage,
  GroupSettingHeroImageSelectorPage,
  SimpleAlertDialog,

  GroupDetailNotMemberPage, // 디버깅용 route - 모임 상세 미가입자뷰
  GroupDetailNotMemberMeetupReviewListPage,

  GroupLevelDetailPage,

  InternalToolPage,
  GroupLeaderBoard,

  GroupChallengeNewTemplatePage,
  GroupChallengeNewPage,
  GroupChallengeDetailPage,
  GroupChallengeListPage,
  ChallengeListPage,
  ChallengeEditPage,
  ChallengePayRejectPage,
  ChallengeCertifyFeedsPage,
  'BottomSheet/CreatedChallengeGroupBottomSheet': CreatedChallengeGroupBottomSheet,

  ParentingGroupKidProfileCreatePage,
  ParentingGroupKidProfileEditPage,
  ParentingGroupKidProfileListPage,

  ParentingGroupFreshMemberKidProfileCreatePage,
  ParentingGroupMemberKidProfileCreatePage,

  ChallengeMyDailyCertifyResultPage,

  // ---- 서울 한강 보물찾기 런 프로모션 연관 페이지 ----
  SeoulRunMeetupNewOptionsPage,
  SeoulRunMeetupCalendarPage,
  SeoulRunMeetupNewPage,
  SeoulRunLocationListPage,
  SeoulRunMeetupEditPage,
  SeoulRunMeetupEditOptionsPage,
  SeoulRunPromotionGuidePage,
  SeoulRunQRCheckPromotionPage,
  OpenTreasureBoxPage,
  'BottomSheet/SelectCreateMeetupInMyGroupBottomSheet': SelectCreateMeetupInMyGroupBottomSheet, // 내가 가입한 모임 중 어떤 모임에서 일정을 만들지 선택
  'BottomSheet/CreatedSeoulRunMeetupGroupBottomSheet': CreatedSeoulRunMeetupGroupBottomSheet, // 서울 한강런 모임 생성 바텀시트
  'BottomSheet/CreateSeoulRunGroupMeetupSelectBottomSheet':
    CreateSeoulRunGroupMeetupSelectBottomSheet, // 모임 상세 > 일정 리스트에서 일정 생성 클릭시 선택
  // ---- 서울 한강 보물찾기 런 프로모션 연관 페이지 끝 ----

  KarrotPaymentsCreateAccountPage,
  KarrotPaymentsCreateAccountSuccessPage,

  'BottomSheet/GroupLeaderBoardGuideBottomSheet': GroupLeaderBoardGuideBottomSheet,
  'BottomSheet/TurnOnNotificationBottomSheet': TurnOnNotificationBottomSheet,
  'BottomSheet/GroupSettingApplicationApprovalBottomSheet':
    GroupSettingApplicationApprovalBottomSheet,
  'BottomSheet/GroupSettingApplicationQuestionBottomSheet':
    GroupSettingApplicationQuestionBottomSheet,
  'BottomSheet/GroupSettingEditMaximumMemberBottomSheet': GroupSettingEditMaximumMemberBottomSheet,
  'BottomSheet/GroupSettingEditGroupAgeRangeBottomSheet': GroupSettingEditGroupAgeRangeBottomSheet,
  'BottomSheet/GroupSettingCreateBoardCategoryBottomSheet':
    GroupSettingCreateBoardCategoryBottomSheet,
  'BottomSheet/GroupSettingEditPermissionBottomSheet': GroupSettingEditPermissionBottomSheet,
  'BottomSheet/GroupSettingEditSubNicknameQuestionBottomSheet':
    EditGroupSubnicknameQuestionBottomSheet,
  'BottomSheet/NoticePublishGroupBottomSheet': NoticePublishGroupBottomSheet,
  'BottomSheet/NoticeGroupLevelBottomSheet': NoticeGroupLevelBottomSheet,
  'BottomSheet/LevelUpAlertBottomSheet': LevelUpAlertBottomSheet,
  'BottomSheet/GroupMeetupCreateBottomSheet': GroupMeetupCreateBottomSheet,
  'BottomSheet/GroupEntryPointGuideBottomSheet': GroupEntryPointGuideBottomSheet,
  'BottomSheet/RestrictGroupBottomSheet': RestrictGroupBottomSheet,
  'BottomSheet/ApplicationRejectedCommentBottomSheet': ApplicationRejectedCommentBottomSheet,
  'BottomSheet/MemberGradeApplicationRejectedCommentBottomSheet':
    MemberGradeApplicationRejectedCommentBottomSheet,

  'BottomSheet/GroupSettingEditBoardCategoryNameBottomSheet':
    GroupSettingEditBoardCategoryNameBottomSheet,
  'BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet':
    GroupSetMemberProfileSubNicknameBottomSheet,
  'BottomSheet/GroupMoveBoardCategoryBottomSheet': GroupMoveBoardCategoryBottomSheet,
  'BottomSheet/GroupChatRoomPrivateBottomSheet': GroupChatRoomPrivateBottomSheet,
  'BottomSheet/CongratsManagerRoleBottomSheet': CongratsManagerRoleBottomSheet,
  'BottomSheet/VerificationInduceBottomSheet': VerificationInduceBottomSheet,
  'BottomSheet/TimePickerBottomSheet': TimePickerBottomSheet,
  'BottomSheet/GroupSetNewSubnicknameQuestion': NewGroupSubnicknameQuestionBottomSheet,
  'BottomSheet/CheckCanViewVerifiedUserInfoBottomSheet': CheckCanViewVerifiedUserInfoBottomSheet,
  'BottomSheet/MemberRejectMenuBottomSheet': MemberRejectMenuBottomSheet,

  'BottomSheet/SelectGroupBeforeVerificationChange': SelectGroupBeforeVerificationChange,

  // 러닝그로스 완료 바텀시트
  'BottomSheet/RunningGrowthMissionCompleteBottomSheet': RunningGrowthMissionCompleteBottomSheet,

  'BottomSheet/GuardForbiddenWordGroupBottomSheet': GuardForbiddenWordGroupBottomSheet,
  'BottomSheet/SnsTagSetBottomSheet': SnsTagSetBottomSheet,

  'BottomSheet/SharePostBottomSheet': SharePostBottomSheet,
  'BottomSheet/CommentMoreMenuBottomSheet': CommentMoreMenuBottomSheet,
  'Modal/CommentDeleteModal': CommentDeleteModal,
  'BottomSheet/InviteMemberShareBottomSheet': InviteMemberShareBottomSheet,
  'BottomSheet/LeaveGroupBottomSheet': LeaveGroupBottomSheet,

  'BottomSheet/RequirementCountSelectorBottomSheet': RequirementCountSelectorBottomSheet,
};
