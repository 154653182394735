import { groupClient } from '@community-group/api';
import {
  GroupChallengeCreateRequest,
  GroupChallengeCreateResponse,
} from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useRead } from '@/shared/api/hooks/useRead';
import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

type Params = {
  groupChallengeCreateRequest: GroupChallengeCreateRequest;
};
type Props = UseMutationOptions<AxiosResponse<GroupChallengeCreateResponse>, Error, Params>;

// 챌린지 생성 Hook, 모임이 없는 경우에는 groupId를 Null로 보내면 됨
export const usePostChallenge = ({ onError, onSuccess }: Props) => {
  const postGroupChallenge = useRead(groupClient.api.GroupChallengeApi.apiV1ChallengesPost);

  const fetchPostGroupChallenge = ({ groupChallengeCreateRequest }: Params) => {
    return postGroupChallenge({
      groupChallengeCreateRequest,
    });
  };

  return useThrottleMutation(fetchPostGroupChallenge, {
    onError,
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);
    },
  });
};
