import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { isHigherManager, isManager, isSuperHost } from '@community-group/components';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { useGetApplications } from '@/api/hooks/useGetApplications';
import { useGetGroupFeedReviewWriteableMeetup } from '@/api/hooks/useGetGroupFeedReviewWriteableMeetup';
import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';
import GroupLevelBanner from '@/components/common/GroupLevelBanner';
import GroupDetailRunningGrowthBanner from '@/components/group/Detail/components/RunningGrowth/GroupDetailRunningGrowthBanner/index';
import { useQueryChallengeBanner } from '@/domain/Challenge/hooks/useReadChallengeBanner';
import { useQueryGroupMemberApplicationCount } from '@/domain/GroupMember/hooks/useReadGroupMemberApplicationCount';
import { useQueryGroupMemberGradeApplicationStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeApplicationStatus';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { useQueryGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import ChallengeBanner from '@/features/ChallengeBanner';
import { isAccessibleGrade } from '@/utils/permission';

import useMemberMissionList from '../../hooks/useMemberMissionList';
import GroupDetailMeetupReviewBanner from './GroupDetailMeetupReviewBanner';
import GroupDetailMemberGradeMissionBanner from './GroupDetailMemberGradeMissionBanner';
import GroupDetailOnboardingBannerForMember from './GroupDetailOnboardingBannerForMember';
import GroupDetailWaitingMemberBanner from './GroupDetailWaitingMemberBanner';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

const useMainBanners = ({ group, currentUser }: Props) => {
  const groupId = useMemo(() => group.id.toString(), [group.id]);
  const userId = useMemo(() => currentUser.id.toString(), [currentUser.id]);

  const missionList = useMemberMissionList(groupId);

  const hasRemainMission = missionList.length !== 0;

  const { data: applications } = useGetApplications(groupId);
  const { data: reviewWriteable } = useGetGroupFeedReviewWriteableMeetup(groupId);
  const { runningGrowthData } = useGetRunningGrowthMission(groupId);
  const [
    { data: challengeBanner },
    { data: memberGradeStatus },
    { data: memberGradeApplicationCount },
    { data: permissions },
    { data: isApplicationPending },
  ] = useSuspenseQueries({
    queries: [
      useQueryChallengeBanner({ groupId: Number(groupId) }),
      useQueryGroupMemberGradeStatus(groupId),
      useQueryGroupMemberApplicationCount(groupId),
      useQueryGroupPermissions(groupId),
      useQueryGroupMemberGradeApplicationStatus({ groupId, userId }),
    ],
  });

  const memberGradeEnabled = useMemo(() => {
    return memberGradeStatus === 'system';
  }, [memberGradeStatus]);
  const showMemberGradeBanner = useMemo(() => {
    return memberGradeEnabled && currentUser.grade.name === '준회원' && !isApplicationPending;
  }, [memberGradeEnabled, currentUser.grade.name, isApplicationPending]);

  const showGroupJoinWaitingBanner = useMemo(() => {
    if (!currentUser.permissions.applyApplicationMember) return false;
    if (group.management?.applicationCount === undefined) return false;
    if (group.management?.applicationCount <= 0) return false;

    return true;
  }, [group, currentUser]);

  const showGroupMemberGradeWaitingBanner = useMemo(
    () =>
      isAccessibleGrade(currentUser.grade, permissions.modifyGroupMemberGrade.currentPriority) &&
      memberGradeApplicationCount > 0,
    [
      currentUser.grade,
      memberGradeApplicationCount,
      permissions.modifyGroupMemberGrade.currentPriority,
    ]
  );

  const showRunningGrowthBanner =
    isSuperHost(currentUser.role) && runningGrowthData && runningGrowthData.length > 0;

  const memberCount = applications?.pages[0].data.applicationCount ?? 0;

  const showChallengeBanner = challengeBanner && challengeBanner.challenges.length > 0;

  // ** 일반 멤버인 경우
  const getMemberBanners = useCallback(() => {
    const result: { id: string; element: React.ReactNode }[] = [];

    // 모임 일정 후기 배너 노출
    const meetupId = reviewWriteable?.meetupId?.toString();
    if (meetupId) {
      result.push({
        id: 'meetup-review-banner',
        element: (
          <GroupDetailMeetupReviewBanner
            group={group}
            currentUser={currentUser}
            meetupId={meetupId}
          />
        ),
      });
    }

    // 모임 챌린지 배너 노출
    if (showChallengeBanner) {
      result.push({
        id: 'challenge-banner',
        element: <ChallengeBanner challengeBanner={challengeBanner} />,
      });
    }

    if (showMemberGradeBanner) {
      result.push({
        id: 'member-grade-mission-banner',
        element: <GroupDetailMemberGradeMissionBanner group={group} userId={userId} />,
      });
    }

    // 공통 함수: 2주 이내 가입 여부 확인
    const isJoinedIn2Weeks = (joinedAt: string) =>
      new Date(joinedAt) > new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14);

    /** @note 멤버 등급제가 활성화된 경우 멤버 등업 미션 배너가 노출되므로 멤버 온보딩 배너는 노출하지 않아요 */
    if (!memberGradeEnabled && isJoinedIn2Weeks(currentUser.joinedAt) && hasRemainMission) {
      // 멤버 온보딩 배너 노출
      result.push({
        id: 'member-onboarding-banner',
        element: <GroupDetailOnboardingBannerForMember groupId={groupId} />,
      });
    }

    // 모든 챌린지를 완료한 경우 challenge-banner가 존재하면 제일 뒤로 이동시킴
    // if (allDoneChallenge) {
    //   const challengeBannerIndex = result.findIndex((banner) => banner.id === 'challenge-banner');
    //   if (challengeBannerIndex !== -1) {
    //     result.push(result.splice(challengeBannerIndex, 1)[0]);
    //   }
    // }

    if (result.length === 0) return [];
    return result.filter((banner) => banner.element !== null);
  }, [
    challengeBanner,
    currentUser,
    group,
    groupId,
    hasRemainMission,
    memberGradeEnabled,
    reviewWriteable?.meetupId,
    showChallengeBanner,
    showMemberGradeBanner,
    userId,
  ]);

  // ** 모임장 or 운영진인 경우
  const getHostAndManagerBanners = useCallback(() => {
    const result: { id: string; element: React.ReactNode }[] = [];

    // 가입, 등업 요청 대기 중인 멤버가 있는 경우
    if (showGroupJoinWaitingBanner || showGroupMemberGradeWaitingBanner) {
      result.push({
        id: 'waiting-member-banner',
        element: (
          <GroupDetailWaitingMemberBanner
            group={group}
            currentUser={currentUser}
            joinApplicationCount={memberCount}
            memberGradeApplicationCount={memberGradeApplicationCount}
          />
        ),
      });
    }

    // 모임 일정 후기 배너 노출
    const meetupId = reviewWriteable?.meetupId?.toString();
    if (meetupId) {
      result.push({
        id: 'meetup-review-banner',
        element: (
          <GroupDetailMeetupReviewBanner
            group={group}
            currentUser={currentUser}
            meetupId={meetupId}
          />
        ),
      });
    }

    // 모임 챌린지 배너 노출
    if (showChallengeBanner) {
      result.push({
        id: 'challenge-banner',
        element: <ChallengeBanner challengeBanner={challengeBanner} />,
      });
    }

    // 러닝 그로스 미션 배너 노출
    if (showRunningGrowthBanner) {
      result.push({
        id: 'running-growth-banner',
        element: <GroupDetailRunningGrowthBanner />,
      });
    }

    result.push({
      id: 'group-level-banner',
      element: <GroupLevelBanner groupId={groupId} size="large" />,
    });

    // * 운영진인 경우
    if (isManager(currentUser.role)) {
      // 공통 함수: 2주 이내 가입 여부 확인
      const isJoinedIn2Weeks = (joinedAt: string) =>
        new Date(joinedAt) > new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14);

      if (isJoinedIn2Weeks(currentUser.joinedAt) && hasRemainMission) {
        // 멤버 온보딩 배너 노출
        result.push({
          id: 'member-onboarding-banner',
          element: <GroupDetailOnboardingBannerForMember groupId={groupId} />,
        });
      }
    }

    // 모든 챌린지를 완료한 경우 challenge-banner가 존재하면 제일 뒤로 이동시킴
    // if (allDoneChallenge) {
    //   const challengeBannerIndex = result.findIndex((banner) => banner.id === 'challenge-banner');
    //   if (challengeBannerIndex !== -1) {
    //     result.push(result.splice(challengeBannerIndex, 1)[0]);
    //   }
    // }

    if (result.length === 0) return [];
    return result.filter((banner) => banner.element !== null);
  }, [
    showGroupJoinWaitingBanner,
    showGroupMemberGradeWaitingBanner,
    reviewWriteable?.meetupId,
    showChallengeBanner,
    showRunningGrowthBanner,
    groupId,
    currentUser,
    group,
    memberCount,
    memberGradeApplicationCount,
    challengeBanner,
    hasRemainMission,
  ]);

  return {
    banners: isHigherManager(currentUser.role) ? getHostAndManagerBanners() : getMemberBanners(),
  };
};

export default useMainBanners;
