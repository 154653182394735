import { groupClient } from '@community-group/api';
import { BoxButton, Typography } from '@community-group/components';
import React from 'react';

import { useFlow } from '@/stackflow';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { useJuniorGroupExperiment } from '@/utils/analytics/experiment/experiments/useJuniorGroupExperiment';

import GroupInfoListViewItem from '../GroupInfoListItem/ListView';
import * as s from './GroupExplorerListSection.css';

interface Props {
  groups: groupClient.model.GroupSummaryPresentation[];
  title: string;
  appendRightHeader?: React.ReactNode;
  footerButtonText?: React.ReactNode;
  footerButtonOnClick?: () => void;
  onFooterButtonClick?: () => void;
  isExperiment?: boolean;
}

const GroupExplorerListSection = ({
  groups,
  title,
  footerButtonText,
  footerButtonOnClick,
  isExperiment = false,
}: Props) => {
  const { push } = useFlow();
  const { utm_medium } = useQueryParams();
  const { isTreatment: enabledJuniorGroupExperiment } = useJuniorGroupExperiment();

  const isUTMMediumFleamarket = utm_medium?.includes('fleamarket');
  const displayCount = isExperiment && isUTMMediumFleamarket ? 10 : 3;

  return (
    <div className={s.Wrapper}>
      <div className={s.Header}>
        <Typography typography="title3Bold">{title}</Typography>
      </div>
      <div className={s.List}>
        {groups.slice(0, Math.min(displayCount, groups.length)).map((group) => (
          <GroupInfoListViewItem
            key={group?.id}
            group={group}
            config={{
              enableLazyLoadImage: false,
              enabledJuniorGroupExperiment: enabledJuniorGroupExperiment,
            }}
            onClick={() => {
              trackEvent({
                event: 'click_recommend_similar_group_list_item',
                params: {
                  groupName: group?.name,
                  groupCategoryName: group?.category?.name,
                  groupId: group?.id,
                  isExperiment,
                },
                sample: true,
              });

              push('GroupDetailPage', {
                groupId: group?.id.toString(),
              });
            }}
          />
        ))}
      </div>
      {footerButtonText && (
        <div className={s.Footer}>
          <BoxButton variant="secondary" size="medium" width="100%" onClick={footerButtonOnClick}>
            {footerButtonText}
          </BoxButton>
        </div>
      )}
    </div>
  );
};

export default React.memo(GroupExplorerListSection);
