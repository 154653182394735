import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { MAXIMUM_MEMBER_OPTIONS } from '@/components/group/new/RecruitmentCondition/constants/maximum-member-constants';
import MaximumMemberChips from '@/components/group/new/RecruitmentCondition/maximumMember';

import * as s from './style.css';

type Props = {
  initialMaximumMemberCount: number;
};

const GroupMaxMemberSettingSection = ({ initialMaximumMemberCount }: Props) => {
  const initSelectedChipId = () => {
    if (initialMaximumMemberCount === -1) return 0;

    const foundIndex = MAXIMUM_MEMBER_OPTIONS.findIndex(
      (option) => option.value === initialMaximumMemberCount
    );

    return foundIndex !== -1 ? foundIndex : MAXIMUM_MEMBER_OPTIONS.length - 1;
  };

  const [selectedChip, setSelectedChip] = useState(initSelectedChipId());

  const { watch, setValue, register } = useFormContext();

  return (
    <div className={s.Wrapper}>
      <MaximumMemberChips
        alwaysShowInput={true}
        register={register}
        selectedChip={selectedChip}
        setSelectedChip={setSelectedChip}
        watch={watch}
        setValue={setValue}
      />
    </div>
  );
};

export default GroupMaxMemberSettingSection;
