import { BoxButton, Dialog, useDialog } from '@community-group/components';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import { useQueryGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { usePutGroupMeetupJoinStatus } from '@/api/hooks/usePutGroupMeetupJoinStatus';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { useBlockGroupActionByGroupStatus } from '@/features/GroupDetail/hooks/useBlockGroupActionByGroupStatus';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { extendAppsflyerLoggerType, trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';

import { GroupMeetupDetailAccessoryBarWrapper } from './shared/GroupMeetupDetailAccessoryBarWrapper';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

export const NotJoinedGroupMeetupJoinButton = () => {
  const { groupId, meetupId } = usePathParams();

  const { push } = useFlow();
  const { open: openDialog, close: closeDialog } = useDialog();
  const { open: openSnackbar } = useSnackbar();

  const [
    { data: me },
    { data: group, refetch: refetchGroup },
    { data: meetup, refetch: refetchMeetup },
    { data: memberGradeStatus },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupMe({ groupId }),
      useQueryGroupDetail(groupId),
      useQueryGroupMeetupDetail({ groupId, meetupId }),
      useQueryGroupMemberGradeStatus(groupId),
    ],
  });

  const memberGradeEnabled = useMemo(() => memberGradeStatus === 'system', [memberGradeStatus]);

  const [loading, setLoading] = useState(false);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate } = usePutGroupMeetupJoinStatus({
    onError: handleErrorWithToast,
    onSettled: () => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      refetchGroup();
      refetchMeetup();
    },
  });

  const blockGroupActionByGroupStatus = useBlockGroupActionByGroupStatus({
    groupId: groupId.toString(),
    actionName: 'JoinMeetup',
  });

  const handleJoinClick = blockGroupActionByGroupStatus(() => {
    if (memberGradeEnabled && !meetup.canJoinAnyMember && me.grade.name === '준회원') {
      openDialog({
        element: (
          <Dialog
            description="정회원 이상만 참여할 수 있는 일정이에요. 멤버 등급을 확인해주세요."
            primaryActionLabel="내 멤버 등급 보기"
            secondaryActionLabel="닫기"
            onSecondaryAction={closeDialog}
            onPrimaryAction={async () => {
              await closeDialog();
              push('GroupMemberGradeDetailPage', {
                groupId,
                userId: me.id.toString(),
                from: 'meetupJoinButton',
              });
            }}
            secondaryActionIntent="nonpreferred"
          />
        ),
      });
      return;
    }

    setLoading(true);
    trackEvent({
      event: 'click_group_meetup_join_button',
      params: {
        meetupId,
        groupId: meetup.groupInfo.id,
        meetupName: meetup.title,
        groupName: meetup.groupInfo.name,
        groupCategory: group.category.name,
        isGroupMember: meetup.currentUserInfo.groupJoined,
        isGroupMeetupMember: meetup.currentUserInfo.meetupJoined,
        isLikedGroupMeetup: meetup.currentUserInfo.like,
      },
    });

    mutate(
      {
        groupId,
        meetupId,
        meetupJoinStatus: 'join',
      },
      {
        onSuccess: () => {
          openSnackbar({
            message: '일정에 참여했어요',
          });

          openLink(meetup.channelInfo?.targetUri);

          trackEvent({
            event: 'click_join_group_meetup',
            params: {
              meetupId,
              groupId: meetup.groupInfo.id,
              userRole: meetup.currentUserInfo.role,
              meetupJoined: meetup.currentUserInfo.meetupJoined,
              maximumParticipantsNumber: meetup.maximumParticipantsNumber,
              currentParticipantsNumber: meetup.currentParticipantsNumber,
              currentMeetupJoinStatus: meetup.currentUserInfo.meetupJoinStatus,
              category: group.category.name,
              isInstantMeetupJoin: false,
              currentUserState: me.state,
              needVerification: group.needVerification,
            },
            loggerType: extendAppsflyerLoggerType,
          });
        },
      }
    );
  });

  const isFullyRecruited = meetup.progressStatus?.status === 'fullyRecruited';

  if (meetup.progressStatus.status === 'closed') {
    return <></>;
  }

  return (
    <GroupMeetupDetailAccessoryBarWrapper groupId={groupId} meetupId={meetupId}>
      <BoxButton
        size="xlarge"
        width="100%"
        isLoading={loading}
        isDisabled={isFullyRecruited}
        onClick={handleJoinClick}
      >
        {isFullyRecruited ? '모집 마감' : '참여하기'}
      </BoxButton>
    </GroupMeetupDetailAccessoryBarWrapper>
  );
};
