import { Category, CategoryItemType } from '@community-group/api/lib/group/models';
import { Spacing, SwitchV3, Typography } from '@community-group/components';
import { useMemo } from 'react';

import { ORDER_HOME } from '@/api/base/group';
import { useGetCategories } from '@/api/hooks/useGetCategories';
import GroupIcon from '@/assets/images/group_icon.png';
import { Container } from '@/components/common/Container';
import { trackEvent } from '@/utils/analytics';

import CategoryFilterList from '../../common/Filter/CategoryFilterList';
import { FilterType } from '.';
import * as s from './GroupListFilter.css';
import SubCategoryFilter from './SubCategoryFilter';

type Props = {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
};

const GroupListFilter = ({ filter, setFilter }: Props) => {
  const { categories } = useGetCategories();

  const categoryList = useMemo(() => {
    return [
      { id: 0, name: '전체', icon: GroupIcon, createdAt: '', subCategories: [] },
      ...categories,
    ];
  }, [categories]);

  const subCategoryList = categoryList.find((el) => el.id === filter.category)?.subCategories ?? [];

  return (
    <div className={s.Wrapper}>
      <Container paddingY={0} paddingX={0}>
        <CategoryFilterList
          initialCategoryId={filter.category}
          categories={categoryList}
          onClickItem={(item: Category | CategoryItemType) => {
            trackEvent({
              event: 'click_group_home_main_category',
              params: {
                mainCategory: item.id !== 0 ? item.id : undefined,
              },
            });
            setFilter((prevFilter) => ({
              ...prevFilter,
              category: item.id !== 0 ? item.id : undefined,
              subCategory: undefined,
            }));
          }}
        />

        {subCategoryList.length !== 0 ? (
          <SubCategoryFilter
            onClickSubCategory={(id) => {
              setFilter((prevFilter) => ({
                ...prevFilter,
                subCategory: id ?? undefined,
              }));
            }}
            selectedCategory={filter.category ?? 0}
            subCategories={subCategoryList}
            selectedSubCategory={filter.subCategory ?? undefined}
          />
        ) : (
          <Spacing size={12} />
        )}

        <div className={s.SwitchOrderWrapper}>
          <div
            className={s.SwitchFilterWrapper}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setFilter((prevFilter) => ({
                ...prevFilter,
                order:
                  prevFilter.order === ORDER_HOME.CREATED_AT_DESC
                    ? ORDER_HOME.RECOMMEND_SCORE_DESC
                    : ORDER_HOME.CREATED_AT_DESC,
              }));
            }}
          >
            <SwitchV3
              size="small"
              checked={filter.order === ORDER_HOME.CREATED_AT_DESC}
              onChange={(e) => {
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  order: e.currentTarget.checked
                    ? ORDER_HOME.CREATED_AT_DESC
                    : ORDER_HOME.RECOMMEND_SCORE_DESC,
                }));
              }}
            />
            <Typography typography="bodyM2Regular" color="gray900">
              최신순으로 보기
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default GroupListFilter;
