import './stylesheet.css';
import '../token-minimum.css';

import clsx from 'clsx';
import * as React from 'react';

import type { Assign } from '../utils/types';
import { visuallyHidden } from '../utils/visuallyHidden';
import { type SwitchVariantProps, switchStyle } from './recipe';
import { type UseSwitchProps, useSwitch } from './useSwitch';

export interface SwitchProps
  extends Assign<React.HTMLAttributes<HTMLInputElement>, UseSwitchProps>,
    SwitchVariantProps {}

export const SwitchV3 = React.forwardRef<HTMLInputElement, SwitchProps>(
  ({ className, size = 'medium', ...otherProps }, ref) => {
    const { stateProps, restProps, controlProps, hiddenInputProps, rootProps, thumbProps } =
      useSwitch(otherProps);
    const classNames = switchStyle({ size });

    return (
      <label className={clsx(classNames.root, className)} {...rootProps}>
        <div {...controlProps} className={classNames.control}>
          <div {...stateProps} {...thumbProps} className={classNames.thumb} />
        </div>
        <input ref={ref} {...hiddenInputProps} {...restProps} style={visuallyHidden} />
      </label>
    );
  }
);

SwitchV3.displayName = 'SwitchV3';
