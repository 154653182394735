import { Typography, useBottomSheet } from '@community-group/components';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useCallback } from 'react';

import { useReadGroupCategories } from '@/domain/GroupCategory/hooks/useReadGroupCategories';
import GroupCategoryBottomSheet from '@/features/GroupSetting/GroupCategoryBottomSheet';

import * as s from './style.css';

type Props = {
  categoryId?: number;
  onSelectCategory: (categoryId: number) => void;
};

const GroupInfoCategory = ({ categoryId, onSelectCategory }: Props) => {
  const { data: categories } = useReadGroupCategories();
  const { open: openBottomSheet, close: closeBottomSheet } = useBottomSheet();
  const onClickCategory = useCallback(
    (categoryId: number) => {
      onSelectCategory(categoryId);
      closeBottomSheet();
    },
    [onSelectCategory, closeBottomSheet]
  );

  const handleChangeCategory = () => {
    openBottomSheet({
      element: (
        <GroupCategoryBottomSheet
          categories={categories}
          selectedCategoryId={categoryId}
          onSelectCategory={onClickCategory}
        />
      ),
    });
  };

  if (!categoryId) return null;

  return (
    <div className={s.CategoryWrapper} onClick={handleChangeCategory}>
      <Typography typography="label3Bold" color="gray900">
        카테고리
      </Typography>
      <div className={s.CategoryBox}>
        <Typography typography="label2Regular" color="gray900" style={{ flex: 1 }}>
          {categories?.find((category) => category.id === categoryId)?.name}
        </Typography>
        <IconChevronRightLine size={22} color={vars.$scale.color.gray900} />
      </div>
    </div>
  );
};

export default GroupInfoCategory;
