import {
  BoxButton,
  Render,
  Spacing,
  Typography,
  VerticalSpacing,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconCheckmarkLine, IconExclamationmarkCircleLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useSuspenseQueries } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';

import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { usePostGroupMemberGradeApplication } from '@/domain/GroupMember/hooks/usePostGroupMemberGradeApplication';
import { useQueryGroupMemberGradeApplicationStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeApplicationStatus';
import { useQueryGroupMemberGradeRequirements } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeRequirements';
import { mapMemberGradeRequirementActivities } from '@/domain/GroupMember/utils/memberGradeRequirements';
import { useQueryGroupProfileUserActivities } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserActivities';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import MemberGradeIcon from './MemberGradeIcon';
import RequirementProgress from './RequirementProgress';
import * as s from './style.css';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

type Props = {
  groupId: string;
  userId: string;
  isBannerApplication: boolean;
};

const MyMemberGradeSection = ({ groupId, userId, isBannerApplication }: Props) => {
  const { push } = useFlow();
  const { open: openSnackbar } = useSnackbar();

  const [
    { data: me },
    { data: group },
    {
      data: { count },
    },
    { data: requirements },
    { data: isApplicationPending, refetch: refetchGroupMemberGradeApplicationStatus },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupMe({ groupId }),
      useQueryGroupDetail(groupId),
      useQueryGroupProfileUserActivities({ groupId, userId }),
      useQueryGroupMemberGradeRequirements(groupId),
      useQueryGroupMemberGradeApplicationStatus({ groupId, userId }),
    ],
  });

  const { mutateAsync: postGroupMemberGradeApplication } = usePostGroupMemberGradeApplication({
    onSuccess: () => {
      refetchGroupMemberGradeApplicationStatus();
      openSnackbar({
        message: '등업을 신청했어요. 승인되면 다시 알려드릴게요.',
      });
    },
  });

  const handleApplicationButtonClick = () => {
    trackEvent({
      event: 'click_apply_member_grade_upgrade',
      params: {
        groupId,
        userId,
        groupName: group.name,
        categoryId: group.category.id,
        categoryName: group.category.name,
        from: 'gradePage',
      },
      sample: true,
    });

    postGroupMemberGradeApplication({ groupId, userId });
  };

  useEffect(() => {
    if (!isBannerApplication) return;
    postGroupMemberGradeApplication({ groupId, userId });
  }, [groupId, userId, isBannerApplication, postGroupMemberGradeApplication]);

  const activityClickEvents = {
    groupVisitCount: () => {
      push('GroupDetailPage', { groupId });
    },
    createdArticleCount: () => {
      push('GroupPostNewPage', { groupId });
    },
    createdCommentCount: () => {
      push('GroupDetailFeedListPage', { groupId });
    },
    joinedMeetupCount: () => {
      push('GroupDetailMeetupPage', { groupId });
    },
  };
  const memberGradeRequirementActivities = mapMemberGradeRequirementActivities(requirements, count);
  const isMaxRequirements = memberGradeRequirementActivities.length === requirements.length;
  const isStandardMember = me.grade.name === '준회원';
  const isAllRequirementMet = useMemo(() => {
    return memberGradeRequirementActivities.every(
      (activity) => activity.count >= activity.goalCount
    );
  }, [memberGradeRequirementActivities]);

  return (
    <div className={s.Container}>
      <div className={s.MyMemberGradeContainer}>
        <Typography typography="subtitle2Regular" color="gray900">
          내 멤버 등급
        </Typography>
        <Spacing size={8} />
        <div className={s.MemberGradeContainer}>
          <MemberGradeIcon grade={me.grade} />
          <VerticalSpacing size={6} />
          <Typography typography="title1Bold" color="gray900">
            {me.grade.name}
          </Typography>
        </div>
        <Spacing size={16} />
        <Typography typography="subtitle2Regular" color="gray600">
          {dayjs(me.joinedAt).format('YYYY년 M월 DD일')} 가입
        </Typography>
      </div>
      <Render condition={isStandardMember}>
        <Spacing size={24} />
        <ul className={s.RequirementList({ isMaxRequirements })}>
          {memberGradeRequirementActivities.map((activity) => (
            <li key={activity.key} onClick={activityClickEvents[activity.key]}>
              <RequirementProgress
                label={activity.label}
                representativeIcon={activity.icon}
                currentCount={activity.count}
                goalCount={activity.goalCount}
              />
            </li>
          ))}
        </ul>
      </Render>
      <Render condition={isStandardMember}>
        <Spacing size={24} />
        <Render condition={!isApplicationPending}>
          <BoxButton
            variant="primaryLow"
            size="medium"
            width="100%"
            isDisabled={!isAllRequirementMet}
            onClick={handleApplicationButtonClick}
          >
            등업 신청
          </BoxButton>
          <Render condition={!isAllRequirementMet}>
            <Spacing size={8} />
            <div className={s.RequirementGuideMessage}>
              <IconExclamationmarkCircleLine size={16} color={vars.$scale.color.gray600} />
              <VerticalSpacing size={4} />
              <Typography typography="label3Regular" color="gray600">
                아직 등업 조건이 충족되지 않았어요.
              </Typography>
            </div>
          </Render>
        </Render>
        <Render condition={isApplicationPending}>
          <BoxButton
            variant="primaryLow"
            size="medium"
            width="100%"
            prefix={<IconCheckmarkLine size={16} fill={vars.$scale.color.gray300} />}
            isDisabled
          >
            등업 신청 완료
          </BoxButton>
        </Render>
      </Render>
    </div>
  );
};

export default withAsyncBoundary(MyMemberGradeSection, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
