import 'dayjs/locale/ko';

import { ApiV1AllTypeMeetupsGetRequestParams } from '@community-group/api/lib/group/api/all-meetups-api';
import { AsyncBoundary, Divider, Typography } from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { PaginationList } from '@community-group/components/shared';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { motion } from 'framer-motion';
import { useMemo, useState } from 'react';

import { useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { Container } from '@/components/common/Container';
import GroupMeetupHomeListSkeleton from '@/components/group/Home/components/MeetupTab/ExplorerMeetupSection/ExplorerMeetupList/GroupMeetupHomeListSkeleton';
import NoMoreContentsBox from '@/components/group/Home/components/MeetupTab/ExplorerMeetupSection/ExplorerMeetupList/NoMoreContentsBox';
import { MeetupHomeListCard } from '@/components/group/Home/components/MeetupTab/ExplorerMeetupSection/MeetupHomeListCardItem';
import { useUserInfoForTrackEvent } from '@/components/group/Home/hooks/useUserInfoForTrackEvent';
import { useUserConfig } from '@/contexts/UserConfig';
import { useReadMeetupsWithDayFilter } from '@/domain/GroupMeetup/hooks/useReadMeetupsWithDayFilter';
import { useFlow } from '@/stackflow';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { formatDate, KARROT_DATE_FORMAT } from '@/utils/date';

import { DayCalendarRadio } from './DayCalendarRadio';
import * as s from './index.css';

dayjs.locale('ko');
dayjs.extend(weekday);

const MeetupCalendar = (props: {
  filter: ApiV1AllTypeMeetupsGetRequestParams;
  mode?: 'page' | 'homeSection';
}) => {
  const { meetupCalendarDay = formatDate(new Date(), 'yyyy-MM-dd') } = useQueryParams();
  const [selectedDay, setSelectedDay] = useState<string>(meetupCalendarDay);

  return (
    <>
      <motion.div style={{ backgroundColor: vars.$semantic.color.paperDefault }}>
        <Container paddingY={0} paddingX={0}>
          {props.mode === 'homeSection' && (
            <Typography className={s.title} typography="title3Bold" as="h3" color="gray900">
              모임 일정 캘린더
            </Typography>
          )}
          <DayCalendarRadio setSelectedDay={setSelectedDay} selectedDay={selectedDay} />
          <AsyncBoundary pendingFallback={<GroupMeetupHomeListSkeleton length={3} />}>
            <MeetupCalendarList {...props} selectedDay={selectedDay} />
          </AsyncBoundary>
        </Container>
      </motion.div>
      {props.mode === 'homeSection' && (
        <Divider size={8} background={vars.$semantic.color.paperBackground} />
      )}
    </>
  );
};

const MeetupCalendarList = ({
  filter,
  mode = 'homeSection',
  selectedDay,
}: {
  filter: ApiV1AllTypeMeetupsGetRequestParams;
  mode?: 'page' | 'homeSection';
  selectedDay: string;
}) => {
  const { userConfig } = useUserConfig();
  const regionId = userConfig.regionId ?? 0;

  const { push } = useFlow();

  const { data, hasNextPage, fetchNextPage } = useReadMeetupsWithDayFilter({
    regionId,
    ...filter,
    targetDate: selectedDay ? formatDate(new Date(selectedDay), KARROT_DATE_FORMAT) : undefined,
    showRenderLimit: mode === 'homeSection',
  });

  const { data: myGroups } = useGetMyGroupList();

  const userInfo = useUserInfoForTrackEvent();

  const buttonHandlers = useMemo(() => {
    if ((myGroups?.groups ?? []).length < 1) {
      return {
        label: '모임 만들기',
        onClick: () => {
          push('GroupNewSetGroupCategoryRecommendPage', {});

          return;
        },
      };
    }
    return {
      label: '일정 만들기',
      onClick: () => {
        push('BottomSheet/GroupMeetupCreateBottomSheet', {});
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myGroups?.groups]);

  if (!data) return <></>;

  return (
    <>
      <div className={s.listWrapper}>
        <PaginationList
          items={data}
          hasNextPage={mode === 'page' && hasNextPage}
          fetchNextPage={fetchNextPage}
          render={(meetup) =>
            meetup && (
              <MeetupHomeListCard
                key={meetup.id}
                title={meetup.title}
                headerLabel={[meetup.region.name]}
                meetupAtDisplayText={meetup.meetupAtDisplayText}
                currentParticipantsCount={meetup.currentParticipantsCount}
                maximumParticipantsCount={meetup.maximumParticipantsCount}
                hostInfo={{
                  image: meetup.groupInfo?.profileImage?.small ?? '',
                  displayText: meetup.groupInfo?.name ?? meetup.host.name,
                  imageShape: 'square',
                }}
                onClick={() => {
                  if (meetup.type === 'group_meetup' && meetup.groupInfo?.id) {
                    trackEvent({
                      event: 'click_group_meetup_item',
                      params: {
                        ...userInfo,
                        referer: 'meetup_calendar',
                      },
                      sample: true,
                    });
                    push('GroupMeetupDetailPage', {
                      groupId: meetup.groupInfo.id.toString(),
                      meetupId: meetup.id.toString(),
                    });
                    return;
                  }
                }}
                viewCount={meetup.viewCount}
                image={meetup.image?.medium ?? ''}
              />
            )
          }
        />
      </div>

      {data.length > 2 && mode === 'homeSection' && (
        <div className={s.buttonWrapper}>
          <BoxButton
            variant="secondary"
            size="medium"
            width="100%"
            onClick={() => {
              push('GroupMeetupCalendarPage', {
                meetupCalendarDay: selectedDay,
              });
            }}
            suffix={
              <div className={s.iconWrapper}>
                <IconChevronRightFill width={14} fill={vars.$scale.color.gray900} />
              </div>
            }
          >
            더보기
          </BoxButton>
        </div>
      )}
      {data.length < 1 && (
        <NoMoreContentsBox
          paddingTop={mode === 'homeSection' ? '3.125rem' : '9.375rem'}
          paddingBottom={mode === 'homeSection' ? '4.375rem' : '10.625rem'}
          infoText={'아직 일정이 없어요.\n가장 먼저 일정을 만들어보세요.'}
          button={
            <BoxButton onClick={buttonHandlers.onClick} variant="secondary" size="medium">
              {buttonHandlers.label}
            </BoxButton>
          }
        />
      )}
    </>
  );
};

export default MeetupCalendar;
