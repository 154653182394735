import {
  isHigherManager as checkIsHigherManager,
  withAsyncBoundary,
} from '@community-group/components';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import GroupDetailSeoulRunNoticeBanner from '@/features/SeoulRunPromotion/GroupDetailSeoulRunNoticeBanner';
import useGroupDetailSeoulRunNoticeBannerClosed from '@/features/SeoulRunPromotion/hooks/useGroupDetailSeoulRunNoticeBannerClosed';
import useIsSeoulRunPromotionLocation from '@/features/SeoulRunPromotion/hooks/useIsSeoulRunPromotionLocation';
import { useQueryFeatureFlag } from '@/hooks/useReadFeatureFlag';

import useGroupDetailHeroImageBannerClosed from '../hooks/useGroupDetailHeroImageBannerClosed';
import useGroupDetailMemberGradeNoticeBannerClosed from '../hooks/useGroupDetailMemberGradeNoticeBannerClosed';
import GroupDetailBlockedBanner from './GroupDetailBlockedBanner';
import GroupDetailHeroSliderBanner from './GroupDetailHeroSliderBanner';
import GroupDetailMemberGradeNoticeBanner from './GroupDetailMemberGradeNoticeBanner';
type Props = { groupId: string };

function GroupDetailBanner({ groupId }: Props) {
  const [
    { data: groupDetail },
    { data: groupMe },
    { data: isGroupBlockedGroupExperienceFlagEnabled },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupDetail(groupId),
      useQueryGroupMe({ groupId }),
      useQueryFeatureFlag('isGroupBlockedGroupExperienceFlag'),
    ],
  });

  const currentUserRole = useMemo(() => groupMe.role ?? 'none', [groupMe]);
  const isHigherManager = useMemo(() => checkIsHigherManager(currentUserRole), [currentUserRole]);
  const isSuperHost = useMemo(() => currentUserRole === 'superHost', [currentUserRole]);

  const [memberGradeNoticeBannerClosed] = useGroupDetailMemberGradeNoticeBannerClosed(groupId);
  const [heroImageBannerClosed] = useGroupDetailHeroImageBannerClosed(groupId);

  // TODO: 2025.04~05 한강 보물찾기런 참여 안내 배너 상태
  const [seoulRunNoticeBannerClosed] = useGroupDetailSeoulRunNoticeBannerClosed(groupId);
  const isSeoulRunPromotionLocation = useIsSeoulRunPromotionLocation({
    regionId: groupDetail.groupCreatedRegion.id,
  });

  if (isGroupBlockedGroupExperienceFlagEnabled && groupDetail.status === 'blocked') {
    return (
      <GroupDetailBlockedBanner
        groupId={groupId}
        userId={groupMe.id.toString()}
        isSuperHost={isSuperHost}
      />
    );
  }

  // 2025.04~05 한강 보물찾기런 참여 안내 배너
  if (!seoulRunNoticeBannerClosed && isSeoulRunPromotionLocation) {
    return <GroupDetailSeoulRunNoticeBanner groupId={groupId} />;
  }

  if (isSuperHost && !memberGradeNoticeBannerClosed) {
    // 모임장에게 멤버 등급제 배너 안내 노출
    return <GroupDetailMemberGradeNoticeBanner groupId={groupId} />;
  }

  // 히어로 슬라이더 관련 사진 첨부 or 게시글 작성 안내 배너
  if (isHigherManager && !heroImageBannerClosed) {
    return <GroupDetailHeroSliderBanner groupId={groupId} />;
  }

  return null;
}

export default withAsyncBoundary(GroupDetailBanner, {
  pendingFallback: null,
  rejectedFallback: <></>,
});
