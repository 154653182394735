import { Image as ImageType } from '@community-group/api/lib/group/models';
import { withAsyncBoundary } from '@community-group/components';

import ContentImageList from '@/components/common/ContentImageList';
import { getImagesForViewer } from '@/components/group/ImageViewerPage/utils/imageUtil';
import { useFlow } from '@/stackflow';

type Props = { images: ImageType[] };

const GroupMeetupDetailImageList = ({ images }: Props) => {
  const { push } = useFlow();

  const handleImageClick = (index: number) => {
    push('ImageViewerPage', {
      content: 'images',
      initialIndex: index.toString(),
      imageUrls: getImagesForViewer(images, 'large'),
      screenName: 'groupMeetupImageViewer',
    });
  };

  return <ContentImageList images={images} onImageClick={handleImageClick} />;
};

export default withAsyncBoundary(GroupMeetupDetailImageList, {});
