import { groupClient } from '@community-group/api';
import {
  ChallengeListPresentation,
  ChallengeStatusEnum,
  ChallengeSummaryPresentation,
} from '@community-group/api/lib/group/models';
import { InfiniteData, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useRead } from '@/shared/api/hooks/useRead';

import { GROUP_CHALLENGE_QUERY_KEY } from '../queries';

// 모임 상세 페이지에서 사용하는 챌린지 목록 조회
// groupId 기반 해당 모임에 만들어진 챌린지 목록 조회
export const useReadGroupChallengeList = ({
  id,
  status,
  initialCursor = undefined,
  limit = 30,
}: {
  id: number;
  status: ChallengeStatusEnum;
  initialCursor?: string;
  limit?: number;
}) => {
  const read = useRead(groupClient.api.GroupChallengeApi.apiV1GroupsGroupIdChallengesGet);

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: GROUP_CHALLENGE_QUERY_KEY.groupChallengeList(
        id.toString(),
        status,
        initialCursor ?? '',
        limit
      ),
      queryFn: ({ pageParam = initialCursor }) => {
        return read({
          groupId: Number(id),
          status,
          cursor: pageParam,
          limit,
        });
      },
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) =>
        data.pagination?.hasNext ? data?.pagination.endCursor : undefined,
      refetchInterval: 10000,
      select: selectPaginationFlatten,
    });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};

function selectPaginationFlatten(
  data: InfiniteData<{ data: ChallengeListPresentation }> | undefined
): ChallengeSummaryPresentation[] {
  if (!data) return [];
  return data.pages.flatMap((page) => page.data.contents ?? []);
}
