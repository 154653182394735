import { GroupFeedListResponse } from '@community-group/api/lib/group/models';
import { InfiniteData } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { queryClient } from '@/shared/api/instance';

import { GROUP_CHALLENGE_QUERY_KEY } from '../queries';

type Props = {
  type: 'like' | 'dislike';
  groupId?: string;
  postId?: string;
  challengeId?: number;
};
export const updateChallengeFeeds = async ({ type, groupId, postId, challengeId }: Props) => {
  if (!groupId || !challengeId || !postId) return;

  queryClient.setQueryData<InfiniteData<AxiosResponse<GroupFeedListResponse>>>(
    GROUP_CHALLENGE_QUERY_KEY.feeds(groupId.toString(), challengeId.toString()),
    (prev?: InfiniteData<AxiosResponse<GroupFeedListResponse>>) => {
      if (!prev || prev.pages?.length === 0) return prev;
      const updatedPages = prev.pages.map((page) => {
        const updatedItems = page.data.items.map((item) => {
          if (item.id !== parseInt(postId)) return item;

          return {
            ...item,
            emotion: {
              count: type === 'like' ? item.emotion.count + 1 : item.emotion.count - 1,
              myEmotion: type === 'like' ? 'like' : undefined,
            },
          };
        });
        return {
          ...page,
          data: {
            ...page.data,
            items: updatedItems,
          },
        };
      });
      return {
        ...prev,
        pages: updatedPages,
      };
    }
  );
};
