import type { Region } from '@community-group/api/lib/group/models';
import { Render, resizeImage, Typography } from '@community-group/components';

import { GroupActivityLog } from '@/components/common/GroupInfoListItem/ActivityLog';
import GroupLevel2DBadge from '@/components/common/GroupLevel/GroupLevel2DBadge';
import { GroupStage } from '@/domain/Group/utils/getGroupStage';
import { useFlow } from '@/stackflow';

import * as s from './GroupTitle.css';

type Props = {
  region?: Region;
  name?: string;
  memberCount?: number;
  lastActivatedAt?: string;
  profileImage?: string;
  groupLevel?: number;
  groupStage?: GroupStage;
};

function GroupTitle({
  name,
  memberCount,
  region,
  lastActivatedAt,
  profileImage,
  groupLevel,
  groupStage,
}: Props) {
  const { push } = useFlow();
  const existGroupLevel = Boolean(groupLevel && groupLevel !== 0);

  const handleGroupThumbnailClick = () => {
    if (!profileImage) return;
    const resizedImageUrl = resizeImage(profileImage, {
      quality: 'high',
      size: '1200x1200',
      type: 'outside',
    });

    push('ImageViewerPage', {
      content: 'images',
      imageUrls: JSON.stringify([resizedImageUrl]),
      initialIndex: '0',
      screenName: 'groupThumbnailImageViewer',
    });
  };

  return (
    <div className={s.Layout}>
      <div className={s.Thumbnail} onClick={handleGroupThumbnailClick}>
        <div className={s.BorderWrapper} />
        <img src={profileImage} alt="group-thumbnail" className={s.ProfileImage} />
        <Render condition={existGroupLevel}>
          <GroupLevel2DBadge
            level={groupLevel ?? 0}
            type="small"
            size={28}
            className={s.GroupLevelBadge}
          />
        </Render>
      </div>
      <div className={s.InformationSection}>
        <div className={s.TitleContainer}>
          <Typography typography="title3Bold" color="gray900" ellipsisAfterLines={1}>
            {name}
          </Typography>
        </div>
        <div className={s.DescriptionContainer}>
          <Render condition={Boolean(region?.town)}>
            <Typography typography="caption1Regular" color="gray600">
              {`${region?.town} `}
            </Typography>
          </Render>
          <Render condition={groupStage === 'junior'}>
            <Typography typography="caption1Regular" color="gray600">
              ∙ 신규 모임
            </Typography>
          </Render>
          <Render condition={groupStage === 'senior'}>
            <Typography typography="caption1Regular" color="gray600">
              ∙ 멤버 {memberCount}
            </Typography>
          </Render>
          <GroupActivityLog lastActivatedAt={lastActivatedAt} />
        </div>
      </div>
    </div>
  );
}

export default GroupTitle;
