import { GroupNotificationSettingV2 } from '@community-group/api/lib/group/models';
import { Dialog, Switch, useDialog } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { useEffect } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';

import { useGetNotifications } from '@/api/hooks/useGetNotifications';
import { usePatchNotification } from '@/api/hooks/usePatchNotifications';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import * as s from './style.css';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

type Props = {
  isSection?: boolean;
  groupId: string;
};

type NotificationCategoryName = keyof GroupNotificationSettingV2;
type NotificationCategoryTitle = '활동 알림' | '새 글 알림' | '일정 알림' | '멘션 알림';
type NotificationCategoryValue = {
  title: NotificationCategoryTitle;
  description: string;
  parentNotificationState: NotificationCategoryName;
};
const NotificationCategory = new Map<NotificationCategoryName, NotificationCategoryValue>([
  [
    'groupNotificationState',
    {
      title: '활동 알림',
      description: '작성한 글, 좋아요, 댓글 등 알림',
      parentNotificationState: 'daangnGroupNotificationState',
    },
  ],
  [
    'groupNewPostNotificationState',
    {
      title: '새 글 알림',
      description: '새로운 게시글 알림',
      parentNotificationState: 'daangnGroupNewPostNotificationState',
    },
  ],
  [
    'groupMeetupNotificationState',
    {
      title: '일정 알림',
      description: '새로운 일정, 관심, 출석체크 등 알림',
      parentNotificationState: 'daangnGroupMeetupNotificationState',
    },
  ],
  [
    'groupMentionNotificationState',
    {
      title: '멘션 알림',
      description: '글, 댓글에서 나를 언급할 경우 알림',
      parentNotificationState: 'daangnGroupMentionNotificationState',
    },
  ],
]);

const GroupNotificationSettingSection = ({ isSection = true, groupId }: Props) => {
  const { data: notificationState, refetch } = useGetNotifications(groupId, true);
  const { control, setValue } = useForm<FieldValues>({
    defaultValues: async () => {
      const { data } = await refetch();
      const notificationState = data?.data?.notification;

      return {
        groupNotificationState: Boolean(
          notificationState?.daangnGroupNotificationState === 'all_on' &&
            notificationState?.groupNotificationState === 'all_on'
        ),
        groupNewPostNotificationState: Boolean(
          notificationState?.daangnGroupNewPostNotificationState === 'all_on' &&
            notificationState?.groupNewPostNotificationState === 'all_on'
        ),
        groupMeetupNotificationState: Boolean(
          notificationState?.daangnGroupMeetupNotificationState === 'all_on' &&
            notificationState?.groupMeetupNotificationState === 'all_on'
        ),
        groupMentionNotificationState:
          notificationState?.daangnGroupMentionNotificationState === 'all_on' &&
          notificationState?.groupMentionNotificationState === 'all_on',
      };
    },
  });

  useEffect(() => {
    return () => {
      refetch();
    };
  }, [refetch]);

  const { open: openSnackbar } = useSnackbar();
  const turnOnSnackbar = (title: NotificationCategoryTitle) => {
    openSnackbar({
      message: `이 모임의 ${title}을 받아요`,
    });
  };

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutateAsync: mutateNotification } = usePatchNotification({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetch();
    },
  });

  const { open: openDialog, close: closeDialog } = useDialog();
  const turnOnGroupNotification = async (
    name: NotificationCategoryName,
    category: NotificationCategoryValue
  ) => {
    const isParentNotificationOn =
      notificationState?.[category.parentNotificationState] === 'all_on';

    // 상위 알림이 켜져 있으면 하위 알림을 켠다
    if (isParentNotificationOn) {
      await mutateNotification({
        id: Number(groupId),
        groupNotificationSettingForm: {
          [name]: 'all_on',
        },
      });
      turnOnSnackbar(category.title);
      return;
    }

    // 상위 알림이 꺼져 있으면 상위 알림을 켤지 확인하는 모달을 띄운다
    // 모달에서 알림 켜기 버튼을 누르면 그때 상위 알림과 하위 알림을 모두 켠다
    openDialog({
      element: (
        <Dialog
          title={`${category.title} 설정`}
          description={`전체 알림을 켜야 모임별 ${category.title}을 받을 수 있어요. 전체 모임의 ${category.title}을 켤까요?`}
          primaryActionLabel="알림 켜기"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            trackEvent({
              event: 'click_notification_turn_on_button',
              params: {
                from: isSection ? 'GroupSettingPage' : 'GroupNotificationSettingPage',
                name: name,
              },
              sample: true,
            });

            await mutateNotification({
              id: Number(groupId),
              groupNotificationSettingForm: {
                [name]: 'all_on',
                [category.parentNotificationState]: 'all_on',
              },
            });
            await closeDialog();
            turnOnSnackbar(category.title);
          }}
          onSecondaryAction={async () => {
            await closeDialog();
            setValue(name, false);
          }}
        />
      ),
    });
  };

  const handleSwitchChange = (name: NotificationCategoryName, value: boolean) => {
    trackEvent({
      event: 'click_notification_switch',
      params: {
        from: isSection ? 'GroupSettingPage' : 'GroupNotificationSettingPage',
        name: name,
        isSwitchOn: value,
      },
      sample: true,
    });

    if (!groupId) return;

    const category = NotificationCategory.get(name);
    if (!category) return;

    if (value) {
      turnOnGroupNotification(name, category);
      return;
    }

    mutateNotification({
      id: Number(groupId),
      groupNotificationSettingForm: {
        [name]: 'all_off',
      },
    });
  };

  return (
    <SettingList.Wrapper title={isSection ? '모임 알림' : ''}>
      <div className={s.NotificationWrapper}>
        {Array.from(NotificationCategory.entries()).map(([name, category]) => (
          <SettingList.Item
            key={name}
            title={category.title}
            description={category.description}
            renderRight={
              <Controller
                control={control}
                name={name}
                render={({ field }) => (
                  <Switch
                    onChange={(isSelected) => {
                      field.onChange(isSelected);
                      handleSwitchChange(field.name, isSelected);
                    }}
                    name={name}
                    isSelected={field.value}
                  />
                )}
              />
            }
          />
        ))}
      </div>
    </SettingList.Wrapper>
  );
};

export default GroupNotificationSettingSection;
