import { GroupLevelPresentation } from '@community-group/api/lib/group/models';
import { isHigherManager, isMember, isNotSuperHost } from '@community-group/components';
import { useActivity } from '@stackflow/react';
import { useCallback, useEffect, useRef } from 'react';

import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

const MAX_VISIT_COUNT = 3;
const INITIAL_LEVEL = 0;

interface BottomSheetHandlerProps {
  groupId: string;
}

// 레벨제도 안내 바텀시트 & 레벨 승급 안내 바텀시트 노출 조건 체크 핸들러
function useCheckGroupLevelBottomSheetHandler({ groupId }: BottomSheetHandlerProps) {
  const { isNewGroup } = useQueryParams();
  const isNew = isNewGroup === 'true';
  const { isTop } = useActivity();
  const { data: myInfo } = useReadGroupMe({ groupId });
  const { data: level, refetch } = useGetGroupLevel({ groupId: Number(groupId) });
  const { runningGrowthData = [] } = useGetRunningGrowthMission(groupId);

  const myRole = myInfo.role;
  const checked = useRef(false);
  const isOpenedBottomSheet = useRef(false);

  const {
    closeSuperHostNoticeGroupLevelBottomSheetList,
    shownGroupLevelUpBottomSheetLevel,
    visitGroupCounter,
    setVisitGroupCounter,
    showNoticeLevelBottomSheet,
    handleLevelUp,
  } = useCheckGroupLevelBottomSheetUtils({ groupId, isOpenedBottomSheet, level });

  // 슈퍼호스트 & 매니저 권한 바텀시트 우선순위
  const SuperHostAndManagerBottomSheetPriority = useCallback(() => {
    const prevShownLevel = shownGroupLevelUpBottomSheetLevel?.[groupId] ?? INITIAL_LEVEL;
    const isLevelUp = level && level.currentLevel > prevShownLevel;
    const hasSeenLevelNotice = closeSuperHostNoticeGroupLevelBottomSheetList;

    const isFirstVisit = isNew;

    // 첫 방문시에는 레벨업 케이스만 바텀시트 노출 가능
    if (isFirstVisit) {
      if (isLevelUp) {
        return handleLevelUp(level.currentLevel);
      }
      return false;
    }

    // 두번째 방문부터는 레벨 안내 바텀시트 노출 (아직 안봤다면)
    if (!hasSeenLevelNotice) {
      return showNoticeLevelBottomSheet();
    }

    // 레벨 안내 바텀시트를 봤고, 레벨업했다면 레벨업 바텀시트 노출
    if (isLevelUp) {
      return handleLevelUp(level.currentLevel);
    }

    return false;
  }, [
    closeSuperHostNoticeGroupLevelBottomSheetList,
    groupId,
    handleLevelUp,
    isNew,
    level,
    showNoticeLevelBottomSheet,
    shownGroupLevelUpBottomSheetLevel,
  ]);

  // 일반 멤버 바텀시트 우선순위
  const memberBottomSheetPriority = useCallback(
    ({ visitCount }: { visitCount: number }) => {
      const isFirstVisit = visitCount === 1;

      const prevShownLevel = shownGroupLevelUpBottomSheetLevel?.[groupId] ?? INITIAL_LEVEL;
      const isLevelUp = level && level.currentLevel > prevShownLevel;

      // 멤버 첫 방문시에는 바텀시트 노출 안됨
      // 초기 레벨 값을 알기 어렵기 때문에 레벨업 바텀시트도 노출시키지 않음
      if (isFirstVisit) return false;

      const hasSeenLevelNotice = closeSuperHostNoticeGroupLevelBottomSheetList;

      if (!hasSeenLevelNotice) {
        return showNoticeLevelBottomSheet();
      }

      if (isLevelUp) {
        return handleLevelUp(level.currentLevel);
      }

      return false;
    },
    [
      closeSuperHostNoticeGroupLevelBottomSheetList,
      groupId,
      handleLevelUp,
      level,
      showNoticeLevelBottomSheet,
      shownGroupLevelUpBottomSheetLevel,
    ]
  );

  /*
    노출 우선 순위
    - 전국러닝그로스 모임에서는 해당 바텀시트가 오픈되지 않음.
    - visitCount를 통해 첫번째 방문에서는 바텀시트 오픈하지 않음. 이후 방문부터 로직 체크
    - (신규 생성 해서 진입한 모임 상세라면) 2번 바텀시트 열람여부와 상관없이 2번 바텀시트 조건 체크
    - (신규 생성 해서 진입한 모임 상세가 아니라면) 1번 바텀시트 오픈 조건 체크
    - (모든 경우) 2번 바텀시트 오픈 조건 체크

    1번 바텀시트를 봤다면, 이후 등급 업 전까지는 2번 바텀시트가 노출되지 않음 
  */

  const checkGroupLevelBottomSheetHandler = useCallback(() => {
    // 호스트&운영진이 아니라면 바텀시트 보여주지 않음
    if (!isHigherManager(myRole) || runningGrowthData.length !== 0) {
      return false;
    }

    const currentVisitCount = visitGroupCounter?.[groupId] ?? 0;
    const newVisitCount = !checked.current ? currentVisitCount + 1 : currentVisitCount;

    // 첫번째 방문에는 바텀시트를 띄우지 않도록 조건 분기 하기 위한 페이지 방문 횟수 카운터
    // - 상세 진입 후 다른 페이지 왔다갔다 하는것은 visit counter 카운트 하지 않음
    // - 방문 횟수 3번 이상은 불필요한 데이터라 조건 추가

    if (!checked.current && currentVisitCount < MAX_VISIT_COUNT) {
      setVisitGroupCounter({
        ...visitGroupCounter,
        [groupId]: newVisitCount,
      });

      checked.current = true;
    }

    isOpenedBottomSheet.current = false;

    return SuperHostAndManagerBottomSheetPriority();
  }, [
    myRole,
    runningGrowthData,
    visitGroupCounter,
    groupId,
    SuperHostAndManagerBottomSheetPriority,
    setVisitGroupCounter,
  ]);

  useEffect(() => {
    // detail page 내 use sequential hook 호출 여부 처리
    if (checked.current && level?.currentLevel && isTop) checkGroupLevelBottomSheetHandler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level?.currentLevel, isTop]);

  // 모임 상세 접근시 level data refetch
  useEffect(() => {
    if (isTop) {
      refetch();
    }
  }, [isTop, refetch]);

  return checkGroupLevelBottomSheetHandler;
}

export default useCheckGroupLevelBottomSheetHandler;

const useCheckGroupLevelBottomSheetUtils = ({
  groupId,
  isOpenedBottomSheet,
  level,
}: {
  groupId: string;
  isOpenedBottomSheet: React.MutableRefObject<boolean>;
  level: GroupLevelPresentation | undefined;
}) => {
  const { push } = useFlow();

  // 레벨제도 안내 바텀시트
  const [
    closeSuperHostNoticeGroupLevelBottomSheetList,
    setCloseSuperHostNoticeGroupLevelBottomSheetList,
  ] = useStorage('closeSuperHostNoticeGroupLevelBottomSheetList', false);

  // 레벨 승급 안내 바텀시트
  const [shownGroupLevelUpBottomSheetLevel, setShownGroupLevelUpBottomSheetLevel] = useStorage(
    'shownGroupLevelUpBottomSheetLevel',
    {}
  );

  // 방문 횟수 카운터
  const [visitGroupCounter, setVisitGroupCounter] = useStorage('visitGroupCounter', {});

  const updateShownLevel = useCallback(
    (currentLevel: number) => {
      setShownGroupLevelUpBottomSheetLevel({
        ...shownGroupLevelUpBottomSheetLevel,
        [groupId]: currentLevel,
      });
    },
    [groupId, setShownGroupLevelUpBottomSheetLevel, shownGroupLevelUpBottomSheetLevel]
  );

  const showLevelUpBottomSheet = useCallback(
    (currentLevel: string) => {
      if (!isOpenedBottomSheet.current) {
        isOpenedBottomSheet.current = true;
        push('BottomSheet/LevelUpAlertBottomSheet', {
          groupId,
          level: currentLevel,
        });
      }
    },
    [groupId, isOpenedBottomSheet, push]
  );

  const showNoticeLevelBottomSheet = useCallback(() => {
    if (!isOpenedBottomSheet.current) {
      isOpenedBottomSheet.current = true;
      setCloseSuperHostNoticeGroupLevelBottomSheetList(true);

      if (level) {
        updateShownLevel(level.currentLevel);
      }

      push('BottomSheet/NoticeGroupLevelBottomSheet', { groupId });
      return true;
    }
    return false;
  }, [
    groupId,
    isOpenedBottomSheet,
    level,
    push,
    setCloseSuperHostNoticeGroupLevelBottomSheetList,
    updateShownLevel,
  ]);

  const handleLevelUp = useCallback(
    (currentLevel: number) => {
      updateShownLevel(currentLevel);
      showLevelUpBottomSheet(currentLevel.toString());
      return true;
    },
    [showLevelUpBottomSheet, updateShownLevel]
  );

  return {
    closeSuperHostNoticeGroupLevelBottomSheetList,
    setCloseSuperHostNoticeGroupLevelBottomSheetList,
    shownGroupLevelUpBottomSheetLevel,
    setShownGroupLevelUpBottomSheetLevel,
    visitGroupCounter,
    setVisitGroupCounter,
    showNoticeLevelBottomSheet,
    handleLevelUp,
  };
};
