import { GroupMeetupReviewCreateRequest } from '@community-group/api/lib/group/models';

export const groupMeetupReviewCreateRequestInitialValues: Partial<GroupMeetupReviewCreateRequest> =
  {
    reviewRating: undefined,
    reviewPost: {
      images: [],
      content: '',
    },
  };
