import {
  AsyncBoundary,
  isMember,
  useKeyboardSize,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useCallback, useMemo, useState } from 'react';

import { useGetMemberList } from '@/api/hooks/useGetMemberList';
import { Container } from '@/components/common/Container';
import EmptySection from '@/components/common/Empty';
import { LoadMoreMemberListContainer } from '@/components/common/LoadMoreContainer';
import MemberSearchBar from '@/components/common/MemberSearchBar';
import ProfileListItem from '@/components/common/Profile/ListItem';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useHandleDelegateSuperHost } from '@/domain/GroupProfile/utils/useHandleDelegateSuperHost';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import * as s from './memberDelegateSuperHost.css';

export type GroupSettingMemberDelegateSuperHostPageParams = Pick<PageParams, 'groupId'>;

const GroupSettingMemberDelegateSuperHostPage: ActivityComponentType<
  GroupSettingMemberDelegateSuperHostPageParams
> = () => {
  return (
    <AppScreen appBar={{ title: '모임장 위임' }}>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <DelegateSuperHostWrapper />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupSettingMemberDelegateSuperHostPage;

const DelegateSuperHostWrapper = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isFocusedSearchBar, setIsFocusedSearchBar] = useState(false);
  const { keyboardHeight } = useKeyboardSize();

  const { groupId } = usePathParams();
  const { data, hasNextPage } = useGetMemberList({ groupId, keyword: searchValue });

  const memberList = data?.pages.map(({ data }) => data.members);
  const flattenMemberList = useMemo(() => memberList?.flat(1) ?? [], [memberList]);

  const { data: me } = useReadGroupMe({ groupId });

  const { handleDelegateSuperHost } = useHandleDelegateSuperHost({ groupId, currentUser: me });

  const paddingBottomStyle = isFocusedSearchBar
    ? `calc(env(safe-area-inset-bottom) + ${keyboardHeight}px )`
    : 'env(safe-area-inset-bottom)';

  const FilteredMemberList = useCallback(() => {
    if (flattenMemberList.length <= 1) {
      return <EmptySection>위임할 수 있는 이웃이 없어요.</EmptySection>;
    }

    return (
      <ul>
        {flattenMemberList.map((member) => {
          if (member.id === me.id) return null;

          return (
            <li
              className={s.listItem}
              key={member.id}
              onClick={() => {
                handleDelegateSuperHost(member);
              }}
            >
              <ProfileListItem user={member} />
            </li>
          );
        })}
      </ul>
    );
  }, [flattenMemberList, me.id, handleDelegateSuperHost]);

  return (
    <div
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: paddingBottomStyle,
      }}
    >
      {isMember(me.role) && (
        <>
          <div
            style={{
              paddingTop: '0.5rem',
            }}
          >
            <MemberSearchBar
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setIsFocusedSearchBar={setIsFocusedSearchBar}
              placeholder="닉네임을 검색해보세요"
            />
          </div>
        </>
      )}
      <Container paddingX={0} paddingY={0}>
        <FilteredMemberList />
        {hasNextPage && <LoadMoreMemberListContainer searchValue={searchValue} />}
      </Container>
    </div>
  );
};
