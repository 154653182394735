import { useKeyboardSize } from '@community-group/components';
import {
  IconCheckmarkBallotboxLine,
  IconKeyboardChevronDownLine,
  IconLocationpinLine,
  IconPlusLine,
} from '@daangn/react-monochrome-icon';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { vars } from '@seed-design/design-token';
import React, { useEffect, useState } from 'react';

import { PollFormActivityParamsJSON } from '@/components/group/Poll/pages';
import { useBridge } from '@/contexts/Bridge';
import { useDeviceConfig } from '@/contexts/DeviceConfig';
import { usePlaceSearchClient } from '@/hooks/usePlaceSearchClient';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import { GroupPostFormProps } from '../GroupPostForm';
import AppBarNotice from './AppBarNotice';
import * as s from './index.css';
import PhotoButton from './PhotoButton';
import VideoButton from './VideoButton';

export const GroupPostFormAppBar = ({
  handleFocusTextarea,
  handleBlurTextarea,
  formHandler,
  mode = 'new',
  handleAddButton,
  mentionSuggestionsRef,
}: GroupPostFormProps & {
  handleFocusTextarea: () => void;
  handleBlurTextarea: () => void;
  mode?: 'new' | 'edit';
  handleAddButton?: () => void;
  mentionSuggestionsRef: React.RefObject<HTMLDivElement>;
}) => {
  const { postType, groupId = '0', from, calloutType } = useQueryParams();
  const { bridge } = useBridge();
  const { deviceConfig } = useDeviceConfig();
  const { keyboardHeight, isKeyboardOn } = useKeyboardSize(bridge);

  const { push } = useConnectedActions();
  const { openSearchPoiPage } = usePlaceSearchClient();

  const { setValue, watch, getValues } = formHandler;

  const handleLocationClick = async () => {
    if ((watch('poiStreams') ?? []).length > 0) {
      return bridge.openToast({
        toast: {
          body: '게시글에는 하나의 장소만 추가할 수 있어요.',
        },
      });
    }

    openSearchPoiPage({});
  };

  const handleMentionClick = () => {
    setValue('content', `${watch('content')} @`);

    handleFocusTextarea();
  };

  const handlePollClick = async () => {
    const poll = getValues('poll');
    const pollValues = (await push('GroupPollFormPage', {
      groupId,
      pollOptionValueJSONString: JSON.stringify({
        title: poll?.title ?? '',
        pollOptionTitles: poll?.options?.map((option) => option.title) ?? [],
        isMultiple: poll?.isMultiple ?? false,
      }),
    })) as PollFormActivityParamsJSON;

    setValue('poll', {
      title: pollValues.title,
      options: pollValues.pollOptionTitles.map((title) => ({ title })),
      isMultiple: pollValues.isMultiple,
    });
  };

  const [shownAddPhotoGuideHelpBubble, setShownAddPhotoGuideHelpBubble] = useState(false);

  const hidePhotoGuideHelpBubbleAfter3sec = () => {
    // 툴팁이 3초 후에 사라지도록 설정
    setTimeout(() => {
      setShownAddPhotoGuideHelpBubble(false);
    }, 3000);
  };

  useEffect(() => {
    const isHeroImageFunnelEntryUser = from === 'HeroImage' || calloutType === 'first-post-mission';

    if (isHeroImageFunnelEntryUser) {
      setTimeout(() => {
        setShownAddPhotoGuideHelpBubble(true);
        hidePhotoGuideHelpBubbleAfter3sec();
      }, 500);
    }
  }, []);

  return (
    <div
      className={s.Wrapper}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <AppBarNotice mode={mode} watch={watch} />

      <div
        className={s.AppBarWrapper({
          isOpenKeyboard: isKeyboardOn,
          isAndroid: deviceConfig.theme === 'android',
        })}
        ref={mentionSuggestionsRef}
        onClick={handleFocusTextarea}
      >
        <div className={s.LeftSide}>
          {mode === 'new' && postType !== 'meetupReview' && handleAddButton && (
            <button
              className={s.IconButton}
              onClick={(event) => {
                event.stopPropagation();
                handleBlurTextarea();
                handleAddButton();
                setShownAddPhotoGuideHelpBubble(false);
              }}
            >
              <IconPlusLine width={20} height={20} color={vars.$scale.color.gray600} />
            </button>
          )}

          <ul className={s.FeatureList}>
            <PhotoButton
              formHandler={formHandler}
              shownAddPhotoGuideHelpBubble={shownAddPhotoGuideHelpBubble}
              setShownAddPhotoGuideHelpBubble={setShownAddPhotoGuideHelpBubble}
            />
            <VideoButton formHandler={formHandler} groupId={groupId} />

            {mode === 'new' && (
              <button className={s.FeatureButton} onClick={handlePollClick}>
                <IconCheckmarkBallotboxLine
                  color={vars.$scale.color.gray600}
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              </button>
            )}

            <button className={s.FeatureButton} onClick={handleLocationClick}>
              <IconLocationpinLine width={24} height={24} color={vars.$scale.color.gray600} />
            </button>
          </ul>
        </div>
        {keyboardHeight > 0 && (
          <button
            className={s.KeyboardHidingButton}
            onClick={(event) => {
              event.stopPropagation();
              handleBlurTextarea();
            }}
          >
            <IconKeyboardChevronDownLine width={24} height={24} color={vars.$scale.color.gray600} />
          </button>
        )}
      </div>
    </div>
  );
};
