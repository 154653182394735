import { Spacing, Typography, useEffectOnce } from '@community-group/components';
import { useActivity } from '@stackflow/react';
import React, { useCallback } from 'react';
import { v4 } from 'uuid';

import ClappingGif from '@/assets/images/clapping.gif';

import ClapParticle, { ClickAnimationProps } from './ClapParticles';
import * as s from './style.css';

type Props = {
  totalCertifyCount: number;
};

const CLAP_PARTICLE_MAX_DURATION = 3000;
const CLAP_PARTICLE_COUNT = 10;
const CLAP_PARTICLE_RANDOM_DELAY = 1000;

const CertifyResultHeroSection = ({ totalCertifyCount }: Props) => {
  const { isTop } = useActivity();
  const [Particle, setParticle] = React.useState<Omit<ClickAnimationProps, 'onDestroy'>[]>([]);
  const handleDoubleClick = useCallback(() => {
    for (let i = 0; i < CLAP_PARTICLE_COUNT; i++) {
      setTimeout(() => {
        const uuid = `${v4()}-${i}-${new Date().getTime()}`;
        setParticle((prev) => [
          ...prev.filter((el) => {
            const isDiff15Sec = new Date().getTime() - Number(el.id) > CLAP_PARTICLE_MAX_DURATION;
            if (isDiff15Sec) return false;
            return true;
          }),
          {
            id: uuid,
            createdAt: new Date().getTime().toString(),
            posX: 45 + Math.random() * (window.innerWidth - 90),
            posY: window.innerHeight,
          },
        ]);
      }, Math.random() * CLAP_PARTICLE_RANDOM_DELAY);
    }
  }, []);

  React.useEffect(() => {
    if (!isTop) {
      setParticle([]);
    }
    return () => {
      setParticle([]);
    };
  }, [isTop]);

  useEffectOnce(() => {
    handleDoubleClick();
  });

  return (
    <div className={s.Container}>
      <img
        src={ClappingGif}
        alt="박수"
        className={s.ClappingImage}
        onTouchStart={handleDoubleClick}
      />
      <Spacing size={16} />
      <Typography typography="title1Bold" color="gray900">
        챌린지 {totalCertifyCount}일차 인증 완료
      </Typography>
      <Spacing size={8} />
      <Typography typography="subtitle1Regular" color="gray600">
        오늘도 해내셨군요!
      </Typography>
      {Particle.map((item) => {
        return (
          <ClapParticle
            key={item.id}
            id={item.id}
            createdAt={item.createdAt}
            posX={item.posX}
            posY={item.posY}
            onDestroy={(id) => {
              setParticle((prev) => prev.filter((el) => el.id !== id));
            }}
          />
        );
      })}
    </div>
  );
};

export default CertifyResultHeroSection;
