import { GROUP_DETAIL_QUERY_KEY } from '@/domain/GroupDetail/queries';
import { queryClient } from '@/shared/api/instance';

type Params = {
  groupId: string;
};

/*
 *  모임 설정 페이지에서 사용하는 쿼리를 한번에 리페치하는 함수
 */
export const refetchGroupSetting = ({ groupId }: Params) => {
  queryClient.refetchQueries({
    queryKey: GROUP_DETAIL_QUERY_KEY.base(groupId),
  });
};
