import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { useTransparentNavbarState } from '@/components/common/TransparentNavScreen/TransparentNavScreenContext';
import { GROUP_DETAIL_ACTIVE_TAB, useQueryParams } from '@/stackflow/hooks/useQueryParams';

import { GroupDetailScrollProvider } from './GroupDetailScrollContext';

type Props = {
  selectedBoardKey: number;
  setSelectedBoardKey: (key: number, scrollToTop?: boolean) => void;
  boardChipAutoScroll: boolean;
  activeTabKey: GROUP_DETAIL_ACTIVE_TAB;
  setActiveTabKey: (key: GROUP_DETAIL_ACTIVE_TAB) => void;
};

const Context = createContext<Props | undefined>(undefined);

export const GroupDetailContext = ({ children }: { children: ReactNode }) => {
  const { activeTab = 'home', boardCategoryKey = '0' } = useQueryParams();
  const [selectedBoardKey, setSelectedBoardKey] = useState(() => Number(boardCategoryKey));
  const [boardChipAutoScroll, setBoardChipAutoScroll] = useState(false);

  // V1 상세 탭
  const [activeTabKey, setActiveTabKey] = useState<GROUP_DETAIL_ACTIVE_TAB>(activeTab || 'home'); // 'feed' | 'meetups' | 'photos'

  const { scrollRef } = useTransparentNavbarState();
  const updateSelectedBoardKey = useCallback(
    (key: number, scrollToTop?: boolean) => {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      scrollToTop && scrollRef.current?.scrollTo({ top: 0 });
      setSelectedBoardKey(key);
      setTimeout(async () => {
        setBoardChipAutoScroll(true);
        await delay(400);
        setBoardChipAutoScroll(false);
      }, 300);
    },
    [scrollRef]
  );

  const controls = useMemo(
    () => ({
      selectedBoardKey,
      setSelectedBoardKey: updateSelectedBoardKey,
      boardChipAutoScroll,
      activeTabKey,
      setActiveTabKey,
    }),
    [selectedBoardKey, updateSelectedBoardKey, boardChipAutoScroll, activeTabKey]
  );

  return (
    <Context.Provider value={controls}>
      <GroupDetailScrollProvider>{children}</GroupDetailScrollProvider>
    </Context.Provider>
  );
};

export const useGroupDetailContext = () => {
  const controls = useContext(Context);

  if (controls === undefined) {
    throw new Error('GroupDetailContext 안에서 사용해주세요.');
  }

  return controls;
};
