import { ActivityComponentType } from '@stackflow/react';

import GroupGuideBookSettingSection from '@/features/GroupSetting/GroupGuideBook';
import { AppScreen } from '@/stackflow/components/AppScreen';

const GroupGuideBookSettingPage: ActivityComponentType = () => {
  return (
    <AppScreen appBar={{ title: '운영 가이드북' }}>
      <GroupGuideBookSettingSection />
    </AppScreen>
  );
};

export default GroupGuideBookSettingPage;
