import {
  GroupCurrentUser,
  GroupDetailPresentation,
  GroupMemberRoleEnum,
  GroupMemberSummaryWithActivityPresentation,
} from '@community-group/api/lib/group/models';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  useBottomSheet,
} from '@community-group/components';
import { useCallback } from 'react';

import MemberSummarySection from '@/components/group/DetailV2/components/GroupDetailMemberSummary/MemberSummarySection';
import GroupDetailSummaryMoreButton from '@/components/group/DetailV2/components/GroupDetailSummaryMoreButton';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { openGroupProfileReport } from '@/utils/link';

import * as s from './index.css';

interface Props {
  groupId: string;
  members: GroupMemberSummaryWithActivityPresentation[];
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
  hideMemberCount?: boolean;
}

const MAX_MEMBER_COUNT = 3;

const MemberSection = ({ groupId, members, group, currentUser, hideMemberCount }: Props) => {
  const { push } = useFlow();

  const bottomSheet = useBottomSheet();
  const memberCount = group.memberCount;
  const filteredMembers = members.slice(0, MAX_MEMBER_COUNT);

  const handleMemberListItemClick = useCallback(
    (memberId: number, memberRole: GroupMemberRoleEnum) => (e) => {
      e.stopPropagation();
      e.preventDefault();

      trackEvent({
        event: 'click_member_profile',
        params: {
          groupId: groupId,
          groupName: group.name,
          clickedUserId: memberId,
          clickedUserRole: memberRole,
          groupCategoryName: group.category.name,
          isGroupCategoryOn: group.isBoardManaged,
          role: currentUser.role,
          version: '2',
        },
        sample: true,
      });

      push('GroupUserProfileDetailPage', {
        groupId,
        userId: memberId.toString(),
      });
    },
    [group.category.name, group.isBoardManaged, group.name, groupId, currentUser.role, push]
  );
  const handleMemberActionButtonClick = useCallback(
    (memberId) => (e) => {
      e.stopPropagation();
      e.preventDefault();

      const handleReportMenuClick = async () => {
        await bottomSheet.closeAsync();
        openGroupProfileReport({
          groupId,
          userId: memberId.toString() ?? '',
        });
      };
      bottomSheet.open({
        element: (
          <ActionSheet
            bottomButton={{
              label: '닫기',
            }}
          >
            <ActionList>
              <ActionListItem onClick={handleReportMenuClick}>신고</ActionListItem>
            </ActionList>
          </ActionSheet>
        ),
      });
    },
    [bottomSheet, groupId]
  );

  return (
    <section className={s.Wrapper}>
      <MemberSummarySection
        totalCount={memberCount}
        members={filteredMembers}
        currentUserId={currentUser.id}
        currentUserRole={currentUser.role}
        onMemberListItemClick={handleMemberListItemClick}
        onMemberActionButtonClick={handleMemberActionButtonClick}
        hideMemberCount={hideMemberCount}
      >
        <GroupDetailSummaryMoreButton
          group={group}
          summaryType="members"
          currentUserRole={currentUser.role}
        >
          더보기
        </GroupDetailSummaryMoreButton>
      </MemberSummarySection>
    </section>
  );
};

export default MemberSection;
