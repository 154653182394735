import { Render, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import {
  IconCalendarFill,
  IconCheckmarkFlowerFill,
  IconLocationpinFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import * as s from './GroupMeetupInfoList.css';

type Props = {
  meetupDateText?: string;
  meetupLocationText?: string;
  canJoinAnyMember?: boolean;
};

const GroupMeetupInfoList = ({ meetupDateText, meetupLocationText, canJoinAnyMember }: Props) => {
  if (!meetupDateText && !meetupLocationText && canJoinAnyMember) return null;

  return (
    <ul className={s.MeetupInfoList}>
      <Render condition={Boolean(meetupDateText)}>
        <li className={s.MeetupInfoItem}>
          <IconCalendarFill size={16} color={vars.$scale.color.gray600} />
          <VerticalSpacing size={8} />
          <Typography typography="label2Regular" color="gray800" ellipsisAfterLines={1}>
            {meetupDateText}
          </Typography>
        </li>
      </Render>
      <Render condition={Boolean(meetupLocationText)}>
        <li className={s.MeetupInfoItem}>
          <IconLocationpinFill size={16} color={vars.$scale.color.gray600} />
          <VerticalSpacing size={8} />
          <Typography typography="label2Regular" color="gray800" ellipsisAfterLines={1}>
            {meetupLocationText}
          </Typography>
        </li>
      </Render>
      <Render condition={!canJoinAnyMember}>
        <li className={s.MeetupInfoItem}>
          <IconCheckmarkFlowerFill size={16} color={vars.$scale.color.gray600} />
          <VerticalSpacing size={8} />
          <Typography typography="label2Regular" color="gray800" ellipsisAfterLines={1}>
            정회원 이상 참여 가능
          </Typography>
        </li>
      </Render>
    </ul>
  );
};

export default GroupMeetupInfoList;
