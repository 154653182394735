import { ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import FeedMeetupList from '../components/GroupDetailFeed/FeedMeetupList';

type Params = Pick<PageParams, 'groupId'>;

const GroupDetailMeetupListPage: ActivityComponentType<Params> = () => {
  const { groupId } = usePathParams();
  const { data: group } = useReadGroupDetail(groupId);

  return (
    <AppScreen appBar={{ title: '일정' }}>
      {group && (
        <FeedMeetupList
          groupId={groupId}
          shouldSetSubNickname={group.subNicknameSetting.isSettingOn}
        />
      )}
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupDetailMeetupListPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
