import { AsyncBoundary, Render, useKeyboardSize, ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useState } from 'react';

import MemberSearchBar from '@/components/common/MemberSearchBar';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { isMember } from '@/utils/role';

import { MemberListAppScreen } from '../components/MemberListAppScreen';
import MemberListForMember from './MemberListForMember';

export type GroupMemberListPageParams = Pick<PageParams, 'groupId'>;

const GroupMemberListPage: ActivityComponentType<GroupMemberListPageParams> = () => {
  return (
    <MemberListAppScreen>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <MemberListWrapper />
      </AsyncBoundary>
    </MemberListAppScreen>
  );
};

const MemberListWrapper = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isFocusedSearchBar, setIsFocusedSearchBar] = useState(false);
  const { keyboardHeight } = useKeyboardSize();

  const { groupId } = usePathParams();

  const [{ data: me }] = useSuspenseQueries({
    queries: [useQueryGroupMe({ groupId }), useQueryGroupMemberGradeStatus(groupId)],
  });

  useEnterTrackEvent({
    event: 'enter_member_list',
    params: { groupId },
    sample: true,
  });

  return (
    <div
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: isFocusedSearchBar
          ? `calc(env(safe-area-inset-bottom) + ${keyboardHeight}px )`
          : 'env(safe-area-inset-bottom)',
      }}
    >
      <Render condition={isMember(me.role)}>
        <div style={{ paddingTop: '0.5rem' }}>
          <MemberSearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setIsFocusedSearchBar={setIsFocusedSearchBar}
            placeholder="닉네임을 검색해보세요"
          />
        </div>
      </Render>
      <MemberListForMember groupId={groupId} searchValue={searchValue} />
    </div>
  );
};

export default GroupMemberListPage;
