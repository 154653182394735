import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { isSuperHost, Typography } from '@community-group/components';
import { IconPictureLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { useGetGroupPhotoList } from '@/api/hooks/useGetGroupPhotoList';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import { getInfinitiveImagesFlatList } from '../utils/imageUtil';
import GroupDetailHeroFirstSlider from './GroupDetailHeroFirstSlider';
import GroupDetailHeroLastSlider from './GroupDetailHeroLastSlider';
import * as s from './GroupDetailHeroSlider.css';
import ImageViewerSlider, { ImageViewerImageListType } from './ImageViewerSlider';

export type AlbumImageViewerProps = {
  groupId: string;
  initialIndex: number;
  role: GroupMemberRoleEnum;
  onlyPublic?: boolean;
};

// 슬라이드 이미지 갯수
export const SLIDER_IMAGE_COUNT = 6;

// 모임 상세 상단 히어로 슬라이더
const GroupDetailHeroSlider = ({
  groupId,
  initialIndex,
  role,
  onlyPublic,
}: AlbumImageViewerProps) => {
  const { push } = useFlow();
  const [currentImage, setCurrentImage] = React.useState<ImageViewerImageListType>();

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useGetGroupPhotoList({
    groupId,
    onlyPublic: onlyPublic ? true : false,
  });

  const photoList = useMemo(() => getInfinitiveImagesFlatList(data), [data]).slice(
    0,
    SLIDER_IMAGE_COUNT
  );
  const flattenPhotoList = photoList.map((el) => {
    return {
      id: el.image.id,
      imageUrl: el.image.medium,
      postId: el.post.id,
      isPinned: el.isPinned,
    };
  });

  const handleMoveToAlbum = useCallback(() => {
    trackEvent({
      event: 'click_hero_slider_gallery',
      params: {
        groupId,
      },
      sample: true,
    });

    push('GroupDetailAlbumPage', {
      groupId,
    });
  }, [groupId, push]);

  const handleMoveToAlbumViewer = useCallback(
    (_, index: number) => {
      trackEvent({
        event: 'click_hero_slider',
        params: {
          groupId,
          index,
        },
        sample: true,
      });

      push('ImageViewerPage', {
        content: 'album',
        groupId: groupId,
        initialIndex: index.toString(),
        screenName: 'groupDetailAlbumImageViewer',
      });
    },
    [groupId, push]
  );

  const ImageData = useMemo(() => {
    return {
      imageList: flattenPhotoList,
      initialIndex,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
    };
  }, [fetchNextPage, flattenPhotoList, hasNextPage, initialIndex, isFetchingNextPage]);

  const hasPinnedImage = flattenPhotoList?.some((photoImage) => photoImage?.isPinned);
  const isViewLastSlide = currentImage?.id === undefined;
  const isViewFirstSlide = flattenPhotoList.length >= 1 && !hasPinnedImage && isSuperHost(role);
  const notMember = role === 'none';

  return (
    <div className={clsx(s.Wrapper({ notMember }), 'group-detail-hero-slider')}>
      <ImageViewerSlider
        onClickImage={handleMoveToAlbumViewer}
        imageData={ImageData}
        setCurrentImage={setCurrentImage}
        renderBottom={() => (
          <>
            <div className={s.TopGradation} />
            <div className={s.BottomGradation} />
          </>
        )}
        objectFit="cover"
        options={{
          isVirtual: false,
          isLazyLoad: false,
        }}
        firstSliderChildren={
          isViewFirstSlide && (
            <SwiperSlide key={'first-slide'} virtualIndex={-1}>
              <GroupDetailHeroFirstSlider
                photoList={photoList}
                groupId={groupId}
                notMember={notMember}
              />
            </SwiperSlide>
          )
        }
        lastSliderChildren={
          <SwiperSlide key={'last-slide'} virtualIndex={photoList.length}>
            <GroupDetailHeroLastSlider
              photoList={photoList}
              groupId={groupId}
              notMember={notMember}
            />
          </SwiperSlide>
        }
      />
      {!isViewLastSlide && !isViewFirstSlide && (
        <div className={s.MoveToPostAlbumWrapper} onClick={handleMoveToAlbum}>
          <IconPictureLine size={14} color={vars.$static.color.staticWhite} />
          <Typography typography="caption2Regular" color="staticWhite">
            앨범보기
          </Typography>
        </div>
      )}
    </div>
  );
};

export default React.memo(GroupDetailHeroSlider);
