import { BottomSheetProvider, DialogProvider, SnackbarProvider } from '@community-group/components';
import { ReactNode } from 'react';

export const UIOverlayProvider = (props: { children: ReactNode }) => {
  return (
    <SnackbarProvider offset="auto">
      <DialogProvider>
        <BottomSheetProvider>{props.children}</BottomSheetProvider>
      </DialogProvider>
    </SnackbarProvider>
  );
};

export { _useSnackbar as useSnackbar } from './useSnackbar';
