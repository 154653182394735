import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_LEVEL_QUERY_KEY } from '../queries';

export const useQueryGroupLevel = (groupId: string) => {
  const read = useAsyncRead(groupClient.api.GroupLevelApi.apiV1GroupsGroupIdLevelGet);
  return {
    queryKey: GROUP_LEVEL_QUERY_KEY.base(groupId),
    queryFn: () => read({ groupId: Number(groupId) }),
    refetchInterval: 1000 * 60 * 5, // 5분마다 갱신
  };
};

export const useReadGroupLevel = (groupId: string) => {
  const query = useQueryGroupLevel(groupId);
  return useSuspenseQuery({ ...query });
};

export const useQueryNullableGroupLevel = ({ groupId }: { groupId?: string }) => {
  const read = useAsyncRead(groupClient.api.GroupLevelApi.apiV1GroupsGroupIdLevelGet);
  return {
    queryKey: groupId ? GROUP_LEVEL_QUERY_KEY.base(groupId) : [],
    queryFn: () => (groupId ? read({ groupId: Number(groupId) }) : null),
    refetchInterval: 1000 * 60 * 5, // 5분마다 갱신
  };
};

export const useReadNullableGroupLevel = (groupId?: string) => {
  const query = useQueryNullableGroupLevel({ groupId });
  return useSuspenseQuery({ ...query });
};
