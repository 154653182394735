import { ActionableCallout, Typography, withAsyncBoundary } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { useSuspenseQueries } from '@tanstack/react-query';
import { cubicBezier, motion } from 'framer-motion';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import MemberSearchBar from '@/components/common/MemberSearchBar';
import FakeSearchBar from '@/components/common/MemberSearchBar/FakeSearchBar';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import { useSettingHandler } from '@/features/GroupSetting/hooks/useSettingHandler';
import { useQueryXproperty } from '@/hooks/useXproperty';
import { trackEvent } from '@/utils/analytics';
import { openGroupSurvey } from '@/utils/link';

import * as s from './style.css';

type Props = {
  groupId: string;
  setShowSearchResult: Dispatch<SetStateAction<boolean>>;
};
const GroupSettingSearch = ({ groupId, setShowSearchResult }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const isSearchKeywordEmpty = useMemo(() => searchValue.trim() === '', [searchValue]);
  const [{ data: currentUser }, { data: groupDetail }, { data: permissions }, { data: xProperty }] =
    useSuspenseQueries({
      queries: [
        useQueryGroupMe({ groupId }),
        useQueryGroupDetail(groupId),
        useQueryGroupPermissions(groupId),
        useQueryXproperty(),
      ],
    });
  const role = currentUser.role;

  const { handleItemClick, totalSetting } = useSettingHandler({
    role,
    groupId,
    groupDetail,
    userId: currentUser.id.toString(),
    permissions,
    xProperty,
  });

  const matchedSettingConfig = totalSetting.filter((item) => {
    const trimmedSearch = searchValue.trim();
    return trimmedSearch !== '' && new RegExp(trimmedSearch, 'i').test(item.displayText);
  });

  // 검색어를 포함하는 텍스트에서 해당 부분을 하이라이트 처리
  const highlightText = (text: string, highlight: string) => {
    if (!highlight.trim()) return text;

    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ ...vars.$semantic.typography.subtitle1Bold }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  useEffect(() => {
    setShowSearchResult(!isSearchKeywordEmpty);
  }, [isSearchKeywordEmpty, matchedSettingConfig, setShowSearchResult]);

  const handleSearchItemClick = useCallback(
    (item: (typeof matchedSettingConfig)[number]) => {
      handleItemClick(item);
      trackEvent({
        event: 'click_group_setting_search_item',
        params: {
          groupId,
          name: item.displayText,
          groupName: groupDetail.name,
          categoryId: groupDetail.category.id,
          categoryName: groupDetail.category.name,
          role: currentUser.role,
        },
      });
    },
    [handleItemClick, groupId, groupDetail, currentUser.role]
  );

  const renderSearchResult = useMemo(() => {
    if (isSearchKeywordEmpty) return null;
    if (!matchedSettingConfig.length)
      return (
        <div className={s.NoSearchWrapper}>
          <div className={s.NoSearchResult}>
            검색 결과가 없어요.
            <br />
            다른 단어로 검색해보세요.
          </div>
          <ActionableCallout width="100%" onClick={openGroupSurvey}>
            <Typography typography="bodyL2Regular">
              <span style={{ ...vars.$semantic.typography.bodyL2Bold }}>설문 </span> 원하는 기능이
              없다면 제안해주세요.
            </Typography>
          </ActionableCallout>
        </div>
      );
    return (
      <>
        {matchedSettingConfig.map((item) => (
          <div
            className={s.ListItem}
            key={item.displayText}
            onClick={() => handleSearchItemClick(item)}
          >
            <Typography typography="subtitle1Regular">
              {highlightText(item.displayText, searchValue)}
            </Typography>
          </div>
        ))}
      </>
    );
  }, [isSearchKeywordEmpty, matchedSettingConfig, searchValue, handleSearchItemClick]);

  return (
    <motion.div
      style={{ position: 'relative' }}
      key="search-result"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: cubicBezier(0.4, 0, 0.2, 1) } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: cubicBezier(0.4, 0, 0.2, 1) } }}
    >
      <MemberSearchBar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder="기능 검색"
        shouldTrim={false}
      />
      <div className={s.Wrapper} style={{ display: isSearchKeywordEmpty ? 'none' : 'flex' }}>
        {renderSearchResult}
      </div>
    </motion.div>
  );
};

export default withAsyncBoundary(GroupSettingSearch, {
  pendingFallback: <FakeSearchBar placeholder="기능 검색" />,
  rejectedFallback: <></>,
});
