import { AsyncBoundary, ViewLoader } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';

import GroupJoinManageSettingSection from '@/features/GroupSetting/GroupJoinManage';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupJoinSettingPageParams = Pick<PageParams, 'groupId'>;

const GroupJoinSettingPage: ActivityComponentType<GroupJoinSettingPageParams> = () => {
  const { groupId } = usePathParams();

  return (
    <AppScreen
      appBar={{
        title: '가입 방식 관리',
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <GroupJoinManageSettingSection groupId={groupId} />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupJoinSettingPage;
