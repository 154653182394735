import {
  GroupMeetupReviewCreateRequest,
  MediaUploadForm,
} from '@community-group/api/lib/group/models';
import {
  BoxButton,
  FileUploadState,
  ImageFieldItem,
  ImageForm,
  VideoFieldItem,
} from '@community-group/components';
import { IconCamcorderLine, IconCameraLine } from '@daangn/react-monochrome-icon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useBridge } from '@/contexts/Bridge';
import { useHandleUploadImages } from '@/features/ChallengeCreate/hooks/useHandleUploadImages';
import { ImageUploadState } from '@/features/ChallengeCreate/utils/fileUpload';
import { useSetWrittenMeetupReviewMedias } from '@/features/GroupMeetupReview/hooks/useSetWrittenMeetupReviewMedias';
import useGroupHandleVideoUpload from '@/shared/hooks/useGroupHandleVideoUpload';

import * as s from './style.css';

type Props = {
  groupId: string;
  meetupId: string;
  handleMediaUploading: (isUploading: boolean) => void;
};
const GroupMeetupReviewFormMediasField = ({ groupId, meetupId, handleMediaUploading }: Props) => {
  const { bridge } = useBridge();
  const { setValue } = useFormContext<GroupMeetupReviewCreateRequest>();
  const [images, setImages] = useState<ImageUploadState[]>([]);
  const [videoFiles, setVideoFiles] = useState<FileUploadState[]>([]);

  const { handleVideoFileSelection, retryVideoUpload, cancelVideoUpload } =
    useGroupHandleVideoUpload({
      uploadedImagesCount: images.length,
      externalOnValidationErrorHandler: (error) => {
        bridge.openToast({
          toast: {
            body: error.message.description ?? '사진, 동영상은 최대 10장까지 첨부할 수 있어요.',
          },
        });
      },
      externalStateSelector: {
        externalState: videoFiles,
        externalStateHandler: (updater) => setVideoFiles(updater),
      },
    });

  const contentLength = useMemo(
    () => images.length + videoFiles.length,
    [images.length, videoFiles.length]
  );
  const { pickImages, retryUploadImage } = useHandleUploadImages({
    originImages: images,
    setImages: (images) => setImages(images),
    externalValidateImages: (blobs) => {
      const newImagesLength = blobs.length;
      if (contentLength >= 10) {
        return { isValid: false, message: '사진, 동영상은 최대 10장까지 첨부할 수 있어요.' };
      }
      if (contentLength + newImagesLength > 10) {
        return {
          isValid: true,
          message: '사진, 동영상은 최대 10장까지 첨부할 수 있어요.',
          blobs: blobs.slice(0, 10 - contentLength),
        };
      }
      return { isValid: true, blobs };
    },
  });

  const handleImageClick = async (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    await pickImages();
  };

  const handleImageRemove = useCallback(
    (targetId: string) => {
      setImages(images.filter(({ id }) => id !== targetId));
    },
    [images]
  );

  const handleInitialImages = useCallback((images: ImageUploadState[]) => setImages(images), []);

  const handleInitialVideos = useCallback((videos: FileUploadState[]) => setVideoFiles(videos), []);

  useSetWrittenMeetupReviewMedias({
    groupId,
    meetupId,
    handleInitialImages,
    handleInitialVideos,
  });

  useEffect(() => {
    const currentImages = images
      .filter(({ id }) => !id.includes('temp') && !id.includes('error'))
      .map<MediaUploadForm>(({ id }: ImageForm) => ({ id, type: 'image' }));

    const currentVideos = videoFiles
      .filter(({ uploadedVideoInfo }) => uploadedVideoInfo !== undefined)
      .map(
        ({ uploadedVideoInfo }) => ({ id: uploadedVideoInfo?.id, type: 'video' } as MediaUploadForm)
      );

    setValue('reviewPost.medias', [...currentImages, ...currentVideos]);
  }, [images, videoFiles, setValue]);

  useEffect(() => {
    handleMediaUploading(videoFiles.some(({ status }) => status === 'uploading'));
  }, [videoFiles, handleMediaUploading]);

  return (
    <div className={s.Wrapper}>
      <div className={s.ButtonWrapper}>
        <BoxButton
          variant="secondary"
          prefix={<IconCameraLine />}
          width="100%"
          onClick={handleImageClick}
        >
          사진 추가
        </BoxButton>
        <BoxButton
          variant="secondary"
          prefix={<IconCamcorderLine />}
          width="100%"
          onClick={handleVideoFileSelection}
        >
          동영상 추가
        </BoxButton>
      </div>
      <div className={s.ImageWrapper} style={{ paddingBottom: contentLength > 0 ? '1rem' : '0' }}>
        {videoFiles.map((video) => (
          <VideoFieldItem
            key={video.id}
            video={video}
            onRemoveClick={cancelVideoUpload}
            onRetryClick={retryVideoUpload}
            className={s.ImageField}
          />
        ))}
        {images.map((image: ImageUploadState) => (
          <ImageFieldItem
            className={s.ImageField}
            key={image.id}
            imageId={image.id}
            image={image.url}
            onRemoveClick={handleImageRemove}
            onRetryClick={retryUploadImage}
          />
        ))}
      </div>
    </div>
  );
};

export default GroupMeetupReviewFormMediasField;
