import { groupClient } from '@community-group/api';
import { GroupMeetupDetailPresentation } from '@community-group/api/lib/group/models';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';
import { useRead } from '@/shared/api/hooks/useRead';

import { GROUP_URL } from '../base/group';

export const getGroupMeetupDetailPath = (groupId: string, meetupId: string) =>
  `${GROUP_URL}/${groupId}/meetups/${meetupId}`;

type Params = {
  groupId: string;
  meetupId: string;
};

type NullableParams = {
  groupId: string;
  meetupId?: string;
};

export const useQueryGroupMeetupDetail = ({ groupId, meetupId }: Params) => {
  const read = useAsyncRead(groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdGet);
  return {
    queryKey: [getGroupMeetupDetailPath(groupId, meetupId)],
    queryFn: () =>
      read({
        groupId: Number(groupId),
        meetupId: Number(meetupId),
      }).then((res) => res.groupMeetup as GroupMeetupDetailPresentation),
  };
};

export const useReadGroupMeetupDetail = ({ groupId, meetupId }: Params) => {
  const query = useQueryGroupMeetupDetail({ groupId, meetupId });
  return useSuspenseQuery({ ...query });
};

export const useReadGroupMeetupDetailNoSuspense = ({ groupId, meetupId }: Params) => {
  const read = useRead(groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdGet);

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [getGroupMeetupDetailPath(groupId, meetupId)],
    queryFn: () => read({ groupId: Number(groupId), meetupId: Number(meetupId) }),
    enabled: !!meetupId,
    throwOnError: true,
  });

  const response = data?.data.groupMeetup as GroupMeetupDetailPresentation;

  return { data: response, isLoading, isError, refetch };
};

export const useQueryNullableGroupMeetupDetail = ({ groupId, meetupId }: NullableParams) => {
  const read = useAsyncRead(groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdGet);

  return {
    queryKey: meetupId ? [getGroupMeetupDetailPath(groupId, meetupId)] : [],
    queryFn: () => {
      if (!meetupId) return null;
      return read({
        groupId: Number(groupId),
        meetupId: Number(meetupId),
      }).then((res) => res.groupMeetup);
    },
  };
};

/**
 * meetupId가 nullable한 상태일 때 사용하는 useReadGroupMeetupDetail
 * meetupId가 존재하는 경우 기존의 훅과 동일한 쿼리키를 바라봐요.
 * @param groupId
 * @param meetupId
 * @returns GroupMeetupDetailPresentation
 */
export const useReadNullableGroupMeetupDetail = ({ groupId, meetupId }: NullableParams) => {
  const query = useQueryNullableGroupMeetupDetail({ groupId, meetupId });
  return useSuspenseQuery({ ...query });
};
