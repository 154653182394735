import type { BoardCategoryResponse } from '@community-group/api/lib/group/models';
import { isNil } from 'lodash-es';

import type { PollFormActivityParamsJSON } from '@/components/group/Poll/pages';
import type { GroupPostFormHandlerProps } from '@/components/group/PostForm/components/GroupPostForm';
import { useStorage } from '@/hooks/useStorage';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

type Props = {
  boardCategories: BoardCategoryResponse;
};

export const GROUP_POST_FORM_IS_GROUP_ONLY_KEY = 'postFormIsGroupOnly' as const;

export const useMakeDefaultPostFormValue = ({ boardCategories }: Props) => {
  const {
    content = '',
    postType: queryParamsPostType = 'post',
    meetupId: queryParamsMeetupId,
    challengeId: queryParamsChallengeId,
    initialPublishPost,
    pollOptionValueJSONString,
  } = useQueryParams();

  const [isPostFormIsGroupOnly] = useStorage('postFormIsGroupOnly', false);
  const isInitialPublishPost = initialPublishPost === 'true';

  const initialPollOption = pollOptionValueJSONString
    ? (JSON.parse(pollOptionValueJSONString) as PollFormActivityParamsJSON)
    : undefined;

  const pollOptionTitles = () => {
    if (!initialPollOption) return undefined;

    const options = initialPollOption.pollOptionTitles.filter(
      (pollOption) => !isNil(pollOption)
    ) as string[];

    return options.map((option) => ({
      title: option,
    }));
  };

  return {
    isGroupOnly: isPostFormIsGroupOnly ?? false,
    isPublished: isInitialPublishPost,
    content: decodeURIComponent(content),
    images: [],
    originImages: [],
    prevImages: [],
    isNoticed: false,
    mentionedUserIds: [],
    poiStreams: [],
    postType: {
      type: queryParamsPostType,
      meetupId: queryParamsMeetupId ? Number(queryParamsMeetupId) : 0,
      challengeId: queryParamsChallengeId ? Number(queryParamsChallengeId) : undefined,
    },
    boardCategoryIds: [
      boardCategories.boardCategories?.find((item) => item.type === 'default')?.id,
    ],
    poll: initialPollOption
      ? {
          options: pollOptionTitles(),
          title: initialPollOption.title,
          isMultiple: initialPollOption.isMultiple,
        }
      : undefined,
    uploadVideos: [],
  } as GroupPostFormHandlerProps;
};
