import { getDateDiffFromNow, Typography } from '@community-group/components';
import type { FC } from 'react';

import * as s from './ActivityLog.css';
export const GroupActivityLog: FC<{
  lastActivatedAt?: string;
}> = ({ lastActivatedAt }) => {
  if (!lastActivatedAt) return null;
  const activityDate = new Date(Date.parse(lastActivatedAt));
  const dateDiff = getDateDiffFromNow(activityDate);

  // 2시간 59분
  const IN2HOUR59MIN = 1000 * 60 * 179;
  if (dateDiff.diff > IN2HOUR59MIN) return null;

  const renderText = `${dateDiff.text} 전 활동`;
  return (
    <span className={s.Badge}>
      <div className={s.Space}>⸱</div>
      <Typography
        typography="caption1Regular"
        color="accent"
        wordBreak="keep-all"
        ellipsisAfterLines={1}
      >
        {renderText}
      </Typography>
    </span>
  );
};
