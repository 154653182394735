import { MouseEvent, useEffect, useRef } from 'react';

import { useSubscribeAndroidBackButtonPress } from './useSubscribeAndroidBackButtonPress';

type Props = {
  isBlocked: boolean;
  onBlock: () => void;
  onPressBackButton: () => void;
};

export const useBlockGroupActivity = ({ isBlocked, onBlock, onPressBackButton }: Props) => {
  const androidBackButtonEnabled = useRef(false);
  useEffect(() => {
    if (isBlocked) {
      onBlock();
      return;
    }

    androidBackButtonEnabled.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlocked]);

  useSubscribeAndroidBackButtonPress({
    pressHandler: (e?: MouseEvent<HTMLButtonElement>) => {
      if (androidBackButtonEnabled.current) return;
      e?.preventDefault();
      onPressBackButton();
    },
    enabled: androidBackButtonEnabled.current,
  });
};
