import { GroupMeetupCreateForm } from '@community-group/api/lib/group/models';
import { BoxButton, Dialog, Spacing, withAsyncBoundary } from '@community-group/components';
import {
  AsyncBoundary,
  Divider,
  useDialog,
  useKeyboardSize,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useEffect, useMemo, useRef } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { getCurrentRunningGrowthStep } from '@/components/group/Detail/components/RunningGrowth/utils/curerntLevel';
import { GroupMeetupFormDescriptionField } from '@/components/group/Meetup/Form/components/Field/Description';
import { GroupMeetupFormImagesField } from '@/components/group/Meetup/Form/components/Field/Images';
import { GroupMeetupFormMaximumParticipantsNumberField } from '@/components/group/Meetup/Form/components/Field/MaximumParticipantsNumber';
import { GroupMeetupFormPoiField } from '@/components/group/Meetup/Form/components/Field/Poi';
import { GroupMeetupFormTitleField } from '@/components/group/Meetup/Form/components/Field/Title';
import {
  CloneGroupMeetupFormDataQueryParams,
  useInitGroupMeetupFormData,
} from '@/components/group/Meetup/Form/hooks/useInitGroupMeetupFormData';
import { closeListItemFormFieldQuerySelector } from '@/components/group/Meetup/Form/utils/selector';
import { openVerifyNeighborhood } from '@/constants/path';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import { useQueryNullableGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryNullableGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryNullableGroupLevel } from '@/domain/GroupLevel/hooks/useReadGroupLevel';
import { useQueryNullableGroupMemberGrades } from '@/domain/GroupMember/hooks/useReadGroupMemberGrades';
import { useQueryNullableGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import MeetupFormTopCallout from '@/features/SeoulRunPromotion/MeetupFormTopCallout';
import CreateMeetupGuideCallout from '@/features/SeoulRunPromotion/New/CreateMeetupGuideCallout';
import { SeoulRunGroupMeetupFormMeetupDateTimeField } from '@/features/SeoulRunPromotion/SeoulRunMeetupDateTime/MeetupDateTime';
import { useCurrentRegionCheckOpend } from '@/hooks/useCurrentRegionCheckOpend';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { useSubscribeAndroidBackButtonPress } from '@/shared/hooks/useSubscribeAndroidBackButtonPress';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { ActivityQueryParams, useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { getPermissionGradeName, isAccessibleGrade } from '@/utils/permission';
import { meetupCreateFormSchema, meetupFieldSchema } from '@/utils/validate/formSchema/meetup';
import {
  seoulRunMeetupCreateFormSchema,
  seoulRunMeetupSchema,
} from '@/utils/validate/formSchema/seoulRunMeetup';
import { convertHookFormErrorObjectToArray } from '@/utils/validate/util';

import * as s from './index.css';

export type GroupMeetupNewPageParams = Pick<
  PageParams,
  keyof CloneGroupMeetupFormDataQueryParams | 'from'
> &
  Pick<ActivityQueryParams, 'groupId' | 'cloneGroupMeetupFormData' | 'poiName'>;

const SeoulRunMeetupNewPage: ActivityComponentType<GroupMeetupNewPageParams> = () => {
  const { from, calloutType, groupId } = useQueryParams();
  const { replace, push, pop } = useFlow();

  const { data, regionCertified, userInfo, isOpenRegion } = useCurrentRegionCheckOpend();

  const back = useBack();
  const { open: openDialog, close: closeDialog } = useDialog();

  useSetFixedLayoutSize();
  useUpdateStyleCurrentRouter({ IOS_ONLY_hideKeyboardAccessoryView: false }, true);

  const { bridge } = useBridge();
  const { isKeyboardOn } = useKeyboardSize(bridge);
  const {
    userConfig: { userId },
  } = useUserConfig();

  const initGroupMeetupFormData = useInitGroupMeetupFormData();

  const hookForm = useForm<GroupMeetupCreateForm>({
    defaultValues: initGroupMeetupFormData,
    resolver: zodResolver(groupId ? meetupCreateFormSchema : seoulRunMeetupCreateFormSchema),
  });

  const [
    { data: me },
    { data: group },
    { data: level },
    { data: permissions },
    { data: memberGrades },
  ] = useSuspenseQueries({
    queries: [
      useQueryNullableGroupMe({ groupId }),
      useQueryNullableGroupDetail(groupId),
      useQueryNullableGroupLevel({ groupId }),
      useQueryNullableGroupPermissions({ groupId }),
      useQueryNullableGroupMemberGrades({ groupId }),
    ],
  });

  const myCreateGroupMeetupPermission = useMemo(() => {
    if (!groupId || !me || !memberGrades || !permissions) return {};

    return {
      permissionGradeName: getPermissionGradeName(
        memberGrades,
        permissions.createGroupMeetup.currentPriority
      ),
      hasAccessiblePermission: isAccessibleGrade(
        me?.grade,
        permissions.createGroupMeetup.currentPriority
      ),
    };
  }, [groupId, me, memberGrades, permissions]);

  const androidBackButtonEnabled = useRef(false);

  useEffect(() => {
    if (!data) return;
    if (!isOpenRegion) {
      openDialog({
        element: (
          <Dialog
            title="모임 서비스 제한 지역"
            description={`${userInfo.regionCheckIns.regionName}에서는 모임을 이용할 수 없어요.`}
            primaryActionLabel="확인"
            onPrimaryAction={async () => {
              await closeDialog();
              setTimeout(() => {
                pop();
              }, 50);
            }}
          />
        ),
      });
      return;
    }

    if (!regionCertified) {
      openDialog({
        element: (
          <Dialog
            title="동네인증"
            description={`모임을 만들려면 ${userInfo.regionCheckIns.regionName} 동네인증이 필요해요.`}
            primaryActionLabel="인증"
            secondaryActionLabel="취소"
            onPrimaryAction={async () => {
              trackEvent({ event: 'click_verify_neighboorhood' });
              pop();
              openVerifyNeighborhood();
            }}
            onSecondaryAction={async () => {
              trackEvent({ event: 'click_cancel_verify_neighboorhood' });
              await closeDialog();
              setTimeout(() => {
                pop();
              }, 50);
            }}
          />
        ),
      });
      return;
    }

    if (!groupId || !me || myCreateGroupMeetupPermission.hasAccessiblePermission) {
      androidBackButtonEnabled.current = true;
      return;
    }

    if (myCreateGroupMeetupPermission.permissionGradeName === '정회원') {
      openDialog({
        element: (
          <Dialog
            description="정회원만 일정을 만들 수 있어요. 멤버 등급을 확인해주세요."
            secondaryActionIntent="nonpreferred"
            primaryActionLabel="내 멤버 등급 보기"
            secondaryActionLabel="닫기"
            onPrimaryAction={async () => {
              await closeDialog();
              replace('GroupMemberGradeDetailPage', {
                groupId,
                userId: me?.id.toString(),
                from: 'meetup',
              });
            }}
            onSecondaryAction={async () => {
              await closeDialog();
              back();
            }}
          />
        ),
      });
      return;
    }

    openDialog({
      element: (
        <Dialog
          description={`${myCreateGroupMeetupPermission.permissionGradeName}만 일정을 만들 수 있어요.`}
          secondaryActionIntent="nonpreferred"
          primaryActionLabel="확인"
          onPrimaryAction={async () => {
            await closeDialog();
            back();
          }}
        />
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenRegion, regionCertified, myCreateGroupMeetupPermission]);

  useSubscribeAndroidBackButtonPress({
    pressHandler: async (e?: React.MouseEvent<HTMLButtonElement>) => {
      if (androidBackButtonEnabled.current) return;
      e?.preventDefault();
      await closeDialog();
      back();
    },
    enabled: androidBackButtonEnabled.current,
  });

  useEnterTrackEvent({
    event: 'enter_create_meetup',
    params: {
      calloutType,
      currentRunningGrowthMission: getCurrentRunningGrowthStep(calloutType),
      currentLevel: level?.currentLevel,
      currentProgressPercentage: level?.forLevelUp.percentage,
      from,
      userId: (userId ?? 0).toString(),
      categoryId: group?.category.id,
      categoryName: group?.category.name,
    },
    loggerType: ['APPSFLYER', 'AMPLITUDE', 'KARROT'],
  });

  const { handleSubmit: handleFormSubmit, watch } = hookForm;

  const isDisabledButton = useMemo(() => {
    if (isKeyboardOn) return false;

    if (watch('title').length < (meetupFieldSchema.title.minLength ?? 1)) return true;

    if (
      (watch('images') ?? [])?.filter(
        (imageId) => imageId.includes('temp') || imageId.includes('error')
      ).length > 0
    )
      return true;

    return false;
  }, [isKeyboardOn, watch('title'), watch('images')]);

  const handleSubmit = () => {
    if (isKeyboardOn) return;

    handleFormSubmit(
      (data) => {
        push('SeoulRunMeetupNewOptionsPage', {
          meetupCreateForm: JSON.stringify(data),
          calloutType,
          from,
          groupId,
        });
      },
      (errors) => {
        const errorList = convertHookFormErrorObjectToArray(errors);

        const firstError = errorList[0];

        bridge.openToast({
          toast: {
            body: firstError.value.message ?? '',
          },
        });
      }
    )();
  };

  return (
    <AppScreen
      appBar={{
        backButton: {
          render: () => (
            <div className={s.CloseButtonWrapper} onClick={back}>
              <IconXmarkLine />
            </div>
          ),
        },
        closeButton: {
          render: () => (
            <div className={s.CloseButtonWrapper} onClick={back}>
              <IconXmarkLine />
            </div>
          ),
        },
      }}
      accessoryBar={
        <div
          className={s.AccessoryBarWrapper}
          style={{
            padding: isKeyboardOn
              ? '0'
              : '0.75rem 1rem calc(0.5rem + env(safe-area-inset-bottom)) 1rem',
          }}
        >
          <BoxButton
            width="100%"
            variant="primary"
            size="xlarge"
            isDisabled={isDisabledButton}
            onClick={handleSubmit}
            UNSAFE_style={isKeyboardOn ? { borderRadius: 0 } : undefined}
          >
            다음
          </BoxButton>
        </div>
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <form>
          <GroupMeetupNewView hookForm={hookForm} groupId={groupId} />
        </form>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(SeoulRunMeetupNewPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});

type Props = {
  groupId?: string;
  hookForm: UseFormReturn<GroupMeetupCreateForm>;
};
const GroupMeetupNewView = ({ groupId, hookForm: { watch, setValue } }: Props) => {
  const { poiName } = useQueryParams();

  const resolveSchema = groupId ? meetupFieldSchema : seoulRunMeetupSchema;

  return (
    <div className={s.Wrapper}>
      <MeetupFormTopCallout poiName={poiName ?? ''} />
      <GroupMeetupFormTitleField
        name="title"
        watch={watch}
        setValue={setValue}
        placeholder="언제 어디서 보물찾기를 할까요?"
        maxLength={resolveSchema.title.maxLength ?? 100}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <SeoulRunGroupMeetupFormMeetupDateTimeField
        dateName="meetupTimeAt"
        onlyDateName="hasMeetupTimeAtOnlyDate"
        watch={watch}
        setValue={setValue}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider />
      <GroupMeetupFormPoiField
        name="poiItems.0"
        setValue={setValue}
        defaultValue={poiName}
        openDisabled
      />
      <Divider />
      <GroupMeetupFormMaximumParticipantsNumberField
        name="maximumParticipantsNumber"
        watch={watch}
        setValue={setValue}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider />
      <GroupMeetupFormDescriptionField
        name="description"
        watch={watch}
        setValue={setValue}
        placeholder="활동에 대한 설명을 추가해주세요."
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider />
      <GroupMeetupFormImagesField name="images" setValue={setValue} />
      <Spacing size={8} />
      <CreateMeetupGuideCallout />
      <Spacing size={58} />
    </div>
  );
};
