import { BoxButton, Spacing, Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';

import * as s from './style.css';

const CreatedChallengeGroupBottomSheet: ActivityComponentType = () => {
  const { pop } = useFlow();

  return (
    <BottomSheet style={{ padding: 0 }}>
      <div className={s.Container}>
        <Typography typography="title2Bold">챌린지 모임을 만들었어요!</Typography>
        <Spacing size={6} />
        <Typography typography="subtitle1Regular" color="gray700" className={s.InfoText}>
          내가 만든 모임은
          <br />
          &apos;동네생활&apos; 탭 상단에서 다시 확인할 수 있어요.
        </Typography>
        <Spacing size={16} />
        <img
          className={s.Image}
          src="https://assetstorage.krrt.io/1025317940251365954/76e02c7e-91a7-4413-b014-aa90f5ec04e1/width=1029,height=480.webp"
        />
        <Spacing size={28} />
        <BoxButton
          size="xlarge"
          width="100%"
          variant="primary"
          onClick={() => {
            pop();
          }}
        >
          확인했어요
        </BoxButton>
      </div>
    </BottomSheet>
  );
};

export default CreatedChallengeGroupBottomSheet;
