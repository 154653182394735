import { GroupMemberGradeRequirementPresentation } from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import { SettingList } from '@community-group/components/features';

import { mapMemberGradeRequirements } from '@/domain/GroupMember/utils/memberGradeRequirements';
import * as stackflow from '@/stackflow';

import * as s from './SettingMemberGradeRequirements.css';

type Props = {
  requirements: GroupMemberGradeRequirementPresentation[];
  setRequirements: (requirement: GroupMemberGradeRequirementPresentation) => void;
};

const SettingMemberGradeRequirements = ({ requirements, setRequirements }: Props) => {
  const connectedFlow = stackflow?.useConnectedFlow();
  const handleClickRequirement = (requirement) => async () => {
    const request = await connectedFlow.push('BottomSheet/RequirementCountSelectorBottomSheet', {
      title: requirement.label,
      selectedCount: requirement.goalCount,
    });

    setRequirements({ ...requirement, goalCount: request.selectedCount });
  };

  return (
    <div className={s.Container}>
      <div className={s.TitleContainer}>
        <Typography typography="subtitle1Bold" color="gray900">
          정회원 등업 조건
        </Typography>
        <Spacing size={2} />
        <Typography typography="caption1Regular" color="gray600">
          모든 조건을 만족하는 멤버는 등업을 신청할 수 있어요. 승인 이후 정회원이 돼요.
        </Typography>
      </div>
      <SettingList.Wrapper>
        {mapMemberGradeRequirements(requirements).map((requirement) => (
          <SettingList.Item
            key={requirement.activity}
            title={requirement.label}
            onClick={handleClickRequirement(requirement)}
            renderRight={
              <Typography typography="bodyM1Regular" color="primary">
                {requirement.goalCount}회
              </Typography>
            }
          />
        ))}
      </SettingList.Wrapper>
    </div>
  );
};

export default SettingMemberGradeRequirements;
