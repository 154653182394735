import type { MissionAchieveCountPresentation } from '@community-group/api/lib/group/models';
import { Typography, VerticalSpacing } from '@community-group/components';
import { IconCheckmarkFill, IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import type { ComponentPropsWithoutRef } from 'react';

import type { MissionInfoItem } from '@/components/group/GroupLevel/utils/missions';

import { isMissionAchieved } from './guard';
import * as s from './MissionItem.css';

type Props = {
  missionInfo: MissionInfoItem;
  missionStatus: MissionAchieveCountPresentation;
} & ComponentPropsWithoutRef<'div'>;

function MissionItem({ missionInfo, missionStatus, ...props }: Props) {
  const Icon = missionInfo.icon;
  const successMission = isMissionAchieved(missionStatus);

  return (
    <div className={s.Content({ successMission })} {...props}>
      <div className={s.MissionStateWrapper}>
        <Icon
          size={16}
          color={successMission ? vars.$semantic.color.success : vars.$scale.color.gray700}
        />
        <VerticalSpacing size={12} />
        <Typography typography="subtitle2Regular" color={successMission ? 'green700' : 'gray900'}>
          {missionInfo.title}
        </Typography>
      </div>
      <VerticalSpacing size={12} />
      <div className={s.MissionStateWrapper}>
        <Typography typography="caption1Regular" color={successMission ? 'success' : 'gray600'}>
          {missionStatus.currentCount}/{missionStatus.achieveCount}
        </Typography>
        <VerticalSpacing size={8} />
        {successMission ? (
          <IconCheckmarkFill size={16} color={vars.$semantic.color.success} />
        ) : (
          <IconChevronRightFill size={16} color={vars.$scale.color.gray600} />
        )}
      </div>
    </div>
  );
}

export default MissionItem;
