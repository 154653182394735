import { PostDetailMeetupInfoPresentation } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { IconCalendarFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { MouseEvent, Suspense, useMemo } from 'react';

import { useReadGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';

import * as s from './MeetupBanner.css';

type Props = {
  groupId: string;
  meetup: PostDetailMeetupInfoPresentation;
  isReview?: boolean;
  onClick?: (e: MouseEvent) => void;
};

export const MeetupBanner = ({ groupId, meetup, isReview, onClick }: Props) => {
  const title = isReview ? meetup.title : meetup.meetupTimeAtDisplayText;

  return (
    <div className={s.Container} onClick={onClick} aria-hidden="true">
      <div className={s.ImageWrapper}>
        <IconCalendarFill width={24} height={24} color={vars.$semantic.color.primary} />
      </div>
      <div className={s.ContentsWrapper}>
        <div className={s.Contents}>
          <Typography typography="bodyM2Regular" color="gray900" ellipsisAfterLines={1}>
            {title}
          </Typography>
          <Suspense>
            <MeetupBannerDescription
              groupId={groupId}
              meetup={meetup}
              isReview={Boolean(isReview)}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

type MeetupBannerDescriptionProps = {
  groupId: string;
  meetup: PostDetailMeetupInfoPresentation;
  isReview: boolean;
};

const MeetupBannerDescription = ({ groupId, meetup, isReview }: MeetupBannerDescriptionProps) => {
  const { data: memberGradeStatus } = useReadGroupMemberGradeStatus(groupId);

  const memberGradeEnabled = useMemo(() => {
    return memberGradeStatus === 'system';
  }, [memberGradeStatus]);

  const descriptionLeft = isReview ? `${meetup.meetupTimeAtDisplayText} ∙` : '일정 ∙ ';
  const descriptionMiddle = memberGradeEnabled && !meetup.canJoinAnyMember ? '정회원 이상 ∙ ' : '';
  const descriptionRight = `${meetup.currentParticipantsNumber}/${meetup.maximumParticipantsNumber}명 참여`;

  return (
    <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
      {descriptionLeft + descriptionMiddle + descriptionRight}
    </Typography>
  );
};
