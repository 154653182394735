import { ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import GroupNotificationSettingSection from '@/features/GroupSetting/GroupNotification';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

const GroupSettingNotification: ActivityComponentType = () => {
  const { groupId } = usePathParams();
  const { data: group } = useReadGroupDetail(groupId);

  return (
    <AppScreen appBar={{ title: group?.name ?? '' }}>
      <GroupNotificationSettingSection isSection={false} groupId={groupId} />
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingNotification, {
  rejectedFallback: <ViewError />,
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
});
