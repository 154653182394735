import {
  BottomBasicButton,
  MultilineTextField,
  Spacing,
  Typography,
} from '@community-group/components';
import { IconChevronLeftLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';
import { useState } from 'react';

import { useConnectedFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { PageParams } from '@/stackflow/types/params';

import * as s from './index.css';

type Params = Pick<PageParams, 'groupId'>;

export type GroupMemberGradeApplicationRejectMessagePageRequest = {
  method: 'close' | 'reject';
  message?: string;
};

const GroupMemberGradeApplicationRejectMessagePage: ActivityComponentType<Params> = () => {
  const [message, setMessage] = useState('');
  const { pop } = useConnectedFlow();
  const handleClose = () => {
    pop().send({
      method: 'close',
    });
  };
  const handleReject = () => {
    pop().send({
      method: 'reject',
      message,
    });
  };

  return (
    <AppScreen
      appBar={{
        closeButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: handleClose,
        },
        backButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: handleClose,
        },
      }}
      accessoryBar={
        <BottomBasicButton onClick={handleReject} disabled={!message}>
          거절하고 의견 보내기
        </BottomBasicButton>
      }
    >
      <div className={s.Container}>
        <Spacing size={12} />
        <Typography typography="title1Bold">
          거절할 멤버에게
          <br />
          보낼 의견을 입력해주세요
        </Typography>
        <Spacing size={8} />
        <Typography typography="bodyM1Regular">입력하신 내용은 알림으로 전달돼요.</Typography>
        <Spacing size={24} />
        <MultilineTextField
          value={message}
          onChange={(value) => setMessage(value)}
          placeholder="ex) 작성한 글이 너무 짧아요. 수정한 후 다시 신청해주세요."
          maxLength={100}
          minLength={1}
          minHeight="8.5rem"
        />
      </div>
    </AppScreen>
  );
};

export default GroupMemberGradeApplicationRejectMessagePage;
