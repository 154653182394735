import { Typography } from '@community-group/components';
import { IconPenHorizlineFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import React from 'react';

import { useFlow } from '@/stackflow';

import * as s from './style.css';

const FloatingCreateChallengeButton = () => {
  const { push } = useFlow();
  const handleClick = () => {
    push('GroupChallengeNewPage', {});
  };
  return (
    <div className={s.Button} onClick={handleClick}>
      <IconPenHorizlineFill size={16} color={vars.$semantic.color.onPrimary} />
      <Typography typography="subtitle1Bold" color="onPrimary">
        챌린지 만들기
      </Typography>
    </div>
  );
};

export default FloatingCreateChallengeButton;
