import { IconChevronLeftLine, IconXmarkLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useActivity } from '@stackflow/react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import React, { forwardRef, RefObject, useEffect, useMemo, useRef, useState } from 'react';

import { useBack } from '@/stackflow/hooks/useBack';

import * as s from './Navbar.css';

export type StackflowTheme = 'android' | 'cupertino';
export type TransparentNavbarStyle = 'gradient' | 'transparent' | 'hard';
export interface NavbarProps {
  title?: React.ReactNode;
  renderRight?: React.ReactNode;
  appendBottom?: React.ReactNode;
  explicitIconStyle?: 'back' | 'backwardNew' | 'close';
  iconColor: string;
  theme: StackflowTheme;
  showNavigationBg?: boolean;
  showNavigationShadow?: boolean;
  navbarStyle: TransparentNavbarStyle;
  contentsScrollRef: RefObject<HTMLDivElement>;
}

const Navbar = forwardRef<HTMLDivElement, NavbarProps>(
  (
    {
      title,
      renderRight,
      appendBottom,
      iconColor,
      theme,
      showNavigationBg = true,
      showNavigationShadow = true,
      navbarStyle,
      explicitIconStyle,
      contentsScrollRef,
    },
    ref
  ) => {
    const [centerTextMaxWidth, setCenterTextMaxWidth] = useState<undefined | number>(undefined);
    const { isRoot: isFirst } = useActivity();

    const back = useBack();

    const centerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (theme === 'cupertino') {
        let currentClientWidth = 0;
        let animationFrameId: number;

        const detectMaxWidth = () => {
          animationFrameId = requestAnimationFrame(() => {
            const clientWidth = centerRef.current?.clientWidth;
            if (clientWidth && clientWidth !== currentClientWidth) {
              currentClientWidth = clientWidth;
              setCenterTextMaxWidth(clientWidth - 32);
            }
            detectMaxWidth();
          });
        };
        detectMaxWidth();
        return () => {
          cancelAnimationFrame(animationFrameId);
        };
      }
    }, [theme]);

    const explicitIconInstance = useMemo(() => {
      switch (explicitIconStyle) {
        case 'back':
          return <IconChevronLeftLine color={iconColor} size={16} />;
        case 'close':
          return <IconXmarkLine color={iconColor} size={16} />;
        default:
          return null;
      }
    }, [explicitIconStyle, iconColor]);

    const leftIconInstance = useMemo(
      () => (
        <button className={s.Back} onClick={back} data-action="back">
          {explicitIconInstance ? (
            explicitIconInstance
          ) : isFirst ? (
            <IconXmarkLine color={iconColor} />
          ) : (
            <IconChevronLeftLine color={iconColor} />
          )}
        </button>
      ),
      [back, explicitIconInstance, isFirst, iconColor]
    );

    const getContainerBackgroundColor = () => {
      if (navbarStyle === 'hard' || showNavigationBg) {
        return vars.$semantic.color.paperDefault;
      }
      if (navbarStyle === 'gradient') {
        return 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)';
      }

      return '';
    };

    const getContainerBoxShadow = () => {
      if (!showNavigationShadow) return 'none';
      return '';
    };

    const handleMoveToTop = () => {
      contentsScrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
      <div className={s.NavbarBase}>
        <div
          className={s.Container({ navigatorTheme: theme })}
          style={{
            ...assignInlineVars({ [s.ContainerBackgroundColorVar]: getContainerBackgroundColor() }),
            boxShadow: getContainerBoxShadow(),
          }}
          ref={ref}
        >
          <div className={s.Flex}>
            <div className={s.Left}>{leftIconInstance}</div>
            <div className={s.Center} ref={centerRef} onClick={handleMoveToTop}>
              <h1 className={s.CenterTitle({ navigatorTheme: theme, showNavigationBg })}>
                <div className={s.TitleSpan} style={{ maxWidth: centerTextMaxWidth }}>
                  {title}
                </div>
              </h1>
            </div>
            <div className={s.Right}>{renderRight}</div>
          </div>
        </div>

        {appendBottom && <div className={s.Bottom({ navigatorTheme: theme })}>{appendBottom}</div>}
      </div>
    );
  }
);

export default Navbar;
