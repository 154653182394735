import * as React from 'react';
import { useCallback } from 'react';

import * as s from './ClapParticles.css';

export interface ClickAnimationProps {
  id: string;
  posX: number;
  posY: number;
  createdAt: string;
  onDestroy: (id: string) => void;
}

const ClapParticle: React.FC<ClickAnimationProps> = ({ id, posX, posY, createdAt, onDestroy }) => {
  const particleRef = React.useRef<HTMLImageElement>(null);

  React.useEffect(() => {
    const handleAnimationEnd = () => {
      onDestroy(id);
    };
    if (particleRef.current) {
      particleRef.current.addEventListener('animationend', handleAnimationEnd);
    }
    return () => {
      if (particleRef.current) {
        particleRef.current.removeEventListener('animationend', handleAnimationEnd);
      }
    };
  }, [id, onDestroy]);

  const getRandomVariant = useCallback(() => {
    const distances = ['short', 'medium', 'long'] as const;
    const speeds = ['slow', 'normal', 'fast'] as const;
    return {
      distance: distances[Math.floor(Math.random() * distances.length)],
      speed: speeds[Math.floor(Math.random() * speeds.length)],
    };
  }, []);

  const { distance, speed } = React.useMemo(() => getRandomVariant(), [getRandomVariant]);

  return (
    <div
      className={s.image({
        distance,
        speed,
      })}
      style={{ left: `${posX - 25}px`, top: `${posY - 25}px` }}
      ref={particleRef}
    />
  );
};

export default React.memo(ClapParticle);
