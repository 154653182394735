import { useCallback, useMemo } from 'react';
import { match } from 'ts-pattern';

import { groupPersonalSettingConfig } from '@/features/GroupSetting/util/groupSettingConfig';
import { useGetParentingGroupStorage } from '@/hooks/useGetParentingGroupStorage';
import * as stackflow from '@/stackflow';

type Props = {
  groupId: string;
  userId: string;
};

export const usePersonalSettingHandler = ({ groupId, userId }: Props) => {
  const stack = stackflow?.useFlow();
  const push = stack?.push;
  const { isKidProfileEnabled } = useGetParentingGroupStorage(groupId);

  const personalSetting = useMemo(
    () =>
      groupPersonalSettingConfig.filter((item) => {
        if (item.displayText === '내 아이 정보') return isKidProfileEnabled;
        return true;
      }),
    [isKidProfileEnabled]
  );
  const handleItemClick = useCallback(
    (item: (typeof personalSetting)[number]) => {
      match(item)
        .with({ displayText: '모임 알림 설정' }, () => {
          push('GroupNotificationSettingPage', { groupId });
        })
        .with({ displayText: '내 모임 프로필' }, () => {
          push('GroupProfileDetailV2Page', { groupId, userId });
        })
        .with({ displayText: '내 아이 정보' }, () => {
          if (!isKidProfileEnabled) return;
          push('ParentingGroupKidProfileListPage', { groupId, userId });
        })
        .exhaustive();
    },
    [push, groupId, userId, isKidProfileEnabled]
  );

  return { handleItemClick, personalSetting };
};
