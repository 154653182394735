import { GroupChallengeCreateForm } from '@community-group/api/lib/group/models';
import { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import { GroupChallengeFormProps } from '../../types';
import * as s from './style.css';

type Props = GroupChallengeFormProps & {
  titleFieldName: keyof GroupChallengeCreateForm;
  onClick?: () => void;
};

export const GroupChallengeFormTitleField = ({ formHandler, titleFieldName, onClick }: Props) => {
  const [focused, setFocused] = useState(false);

  const titleValue = useMemo(
    () => (formHandler.watch(titleFieldName) ?? '') as string,
    [formHandler, titleFieldName]
  );

  return (
    <div className={s.Wrapper}>
      {!focused && titleValue.length <= 0 && <div className={s.FakeCaret} />}
      <Controller
        control={formHandler.control}
        name={titleFieldName}
        defaultValue={titleValue ?? ''}
        render={({ field }) => (
          <input
            data-field-name={titleFieldName}
            name={titleFieldName}
            className={s.Input}
            value={field.value as string}
            onClick={onClick}
            onChange={field.onChange}
            onFocus={() => setFocused(true)}
            maxLength={24}
            placeholder="어떤 챌린지를 진행할까요?"
          />
        )}
      />
    </div>
  );
};
