import { IconXmarkLine } from '@daangn/react-monochrome-icon';

import { useBack } from '@/stackflow/hooks/useBack';

import * as s from './style.css';

const GroupPostCreateBackButton = () => {
  const backPop = useBack();

  return (
    <div className={s.CloseButtonWrapper} onClick={backPop}>
      <IconXmarkLine />
    </div>
  );
};

export default GroupPostCreateBackButton;
