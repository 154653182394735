import {
  ChallengeInfoPostDetail,
  PostTypePresentationType,
} from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { IconFlameFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { differenceInDays } from 'date-fns';
import { useMemo } from 'react';

import {
  getCertificationIntervalText,
  getCertificationTotalDaysText,
} from '@/features/Challenge/utils/certification';
import { getChallengeDateInfo } from '@/features/Challenge/utils/date';

import * as s from './PoiBanner.css';

type Props = {
  postType: PostTypePresentationType;
  challengeInfo: ChallengeInfoPostDetail;

  onClick?: () => void;
};

// 피드 게시글 내부 - 챌린지 배너
export const FeedContentsChallengeBanner = ({
  postType,
  challengeInfo,
  onClick: handleClick,
}: Props) => {
  const {
    totalDays,
    participantsCount,
    name,
    status,
    startedAt,
    endedAt,
    certifyIntervalTargetCount,
  } = challengeInfo;

  const subInfo = useMemo(() => {
    const totalWeeks = getCertificationTotalDaysText(totalDays, 7);
    const attendedCount = participantsCount;

    return `${totalWeeks} ⸱ ${attendedCount}명 참여`;
  }, [totalDays, participantsCount]);

  // 시작일
  const dateInfo = useMemo(() => {
    if (status === 'closed') {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      const endedAtDate = new Date(new Date(endedAt).setHours(0, 0, 0, 0));

      const diffDate = Math.max(0, differenceInDays(endedAtDate, today));

      if (diffDate === 0) {
        return '오늘 종료';
      }

      return `${diffDate}일 전 종료`;
    }

    const { durationText } = getChallengeDateInfo({ startedAt, endedAt, status, totalDays });
    return durationText;
  }, [endedAt, startedAt, status, totalDays]);

  // 인증 빈도
  const certifyGoalCountText = useMemo(() => {
    const intervalText = getCertificationIntervalText(certifyIntervalTargetCount, '일');
    return `${intervalText} 인증`;
  }, [certifyIntervalTargetCount]);

  const infoSection = useMemo(() => {
    if (postType === 'challenge') {
      return (
        <div className={s.contents}>
          <Typography typography="bodyM2Regular" ellipsisAfterLines={1}>
            {dateInfo}, {certifyGoalCountText}
          </Typography>
          <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
            {subInfo}
          </Typography>
        </div>
      );
    }

    return (
      <div className={s.contents}>
        <Typography typography="bodyM2Regular" ellipsisAfterLines={1}>
          {name}
        </Typography>
        <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
          {subInfo}
        </Typography>
      </div>
    );
  }, [postType, name, subInfo, dateInfo, certifyGoalCountText]);

  return (
    <div
      className={s.wrapper}
      onClick={() => {
        return handleClick?.();
      }}
    >
      <div className={s.ImageContainer}>
        <IconFlameFill size={24} color={vars.$semantic.color.primary} />
      </div>
      <div className={s.contentsWrapper}>{infoSection}</div>
    </div>
  );
};
