import {
  IconBarchartSquareFill,
  IconCalendarFill,
  IconMegaphoneFill,
  IconPenHorizlineFill,
  IconPerson2Fill,
  IconPictureFill,
} from '@daangn/react-monochrome-icon';

import type { MissionId } from './GroupLevelType';

export type MissionInfoItem = {
  icon: React.ElementType;
  title: string;
  onlyHost: boolean;
};

export type MissionInfoType = Record<MissionId, MissionInfoItem>;

const MISSION_INFO_WRITE_FIRST_POST: Pick<MissionInfoType, 'writeFirstPost'> = {
  writeFirstPost: {
    icon: IconMegaphoneFill,
    title: '첫 글 작성하기',
    onlyHost: false,
  },
};

const MISSION_INFO_WRITE_FIRST_POST_EXPERIMENTAL: Pick<MissionInfoType, 'writeFirstPost'> = {
  writeFirstPost: {
    icon: IconMegaphoneFill,
    title: '모임 홍보하기',
    onlyHost: false,
  },
};

const MISSION_INFO_COMMON: Omit<MissionInfoType, 'writeFirstPost'> = {
  activityPoint: {
    icon: IconBarchartSquareFill,
    title: '활동점수 모으기',
    onlyHost: false,
  },
  hasGroupDescription: {
    icon: IconPenHorizlineFill,
    title: '모임 소개 작성하기',
    onlyHost: true,
  },
  hasGroupThumbnail: {
    icon: IconPictureFill,
    title: '모임 대표사진 등록하기',
    onlyHost: true,
  },
  writePost: {
    icon: IconPenHorizlineFill,
    title: '게시글 남기기',
    onlyHost: false,
  },
  writeMeetup: {
    icon: IconCalendarFill,
    title: '일정 만들기',
    onlyHost: false,
  },
  raiseMember: {
    icon: IconPerson2Fill,
    title: '멤버 모으기',
    onlyHost: false,
  },
  rollbackActivityPoint: {
    icon: IconBarchartSquareFill,
    title: '추가 활동 점수 모으기',
    onlyHost: false,
  },
};

// 미션 정보
export const MISSION_INFO: MissionInfoType = {
  ...MISSION_INFO_COMMON,
  ...MISSION_INFO_WRITE_FIRST_POST,
};

export const MISSION_INFO_EXPERIMENTAL: MissionInfoType = {
  ...MISSION_INFO_COMMON,
  ...MISSION_INFO_WRITE_FIRST_POST_EXPERIMENTAL,
};
