import { AsyncBoundary, ClientOnly, ViewError } from '@community-group/components';
import { AvoidSnackbarOverlap } from '@community-group/components';
import { useStickInputStore } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useRef } from 'react';

import { useHandleErrorWithThrowAccessStatusPage } from '@/hooks/useHandleErrorWithThrowAccessStatusPage';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import CommentList from '../components/CommentFeed/CommentList';
import CommentFormAccessoryView from '../components/CommentFormAccessoryView';
import { GroupPostDetailAppScreen } from '../components/GroupPostDetailAppScreen';
import PostDetailInformation from '../components/PostDetailInformation';
import PostShortcutBanner from '../components/PostShortcutBanner';
import { pullToRefreshPostDetail } from '../utils/pullToRefresh';
import { PostDetailSkeleton } from './Skeleton';

export type GroupPostDetailPageParams = Pick<
  PageParams,
  'groupId' | 'postId' | 'from' | 'photoContestEntryId'
>;

const GroupPostDetailPage: ActivityComponentType<GroupPostDetailPageParams> = () => {
  const { groupId, postId } = usePathParams();

  const BottomRef = useRef<HTMLDivElement>(null);

  const { setFocused } = useStickInputStore();

  useUpdateStyleCurrentRouter({});

  const handleErrorWithThrowErrorStatusPage = useHandleErrorWithThrowAccessStatusPage();

  return (
    <GroupPostDetailAppScreen
      onPull={() => pullToRefreshPostDetail(groupId, postId)}
      accessoryBar={
        <ClientOnly
          render={() => (
            <AsyncBoundary>
              <AvoidSnackbarOverlap>
                <CommentFormAccessoryView listRef={BottomRef} groupId={groupId} postId={postId} />
              </AvoidSnackbarOverlap>
            </AsyncBoundary>
          )}
        />
      }
    >
      <AsyncBoundary
        pendingFallback={<PostDetailSkeleton />}
        rejectedFallback={<ViewError />}
        onError={(error) =>
          handleErrorWithThrowErrorStatusPage({ error, serviceType: 'post', groupId, postId })
        }
      >
        <PostDetailInformation
          onClickCommentIcon={() => {
            setFocused(true);
          }}
        />
      </AsyncBoundary>
      <AsyncBoundary>
        <PostShortcutBanner />
      </AsyncBoundary>
      <AsyncBoundary>
        <div style={{ paddingTop: '0.5rem', paddingBottom: '1rem' }}>
          <CommentList />
        </div>
      </AsyncBoundary>
      <div
        style={{ position: 'relative', width: '100%', height: '1px', left: 0, bottom: 0 }}
        ref={BottomRef}
      />
    </GroupPostDetailAppScreen>
  );
};

export default GroupPostDetailPage;
