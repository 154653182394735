import { GroupMeetupCreateForm } from '@community-group/api/lib/group/models';

import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { initGroupMeetupCreateFormData } from '@/utils/validate/formSchema/meetup';

export type CloneGroupMeetupFormDataQueryParams = {
  cloneGroupMeetupFormData: string;
  poiName: string;
};

export const useInitGroupMeetupFormData = (): GroupMeetupCreateForm => {
  const { cloneGroupMeetupFormData = '' } = useQueryParams();

  if (!cloneGroupMeetupFormData) {
    return initGroupMeetupCreateFormData;
  }

  const parsedCloneGroupMeetupFormData = JSON.parse(
    cloneGroupMeetupFormData
  ) as GroupMeetupCreateForm;

  return {
    title: parsedCloneGroupMeetupFormData.title ?? initGroupMeetupCreateFormData.title,
    description:
      parsedCloneGroupMeetupFormData.description ?? initGroupMeetupCreateFormData.description,
    creationCycle: initGroupMeetupCreateFormData.creationCycle,
    maximumParticipantsNumber:
      parsedCloneGroupMeetupFormData.maximumParticipantsNumber ??
      initGroupMeetupCreateFormData.maximumParticipantsNumber,
    hasMeetupTimeAtOnlyDate: false,
    meetupTimeAt: initGroupMeetupCreateFormData.meetupTimeAt,

    poiItems: parsedCloneGroupMeetupFormData.poiItems ?? initGroupMeetupCreateFormData.poiItems,
    exposureRange:
      parsedCloneGroupMeetupFormData.exposureRange ?? initGroupMeetupCreateFormData.exposureRange,
    images: parsedCloneGroupMeetupFormData.images ?? initGroupMeetupCreateFormData.images,
    isPublished: false,
    canJoinAnyMember:
      parsedCloneGroupMeetupFormData.canJoinAnyMember ??
      initGroupMeetupCreateFormData.canJoinAnyMember,
  };
};
