import { groupClient } from '@community-group/api';
import { ApiV1GroupsIdMembersGetRequestParams } from '@community-group/api/lib/group/api/group-member-api';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { GROUP_MEMBER_QUERY_KEY } from '@/domain/GroupMember/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

export const useReadGroupInfiniteMembers = ({
  id,
  order,
  shownOnlyHost,
  gradeIds,
  roles,
  shownMemberActivities,
  shownMemberApplication,
  limit = 30,
  cursor,
  search,
}: ApiV1GroupsIdMembersGetRequestParams) => {
  const read = useAsyncRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGet);
  return useSuspenseInfiniteQuery({
    queryKey: GROUP_MEMBER_QUERY_KEY.membersInfinite({
      id,
      order,
      shownOnlyHost,
      gradeIds,
      roles,
      shownMemberActivities,
      shownMemberApplication,
      limit,
      search,
    }),
    queryFn: ({ pageParam = cursor }) =>
      read({
        id,
        order: order ?? 'joinedAtAscWithRole',
        shownOnlyHost: gradeIds?.length ? undefined : shownOnlyHost,
        gradeIds: gradeIds ?? undefined, // NOTE: gradeIds와 roles는 동시에 사용할 수 없음, 둘 다 사용하면 gradeIds가 우선권을 가짐
        roles: roles ?? undefined,
        shownMemberActivities: shownMemberActivities ?? false,
        shownMemberApplication: shownMemberApplication ?? false,
        cursor: pageParam ? pageParam : undefined,
        search,
        limit,
      }),
    initialPageParam: cursor,
    getNextPageParam: (data) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    select: (data) => data.pages.flatMap((page) => page.members),
  });
};
