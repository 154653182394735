import { groupClient } from '@community-group/api';
import { ChallengeParticipateResponse } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useRead } from '@/shared/api/hooks/useRead';
import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

type Params = {
  groupId: number;
  challengeId: number;
};
type Props = UseMutationOptions<AxiosResponse<ChallengeParticipateResponse>, Error, Params>;

export const usePostJoinChallengeParticipants = ({ onError, onSuccess }: Props) => {
  const postJoinChallenge = useRead(
    groupClient.api.GroupChallengeMemberApi.apiV1GroupsGroupIdChallengesChallengeIdParticipantsPost
  );

  const fetchPostJoinChallenge = ({ groupId, challengeId }: Params) => {
    return postJoinChallenge({
      groupId,
      challengeId,
    });
  };

  return useThrottleMutation(fetchPostJoinChallenge, {
    onError,
    onSuccess: onSuccess,
  });
};
