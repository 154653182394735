import { Category, CategoryItemType } from '@community-group/api/lib/group/models';
import { smoothScrollTo } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import FilterBadgeRadio from '@/components/common/base/Input/Radio/FilterBadge';
import { useBridge } from '@/contexts/Bridge';
import { trackEvent } from '@/utils/analytics';

import * as s from './CategoryFilterList.css';

interface Props {
  initialCategoryId?: number;
  categories: Category[] | CategoryItemType[];
  onClickItem: (item: Category | CategoryItemType) => void;
}

function CategoryFilterList({
  categories,
  initialCategoryId,
  onClickItem: handleCategoryItem,
}: Props) {
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>(
    initialCategoryId
  );
  const { register } = useForm();
  const [slider, setSlider] = useState<{
    left: number;
    width: number;
  }>({ left: 0, width: 0 });

  // 아래 제거 할 경우 초기 렌더링시 해당 카테고리가 선택되었다는 하단 검정 Bar가 안나오는 현상이 있음.
  useEffect(() => {
    if (initialCategoryId) {
      handleSelectedCategory(
        categories.find((item) => item.id === initialCategoryId) as Category,
        true
      );
    } else {
      handleSelectedCategory(categories[0] as Category, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabListRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<Map<number, HTMLDivElement>>(new Map());
  const { bridge } = useBridge();
  const handleSelectedCategory = useCallback(
    (item: Category | CategoryItemType, initialize?: boolean) => {
      // 유저의 액션에 의해서만 haptic 동작하도록 분기처리
      if (!initialize) {
        bridge.setHapticSelect({});
        trackEvent({
          event: 'click_group_filter',
          params: {
            category: item.name,
            categoryId: item.id,
          },
          sample: true,
        });
      }
      setSelectedCategoryId(item.id);
      handleCategoryItem(item);

      const container = tabListRef.current;
      const target = buttonRef.current.get(item.id);

      if (target && container) {
        if (container.scrollWidth === 0) return;
        const left = target.offsetLeft;
        const itemWidth = target.offsetWidth;
        smoothScrollTo({
          duration: 300,
          element: container,
          point: left - container.offsetWidth / 2 + itemWidth / 2,
          direction: 'horizontal',
        });
        setSlider({
          left: left,
          width: itemWidth,
        });
      }
    },
    [handleCategoryItem]
  );

  return (
    <div className={s.badgeWrapper} ref={tabListRef}>
      {categories.map((item) => (
        <FilterBadgeRadio
          key={item.id}
          value={item.id}
          name="category"
          text={item.name}
          register={register}
          onClick={() => handleSelectedCategory(item)}
          checked={
            item.id === selectedCategoryId || (item.id === 0 && selectedCategoryId === undefined) // 전체 카테고리 선택 시 처리
          }
          ref={(el: HTMLDivElement) => {
            buttonRef.current.set(item.id, el);
          }}
        />
      ))}
      <motion.div
        style={{
          height: '2px',
          bottom: 0,
          left: `${slider.left}px`,
          width: `${slider.width}px`,
          position: 'absolute',
          background: vars.$scale.color.gray900,
          transition: 'left 0.3s ease-in-out, width 0.3s ease-in-out',
        }}
      />
    </div>
  );
}

export default CategoryFilterList;
