import { Category } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';

import * as s from './style.css';

type Props = {
  categories: Category[];
  selectedCategoryId?: number;
  onSelectCategory: (categoryId: number) => void;
};
const GroupCategoryBottomSheet = ({ categories, selectedCategoryId, onSelectCategory }: Props) => {
  return (
    <div className={s.Wrapper}>
      <div className={s.BottomSheetTitle}>
        <Typography typography="title2Bold" color="gray900">
          카테고리
        </Typography>
      </div>
      <div className={s.ChipWrapper}>
        {categories.map((category) => (
          <div
            key={category.id}
            className={s.Chip({ checked: selectedCategoryId === category.id })}
            onClick={() => onSelectCategory(category.id)}
          >
            {category.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupCategoryBottomSheet;
