import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_FEED_QUERY_KEY } from '../queries';

export const useQueryGroupFeedMeetupReviewsForNotMember = (groupId: string) => {
  const read = useAsyncRead(
    groupClient.api.GroupFeedApi.apiV1GroupsIdFeedMeetupReviewsForNotMemberGet
  );
  return {
    queryKey: GROUP_FEED_QUERY_KEY.feedMeetupReviewsForNotMember(groupId),
    queryFn: () => read({ id: Number(groupId) }).then((res) => res.reviews),
  };
};

export const useReadGroupFeedMeetupReviewsForNotMember = (groupId: string) => {
  const query = useQueryGroupFeedMeetupReviewsForNotMember(groupId);
  return useSuspenseQuery(query);
};
