import { ApiV1AllTypeMeetupsGetRequestParams } from '@community-group/api/lib/group/api/all-meetups-api';
import { AsyncBoundary, ViewError, ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { $Values } from 'utility-types';

import { ORDER_HOME, ORDER_MEETUP } from '@/api/base/group';
import { View } from '@/components/common/View';
import MeetupCalendar from '@/features/GroupMeetup/MeetupCalendar';
import { AppScreen } from '@/stackflow/components/AppScreen';

const GroupMeetupCalendarPage: ActivityComponentType = () => {
  return (
    <AppScreen appBar={{ title: '모임 일정 캘린더' }}>
      <View>
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
          <GroupMeetupCalendarView />
        </AsyncBoundary>
      </View>
    </AppScreen>
  );
};

export default GroupMeetupCalendarPage;

export type FilterType = {
  order: $Values<typeof ORDER_HOME>;
  keywordName?: string;
};

const GroupMeetupCalendarView = () => {
  const filter = {
    order: ORDER_MEETUP.MEET_AT_ASC,
    categoryId: undefined,
    statuses: ['opened', 'fully_recruited', 'closed'],
  } as ApiV1AllTypeMeetupsGetRequestParams;

  return (
    <>
      <AsyncBoundary pendingFallback={<ViewLoader />}>
        <MeetupCalendar filter={filter} mode="page" />
      </AsyncBoundary>
      <div
        style={{
          width: '100%',
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      />
    </>
  );
};
