import { BoxButton, Spacing, Typography } from '@community-group/components';
import React from 'react';

import { useFlow } from '@/stackflow';

import * as s from './LoadingPositionView.css';

const LoadingPositionView = () => {
  const { replace } = useFlow();

  const handleGoHomePage = React.useCallback(() => {
    replace('HomePage', {});
  }, [replace]);

  const reload = React.useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className={s.Container}>
      <Typography typography="subtitle1Regular" textAlign="center">
        위치 정보 사용을 허용해주세요.
      </Typography>
      <Spacing size={4} />
      <Typography typography="subtitle2Regular" color="gray600" textAlign="center">
        내 위치 확인을 위해 설정에서 위치 정보 사용을 허용해주세요.
      </Typography>
      <Spacing size={38} />
      <BoxButton variant="secondary" size="medium" onClick={reload}>
        다시 시도
      </BoxButton>
      <Spacing size={18} />
      <Typography
        textAlign="center"
        typography="label3Regular"
        color="gray900"
        onClick={handleGoHomePage}
      >
        홈으로 가기
      </Typography>
    </div>
  );
};

export default LoadingPositionView;
