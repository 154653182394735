import { Radio, RadioGroup, Spacing, Typography } from '@community-group/components';
import { IconCheckmarkFlowerLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { Path, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { GroupMeetupFormListItem, GroupMeetupFormListItemProps } from '../ListItem';
import * as s from './ExposureRange.css';

interface Props<T extends object> extends Pick<GroupMeetupFormListItemProps, 'children'> {
  name: Path<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
}

export const GroupMeetupJoinMemberGradeField = <T extends object>({
  name,
  watch,
  setValue,
  ...props
}: Props<T>) => {
  const canJoinAnyMember = watch(name);

  return (
    <GroupMeetupFormListItem
      {...props}
      fieldIcon={<IconCheckmarkFlowerLine size={24} color={vars.$scale.color.gray900} />}
      fieldLabel="참여 조건"
      fieldName={name}
      fieldValue={
        <div className={s.Value}>
          {GROUP_MEETUP_MEMBER_GRADE_ENUM_TEXT[String(canJoinAnyMember)].label}
        </div>
      }
    >
      <Spacing size={32} />
      <RadioGroup
        size="large"
        value={String(canJoinAnyMember)}
        onChange={(value) => {
          setValue(name, value === 'true');
        }}
      >
        {Object.entries(GROUP_MEETUP_MEMBER_GRADE_ENUM_TEXT).map(
          ([key, { label, description }]) => (
            <div className={s.RadioItem} key={key}>
              <Radio value={key}>
                <div className={s.RadioItemTextWrapper}>
                  <Typography as="p" typography="label2Regular" color="gray900">
                    {label}
                  </Typography>
                  <Typography as="p" typography="caption1Regular" color="gray600">
                    {description}
                  </Typography>
                </div>
              </Radio>
            </div>
          )
        )}
      </RadioGroup>
      <Spacing size={8} />
    </GroupMeetupFormListItem>
  );
};

const GROUP_MEETUP_MEMBER_GRADE_ENUM_TEXT = {
  true: {
    label: '모든 멤버',
    description: '모임 멤버 누구나 참여할 수 있어요.',
  },
  false: {
    label: '정회원 이상',
    description: '정회원부터 참여할 수 있어요.',
  },
};
