import {
  FeedbackRatingEnum,
  GroupMeetupMemberReviewCreateForm,
  GroupMeetupReviewCreateRequest,
  GroupMeetupReviewModifyRequestReviewPost,
} from '@community-group/api/lib/group/models';
import { z } from 'zod';

const feedbackRatingSchema: z.ZodType<FeedbackRatingEnum> = z.enum([
  'DISLIKE',
  'LIKE',
  'EXCELLENT',
]);
const groupMeetupPreviewPostSchema: z.ZodType<
  Omit<GroupMeetupReviewModifyRequestReviewPost, 'medias'>
> = z
  .object({
    images: z.array(z.string()),
    content: z.string(),
    mentionedUserIds: z.array(z.number()).optional().default([]),
    isGroupOnly: z.boolean().optional().default(false),
  })
  .superRefine((value, ctx) => {
    if (value.images.length === 0 && value.content.length === 0) return z.NEVER;

    if (value.images.length !== 0 && value.content.length <= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '내용을 입력해주세요.',
        path: ['content'],
      });
    }

    if (value.content.length !== 0 && value.content.length < 3) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '3자 이상 입력해주세요.',
        path: ['content'],
      });
    }
  });

export const meetupReviewCreateFormSchema: z.ZodType<GroupMeetupReviewCreateRequest> = z.object({
  reviewRating: feedbackRatingSchema,
  reviewPost: z.optional(groupMeetupPreviewPostSchema),
});

export const initMeetupReviewCreateFormData: Partial<GroupMeetupReviewCreateRequest> = {
  reviewRating: undefined,
  reviewPost: {
    images: [],
    content: '',
  },
};

type GroupMemberReviewForm = Partial<Pick<GroupMeetupMemberReviewCreateForm, 'feedbackRating'>> &
  Omit<GroupMeetupMemberReviewCreateForm, 'feedbackRating'>;

const meetupMemberReviewSchema: z.ZodType<GroupMemberReviewForm> = z.object({
  feedbackRating: feedbackRatingSchema.optional(),
  targetMemberId: z.number(),
  feedbackTypeIds: z.custom<number[]>((value) => value ?? []),
  reportReason: z.string().optional(),
  review: z.string().optional(),
});

export type GroupMeetupMemberReviewRequestForm = {
  feedbackMembers: GroupMemberReviewForm[];
};

export const meetupMemberReviewCreateFormSchema = z.object({
  feedbackMembers: z.array(meetupMemberReviewSchema),
});

export const initMeetupMemberReviewCreateFormData: GroupMeetupMemberReviewRequestForm = {
  feedbackMembers: [],
};
