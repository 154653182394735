import { Spacing } from '@community-group/components';
import { Fragment } from 'react';

import { MemberGradeMission } from '@/components/group/DetailV2/hooks/useMemberGradeMissionList';

import MemberGradeMissionItem from './MemberGradeMissionItem';
import * as s from './MemberGradeMissionList.css';

type Props = {
  missionList: MemberGradeMission[];
};

const MemberGradeMissionList = ({ missionList }: Props) => {
  const handleMissionItemClick = (item: MemberGradeMission) => () => {
    if (item.isCompleted) return;
    item.onClick();
  };

  return (
    <ul className={s.MissionList}>
      {missionList.map((item, i) => (
        <Fragment key={item.title}>
          <MemberGradeMissionItem
            key={item.title}
            icon={item.icon}
            title={item.title}
            count={item.count}
            goalCount={item.goalCount}
            isCompleted={item.isCompleted}
            onClick={handleMissionItemClick(item)}
          />
          {i === missionList.length - 1 ? null : <Spacing size={8} />}
        </Fragment>
      ))}
    </ul>
  );
};

export default MemberGradeMissionList;
