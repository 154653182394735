import {
  ChallengeInfoPostDetail,
  GroupCurrentUser,
  GroupFeedMeetupInfoPresentation,
  PostDetail,
} from '@community-group/api/lib/group/models';
import { isSuperHost, Spacing } from '@community-group/components';
import { IconDot3VerticalLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { MouseEvent, useMemo } from 'react';
import React from 'react';

import { getGroupFeedType } from '../../../utils/getGroupFeedType';
import FeedItemContents from './FeedItemContents';
import FeedItemFeedbackBox from './FeedItemFeedbackBox';
import FeedItemProfile from './FeedItemProfile';
import * as s from './index.css';

type Props = {
  post: PostDetail;
  currentUser: GroupCurrentUser;
  meetup?: GroupFeedMeetupInfoPresentation;
  challenge?: ChallengeInfoPostDetail;
  onClick?: () => void;
  onProfileClick?: (e: MouseEvent) => void;
  onProfileButtonClick?: (e: MouseEvent) => void;
  onMeetupBannerClick?: (e: MouseEvent) => void;
  onPollBannerClick?: (e: MouseEvent) => void;
  onRefetchFeed?: () => void;
  isLastItem?: boolean;
};

/**
 * @description post: PostDetail이 아닌 GroupFeedSummary로 통일이 되면 domain/GroupFeed/components/FeedItemV2 사용해주세요
 */
const FeedItem = ({
  post,
  currentUser,
  meetup,
  challenge,
  onClick,
  onProfileClick,
  onProfileButtonClick,
  onMeetupBannerClick,
  onPollBannerClick,
  onRefetchFeed,
  isLastItem,
}: Props) => {
  const groupId = post.groupInfo?.id.toString() ?? '';
  const feedType = useMemo(() => getGroupFeedType(post.postType), [post.postType]);
  const isChallenge = feedType === 'challenge';
  const isAuthor = currentUser.id === post.author.id;
  const hasNoPermissionForChallenge = !isAuthor && !isSuperHost(currentUser.role);

  // 챌린지 글이면서 일반 유저가 접근하는 경우 더보기 버튼 색상 회색으로 변경
  const moreButtonColor =
    isChallenge && hasNoPermissionForChallenge
      ? vars.$scale.color.gray300
      : vars.$scale.color.gray600;

  return (
    <motion.div
      className={clsx(['feed-item-wrapper', s.container])}
      key={post.id}
      style={{
        borderBottom: isLastItem ? 'none' : undefined,
      }}
    >
      <div className={s.wrapper} onClick={onClick} aria-hidden="true">
        <div className={s.header}>
          <FeedItemProfile post={post} currentUser={currentUser} onClick={onProfileClick} />
          <button onClick={onProfileButtonClick}>
            <IconDot3VerticalLine size={20} color={moreButtonColor} />
          </button>
        </div>
        <Spacing size={12} />
        <div style={{ width: '100%' }}>
          <FeedItemContents
            groupId={groupId}
            post={post}
            currentUserRole={currentUser.role}
            meetup={meetup}
            challenge={challenge}
            onMeetupBannerClick={onMeetupBannerClick}
            onPollBannerClick={onPollBannerClick}
          />
          {/* TODO: 챌린지 공유기능 개발 완료 후 오픈 */}
          {!isChallenge && (
            <>
              <Spacing size={12} />
              <FeedItemFeedbackBox
                post={post}
                groupId={groupId}
                refetchFeed={() => onRefetchFeed?.()}
              />
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default React.memo(FeedItem);
