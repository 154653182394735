import { HelpBubbleTrigger } from '@community-group/components';
import { IconPenHorizlineFill, IconPlusFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, PropsWithChildren, useState } from 'react';

import { useTimeout } from '@/hooks/useTimeout';

import * as s from './index.css';

const FLOATING_BUTTON = {
  DEFAULT: {
    icon: (size: number) => <IconPlusFill size={size} color={vars.$static.color.staticWhite} />,
    text: '모임 만들기',
  },
  MEETUP_CREATE: {
    icon: (size: number) => <IconPlusFill size={size} color={vars.$static.color.staticWhite} />,
    text: '일정 만들기',
  },
  POST: {
    icon: (size: number) => (
      <IconPenHorizlineFill size={size} color={vars.$static.color.staticWhite} />
    ),
    text: '글쓰기',
  },
};

type Props = {
  type?: keyof typeof FLOATING_BUTTON;
  hideText?: boolean;
  text?: string;
  isInitialPublishPost?: boolean;
  onClick: () => void;
};

const HelpBubbleWrapper: FC<
  PropsWithChildren<
    Pick<Props, 'isInitialPublishPost'> & {
      delay?: number;
    }
  >
> = ({ isInitialPublishPost, children, delay = 1000 }) => {
  const [isTimeout, setIsTimeout] = useState(false);

  useTimeout(() => {
    setIsTimeout(true);
  }, delay);

  if (!isInitialPublishPost) return <>{children}</>;

  return (
    <HelpBubbleTrigger
      isHidden={!isTimeout}
      title={`게시글을 작성하고\n 모임을 홍보해보세요!`}
      isOpen={isInitialPublishPost}
      portalled={false}
      isModal={false}
      UNSAFE_style={{
        whiteSpace: 'pre-line',
      }}
      positioning={{
        placement: 'bottom-end',
      }}
    >
      {children}
    </HelpBubbleTrigger>
  );
};
export const FloatingIcon = ({
  type = 'DEFAULT',
  hideText,
  isInitialPublishPost,
  onClick,
}: Props) => {
  return (
    <div className={s.Wrapper} onClick={onClick}>
      <HelpBubbleWrapper isInitialPublishPost={isInitialPublishPost}>
        <motion.button
          className={s.ButtonWrapper}
          style={{
            padding: hideText ? '1rem' : '0.75rem 1.125rem',
            height: hideText ? '3.5rem' : '100%',
          }}
        >
          <motion.div className={s.ButtonTextWrapper}>
            {FLOATING_BUTTON[type].icon(hideText ? 24 : 16)}

            <>
              {!hideText && <div style={{ width: '4px' }} />}
              <AnimatePresence>
                <motion.div
                  variants={container}
                  className={s.ButtonText}
                  animate={hideText ? 'hidden' : 'show'}
                  initial="show"
                >
                  <motion.div variants={textVariants}>{FLOATING_BUTTON[type].text}</motion.div>
                </motion.div>
              </AnimatePresence>
            </>
          </motion.div>
        </motion.button>
      </HelpBubbleWrapper>
    </div>
  );
};

const container = {
  hidden: {
    width: 0,
    opacity: 0,
    transition: {
      delayChildren: 0,
    },
  },
  show: {
    width: 'auto',
    height: 'auto',
    transition: {
      delayChildren: 0.2,
    },
  },
};
const textVariants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
  show: {
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
};
