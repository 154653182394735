import { Divider, Spacing, ViewLoader } from '@community-group/components';
import { withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import MemberGradeSpecSection from '@/features/GroupMember/MemberGradeSpec';
import MyMemberGradeSection from '@/features/GroupMember/MyMemberGrade';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupMemberGradeDetailPageParams = Pick<
  PageParams,
  'groupId' | 'userId' | 'isBannerApplication' | 'from'
>;

const GroupMemberGradeDetailPage: ActivityComponentType<GroupMemberGradeDetailPageParams> = () => {
  const { groupId, userId } = usePathParams();
  const { isBannerApplication, from } = useQueryParams();

  useEnterTrackEvent({
    event: 'enter_member_grade',
    params: {
      groupId,
      userId,
      from,
    },
  });

  return (
    <AppScreen>
      <MyMemberGradeSection
        groupId={groupId}
        userId={userId}
        isBannerApplication={isBannerApplication === 'true'}
      />
      <Divider size={8} />
      <MemberGradeSpecSection groupId={groupId} />
      <Spacing size={120} />
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupMemberGradeDetailPage, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
