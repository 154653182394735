import { useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';

export type UserInfoForTrackEvent = {
  joinedGroupCount: number;
};

export const useUserInfoForTrackEvent = (): UserInfoForTrackEvent => {
  const { data } = useGetMyGroupList();

  return {
    joinedGroupCount: data?.groups?.length,
  };
};
