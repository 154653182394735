import * as s from './style.css';

type Props = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const HideScrollBar = ({ children, style, ...rest }: Props) => {
  return (
    <section className={s.HideScrollBarWrapper} style={{ ...style }} {...rest}>
      {children}
    </section>
  );
};

export default HideScrollBar;
