import { vars } from '@seed-design/design-token';
import React, { CSSProperties, forwardRef, ReactNode } from 'react';
import { $Keys } from 'utility-types';

export type TypographyValue = $Keys<typeof vars.$semantic.typography>;
export type ColorValue = $Keys<
  typeof vars.$scale.color & typeof vars.$semantic.color & typeof vars.$static.color
>;

interface BaseProps {
  children?: ReactNode;
  typography: TypographyValue;
  color?: ColorValue;
  ellipsisAfterLines?: number;
  textAlign?: CSSProperties['textAlign'];
  wordBreak?: CSSProperties['wordBreak'];
  whiteSpace?: CSSProperties['whiteSpace'];
}

type TypographyElements =
  | 'span'
  | 'p'
  | 'strong'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'div'
  | 'button';

type TypographyProps = BaseProps & {
  as?: TypographyElements;
} & Omit<React.HTMLAttributes<HTMLElement>, 'as'>;

const multilineEllipticalTypography = (numberOfLines: number, whiteSpace?: string) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: numberOfLines,
  WebkitBoxOrient: 'vertical',
  whiteSpace: whiteSpace ?? 'pre-wrap',
});

const getColor = (color: ColorValue) => {
  if (color in vars.$scale.color) {
    return vars.$scale.color[color as $Keys<typeof vars.$scale.color>];
  }

  if (color in vars.$semantic.color) {
    return vars.$semantic.color[color as $Keys<typeof vars.$semantic.color>];
  }

  if (color in vars.$static.color) {
    return vars.$static.color[color as $Keys<typeof vars.$static.color>];
  }

  return vars.$scale.color.gray900;
};

const getEllipsisAfterLines = (ellipsisAfterLines?: number, whiteSpace?: string) => {
  if (!ellipsisAfterLines) return {};

  return {
    wordBreak: 'break-all',
    ...multilineEllipticalTypography(ellipsisAfterLines, whiteSpace),
  };
};

export const Typography = forwardRef((props: TypographyProps, ref: React.Ref<any>) => {
  const {
    as: Component = 'span',
    children,
    color = 'gray900',
    ellipsisAfterLines,
    style,
    wordBreak,
    textAlign,
    typography,
    whiteSpace,
    ...rest
  } = props;

  const isSingleLine = ellipsisAfterLines !== undefined && ellipsisAfterLines === 1;

  const typographyStyle = vars.$semantic.typography[typography];

  return (
    <Component
      ref={ref}
      style={{
        ...style,
        textAlign,
        wordBreak: isSingleLine ? 'normal' : wordBreak,
        color: getColor(color),
        display: 'inline-block',
        ...typographyStyle,
        ...getEllipsisAfterLines(ellipsisAfterLines, whiteSpace),
      }}
      {...rest}
    >
      {children}
    </Component>
  );
});
