import {
  BoxButton,
  Spacing,
  Typography,
  useFocusElement,
  useScrollPicker,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { Dispatch, useRef, useState } from 'react';

import * as stackflow from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import * as s from './RequirementCountSelectorBottomSheet.css';

type Params = Pick<PageParams, 'title' | 'selectedCount'>;

const RequirementCountSelectorBottomSheet: ActivityComponentType<Params> = () => {
  const { title, selectedCount } = useQueryParams();
  const [countValue, setCountValue] = useState(Number(selectedCount ?? '0'));

  const connectedFlow = stackflow?.useConnectedFlow();

  const handleConfirm = () => {
    connectedFlow.pop().send({
      selectedCount: countValue,
    });
  };

  return (
    <BottomSheet>
      <Spacing size={4} />
      <Typography typography="title2Bold" color="gray900">
        {title}
      </Typography>
      <RequirementCountSelector selectedCount={countValue} setSelectedCount={setCountValue} />
      <BoxButton variant="primary" size="xlarge" width="100%" onClick={handleConfirm}>
        확인
      </BoxButton>
    </BottomSheet>
  );
};

type Props = {
  selectedCount: number;
  setSelectedCount: Dispatch<React.SetStateAction<number>>;
};

const RequirementCountSelector = ({ selectedCount, setSelectedCount }: Props) => {
  const ref = useRef<HTMLUListElement>(null);
  const height = 46;
  const gap = 17;

  const { focused, onFocus, onBlur } = useFocusElement();
  const handleTouchEnd = () => onBlur();
  const handleTouchStart = () => onFocus();

  const selectList = Array.from({ length: 1001 }, (_, index) => index);
  const { handleScroll, handleSelectItem } = useScrollPicker({
    ref,
    unit: height + gap,
    selectedItemIndex: selectList.findIndex((item) => item === selectedCount),
    selectList,
    setSelectedItem: setSelectedCount,
  });

  const getItemColorStyle = (selected: boolean) => {
    if (selected) {
      if (focused) return vars.$scale.color.carrot500;
      return vars.$scale.color.gray900;
    }

    if (focused) return vars.$scale.color.carrot200;
    return vars.$scale.color.gray300;
  };

  return (
    <div className={s.Wrapper} style={{ height: `${height * 5 + gap * 4}px` }}>
      <ul
        ref={ref}
        className={s.CountList}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onScroll={handleScroll}
        style={{
          gridGap: `${gap}px`,
        }}
      >
        <div
          style={{
            height: `${height}px`,
            width: '100%',
            position: 'absolute',
            top: 0,
            background: `linear-gradient(180deg, ${vars.$semantic.color.paperSheet} 0%, rgba(255, 255, 255, 0.00) 100%)`,
          }}
        />
        <div className={s.CountListAlignmentItem} style={{ height: `${height * 2}px` }} />
        {selectList.map((item, index) => (
          <li
            key={`requirement-count-${item}`}
            className={s.CountItem({ selected: item === selectedCount })}
            onClick={() => handleSelectItem(index)}
            style={{
              height: `${height}px`,
              color: getItemColorStyle(item === selectedCount),
            }}
          >
            {item}회
          </li>
        ))}
        <div className={s.CountListAlignmentItem} style={{ height: `${height * 2}px` }} />
        <div
          style={{
            height: `${height}px`,
            width: '100%',
            position: 'absolute',
            bottom: 0,
            background: `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, ${vars.$semantic.color.paperSheet} 100%)`,
          }}
        />
      </ul>
    </div>
  );
};

export default RequirementCountSelectorBottomSheet;
