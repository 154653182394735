import { DismissableInlineAlert } from '@daangn/sprout-components-inline-alert';

import { useFlow } from '@/stackflow';

import useGroupDetailMemberGradeNoticeBannerClosed from '../../hooks/useGroupDetailMemberGradeNoticeBannerClosed';

type Props = { groupId: string };

const GroupDetailMemberGradeNoticeBanner = ({ groupId }: Props) => {
  const { push } = useFlow();
  const handleClick = (event) => {
    const contentDiv = event.currentTarget.querySelector('[data-part="content"]');
    if (!contentDiv || !contentDiv.contains(event.target)) return;
    push('GroupSettingMemberGradePage', {
      groupId,
      from: 'noticeBanner',
    });
  };

  const [_, addMemberGradeNoticeBannerShownCount] =
    useGroupDetailMemberGradeNoticeBannerClosed(groupId);

  return (
    <div onClick={handleClick}>
      <DismissableInlineAlert
        description="드디어 정회원 기능이 생겼어요! 등업 조건을 설정해보세요."
        variant="info"
        onClose={addMemberGradeNoticeBannerShownCount}
      />
    </div>
  );
};

export default GroupDetailMemberGradeNoticeBanner;
