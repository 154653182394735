import { Dialog, useDialog, withAsyncBoundary } from '@community-group/components';
import { useSuspenseQueries } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useSnackbar } from '@/_app/providers/UIOverlayProvider';
import { GradeId } from '@/domain/GroupMember/constants';
import { usePutGroupMemberGrade } from '@/domain/GroupMember/hooks/usePutGroupMemberGrade';
import { useQueryGroupMemberGrades } from '@/domain/GroupMember/hooks/useReadGroupMemberGrades';
import { GROUP_MEMBER_QUERY_KEY } from '@/domain/GroupMember/queries';
import { GroupManagerFormValues } from '@/features/GroupSetting/util/groupManagerForm';
import { queryClient } from '@/shared/api/instance';
import { AppBarTextSubmitButton } from '@/stackflow/components/AppBarTextSubmitButton';

type Props = {
  groupId: string;
};
const GroupManagerSettingRenderRight = ({ groupId }: Props) => {
  const { watch } = useFormContext<GroupManagerFormValues>();

  const { open: openDialog, close: closeDialog } = useDialog();
  const { open: openSnackbar } = useSnackbar();

  const [{ data: memberGrades }] = useSuspenseQueries({
    queries: [useQueryGroupMemberGrades(groupId)],
  });

  const managerGradeId =
    memberGrades.find((grade) => grade.role === 'manager')?.id ?? GradeId.MANAGER;
  const { mutate: putMemberGrade, isPending } = usePutGroupMemberGrade({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: GROUP_MEMBER_QUERY_KEY.membersInfinite({ id: Number(groupId) }),
      });
    },
  });

  const initialManager = watch('initialManager');
  const newManager = watch('newManager');

  const managerPromoteUsers = useMemo(
    () => newManager.filter(({ id }) => !initialManager.some((u) => u.id === id)),
    [initialManager, newManager]
  );
  const managerDemoteUsers = useMemo(
    () => initialManager.filter(({ id }) => !newManager.some((u) => u.id === id)),
    [initialManager, newManager]
  );

  const promoteState = useMemo(() => {
    const promoteCount = newManager.length;
    const demoteCount = managerDemoteUsers.length;
    if (promoteCount > 0 && demoteCount > 0) return 'promoteAndDemote';
    if (promoteCount > 0) return 'promote';
    if (demoteCount > 0) return 'demote';
    return 'none';
  }, [managerDemoteUsers, newManager]);

  const isButtonDisabled = useMemo(() => {
    return isEqual(initialManager, newManager);
  }, [initialManager, newManager]);

  const getDescription = useCallback(
    (isConfirmation = false) => {
      if (promoteState === 'demote') {
        const nickname = managerDemoteUsers[0]?.nickname;
        const count = managerDemoteUsers.length - 1;
        const suffix = isConfirmation ? '할까요?' : '했어요.';
        if (!count) return `${nickname}님을 운영진에서 제외${suffix}`;
        return `${nickname}님외 ${count}명을 운영진에서 제외${suffix}`;
      }

      const nickname = newManager[0]?.nickname;
      const count = newManager.length - 1;
      const suffix = isConfirmation ? '할까요?' : '했어요.';
      if (!count) return `${nickname}님을 운영진으로 설정${suffix}`;
      return `${nickname}님외 ${count}명을 운영진으로 설정${suffix}`;
    },
    [managerDemoteUsers, newManager, promoteState]
  );

  const handleMutate = async () => {
    if (isPending) return;

    const promoteResults = managerPromoteUsers.map(({ id }) =>
      putMemberGrade({ groupId, userId: id, gradeId: managerGradeId })
    );
    const demoteResults = managerDemoteUsers.map(({ id }) =>
      putMemberGrade({ groupId, userId: id, gradeId: managerGradeId })
    );

    await closeDialog();

    const results = await Promise.allSettled([...promoteResults, ...demoteResults]);

    const failures = results.filter((result) => result.status === 'rejected').length;

    if (failures > 0) {
      openSnackbar({
        message: `일부 멤버(${failures}명)의 권한을 변경하지 못했어요.`,
      });
    } else {
      openSnackbar({
        message: getDescription(),
      });
    }
  };

  const handleSubmit = () => {
    if (isPending) return;

    if (promoteState === 'none') return;

    openDialog({
      element: (
        <Dialog
          title="운영진 설정"
          primaryActionLabel="확인"
          secondaryActionLabel="취소"
          description={getDescription(true)}
          onPrimaryAction={handleMutate}
          onSecondaryAction={closeDialog}
        />
      ),
    });
  };

  return <AppBarTextSubmitButton disabled={isButtonDisabled} onClick={handleSubmit} />;
};

export default withAsyncBoundary(GroupManagerSettingRenderRight, {
  rejectedFallback: <></>,
  pendingFallback: <AppBarTextSubmitButton disabled />,
});
