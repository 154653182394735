import {
  UploadVideoValidationError,
  useDialog,
  useVideoFileHandler,
  VideoFileHandlerProps,
  VideoUploadErrorType,
} from '@community-group/components';
import { useMemo } from 'react';

import { useUploadVideo } from '@/api/base/uploadVideo';
import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { StackflowTheme } from '@/components/common/TransparentNavScreen/Navbar';
import AlertModal from '@/components/group/PostForm/components/AlertModal';
import { useTheme } from '@/stackflow/hooks/useTheme';

type Props = Partial<VideoFileHandlerProps> & {
  uploadedImagesCount?: number;
  externalOnValidationErrorHandler?: (error: UploadVideoValidationError) => void;
};

const MAX_UPLOAD_VIDEO_COUNT = 10;

// 에러 우선순위 설정
const ERROR_PRIORITY: { [key in VideoUploadErrorType]: number } = {
  fileCount: 1, // 파일 개수 초과 에러
  videoDuration: 2, // 동영상 길이 에러
  fileSize: 3, // 파일 크기 에러
  videoDurationError: 4, // 동영상 길이 메타데이터 에러 - 제보 https://daangn.slack.com/archives/C03RY6TNUBD/p1728970900108469
  fileFormat: 5, // 파일 형식 에러
  fileType: 6, // 파일 타입 에러
  undefinedFile: 7, // 파일 없음 에러
  uploadError: 8, // 업로드 에러
};

const useGroupHandleVideoUpload = ({
  uploadedImagesCount,
  externalOnValidationErrorHandler,
  ...rest
}: Props) => {
  const fetchInstance = useFetchInstance();
  const { mutateAsync: mutateUploadVideo } = useUploadVideo({ fetchInstance });
  const { open: openDialog, close: closeDialog } = useDialog();

  const { getTheme } = useTheme();
  const deviceType = useMemo(() => getTheme(), []) as StackflowTheme;

  const onValidationError = (error: UploadVideoValidationError[]) => {
    const sortPrimaryError = error.sort((a, b) => ERROR_PRIORITY[a.type] - ERROR_PRIORITY[b.type]);

    const primaryError = sortPrimaryError[0];

    if (externalOnValidationErrorHandler) return externalOnValidationErrorHandler(primaryError);
    openDialog({
      element: (
        <AlertModal
          title={primaryError.message.title}
          description={primaryError.message.description ?? ''}
          primaryActionLabel={'확인'}
          onPrimaryAction={closeDialog}
          onClose={closeDialog}
        />
      ),
    });
  };

  return useVideoFileHandler({
    theme: deviceType,
    validationConfig: {
      maxVideoCount: MAX_UPLOAD_VIDEO_COUNT - (uploadedImagesCount ?? 0),
    },
    uploadVideo: async ({ file, onProgress }) => {
      const data = await mutateUploadVideo({
        file,
        onProgress,
      });
      return data;
    },
    onValidationError,
    ...rest,
  });
};

export default useGroupHandleVideoUpload;
