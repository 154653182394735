import {
  AsyncBoundary,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useCallback, useEffect, useState } from 'react';

import { useBridge } from '@/contexts/Bridge';
import { useReadSeoulRunUserState } from '@/domain/SeoulRunPromotion/hook/useReadUserState';
import useEnableSeoulRunPromotion from '@/features/SeoulRunPromotion/hooks/useEnableSeoulRunPromotion';
import LoadingPositionView from '@/features/SeoulRunPromotion/LoadingPositionView';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { HOME_ACTIVE_TAB } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

type Params = Pick<PageParams, 'placeId'>;

type Position = {
  latitude: number;
  longitude: number;
};

// 서울런 프로모션 QR을 통해 경품 수령이 가능한지 확인하는 페이지
const SeoulRunQRCheckPromotionPage: ActivityComponentType<Params> = () => {
  const { placeId } = usePathParams();
  const { replace } = useFlow();

  const enableSeoulRun = useEnableSeoulRunPromotion();

  const { bridge } = useBridge();

  const [position, setPosition] = useState<Position | null>(null);

  const handelCheckGPSCheckFlow = useCallback(async () => {
    if (document.visibilityState !== 'visible') return;

    const result = await bridge.getCurrentPosition({ systemSettingPopupEnabled: true });
    if (
      !result.geolocation.currentPosition?.position.latitude ||
      !result.geolocation.currentPosition?.position.longitude
    )
      return;

    setPosition(result.geolocation.currentPosition.position);
  }, [bridge]);

  useEffect(() => {
    // 서울런 프로모션이 비활성화 되어 있거나, placeId가 없는 경우 404 페이지로 이동

    if (!placeId || !enableSeoulRun) {
      replace('NotFoundPage', {});
      return;
    }

    handelCheckGPSCheckFlow();
    window.addEventListener('visibilitychange', handelCheckGPSCheckFlow);
    return () => {
      window.removeEventListener('visibilitychange', handelCheckGPSCheckFlow);
    };
  }, [bridge, handelCheckGPSCheckFlow, placeId, replace, enableSeoulRun]);

  return (
    <AppScreen>
      {position ? (
        <AsyncBoundary pendingFallback={<ViewLoader />}>
          <CheckPromoTarget position={position} placeId={placeId} />
        </AsyncBoundary>
      ) : (
        <LoadingPositionView />
      )}
    </AppScreen>
  );
};

const CheckPromoTarget = ({ placeId, position }: { placeId: string; position: Position }) => {
  const { data: seoulRunUserState } = useReadSeoulRunUserState({
    latitude: position.latitude.toString(),
    longitude: position.longitude.toString(),
  });

  const { replace, push } = useFlow();

  const handleGoHomePage = useCallback(() => {
    replace(
      'HomePage',
      {
        activeHomeTab: HOME_ACTIVE_TAB.SEOUL_HANGANGRUN_PROMOTION_TAB,
      },
      {
        animate: false,
      }
    );
  }, [replace]);

  useEffect(() => {
    const currentPlace = seoulRunUserState.placeState?.find((place) => place.placeId === placeId);

    // 1. 찾을 수 없는 PlaceId가 있는 경우 -> 404
    if (!currentPlace || !placeId) {
      replace('NotFoundPage', {
        animate: false,
      });
      return;
    }

    // 2. 프로모션이 종료된 경우 -> 안내 다이얼로그 띄움
    if (currentPlace.userState === 'ended_promotion') {
      handleGoHomePage();
      push('SimpleAlertDialog', {
        title: '이미 종료된 이벤트에요',
        description:
          '2025년 4월 15일부터 5월 15일까지 진행된 이벤트로 현재 종료되어 더이상 참여할 수 없어요.',
        primaryLabel: '확인',
      });

      return;
    }

    // 3. 일정 미참여자인 경우 -> 안내 다이얼로그 띄움
    if (currentPlace.userState === 'not_joined') {
      handleGoHomePage();
      push('SimpleAlertDialog', {
        title: '이벤트 상품을 받으려면 모임 일정에 참여해주세요',
        description: '모임에 가입하고 일정에 참여한 경우에만 보물상자 속 상품을 확인할 수 있어요.',
        primaryLabel: '이벤트 자세히 보기',
      });
      return;
    }

    // 3. 현재 유저의 GPS 위치와 PlaceId 위치가 다른 경우 -> 위치 오류 다이얼로그로 띄움
    if (currentPlace.userState === 'not_correct_location') {
      handleGoHomePage();
      push('SimpleAlertDialog', {
        title: '이벤트 위치에서 벗어났어요.', //TODO: 어쩌구저쩌구
        description: '모임에 가입하고 일정에 참여한 경우에만 보물상자 속 상품을 확인할 수 있어요.',
        primaryLabel: '이벤트 자세히 보기',
      });
      return;
    }

    // 4. 이미 경품 수령한 경우 -> 안내 다이얼로그 띄움
    if (currentPlace.userState === 'prize_received') {
      handleGoHomePage();
      push('SimpleAlertDialog', {
        title: '이미 상품을 받은 보물상자에요',
        description:
          '이미 상품을 받은 보물상자는 다시 열 수 없어요. 다른 지역에 숨어져있는 보물을 찾아보세요!',
        primaryLabel: '이벤트 자세히 보기',
      });
      return;
    }

    // 5. pay에서 지급이 거절된 경우 -> 페이 에러 페이지로 이동
    if (currentPlace.userState === 'pay_rejected') {
      return;
    }

    // 6. 페이 미가입자인 경우 -> 페이 가입 플로우 태우기 -> onSuccessReplace로 다시 route page로 오기
    if (!seoulRunUserState.isPayMember) {
      replace('KarrotPaymentsCreateAccountPage', {
        karrotPaymentsCreateAccountQueryParamsWhere: 'group_promotion_seoul_run',
        onSuccessReplacePage: 'SeoulRunQRCheckPromotionPage',
        onSuccessReplaceParams: JSON.stringify({
          placeId,
        }),
      });
      return;
    }

    // 7. 페이 가입자 & 경품 수령 가능한 케이스(joined)인 경우 => 경품 수령 페이지로 이동
    if (currentPlace.userState === 'joined') {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    seoulRunUserState.isPayMember,
    seoulRunUserState.placeState,
    placeId,
    handleGoHomePage,
    push,
  ]);

  return (
    <div>
      <ViewLoader />
    </div>
  );
};

export default withAsyncBoundary(SeoulRunQRCheckPromotionPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
