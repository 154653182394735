import { BoxButton, Spacing, Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { SEOUL_RUN_PROMOTION_CONFIG } from '@/features/SeoulRunPromotion/config';
import TransparentNavBar from '@/features/SeoulRunPromotion/LocationListSection/TransparentNavBar';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { openLink } from '@/utils/link';

import * as s from './style.css';

const COIN_DROP_PNG =
  'https://asset-town.krrt.io/production/motion/b43d9ec8-7397-4235-91d3-1dc9b423fe5f/eaa375194e90fd0ecba8c9738775cf18c975ede1.png';

type Props = Pick<PageParams, 'groupId'>;

// 모임 상세 > 일정 리스트 > 한강공원 보물찾기 이벤트 > 플로우로 진입시 뜨는 페이지
const SeoulRunPromotionGuidePage: ActivityComponentType<Props> = () => {
  const { replace } = useFlow();
  const { groupId } = usePathParams();

  const handleOpenChallengePromotionLandkit = () => {
    openLink(SEOUL_RUN_PROMOTION_CONFIG.LANDKIT_PAGE, true);
  };

  const moveToSeoulRunLocationList = () => {
    replace('SeoulRunLocationListPage', {
      groupId,
    });
  };

  return (
    <AppScreen
      hasAppBar={false}
      accessoryBar={
        <div className={s.ButtonContainer}>
          <BoxButton
            variant="secondary"
            size="xlarge"
            width={'100%'}
            onClick={handleOpenChallengePromotionLandkit}
          >
            더 알아보기
          </BoxButton>
          <BoxButton
            variant="primary"
            size="xlarge"
            width={'100%'}
            onClick={moveToSeoulRunLocationList}
          >
            일정 만들기
          </BoxButton>
        </div>
      }
    >
      <div className={s.Container}>
        <TransparentNavBar isTransparent={true} />
        <div className={s.InnerContentsContainer}>
          <div className={s.EventChip}>
            <Typography typography="subtitle2Bold" color="staticWhite">
              EVENT
            </Typography>
          </div>
          <Spacing size={8} />
          <Typography typography="h4" textAlign="center" style={{ zIndex: 1000 }}>
            한강 보물찾기런 참여하고
            <br />
            어쩌구저쩌꾸세요
          </Typography>
          <Spacing size={8} />
          <Typography typography="subtitle2Regular" color="gray600" className={s.MoreButton}>
            한강 어쩌구저쩌구 샬라샬라 이벤트 소개에용
          </Typography>
          <Spacing size={8} />
          <img src={COIN_DROP_PNG} alt="동전지갑 이미지" className={s.CoinWalletImage} />
          <Spacing size={40} />
          <div className={s.ListContainer}>
            <div className={s.NumberContainer}>
              <Typography typography="caption1Bold" color="gray700">
                1
              </Typography>
            </div>
            <div className={s.ListItem}>
              <Typography typography="bodyL1Regular" color="gray900">
                6개의 한강공원 중 한 곳을 선택하고
              </Typography>
            </div>
          </div>
          <Spacing size={12} />
          <div className={s.ListContainer}>
            <div className={s.NumberContainer}>
              <Typography typography="caption1Bold" color="gray700">
                2
              </Typography>
            </div>
            <div className={s.ListItem}>
              <Typography typography="bodyL1Regular" color="gray900">
                보물찾기런 일정을 만들어 보물지도를 받아요
              </Typography>
              <Spacing size={2} />
              <Typography typography="caption2Regular" color="gray600">
                보물지도는 일정 채팅방에서 확인할 수 있어요
              </Typography>
            </div>
          </div>
          <Spacing size={12} />
          <div className={s.ListContainer}>
            <div className={s.NumberContainer}>
              <Typography typography="caption1Bold" color="gray700">
                3
              </Typography>
            </div>
            <div className={s.ListItem}>
              <Typography typography="bodyL1Regular" color="gray900">
                참여한 멤버들과 한강공원에 숨겨진 보물을 찾아보세요! 🏃‍♂️
              </Typography>
              <Spacing size={2} />
              <Typography typography="caption2Regular" color="gray600">
                이벤트 내용 확정되면 반영할게요
              </Typography>
            </div>
          </div>
          <Spacing size={36} />
        </div>
      </div>
    </AppScreen>
  );
};

export default SeoulRunPromotionGuidePage;
