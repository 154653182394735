import { GroupMeetupModifyForm } from '@community-group/api/lib/group/models';
import { BoxButton, Spacing } from '@community-group/components';
import { Divider, useKeyboardSize, ViewLoader } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { useReadGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { GroupMeetupFormDescriptionField } from '@/components/group/Meetup/Form/components/Field/Description';
import { GroupMeetupFormImagesField } from '@/components/group/Meetup/Form/components/Field/Images';
import { GroupMeetupFormMaximumParticipantsNumberField } from '@/components/group/Meetup/Form/components/Field/MaximumParticipantsNumber';
import { GroupMeetupFormMeetupDateTimeField } from '@/components/group/Meetup/Form/components/Field/MeetupDateTime';
import { GroupMeetupFormPoiField } from '@/components/group/Meetup/Form/components/Field/Poi';
import { GroupMeetupFormTitleField } from '@/components/group/Meetup/Form/components/Field/Title';
import {
  closeListItemFormFieldQuerySelector,
  getFormFieldQuerySelector,
} from '@/components/group/Meetup/Form/utils/selector';
import { useBridge } from '@/contexts/Bridge';
import MeetupFormTopCallout from '@/features/SeoulRunPromotion/MeetupFormTopCallout';
import CreateMeetupGuideCallout from '@/features/SeoulRunPromotion/New/CreateMeetupGuideCallout';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { formatDate, KARROT_DATE_FORMAT } from '@/utils/date';
import { meetupFieldSchema, meetupModifyFormSchema } from '@/utils/validate/formSchema/meetup';
import { convertHookFormErrorObjectToArray } from '@/utils/validate/util';

import * as s from './index.css';

export type SeoulRunMeetupEditPageParams = Pick<PageParams, 'groupId' | 'meetupId'>;

const SeoulRunMeetupEditPage: ActivityComponentType<SeoulRunMeetupEditPageParams> = () => {
  const { groupId, meetupId } = usePathParams();
  const { push } = useFlow();
  const pop = useBack();
  const { bridge } = useBridge();
  const { isKeyboardOn } = useKeyboardSize(bridge);

  const [pageLoading, setPageLoading] = useState(true);
  const { data, isLoading: isFetchLoading } = useReadGroupMeetupDetail({
    groupId,
    meetupId,
  });

  const hookForm = useForm<GroupMeetupModifyForm>({
    resolver: zodResolver(meetupModifyFormSchema),
  });

  useEffect(() => {
    try {
      if (!data) return;

      const currentMeetupTimeAt = data.meetupTimeAt ?? '';
      const meetupTimeAt = data.hasMeetupTimeAtOnlyDate
        ? currentMeetupTimeAt.split('T')[0]
        : currentMeetupTimeAt;

      const initGroupMeetupModifyFormData: GroupMeetupModifyForm = {
        title: data.title,
        description: data.description,
        maximumParticipantsNumber: data.maximumParticipantsNumber,
        hasMeetupTimeAtOnlyDate: !!data.hasMeetupTimeAtOnlyDate,
        meetupTimeAt: formatDate(new Date(meetupTimeAt), KARROT_DATE_FORMAT),
        exposureRange: data.exposureRange,
        poiItems: data.poi ? [{ id: data.poi.id, type: data.poi.type }] : [],
        images: data.images.map(({ id }) => id),
        canJoinAnyMember: data.canJoinAnyMember,
      };

      setPageLoading(false);
      return hookForm.reset(initGroupMeetupModifyFormData);
    } catch (e) {
      bridge.openToast({
        toast: { body: '문제가 발생했어요. 잠시 후에 다시 시도해 주세요.' },
      });

      pop();
    }
  }, [data]);

  const { handleSubmit: handleFormSubmit, watch } = hookForm;
  const isDisabledButton = useMemo(() => {
    if (pageLoading) return true;

    if (isKeyboardOn) return false;

    if (watch('title').length < (meetupFieldSchema.title.minLength ?? 1)) return true;

    if (
      (watch('images') ?? [])?.filter(
        (imageId) => imageId.includes('temp') || imageId.includes('error')
      ).length > 0
    )
      return true;

    return false;
  }, [pageLoading, isKeyboardOn, watch('title'), watch('images')]);

  const handleSubmit = () => {
    if (isKeyboardOn) return;

    if (watch('maximumParticipantsNumber') < (data?.currentParticipantsNumber ?? 1)) {
      return bridge.openToast({
        toast: {
          body: '최대 참여인원수는 현재 모임 참여자 수 보다 작을 수 없어요.',
        },
      });
    }

    handleFormSubmit(
      (data) => {
        push('GroupMeetupEditOptionsPage', {
          groupId,
          meetupId,
          meetupModifyForm: JSON.stringify(data),
        });
      },
      (errors) => {
        const errorList = convertHookFormErrorObjectToArray(errors);

        const firstError = errorList[0];

        bridge.openToast({
          toast: {
            body: firstError.value.message ?? '',
          },
        });
      }
    )();
  };

  return (
    <AppScreen
      appBar={{
        backButton: {
          render: () => (
            <div className={s.CloseButtonWrapper} onClick={pop}>
              <IconXmarkLine />
            </div>
          ),
        },
        closeButton: {
          render: () => (
            <div className={s.CloseButtonWrapper} onClick={pop}>
              <IconXmarkLine />
            </div>
          ),
        },
      }}
      accessoryBar={
        !pageLoading &&
        !isFetchLoading && (
          <div
            className={s.AccessoryBarWrapper}
            style={{
              padding: isKeyboardOn
                ? '0'
                : '0.75rem 1rem calc(0.5rem + env(safe-area-inset-bottom)) 1rem',
            }}
          >
            <BoxButton
              width="100%"
              onClick={handleSubmit}
              isDisabled={isDisabledButton}
              size="xlarge"
              UNSAFE_style={isKeyboardOn ? { borderRadius: 0 } : undefined}
            >
              다음
            </BoxButton>
          </div>
        )
      }
    >
      {pageLoading || isFetchLoading ? (
        <ViewLoader />
      ) : (
        <form>
          <GroupMeetupEditView hookForm={hookForm} />
        </form>
      )}
    </AppScreen>
  );
};

export default SeoulRunMeetupEditPage;

type Props = {
  hookForm: UseFormReturn<GroupMeetupModifyForm>;
};
const GroupMeetupEditView = ({ hookForm: { watch, setValue } }: Props) => {
  const { groupId, meetupId } = usePathParams();
  const { data } = useReadGroupMeetupDetail({
    groupId,
    meetupId,
  });

  return (
    <section className={s.Wrapper}>
      <MeetupFormTopCallout poiName={data?.poi?.name ?? ''} />
      <GroupMeetupFormTitleField
        name="title"
        watch={watch}
        setValue={setValue}
        placeholder="언제 어디서 보물찾기를 할까요?"
        maxLength={meetupFieldSchema.title.maxLength ?? 100}
        onClick={() => {
          closeListItemFormFieldQuerySelector(['meetupTimeAt', 'hasMeetupTimeAtOnlyDate']);
        }}
      />
      <GroupMeetupFormMeetupDateTimeField
        dateName="meetupTimeAt"
        onlyDateName="hasMeetupTimeAtOnlyDate"
        watch={watch}
        setValue={setValue}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider />
      <GroupMeetupFormPoiField
        name="poiItems.0"
        setValue={setValue}
        defaultValue={data?.poi?.name}
        placeholder="미정"
        onClick={() => {
          closeListItemFormFieldQuerySelector(['meetupTimeAt', 'hasMeetupTimeAtOnlyDate']);
        }}
      />
      <Divider />
      <GroupMeetupFormMaximumParticipantsNumberField
        name="maximumParticipantsNumber"
        watch={watch}
        setValue={setValue}
        onClick={() => {
          closeListItemFormFieldQuerySelector(['meetupTimeAt', 'hasMeetupTimeAtOnlyDate']);
        }}
      />
      <Divider />
      <GroupMeetupFormDescriptionField
        name="description"
        watch={watch}
        setValue={setValue}
        onFocus={() => {
          setTimeout(() => {
            const descriptionFieldDom = getFormFieldQuerySelector('description');

            descriptionFieldDom?.scrollIntoView({
              behavior: 'smooth',
            });
          }, 350);
        }}
        placeholder="활동에 대한 설명을 추가해주세요."
        onClick={() => {
          closeListItemFormFieldQuerySelector(['meetupTimeAt', 'hasMeetupTimeAtOnlyDate']);
        }}
      />
      <Divider />
      <GroupMeetupFormImagesField
        name="images"
        setValue={setValue}
        prevImages={
          data?.images?.map(({ id, small }) => ({
            id,
            src: small,
          })) ?? []
        }
      />
      <Spacing size={8} />
      <CreateMeetupGuideCallout />
    </section>
  );
};
