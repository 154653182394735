import { groupClient } from '@community-group/api';
import { PaginationQueryParams } from '@community-group/components/shared';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_MEETUP_QUERY_KEY } from '../queries';

type Props = {
  regionId: number;
  meetupStatus?: ('opened' | 'fullyRecruited' | 'closed' | 'deleted' | 'canceled')[];
} & PaginationQueryParams;

export const useReadGroupMeetupsByRegion = ({
  regionId,
  meetupStatus = ['opened'],
  initialCursor = undefined,
}: Props) => {
  const read = useAsyncRead(groupClient.api.MeetupApi.apiV1GroupMeetupsGet);

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: GROUP_MEETUP_QUERY_KEY.groupMeetupsByRegion(regionId, meetupStatus),
      queryFn: ({ pageParam = null }) =>
        read({
          cursor: pageParam ? pageParam : undefined,
          limit: 20,
          regionId,
          progressStatuses: meetupStatus,
        }),
      initialPageParam: initialCursor,
      getNextPageParam: (lastPage) =>
        lastPage.hasNext ? lastPage.endCursor ?? undefined : undefined,
      select: (data) => data.pages.flatMap((page) => page.meetups ?? []),
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
