import { Render, Spacing } from '@community-group/components';

import { Breadcrumbs as CommonBreadcrumbs } from '@/components/common/Breadcrumbs';
import { useReadIsSeoulRunMeetupPromotion } from '@/domain/SeoulRunPromotion/hook/useReadIsSeoulRunMeetupPromotion';
import { SEOUL_RUN_PROMOTION_CONFIG } from '@/features/SeoulRunPromotion/config';
import useIsSeoulRunPromotionLocation from '@/features/SeoulRunPromotion/hooks/useIsSeoulRunPromotionLocation';
import SeoulRunMeetupDetailBreadcrumb from '@/features/SeoulRunPromotion/SeoulRunMeetupDetailBreadcrumb';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from './MeetupDetailBreadcrumbs.css';

const MeetupDetailBreadcrumbs = ({ createdGroupRegionId }: { createdGroupRegionId: number }) => {
  const { groupId, meetupId } = usePathParams();
  const { push } = useFlow();

  const isSeoulRunPromotionLocation = useIsSeoulRunPromotionLocation({
    regionId: createdGroupRegionId,
  });
  const { data } = useReadIsSeoulRunMeetupPromotion({
    groupId: Number(groupId),
    meetupId: Number(meetupId),
  });
  const isSeoulRunMeetupPromotion = data.promotionType === SEOUL_RUN_PROMOTION_CONFIG.EVENT_TYPE;

  return (
    <>
      <div className={s.Container}>
        <CommonBreadcrumbs
          prefixIconType="post"
          items={['일정']}
          onClick={() => {
            push('GroupDetailMeetupPage', {
              groupId,
            });
          }}
        />
        <Render condition={isSeoulRunPromotionLocation && isSeoulRunMeetupPromotion}>
          <SeoulRunMeetupDetailBreadcrumb />
        </Render>
      </div>
      <Spacing size={4} />
    </>
  );
};

export default MeetupDetailBreadcrumbs;
