import { ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { Suspense } from 'react';

import GroupProfileDeletedAccount from '@/features/GroupProfile/DeletedAccount';
import GroupProfileMemberManageActivitySummary from '@/features/GroupProfileMemberManage/ActivitySummary';
import GroupProfileMemberManageApplicationInfo from '@/features/GroupProfileMemberManage/ApplicationInfo';
import GroupProfileMemberManageJoinedMeetup from '@/features/GroupProfileMemberManage/JoinedMeetup';
import GroupProfileMemberManage from '@/features/GroupProfileMemberManage/MemberManage';
import HideScrollBar from '@/shared/components/AppScreen/components/HideScrollBar';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupProfileMemberManagePageParams = Pick<PageParams, 'groupId' | 'userId'>;

const GroupProfileMemberManagePage: ActivityComponentType<
  GroupProfileMemberManagePageParams
> = () => {
  const { groupId, userId } = usePathParams();
  return (
    <AppScreen appBar={{ title: '멤버 관리' }}>
      <Suspense fallback={<ViewLoader />}>
        <HideScrollBar style={{ paddingBottom: '7.5rem' }}>
          <GroupProfileMemberManageActivitySummary groupId={groupId} userId={userId} />
          <GroupProfileMemberManageApplicationInfo groupId={groupId} userId={userId} />
          <GroupProfileMemberManageJoinedMeetup groupId={groupId} userId={userId} />
          <GroupProfileMemberManage groupId={groupId} userId={userId} />
        </HideScrollBar>
      </Suspense>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupProfileMemberManagePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <GroupProfileDeletedAccount />
    </AppScreen>
  ),
});
