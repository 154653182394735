import { Divider, Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { Fragment } from 'react';

import TransparentNavTitleShowObserver from '@/components/common/TransparentNavScreen/TransparentNavTitleShowObserver';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import getGroupStage from '@/domain/Group/utils/getGroupStage';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupFeedMeetupReviewsForNotMember } from '@/domain/GroupFeed/hooks/useReadGroupFeedMeetupReviewsForNotMember';
import { useQueryGroupFeedSummaryMeetup } from '@/domain/GroupFeed/hooks/useReadGroupFeedSummaryMeetup';
import { useQueryGroupLevel } from '@/domain/GroupLevel/hooks/useReadGroupLevel';
import { useQueryGroupMembers } from '@/domain/GroupMember/hooks/useReadGroupMembers';
import { useQueryGroupSuperHost } from '@/domain/GroupMember/hooks/useReadGroupSuperHost';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { ActivityQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { useJuniorGroupExperiment } from '@/utils/analytics/experiment/experiments/useJuniorGroupExperiment';

import FixedBottomSection from '../../Detail/components/FixedBottomSection';
import RecommendSimilarGroupSection from '../../Detail/components/Home/components/RecommendSimilarGroupSection';
import useGetIsExistHeroSlider from '../../Detail/hooks/useGetIsExistHeroSlider';
import GroupDetailHooks from '../../DetailV2/components/GroupDetailHooks';
import GroupDetailHeroSlider from '../../ImageViewerPage/components/GroupDetailHeroSlider';
import DetailNotMemberFeedSummary from '../components/DetailNotMemberFeedSummary';
import { GroupCreateButtonSection } from '../components/GroupCreateButtonSection';
import GroupDetailNotMemberBanner from '../components/GroupDetailNotMemberBanner';
import GroupInfoSection from '../components/GroupInfoSection';
import HostInfoSection from '../components/HostInfoSection';
import MeetupReviewSection from '../components/MeetupReviewsSection';
import MeetupSection from '../components/MeetupSection';
import MemberSection from '../components/MemberSection';
import { validHostInfoData } from '../utils/validData';

export type GroupDetailPageParams = Pick<
  PageParams,
  'groupId' | 'activeTab' | 'isNew' | 'from' | 'prevViewedGroupId'
> &
  Pick<ActivityQueryParams, 'requestMeetupReviewId' | 'boardCategoryKey' | 'applicationStatus'>;

// Detail 미가입자뷰
const GroupDetailNotMemberPage: ActivityComponentType<GroupDetailPageParams> = () => {
  const { groupId } = usePathParams();

  const [
    { data: groupMe },
    { data: group },
    {
      data: { members },
    },
    { data: groupSuperHost },
    { data: feedMeetups },
    { data: feedMeetupReviews },
    { data: groupLevel },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupMe({ groupId }),
      useQueryGroupDetail(groupId),
      useQueryGroupMembers({
        groupId,
        roles: ['manager', 'member'],
        limit: 3,
      }),
      useQueryGroupSuperHost(groupId),
      useQueryGroupFeedSummaryMeetup(groupId),
      useQueryGroupFeedMeetupReviewsForNotMember(groupId),
      useQueryGroupLevel(groupId),
    ],
  });

  const isExistHeroSlider = useGetIsExistHeroSlider({ groupId });
  const { isTreatment: enabledJuniorGroupExperiment } = useJuniorGroupExperiment();
  const groupStage = enabledJuniorGroupExperiment
    ? getGroupStage(group.memberCount, group.createdAt)
    : 'senior';
  // groupStage가 'senior'일 때 멤버 숫자 노출
  const hideMemberCount = enabledJuniorGroupExperiment && groupStage !== 'senior';

  const sections = [
    {
      order: 1,
      name: 'heroSlider',
      condition: isExistHeroSlider,
      component: <GroupDetailHeroSlider groupId={groupId} initialIndex={0} role={groupMe.role} />,
    },
    {
      order: 2,
      name: 'groupDetailBanner',
      condition: group,
      component: <GroupDetailNotMemberBanner groupId={groupId} />,
    },
    {
      order: 3,
      name: 'groupInfoSection',
      condition: group,
      component: (
        <>
          <TransparentNavTitleShowObserver />
          <GroupInfoSection group={group} groupLevel={groupLevel} groupStage={groupStage} />
        </>
      ),
    },
    {
      order: 4,
      name: 'meetupReviewSection',
      dividerHeight: 1,
      condition: (feedMeetupReviews ?? []).length > 2,
      component: <MeetupReviewSection groupId={groupId} />,
    },
    {
      order: 5,
      name: 'hostInfoSection',
      dividerHeight: 1,
      condition: validHostInfoData(groupSuperHost),
      component: groupSuperHost && <HostInfoSection groupId={groupId} hostInfo={groupSuperHost} />,
    },
    {
      order: 6,
      name: 'memberSection',
      dividerHeight: 1,
      condition: members.length > 0,
      component: (
        <MemberSection
          groupId={groupId}
          members={members}
          group={group}
          currentUser={groupMe}
          hideMemberCount={hideMemberCount}
        />
      ),
    },
    {
      order: 7,
      name: 'meetupSection',
      dividerHeight: 1,
      condition: feedMeetups && feedMeetups.meetups.length > 0,
      component: (
        <MeetupSection
          feedMeetup={feedMeetups}
          groupId={groupId}
          group={group}
          shouldSetSubNickname={group.subNicknameSetting.isSettingOn}
        />
      ),
    },
    {
      order: 8,
      name: 'detailNotMemberFeedSummary',
      dividerHeight: 1,
      condition: groupMe,
      component: <DetailNotMemberFeedSummary group={group} currentUser={groupMe} />,
    },
    {
      order: 9,
      name: 'recommendSimilarGroupSection',
      condition: true,
      dividerHeight: 8,
      dividerPadding: 0,
      component: <RecommendSimilarGroupSection categoryId={group.category.id} />,
    },
    {
      order: 10,
      dividerHeight: 8,
      dividerPadding: 0,
      name: 'groupCreateButtonSection',
      condition: true,
      component: <GroupCreateButtonSection />,
    },
  ];

  return (
    <>
      {sections
        .filter((section) => Boolean(section.condition))
        .map((section) => (
          <Fragment key={section.name}>
            {section?.dividerHeight && (
              <Divider size={section.dividerHeight} padding={section?.dividerPadding ?? 16} />
            )}
            {section.component}
          </Fragment>
        ))}
      <Spacing size={120} />
      <FixedBottomSection enableNeedVerificationNotice={false} />
      {groupMe && <GroupDetailHooks group={group} currentUser={groupMe} />}
    </>
  );
};

export default GroupDetailNotMemberPage;
