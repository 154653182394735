import { useState } from 'react';

import { usePostUnwatchGroup } from '@/api/hooks/usePostUnwatchGroup';
import { usePostWatchGroup } from '@/api/hooks/usePostWatchGroup';
import { useBridge } from '@/contexts/Bridge';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { trackEvent } from '@/utils/analytics';
import { openMyWatches } from '@/utils/link';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

interface Props {
  groupId: string;
}

export const useWatchGroup = ({ groupId }: Props) => {
  const { data: group, refetch } = useReadGroupDetail(groupId);

  const [watched, setWatched] = useState(group?.watched ?? false);

  const { bridge } = useBridge();
  const { open: openSnackbar, close: closeSnackbar } = useSnackbar();
  const handleErrorWithToast = useHandleErrorWithToast();

  const { mutate: watchGroup } = usePostWatchGroup({
    onSuccess: () => {
      refetch();
      openSnackbar({
        message: '관심 모임에 추가했어요.',
        actionLabel: '목록 보기',
        onAction: () => openMyWatches(),
        shouldCloseOnAction: true,
      });
    },
    onError: (e) => {
      setWatched(false);
      handleErrorWithToast(e);
    },
  });
  const { mutate: unwatchGroup } = usePostUnwatchGroup({
    onSuccess: () => refetch(),
    onError: (e) => {
      setWatched(true);
      handleErrorWithToast(e);
    },
  });

  const onWatchButtonClick = () => {
    bridge.setHapticLightFeedback({});
    close();

    trackEvent({
      event: 'click_watch_group',
      params: {
        groupId,
        // 변경될 모임 관심 상태
        watch: !watched,
        isChatRequired: !group?.chatRoomSetting.isShowChatRoomSetting,
        isChatActivated: !group?.chatRoomSetting.isDeactivate,
      },
      sample: true,
    });

    if (watched) {
      setWatched(false);
      unwatchGroup({ groupId });
      return;
    }
    setWatched(true);
    watchGroup({ groupId });
  };

  return {
    watched,
    onWatchButtonClick,
  };
};
