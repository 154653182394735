import { Typography } from '@community-group/components';

import * as s from './style.css';

type Props = {
  certifiedRate: number;
  isCertifyEveryDay: boolean;
  continuousCertifiedProgress: number;
};

const CertifyCheerUpText = ({
  certifiedRate,
  isCertifyEveryDay,
  continuousCertifiedProgress,
}: Props) => {
  if (isCertifyEveryDay) {
    return (
      <div className={s.StatusCheerUpText}>
        <Typography typography="title3Bold" color="gray900">
          잘하고 있어요!
        </Typography>
        <Typography typography="title3Bold" color="gray900">
          <Typography typography="title3Bold" color="primary">
            {continuousCertifiedProgress}회
          </Typography>{' '}
          연속 인증 완료했어요
        </Typography>
      </div>
    );
  }
  return (
    <div className={s.StatusCheerUpText}>
      <Typography typography="title3Bold" color="gray900">
        잘하고 있어요!
      </Typography>
      <Typography typography="title3Bold" color="gray900">
        전체 목표 중{' '}
        <Typography typography="title3Bold" color="primary">
          {certifiedRate}%
        </Typography>{' '}
        인증 완료했어요
      </Typography>
    </div>
  );
};

export default CertifyCheerUpText;
