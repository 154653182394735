import {
  AsyncBoundary,
  Divider,
  Render,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';

import { useGetMyMainProfile } from '@/api/hooks/useGetMyMainProfile';
import { MEETUP_FEEDBACK_TYPES } from '@/components/group/Meetup/Review/constant/reviewOptions';
import GroupMainProfileReviewSection from '@/features/GroupMainProfile/ReviewSection';
import GroupMainProfileUserInfo from '@/features/GroupMainProfile/UserInfo';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import HideScrollBar from '@/shared/components/AppScreen/components/HideScrollBar';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';

import MyRequestDirectChatPageAppBarRightButton from '../../../MainProfile/Components/MyRequestDirectChatPageAppBarRightButton';

// 본인 프로필 페이지 (홈 > 우측 상단 프로필)
const GroupProfileDetailPage: ActivityComponentType = () => {
  return (
    <AppScreen
      appBar={{
        title: '모임 프로필',
        renderRight: () => {
          return <MyRequestDirectChatPageAppBarRightButton />;
        },
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <ProfileWrapper />
      </AsyncBoundary>
    </AppScreen>
  );
};

const ProfileWrapper = () => {
  const { data: profile } = useGetMyMainProfile({});

  useEnterTrackEvent({
    event: 'enter_integreated_group_profile_page',
    params: {
      userId: profile?.id.toString(),
      nickname: profile?.nickname,
    },
    sample: true,
  });

  const isRenderMeetupMemberReviews = useMemo(() => {
    const filteredPositiveMeetupMemberReviews = (profile?.meetupMemberReviews ?? []).filter(
      ({ feedbackTypeId }) => MEETUP_FEEDBACK_TYPES.LIKE.get(feedbackTypeId)
    );

    return filteredPositiveMeetupMemberReviews.length > 0;
  }, [profile]);

  return (
    <LayoutWrapper>
      <HideScrollBar>
        <GroupMainProfileUserInfo profile={profile} />
        <Render condition={isRenderMeetupMemberReviews}>
          <Divider size={8} />
          <GroupMainProfileReviewSection mainProfile={profile} />
        </Render>
      </HideScrollBar>
    </LayoutWrapper>
  );
};

export default withAsyncBoundary(GroupProfileDetailPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: <ViewError />,
});
