import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
  GroupPermissionsResponsePermissions,
} from '@community-group/api/lib/group/models';
import { useCallback, useMemo } from 'react';
import { match } from 'ts-pattern';

import { XpropertyData } from '@/api/base/xproperty';
import { useCustomerServiceSettingHandler } from '@/features/GroupSetting/hooks/useCustomerServiceSettingHandler';
import { useDefaultSettingHandler } from '@/features/GroupSetting/hooks/useDefaultSettingHandler';
import { useJoinSettingHandler } from '@/features/GroupSetting/hooks/useJoinSettingHandler';
import { useMemberSettingHandler } from '@/features/GroupSetting/hooks/useMemberSettingHandler';
import { usePersonalSettingHandler } from '@/features/GroupSetting/hooks/usePersonalSettingHandler';
import { groupCustomerServiceSettingConfig } from '@/features/GroupSetting/util/groupSettingConfig';

type Props = {
  role: GroupMemberRoleEnum;
  groupId: string;
  userId: string;
  groupDetail: GroupDetailPresentation;
  permissions: GroupPermissionsResponsePermissions;
  xProperty: XpropertyData;
};
export const useSettingHandler = ({
  role,
  groupId,
  userId,
  groupDetail,
  permissions,
  xProperty,
}: Props) => {
  const { handleItemClick: personalSettingHandler, personalSetting } = usePersonalSettingHandler({
    groupId,
    userId,
  });
  const { handleItemClick: defaultSettingHandler, defaultSetting } = useDefaultSettingHandler({
    role,
    groupId,
    groupDetail,
    permissions,
  });
  const { handleItemClick: joinSettingHandler, joinSetting } = useJoinSettingHandler({
    role,
    groupId,
    groupDetail,
  });
  const { handleItemClick: memberSettingHandler, memberSetting } = useMemberSettingHandler({
    role,
    groupId,
  });
  const customerServiceSettingHandler = useCustomerServiceSettingHandler({ role, xProperty });

  const totalSetting = useMemo(
    () => [
      ...personalSetting,
      ...defaultSetting,
      ...joinSetting,
      ...memberSetting,
      ...groupCustomerServiceSettingConfig,
    ],
    [personalSetting, defaultSetting, joinSetting, memberSetting]
  );

  const handleItemClick = useCallback(
    (item: (typeof totalSetting)[number]) => {
      match(item)
        .with({ category: '사용자 설정' }, () =>
          personalSettingHandler(item as (typeof personalSetting)[number])
        )
        .with({ category: '모임 설정' }, () =>
          defaultSettingHandler(item as (typeof defaultSetting)[number])
        )
        .with({ category: '멤버 관리' }, () =>
          memberSettingHandler(item as (typeof memberSetting)[number])
        )
        .with({ category: '가입 관리' }, () =>
          joinSettingHandler(item as (typeof joinSetting)[number])
        )
        .with({ category: '고객센터' }, () =>
          customerServiceSettingHandler(item as (typeof groupCustomerServiceSettingConfig)[number])
        )
        .exhaustive();
    },
    [
      customerServiceSettingHandler,
      defaultSettingHandler,
      joinSettingHandler,
      memberSettingHandler,
      personalSettingHandler,
    ]
  );
  return { handleItemClick, totalSetting };
};
