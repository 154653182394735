import { groupClient } from '@community-group/api';
import { GroupMeetupReviewCreateRequest } from '@community-group/api/lib/group/models';
import { AsyncBoundary, BoxButton, Divider, Render, Spacing } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { valibotResolver } from '@hookform/resolvers/valibot';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActivityComponentType } from '@stackflow/react';
import { isEqual } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { usePutGroupMeetupReview } from '@/domain/GroupMeetupReview/hooks/usePutGroupMeetupReview';
import { useReadGroupMeetupReview } from '@/domain/GroupMeetupReview/hooks/useReadGroupMeetupReview';
import GroupMeetupReviewWrittenReviewDialog from '@/features/GroupMeetupReview/components/WrittenReviewDialog';
import { useInitialMeetupReviewForm } from '@/features/GroupMeetupReview/hooks/useInitialMeetupReviewForm';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { ScreenLoader } from '@/shared/components/AppScreen';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';
import {
  initMeetupMemberReviewCreateFormData,
  meetupMemberReviewCreateFormSchema,
} from '@/utils/validate/formSchema/meetupReview';

import MeetupDetailHeader from '../components/common/MeetupDetailHeader/MeetupDetailHeader';
import { MeetupDetailHeaderSkeleton } from '../components/common/MeetupDetailHeader/Skeleton';
import { MeetupMemberReviewForm } from '../components/MeetupMemberReviewForm';
import { MeetupReviewForm } from '../components/MeetupReviewForm';
import { useGroupMeetupCreateReviewForm } from '../hooks';
import * as s from './style.css';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

export type GroupMeetupCreateReviewPageParams = Pick<PageParams, 'groupId' | 'meetupId'>;

const GroupMeetupCreateReviewPage: ActivityComponentType<
  GroupMeetupCreateReviewPageParams
> = () => {
  const back = useBack();
  return (
    <AppScreen
      appBar={{
        backButton: {
          render: () => (
            <div className={s.CloseButtonWrapper} onClick={back}>
              <IconXmarkLine />
            </div>
          ),
        },
        closeButton: {
          render: () => (
            <div className={s.CloseButtonWrapper} onClick={back}>
              <IconXmarkLine />
            </div>
          ),
        },
      }}
      disabledClientOnly={true}
    >
      <AsyncBoundary pendingFallback={<ScreenLoader />}>
        <GroupMeetupCreateReviewView />
      </AsyncBoundary>
    </AppScreen>
  );
};

const GroupMeetupCreateReviewView = () => {
  const { groupId, meetupId } = usePathParams();
  const { replace } = useFlow();
  const { open: openSnackbar } = useSnackbar();
  const { data: review } = useReadGroupMeetupReview({ groupId, meetupId });
  const isWrittenReviewExists = review !== null;
  const { mutate: putGroupMeetupReview, isPending: isModifyLoading } = usePutGroupMeetupReview({});
  const [isMediaUploading, setIsMediaUploading] = useState(false);
  const handleMediaUploading = useCallback(
    (isUploading: boolean) => setIsMediaUploading(isUploading),
    []
  );
  useSetFixedLayoutSize();

  useEnterTrackEvent({
    event: 'enter_write_group_meetup_review_page',
    params: {
      groupId,
      meetupId,
    },
  });

  const initialMeetupReviewForm = useInitialMeetupReviewForm({ review });

  const meetupReviewFormHandler = useForm<GroupMeetupReviewCreateRequest>({
    defaultValues: initialMeetupReviewForm,
    resolver: valibotResolver(groupClient.model.GroupMeetupReviewCreateRequestFormSchema),
  });

  const isSelectedReviewRating = useMemo(
    () => !!meetupReviewFormHandler.watch('reviewRating'),
    [meetupReviewFormHandler.watch('reviewRating')]
  );

  const meetupMemberReviewFormHandler = useForm({
    defaultValues: initMeetupMemberReviewCreateFormData,
    resolver: zodResolver(meetupMemberReviewCreateFormSchema),
  });

  const { isLoading, handleSubmit } = useGroupMeetupCreateReviewForm({
    meetupReviewFormHandler,
    meetupMemberReviewFormHandler,
  });

  const handleSubmitForm = async () => {
    handleSubmit({
      onSuccess: () => {
        const meetupReviewFormData = meetupReviewFormHandler.getValues();
        const meetupMemberReviewFormData = meetupMemberReviewFormHandler.getValues();

        trackEvent({
          event: 'click_submit_group_meetup_review',
          params: {
            groupId,
            meetupId,
            reviewRating: meetupReviewFormData.reviewRating,
            hasReviewPost: !!meetupReviewFormData.reviewPost?.content,
            hasMemberReviewMessage: !!meetupMemberReviewFormData.feedbackMembers.find(
              (feedback) => !!feedback?.review
            ),
            positiveFeedbackMemberCount: meetupMemberReviewFormData.feedbackMembers.filter(
              ({ feedbackRating }) => feedbackRating === 'LIKE'
            ).length,
            negativeFeedbackMemberCount: meetupMemberReviewFormData.feedbackMembers.filter(
              ({ feedbackRating }) => feedbackRating === 'DISLIKE'
            ).length,
            hasReviewImage: !!meetupReviewFormData.reviewPost?.medias?.find(
              (media) => media.type === 'image'
            ),
            hasReviewVideo: !!meetupReviewFormData.reviewPost?.medias?.find(
              (media) => media.type === 'video'
            ),
          },
        });

        replace('GroupMeetupCreateDoneReviewPage', { groupId });
        refetchGroupDetail({ groupId });
        refetchGroupMeetupDetail({ groupId, meetupId });
      },
    });
  };

  const { watch, getValues } = meetupReviewFormHandler;

  const handleSubmitClick = async () => {
    if (review && !isModifyLoading) {
      const newReview = getValues();
      const content = newReview.reviewPost?.content;
      if (!content || content.trim() === '') {
        return openSnackbar({
          message: '본문이 너무 짧아요. 3자 이상 작성해주세요.',
        })
      }

      putGroupMeetupReview(
        {
          groupId: Number(groupId),
          meetupId: Number(meetupId),
          groupMeetupReviewModifyRequest: {
            reviewRating: newReview.reviewRating,
            reviewPost: {
              ...newReview.reviewPost,
              content: newReview.reviewPost?.content ?? '',
              medias:
                newReview.reviewRating === 'DISLIKE' ? [] : newReview.reviewPost?.medias ?? [],
            },
          },
        },
        {
          onSuccess: () => {
            trackEvent({
              event: 'click_submit_group_meetup_modified_review',
              params: {
                groupId,
                meetupId,
                reviewRating: newReview.reviewRating,
                hasReviewPost: !!newReview.reviewPost?.content,
                hasReviewImage: !!newReview.reviewPost?.medias?.find(
                  (media) => media.type === 'image'
                ),
                hasReviewVideo: !!newReview.reviewPost?.medias?.find(
                  (media) => media.type === 'video'
                ),
              },
            });
            replace('GroupMeetupCreateDoneReviewPage', { groupId });
            refetchGroupDetail({ groupId });
            refetchGroupMeetupDetail({ groupId, meetupId });
          },
        }
      );
      return;
    }
    if (isLoading) return;

    handleSubmitForm();
  };

  const buttonDisabled = useMemo(() => {
    if (isMediaUploading) return true;
    if (review) {
      return isEqual(watch(), initialMeetupReviewForm);
    }
    return false;
  }, [review, watch, initialMeetupReviewForm, isMediaUploading]);

  return (
    <>
      <GroupMeetupReviewWrittenReviewDialog review={review} />
      <AsyncBoundary pendingFallback={<MeetupDetailHeaderSkeleton />}>
        <MeetupDetailHeader />
        <Divider size={1} padding={16} />
      </AsyncBoundary>
      <AsyncBoundary>
        <FormProvider {...meetupReviewFormHandler}>
          <MeetupReviewForm
            groupId={groupId}
            meetupId={meetupId}
            handleMediaUploading={handleMediaUploading}
          />
        </FormProvider>
      </AsyncBoundary>
      <Render condition={!isWrittenReviewExists}>
        <div style={{ display: isSelectedReviewRating ? 'block' : 'none' }}>
          <AsyncBoundary>
            <MeetupMemberReviewForm formHandler={meetupMemberReviewFormHandler} />
          </AsyncBoundary>
        </div>
      </Render>
      <Spacing size={48} />
      {isSelectedReviewRating && (
        <div className={s.ButtonWrapper}>
          <BoxButton
            isDisabled={buttonDisabled}
            width="100%"
            size="xlarge"
            isLoading={isLoading || isModifyLoading}
            onClick={handleSubmitClick}
          >
            후기 남기기
          </BoxButton>
        </div>
      )}
    </>
  );
};

export default GroupMeetupCreateReviewPage;
