import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_MEMBER_QUERY_KEY } from '../queries';

type Params = {
  groupId: string;
  applicationId: string;
};

export const useQueryGroupMemberGradeApplicationDecision = ({ groupId, applicationId }: Params) => {
  const read = useAsyncRead(
    groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGradeApplicationDecisionGradeApplicationIdGet
  );
  return {
    queryKey: GROUP_MEMBER_QUERY_KEY.memberGradeApplicationDecision(groupId, applicationId),
    queryFn: () => read({ id: Number(groupId), gradeApplicationId: applicationId }),
  };
};

export const useReadGroupMemberGradeApplicationDecision = (params: Params) => {
  const query = useQueryGroupMemberGradeApplicationDecision(params);
  return useSuspenseQuery({ ...query });
};
