import 'dayjs/locale/ko';

import { Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import React, { useLayoutEffect, useRef } from 'react';

import * as s from './SeoulRunDayCalendarRadio.css';

dayjs.locale('ko');
dayjs.extend(weekday);

type Props = {
  selectedDay: string;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
  days: dayjs.Dayjs[];
};

export const SeoulRunDayCalendarRadio = ({ setSelectedDay, selectedDay, days }: Props) => {
  const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDay(e.target.value ?? '');
  };

  const containerRef = useRef<HTMLDivElement>(null);

  //  선택시 view 중앙으로 스크롤 시키는 로직
  useLayoutEffect(() => {
    if (containerRef.current) {
      const selectedDay = containerRef.current.querySelector(`.selected_day`);

      if (selectedDay) {
        const containerWidth = containerRef.current.getBoundingClientRect().width;
        const containerLeft = containerRef.current.scrollLeft;
        const elementWidth = selectedDay.clientWidth;
        const elementLeft = selectedDay.getBoundingClientRect().left + containerLeft;

        const scrollLeft = elementLeft + elementWidth / 2 - containerWidth / 2;

        containerRef.current.scrollTo({
          left: scrollLeft,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedDay]);

  return (
    <div className={s.Wrapper} ref={containerRef}>
      <label className={s.AllDateMenu({ checked: !selectedDay })} key="all">
        <input
          className={s.HiddenInput}
          id="calendar-id"
          type="radio"
          name="day"
          value={undefined}
          onChange={handleDayChange}
          checked={!selectedDay}
        />
        <Typography
          typography="title3Bold"
          as="strong"
          color={!selectedDay ? 'gray900' : 'gray600'}
          wordBreak="keep-all"
        >
          전체
        </Typography>
      </label>

      {days.map((day) => {
        const checked = selectedDay === day.format('YYYY-MM-DD');
        return (
          <label className={clsx(s.InputWrapper, checked && 'selected_day')} key={day.format('DD')}>
            <input
              className={s.Input}
              id={`calendar-day-${day.format('YYYY-MM-DD')}`}
              type="radio"
              name="day"
              value={day.format('YYYY-MM-DD')}
              onChange={handleDayChange}
              checked={checked}
            />
            <Typography
              className={s.Text}
              typography="caption2Regular"
              color={checked ? 'gray900' : 'gray600'}
              as="p"
              textAlign="center"
              style={{
                background: checked ? vars.$scale.color.gray100 : 'transparent',
              }}
            >
              {dayjs().startOf('day').isSame(day.startOf('day')) ? '오늘' : day.format('dd')}
              <br />
              <Typography
                typography="label1Bold"
                as="strong"
                color={checked ? 'gray900' : 'gray600'}
              >
                {day.format('DD')}
              </Typography>
            </Typography>
          </label>
        );
      })}
    </div>
  );
};
