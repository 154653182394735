import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import { useCallback, useMemo } from 'react';
import { match } from 'ts-pattern';

import { groupJoinSettingConfig } from '@/features/GroupSetting/util/groupSettingConfig';
import * as stackflow from '@/stackflow';

type Props = {
  role: GroupMemberRoleEnum;
  groupId: string;
  groupDetail: GroupDetailPresentation;
};

export const useJoinSettingHandler = ({ role, groupId, groupDetail }: Props) => {
  const stack = stackflow?.useFlow();
  const push = stack?.push;
  const joinSetting = useMemo(
    () => groupJoinSettingConfig.filter((item) => item.roles.some((r) => r === role)),
    [role]
  );

  const ageTagInfo = useMemo(() => {
    if (!groupDetail.ageRangeTag) return { minAge: undefined, maxAge: undefined };
    return {
      minAge: groupDetail.ageRangeTag.minAge.toString(),
      maxAge: groupDetail.ageRangeTag.maxAge.toString(),
    };
  }, [groupDetail.ageRangeTag]);

  const handleItemClick = useCallback(
    (item: (typeof joinSetting)[number]) => {
      match([item, role])
        .with([{ displayText: '가입 방식 관리' }, 'superHost'], () =>
          push('GroupJoinSettingPage', { groupId })
        )
        //TODO: 활동 지역 설정으로 변경 예정
        .with([{ displayText: '모집 동네 범위 설정' }, 'superHost'], () =>
          push('GroupSettingJoinableRegionRangePage', { groupId })
        )
        .with([{ displayText: '최대 가입 인원 설정' }, 'superHost'], () =>
          push('GroupMaxMemberSettingPage', {
            groupId,
            currentMemberCount: (groupDetail.memberCount ?? 1).toString(),
            initialMaximumMemberCount: (groupDetail.maximumMemberCount ?? -1).toString(),
          })
        )
        .with([{ displayText: '모집 연령대 설정' }, 'superHost'], () =>
          push('GroupAgeRangeSettingPage', {
            groupId,
            initialGroupMinAge: ageTagInfo.minAge,
            initialGroupMaxAge: ageTagInfo.maxAge,
          })
        )
        .otherwise(() => null);
    },
    [
      role,
      push,
      groupId,
      groupDetail.memberCount,
      groupDetail.maximumMemberCount,
      ageTagInfo.minAge,
      ageTagInfo.maxAge,
    ]
  );
  return { handleItemClick, joinSetting };
};
