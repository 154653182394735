import { BottomBasicButton, Spacing, Typography } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { AnimatePresence } from 'framer-motion';
import { FieldValues, useForm } from 'react-hook-form';

import { usePutProfile } from '@/api/hooks/usePutProfile';
import { FormInput } from '@/components/common/base/Input/Text';
import SubNicknamePreview from '@/components/common/GroupMemberSubNicknamePreview/SubNicknamePreview';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import useParentingGroupKidProfile from '@/hooks/useParentingGroupKidProfile';
import { useFlow } from '@/stackflow';
import { AppFullScreenBottomSheet } from '@/stackflow/components/AppFullScreenBottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupUserProfile } from '@/utils/refetch/groupUserProfile';
import { groupMemberProfileFieldSchema } from '@/utils/validate/formSchema/subNickname';

const GroupSetMemberProfileSubNicknameBottomSheet: ActivityComponentType = () => {
  const { pop, push } = useFlow();
  const { groupId } = usePathParams();

  const { watch, register } = useForm({
    defaultValues: {
      subNickname: undefined,
    } as FieldValues,
  });

  const { data: group } = useReadGroupDetail(groupId);

  const subNicknameFieldValue = watch('subNickname');

  const handleErrorWithToast = useHandleErrorWithToast();

  const shouldUseParentingKidProfile = useParentingGroupKidProfile(groupId);

  const { mutate, isPending: isSubmitEditProfileLoading } = usePutProfile({
    onSuccess: ({ data }) => {
      trackEvent({
        event: 'click_member_edit_subnickname',
        params: {
          groupId: groupId,
          groupName: group?.name,
          groupCategory: group?.category.name,
          userId: data.groupMember.id.toString() ?? '',
          subNickname: subNicknameFieldValue,
        },
        sample: true,
      });
      refetchGroupDetail({ groupId });
      refetchGroupUserProfile({ groupId, userId: data.groupMember.id.toString() ?? '' });
      pop();

      if (!shouldUseParentingKidProfile) return;
      push('ParentingGroupFreshMemberKidProfileCreatePage', {
        groupId,
        createKidProfileFlowType: 'withSubNickname',
      });
    },
    onError: (error) => {
      handleErrorWithToast(error);
    },
  });

  const handleSubmit = () => {
    mutate({
      id: Number(groupId.toString()),
      groupMemberModifyForm: {
        profile: {
          subNickname: subNicknameFieldValue,
        },
      },
    });
  };

  const getErrorMessage = () => {
    if (!subNicknameFieldValue) return '';
    const result = groupMemberProfileFieldSchema.subNickname.safeParse(subNicknameFieldValue);
    if (result.success) return '';
    const parsedError = JSON.parse(result.error.message)[0];
    return parsedError.message ?? '';
  };

  return (
    <AppFullScreenBottomSheet
      accessoryBar={
        <>
          <BottomBasicButton
            loading={isSubmitEditProfileLoading}
            onClick={handleSubmit}
            disabled={!subNicknameFieldValue}
          >
            완료
          </BottomBasicButton>
        </>
      }
      appBar={{
        backButton: {
          renderIcon: () => {
            return <IconXmarkLine size={24} color={vars.$scale.color.gray900} />;
          },
        },
      }}
    >
      <p
        style={{
          fontSize: '1.5rem',
          fontWeight: 700,
          lineHeight: '2rem',
          marginBottom: '0.5rem',
          marginTop: '1rem',
        }}
      >
        모임에서 사용할 <br /> 별명을 입력해주세요
      </p>
      <Typography typography="bodyL1Regular" color="gray900">
        별명은 이 모임에서만 닉네임 옆에 함께 표시돼요.
      </Typography>
      <Spacing size={16} />
      <SubNicknamePreview subNicknameText={subNicknameFieldValue ?? ''} />
      <Spacing size={24} />
      <AnimatePresence>
        <Typography typography="label3Bold" color="gray900">
          {group?.subNicknameSetting?.requestText ?? '나이/성별/운동경력'}
        </Typography>
        <Spacing size={12} />
        <FormInput
          register={register}
          value={subNicknameFieldValue}
          name="subNickname"
          placeholder="별명을 입력해주세요"
          minLength={groupMemberProfileFieldSchema.subNickname.minLength ?? 0}
          maxLength={groupMemberProfileFieldSchema.subNickname.maxLength ?? 0}
          isInvalid={
            subNicknameFieldValue?.length > 0 &&
            groupMemberProfileFieldSchema.subNickname.safeParse(subNicknameFieldValue).success ===
              false
          }
          errorMessage={getErrorMessage()}
        />
      </AnimatePresence>
    </AppFullScreenBottomSheet>
  );
};

export default GroupSetMemberProfileSubNicknameBottomSheet;
