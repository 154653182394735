import { PaginationList } from '@community-group/components/shared';
import React from 'react';

import { useUserConfig } from '@/contexts/UserConfig';
import { useReadChallengeParticipantsRank } from '@/domain/Challenge/hooks/useReadChallengeParticipantsRank';
import ParticipantsRankItem from '@/features/ChallengeDetail/ParticipantsStatusSection/ParticipantsRankItem';

type Props = {
  groupId: number;
  challengeId: number;
};

const CertifyRankList = ({ groupId, challengeId }: Props) => {
  const { userConfig } = useUserConfig();

  const {
    data: rankData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useReadChallengeParticipantsRank({
    groupId: Number(groupId),
    challengeId: Number(challengeId),
  });

  return (
    <PaginationList
      items={rankData?.pages ?? []}
      render={(item) =>
        item && (
          <ParticipantsRankItem
            key={item.id}
            groupId={String(groupId)}
            isMe={item.id === userConfig.userId}
            {...item}
          />
        )
      }
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};

export default CertifyRankList;
