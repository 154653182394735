import { AxiosInstance } from 'axios';
import humps from 'humps';

import { ReleaseDatesType } from '@/hooks/useReleaseDate';

const { camelizeKeys } = humps;

export const getXpropertyPath = '/properties';

type Params = {
  xpropertyFetchInstance: AxiosInstance;
};
export const getXproperty = async ({ xpropertyFetchInstance }: Params) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const { data } = await xpropertyFetchInstance.get<{ properties: Object }>(getXpropertyPath);

  const camelizedData = camelizeKeys(data.properties) as XpropertyData;
  return camelizedData;
};

export interface XpropertyData {
  groupFeDebugFlag?: {
    showVconsoleToInternalUser: boolean;
  };
  meetupValidationValues?: { minMemberCount: number; maxMemberCount: number };
  groupMaintenanceFilterProperty?: {
    messages: {
      title?: string;
      body?: string;
    };
  };
  communityGroupRedTeamConfigs?: {
    enabled: boolean;
    targetUsers: number[];
  };
  communityGroupSurveyBanners?: {
    [key: string]: {
      title: string;
      targetUrl: string;
      variant?: 'outlined' | 'default';
      show?: boolean;
    };
  };
  communityGroupWebDates?: ReleaseDatesType;
  groupMomGrowthParticipantGroupIds?: {
    participants: {
      additionalExposureRegionIds: number[];
      groupId: number;
      kidProfileEnabled: boolean;
      showNearbyMemberEnabled: boolean;
    }[];
  };
}
