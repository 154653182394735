import React from 'react';

import { useBridge } from '@/contexts/Bridge';
import { TypeActivities, useFlow } from '@/stackflow';

import useActiveActivities from './useActiveActivities';

type HandleBackProps = {
  activityName?: keyof TypeActivities;
  isCloseRouterActivityNotExist?: boolean;
};

/**
 * 특정 Activity로 돌아가거나 라우터를 닫는 훅
 *
 * @returns {Function} handleBack - Activity 이동을 처리하는 콜백 함수
 * @param {Object} options
 * @param {string} [options.activityName] - 돌아갈 Activity 이름
 * @param {boolean} [options.isCloseRouterActivityNotExist=true] - Activity가 존재하지 않을 때 라우터를 닫을지 여부
 */

const useBackToActivity = () => {
  const { isExistSpecificActivities, diffActivitiesFromCurrent } = useActiveActivities();
  const { pop } = useFlow();
  const { bridge } = useBridge();
  const handleBack = React.useCallback(
    ({ activityName, isCloseRouterActivityNotExist = true }: HandleBackProps) => {
      if (!activityName) {
        bridge.closeRouter({});
        return;
      }
      if (isExistSpecificActivities(activityName)) {
        const diff = diffActivitiesFromCurrent(activityName);
        if (diff !== -1) {
          pop(diff);
          return;
        }
      }

      if (!isCloseRouterActivityNotExist) return;

      bridge.closeRouter({});
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isExistSpecificActivities, diffActivitiesFromCurrent, pop]
  );

  return handleBack;
};

export default useBackToActivity;
