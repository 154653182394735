import { JoinableRegionRangeEnum } from '@community-group/api/lib/group/models';
import {
  getBoundsByCoordinates,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconChevronLeftLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useRef, useState } from 'react';

import {
  initialGroupRegionRanges,
  REGION_RANGE_NAME,
  useGetGroupRegionRanges,
} from '@/api/hooks/useGetGroupRegionRanges';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import { Map } from '@/components/common/Map';
import { getPolygonCoordinates } from '@/components/common/Map/components/Ploygon';
import { Coordinates, estimateZoomLevel } from '@/components/common/Map/utils/polygon';
import { SelectBoxRadio } from '@/components/common/SelectBox/SelectBoxRadio';
import { useBridge } from '@/contexts/Bridge';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';

import * as s from './joinableRegionRange.css';

export type GroupSettingJoinableRegionRangePageParams = Pick<PageParams, 'groupId'>;

export const GroupSettingJoinableRegionRangePage: ActivityComponentType<
  GroupSettingJoinableRegionRangePageParams
> = () => {
  const { groupId } = usePathParams();
  const { from } = useQueryParams();
  const { data: group } = useReadGroupDetail(groupId);

  const initJoinableRegionRangeRef = useRef<JoinableRegionRangeEnum | undefined>(undefined);
  useEffect(() => {
    if (initJoinableRegionRangeRef.current) return;

    initJoinableRegionRangeRef.current = group?.joinableRegionRange;
  }, [group]);

  const { data: regionData } = useGetGroupRegionRanges(group?.groupCreatedRegion.id ?? 0);

  const { joinableRegionRanges, regionInfo } = regionData ?? initialGroupRegionRanges();

  // TODO: 추후 adjacent는 제외되어야 함. (더이상 사용하지 않음) (참고: https://daangn.slack.com/archives/C0324MBCA9Z/p1694502687068089?thread_ts=1694140374.821359&cid=C0324MBCA9Z)
  const [selectedRegionRange, setSelectedRegionRange] = useState<
    JoinableRegionRangeEnum | undefined
  >(group?.joinableRegionRange === 'adjacent' ? 'my' : group?.joinableRegionRange);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      trackEvent({
        event: 'click_setting_region_range',
        params: {
          groupId: groupId,
          category: group?.category.name,
          regionRange: selectedRegionRange,
          from,
        },
      });
      refetchGroupSetting({ groupId });
    },
  });

  const handleRegionRangeChange = (regionRange: JoinableRegionRangeEnum) => {
    setSelectedRegionRange(regionRange);
  };

  const { pop } = useFlow();
  const { bridge } = useBridge();
  const handleBackButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    pop();
  };

  const handleSubmit = () => {
    mutate(
      {
        id: groupId,
        groupModifyForm: {
          joinableRegionRange: selectedRegionRange,
        },
      },
      {
        onSuccess: () => {
          bridge.openToast({
            toast: {
              body: '수정되었어요.',
            },
          });
          pop();
        },
      }
    );
  };
  return (
    <AppScreen
      appBar={{
        title: '모집 동네 범위 설정',
        backButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: (event) => {
            handleBackButtonClick(event);
          },
        },
        renderRight: () => (
          <Typography
            typography="label2Regular"
            onClick={handleSubmit}
            style={{
              padding: '8px',
            }}
          >
            완료
          </Typography>
        ),
      }}
    >
      <div
        style={{
          width: '100%',
          padding: '1rem',
        }}
      >
        {joinableRegionRanges.map(({ key, regionPolygons: originRegionPolygons, count }) => {
          if (key === 'unlimited') {
            return (
              <SelectBoxRadio
                style={{
                  marginBottom: '0.75rem',
                }}
                key={key}
                label={REGION_RANGE_NAME[key as JoinableRegionRangeEnum]}
                description="어디서나 가입할 수 있어요."
                selected={selectedRegionRange === key}
                onClick={() => {
                  handleRegionRangeChange(key);
                }}
              />
            );
          }
          // lat: 위도, lng: 경도, 서버 데이터에서는 [경도(lng), 위도(lat)] 순서로 오기 때문에 순서를 바꿔줍니다.
          const regionPolygons = originRegionPolygons.map(([lng, lat]) => [lat, lng]);

          const mappedPolygonData = getPolygonCoordinates(
            regionInfo.name,
            regionPolygons as Coordinates
          );
          const hasRegionPolygon = regionPolygons.length > 1 && mappedPolygonData;
          const hasManyRegions = count > 1;

          const zoomLevel = estimateZoomLevel(regionPolygons as Coordinates, {
            width: 324,
            height: 240,
          });

          const description = hasManyRegions
            ? `${regionInfo.name}과 근처 동네 ${count - 1}개`
            : `${regionInfo.name}`;

          return (
            <SelectBoxRadio
              style={{
                marginBottom: '0.75rem',
              }}
              key={key}
              label={REGION_RANGE_NAME[key as JoinableRegionRangeEnum]}
              description={description}
              selected={selectedRegionRange === key}
              onClick={() => {
                handleRegionRangeChange(key);
              }}
            >
              {hasRegionPolygon && (
                <div className={s.joinableRegionRangeMapWrapperStyle}>
                  <Map
                    className={s.joinableRegionRangeMapStyle}
                    interactive
                    initialMapState={{
                      zoom: zoomLevel,
                      bounds: getBoundsByCoordinates([[regionPolygons]], {
                        container: { clientHeight: 240, clientWidth: 324 },
                      }),
                    }}
                    module={false}
                    onLoad={({ target }) => {
                      target.addPolygonLayer(mappedPolygonData, {
                        removeOthers: true,
                        moveToCenter: false,
                        style: {
                          fillPaint: {
                            'fill-color': '#FF7E36',
                            'fill-opacity': 0.15,
                          },
                          linePaint: {
                            'line-color': '#FF7E36',
                            'line-width': 1,
                          },
                        },
                      });
                    }}
                  />
                </div>
              )}
            </SelectBoxRadio>
          );
        })}
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingJoinableRegionRangePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
