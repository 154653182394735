import { GroupSearchPresentation } from '@community-group/api/lib/group/models';
import { ImpressionArea } from '@community-group/components';
import { QueryFromType } from '@daangn/search-log-group';
import { vars } from '@seed-design/design-token';
import { useEffect, useRef, useState } from 'react';

import { SearchFilterParams } from '@/api/hooks/useGetSearchGroupList';
import { isSetFilter } from '@/components/group/Search/utils/filter';
import useKarrotSearchLogger, {
  getCategoryParams,
  mappedSearchItemParams,
} from '@/hooks/useKarrotSearchLogger';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { useJuniorGroupExperiment } from '@/utils/analytics/experiment/experiments/useJuniorGroupExperiment';

import { SearchTabType } from '../../Page/Result';
import SearchResultEmptyView from './EmptyView';
import * as s from './index.css';
import { SearchResultItem } from './SearchResultItem';
import { SearchResultLoader } from './SearchResultLoader';

type Props = {
  currentTab: SearchTabType;
  startIndex?: number;
  groupList: GroupSearchPresentation[];
  hasNextPage: boolean;
  searchFilter: SearchFilterParams;
  initFilter?: () => void;
};

const SearchResultList = ({
  currentTab,
  groupList,
  hasNextPage,
  searchFilter,
  startIndex = 0,
  initFilter,
}: Props) => {
  const { query = '' } = usePathParams();
  const { referrer = 'community_group.client', queryFrom = 'unknown', queryId } = useQueryParams();

  const searchLogger = useKarrotSearchLogger();
  const { isTreatment: enabledJuniorGroupExperiment } = useJuniorGroupExperiment();

  const { push } = useFlow();

  const listRef = useRef<HTMLUListElement>(null);
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    if (!listRef.current) return;

    const scrollElement = listRef.current;

    const handleScroll = () => {
      requestAnimationFrame(() => {
        if (scrollElement.scrollTop <= 0) {
          setIsTop(true);
        } else {
          setIsTop(false);
        }
      });
    };

    scrollElement.addEventListener('scroll', handleScroll);

    return () => scrollElement.removeEventListener('scroll', handleScroll);
  }, [listRef]);

  if (groupList.length <= 0 && isSetFilter(searchFilter)) {
    return (
      <SearchResultEmptyView
        currentTab={currentTab}
        isSetFilter={isSetFilter(searchFilter)}
        initFilter={initFilter ?? (() => {})}
      />
    );
  }

  return (
    <>
      <div
        className={s.spacing}
        style={{
          backgroundColor: isTop ? 'transparent' : vars.$semantic.color.divider1,
        }}
      />
      <ul
        style={{
          height: groupList.length > 4 ? 'calc(100% - 3.6875rem)' : undefined,
        }}
        ref={listRef}
      >
        {groupList.map((result, index) => {
          const searchParams = mappedSearchItemParams({
            collection: 'group',
            documentId: result.id.toString(),
            queryId,
            index: startIndex + index,
            query,
            queryFrom: queryFrom as QueryFromType,
            filterParams: {
              filterSort: searchFilter.order,
              filterCategoryIds: getCategoryParams(searchFilter.category),
              filterRegionRange: searchFilter.regionRange,
              filterMinAge: searchFilter.minAge ?? undefined,
              filterMaxAge: searchFilter.maxAge ?? undefined,
            },
            tab: currentTab,
          });

          return (
            <ImpressionArea
              key={JSON.stringify(searchParams)}
              onLogging={() => {
                searchLogger.impressionGroupSearchItemV1(searchParams);
              }}
              exposureTime={300}
              threshold={0.99}
            >
              <SearchResultItem
                result={result}
                onClick={() => {
                  searchLogger.clickedGroupSearchItemV1(searchParams);

                  trackEvent({
                    event: 'click_simple_search_article',
                    params: {
                      service_name: 'community_group',
                      origin: 'community_group.client',
                      referrer: referrer,
                      query: decodeURIComponent(query ?? ''),
                      size: 20,
                      offset: Math.floor((index ?? 0) / 20) * 20,
                      document_id: result.id.toString(),
                    },
                    loggerType: ['SEARCH_KARROT'],
                  });

                  trackEvent({
                    event: 'click_search_result_list_item',
                    params: {
                      groupName: result.name,
                      groupCategoryName: result.category?.name,
                      groupId: result.id,
                      listItemIndex: index,
                      filterOrder: searchFilter.order,
                    },
                    sample: true,
                  });

                  push('GroupDetailPage', {
                    groupId: result.id.toString(),
                  });
                }}
                enabledJuniorGroupExperiment={enabledJuniorGroupExperiment}
              />
            </ImpressionArea>
          );
        })}
        {hasNextPage && <SearchResultLoader {...searchFilter} />}
        <div className={s.safeAreaBottom} />
      </ul>
    </>
  );
};

export default SearchResultList;
