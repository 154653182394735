import { Spinner } from '@community-group/components';
import queryString from 'query-string';
import { useRef, useState } from 'react';

import { SEOUL_RUN_PROMOTION_CONFIG } from '@/features/SeoulRunPromotion/config';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useTheme } from '@/stackflow/hooks/useTheme';

import * as s from './SeoulRunPromotionTab.css';

const SeoulRunPromotionTab = () => {
  const { getTheme } = useTheme();

  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    refer_root,
    refer_section,
    refer_content,
    refer_content_index,
    refer_action,
  } = useQueryParams();

  const landkitQueryStrings = queryString.stringify({
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    refer_root,
    refer_section,
    refer_content,
    refer_content_index,
    refer_action,
  });

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  useEnterTrackEvent({
    event: 'enter_home_event_tab',
    params: {},
    sample: true,
  });

  return (
    <div className={s.Container({ navigatorTheme: getTheme() })}>
      {isLoading && (
        <div className={s.LoaderWrapper}>
          <Spinner size="small" />
        </div>
      )}
      <iframe
        src={`${SEOUL_RUN_PROMOTION_CONFIG.LANDKIT_PAGE}${
          landkitQueryStrings ? `?${landkitQueryStrings}` : ''
        }`}
        className={s.IframeStyle}
        ref={iframeRef}
        allow="web-share"
        onLoad={handleIframeLoad}
        style={{
          paddingTop: 'calc(env(safe-area-inset-top) * -1)',
        }}
      />
    </div>
  );
};

export default SeoulRunPromotionTab;
