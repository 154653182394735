import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { AsyncBoundary } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import GroupDeleteSettingItem from '@/features/GroupSetting/GroupDeleteItem';
import * as stackflow from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { openGroupReport } from '@/utils/link';
type Props = {
  role: GroupMemberRoleEnum;
  groupId: string;
};

const GroupExtraSettingSection = ({ role, groupId }: Props) => {
  const stack = stackflow?.useFlow();
  const push = stack?.push;
  const handleLeaveConfirm = () => {
    push('BottomSheet/LeaveGroupBottomSheet', { groupId });
  };

  const handleDelegateSuperHost = () => {
    push('GroupSettingMemberDelegateSuperHostPage', { groupId });
  };

  const handleGroupReport = () => {
    trackEvent({ event: 'click_report', params: { type: 'group' } });
    openGroupReport({ groupId });
  };

  const SettingItems = useMemo(() => {
    if (role === 'superHost') {
      return (
        <SettingList.Wrapper
          renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
        >
          <SettingList.Item title="모임장 위임" onClick={handleDelegateSuperHost} />
          <AsyncBoundary pendingFallback={<SettingList.Item title="모임 삭제" warning />}>
            <GroupDeleteSettingItem />
          </AsyncBoundary>
        </SettingList.Wrapper>
      );
    }
    return (
      <SettingList.Wrapper
        renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
      >
        <SettingList.Item title="모임 신고" onClick={handleGroupReport} />
        <SettingList.Item title="모임 나가기" warning onClick={handleLeaveConfirm} />
      </SettingList.Wrapper>
    );
  }, [role]);
  return <>{SettingItems}</>;
};

export default GroupExtraSettingSection;
