import { useCallback } from 'react';

import { useQueryGroupMemberGradeApplicationDecision } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeApplicationDecision';
import { queryClient } from '@/shared/api/instance';
import { useFlow } from '@/stackflow';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

type Props = {
  groupId: string;
};

export const useCheckMemberGradeApplicationRejectedBottomSheet = ({ groupId }: Props) => {
  const { memberGradeApplicationId } = useQueryParams();
  const query = useQueryGroupMemberGradeApplicationDecision({
    groupId,
    applicationId: memberGradeApplicationId ?? '',
  });
  const { push } = useFlow();

  const checkTurnOnNotificationBottomSheet = useCallback(async () => {
    if (!memberGradeApplicationId) return false;

    const { status, message } = await queryClient.fetchQuery(query);
    const allowBottomSheet = status === 'reject' && Boolean(message);

    if (!allowBottomSheet) return false;

    // 거절 메시지 확인 바텀 시트
    push('BottomSheet/MemberGradeApplicationRejectedCommentBottomSheet', {
      groupId,
      memberGradeApplicationId,
    });
    return true;
  }, [groupId, memberGradeApplicationId, push, query]);

  return checkTurnOnNotificationBottomSheet;
};
