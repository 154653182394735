import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
  GroupPermissionsResponsePermissions,
} from '@community-group/api/lib/group/models';
import { useCallback, useMemo } from 'react';
import { match, P } from 'ts-pattern';

import { useHandleGroupRegularMeetupPlace } from '@/domain/Group/hooks/useHandleGroupRegularMeetupPlace';
import { groupDefaultSettingConfig } from '@/features/GroupSetting/util/groupSettingConfig';
import * as stackflow from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

type Props = {
  groupId: string;
  role: GroupMemberRoleEnum;
  permissions: GroupPermissionsResponsePermissions;
  groupDetail: GroupDetailPresentation;
};
export const useDefaultSettingHandler = ({ groupId, role, permissions, groupDetail }: Props) => {
  const stack = stackflow?.useFlow();
  const push = stack?.push;

  const { handleUpdatePoiButtonClick } = useHandleGroupRegularMeetupPlace({ groupId });

  const defaultSetting = useMemo(
    () =>
      groupDefaultSettingConfig.filter((item) => {
        if (item.displayText === '모임장봇 설정')
          return permissions.modifyGroupBotSetting.currentRoles.some((r) => r === role);
        return item.roles.some((r) => r === role);
      }),
    [role, permissions.modifyGroupBotSetting.currentRoles]
  );

  const hasMeetupPois = useMemo(
    () => groupDetail.meetupPois.length > 0 && groupDetail.meetupPois[0].coordinates.latitude !== 0,
    [groupDetail.meetupPois]
  );

  const handleItemClick = useCallback(
    (item: (typeof defaultSetting)[number]) => {
      match([item, role])
        .with([{ displayText: '게시판 관리' }, 'superHost'], () => {
          trackEvent({
            event: 'click_board_category_setting',
            params: { groupId },
          });
          push('BoardCategorySetting', { groupId });
        })
        .with(
          [
            { displayText: '모임장봇 설정' },
            P.when(() => permissions.modifyGroupBotSetting.currentRoles.some((r) => r === role)),
          ],
          () => push('GroupSettingGroupBotPage', { groupId, from: 'setting' })
        )
        .with([{ displayText: '자주 모이는 장소 설정' }, 'superHost'], () => {
          if (hasMeetupPois) push('GroupRegularMeetupPlaceSettingPage', { groupId });
          else handleUpdatePoiButtonClick();
        })
        .with([{ displayText: '운영 가이드북' }, P.union('superHost', 'manager')], () =>
          push('GroupGuideBookSettingPage', { groupId })
        )
        .otherwise(() => null);
    },
    [
      role,
      push,
      groupId,
      permissions.modifyGroupBotSetting.currentRoles,
      hasMeetupPois,
      handleUpdatePoiButtonClick,
    ]
  );
  return { handleItemClick, defaultSetting };
};
