import { AsyncBoundary, Typography } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useGetBoardTypeList } from '@/api/hooks/useGetBoardTypeList';
import { usePutBoardCategories } from '@/api/hooks/usePutBoardCategoires';
import { FormInput } from '@/components/common/base/Input/Text';
import { FixedButton } from '@/components/common/Form/components/FixedButton';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { groupBoardCategoryFieldSchema } from '@/utils/validate/formSchema/boardCategory';
import { validateSchemaWithMessage } from '@/utils/validate/util';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

export type GroupSettingEditBoardCategoryNameBottomSheetParams = Pick<
  PageParams,
  'groupId' | 'initialBoardCategoryName'
>;

const GroupSettingEditBoardCategoryNameBottomSheet: ActivityComponentType<
  GroupSettingEditBoardCategoryNameBottomSheetParams
> = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <GroupSettingEditBoardCategoryNameBottomSheetWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const GroupSettingEditBoardCategoryNameBottomSheetWrapper = () => {
  const { groupId } = usePathParams();
  const { initialBoardCategoryName } = useQueryParams();
  const { open: openSnackbar } = useSnackbar();

  const { pop } = useFlow();

  const { data: categories } = useGetBoardTypeList(Number(groupId), 1000000);

  const { watch, register } = useForm<FieldValues>({
    defaultValues: {
      name: initialBoardCategoryName,
    },
  });

  const nameFieldValue = watch('name');

  const handleErrorWithToast = useHandleErrorWithToast();
  const { bridge } = useBridge();
  const { mutate, isPending } = usePutBoardCategories({
    onError: handleErrorWithToast,
    onSuccess: () => {
      trackEvent({
        event: `click_board_category_edit`,
        params: {
          groupId,
        },
      });
      bridge.openToast({
        toast: {
          body: '게시판을 수정했어요.',
        },
      });
      refetchGroupDetail({ groupId });
      pop();
    },
  });

  const handleEditName = async () => {
    const editedItems = categories?.boardCategories?.map((item) => {
      if (item.category === initialBoardCategoryName) {
        return {
          ...item,
          category: nameFieldValue,
        };
      }
      return item;
    });
    await mutate({
      id: Number(groupId),
      postBoardCategoryModifyForm: {
        boardCategories: editedItems ?? [],
      },
    });
  };

  const isButtonDisabled = useMemo(() => {
    if (nameFieldValue.trim().length === 0) return true;

    if (initialBoardCategoryName === nameFieldValue) return true;

    return false;
  }, [nameFieldValue]);

  const validateForm = () => {
    const validateResult = validateSchemaWithMessage(groupBoardCategoryFieldSchema.name, nameFieldValue);

    if (!validateResult.success) {
      openSnackbar({
        message: validateResult.message,
      });

      return false;
    }
    
    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    handleEditName();
  };

  return (
    <>
      <Spacing size={16} />
      <Typography typography="title3Bold" color="gray900">
        게시판 수정
      </Typography>
      <Spacing size={16} />
      <FormInput
        register={register}
        name="name"
        placeholder="게시판을 잘 표현할 수 있는 이름이면 좋아요."
        maxLength={groupBoardCategoryFieldSchema.name.maxLength ?? 0}
        value={nameFieldValue}
      />
      <Spacing size={16} />
      <FixedButton disabled={isButtonDisabled} onClick={handleSubmit} isLoading={isPending}>
        수정
      </FixedButton>
    </>
  );
};

export default GroupSettingEditBoardCategoryNameBottomSheet;
