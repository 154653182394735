import { Render, withAsyncBoundary } from '@community-group/components';
import { IconMagnifyingglassLine } from '@daangn/react-monochrome-icon';
import { useSuspenseQueries } from '@tanstack/react-query';

import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { LayoutRefProps } from '@/stackflow/components/Layout/components/ContentsLayout';
import { trackEvent } from '@/utils/analytics';

type Props = {
  showSearchBar: boolean;
  setShowSearchBar: React.Dispatch<React.SetStateAction<boolean>>;
  groupId: string;
  layoutRef: React.MutableRefObject<LayoutRefProps | null>;
};
const GroupSettingRenderRight = ({
  showSearchBar,
  setShowSearchBar,
  groupId,
  layoutRef,
}: Props) => {
  const [{ data: groupDetail }, { data: currentUser }] = useSuspenseQueries({
    queries: [useQueryGroupDetail(groupId), useQueryGroupMe({ groupId })],
  });

  const handleSearchClick = () => {
    if (layoutRef?.current?.contentsRef?.current) {
      layoutRef.current.contentsRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setShowSearchBar((p) => !p);
    trackEvent({
      event: 'click_group_setting_search_bar_click',
      params: {
        groupId,
        groupName: groupDetail.name,
        role: currentUser.role,
        categoryId: groupDetail.category.id,
        categoryName: groupDetail.category.name,
      },
    });
  };

  return (
    <Render condition={!showSearchBar}>
      <IconMagnifyingglassLine
        size={24}
        style={{ marginRight: '0.5rem' }}
        onClick={handleSearchClick}
      />
    </Render>
  );
};

export default withAsyncBoundary(GroupSettingRenderRight, {
  pendingFallback: <IconMagnifyingglassLine size={24} style={{ marginRight: '0.5rem' }} />,
  rejectedFallback: <></>,
});
