import { groupClient } from '@community-group/api';

import { USER_ME_URL } from '@/api/base/user';
import { getOpenEventPath } from '@/api/hooks/event/common/useGetOpenEvent';
import { getGroupFeedListPath } from '@/api/hooks/useGetGroupFeedList';
import { getGroupFeedReviewWriteableMeetupPath } from '@/api/hooks/useGetGroupFeedReviewWriteableMeetup';
import { getGroupFeedSummaryBulletin } from '@/api/hooks/useGetGroupFeedSummaryBulletin';
import { getGroupFeedUpcomingMeetupCount } from '@/api/hooks/useGetGroupFeedUpcomingMeetupCount';
import { getGroupLevelPath } from '@/api/hooks/useGetGroupLevel';
import { getGroupPhotoListPath } from '@/api/hooks/useGetGroupPhotoList';
import { getMeetupListPath } from '@/api/hooks/useGetMeetupList';
import { getMemberMissionListPath } from '@/api/hooks/useGetMemberMissionList';
import { getNoticesPath } from '@/api/hooks/useGetNotices';
import { getRunningGrowthMissionPath } from '@/api/hooks/useGetRunningGrowthMission';
import { getSuperHostOnBoardingMissionPath } from '@/api/hooks/useGetSuperHostOnBoardingMission';
import { GROUP_DETAIL_QUERY_KEY } from '@/domain/GroupDetail/queries';
import { GROUP_LEVEL_QUERY_KEY } from '@/domain/GroupLevel/queries';
import { queryClient } from '@/shared/api/instance';

import { refetchGroupChallenge } from './challenge';

type Params = {
  groupId: string;
};

/*
 *  모임 상세 페이지에서 사용하는 쿼리를 한번에 리페치하는 함수
 */
export const refetchGroupDetail = ({ groupId }: Params) => {
  const numberGroupId = Number(groupId);

  queryClient.refetchQueries({
    queryKey: GROUP_LEVEL_QUERY_KEY.base(groupId),
  });

  queryClient.refetchQueries({
    queryKey: GROUP_DETAIL_QUERY_KEY.me(groupId),
  });
  queryClient.refetchQueries({
    queryKey: GROUP_DETAIL_QUERY_KEY.base(groupId),
  });
  queryClient.refetchQueries({
    queryKey: [getGroupPhotoListPath(groupId)],
  });
  queryClient.refetchQueries({
    queryKey: [getMeetupListPath(groupId)],
  });
  queryClient.refetchQueries({
    queryKey: [getGroupFeedReviewWriteableMeetupPath(numberGroupId)],
  });
  queryClient.refetchQueries({
    queryKey: [
      groupClient.api.PostBoardApi.getapiV1GroupsIdPostsBoardCategoriesGetPath(numberGroupId),
    ],
  });
  queryClient.refetchQueries({
    queryKey: [getNoticesPath(numberGroupId)],
  });
  queryClient.refetchQueries({
    queryKey: [getOpenEventPath()],
  });
  queryClient.refetchQueries({
    queryKey: [USER_ME_URL],
  });
  queryClient.refetchQueries({
    queryKey: [getMemberMissionListPath(numberGroupId)],
  });
  queryClient.refetchQueries({
    queryKey: [getSuperHostOnBoardingMissionPath(numberGroupId)],
  });
  queryClient.refetchQueries({
    queryKey: [getRunningGrowthMissionPath(numberGroupId)],
  });
  queryClient.refetchQueries({
    queryKey: [getGroupFeedListPath(groupId)],
  });
  queryClient.refetchQueries({
    queryKey: [getGroupFeedSummaryBulletin(numberGroupId)],
  });
  queryClient.refetchQueries({
    queryKey: [getGroupFeedUpcomingMeetupCount(numberGroupId)],
  });
  queryClient.refetchQueries({
    queryKey: [getGroupLevelPath(numberGroupId)],
  });
  refetchGroupChallenge({ groupId });
};
