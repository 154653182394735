import { Typography, VerticalSpacing } from '@community-group/components';
import { IconPersonFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import getGroupStage from '@/domain/Group/utils/getGroupStage';

import * as s from './ListView.css';

type Props = {
  memberCount: number;
  createdAt: string;
  enabledJuniorGroupExperiment?: boolean;
};

export const MemberCountBadge = ({
  memberCount,
  createdAt,
  enabledJuniorGroupExperiment,
}: Props) => {
  if (enabledJuniorGroupExperiment) {
    return <MemberCountBadgeTreatment memberCount={memberCount} createdAt={createdAt} />;
  }

  return (
    <span className={s.Badge}>
      <div className={s.Space}>⸱</div>
      <IconPersonFill size={12} color={vars.$scale.color.gray500} />
      <VerticalSpacing size={2} />
      <Typography typography="caption1Regular" color="gray600" wordBreak="keep-all">
        {memberCount}명
      </Typography>
    </span>
  );
};

const MemberCountBadgeTreatment = ({ memberCount, createdAt }: Props) => {
  const groupStage = getGroupStage(memberCount, createdAt);

  const memberCountText = useMemo(() => {
    if (groupStage === 'junior') return '신규 모임';
    return `${memberCount}명`;
  }, [groupStage, memberCount]);

  if (groupStage === 'middle') return null;

  return (
    <span className={s.Badge}>
      <div className={s.Space}>⸱</div>
      <IconPersonFill size={12} color={vars.$scale.color.gray500} />
      <VerticalSpacing size={2} />
      <Typography typography="caption1Regular" color="gray600" wordBreak="keep-all">
        {memberCountText}
      </Typography>
    </span>
  );
};
