import { BoxButton } from '@community-group/components';
import AccountSdk from '@karrotpay/account-sdk/lib/sdk/v1/sdk';

import { useBridge } from '@/contexts/Bridge';
import { TypeActivityNames, useFlow } from '@/stackflow';

import * as s from './style.css';

type Props = {
  karrotPaymentsCreateAccountQueryParams: {
    where: string;
  };
  replaceParams: {
    activity: TypeActivityNames;
    params?: object;
    options?: { animate?: boolean };
  };
};
const KarrotPaymentsCreateAccountAccessoryBar = ({
  karrotPaymentsCreateAccountQueryParams,
  replaceParams,
}: Props) => {
  const { bridge } = useBridge();
  const { replace } = useFlow();

  const handleJoinKarrotPayments = () => {
    const accountSDK = new AccountSdk(bridge as any, globalThis.appConfig.appEnv, 'DAANGN_WEB');
    accountSDK.signup({
      queryParams: { where: karrotPaymentsCreateAccountQueryParams.where },
      onSignupSuccess: () => {
        replace(replaceParams.activity, replaceParams.params ?? {}, replaceParams.options);
      },
    });
  };

  return (
    <div className={s.Wrapper}>
      <BoxButton width="100%" size="xlarge" onClick={handleJoinKarrotPayments}>
        당근페이 가입하기
      </BoxButton>
    </div>
  );
};

export default KarrotPaymentsCreateAccountAccessoryBar;
