import { groupClient } from '@community-group/api';
import {
  GroupFeedListResponse,
  GroupFeedSummary,
  GroupFeedSummaryResponse,
  PostDetail,
  PostListResponse,
  PostResponse,
} from '@community-group/api/lib/group/models';
import { InfiniteData, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { updateChallengeFeeds } from '@/domain/Challenge/utils/updateChallengeFeeds';
import { GROUP_FEED_QUERY_KEY } from '@/domain/GroupFeed/queries';
import { updateGroupMemberFeeds } from '@/domain/GroupFeed/utils/updateGroupMemberFeeds';
import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';
import { queryClient } from '@/shared/api/instance';

import { FeedPageProps } from '../base/feed';
import { GROUP_URL } from '../base/group';
import { PostLikeResponse } from '../base/like';
import { useFetchInstance } from './instance/useFetchInstance';
import { getGroupFeedListPath } from './useGetGroupFeedList';
import { getGroupFeedSummaryQueryKey } from './useGetGroupFeedSummary';
import { getMemberFeedsSummaryQueryKey } from './useGetMemberFeeds';
import { getMyGroupPostListPath } from './useGetMyGroupPostList';
import { getPostDetailPath } from './useGetPostDetail';

type Props = UseMutationOptions<
  PostLikeResponse,
  Error,
  {
    groupId?: string;
    postId?: string;
    authorId?: string;
    boardCategories?: number[];
    challengeId?: number;
  }
>;

export const usePatchLikePost = ({ onError, onSuccess, onSettled }: Props) => {
  const onMutate = async (variables: {
    groupId?: string;
    postId?: string;
    authorId?: string;
    challengeId?: number;
  }) => {
    await cancelQueryKeyHandler({
      groupId: variables.groupId,
      postId: variables.postId,
      authorId: variables.authorId,
    });

    updatePostDetail(variables.groupId, variables.postId);
    updateFeedList(variables.postId);
    updateGroupFeedList(variables.groupId, variables.postId);
    updateGroupDetailFeedList(variables.groupId, variables.postId);
    updateGroupDetailFeedSummary(variables.groupId, variables.postId);
    updateMemberFeedsSummary(variables.groupId, variables.authorId, variables.postId);
    updateGroupMemberFeeds({
      type: 'like',
      groupId: variables.groupId,
      userId: variables.authorId,
      targetId: variables.postId,
    });

    if (variables.challengeId) {
      updateChallengeFeeds({
        type: 'like',
        groupId: variables.groupId,
        postId: variables.postId,
        challengeId: variables.challengeId,
      });
    }
  };
  const handleSettled: typeof onSettled = (data, error, variables, context) => {
    onSettled?.(data, error, variables, context);
  };

  const fetchInstance = useFetchInstance();

  const patchLikePost = async ({ groupId, postId }: { groupId?: string; postId?: string }) => {
    const { data } = await fetchInstance.patch<
      { content: string },
      AxiosResponse<PostLikeResponse>
    >(`${GROUP_URL}/${groupId}/posts/${postId}/emotions`);

    return data;
  };

  return useThrottleMutation(patchLikePost, {
    onSettled: handleSettled,
    onError,
    onMutate,
    onSuccess,
  });
};

const cancelQueryKeyHandler = async ({
  groupId,
  postId,
  authorId,
}: {
  groupId?: string;
  postId?: string;
  authorId?: string;
}) => {
  const postDetailKey = getPostDetailPath(groupId, postId);
  const feedListKey = getMyGroupPostListPath();
  const groupDetailFeedListKey = groupId ? getGroupFeedListPath(groupId) : undefined;
  const groupFeedSummaryKey = getGroupFeedSummaryQueryKey(Number(groupId));
  const groupMemberFeedsSummaryKey = getMemberFeedsSummaryQueryKey(
    Number(groupId),
    Number(authorId)
  );

  await queryClient.cancelQueries({
    queryKey: [
      postDetailKey,
      feedListKey,
      groupDetailFeedListKey,
      groupFeedSummaryKey,
      groupMemberFeedsSummaryKey,
      GROUP_FEED_QUERY_KEY.base(groupId ?? '0', authorId ?? '0'),
    ],
    exact: true,
  });
};

const updatePostDetail = async (groupId?: string, postId?: string) => {
  const postDetailKey = getPostDetailPath(groupId, postId);

  // prveData 체크하지 않고 바로 setQueryData를 하면 해당 query를 직접적으로 사용하는 페이지에서 에러가 발생해요.
  const prevData = queryClient.getQueryData<
    AxiosResponse<groupClient.model.PostResponse | undefined>
  >([postDetailKey]);

  if (!prevData) return;

  queryClient.setQueryData<AxiosResponse<PostResponse> | undefined>([postDetailKey], (prevData) => {
    if (!prevData) {
      return;
    }
    return {
      ...prevData,
      data: {
        ...prevData.data,
        post: {
          ...prevData.data.post,
          emotion: {
            count: prevData.data.post.emotion.count + 1,
            myEmotion: 'like',
          },
        },
      },
    };
  });
};

const updateFeedList = async (postId = '0') => {
  const feedListKey = getMyGroupPostListPath();

  const prevData = queryClient.getQueryData<FeedPageProps | undefined>([feedListKey]);

  if (!prevData) return;

  queryClient.setQueryData<FeedPageProps | undefined>([feedListKey], (prevData) => {
    if (!prevData) {
      return prevData;
    }

    const currentData = prevData.pages.map((page) => {
      const posts = page.data?.posts?.map((post: PostDetail) => {
        if (post.id === parseInt(postId)) {
          return {
            ...post,
            emotion: {
              count: post.emotion.count + 1,
              myEmotion: 'like',
            },
          };
        }

        return post;
      });

      return {
        ...page,
        data: {
          ...page.data,
          posts: posts,
        },
      };
    });

    return {
      pages: currentData,
      pageParams: prevData.pageParams,
    };
  });
};

const updateGroupFeedList = async (groupId?: string, targetId?: string) => {
  if (!groupId || !targetId) return;

  const cache = queryClient.getQueriesData({
    queryKey: [getGroupFeedListPath(groupId)],
  });

  cache.forEach(([queryKey]) => {
    queryClient.setQueryData(queryKey, (prev?: InfiniteData<AxiosResponse<PostListResponse>>) => {
      if (!prev) return;

      const targetPostId = parseInt(targetId);
      const next = prev.pages.map((page) => {
        const posts = page.data?.posts?.map((post: PostDetail) => {
          if (post.id !== targetPostId) return post;

          return {
            ...post,
            emotion: {
              count: post.emotion.count + 1,
              myEmotion: 'like',
            },
          };
        });

        return {
          ...page,
          data: {
            ...page.data,
            posts,
          },
        };
      });

      return {
        pages: next,
        pageParams: prev.pageParams,
      };
    });
  });
};

// 모임 상세의 게시판 피드 데이터를 갱신
const updateGroupDetailFeedList = async (groupId?: string, targetId?: string) => {
  if (!groupId || !targetId) return;

  const queryKey = [getGroupFeedListPath(groupId)];

  const cache = queryClient.getQueriesData({
    queryKey,
  });

  cache.forEach(([queryKey]) => {
    queryClient.setQueryData(
      queryKey,
      (prev?: InfiniteData<AxiosResponse<GroupFeedListResponse>>) => {
        if (!prev || !prev.pages) return;

        const targetItemId = parseInt(targetId);
        const next = prev.pages.map((page) => {
          const items = page.data?.items?.map((item: GroupFeedSummary) => {
            if (item.id !== targetItemId) return item;

            return {
              ...item,
              emotion: {
                count: item.emotion.count + 1,
                myEmotion: 'like',
              },
            };
          });

          return {
            ...page,
            data: {
              ...page.data,
              items,
            },
          };
        });

        return {
          pages: next,
          pageParams: prev.pageParams,
        };
      }
    );
  });
};

// 모임 상세의 게스트 게시판 요약 피드 데이터를 갱신
const updateGroupDetailFeedSummary = async (groupId?: string, targetId?: string) => {
  if (!groupId || !targetId) return;

  const queryKey = [getGroupFeedSummaryQueryKey(Number(groupId))];
  const cache = queryClient.getQueriesData({
    queryKey,
  });

  cache.forEach(([queryKey]) => {
    queryClient.setQueryData(queryKey, (prev?: AxiosResponse<GroupFeedSummaryResponse>) => {
      if (!prev || !prev.data) return;

      const targetItemId = parseInt(targetId);
      const updateEmotionCount = (feed) => {
        if (feed.id !== targetItemId) return feed;

        return {
          ...feed,
          emotion: {
            count: feed.emotion.count + 1,
            myEmotion: 'like',
          },
        };
      };

      const allFeeds = prev.data.allFeeds.map(updateEmotionCount);
      const categoryFeeds = prev.data.categoryFeeds.map((category) => {
        if (!category || !category.feeds) return category;
        const feeds = category.feeds.map(updateEmotionCount);

        return {
          ...category,
          feeds,
        };
      });

      return {
        ...prev,
        data: {
          ...prev.data,
          allFeeds,
          categoryFeeds,
        },
      };
    });
  });
};

const updateMemberFeedsSummary = async (groupId?: string, authorId?: string, targetId?: string) => {
  if (!groupId || !targetId) return;

  const queryKey = [getMemberFeedsSummaryQueryKey(Number(groupId), Number(authorId))];
  const cache = queryClient.getQueriesData({
    queryKey,
  });

  cache.forEach(([queryKey]) => {
    queryClient.setQueryData(
      queryKey,
      (prev?: InfiniteData<AxiosResponse<GroupFeedListResponse>>) => {
        if (!prev || prev.pages.length === 0) return prev;

        const targetItemId = parseInt(targetId);

        const updatedPages = prev.pages.map((page) => {
          const updatedItems = page.data.items.map((item) => {
            if (item.id !== targetItemId) return item;
            return {
              ...item,
              emotion: {
                count: item.emotion.count + 1,
                myEmotion: 'like',
              },
            };
          });

          return {
            ...page,
            data: {
              ...page.data,
              items: updatedItems, // 기존 items 배열을 업데이트된 items로 교체합니다.
            },
          };
        });

        const updated = {
          ...prev,
          pages: updatedPages, // pages 배열을 업데이트된 pages로 교체합니다.
        };

        return updated; // 업데이트된 데이터를 반환합니다.
      }
    );
  });
};
