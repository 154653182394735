import { GroupMeetupProgressStatusPresentationStatus } from '@community-group/api/lib/group/models';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  Dialog,
  useBottomSheet,
  useDialog,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';

import { isOpenedGroupMeetup } from '../../utils/progressStatus';

type Props = {
  progressStatus: GroupMeetupProgressStatusPresentationStatus;
  isSeoulRunPromotionMeetup?: boolean;
  onClone: () => void;
  onModifyClick: () => void;
  onClose: () => void;
  onDelete: () => void;
  onReport?: () => void;
};

export const MeetupHostMoreMenuAppBarBottomSheet = ({
  progressStatus,
  isSeoulRunPromotionMeetup = false,
  onClone,
  onModifyClick,
  onClose,
  onDelete,
  onReport,
}: Props) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();
  const { open: openDialog, close: closeDialog } = useDialog();

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        {!isSeoulRunPromotionMeetup && (
          <ActionListItem
            onClick={async () => {
              await closeBottomSheet();

              onClone();
            }}
          >
            복제
          </ActionListItem>
        )}
        {isOpenedGroupMeetup(progressStatus) && (
          <>
            <ActionListItem
              onClick={async () => {
                await closeBottomSheet();
                openDialog({
                  element: (
                    <Dialog
                      title="일정을 종료할까요?"
                      description="이웃이 더이상 일정에 참여할 수 없고, 수정할 수 없어요."
                      primaryActionLabel="종료"
                      secondaryActionLabel="취소"
                      onSecondaryAction={async () => {
                        await closeDialog();
                      }}
                      onPrimaryAction={async () => {
                        await closeDialog();
                        onClose();
                      }}
                    />
                  ),
                });
              }}
            >
              일정종료
            </ActionListItem>
            <ActionListItem
              onClick={async () => {
                await closeBottomSheet();

                onModifyClick();
              }}
            >
              수정
            </ActionListItem>
          </>
        )}
        <ActionListItem
          color={vars.$semantic.color.danger}
          onClick={async () => {
            await closeBottomSheet();

            onDelete();
          }}
        >
          삭제
        </ActionListItem>
        {onReport && (
          <ActionListItem
            onClick={async () => {
              await closeBottomSheet();

              onReport();
            }}
          >
            신고
          </ActionListItem>
        )}
      </ActionList>
    </ActionSheet>
  );
};
