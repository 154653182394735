import { BoxButton, useKeyboardState } from '@community-group/components';
import { UseFormReturn } from 'react-hook-form';

import { GroupChallengeEditFormProps } from '@/activities/Group/[groupId]/Challenge/Edit';
import usePatchChallenge from '@/domain/Challenge/hooks/usePatchChallenge';
import { validateCreateDescription } from '@/features/ChallengeCreate/utils/date';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';

import * as s from './style.css';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

const GroupChallengeEditAccessoryBar = ({
  formHandler,
}: {
  formHandler: UseFormReturn<GroupChallengeEditFormProps>;
}) => {
  const { groupId, challengeId } = usePathParams();
  const { pop } = useFlow();
  const { open: openSnackbar } = useSnackbar();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: patchChallenge } = usePatchChallenge({
    onError: handleErrorWithToast,
    onSuccess: () => {
      pop();
      openSnackbar({
        message: '챌린지가 수정되었어요.',
      });
      refetchGroupDetail({ groupId });
      refetchGroupChallenge({ groupId, challengeId });
    },
  });

  const handleSubmit = () => {
    formHandler.handleSubmit(async (data) => {
      const description = data?.description ?? '';
      const descriptionValidation = validateCreateDescription({ description });
      if (!descriptionValidation.isValid) {
        openSnackbar({
          message: descriptionValidation.message,
        });
        return;
      }

      const formData = {
        description: data.description,
        status: data.status,
        images: data.originImages?.map((image) => image.id) ?? [],
      };

      patchChallenge({
        id: Number(groupId),
        challengeId: Number(challengeId),
        form: formData,
      });
    })();
  };
  const { opened: isOpenedKeyboard } = useKeyboardState();

  return (
    <div className={s.Container({ isOpenedKeyboard })}>
      <BoxButton size="xlarge" variant="primary" width="100%" onClick={handleSubmit}>
        수정하기
      </BoxButton>
    </div>
  );
};

export default GroupChallengeEditAccessoryBar;
