import '../token-minimum.css';
import './stylesheet.css';

import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import * as React from 'react';

import { type ActionChipVariantProps, actionChip } from './recipe';

export interface ActionChipProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    ActionChipVariantProps {
  prefixIcon?: React.ReactNode;

  suffixIcon?: React.ReactNode;
}

export const ActionChip = React.forwardRef<HTMLButtonElement, ActionChipProps>(
  (
    {
      className,
      size = 'medium',
      layout = 'text',
      children,
      prefixIcon,
      suffixIcon,
      ...otherProps
    },
    ref
  ) => {
    const classNames = actionChip({ size, layout });
    return (
      <button ref={ref} className={clsx(classNames.root, className)} {...otherProps}>
        {prefixIcon && <Slot className={classNames.prefix}>{prefixIcon}</Slot>}
        {layout === 'text' ? (
          <span className={classNames.label}>{children}</span>
        ) : (
          <Slot className={classNames.icon}>{children}</Slot>
        )}
        {suffixIcon && <Slot className={classNames.suffix}>{suffixIcon}</Slot>}
      </button>
    );
  }
);
ActionChip.displayName = 'ActionChip';
