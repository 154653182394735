import { Typography } from '@community-group/components';
import { IconFlameFill, IconMegaphoneFill, IconPostFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import React, { useCallback, useMemo } from 'react';

import IconCategoryGroup from '@/assets/images/icon_category_group.svg';

import * as s from './index.css';

type Props = {
  prefixIconType?: 'group' | 'post' | 'challenge' | 'notice';
  separator?: string;
  items: string[];
  onClick?: () => void;
};

export const Breadcrumbs = ({
  prefixIconType = 'group',
  separator = '/',
  items,
  onClick,
}: Props) => {
  const PrefixIcon = useCallback(() => {
    if (prefixIconType === 'group') return <img width={15} height={15} src={IconCategoryGroup} />;
    if (prefixIconType === 'post')
      return <IconPostFill size={15} color={vars.$scale.color.gray500} />;
    if (prefixIconType === 'challenge')
      return <IconFlameFill size={18} color={vars.$scale.color.gray500} />;
    if (prefixIconType === 'notice') return <IconMegaphoneFill size={15} color="#E15D64" />;
    return <></>;
  }, [prefixIconType]);

  const itemLastIndex = items.length - 1;

  // 공지사항일 경우 스타일 클래스 결정
  const wrapperClassName = useMemo(() => {
    return prefixIconType === 'notice' ? s.noticeWrapper : s.wrapper;
  }, [prefixIconType]);

  return (
    <div className={wrapperClassName} onClick={onClick}>
      <div className={s.iconWrapper}>
        <PrefixIcon />
      </div>
      {items.map((item, index) => {
        const isNotSingleItem = index !== 0;
        const isLastIndex = itemLastIndex === index;
        const isLastOfMultipleItems = isLastIndex && isNotSingleItem;

        return (
          <React.Fragment key={`breadcrumbs-${item}`}>
            {isNotSingleItem && <div className={s.separator}>{separator}</div>}
            <Typography
              as="caption"
              typography="caption1Regular"
              color={isLastIndex ? (prefixIconType === 'notice' ? 'red950' : 'gray900') : 'gray600'}
              ellipsisAfterLines={isLastOfMultipleItems ? undefined : 1}
              className={s.BreadcrumbsText}
            >
              {item}
            </Typography>
          </React.Fragment>
        );
      })}
    </div>
  );
};
