import { GroupStatusEnum } from '@community-group/api/lib/group/models';
import { Dialog, useDialog } from '@community-group/components';
import { useActivity } from '@stackflow/react';

import { useBlockGroupActivity } from '@/shared/hooks/useBlockGroupActivity';
import { useBack } from '@/stackflow/hooks/useBack';

import { BlockableActivityNames } from './blockMessageMapModel';
import { useCheckGroupActivityAccessibilityByGroupStatus } from './useCheckGroupActivityAccessibilityByGroupStatus';

export const useBlockGroupActivityByGroupStatus = (groupStatus: GroupStatusEnum) => {
  const { name } = useActivity();
  const activityName = name as BlockableActivityNames;
  const { isAccessible, message } = useCheckGroupActivityAccessibilityByGroupStatus({
    groupStatus,
    activityName,
  });

  const { open: openDialog, close: closeDialog } = useDialog();
  const back = useBack();

  useBlockGroupActivity({
    isBlocked: !isAccessible,
    onBlock: () => {
      openDialog({
        element: (
          <Dialog
            description={message}
            secondaryActionIntent="nonpreferred"
            primaryActionLabel="확인"
            onPrimaryAction={async () => {
              await closeDialog();
              back();
            }}
          />
        ),
      });
    },
    onPressBackButton: async () => {
      await closeDialog();
      back();
    },
  });
};
