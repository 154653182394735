import { IconMagnifyingglassLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import * as s from './index.css';

interface Props {
  placeholder?: string;
}

const FakeSearchBar = ({ placeholder }: Props) => {
  return (
    <div className={s.Wrapper}>
      <div className={s.Form}>
        <div className={s.IconWrapper}>
          <IconMagnifyingglassLine size={16} color={vars.$scale.color.gray500} />
        </div>
        <input className={s.Input} placeholder={placeholder} />
      </div>
    </div>
  );
};

export default FakeSearchBar;
