import { ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';

import { GROUP_CHALLENGE_QUERY_KEY } from '@/domain/Challenge/queries';
import ChallengeCertifyFeedsTitle from '@/features/GroupChallengeCertify/Feed/ChallengeCertifyFeedsTitle';
import GroupChallengeCertifyFeeds from '@/features/GroupChallengeCertify/Feed/GroupChallengeCertifyFeeds';
import { queryClient } from '@/shared/api/instance';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

export type ChallengeCertifyFeedsPageParams = Pick<PageParams, 'groupId' | 'challengeId'>;

const ChallengeCertifyFeedsPage: ActivityComponentType<ChallengeCertifyFeedsPageParams> = () => {
  const { groupId, challengeId } = usePathParams();

  // 모든 페이지네이션 데이터를 리셋하고 첫 페이지만 다시 fetch
  const handleRefetch = () => {
    const queryKey = GROUP_CHALLENGE_QUERY_KEY.feeds(groupId.toString(), challengeId.toString());

    queryClient.resetQueries({
      queryKey,
      exact: true,
    });
    queryClient.refetchQueries({
      queryKey,
      exact: true,
    });
  };

  return (
    <AppScreen
      appBar={{
        title: <ChallengeCertifyFeedsTitle groupId={groupId} challengeId={challengeId} />,
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
      onPull={handleRefetch}
    >
      <GroupChallengeCertifyFeeds groupId={groupId} challengeId={challengeId} />
    </AppScreen>
  );
};

export default withAsyncBoundary(ChallengeCertifyFeedsPage, {
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
});
