import { groupClient } from '@community-group/api';
import { ApiV1GroupsProfileUserIdMeetupMemberReviewMessagesGetRequestParams } from '@community-group/api/lib/group/api/group-profile-api';
import { PaginationQueryParams } from '@community-group/components/shared';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { GROUP_PROFILE_QUERY_KEY } from '@/domain/GroupProfile/queries';
import { useRead } from '@/shared/api/hooks/useRead';
type Props = {
  userId: number;
  reviewExposureRange?: ApiV1GroupsProfileUserIdMeetupMemberReviewMessagesGetRequestParams['reviewExposureRange'];
} & PaginationQueryParams;

export const useReadGroupMeetupMemberReviewMessages = ({
  userId,
  initialCursor,
  reviewExposureRange,
}: Props) => {
  const read = useRead(
    groupClient.api.GroupProfileApi.apiV1GroupsProfileUserIdMeetupMemberReviewMessagesGet
  );

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  } = useSuspenseInfiniteQuery({
    queryKey: GROUP_PROFILE_QUERY_KEY.meetupMemberReview(userId.toString()),
    queryFn: ({ pageParam = initialCursor }) => {
      return read({
        userId,
        reviewExposureRange,
        cursor: pageParam,
        limit: 30,
      });
    },
    initialPageParam: initialCursor,
    getNextPageParam: (data) =>
      data.data.pagination?.hasNext ? data.data.pagination.endCursor : undefined,
    select: (data) => data.pages.flatMap((page) => page.data.content),
  });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  };
};
