import { ActivityComponentType } from '@stackflow/react';

import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import AlbumImageViewer from './pages/AlbumImageViewer';
import ImagesImageViewer from './pages/ImagesImageViewer';
import PostDetailImageViewer from './pages/PostDetailImageViewer';
import ProfileImageViewer from './pages/ProfileImageViewer';

const ImageViewerPage: ActivityComponentType<ImageViewerType> = () => {
  const {
    content = 'album',
    groupId = '',
    postId = '',
    userId = '',
    imageUrls = '',
    initialIndex,
  } = useQueryParams();

  if (content === 'album') {
    return <AlbumImageViewer groupId={groupId} initialIndex={Number(initialIndex)} />;
  }
  if (content === 'post') {
    return (
      <PostDetailImageViewer
        groupId={groupId}
        postId={postId}
        initialIndex={Number(initialIndex)}
      />
    );
  }
  if (content === 'images') {
    return (
      <ImagesImageViewer initialIndex={initialIndex?.toString() ?? ''} imageUrls={imageUrls} />
    );
  }
  return <ProfileImageViewer groupId={groupId} userId={userId} />;
};

export default ImageViewerPage;

type CommonFields = {
  initialIndex: string;
  screenName?: string;
};

type AlbumType = CommonFields & {
  content: 'album';
  groupId: string;
};

type PostType = CommonFields & {
  content: 'post';
  groupId: string;
  postId: string;
};

type ProfileType = CommonFields & {
  content: 'profile';
  groupId: string;
  userId: string;
};

type ImagesType = CommonFields & {
  content: 'images';
  imageUrls: string;
};

type ImageViewerType = AlbumType | PostType | ProfileType | ImagesType;
