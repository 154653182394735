import { postUploadImageV2 } from '@community-group/api';
import { StickyInput, StickyInputFormData, useStickInputStore } from '@community-group/components';
import React, { forwardRef, Ref, RefObject, useMemo } from 'react';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useGetPostDetail } from '@/api/hooks/useGetPostDetail';
import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useBridge } from '@/contexts/Bridge';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useBlockGroupActionByGroupStatus } from '@/features/GroupDetail/hooks/useBlockGroupActionByGroupStatus';
import { useMembersForMentions } from '@/hooks/useMembersForMentions';
import { useFlow } from '@/stackflow';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { isMember } from '@/utils/role';

import { useJoinGroupState } from '../../JoinGroupState/hooks/useJoinGroupState';
import { useHandlePostComment } from '../hooks/useHandlePostComment';
import { useHandlePutComment } from '../hooks/useHandlePutComment';
import PendingJoinedGroupView from './PendingJoinedGroupView';
import PostNotMemberAccessoryView from './PostNotMemberAccessoryView';

type Props = {
  listRef: RefObject<HTMLDivElement>;
  groupId: string;
  postId: string;
  parentCommentId?: string;
};

const CommentFormAccessoryView = forwardRef(
  ({ listRef, groupId, postId, parentCommentId }: Props, ref: Ref<HTMLDivElement>) => {
    const fetchCommunityInstance = useFetchInstance();

    const { from } = useQueryParams();

    const { stickyInputProps } = useStickInputStore();
    const isEditMode = useMemo(() => stickyInputProps?.type === 'edit', [stickyInputProps?.type]);

    const membersForMentions = useMembersForMentions(groupId as string);
    const { groupInfo, currentUser } = useJoinGroupState({ groupId });
    const { push } = useFlow();

    const { mutate: mutatePostComment } = useHandlePostComment({
      ref: listRef,
      groupId,
      postId: postId ?? '',
      commentId: parentCommentId ?? '',
      userId: currentUser.id.toString(),
    });

    const { mutate: mutatePutComment } = useHandlePutComment({
      groupId,
      postId,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handlePostComment = (formData: StickyInputFormData, callbackHandler) => {
      trackEvent({
        event: 'click_comment_input',
        params: {
          groupId,
          postId,
          contentType: 'post',
          from,
        },
        sample: true,
      });

      mutatePostComment(
        {
          groupId,
          relatedId: postId ?? '',
          commentCreateForm: {
            parentId: parentCommentId ? Number(parentCommentId) : null,
            content: formData.content,
            images: formData.images.map(({ id }) => id),
            mentionedUserIds: formData.mentionedUserIds as number[],
          },
        },
        {
          ...callbackHandler,
        }
      );
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handlePutComment = (formData: StickyInputFormData, callbackHandler) => {
      mutatePutComment(
        {
          groupId: groupId as string,
          relatedId: postId as string,
          commentId: formData?.id as string,
          commentModifyForm: {
            content: formData.content,
            mentionedUserIds: formData.mentionedUserIds as number[],
          },
        },
        {
          ...callbackHandler,
        }
      );
    };

    const blockGroupActionByGroupStatus = useBlockGroupActionByGroupStatus({
      groupId,
      actionName: stickyInputProps?.type === 'edit' ? 'EditComment' : 'CreateComment',
    });

    const handleSubmit = blockGroupActionByGroupStatus(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (formData: StickyInputFormData, callbackHandler) => {
        if (stickyInputProps?.type === 'edit') {
          return handlePutComment(formData, callbackHandler);
        }

        handlePostComment(formData, callbackHandler);
      }
    );

    const { data: myInfo } = useReadGroupMe({ groupId });
    const myRole = myInfo.role;
    const { handleGroupOnly } = useHandleGroupOnly({ groupId });

    const { post } = useGetPostDetail(groupId, postId);

    const { bridge } = useBridge();

    if (myInfo.state === 'notMember') {
      return <PostNotMemberAccessoryView />;
    }

    if (myInfo.state === 'applied') {
      return <PendingJoinedGroupView />;
    }

    // TODO: 아래로 모두 PostCommentFormAccessoryBar로 교체 가능
    if (!post || post.author.isAccountDeleted) return <></>;

    return (
      <div
        ref={ref}
        onTouchStartCapture={(event) => {
          if (isMember(myRole)) return;

          event.stopPropagation();
        }}
        onMouseDownCapture={(event) => {
          if (isMember(myRole)) return;

          event.stopPropagation();
        }}
        onClickCapture={(event) => {
          if (isMember(myRole)) return;

          event.stopPropagation();
          handleGroupOnly({
            blockGroupOnlyTitle: '모임 가입 안내',
            blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getComment,
            onSuccess() {
              if (groupInfo.isSettingSubNickname) {
                push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
                  groupId,
                });
              }
            },
          });
        }}
      >
        <StickyInput
          fixed={false}
          type={isEditMode ? 'edit' : 'new'}
          initialValues={isEditMode ? stickyInputProps?.initialValues : undefined}
          mentionConfig={[
            {
              trigger: '@',
              name: 'mentionedUserIds',
              mentionList: membersForMentions ?? [],
            },
          ]}
          onSubmit={handleSubmit}
          placeholder={'댓글을 입력해주세요.'}
          plugins={{
            bridge,
            postUploadImageV2: (imageFile) =>
              postUploadImageV2({ imageFile, fetchCommunityInstance }),
          }}
        />
      </div>
    );
  }
);

export default React.memo(CommentFormAccessoryView);
