import {
  ActionList,
  ActionListItem,
  ActionSheet,
  useBottomSheet,
} from '@community-group/components';

type Props = {
  onClone: () => void;
  onReport?: () => void;
  isSeoulRunPromotionMeetup?: boolean;
};

export const GroupMemberMoreMenuAppBarBottomSheet = ({
  onClone,
  onReport,
  isSeoulRunPromotionMeetup = false,
}: Props) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        {!isSeoulRunPromotionMeetup && (
          <ActionListItem
            onClick={async () => {
              await closeBottomSheet();

              onClone();
            }}
          >
            복제
          </ActionListItem>
        )}
        {onReport && (
          <ActionListItem
            onClick={async () => {
              await closeBottomSheet();

              onReport();
            }}
          >
            신고
          </ActionListItem>
        )}
      </ActionList>
    </ActionSheet>
  );
};
