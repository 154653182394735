import { AsyncBoundary, ViewLoader } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import { AGE_RANGE_OPTIONS } from '@/components/group/new/RecruitmentCondition/constants/age-range-constants';
import GroupAgeRangeSettingRenderRight from '@/features/GroupSetting/AppScreen/GroupAgeRangeSettingRenderRight';
import GroupAgeRangeSettingSection from '@/features/GroupSetting/GroupAgeRange';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupAgeRangeSettingPageParams = Pick<
  PageParams,
  'groupId' | 'initialGroupMinAge' | 'initialGroupMaxAge'
>;

const GroupAgeRangeSettingPage: ActivityComponentType<GroupAgeRangeSettingPageParams> = () => {
  const { groupId } = usePathParams();
  const { initialGroupMinAge, initialGroupMaxAge } = useQueryParams();

  const initAgeRangeValue = {
    minAge: initialGroupMinAge ? Number(initialGroupMinAge) : undefined,
    maxAge: initialGroupMaxAge ? Number(initialGroupMaxAge) : undefined,
  } as const;

  const initSelectedChipId = () => {
    const selectedIndex = AGE_RANGE_OPTIONS.map((option, index) => {
      if (initAgeRangeValue.minAge === undefined || initAgeRangeValue.maxAge === undefined)
        return 0;

      if (
        option.value?.minAge === initAgeRangeValue.minAge &&
        option.value?.maxAge === initAgeRangeValue.maxAge
      )
        return index;

      return;
    }).filter((element) => element !== undefined);

    if (selectedIndex.length === 0) return AGE_RANGE_OPTIONS.length - 1;

    return selectedIndex[0];
  };

  const formHandler = useForm<FieldValues>({
    defaultValues: { ageRange: initAgeRangeValue, selectedChipId: initSelectedChipId() },
  });

  return (
    <FormProvider {...formHandler}>
      <AppScreen
        appBar={{
          title: '모집 연령대 설정',
          borderSize: '0.5px',
          borderColor: vars.$semantic.color.divider3,
          renderRight: () => (
            <GroupAgeRangeSettingRenderRight
              groupId={groupId}
              initialSelectedChipId={initSelectedChipId()}
              initialAgeRangeValue={initAgeRangeValue}
            />
          ),
        }}
      >
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
          <GroupAgeRangeSettingSection />
        </AsyncBoundary>
      </AppScreen>
    </FormProvider>
  );
};

export default GroupAgeRangeSettingPage;
