import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_CHALLENGE_QUERY_KEY } from '../queries';

type Props = {
  groupId: string;
  challengeId: string;
};

export const useQueryChallengeDetail = ({ groupId, challengeId }: Props) => {
  const read = useAsyncRead(
    groupClient.api.GroupChallengeApi.apiV1GroupsGroupIdChallengesChallengeIdDetailGet
  );
  return {
    queryKey: GROUP_CHALLENGE_QUERY_KEY.detail(groupId.toString(), challengeId.toString()),
    queryFn: () => read({ groupId: Number(groupId), challengeId: Number(challengeId) }),
  };
};

export const useReadChallengeDetail = ({ groupId, challengeId }: Props) => {
  const query = useQueryChallengeDetail({ groupId, challengeId });
  return useSuspenseQuery({ ...query });
};
