import type { MediaUploadForm } from '@community-group/api/lib/group/models';
import {
  type FileUploadState,
  AsyncBoundary,
  isHigherManager,
  TextButton,
  useBottomSheet,
  validateUploadingVideos,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import type { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { GROUP_URL } from '@/api/base/post';
import { useGetBoardTypeList } from '@/api/hooks/useGetBoardTypeList';
import { getGroupFeedListPath } from '@/api/hooks/useGetGroupFeedList';
import { usePostArticle } from '@/api/hooks/usePostArticle';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import { useQueryNullableChallengeDetail } from '@/domain/Challenge/hooks/useReadNullableChallengeDetail';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupLevel } from '@/domain/GroupLevel/hooks/useReadGroupLevel';
import {
  GROUP_POST_FORM_IS_GROUP_ONLY_KEY,
  useMakeDefaultPostFormValue,
} from '@/domain/GroupPostCreate/hooks/useMakeDefaultPostFormValue';
import {
  GROUP_SAVED_POST_KEY,
  useMakeSavedFormValue,
} from '@/domain/GroupPostCreate/hooks/useMakeSavedFormValue';
import { useRenderDefaultPostForm } from '@/domain/GroupPostCreate/hooks/useRenderDefaultPostForm';
import { useSaveTemporalGroupPost } from '@/domain/GroupPostCreate/hooks/useSaveTemporalGroupPost';
import { GROUP_PROFILE_QUERY_KEY } from '@/domain/GroupProfile/queries';
import { useBlockGroupActivityByGroupStatus } from '@/features/GroupDetail/hooks/useBlockGroupActivityByGroupStatus';
import GroupPostCreateBackButton from '@/features/GroupPostCreate/AppScreen/BackButton';
import useActiveActivities from '@/hooks/useActiveActivities';
import useBackToActivity from '@/hooks/useBackToActivity';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useOpenDialogValidateFormChallengeCertify } from '@/hooks/useOpenDialogValidateFormChallengeCertify';
import { useStorage } from '@/hooks/useStorage';
import { queryClient } from '@/shared/api/instance';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import type { PageParams } from '@/stackflow/types/params';
import { useStore } from '@/store';
import { MODAL_KEY } from '@/store/modal/modalSliceStore';
import { extendAppsflyerLoggerType, trackEvent } from '@/utils/analytics';
import { isTreatment } from '@/utils/analytics/experiment/guard';
import { getUserExperimentalABGroup } from '@/utils/analytics/experiment/userExperimentalGroup';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupRunningGrowth } from '@/utils/refetch/runningGrowth';
import { postFormSchema } from '@/utils/validate/formSchema/post';
import { validateSchemaWithHandleToast } from '@/utils/validate/util';

import { getCurrentRunningGrowthStep } from '../../Detail/components/RunningGrowth/utils/curerntLevel';
import { GroupPostFormAppBar } from '../components/AppBar';
import AppBarTitle from '../components/AppBar/AppBarTitle';
import { type GroupPostFormHandlerProps, GroupPostForm } from '../components/GroupPostForm';
import { GroupPostNewStartBottomSheet } from '../components/GroupPostNewStartBottomSheet';
import { typeSafeBoolean } from '../utils';

export type GroupPostNewPageParams = Pick<
  PageParams,
  'groupId' | 'postType' | 'content' | 'meetupId' | 'initialPublishPost' | 'challengeId'
>;

export type GroupTemporalPost = Omit<GroupPostFormHandlerProps, 'originImages' | 'uploadVideos'> & {
  originImages: Array<{ id: string; image: string }> | undefined;
  uploadVideos: Array<FileUploadState>;
};

const GroupPostNewPage: ActivityComponentType = () => {
  const { groupId = '' } = usePathParams();
  const {
    postType: queryParamsPostType = 'post',
    challengeId: queryParamsChallengeId,
    from,
    calloutType,
  } = useQueryParams();
  const { bridge } = useBridge();
  // @TODO: remove this
  const { pop, replace, push } = useConnectedActions();

  const isPost = queryParamsPostType === 'post';

  const { isExistSpecificActivities } = useActiveActivities();
  const mentionSuggestionsRef = useRef<HTMLDivElement>(null);

  const {
    userConfig: { userId },
  } = useUserConfig();
  const [{ data: group }, { data: challenge }, { data: myInfo }, { data: levelData }] =
    useSuspenseQueries({
      queries: [
        useQueryGroupDetail(groupId),
        useQueryNullableChallengeDetail({ groupId, challengeId: queryParamsChallengeId }),
        useQueryGroupMe({ groupId }),
        useQueryGroupLevel(groupId),
      ],
    });
  const { data: boardCategories } = useGetBoardTypeList(Number(groupId));

  const isHigherManagerUser = isHigherManager(myInfo.role);
  const treatmentUser = getUserExperimentalABGroup(userId);

  const [, setIsPostFormIsGroupOnly] = useStorage(GROUP_POST_FORM_IS_GROUP_ONLY_KEY, false);
  const defaultPostFormValues = useMakeDefaultPostFormValue({ boardCategories });
  // 이게 defaultForm Value
  const composedFormValue = useMakeSavedFormValue({ groupId, defaultPostFormValues });
  const [temporalPost, setTemporalPost] = useStorage(GROUP_SAVED_POST_KEY, {
    [groupId]: {} as GroupTemporalPost,
  });

  const formHandler = useForm({
    defaultValues: isPost ? composedFormValue : defaultPostFormValues,
  });
  const { watch, getValues, setValue, reset } = formHandler;
  const isPublished = watch('isPublished');

  const cleanupTemporalPost = useCallback(() => {
    setTemporalPost({
      ...temporalPost,
      [groupId]: {} as GroupTemporalPost,
    });
    reset(defaultPostFormValues);
  }, [defaultPostFormValues, groupId, reset, setTemporalPost, temporalPost]);

  // 이게 임시저장 폼 렌더링 여부
  const isUseTemporaryPost = useRenderDefaultPostForm({
    shouldRender: isPost && !!temporalPost && !isEmpty(temporalPost[groupId]),
    resetForm: cleanupTemporalPost,
  });

  const { open: openBottomSheet } = useBottomSheet();
  const handleBack = useBackToActivity();

  const handleGroupPostBottomSheet = () => {
    openBottomSheet({
      element: (
        <GroupPostNewStartBottomSheet
          groupId={groupId}
          formHandler={formHandler}
          hasMention={false}
          replacePage={replace}
          popPage={pop}
          pushPage={push}
        />
      ),
      config: {
        dimOpacity: 0,
      },
    });
  };

  const { setOpenWebviewModal } = useStore();

  useEffect(() => {
    if (queryParamsPostType === 'meetupReview' || queryParamsPostType === 'challengeCertify') {
      return;
    }

    if (from === 'HeroImage') return;

    // 임시저장 폼을 쓰면 skip
    if (isUseTemporaryPost) return;

    if (
      group?.isBoardManaged &&
      boardCategories?.boardCategories &&
      boardCategories?.boardCategories.length > 1
    ) {
      setOpenWebviewModal(MODAL_KEY.SELECT_BOARD_TYPE_BOTTOM_SHEET, true);
      return;
    }

    handleGroupPostBottomSheet();
  }, [isUseTemporaryPost]);

  useEnterTrackEvent({
    event: 'enter_group_write_post',
    params: {
      groupId,
      queryParamsPostType,
      calloutType,
      currentRunningGrowthMission: getCurrentRunningGrowthStep(calloutType),
      currentLevel: levelData?.currentLevel,
      currentProgressPercentage: levelData?.forLevelUp.percentage,
      userId: (myInfo.id ?? 0).toString(),
      categoryId: group?.category.id,
      categoryName: group?.category.name,
      from,
    },
    sample: true,
    loggerType: ['APPSFLYER', 'AMPLITUDE', 'KARROT'],
  });

  const handleErrorWithToast = useHandleErrorWithToast();
  const {
    mutate: postArticle,
    isPending,
    status,
  } = usePostArticle({
    onError: handleErrorWithToast,
    onSuccess: (data) => {
      refetchGroupDetail({ groupId });
      queryClient.refetchQueries({
        queryKey: [`${GROUP_URL}/${groupId}/notices`],
      });

      // 채팅방에서 일정 생성시 창 닫기
      if (from === 'chat') {
        bridge.closeRouter({});
        return;
      }

      if (group?.isBoardManaged) {
        queryClient.refetchQueries({
          queryKey: [getGroupFeedListPath(groupId)],
        });
      }

      queryClient.refetchQueries({
        queryKey: [`${GROUP_URL}/${groupId}/notices`],
      });

      queryClient.refetchQueries({
        queryKey: GROUP_PROFILE_QUERY_KEY.activities(groupId, myInfo.id.toString()),
      });

      refetchGroupRunningGrowth({ groupId: Number(groupId) });

      // 챌린지 인증 글인 경우 챌린지 상세 페이지로 이동되지 않도록 하기 위해 push를 사용
      // * 글 작성 완료 후 스택
      // - 일반글 : 모임 홈 > 작성된 게시글 상세
      // - 챌린지 인증글 : 모임 홈 > 챌린지 인증 결과 페이지
      if (queryParamsPostType === 'challengeCertify' && data.post.challengeInfo?.id) {
        const move = isExistSpecificActivities('GroupDetailPage') ? push : replace;

        handleBack({
          activityName: 'GroupDetailPage',
          isCloseRouterActivityNotExist: false,
        });

        move('ChallengeMyDailyCertifyResultPage', {
          groupId,
          postId: data.post.id,
          challengeId: data.post.challengeInfo.id,
        });
        return;
      }

      replace('GroupPostDetailPage', { groupId, postId: data.post.id }, { animate: true });
    },
  });

  const handlePostSubmit = () => {
    const watchData = watch();

    const data = {
      ...watchData,
      isNoticed: typeSafeBoolean(watchData?.isNoticed),
      isGroupOnly: typeSafeBoolean(watchData?.isGroupOnly),
      isPublished: typeSafeBoolean(watchData?.isPublished),
    };

    // todo: ts-ignore 제거 (참고: https://github.com/daangn/community-web-group/pull/698#issue-1641248802)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const images = (data.images ?? []).map(({ id }) => id);

    const mediasVideo = (data.uploadVideos ?? [])
      .filter(({ uploadedVideoInfo }) => uploadedVideoInfo?.id)
      .map(
        ({ uploadedVideoInfo }) => ({ id: uploadedVideoInfo?.id, type: 'video' } as MediaUploadForm)
      );

    const mediasImage = images
      .filter((id) => !id.includes('temp') && !id.includes('error'))
      .map<MediaUploadForm>((id) => ({ id, type: 'image' }));

    const invalidUploadVideo = validateUploadingVideos(data.uploadVideos ?? []);
    if (invalidUploadVideo) {
      bridge.openToast({ toast: { body: invalidUploadVideo.message } });
      return false;
    }

    if (
      !validateSchemaWithHandleToast(postFormSchema, {
        content: data.content,
        images,
        medias: [...mediasVideo, ...mediasImage],
        postType: data.postType,
      })
    ) {
      return false;
    }

    postArticle(
      {
        groupId,
        ...data,
        poiItems: [...(data.poiStreams?.map(({ poiId, type }) => ({ id: poiId, type })) ?? [])],
        images,
        medias: [...mediasVideo, ...mediasImage],
      },
      {
        onSuccess: () => {
          const isSelectedDefaultBoardCategory =
            boardCategories.boardCategories?.find((item) => item.id === data.boardCategoryIds?.[0])
              ?.type === 'default';

          setIsPostFormIsGroupOnly(data.isGroupOnly);

          refetchGroupRunningGrowth({ groupId: Number(groupId) });

          // 챌린지 인증글인 경우 데이터 Refetch
          if (data.postType?.type.includes('challenge')) {
            refetchGroupChallenge({
              groupId,
              challengeId: data.postType?.challengeId?.toString() ?? '',
            });
          }

          trackEvent({
            event: 'click_write_post',
            params: {
              groupId,
              isSelectedDefaultBoardCategory,
              isNoticed: data.isNoticed,
              isGroupOnly: data.isGroupOnly,
              isPublished: data.isPublished,
              isMentionedUser: (data.mentionedUserIds ?? []).length > 0,
              hasImages: (data.images ?? []).length > 0,
              hasPois: (data.poiStreams ?? []).length > 0,
              hasVideos: (data.uploadVideos ?? []).length > 0,
              videoCount: (data.uploadVideos ?? []).length,
              postType: data.postType?.type,
              hasPoll: !!data.poll,
              pollName: data.poll?.title,
              pollOptionCount: data.poll?.options.length,
              pollMultipleOption: data.poll?.isMultiple,
              role: myInfo.role,
              referrer: from,
              calloutType,
              currentRunningGrowthMission: getCurrentRunningGrowthStep(calloutType),
              from,
              currentLevel: levelData?.currentLevel,
              currentProgressPercentage: levelData?.forLevelUp.percentage,
              userId: (myInfo.id ?? 0).toString(),
              categoryId: group?.category.id,
              categoryName: group?.category.name,
              challengeId: queryParamsChallengeId,
              challengeName: challenge?.name,
            },
            loggerType: extendAppsflyerLoggerType,
          });

          cleanupTemporalPost();
        },
      }
    );
  };

  const openDialogValidateFormChallengeCertify = useOpenDialogValidateFormChallengeCertify();

  const handleSubmitClick = () => {
    if (isPending || status === 'success') return;

    const hasImages = (getValues('images') ?? []).length > 0;

    // 챌린지 인증 글 validation check
    // post type이 challengeCertify 인데, 사진이 없는 경우 다이얼로그 띄어주기
    if (queryParamsPostType === 'challengeCertify' && !hasImages) {
      return openDialogValidateFormChallengeCertify({
        onPostAnyway: () => {
          // 이대로 올리기 한 경우 postType을 초기값으로 변경
          setValue('postType.type', 'post');
          handlePostSubmit();
        },
      });
    }

    handlePostSubmit();
  };

  const isWaitingForImageUpload = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (watch('originImages').some(({ id }) => id.includes('temp') || id.includes('error'))) {
      return true;
    }
    return false;
  };

  const isWaitingForVideoUpload = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (watch('uploadVideos').some(({ status }) => status === 'failed' || status === 'uploading')) {
      return true;
    }
    return false;
  };

  const isValidSubmit = () => {
    const watchData = watch();

    if (watchData.content.length <= 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (watch('originImages').length > 0) {
        return false;
      }
      if (watch('uploadVideos').length > 0) {
        return false;
      }
      return true;
    }

    if (isWaitingForImageUpload() || isWaitingForVideoUpload()) return true;

    return false;
  };

  const textareaParentRef = useRef<HTMLDivElement>(null);

  const handleFocusTextarea = (cursorEnd = true) => {
    setTimeout(() => {
      const textarea: HTMLInputElement | null | undefined =
        textareaParentRef?.current?.querySelector('[data-name="MentionsInput"]');

      const end = textarea?.textContent?.length;

      if (cursorEnd) {
        textarea?.setSelectionRange(end as number | null, end as number | null);
      }

      textarea?.focus();
    });
  };
  const handleBlurTextarea = () => {
    const textarea: HTMLInputElement | null | undefined = textareaParentRef?.current?.querySelector(
      '[data-name="MentionsInput"]'
    );
    textarea?.blur();
  };

  const placeholder = useMemo(() => {
    if (queryParamsPostType === 'challengeCertify') {
      return '사진과 함께 오늘의 인증 내용을 입력해주세요.';
    }

    // Post 타입이면서 higherManager 이면서 트리먼트 그룹이면 모임 규칙 소개 플레이스홀더 보여주기
    return isPost && isHigherManagerUser && isPublished && isTreatment(treatmentUser)
      ? '모임 규칙이나 활동 방식 등을 소개해보세요.'
      : '질문이나 이야기를 남겨보세요.';
  }, [queryParamsPostType, isPost, isHigherManagerUser, isPublished, treatmentUser]);

  useSaveTemporalGroupPost({ formHandler, defaultPostFormValues, groupId });

  useBlockGroupActivityByGroupStatus(group.status);

  return (
    <AppScreen
      appBar={{
        title: <AppBarTitle group={group} />,
        backButton: {
          render: () => <GroupPostCreateBackButton />,
        },
        closeButton: {
          render: () => <GroupPostCreateBackButton />,
        },
        renderRight: () => (
          <>
            <TextButton
              onClick={handleSubmitClick}
              variant="secondary"
              isDisabled={isValidSubmit() || isPending || status === 'success'}
              UNSAFE_style={{ padding: '0.5rem' }}
            >
              완료
            </TextButton>
          </>
        ),
      }}
      accessoryBar={
        <GroupPostFormAppBar
          mode="new"
          handleBlurTextarea={handleBlurTextarea}
          handleFocusTextarea={handleFocusTextarea}
          formHandler={formHandler}
          handleAddButton={handleGroupPostBottomSheet}
          mentionSuggestionsRef={mentionSuggestionsRef}
        />
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <GroupPostForm
          mode="new"
          formHandler={formHandler}
          handleAddButton={handleGroupPostBottomSheet}
          textareaParentRef={textareaParentRef}
          mentionSuggestionsRef={mentionSuggestionsRef}
          placeholder={placeholder}
        />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupPostNewPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
