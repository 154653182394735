import {
  ActionList,
  ActionListItem,
  ActionSheet,
  AsyncBoundary,
  Dialog,
  Typography,
  useBottomSheet,
  useDialog,
  useStickInputStore,
} from '@community-group/components';
import { IconChevronLeftLine } from '@daangn/react-monochrome-icon';
import { IconDot3VerticalLine } from '@daangn/react-monochrome-icon';
import { HelpBubbleAnchor } from '@daangn/sprout-components-help-bubble';
import { vars } from '@seed-design/design-token';
import { useActivity } from '@stackflow/react';
import { useMemo } from 'react';

import { useDeleteNotice } from '@/api/hooks/useDeleteNotice';
import { useDeletePost } from '@/api/hooks/useDeletePost';
import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import { useGetNotices } from '@/api/hooks/useGetNotices';
import { useGetPostDetail } from '@/api/hooks/useGetPostDetail';
import { usePatchNotice } from '@/api/hooks/usePatchNotice';
import { usePutEditPost } from '@/api/hooks/usePutEditPost';
import CheckAccessiblePermission from '@/components/common/CheckAccessiblePermission';
import IconWrapper from '@/components/common/Icons';
import { IconShareRegularExperiment } from '@/components/common/Icons/shareRegular';
import { useBridge } from '@/contexts/Bridge';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useReadGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import { useHandleIsNotViewGroup } from '@/hooks/NotViewGroup/useHandleIsNotViewGroup';
import useCheckHasPermission from '@/hooks/useCheckHasPermission';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useSharePostContents } from '@/hooks/useSharePostContents';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { AppScreen, AppScreenProps } from '@/stackflow/components/AppScreen';
import TopViewObserver, { useTopBorder } from '@/stackflow/components/AppScreen/TopViewObserver';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useReferQueryParams } from '@/stackflow/hooks/useReferQueryParams';
import { trackEvent } from '@/utils/analytics';
import { detectAlcoholKeywords, detectRomanceKeywords } from '@/utils/check';
import { openGroupPostReport } from '@/utils/link';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchPostDetail } from '@/utils/refetch/groupPostDetail';
import { isSuperHost } from '@/utils/role';

type Props = Pick<AppScreenProps, 'onPull' | 'accessoryBar'> & {
  children: React.ReactNode;
};

export const GroupPostDetailAppScreen = ({ onPull, children, accessoryBar }: Props) => {
  const pop = useBack();
  const { focused } = useStickInputStore();

  const handleBackButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    pop();
  };
  const { border, intersectionObserverRef } = useTopBorder({});

  return (
    <AppScreen
      disabledClientOnly={true}
      preventSwipeBack={focused}
      accessoryBar={accessoryBar}
      onPull={onPull}
      appBar={{
        title: (
          <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
            <GroupNameTitleAppbar />
          </AsyncBoundary>
        ),
        closeButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: (event) => {
            handleBackButtonClick(event);
          },
        },
        backButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: (event) => {
            handleBackButtonClick(event);
          },
        },
        renderRight: () => (
          <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
            <MoreButton />
          </AsyncBoundary>
        ),
        ...border,
      }}
    >
      <TopViewObserver outsideRef={intersectionObserverRef} />
      {children}
    </AppScreen>
  );
};

const GroupNameTitleAppbar = () => {
  const { groupId } = usePathParams();
  const { data: group } = useReadGroupDetail(groupId);
  const { push } = useFlow();

  const moveToGroupDetail = () => {
    push('GroupDetailPage', {
      groupId,
      activeTab: 'feed',
    });
  };
  return (
    <Typography
      typography="title3Bold"
      onClick={moveToGroupDetail}
      style={{
        verticalAlign: 'middle',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {group?.name}
    </Typography>
  );
};

const MoreButton = () => {
  const { push } = useFlow();
  const { isTop } = useActivity();
  const pop = useBack();
  const { groupId = '', postId = '' } = usePathParams();
  const { data: me } = useReadGroupMe({ groupId });
  const { open: openDialog, close: closeDialog } = useDialog();
  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();

  const { refetch: refetchNotice } = useGetNotices(groupId);

  const { from } = useQueryParams();
  const referParams = useReferQueryParams();
  const { post } = useGetPostDetail(groupId, postId);
  const { data: group } = useReadGroupDetail(groupId);
  const { data: permissionData } = useReadGroupPermissions(groupId);
  const { data: levelData } = useGetGroupLevel({ groupId: Number(groupId) });
  const hasModifyPostGroupOnlyPermission = useCheckHasPermission('modifyPostGroupOnly');

  const meetupId = post?.postType?.type === 'meetupReview' ? post?.postType?.meetupId : '';

  useEnterTrackEvent({
    event: 'enter_post_detail',
    params: {
      groupId,
      meetupId,
      role: me.role,
      isChatRequired: !group?.chatRoomSetting.isShowChatRoomSetting,
      isChatActivated: !group?.chatRoomSetting.isDeactivate,
      from,
      isPublishedToFeed: post?.publishType === 'public',
      hasAlcoholKeywordPostContent: detectAlcoholKeywords(post?.content).length > 0,
      hasAlcoholKeywordGroupDescription: detectAlcoholKeywords(group?.description).length > 0,
      hasRomanceKeywordPostContent: detectRomanceKeywords(post?.content).length > 0,
      hasRomanceKeywordGroupDescription: detectRomanceKeywords(group?.description).length > 0,

      groupName: group?.name,
      groupCategoryName: group?.category.name,
      postId,
      description: group?.description ?? '',
      postContent: post?.content ?? '',
      currentLevel: levelData?.currentLevel,
      currentProgressPercentage: levelData?.forLevelUp.percentage,
      userId: me.id.toString(),
      categoryId: group?.category.id,
      categoryName: group?.category.name,
      ...referParams,
    },
    sample: true,
  });

  const handleErrorWithToast = useHandleErrorWithToast();

  const { mutate: mutateDeletePost } = useDeletePost({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupDetail({ groupId });

      pop();
    },
  });

  const { bridge } = useBridge();
  const { mutate: mutatePatchNotice } = usePatchNotice({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchNotice();
      refetchGroupDetail({ groupId });
      refetchPostDetail({ groupId, postId });

      bridge.openToast({
        toast: {
          body: '공지로 등록되었어요.',
        },
      });
    },
  });

  const { mutate: mutateDeleteNotice } = useDeleteNotice({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchNotice();
      refetchGroupDetail({ groupId });
      refetchPostDetail({ groupId, postId });

      bridge.openToast({
        toast: {
          body: '공지에서 제외되었어요.',
        },
      });
    },
  });

  const { mutate: editPost } = usePutEditPost({
    onError: handleErrorWithToast,
    onSuccess: (data) => {
      bridge.openToast({
        toast: {
          body:
            data.data.post.publishType === 'public'
              ? '전체 공개로 변경되었어요.'
              : '모임에만 공개로 변경되었어요.',
        },
      });
      refetchPostDetail({ groupId, postId: data.data.post.id.toString() });
      refetchGroupDetail({ groupId });
    },
  });

  const [showedShareContentsTooltip, setShowedShareContentsTooltip] = useStorage(
    'showedShareContentsTooltip',
    false
  );
  const handleSharePostContents = useSharePostContents();

  const { shownHandleIsNotViewGroupButton, isNotViewGroup, toggleIsNotViewGroup } =
    useHandleIsNotViewGroup({
      groupId,
      groupName: post?.groupInfo?.name ?? '',
    });
  const handleGroupFeedback = async () => {
    await toggleIsNotViewGroup();
  };

  const handleDeletePost = () => {
    openDialog({
      element: (
        <Dialog
          title="게시글을 삭제할까요?"
          description="모든 데이터가 삭제되고 다시 볼 수 없어요."
          primaryActionLabel="삭제"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            await closeDialog();
            await mutateDeletePost(
              { groupId, postId },
              {
                onSuccess: () => {
                  trackEvent({
                    event: 'click_delete',
                    params: {
                      type: 'post',
                    },
                  });
                },
              }
            );
          }}
          onSecondaryAction={async () => {
            trackEvent({ event: 'click_cancel' });
            await closeDialog();
          }}
        />
      ),
    });
  };

  const showModifyGroupOnlyButton = useMemo(() => {
    if (!me || !post) return false;
    // 내 글이면 수정 가능
    if (Number(me.id) === post.author.id) return true;
    // role 정보 or 수정 권한 정보가 없으면 수정 불가
    if (!me.role || !permissionData?.modifyPostGroupOnly) return false;

    // 수정 권한이 있는 role이면 수정 가능
    if (hasModifyPostGroupOnlyPermission) return true;

    return false;
  }, [me, post, permissionData?.modifyPostGroupOnly, hasModifyPostGroupOnlyPermission]);

  const handlePostDetailBottomSheet = async () => {
    if (me.id !== post?.author.id) {
      await openBottomSheet({
        element: (
          <ActionSheet
            bottomButton={{
              label: '닫기',
            }}
          >
            <ActionList>
              <CheckAccessiblePermission
                permission="addNoticePost"
                currentPermission={me.permissions}
              >
                <ActionListItem
                  onClick={async () => {
                    await closeBottomSheet();
                    post?.isNoticed
                      ? mutateDeleteNotice({ id: Number(groupId), postId: Number(postId) })
                      : mutatePatchNotice({ id: Number(groupId), postId: Number(postId) });
                  }}
                >
                  {post?.isNoticed ? '공지에서 제외' : '공지로 등록'}
                </ActionListItem>
              </CheckAccessiblePermission>
              {showModifyGroupOnlyButton && (
                <ActionListItem
                  onClick={async () => {
                    editPost({
                      id: parseInt(groupId),
                      postId: post?.id,
                      postModifyForm: {
                        isGroupOnly: post?.publishType === 'public',
                      },
                    });
                  }}
                >
                  {post?.publishType === 'public' ? '모임에만 공개로 변경' : '전체 공개로 변경'}
                </ActionListItem>
              )}
              {isSuperHost(me.role) && group?.isBoardManaged && (
                <ActionListItem
                  onClick={async () => {
                    await closeBottomSheet();

                    push('BottomSheet/GroupMoveBoardCategoryBottomSheet', {
                      groupId,
                      postId,
                    });
                  }}
                >
                  다른 게시판으로 이동
                </ActionListItem>
              )}
              {me.id === post?.author.id && (
                <ActionListItem
                  onClick={async () => {
                    await closeBottomSheet();
                    push('GroupPostEditPage', {
                      groupId,
                      postId,
                      challengeId: post?.challengeInfo?.id?.toString() ?? '',
                      postType: post?.postType?.type,
                    });
                  }}
                >
                  수정
                </ActionListItem>
              )}
              <CheckAccessiblePermission
                permission="deletePostAndComment"
                currentPermission={me.permissions}
              >
                <ActionListItem
                  onClick={async () => {
                    await closeBottomSheet();
                    handleDeletePost();
                  }}
                  color={vars.$semantic.color.danger}
                >
                  삭제
                </ActionListItem>
              </CheckAccessiblePermission>
              {shownHandleIsNotViewGroupButton && (
                <ActionListItem
                  onClick={async () => {
                    await closeBottomSheet();
                    await handleGroupFeedback();
                  }}
                >
                  {isNotViewGroup ? '이 모임의 글 다시 보기' : '이 모임의 글 보지 않기'}
                </ActionListItem>
              )}
              <ActionListItem
                onClick={() => {
                  trackEvent({
                    event: 'click_report',
                    params: {
                      type: 'post',
                    },
                  });
                  closeBottomSheet();
                  openGroupPostReport({
                    groupId: String(groupId),
                    postId: String(post?.id),
                  });
                }}
              >
                신고
              </ActionListItem>
            </ActionList>
          </ActionSheet>
        ),
        onDimClose: closeBottomSheet,
      });
      return;
    }

    if (me.id === post?.author.id) {
      await openBottomSheet({
        element: (
          <ActionSheet
            bottomButton={{
              label: '닫기',
            }}
          >
            <ActionList>
              <CheckAccessiblePermission
                permission="addNoticePost"
                currentPermission={me.permissions}
              >
                <ActionListItem
                  onClick={async () => {
                    await closeBottomSheet();
                    post?.isNoticed
                      ? mutateDeleteNotice({ id: Number(groupId), postId: Number(postId) })
                      : mutatePatchNotice({ id: Number(groupId), postId: Number(postId) });
                  }}
                >
                  {post?.isNoticed ? '공지에서 제외' : '공지로 등록'}
                </ActionListItem>
              </CheckAccessiblePermission>
              {group?.isBoardManaged && (
                <ActionListItem
                  onClick={async () => {
                    await closeBottomSheet();

                    push('BottomSheet/GroupMoveBoardCategoryBottomSheet', {
                      groupId,
                      postId,
                    });
                  }}
                >
                  다른 게시판으로 이동
                </ActionListItem>
              )}
              <ActionListItem
                onClick={async () => {
                  await closeBottomSheet();

                  push('GroupPostEditPage', {
                    groupId,
                    postId,
                    challengeId: post?.challengeInfo?.id?.toString() ?? '',
                    postType: post?.postType?.type,
                  });
                }}
              >
                수정
              </ActionListItem>

              <ActionListItem
                onClick={async () => {
                  await closeBottomSheet();
                  handleDeletePost();
                }}
                color={vars.$semantic.color.danger}
              >
                삭제
              </ActionListItem>
            </ActionList>
          </ActionSheet>
        ),
        onDimClose: closeBottomSheet,
      });
      return;
    }
  };

  if (!post) return <></>;

  return (
    <>
      <HelpBubbleAnchor
        title={`참여중인 채팅방에\n게시글을 공유해보세요!`}
        onInteractOutside={() => {
          setShowedShareContentsTooltip(true);
        }}
        isOpen={!showedShareContentsTooltip && isTop}
        UNSAFE_style={{
          whiteSpace: 'pre-wrap',
        }}
        positioning={{
          placement: 'bottom-start',
        }}
        marginLeft={-14}
        onOpenChange={() => {
          setShowedShareContentsTooltip(true);
        }}
      >
        <IconWrapper>
          <IconShareRegularExperiment
            currentUserRole={me.role}
            width={24}
            height={24}
            color={vars.$scale.color.gray900}
            onClick={() => {
              setShowedShareContentsTooltip(true);

              if (!post.postType) return;
              const currentUserRole = me.role ?? 'none';

              handleSharePostContents({
                groupId,
                postId,
                postType: post.postType.type,
                permalinkId: post.permalinkId,
                currentUserRole: currentUserRole,
                from: 'postDetailAppBar',
              });
            }}
          />
        </IconWrapper>
      </HelpBubbleAnchor>
      <IconWrapper>
        <IconDot3VerticalLine size={24} onClick={handlePostDetailBottomSheet} />
      </IconWrapper>
    </>
  );
};
