import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_MEMBER_QUERY_KEY } from '../queries';

export const useQueryGroupSuperHost = (groupId: string) => {
  const read = useAsyncRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersSuperHostGet);
  return {
    queryKey: GROUP_MEMBER_QUERY_KEY.membersSuperHost(groupId),
    queryFn: () => read({ id: Number(groupId) }),
  };
};

export const useReadGroupSuperHost = (groupId: string) => {
  const query = useQueryGroupSuperHost(groupId);
  return useSuspenseQuery(query);
};
