import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { AsyncBoundary, ViewError, ViewLoader } from '@community-group/components';
import { ITab, Tabs } from '@karrotframe/tabs';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import { useGetApplications } from '@/api/hooks/useGetApplications';
import {
  MemberListOrderType,
  MemberListParams,
  useGetMemberList,
} from '@/api/hooks/useGetMemberList';
import { useGetRestrictedMemberCount } from '@/api/hooks/useGetRestrictedMemberCount';
import { Container } from '@/components/common/Container';
import EmptySection from '@/components/common/Empty';
import { LoadMoreApplications } from '@/components/common/LoadMoreContainer';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupMemberApplicationCount } from '@/domain/GroupMember/hooks/useReadGroupMemberApplicationCount';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { useQueryGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithThrowAccessStatusPage } from '@/hooks/useHandleErrorWithThrowAccessStatusPage';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { isAccessibleGrade } from '@/utils/permission';
import { refetchGroupMemberList } from '@/utils/refetch/memberList';

import { MemberListAppScreen } from '../components/MemberListAppScreen';
import MemberProfileDetailGradeApplicationList from '../components/MemberProfileDetailGradeApplicationList';
import { MemberProfileDetailList } from '../components/MemberProfileDetailList';
import MemberProfileDetailRestrictedList from '../components/MemberProfileDetailRestrictedList';
import MemberProfileSectionForHost from '../components/MemberProfileSectionForHost';
import { getMemberListForHostTabKeys, TabKey } from '../utils/getMemberListForHostTabKeys';
import * as s from './MemberListForHost.css';

export type GroupMemberListForHostPageParams = Pick<PageParams, 'groupId' | 'tab'>;

const GroupMemberListForHostPage: ActivityComponentType<GroupMemberListForHostPageParams> = () => {
  return (
    <MemberListAppScreen>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <MemberListWrapper />
      </AsyncBoundary>
    </MemberListAppScreen>
  );
};

export type MemberListFilter = Pick<
  MemberListParams,
  'shownMemberActivities' | 'shownMemberApplication' | 'shownOnlyHost' | 'gradeIds'
>;

const MemberListWrapper = () => {
  const { groupId } = usePathParams();

  useEnterTrackEvent({
    event: 'enter_member_list',
    params: { groupId },
    sample: true,
  });

  const { tab = 'joint' } = useQueryParams();
  const [activeTabKey, setActiveTabKey] = useState<'joint' | 'pending' | 'memberGradeApplication'>(
    tab
  );

  const { data: group } = useReadGroupDetail(groupId);

  const [selectedMemberOrderType, setSelectedMemberOrderType] =
    useState<MemberListOrderType>('joinedAtDesc');

  const handleErrorWithThrowErrorStatusPage = useHandleErrorWithThrowAccessStatusPage();
  const { data } = useGetMemberList({
    groupId,
    order: selectedMemberOrderType,
    options: {
      onError: (error) =>
        handleErrorWithThrowErrorStatusPage({
          error,
          serviceType: 'group',
          groupId,
        }),
    },
  });
  const { data: applications, hasNextPage: hasApplicationsNextPage } = useGetApplications(groupId);
  const { data: restrictedMembersCount } = useGetRestrictedMemberCount(groupId);
  const [
    { data: me },
    { data: memberGradeStatus },
    { data: permissions },
    { data: memberGradeApplicationCount },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupMe({ groupId }),
      useQueryGroupMemberGradeStatus(groupId),
      useQueryGroupPermissions(groupId),
      useQueryGroupMemberApplicationCount(groupId),
    ],
  });

  const [memberListFilter, setMemberListFilter] = useState<MemberListFilter>({
    shownMemberActivities: false,
    shownMemberApplication: false,
    shownOnlyHost: false,
    gradeIds: undefined,
  });

  const handleRefetchMemberList = () => {
    refetchGroupMemberList({
      groupId,
      order: selectedMemberOrderType,
      ...memberListFilter,
    });
  };

  const tabs = useMemo(() => {
    const allTabs: Record<TabKey, ITab> = {
      joint: {
        key: 'joint',
        buttonLabel: `멤버 ${data?.pages[0].data.memberCount}`,
        render: () => (
          <MemberProfileDetailList
            memberListFilter={memberListFilter}
            setMemberListFiler={setMemberListFilter}
            selectedMemberOrderType={selectedMemberOrderType}
            setSelectedMemberOrderType={setSelectedMemberOrderType}
          />
        ),
      },
      restricted: {
        key: 'restricted',
        buttonLabel: `강퇴 ${restrictedMembersCount.totalCount ?? ''}`,
        render: () => <MemberProfileDetailRestrictedList />,
      },
      pending: {
        key: 'pending',
        buttonLabel: `가입 요청 ${applications?.pages[0].data.applicationCount}`,
        render: () => (
          <div className={s.wrapper}>
            <Container paddingX={0} paddingY={0}>
              <ul className={s.list}>
                {applications &&
                  applications.pages[0].data.applications &&
                  applications.pages[0].data.applications.length < 1 && (
                    <EmptySection style={{ height: '80vh' }}>
                      가입 요청한 이웃이 없어요.
                    </EmptySection>
                  )}
                {applications &&
                  applications.pages.map((page) =>
                    page.data.applications?.map((application) => (
                      <MemberProfileSectionForHost
                        key={application.id}
                        user={{
                          id: application.id,
                          nickname: application.nickname,
                          subNickname: application.subNickname,
                          profileImage: application.profileImage,
                          userRegion: application.userRegion,
                          role: application.role as GroupMemberRoleEnum,
                          isAccountDeleted: application.isAccountDeleted,
                          verifiedUserInfoText: application.verifiedUserInfoText,
                        }}
                        currentUserRole={me.role}
                        appliedAt={application.appliedAt}
                        applicationAnswers={application.applicationAnswers}
                        onClickActionButton={handleRefetchMemberList}
                        canViewVerifiedUserInfo={group?.canViewVerifiedUserInfo}
                      />
                    ))
                  )}
              </ul>
              {hasApplicationsNextPage && <LoadMoreApplications />}
            </Container>
          </div>
        ),
      },
      memberGradeApplication: {
        key: 'memberGradeApplication',
        buttonLabel: `등업 요청 ${memberGradeApplicationCount}`,
        render: () => <MemberProfileDetailGradeApplicationList groupId={groupId} />,
      },
    };

    const hasApprovedMemberPermission = isAccessibleGrade(
      me.grade,
      permissions.applyApplicationMember.currentPriority
    );
    const hasRestrictMemberPermission = isAccessibleGrade(
      me.grade,
      permissions.restrictMember.currentPriority
    );
    const hasMemberGradeApplicationPermission =
      memberGradeStatus === 'system' &&
      isAccessibleGrade(me.grade, permissions.modifyGroupMemberGrade.currentPriority);

    const tabKeys = getMemberListForHostTabKeys({
      hasApprovedMemberPermission,
      hasRestrictMemberPermission,
      hasMemberGradeApplicationPermission,
    });

    return tabKeys.map((key) => allTabs[key]);
  }, [
    data?.pages,
    restrictedMembersCount.totalCount,
    applications,
    me,
    permissions,
    memberGradeStatus,
    memberListFilter,
    selectedMemberOrderType,
    memberGradeApplicationCount,
    groupId,
  ]);

  if (tabs.length === 1) {
    return (
      <MemberProfileDetailList
        memberListFilter={memberListFilter}
        setMemberListFiler={setMemberListFilter}
        selectedMemberOrderType={selectedMemberOrderType}
        setSelectedMemberOrderType={setSelectedMemberOrderType}
      />
    );
  }

  return (
    <Tabs
      className="custom_tab"
      activeTabKey={activeTabKey}
      disableSwipe
      tabs={tabs}
      onTabChange={(key) => {
        setActiveTabKey(key as 'joint' | 'pending');
      }}
    />
  );
};

export default GroupMemberListForHostPage;
