import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_IMAGE_QUERY_KEY } from '../queries';

type Props = {
  groupId: string;
  onlyPublic?: boolean;
};

export const useQueryGroupPhotos = ({ groupId, onlyPublic }: Props) => {
  const read = useAsyncRead(groupClient.api.ImageApi.apiV1GroupsGroupIdPhotosGet);
  return {
    queryKey: GROUP_IMAGE_QUERY_KEY.base(groupId),
    queryFn: () => read({ groupId: Number(groupId), onlyPublic }).then((res) => res.photos),
  };
};

export const useReadGroupPhotos = ({ groupId, onlyPublic }: Props) => {
  const query = useQueryGroupPhotos({ groupId, onlyPublic });
  return useSuspenseQuery(query);
};
