export const GROUP_SEOULRUN_PROMOTION_QUERY_KEY = {
  base: () => ['groupSeoulRunPromotion'] as const,
  isSeoulRunMeetupPromotion: (groupId: string, meetupId: string) =>
    [
      ...GROUP_SEOULRUN_PROMOTION_QUERY_KEY.base(),
      'isSeoulRunMeetupPromotion',
      groupId,
      meetupId,
    ] as const,
  checkJoinableRegionRegion: (regionId: string) =>
    [...GROUP_SEOULRUN_PROMOTION_QUERY_KEY.base(), 'checkJoinableRegionRegion', regionId] as const,
};
