import { Spinner } from '@community-group/components';

import * as s from './style.css';

const ScreenLoader = () => {
  return (
    <div className={s.ScreenLoaderWrapper}>
      <div className={s.ScreenLoaderInner}>
        <Spinner />
      </div>
    </div>
  );
};

export default ScreenLoader;
