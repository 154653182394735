import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<model.GroupDetailResponse>,
  Error,
  { id?: string; groupModifyForm?: model.GroupModifyForm }
>;

/**
 * @deprecated @domain/Group/hooks/usePutGroup를 사용해주세요.
 */
export const usePutGroup = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const putGroup = groupClient.api.GroupApi.apiV1GroupsIdPut({ axios: fetchInstance });

  const putGroupWrapperFp = ({
    id,
    groupModifyForm,
  }: {
    id: string;
    groupModifyForm?: model.GroupModifyForm;
  }) => {
    return putGroup({
      id: Number(id),
      groupModifyForm,
    });
  };

  return useThrottleMutation(putGroupWrapperFp, {
    onError,
    onSuccess,
  });
};
