import { ApiV1AllTypeMeetupsGetRequestParams } from '@community-group/api/lib/group/api/all-meetups-api';
import { AsyncBoundary, withAsyncBoundary } from '@community-group/components';
import { useState } from 'react';
import { $Values } from 'utility-types';

import { ORDER_MEETUP } from '@/api/base/group';
import { useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import MeetupCalendar from '@/features/GroupMeetup/MeetupCalendar';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';

import { MyLikedMeetups } from '../../common/MyLikedMeetups';
import ExplorerMeetupList from './ExplorerMeetupList';
import GroupMeetupHomeListSkeleton from './ExplorerMeetupList/GroupMeetupHomeListSkeleton';
import FilterSkeleton from './MeetupListFilter/FilterSkeleton';
import MeetupListFilter from './MeetupListFilter/MeetupListFilter';

export type MeetupFilterType = {
  order: $Values<typeof ORDER_MEETUP>;
  categoryId?: number;
  statuses: ApiV1AllTypeMeetupsGetRequestParams['statuses'];
  targetDate?: string;
};

const ExplorerMeetupListSection = () => {
  useEnterTrackEvent({
    event: 'enter_meetup_home',
  });

  const [filter, setFilter] = useState<MeetupFilterType>({
    order: ORDER_MEETUP.MEET_AT_ASC,
    categoryId: undefined,
    statuses: ['opened', 'fully_recruited'],
  });

  const handleSetFilter = (filter: React.SetStateAction<MeetupFilterType>) => {
    setFilter(filter);
  };

  const { data: myGroups } = useGetMyGroupList();
  const hasJoinedGroup = (myGroups?.groups ?? []).length > 0;

  return (
    <>
      {!hasJoinedGroup && <MyLikedMeetups />}
      <MeetupCalendar
        filter={{
          order: ORDER_MEETUP.MEET_AT_ASC,
          categoryId: undefined,
          statuses: ['opened'],
        }}
        mode="homeSection"
      />
      <AsyncBoundary pendingFallback={<FilterSkeleton />} rejectedFallback={<></>}>
        <MeetupListFilter setFilter={handleSetFilter} filter={filter} />
      </AsyncBoundary>
      <AsyncBoundary pendingFallback={<GroupMeetupHomeListSkeleton />} rejectedFallback={<></>}>
        <ExplorerMeetupList filter={filter} />
      </AsyncBoundary>
    </>
  );
};

export default withAsyncBoundary(ExplorerMeetupListSection, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});
