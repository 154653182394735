import { groupClient } from '@community-group/api';
import {
  CoveredLazyLoadImage,
  Render,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import { IconLocationpinFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';

import { useGetParentingGroupStorage } from '@/hooks/useGetParentingGroupStorage';

import { GroupActivityLog } from './ActivityLog';
import * as s from './ListView.css';
import { MemberCountBadge } from './MemberCountBadge';
import RepresentativeGroupBadge from './RepresentativeGroupBadge';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  group: groupClient.model.GroupSummaryPresentation;
  displayRegionName?: string;
  config?: {
    showDescription?: boolean;
    showMemberList?: boolean;
    enableLazyLoadImage?: boolean;
    enabledJuniorGroupExperiment?: boolean;
  };
  titleChildren?: React.ReactNode;
  descriptionChildren?: React.ReactNode;
}

const GroupInfoListViewItem = ({
  group: { id, profileImage, name, description, memberCount, region, lastActivatedAt, createdAt },
  displayRegionName,
  config,
  titleChildren,
  descriptionChildren,
  ...props
}: Props) => {
  const {
    showDescription = true,
    enableLazyLoadImage = true,
    enabledJuniorGroupExperiment = false,
  } = config ?? {};
  const { isTargetGroup } = useGetParentingGroupStorage(id.toString());

  const renderDescription = () => {
    if (descriptionChildren) return descriptionChildren;

    return (
      <Render condition={showDescription && description !== undefined} fallback={null}>
        <Typography typography="caption1Regular" color="gray800" ellipsisAfterLines={1}>
          {description?.replace(/\s+/g, ' ').trim()}
        </Typography>
      </Render>
    );
  };

  const regionName = displayRegionName ?? region?.town;

  return (
    <div {...props} className={clsx([props.className, s.Wrapper])}>
      <div className={s.GroupImage}>
        <CoveredLazyLoadImage
          insideStroke
          isLazy={enableLazyLoadImage}
          src={profileImage.small}
          width="4.5rem"
          height="4.5rem"
          style={{ borderRadius: '1rem' }}
        />
      </div>
      <div className={s.ContentsWrapper}>
        <div className={s.TitleContainer}>
          {isTargetGroup && <RepresentativeGroupBadge />}
          {titleChildren || (
            <Typography typography="subtitle1Bold" color="gray900" ellipsisAfterLines={1}>
              {name}
            </Typography>
          )}
        </div>
        {renderDescription()}
        <div className={s.BadgeWrapper}>
          <Render condition={Boolean(regionName)}>
            <span className={s.Badge}>
              <IconLocationpinFill size={12} color={vars.$scale.color.gray500} />
              <VerticalSpacing size={2} />
              <Typography
                typography="caption1Regular"
                color="gray600"
                wordBreak="keep-all"
                ellipsisAfterLines={1}
              >
                {regionName}
              </Typography>
            </span>
          </Render>
          <MemberCountBadge
            memberCount={memberCount}
            createdAt={createdAt}
            enabledJuniorGroupExperiment={enabledJuniorGroupExperiment}
          />
          <GroupActivityLog lastActivatedAt={lastActivatedAt} />
        </div>
      </div>
    </div>
  );
};

export default GroupInfoListViewItem;
