import { IconExclamationmarkCircleFill } from '@daangn/react-monochrome-icon';
import { InlineAlert } from '@daangn/sprout-components-inline-alert';

import { trackEvent } from '@/utils/analytics';
import { openGroupBlockedDetail } from '@/utils/link';

type Props = {
  groupId: string;
  userId: string;
  isSuperHost: boolean;
};

const GroupDetailBlockedBanner = ({ groupId, userId, isSuperHost }: Props) => {
  const handleClick = () => {
    trackEvent({
      event: 'click_group_blocked_banner',
      params: {
        groupId,
        userId,
      },
    });
    openGroupBlockedDetail();
  };

  return (
    <InlineAlert
      icon={<IconExclamationmarkCircleFill size={16} />}
      description="운영정책을 위반해 모임이 제재됐어요."
      variant="danger"
      weight="strong"
      action={
        isSuperHost
          ? {
              label: '자세히보기',
              onClick: handleClick,
            }
          : undefined
      }
    />
  );
};

export default GroupDetailBlockedBanner;
