import {
  ChallengeInfoPostDetail,
  GroupCurrentUser,
  GroupFeedMeetupInfoPresentation,
  PostDetail,
} from '@community-group/api/lib/group/models';
import { isSuperHost, useBottomSheet } from '@community-group/components';
import React, { MouseEvent, useMemo } from 'react';

import { useHandleGroupOnly } from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { GroupRoutes } from '@/utils/analytics/type';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';
import { refetchPostDetail } from '@/utils/refetch/groupPostDetail';
import { isHigherManager, isNotMember } from '@/utils/role';

import { getGroupFeedType } from '../../../utils/getGroupFeedType';
import HigherManagerRoleOptionsBottomSheet from '../Modal/HigherManagerRoleOptionsBottomSheet';
import NormalUserRoleOptionsList from '../Modal/NormalUserRoleOptionsList';
import WriterRoleOptionsList from '../Modal/WriterRoleOptionsList';
import FeedItemCore from './FeedItem';

type Props = {
  post: PostDetail;
  currentUser: GroupCurrentUser;
  meetup?: GroupFeedMeetupInfoPresentation;
  challenge?: ChallengeInfoPostDetail;
  shouldSetSubNickname?: boolean;
  onClickProfile?: (event: MouseEvent) => void;
  isLastItem?: boolean;
};
/**
 * @description post: PostDetail이 아닌 GroupFeedSummary로 통일이 되면 domain/GroupFeed/components/FeedItemV2를 사용해주세요
 */
const FeedItem = ({
  post,
  currentUser,
  meetup,
  challenge,
  shouldSetSubNickname,
  onClickProfile,
  isLastItem,
}: Props) => {
  const { push } = useFlow();
  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();
  const groupId = useMemo(() => post.groupInfo?.id.toString() ?? '', [post.groupInfo]);
  const postId = useMemo(() => post.id.toString(), [post.id]);

  const blockedGroupOnly = isNotMember(currentUser.role) && post.publishType === 'groupOnly';
  const { groupInfo, handleGroupOnly } = useHandleGroupOnly({ groupId });

  const handleEditPostClick = async () => {
    await closeBottomSheet();

    const feedType = getGroupFeedType(post.postType);
    if (feedType === 'meetup' && post.postType?.meetupId) {
      push('GroupMeetupEditPage', {
        meetupId: post.postType.meetupId.toString(),
        groupId: groupId.toString(),
      });
      return;
    }

    // 챌린지인 경우
    if (feedType === 'challenge' && post.challengeInfo?.id) {
      push('ChallengeEditPage', {
        groupId: groupId.toString(),
        challengeId: post.challengeInfo.id.toString(),
      });
      return;
    }

    push('GroupPostEditPage', {
      groupId,
      postId,
      challengeId: post?.challengeInfo?.id?.toString() ?? '', //챌린지 인증글일 경우 props 필요
      postType: post.postType?.type,
    });
  };

  const handleEditPostBoardCategoryClick = async () => {
    await closeBottomSheet();

    push('BottomSheet/GroupMoveBoardCategoryBottomSheet', {
      groupId,
      postId,
    });
  };

  const handleRefetchFeed = () => {
    refetchGroupDetail({ groupId });
    refetchPostDetail({ groupId, postId });
    refetchGroupMeetupDetail({ groupId, meetupId: post.id.toString() });
  };

  const handleOnClickSheetList = async () => {
    const isAuthor = currentUser.id === post.author.id;
    const isChallengePost = post.postType?.type === 'challenge';

    // 챌린지 글이면서 일반 유저가 접근하는 경우 동작 없음
    if (isChallengePost && !isAuthor && !isSuperHost(currentUser.role)) {
      return;
    }

    // 모임장, 운영진
    if (isHigherManager(currentUser.role)) {
      await openBottomSheet({
        element: (
          <HigherManagerRoleOptionsBottomSheet
            post={post}
            groupId={groupId}
            onEditPost={handleEditPostClick}
            onEditPostBoardCategory={() => handleEditPostBoardCategoryClick()}
            currentUserPermissions={currentUser.permissions}
          />
        ),
        onDimClose: closeBottomSheet,
      });
      return;
    }

    // 일반 멤버
    if (!isAuthor) {
      await openBottomSheet({
        element: <NormalUserRoleOptionsList post={post} groupId={groupId} />,
      });
      return;
    }

    // 글 작성자
    if (isAuthor) {
      await openBottomSheet({
        element: (
          <WriterRoleOptionsList
            post={post}
            groupId={groupId}
            onEditPost={() => {
              handleEditPostClick();
            }}
            onEditPostBoardCategory={handleEditPostBoardCategoryClick}
          />
        ),
      });
      return;
    }
  };

  const handleClick = () => {
    handleGroupOnly({
      isBlock: blockedGroupOnly,
      onSettled: () => {
        const isMeetupPost = post.postType?.type === 'post' && meetup;

        if (isMeetupPost) {
          trackEvent({
            event: 'click_post_meetup_detail',
            params: {
              groupId,
              groupName: groupInfo.name,
              isChatRequired: !groupInfo.isShowChatRoomSetting,
              isChatActivated: !groupInfo.isDeactivateChatRoom,
            },
            sample: true,
          });
          push('GroupMeetupDetailPage', {
            groupId,
            meetupId: meetup.id.toString(),
            from: 'groupDetailPage',
          });
          return;
        }

        const isChallengePost = post.postType?.type === 'challenge';

        if (isChallengePost) {
          trackEvent({
            event: 'click_post_detail',
            params: {
              groupId,
              groupName: groupInfo.name,
              isChatRequired: !groupInfo.isShowChatRoomSetting,
              isChatActivated: !groupInfo.isDeactivateChatRoom,
            },
            sample: true,
          });
          push('GroupChallengeDetailPage', {
            groupId,
            challengeId: challenge?.id.toString() ?? '',
          });
          return;
        }

        push('GroupPostDetailPage', {
          groupId,
          postId,
        });
      },
      onSuccess() {
        if (shouldSetSubNickname) {
          push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
            groupId,
          });
        }
      },
    });
  };

  const handleProfileClick = (event: MouseEvent) => {
    event.stopPropagation();

    if (post.author.isAccountDeleted) return;

    trackEvent({
      event: 'click_profile',
      params: {
        referrer: GroupRoutes.GROUP_DETAIL,
        clickedUserId: post.author?.id,
        clickedUserRole: post.author?.role,
        clickedUserState: post.author?.state,
        type: 'feed',
        groupId,
        groupName: groupInfo.name,
        isChatRequired: !groupInfo.isShowChatRoomSetting,
        isChatActivated: !groupInfo.isDeactivateChatRoom,
      },
    });

    push('GroupUserProfileDetailPage', {
      groupId,
      userId: post.author.id.toString(),
    });
  };

  const handleProfileButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    handleGroupOnly({
      isBlock: blockedGroupOnly,
      onSettled() {
        handleOnClickSheetList();
      },
      onSuccess() {
        if (shouldSetSubNickname) {
          push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
            groupId,
          });
        }
      },
    });
  };

  const handleMeetupBannerClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (!meetup) return;

    push('GroupMeetupDetailPage', {
      groupId,
      meetupId: meetup.id.toString(),
    });
  };

  const handlePollBannerClick = (e: MouseEvent) => {
    e.stopPropagation();

    push('GroupPostDetailPage', {
      groupId,
      postId,
    });
  };

  return (
    <FeedItemCore
      post={post}
      currentUser={currentUser}
      meetup={meetup}
      challenge={challenge}
      onClick={handleClick}
      onProfileClick={onClickProfile || handleProfileClick}
      onProfileButtonClick={handleProfileButtonClick}
      onMeetupBannerClick={handleMeetupBannerClick}
      onPollBannerClick={handlePollBannerClick}
      onRefetchFeed={handleRefetchFeed}
      isLastItem={isLastItem}
    />
  );
};

export const MemoizingFeedItem = React.memo(FeedItem);
