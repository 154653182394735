import { GroupStatusEnum } from '@community-group/api/lib/group/models';

import { useReadFeatureFlag } from '@/hooks/useReadFeatureFlag';

import { BlockableActivityNames, blockActivityMessageMap } from './blockMessageMapModel';

type Props = {
  groupStatus: GroupStatusEnum;
  activityName: BlockableActivityNames;
};

export const useCheckGroupActivityAccessibilityByGroupStatus = ({
  groupStatus,
  activityName,
}: Props): { isAccessible: boolean; message: string } => {
  const { data: isGroupBlockedGroupExperienceEnabled } = useReadFeatureFlag(
    'isGroupBlockedGroupExperienceFlag'
  );
  if (!isGroupBlockedGroupExperienceEnabled || groupStatus !== 'blocked') {
    return {
      isAccessible: true,
      message: '',
    };
  }

  const isBlockedActivity = blockActivityMessageMap.has(activityName);
  return {
    isAccessible: !isBlockedActivity,
    message: blockActivityMessageMap.get(activityName) ?? '제재된 모임에서는 사용할 수 없어요.',
  };
};
