import { GroupStatusEnum } from '@community-group/api/lib/group/models';

import { useReadFeatureFlag } from '@/hooks/useReadFeatureFlag';

import { BlockableActionNames, blockActionMessageMap } from './blockMessageMapModel';

type Props = {
  groupStatus: GroupStatusEnum;
  actionName: BlockableActionNames;
};

export const useCheckGroupActionAvailableByGroupStatus = ({
  groupStatus,
  actionName,
}: Props): { isAvailable: boolean; message: string } => {
  const { data: isGroupBlockedGroupExperienceEnabled } = useReadFeatureFlag(
    'isGroupBlockedGroupExperienceFlag'
  );
  if (!isGroupBlockedGroupExperienceEnabled || groupStatus !== 'blocked') {
    return {
      isAvailable: true,
      message: '',
    };
  }

  const isBlockedAction = blockActionMessageMap.has(actionName);
  return {
    isAvailable: !isBlockedAction,
    message: blockActionMessageMap.get(actionName) ?? '제재된 모임에서는 사용할 수 없어요.',
  };
};
