import { Callout, CalloutDescription, Spacing } from '@community-group/components';

import * as s from './CreateMeetupGuideCallout.css';

const CreateMeetupGuideCallout = () => {
  return (
    <>
      <div className={s.TipLabel}>
        <div className={s.TipBanner}>TIP</div>
        <label>어떤 사람들과 함께하고 싶은지 적어보세요.</label>
      </div>
      <Spacing size={12} />
      <Callout>
        <CalloutDescription>
          <ul>
            <li className={s.CalloutContentsItem}>
              성별, 연령대, 취미, 관심사 등 나와 잘 맞는 이웃을 모집해보세요.
            </li>
            <li className={s.CalloutContentsItem}>ex) 멍멍이 데리고 반포 보물찾기런 가보자고!</li>
            <li className={s.CalloutContentsItem}>
              자세한 보물 위치는 일정 생성 후 채팅방을 통해 확인할 수 있어요.
            </li>
          </ul>
        </CalloutDescription>
      </Callout>
    </>
  );
};

export default CreateMeetupGuideCallout;
