import { UseFormReturn } from 'react-hook-form';

import { GroupChallengeEditFormProps } from '@/activities/Group/[groupId]/Challenge/Edit';
import { useHandleUploadImages } from '@/features/ChallengeCreate/hooks/useHandleUploadImages';

export const useHandleImageClick = ({
  formHandler,
}: {
  formHandler: UseFormReturn<GroupChallengeEditFormProps>;
}) => {
  const { pickImages, retryUploadImage } = useHandleUploadImages({
    originImages: formHandler.watch('originImages'),
    setImages: (pickImages) => {
      // Move the setValue call outside of render
      setTimeout(() => {
        formHandler.setValue('originImages', pickImages);
      }, 0);
    },
  });

  const handleImageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    pickImages();
  };
  return { handleImageClick, retryUploadImage };
};
