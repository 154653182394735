import { PostDetail } from '@community-group/api/lib/group/models';
import { AsyncBoundary, useBottomSheet, useDialog } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { IconDot3VerticalLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';
import React, { MouseEvent } from 'react';

import { useDeletePost } from '@/api/hooks/useDeletePost';
import { useGetMyGroupPostList } from '@/api/hooks/useGetMyGroupPostList';
import FeedItemContents from '@/components/group/Detail/components/Feed/FeedItem/FeedItemContents';
import FeedItemFeedbackBox from '@/components/group/Detail/components/Feed/FeedItem/FeedItemFeedbackBox';
import HigherManagerRoleOptionsBottomSheet from '@/components/group/Detail/components/Feed/Modal/HigherManagerRoleOptionsBottomSheet';
import NormalUserRoleOptionsList from '@/components/group/Detail/components/Feed/Modal/NormalUserRoleOptionsList';
import WriterRoleOptionsList from '@/components/group/Detail/components/Feed/Modal/WriterRoleOptionsList';
import { getGroupFeedType } from '@/components/group/Detail/utils/getGroupFeedType';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './index.css';
import MyFeedItemProfile from './MyFeedItemProfile';

type Props = {
  post: PostDetail;
  idx: number;
  isLastItem: boolean;
};

const MyFeedItem = ({ post, idx, isLastItem }: Props) => {
  return (
    <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
      <MyFeedItemWrapper post={post} idx={idx} isLastItem={isLastItem} />
    </AsyncBoundary>
  );
};

const MyFeedItemWrapper = ({ post, idx, isLastItem }: Props) => {
  const { push } = useFlow();
  const me = post.currentUserInfo;
  const groupId = post?.groupInfo?.id;

  const handleErrorWithToast = useHandleErrorWithToast();
  const { refetch: refetchMyGroupFeed } = useGetMyGroupPostList({});
  const { open: openBottomSheet, closeAsync: closeAsyncBottomSheet } = useBottomSheet();
  const { close: closeDialog } = useDialog();

  const { mutate: mutateDeletePost } = useDeletePost({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchMyGroupFeed();
    },
  });

  const editPostHandler = async () => {
    await closeAsyncBottomSheet();
    const groupStringId = groupId?.toString() ?? '';

    const feedType = getGroupFeedType(post.postType);
    if (feedType === 'meetup' && post.postType?.meetupId) {
      push('GroupMeetupEditPage', {
        meetupId: post.postType.meetupId.toString(),
        groupId: groupStringId,
      });
      return;
    }

    if (feedType === 'challenge' && post.challengeInfo?.id) {
      push('ChallengeEditPage', {
        groupId: groupStringId,
        challengeId: post.challengeInfo.id.toString(),
      });
      return;
    }

    return push('GroupPostEditPage', {
      groupId: groupStringId,
      postId: post.id.toString(),
      postType: post.postType?.type,
      challengeId: post?.challengeInfo?.id?.toString() ?? '',
    });
  };

  const deletePostHandler = async () => {
    await closeDialog();
    await mutateDeletePost(
      { groupId: groupId?.toString(), postId: String(post.id) },
      {
        onSuccess: () => {
          trackEvent({
            event: 'click_delete',
            params: {
              type: 'post',
              from: 'myGroupFeed',
            },
            sample: true,
          });
        },
      }
    );
  };

  const handleEditPostBoardCategoryClick = async () => {
    await closeAsyncBottomSheet();

    push('BottomSheet/GroupMoveBoardCategoryBottomSheet', {
      groupId: groupId?.toString() ?? '',
      postId: post.id.toString(),
    });
  };

  const handleOnClickSheetList = async () => {
    console.log('me?.role', me?.role);
    // TODO: superHost로 내려와야함 수정요청
    if (me?.role === 'superHost') {
      await openBottomSheet({
        element: (
          <HigherManagerRoleOptionsBottomSheet
            post={post}
            groupId={groupId?.toString() ?? ''}
            onEditPost={editPostHandler}
            currentUserPermissions={{} as any}
            onEditPostBoardCategory={handleEditPostBoardCategoryClick}
          />
        ),
      });
      return;
    }

    if (me?.id === post.author.id) {
      await openBottomSheet({
        element: (
          <WriterRoleOptionsList
            post={post}
            groupId={groupId?.toString() ?? ''}
            onEditPost={editPostHandler}
            onDeletePost={deletePostHandler}
            onEditPostBoardCategory={handleEditPostBoardCategoryClick}
          />
        ),
      });
      return;
    }
    await openBottomSheet({
      element: <NormalUserRoleOptionsList post={post} groupId={groupId?.toString() ?? ''} />,
    });
    return;
  };

  const handleMeetupBannerClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (!post.meetupInfo) return;

    push('GroupMeetupDetailPage', {
      groupId: groupId?.toString() ?? '',
      meetupId: post.meetupInfo.id.toString(),
    });
  };

  const handlePollBannerClick = (e: MouseEvent) => {
    e.stopPropagation();

    push('GroupPostDetailPage', {
      groupId: groupId?.toString() ?? '',
      postId: post.id.toString(),
    });
  };

  return (
    <motion.div
      className={s.container}
      key={post.id}
      style={{
        borderBottom: isLastItem ? 'none' : undefined,
      }}
    >
      <div
        className={s.wrapper}
        onClick={() => {
          // TODO: groupCategoryName post.groupInfo에 추가할 것
          trackEvent({
            event: 'click_my_group_post_detail',
            params: {
              postId: post.id,
              groupId: groupId,
              hasImage: post.images.length > 0,
              authorId: post.author.id,
              emotionCount: post.emotion.count,
              postType: post.postType?.type,
              feedIdx: idx,
            },
            sample: true,
          });

          const isChallengePost = post.postType?.type === 'challenge';

          if (isChallengePost) {
            push('GroupChallengeDetailPage', {
              groupId: groupId?.toString() ?? '',
              challengeId: post.challengeInfo?.id.toString() ?? '',
            });
            return;
          }

          push('GroupPostDetailPage', {
            groupId: groupId?.toString() ?? '',
            postId: post.id.toString(),
            from: 'myGroupFeed',
          });
        }}
      >
        <div className={s.header}>
          <MyFeedItemProfile post={post} />
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleOnClickSheetList();
            }}
          >
            <IconDot3VerticalLine size={24} color={vars.$scale.color.gray600} />
          </button>
        </div>
        <Spacing size={12} />
        <div style={{ width: '100%' }}>
          <FeedItemContents
            groupId={groupId?.toString() ?? ''}
            post={post}
            currentUserRole={me?.role ?? 'none'}
            meetup={post.meetupInfo}
            challenge={post.challengeInfo}
            onMeetupBannerClick={handleMeetupBannerClick}
            onPollBannerClick={handlePollBannerClick}
          />
          <Spacing size={16} />
          <FeedItemFeedbackBox
            post={post}
            groupId={groupId?.toString() ?? ''}
            refetchFeed={refetchMyGroupFeed}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default MyFeedItem;

export const MemoizingMyFeedItem = React.memo(MyFeedItem);
