import { AsyncBoundary, ViewLoader } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import GroupMaxMemberSettingRenderRight from '@/features/GroupSetting/AppScreen/GroupMaxMemberSettingRenderRight';
import GroupMaxMemberSettingSection from '@/features/GroupSetting/GroupMaxMemer';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupMaxMemberSettingPageParams = Pick<
  PageParams,
  'groupId' | 'initialMaximumMemberCount' | 'currentMemberCount'
>;

const GroupMaxMemberSettingPage: ActivityComponentType<GroupMaxMemberSettingPageParams> = () => {
  const { groupId } = usePathParams();
  const { initialMaximumMemberCount = '-1', currentMemberCount = '-1' } = useQueryParams();

  const formHandler = useForm<FieldValues>({
    defaultValues: { maximumMember: Number(initialMaximumMemberCount) },
  });

  return (
    <FormProvider {...formHandler}>
      <AppScreen
        appBar={{
          title: '최대 가입 인원 설정',
          borderSize: '0.5px',
          borderColor: vars.$semantic.color.divider3,
          renderRight: () => (
            <GroupMaxMemberSettingRenderRight
              currentMemberCount={Number(currentMemberCount)}
              groupId={groupId}
            />
          ),
        }}
      >
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
          <GroupMaxMemberSettingSection
            initialMaximumMemberCount={Number(initialMaximumMemberCount)}
          />
        </AsyncBoundary>
      </AppScreen>
    </FormProvider>
  );
};

export default GroupMaxMemberSettingPage;
