import { TextField } from '@community-group/components';
import React from 'react';
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import useValidMaximumMemberInput from '../hooks/use-validate-maximum-member-input';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  disable?: boolean;
}

export const MaximumMemberInput = ({ watch, setValue, disable = false }: Props) => {
  const { groupId } = usePathParams();
  const { data: group } = useReadGroupDetail(groupId);
  const currentMemberCount = group?.memberCount;
  const validateMaximumMemberValue = useValidMaximumMemberInput({ watch, currentMemberCount });

  const maximumMemberFieldValue = watch('maximumMember');

  const handleInputChange = (value: string) => {
    if (value === '0' && (maximumMemberFieldValue === '' || maximumMemberFieldValue === undefined))
      return;

    if (isNaN(parseInt(value, 10))) return setValue('maximumMember', undefined);

    setValue('maximumMember', parseInt(value, 10));
  };
  const handleBlur = () => {
    const prevValue = maximumMemberFieldValue;
    if (prevValue === undefined) setValue('maximumMember', null);
  };

  const isError = () => {
    return !validateMaximumMemberValue().isValid;
  };
  const helperText = validateMaximumMemberValue().helperText;

  return (
    <TextField
      type="number"
      value={maximumMemberFieldValue?.toString() ?? ''}
      onChange={(value) => handleInputChange(value)}
      variant="outlined"
      suffix="명"
      isDisabled={disable}
      isInvalid={isError()}
      onBlur={handleBlur}
      errorMessage={helperText}
      minWidth={100}
    />
  );
};

export default MaximumMemberInput;
