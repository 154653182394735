import { groupClient } from '@community-group/api';
import {
  GroupMeetupDetailPresentation,
  GroupMeetupDetailResponse,
} from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { updateGroupDetailFeedList } from '@/domain/GroupFeed/utils/updateGroupDetailFeedList';
import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';
import { queryClient } from '@/shared/api/instance';

import { getGroupMeetupDetailPath } from './useGetGroupMeetupDetail';

type Props = UseMutationOptions<AxiosResponse, Error, Request>;

type Request = {
  groupId: string;
  meetupId: string;
};

export const usePatchMeetupEmotion = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const patchEmotion = groupClient.api.MeetupApi.apiV1GroupsIdMeetupsMeetupIdEmotionsPatch({
    axios: fetchInstance,
  });

  const mutate = async ({ groupId, meetupId }: Request) => {
    const numberGroupId = Number(groupId);
    const numberMeetupId = Number(meetupId);

    const res = await patchEmotion({
      id: numberGroupId,
      meetupId: numberMeetupId,
    });
    patchMeetupDetailEmotion(groupId, meetupId);
    updateGroupDetailFeedList({ groupId, targetId: meetupId, type: 'like' });
    return res;
  };

  return useThrottleMutation(mutate, {
    onError,
    onSuccess,
  });
};

const patchMeetupDetailEmotion = (groupId: string, meetupId: string) => {
  const meetupDetailPath = getGroupMeetupDetailPath(groupId, meetupId);

  queryClient.setQueryData<GroupMeetupDetailPresentation>([meetupDetailPath], (prevData) => {
    if (!prevData) return;

    const prevEmotionCount = prevData.emotion.count ?? 0;

    return {
      ...prevData,
      emotion: {
        count: prevEmotionCount + 1,
        myEmotion: 'like',
      },
    };
  });
};
