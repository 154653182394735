import { MainProfile } from '@community-group/api/lib/group/models';
import {
  BoxButton,
  GroupAvatar,
  HorizontalSpacing,
  Render,
  Typography,
} from '@community-group/components';
import { IconLocationpinFill, IconPersonShieldFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import GroupProfileTemperature from '@/domain/GroupProfile/components/Temperature';
import SnsTagSection from '@/features/GroupProfile/SnsTagSection';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './style.css';
type Props = {
  profile?: MainProfile;
};

const GroupMainProfileUserInfo = ({ profile }: Props) => {
  const { push } = useFlow();
  if (!profile) return null;

  const isMyProfile = profile.isMyProfile;

  const handleImageClick = () => {
    trackEvent({
      event: 'click_user_profile_image',
      params: { isJoined: undefined, userState: undefined },
      sample: true,
    });

    const profileImages = profile.profileImage ? [profile.profileImage] : [];

    push('ImageViewerPage', {
      content: 'images',
      initialIndex: '0',
      imageUrls: JSON.stringify(profileImages),
      screenName: 'groupUserProfileImageViewer',
    });
  };
  return (
    <section className={s.Wrapper}>
      <div className={s.UserInfo}>
        <div className={s.AvatarWrapper}>
          <GroupAvatar size="large" src={profile.profileImage} onClick={handleImageClick} />
          <GroupProfileTemperature temperature={profile.temperature} />
        </div>
        <HorizontalSpacing size={12} />
        <div className={s.UserNicknameBox}>
          <div className={s.UserNickname}>
            <div className={s.UserWithRole}>
              <Typography typography="title2Bold">{profile.nickname}</Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={s.DescriptionWrapper}>
        <div
          className={s.Description}
          style={{ display: !!profile.description || !!profile.snsTags ? 'flex' : 'none' }}
        >
          <Render condition={!!profile.description}>
            <Typography
              color="gray900"
              typography="bodyM1Regular"
              whiteSpace="pre-wrap"
              style={{
                lineHeight: '1.625rem',
                maxHeight: 'auto',
              }}
            >
              {profile.description?.trim()}
            </Typography>
          </Render>
          <Render condition={!!profile.snsTags}>
            <SnsTagSection
              snsTags={profile.snsTags!}
              isMyProfile={isMyProfile}
              nickname={profile.nickname}
              userState={'notMember'}
            />
          </Render>
        </div>
        <div className={s.TagGroup}>
          <div className={s.TagItem}>
            <IconLocationpinFill size={16} color={vars.$scale.color.gray600} />
            <Typography color="gray900" typography="caption1Regular">
              {profile.userRegion.town}
              {profile.userRegionCheckInCount !== 0 && ` 인증 ${profile.userRegionCheckInCount}회`}
            </Typography>
          </div>
          <Render condition={profile.verified}>
            <Typography color="gray700" typography="caption1Regular">
              ⸱
            </Typography>
            <div className={s.TagItem}>
              <IconPersonShieldFill size={16} color={vars.$scale.color.gray600} />
              <Typography color="gray900" typography="caption1Regular">
                본인인증 완료
              </Typography>
            </div>
          </Render>
        </div>
      </div>
      <Render condition={isMyProfile}>
        <BoxButton
          size="medium"
          variant="secondary"
          UNSAFE_style={{ width: '100%' }}
          onClick={() => push('GroupProfileEditPage', {})}
        >
          프로필 수정
        </BoxButton>
      </Render>
    </section>
  );
};

export default GroupMainProfileUserInfo;
