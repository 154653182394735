import { BoxButton, Spacing, Typography } from '@community-group/components';
import { useMemo } from 'react';

import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import { FilterType } from '.';
import * as s from './NoMoreGroupSection.css';
type Props = {
  filter: FilterType;
  itemCount: number;
};

const NoMoreGroupSection = ({ filter, itemCount }: Props) => {
  const { push } = useFlow();

  const text = useMemo(() => {
    const defaultText = '마음에 드는 모임이 없나요?';

    //실험 대상이 맞는 경우
    if (filter.subCategory === undefined) {
      return defaultText;
    }

    if (filter.subCategory && itemCount === 0) {
      return (
        <>
          <b>{`'${filter.subCategory}'`}</b>에 해당하는 모임이 없어요.
        </>
      );
    }

    return defaultText;
  }, [filter.subCategory, itemCount]);

  return (
    <div className={s.NoGroupWrapper}>
      <div className={s.NoGroupTextWrapper}>
        <Typography typography="subtitle1Regular">{text}</Typography>
        <Typography typography="subtitle2Regular" color="gray600">
          모임을 직접 만들어보세요.
        </Typography>
      </div>
      <Spacing size={38} />
      <BoxButton
        size="medium"
        variant="secondary"
        onClick={() => {
          trackEvent({
            event: 'click_start_creating_group',
            params: {
              referrer: 'home_bottom_banner',
              mainCategory: filter.category,
              subCategory: filter.subCategory,
            },
          });
          push('GroupNewSetGroupCategoryRecommendPage', {});
        }}
      >
        모임 만들기
      </BoxButton>
    </div>
  );
};

export default NoMoreGroupSection;
