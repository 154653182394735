export const GROUP_PROFILE_QUERY_KEY = {
  base: (groupId: string, userId: string) => ['groupProfile', groupId, userId] as const,
  comments: (groupId: string, userId: string) =>
    [...GROUP_PROFILE_QUERY_KEY.base(groupId, userId), 'comments'] as const,
  activities: (groupId: string, userId: string) =>
    [...GROUP_PROFILE_QUERY_KEY.base(groupId, userId), 'activities'] as const,
  meetupReviews: (groupId: string, userId: string) =>
    [...GROUP_PROFILE_QUERY_KEY.base(groupId, userId), 'meetupReviews'] as const,
  joinedMeetupThumbnail: (groupId: string, userId: string) =>
    [...GROUP_PROFILE_QUERY_KEY.base(groupId, userId), 'joinedMeetupThumbnail'] as const,
  joinedMeetups: (groupId: string, userId: string, initialCursor?: string) =>
    [...GROUP_PROFILE_QUERY_KEY.base(groupId, userId), 'joinedMeetups', initialCursor] as const,
  checkHasGroupProfile: () => ['checkHasGroupProfile'] as const,
  meetupMemberReview: (userId: string) => ['groupProfile', userId, 'meetupMemberReview'] as const,
};
