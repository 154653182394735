import { ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { PaginationEmpty, PaginationList } from '@community-group/components/shared';
import { useSuspenseQueries } from '@tanstack/react-query';

import { useReadGroupChallengeCertifyFeeds } from '@/domain/Challenge/hooks/useReadGroupChallengeCertifyFeeds';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { MemoizedFeedItemV2 } from '@/domain/GroupFeed/components/MemoizedFeedItemV2';

import * as s from './GroupChallengeCertifyFeeds.css';

type Props = {
  groupId: string;
  challengeId: string;
};

const GroupChallengeCertifyFeeds = ({ groupId, challengeId }: Props) => {
  const {
    data: feeds,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useReadGroupChallengeCertifyFeeds({
    groupId: Number(groupId),
    challengeId: Number(challengeId),
  });

  const [{ data: groupDetail }, { data: me }] = useSuspenseQueries({
    queries: [useQueryGroupDetail(groupId), useQueryGroupMe({ groupId })],
  });

  if (feeds.length <= 0) {
    return <PaginationEmpty>작성된 인증글이 없어요.</PaginationEmpty>;
  }

  return (
    <div className={s.Container}>
      <PaginationList
        items={feeds}
        render={(item) =>
          item && (
            <MemoizedFeedItemV2
              key={`${item.id}-${item.type}`}
              groupId={groupId}
              groupDetail={groupDetail}
              currentUser={me}
              challenge={item.challengeInfo}
              meetup={item.meetupInfo}
              shouldSetSubNickname={groupDetail.subNicknameSetting.isSettingOn}
              feed={item}
            />
          )
        }
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  );
};

export default withAsyncBoundary(GroupChallengeCertifyFeeds, {
  rejectedFallback: <ViewError />,
  pendingFallback: <ViewLoader />,
});
