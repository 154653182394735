import { AsyncBoundary, ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { FormProvider, useForm } from 'react-hook-form';

import GroupManagerSettingRenderRight from '@/features/GroupSetting/AppScreen/GroupManagerSettingRenderRight';
import GroupManagerSettingSection from '@/features/GroupSetting/GroupManager';
import { GroupManagerFormValues } from '@/features/GroupSetting/util/groupManagerForm';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
type GroupManagerSettingPageParams = Pick<PageParams, 'groupId'>;

const GroupManagerSettingPage: ActivityComponentType<GroupManagerSettingPageParams> = () => {
  const { groupId } = usePathParams();

  const formHandler = useForm<GroupManagerFormValues>({
    defaultValues: {
      initialManager: [],
      newManager: [],
    },
  });
  return (
    <FormProvider {...formHandler}>
      <AppScreen
        appBar={{
          title: '운영진 설정',
          renderRight: () => <GroupManagerSettingRenderRight groupId={groupId} />,
        }}
      >
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
          <GroupManagerSettingSection groupId={groupId} />
        </AsyncBoundary>
      </AppScreen>
    </FormProvider>
  );
};

export default GroupManagerSettingPage;
