export const GradeId = {
  SUPER_HOST: '1',
  MANAGER: '100',
  MEMBER: {
    STANDARD: '1001',
    ASSOCIATE: '1000',
  },
} as const;

export const GradePriority = {
  SUPER_HOST: 5,
  MANAGER: 200,
  MEMBER: {
    STANDARD: 1100,
    ASSOCIATE: 1200,
  },
} as const;

export const GradeRole = {
  SUPER_HOST: 'superHost',
  MANAGER: 'manager',
  MEMBER: 'member',
} as const;

export const GradeName = {
  SUPER_HOST: '모임장',
  MANAGER: '운영진',
  MEMBER: {
    STANDARD: '정회원',
    ASSOCIATE: '준회원',
  },
} as const;
