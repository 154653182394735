import { model } from '@community-group/api/lib/group';
import { useBottomSheet } from '@community-group/components';
import { useCallback } from 'react';

import { useGetMyRoomList } from '@/api/hooks/apartment/useGetMyRoomList';
import { useGetRoomList } from '@/api/hooks/apartment/useGetRoomList';
import { usePostJoinRoom } from '@/api/hooks/apartment/usePostJoinRoom';
import BottomSheetForm from '@/components/common/Form/components/BottomSheetForm';
import { useBridge } from '@/contexts/Bridge';
import { useBlockGroupActionByGroupStatus } from '@/features/GroupDetail/hooks/useBlockGroupActionByGroupStatus';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';

import PreAttendInformation from '../components/PreAttendInformation';

export default function useInformationPreEnterRoom(id?: string) {
  const { open, closeAsync } = useBottomSheet();

  const { refetch: refetchRoomList } = useGetRoomList(Number(id));
  const { refetch: refetchMyRoom } = useGetMyRoomList(Number(id));

  const renderBottomSheetForm = useCallback(
    (room: model.TalkRoomPresentation) => {
      return (
        <BottomSheetForm
          onSubmit={() => onSubmit(room)}
          buttonText="입장하기"
          isLoading={false}
          title={''}
        >
          <PreAttendInformation roomInfo={room} />
        </BottomSheetForm>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [closeAsync]
  );
  const { bridge } = useBridge();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate } = usePostJoinRoom({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async ({ data }) => {
      await refetchRoomList();
      await refetchMyRoom();
      trackEvent({
        event: 'click_join_talk_room',
        params: {
          groupId: data.talkRoom.groupId,
          roomId: data.talkRoom.roomId,
          isPrivate: false,
        },
      });

      bridge.openToast({
        toast: {
          body: '채팅방에 입장했어요.',
        },
      });
      await closeAsync();

      openLink(data.talkRoom.channelInfo?.targetUri);
    },
  });

  const blockGroupActionByGroupStatus = useBlockGroupActionByGroupStatus({
    groupId: id ?? '',
    actionName: 'JoinChatRoom',
  });

  const onSubmit = blockGroupActionByGroupStatus(async (room: model.TalkRoomPresentation) => {
    if (['private', 'PRIVATE'].includes(room?.admissionType || '')) {
      await closeAsync();
      return;
    }

    mutate({
      id: Number(id),
      roomId: Number(room?.roomId),
      talkRoomJoinForm: {
        password: undefined,
      },
    });
  });

  return {
    openForm: async (room: model.TalkRoomPresentation) => {
      await open({
        element: renderBottomSheetForm(room),
      });
    },
  };
}
