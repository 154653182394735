import { useMemo } from 'react';

import { useGetSuperHostOnBoardingMission } from '@/api/hooks/useGetSuperHostOnBoardingMission';
import ICON_CT_INVITE from '@/assets/images/icon_ct_invite.svg';
import ICON_CT_PROFILE from '@/assets/images/icon_ct_profile.svg';
import ICON_CT_SPEAKER from '@/assets/images/icon_ct_speaker.svg';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { TypeActivityNames, useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

interface SuperHostOnboardingQuest {
  icon: string;
  title: string;
  description: string;
  onButtonClick: () => void;
  activityName: TypeActivityNames;
  isCompleted: boolean;
}

const useSuperHostOnboardingQuest = (): SuperHostOnboardingQuest[] => {
  const { push } = useFlow();

  const { groupId = '0' } = usePathParams();

  const { data: group } = useReadGroupDetail(groupId);

  const { data } = useGetSuperHostOnBoardingMission(groupId);

  const quest = useMemo(() => {
    const {
      isFirstPostCreated = false,
      isSetGroupImage = false,
      isOnboardingCompleted = false,
    } = data?.data ?? {};

    if (isOnboardingCompleted) return [];

    const hasDescription = !!group?.description || group?.description?.length === 0;

    return [
      {
        icon: ICON_CT_SPEAKER,
        title: '모임 소개 작성하기',
        description: `어떤 활동을 하는지 소개해주세요.`,
        onButtonClick: () => {
          push('GroupOnBoardDescriptionPage', {
            groupId,
          });
        },
        activityName: 'GroupOnBoardDescriptionPage' as TypeActivityNames,
        isCompleted: hasDescription,
      },

      {
        icon: ICON_CT_PROFILE,
        title: '모임 사진 등록하기',
        description: '활동이 잘 드러나는 사진을 등록해주세요.',
        onButtonClick: () => {
          push('GroupOnboardProfilePage', { groupId });
        },
        activityName: 'GroupOnboardProfilePage' as TypeActivityNames,
        isCompleted: isSetGroupImage,
      },
      {
        icon: ICON_CT_INVITE,
        title: '첫 글 작성하기',
        description: '이웃들을 환영하는 글을 작성해보세요.',
        onButtonClick: () => {
          push('GroupPostNewPage', {
            groupId,
            calloutType: 'first-post-mission',
          });
        },
        activityName: 'GroupPostNewPage' as TypeActivityNames,
        isCompleted: isFirstPostCreated,
      },
    ];
  }, [data?.data, group?.description, groupId, push]);

  return quest.sort((prev, current) => {
    if (prev.isCompleted && !current.isCompleted) {
      return -1;
    }
    if (!prev.isCompleted && current.isCompleted) {
      return 1;
    }
    return 0;
  });
};

export default useSuperHostOnboardingQuest;
