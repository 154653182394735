import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_MEMBER_QUERY_KEY } from '../queries';

// 모임에서 사용 중인 멤버 등급 목록을 조회해요
export const useQueryGroupMemberGrades = (groupId: string) => {
  const read = useAsyncRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGradesGet);
  return {
    queryKey: GROUP_MEMBER_QUERY_KEY.memberGrades(groupId),
    queryFn: () => read({ id: Number(groupId) }).then((res) => res.grades),
  };
};

export const useReadGroupMemberGrades = (groupId: string) => {
  const query = useQueryGroupMemberGrades(groupId);
  return useSuspenseQuery({ ...query });
};

export const useQueryNullableGroupMemberGrades = ({ groupId }: { groupId?: string }) => {
  const read = useAsyncRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGradesGet);
  return {
    queryKey: groupId ? GROUP_MEMBER_QUERY_KEY.memberGrades(groupId) : [],
    queryFn: () =>
      groupId ? read({ id: Number(groupId) }).then((res) => res.grades) : Promise.resolve(null),
  };
};

export const useReadNullableGroupMemberGrades = ({ groupId }: { groupId?: string }) => {
  const query = useQueryNullableGroupMemberGrades({ groupId });
  return useSuspenseQuery({ ...query });
};
