import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';

import { handleAxiosError } from '@/api/instance';
import { sentryErrorLevelHandler } from '@/utils/sentry';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

class CustomError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'HandleErrorWithToast Error';
  }
}

export const useHandleErrorWithToast = () => {
  const { open: openSnackbar } = useSnackbar();

  return (error: any) => {
    const { data: errorData } = handleAxiosError(error);
    if (globalThis.appConfig.isAppEnvProduction) {
      if (axios.isAxiosError(error)) {
        const { method, data, url, params, headers }: AxiosError['config'] = error.config!;

        const errorCode = error.response?.data.code ?? data.code;
        const errorMessage = error.response?.data.message ?? data.message;

        Sentry.withScope((scope: Sentry.Scope) => {
          scope.setTags({
            type: 'api',
            response_code: data?.code,
            response_message: data?.message,
          });

          scope.setContext('API Request Detail', {
            method,
            data,
            url,
            params,
            headers,
            response: error.response?.data,
            status: error.response?.status,
          });

          scope.setTransactionName(`${errorCode}`);
          scope.setFingerprint([data?.code, url, method]);

          const level = sentryErrorLevelHandler(errorCode);
          scope.setLevel(level);

          if (level === 'error') {
            Sentry.captureException(new CustomError(`[${errorCode}]: ${errorMessage}`));
          }
        });
      }
    }

    // 모임 Error Response와 기존 당근 Error Response형태가 달라 아래와 같이 ??로 처리했어요.
    const errorMessage = errorData?.message ?? errorData?.status.message;

    if (errorMessage) {
      openSnackbar({
        message: errorMessage,
      });
    }
  };
};
