import { AsyncBoundary, ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useRef, useState } from 'react';

import GroupSettingRenderRight from '@/features/GroupSetting/AppScreen/GroupSettingRenderRIght';
import GroupSettingBase from '@/features/GroupSetting/Base';
import GroupSettingSearch from '@/features/GroupSetting/Search';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutRefProps } from '@/stackflow/components/Layout/components/ContentsLayout';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupSettingV2PageParams = Pick<PageParams, 'groupId' | 'scrollToBottom'>;

const GroupSettingPage: ActivityComponentType<GroupSettingV2PageParams> = () => {
  const { groupId } = usePathParams();
  const { scrollToBottom } = useQueryParams();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const layoutRef = useRef<LayoutRefProps>(null);

  return (
    <AppScreen
      layoutRef={layoutRef}
      appBar={{
        title: '모임 설정',
        renderRight: () => (
          <GroupSettingRenderRight
            showSearchBar={showSearchBar}
            setShowSearchBar={setShowSearchBar}
            groupId={groupId}
            layoutRef={layoutRef}
          />
        ),
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        {showSearchBar && (
          <GroupSettingSearch groupId={groupId} setShowSearchResult={setShowSearchResult} />
        )}
        <div style={{ display: showSearchResult ? 'none' : 'block' }}>
          <GroupSettingBase groupId={groupId} scrollToBottom={scrollToBottom} />
        </div>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupSettingPage;
