import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_MEETUP_REVIEW_QUERY_KEY } from '@/domain/GroupMeetupReview/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

type Props = {
  groupId: string;
  meetupId: string;
};

export const useQueryGroupMeetupReview = ({ groupId, meetupId }: Props) => {
  const read = useAsyncRead(
    groupClient.api.GroupMeetupReviewApi.apiV2GroupsGroupIdMeetupsMeetupIdReviewGet
  );

  return {
    queryKey: GROUP_MEETUP_REVIEW_QUERY_KEY.base(groupId, meetupId),
    queryFn: () =>
      read({ groupId: Number(groupId), meetupId: Number(meetupId) }).then((res) => res.review),
  };
};

export const useReadGroupMeetupReview = ({ groupId, meetupId }: Props) => {
  const query = useQueryGroupMeetupReview({ groupId, meetupId });
  return useSuspenseQuery({ ...query });
};
