import { useUserConfig } from '@/contexts/UserConfig';
import { useReadFeatureFlag } from '@/hooks/useReadFeatureFlag';
import { getUserExperimentalABGroup } from '@/utils/analytics/experiment/userExperimentalGroup';

export const useJuniorGroupExperiment = () => {
  const { userConfig } = useUserConfig();
  const experimentGroup = getUserExperimentalABGroup(userConfig.userId);
  const { data: isJuniorGroupExperimentEnabled } = useReadFeatureFlag('isGroupJuniorGroupFlag');

  return {
    isTreatment: isJuniorGroupExperimentEnabled && experimentGroup === 'B',
  };
};
