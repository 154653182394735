import { Image, Video } from '@community-group/api/lib/group/models';
import { useEffectOnce } from '@community-group/components';
import { FileUploadState } from '@community-group/components';

import { useQueryGroupMeetupReview } from '@/domain/GroupMeetupReview/hooks/useReadGroupMeetupReview';
import { ImageUploadState } from '@/features/ChallengeCreate/utils/fileUpload';
import { queryClient } from '@/shared/api/instance';

type Props = {
  groupId: string;
  meetupId: string;
  handleInitialImages: (images: ImageUploadState[]) => void;
  handleInitialVideos: (videos: FileUploadState[]) => void;
};

export const useSetWrittenMeetupReviewMedias = ({
  groupId,
  meetupId,
  handleInitialImages,
  handleInitialVideos,
}: Props) => {
  const query = useQueryGroupMeetupReview({ groupId, meetupId });
  useEffectOnce(() => {
    (async () => {
      const review = await queryClient.ensureQueryData(query);
      if (!review || !review.reviewPost) return;
      const medias = review.reviewPost.medias;
      if (medias.length === 0) return;

      const images = medias
        .filter((media) => media.type === 'image')
        .map(({ media }) => media as Image);
      const videos = medias
        .filter((media) => media.type === 'video')
        .map(({ media }) => media as Video);

      const imagesAsImageUploadState = images.map<ImageUploadState>((image) => ({
        id: image.id,
        url: image.url,
        status: 'completed',
        blob: new Blob(),
      }));
      const videosAsFileUploadState = videos.map<FileUploadState>((video) => ({
        id: video.id,
        file: new File([], video.videoUrl),
        thumbnail: video.thumbnailUrl,
        status: 'completed',
        progress: 100,
        uploadedVideoInfo: {
          id: video.id,
          duration: video.duration,
        },
      }));

      handleInitialImages(imagesAsImageUploadState);
      handleInitialVideos(videosAsFileUploadState);
    })();
  });
};
