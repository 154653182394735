import { differenceInDays } from 'date-fns';

export type GroupStage =
  | 'junior' // 30일 이내 & 5명 이하의 멤버
  | 'middle' // 30일 이후 & 5명 이하의 멤버
  | 'senior'; // 5명 초과의 멤버

// 한달 이내 생성된 그룹
export const isOneMonthGroup = (createdAt: string): boolean => {
  return differenceInDays(new Date(), new Date(createdAt)) <= 30;
};

const getGroupStage = (memberCount: number, createdAt: string): GroupStage => {
  const isCreatedWithinOneMonth = isOneMonthGroup(createdAt);
  const isSmallGroup = memberCount <= 5; // 5명 이하의 멤버

  if (isCreatedWithinOneMonth && isSmallGroup) return 'junior';
  if (!isCreatedWithinOneMonth && isSmallGroup) return 'middle';
  return 'senior';
};

export default getGroupStage;
