import {
  AsyncBoundary,
  Callout,
  CalloutDescription,
  CalloutTitle,
  MultilineTextField,
  useStickInputStore,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { IconChevronLeftLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useRef } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import { useGetMyMainProfile } from '@/api/hooks/useGetMyMainProfile';
import { usePutGroupProfile } from '@/api/hooks/usePutGroupProfile';
import CameraIcon from '@/assets/images/camera.png';
import { FormInput } from '@/components/common/base/Input/Text';
import { FormContainer, FormTitle } from '@/components/common/Form';
import FormGuideCallout from '@/components/common/FormGuideCallout';
import ImageLoader from '@/components/common/Image/ImageLoader';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useHandleSetGroupProfile } from '@/hooks/useHandleSetGroupProfile';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { useFlow } from '@/stackflow';
import { AppBarTextSubmitButton } from '@/stackflow/components/AppBarTextSubmitButton';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { LayoutRefProps } from '@/stackflow/components/Layout/components/ContentsLayout';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { refetchGroupProfile } from '@/utils/refetch/groupGroupProfile';
import { refetchGroupUserProfile } from '@/utils/refetch/groupUserProfile';

import * as s from './index.css';

const GroupProfileEditPage: ActivityComponentType = () => {
  const { from, groupId, userId, calloutType } = useQueryParams();
  const { data: profile } = useGetMyMainProfile({});
  const { userConfig } = useUserConfig();

  const formMethods = useForm<FieldValues>({
    defaultValues: {
      name: profile?.nickname ?? '',
      description: profile?.description ?? '',
    },
  });
  const { watch, setValue, register } = formMethods;

  const nameFieldValue = watch('name');
  const descriptionFieldValue = watch('description');
  const {
    profileImage,
    handleSetGroupProfile,
    isLoading: isProfileSetImageLoading,
  } = useHandleSetGroupProfile({});

  const { pop } = useFlow();
  const handleErrorWithToast = useHandleErrorWithToast();

  const { setFocused } = useStickInputStore();
  useUpdateStyleCurrentRouter({});
  const { bridge } = useBridge();
  const { mutate: putMutate, isPending: isSubmitEditProfileLoading } = usePutGroupProfile({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      // 특정 모임내에서 프로필을 수정했다면 모임 프로필을 refetch
      if (groupId && userId) {
        refetchGroupUserProfile({
          groupId,
          userId,
        });
      }
      refetchGroupProfile();
      bridge.openToast({
        toast: {
          body: `프로필을 수정했어요.`,
        },
      });

      if (from === 'CHAT_ADAPTER_PAGE') {
        bridge.closeRouter({});
        return;
      }
      pop();
    },
  });

  const handleSubmit = () => {
    putMutate({
      groupMainProfileModifyForm: {
        nickname: nameFieldValue,
        description: descriptionFieldValue,
        profileImageId: profileImage?.id,
      },
    });
  };

  const ref = useRef<LayoutRefProps>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.setViweportInput({
        wrapperRef,
        callbackHandler: [
          {
            elementId: 'description-textarea',
            callback: (e) => {
              const el = e.target as HTMLTextAreaElement;
              el?.parentElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            },
          },
        ],
      });
    }
  }, []);
  
  useEffect(() => {
    // 멘션 입력을 위해 대괄호 입력방지
    // eslint-disable-next-line no-useless-escape
    const regExp = /[\[\]\"]/g;
    if (regExp.test(nameFieldValue)) {
      setValue('name', nameFieldValue.substring(0, nameFieldValue.length - 1));
    }
  }, [nameFieldValue, setValue]);

  return (
    <AppScreen
      appBar={{
        title: '모임 프로필 수정',
        closeButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: () => {
            pop();
          },
        },
        renderRight: () => (
          <AppBarTextSubmitButton loading={isSubmitEditProfileLoading} onClick={handleSubmit} />
        ),
      }}
    >
      <FormProvider {...formMethods}>
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
          <LayoutWrapper padding="1rem" ref={wrapperRef}>
            <Callout>
              <CalloutTitle>안내</CalloutTitle>
              <CalloutDescription>
                모임 프로필은 모든 모임에서 동일하게 사용하는 프로필이에요.
              </CalloutDescription>
            </Callout>
            <Spacing size={24} />
            <div className={s.imageContainer}>
              <div className={s.imageWrapper} onClick={handleSetGroupProfile}>
                <ImageLoader
                  className={s.imageLoaderWrapper}
                  src={
                    (profileImage?.small || profile?.profileImage) ??
                    userConfig.userProfileImageUrl ??
                    ''
                  }
                  isLoading={isProfileSetImageLoading}
                  isCircle
                />
                <div className={s.cameraImageWrapper}>
                  <img className={s.cameraImage} src={CameraIcon} />
                </div>
              </div>
            </div>
            <Spacing size={24} />
            <FormContainer>
              <FormTitle>닉네임</FormTitle>
              <FormInput
                register={register}
                name="name"
                placeholder="닉네임을 입력해주세요."
                maxLength={12}
                value={nameFieldValue}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
              <Spacing size={32} />
              <FormTitle>자기소개</FormTitle>
              <div id="description-textarea">
                <MultilineTextField
                  value={descriptionFieldValue ?? ''}
                  onChange={(value) => {
                    setValue('description', value);
                  }}
                  placeholder="자기소개를 입력해주세요."
                  maxLength={200}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                />
              </div>
            </FormContainer>
            <Spacing size={24} />
            <FormGuideCallout calloutType={calloutType} />
          </LayoutWrapper>
        </AsyncBoundary>
      </FormProvider>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupProfileEditPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
