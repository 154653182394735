import { Render } from '@community-group/components';
import { IconMagnifyingglassLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { debounce } from 'lodash-es';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import RemoveCircle from '@/components/common/Icons/removeCircle';
import useActiveActivities from '@/hooks/useActiveActivities';
import { useHandleUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { trackEvent } from '@/utils/analytics';

import * as s from './index.css';

interface Props {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  placeholder?: string;
  setIsFocusedSearchBar?: Dispatch<SetStateAction<boolean>>;
  shouldTrim?: boolean;
}

const MemberSearchBar = ({
  searchValue,
  setSearchValue,
  placeholder = '검색',
  setIsFocusedSearchBar,
  shouldTrim = true,
}: Props) => {
  const [inputValue, setInputValue] = useState(searchValue);
  const setSearchValueDebounced = useMemo(() => {
    return debounce((value = '') => {
      setSearchValue(value);
    }, 200);
  }, [setSearchValue]);

  useEffect(() => {
    setSearchValueDebounced(inputValue);
  }, [setSearchValueDebounced, inputValue]);

  const { activeActivities } = useActiveActivities();
  const updateStyleCurrentRouter = useHandleUpdateStyleCurrentRouter();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = shouldTrim ? event.target.value.trim() : event.target.value;
    setInputValue(trimmedValue);
  };

  const handleResetClick = () => {
    setInputValue('');
  };

  return (
    <div className={s.Wrapper}>
      <div className={s.Form}>
        <div className={s.IconWrapper}>
          <IconMagnifyingglassLine size={16} color={vars.$scale.color.gray500} />
        </div>
        <input
          className={s.Input}
          type="search"
          onFocus={() => {
            updateStyleCurrentRouter({ scrollable: false, backSwipable: false });
            setIsFocusedSearchBar?.(true);
            trackEvent({
              event: 'click_member_search_bar',
              params: {
                searchValue: inputValue,
              },
            });
          }}
          onBlur={() => {
            updateStyleCurrentRouter({
              scrollable: false,
              backSwipable: activeActivities.length === 1,
            });
            setIsFocusedSearchBar?.(false);
          }}
          value={inputValue}
          onChange={handleChange}
          placeholder={placeholder}
        />
        <Render condition={inputValue.length > 0}>
          <div className={s.Adornment} onClick={handleResetClick}>
            <RemoveCircle size={15} fill={vars.$scale.color.gray600} />
          </div>
        </Render>
      </div>
    </div>
  );
};

export default MemberSearchBar;
