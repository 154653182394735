import { vars } from '@seed-design/design-token';
import Cookies from 'universal-cookie';
import type VConsole from 'vconsole';

import { routes } from '@/stackflow/activity/routes';

const INTERNAL_TOOL_PAGE_KEY = 'internal-tool-page';

const AB_TEST_SELECT_KEY = 'ab-test-select';
const ABC_TEST_SELECT_KEY = 'abc-test-select';
const AB_TEST_BUTTON_KEY = 'ab-test-submit-button';
const AB_TEST_RESET_BUTTON_KEY = 'ab-test-reset-button';

export const AB_TEST_LOCAL_STORAGE_KEY = 'ExperimentABTest';
export const ABC_TEST_LOCAL_STORAGE_KEY = 'ExperimentABCTest';

export const INTERNAL_TOOL_PLUGIN_ID = 'internal_tool';
export const INTERNAL_TOOL_PLUGIN_NAME = 'Internal Tool';

const extractGroupIdFromPath = (pathname: string, routes: Record<string, string>) => {
  // URL에서 pathname만 추출 (쿼리 파라미터 제거)
  const pathWithoutQuery = pathname.split('?')[0];

  // 끝의 슬래시 제거
  const normalizedPath = pathWithoutQuery.replace(/\/$/, '');

  // routes 객체의 모든 path 패턴을 순회
  for (const path of Object.values(routes)) {
    if (path.includes(':groupId')) {
      const regexPattern = path.replace(/:[^/]+/g, '(d+)').replace(/\//g, '\\/');

      const regex = new RegExp(`^${regexPattern}/?$`);
      const match = normalizedPath.match(regex);

      if (match) {
        const groupIdIndex = path.split('/').findIndex((segment) => segment === ':groupId');
        const captureGroupIndex = Math.max(1, groupIdIndex);
        return match[captureGroupIndex];
      }
    }
  }

  // routes에서 매칭되지 않은 경우 직접 정규식으로 한번 더 시도
  const directMatch = normalizedPath.match(/\/group\/(\d+)/);
  return directMatch ? directMatch[1] : null;
};

export const initInternalToolVConsolePlugin = (vConsoleModule: typeof VConsole) => {
  const abTestPlugin = new vConsoleModule.VConsolePlugin(
    INTERNAL_TOOL_PLUGIN_ID,
    INTERNAL_TOOL_PLUGIN_NAME
  );

  // 이벤트 리스너를 등록하는 함수를 별도로 분리
  const registerEventListeners = () => {
    const submitButton = document.getElementById(AB_TEST_BUTTON_KEY);
    submitButton?.addEventListener('click', () => {
      const abSelect = document.getElementById(AB_TEST_SELECT_KEY) as HTMLSelectElement;
      const abcSelect = document.getElementById(ABC_TEST_SELECT_KEY) as HTMLSelectElement;

      const abTestValue = abSelect.value;
      const abcTestValue = abcSelect.value;

      window.localStorage.setItem(AB_TEST_LOCAL_STORAGE_KEY, abTestValue);
      window.localStorage.setItem(ABC_TEST_LOCAL_STORAGE_KEY, abcTestValue);
    });

    const resetButton = document.getElementById(AB_TEST_RESET_BUTTON_KEY);
    resetButton?.addEventListener('click', () => {
      window.localStorage.removeItem(AB_TEST_LOCAL_STORAGE_KEY);
      window.localStorage.removeItem(ABC_TEST_LOCAL_STORAGE_KEY);
      // 리셋 후 뷰 업데이트
      updateView((view) => {
        const container = document.querySelector(`#__vc_plug_${INTERNAL_TOOL_PLUGIN_ID}`);
        if (container) {
          container.innerHTML = view;
          registerEventListeners(); // 이벤트 리스너 재등록
        }
      });
    });

    const internalPageButton = document.getElementById(INTERNAL_TOOL_PAGE_KEY);
    internalPageButton?.addEventListener('click', () => {
      window.location.href = '/internal-tool';
    });
  };

  const updateView = (callback: (view: string) => void) => {
    const groupId = extractGroupIdFromPath(window.location.pathname, routes);
    const cookies = new Cookies();
    const userId = cookies.get('x-user-id');

    const view = internalToolTemplate({
      groupId,
      userId,
    });

    callback(view);
    // view 업데이트 후 이벤트 리스너 등록
    registerEventListeners();
  };

  abTestPlugin.on('renderTab', (callback) => {
    updateView(callback);

    const container = document.querySelector(`#__vc_plug_${INTERNAL_TOOL_PLUGIN_ID}`);
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && container) {
          updateView((view) => {
            container.innerHTML = view;
          });
        }
      });
    });

    if (container) observer.observe(container as Element);
  });

  return abTestPlugin;
};

const internalToolTemplate = ({
  groupId,
  userId,
}: {
  groupId?: string | null;
  userId?: string;
}) => {
  const currentABTestVariant = window.localStorage.getItem(AB_TEST_LOCAL_STORAGE_KEY) ?? 'A';
  const currentABCTestVariant = window.localStorage.getItem(ABC_TEST_LOCAL_STORAGE_KEY) ?? 'A';

  return `
    <div style="width: 100%; padding: 16px; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;">
      <div style="margin-bottom: 20px;">
        <h1 style="font-size: 20px; font-weight: 600; color: #1a1a1a; margin-bottom: 12px;">Internal Tool</h1>
        ${
          groupId || userId
            ? `<div style="background: #f5f5f5; padding: 12px; border-radius: 6px; margin-bottom: 16px; display: flex; flex-direction: column; gap: 4px;">
                ${
                  groupId
                    ? `<p style="margin-bottom: 4px; color: #666; font-size: 14px;">Group ID: <strong style="color: #1a1a1a">${groupId}</strong></p>`
                    : ''
                }
                ${
                  userId
                    ? `<p style="color: #666; font-size: 14px;">User ID: <strong style="color: #1a1a1a">${userId}</strong></p>`
                    : ''
                }
              </div>`
            : ''
        }
        <button id=${INTERNAL_TOOL_PAGE_KEY} type="button" style="width: 100%; font-weight: 600; font-size: 13px; padding: 10px 12px; border-radius: 6px; border: none; background: ${
    vars.$semantic.color.primary
  }; color: white; cursor: pointer; transition: background 0.2s;">Internal Tool 페이지 이동</button>
      </div>

      <div style="background: white; padding: 16px; border-radius: 6px; box-shadow: 0 1px 2px rgba(0,0,0,0.1);">
        <h2 style="font-size: 16px; font-weight: 600; color: #1a1a1a; margin-bottom: 16px;">A/B Test Settings</h2>
        
        <div style="display: grid; grid-template-columns: auto 120px; gap: 12px; align-items: center; margin-bottom: 12px;">
          <label for=${AB_TEST_SELECT_KEY} style="font-size: 13px; font-weight: 500; color: #666;">AB Test Variant</label>
          <select id=${AB_TEST_SELECT_KEY} style="padding: 6px 8px; border: 1px solid #e5e5e5; border-radius: 4px; font-size: 13px; color: #1a1a1a; background: white; cursor: pointer;">
            <option value="A" ${currentABTestVariant === 'A' ? 'selected' : ''}>A</option>
            <option value="B" ${currentABTestVariant === 'B' ? 'selected' : ''}>B</option>
          </select>
        </div>

        <div style="display: grid; grid-template-columns: auto 120px; gap: 12px; align-items: center; margin-bottom: 16px;">
          <label for=${ABC_TEST_SELECT_KEY} style="font-size: 13px; font-weight: 500; color: #666;">ABC Test Variant</label>
          <select id=${ABC_TEST_SELECT_KEY} style="padding: 6px 8px; border: 1px solid #e5e5e5; border-radius: 4px; font-size: 13px; color: #1a1a1a; background: white; cursor: pointer;">
            <option value="A" ${currentABCTestVariant === 'A' ? 'selected' : ''}>A</option>
            <option value="B" ${currentABCTestVariant === 'B' ? 'selected' : ''}>B</option>
            <option value="C" ${currentABCTestVariant === 'C' ? 'selected' : ''}>C</option>
          </select>
        </div>

        <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 8px;">
          <button id=${AB_TEST_BUTTON_KEY} type="button" style="font-weight: 600; font-size: 13px; padding: 8px; border-radius: 4px; border: none; background: ${
    vars.$semantic.color.primary
  }; color: white; cursor: pointer; transition: background 0.2s;">Apply</button>
          <button id=${AB_TEST_RESET_BUTTON_KEY} type="button" style="font-weight: 600; font-size: 13px; padding: 8px; border-radius: 4px; border: none; background: #6b7280; color: white; cursor: pointer; transition: background 0.2s;">초기화</button>
        </div>
      </div>
    </div>
  `;
};
