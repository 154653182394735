import { useStorage } from '@/hooks/useStorage';

const useGroupDetailSeoulRunNoticeBannerClosed = (targetId: string): [boolean, () => void] => {
  const [storage, setStorage] = useStorage('seoulRunNoticeBannerShownCount', {
    [targetId]: 0,
  });
  const shownCount = storage?.[targetId] ?? 0;

  const addShownCount = () => {
    setStorage({
      ...storage,
      [targetId]: shownCount + 1,
    });
  };

  // NOTE: SSR 시점에 스토리지 조회를 실패하면 배너를 노출하지 않는 게 UX를 해치지 않아서 기본값은 true로 설정해요
  return [typeof window === 'undefined' ? true : shownCount >= 1, addShownCount];
};

export default useGroupDetailSeoulRunNoticeBannerClosed;
