import { useSuspenseQuery } from '@tanstack/react-query';

import { CATEGORIES_URL, getCategories } from '../base/categories';
import { useFetchInstance } from './instance/useFetchInstance';

/**
 * @deprecated useReadGroupCategories 를 사용해주세요.
 */

// 2차 카테고리 실험 롤아웃에 따른 category api의 서버쪽 작업이 필요함. 우선 FE의 실험군으로 롤아웃 처리
// 추후 카테고리 response 를 서버에서 받아올 수 있도록 수정 필요
export const useGetCategories = () => {
  const fetchInstance = useFetchInstance();

  const { data: categoryResponse } = useSuspenseQuery({
    queryKey: [CATEGORIES_URL],
    queryFn: async () => {
      const { categories } = await getCategories(fetchInstance);

      return { categories };
    },
  });

  return categoryResponse;
};
