import { Spacing, Typography } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';
import { Fragment } from 'react';

import { useUserConfig } from '@/contexts/UserConfig';
import { useReadNullableGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import {
  ChallengeCustomItem,
  ChallengeTemplateItem,
} from '@/features/ChallengeCreate/ChallengeTemplateItem';
import { getTemplatesByCategory } from '@/features/ChallengeCreate/templates/challengeTemplates';
import { GroupChallengeBaseForm } from '@/features/ChallengeCreate/types';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { ActivityQueryParams, useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { getUserExperimentalABGroup } from '@/utils/analytics/experiment/userExperimentalGroup';

import { defaultGroupChallengeData } from '..';
import * as s from './style.css';

type Params = Pick<ActivityQueryParams, 'groupId' | 'from'>;

const GroupChallengeNewTemplatePage: ActivityComponentType<Params> = () => {
  const { replace } = useFlow();
  // 모임이 없는 경우에도 챌린지 즉시 생성 플로우가 있어, groupId는 nullable한 상태
  const { from, groupId = undefined } = useQueryParams();
  const { userConfig } = useUserConfig();
  const treatmentUser = getUserExperimentalABGroup(userConfig.userId);

  // TODO: 임시저장 기능 사용 시 주석 해제
  // const { tempStoredData } = useTempCreateGroupChallengeFormData({
  //   groupId,
  // });

  const { data: group } = useReadNullableGroupDetail(groupId);
  useEnterTrackEvent({
    event: 'enter_challenge_create_template_page',
    params: {
      groupId,
      groupName: group?.name,
      categoryId: group?.category.id,
      from,
      experimentGroup: treatmentUser,
    },
  });

  const handleClickChallengeTemplateItem = (defaultData?: GroupChallengeBaseForm) => {
    replace('GroupChallengeNewPage', {
      groupId,
      defaultValue: defaultData ? JSON.stringify(defaultData) : undefined,
      from,
    });
  };

  return (
    <AppScreen
      appBar={{
        backButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
        },
        closeButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
        },
      }}
    >
      <div className={s.Wrapper}>
        <Typography typography="title1Bold">어떤 챌린지를 진행할까요?</Typography>
        <Spacing size={24} />
        {/* 임시 저장 데이터 */}
        {/* {tempStoredData && (
          <Fragment key={tempStoredData.name}>
            <ChallengeTemplateItem
              isTempSavedData
              name={tempStoredData.name}
              onClick={() =>
                handleClickChallengeTemplateItem({
                  ...tempStoredData,
                  originImages: [],
                })
              }
              defaultData={{
                name: '',
              }}
            />
            <Spacing size={12} />
          </Fragment>
        )} */}
        {getTemplatesByCategory(group?.category.name ?? '').map((item) => {
          return (
            <Fragment key={item.name}>
              <ChallengeTemplateItem
                {...item}
                onClick={() =>
                  handleClickChallengeTemplateItem({
                    ...defaultGroupChallengeData,
                    ...item.defaultData,
                  })
                }
              />
              <Spacing size={12} />
            </Fragment>
          );
        })}
        <ChallengeCustomItem onClick={() => handleClickChallengeTemplateItem()} />
      </div>
    </AppScreen>
  );
};

export default GroupChallengeNewTemplatePage;
