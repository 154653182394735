import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { useWebServerFetchInstance } from '@/api/hooks/instance/useWebServerFetchInstance';

// !FeatureFlag가 바르게 정의되어 있는지 확인하세요!
// https://unleash-admin.kr.wekarrot.net/projects/community-group-fe

// 사용중인 피쳐플래그 타입
export type FeatureFlagKeysType = {
  isGroupMemberGradeFlag?: boolean; // 멤버 등급 사용 여부
  isGroupBlockedGroupExperienceFlag?: boolean; // 모임 차단 경험 사용 여부
  isGroupJuniorGroupFlag?: boolean; // 신규 모임 인큐베이팅 Phase 0 시작 여부
  isGroupSeoulRunPromotionFlag?: boolean; // 한강 보물찾기런 프로모션 사용 여부
};

type FeatureFlagType = keyof FeatureFlagKeysType;

export const featureFlagPath = '/api/feature-flags';

export const useQueryFeatureFlag = <T extends FeatureFlagType>(featureFlagName: T) => {
  const webServerFetchInstance = useWebServerFetchInstance();

  const queryFeatureFlagFn = async () => {
    const { data } = await webServerFetchInstance.get<FeatureFlagKeysType>(featureFlagPath);
    return data?.[featureFlagName] ?? false;
  };

  return queryOptions({
    queryKey: [featureFlagPath],
    queryFn: queryFeatureFlagFn,
  });
};

export const useReadFeatureFlag = <T extends FeatureFlagType>(featureFlagName: T) => {
  const query = useQueryFeatureFlag(featureFlagName);
  return useSuspenseQuery(query);
};
