import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_SIDEBAR_QUERY_KEY } from '../querires';

export const useQueryGroupSidebar = (groupId: string) => {
  const read = useAsyncRead(groupClient.api.GroupSidebarApi.apiV1GroupsIdSidebarGet);
  return {
    queryKey: GROUP_SIDEBAR_QUERY_KEY.base(groupId),
    queryFn: () => read({ id: Number(groupId) }),
  };
};

export const useReadGroupSidebar = (groupId: string) => {
  const query = useQueryGroupSidebar(groupId);
  return useSuspenseQuery({ ...query });
};
