import { VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Spinner } from '../../../components';
import { throttle } from '../../../utils';
import * as s from './style.css';

type RenderItemProps<T> = {
  item: T;
  index: number;
  isLast: boolean;
  measureElement: (node: Element | null) => void;
} & VirtualItem;

type Props<T> = {
  items: T[];
  render: (props: RenderItemProps<T>) => React.ReactNode;
  scrollRef: React.RefObject<HTMLDivElement>;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  estimateSize: number;
  isStackTop: boolean;
} & Omit<
  Parameters<typeof useVirtualizer<HTMLDivElement, Element>>[0],
  'estimateSize' | 'count' | 'getScrollElement'
>;

export const VirtualList = <T,>({
  items,
  render,
  scrollRef,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  estimateSize,
  isStackTop,
  ...virtualizerOptions
}: Props<T>) => {
  const [kVirtualIndexes, setKVirtualIndexes] = useState<number[]>([]);
  const { ref, inView } = useInView({ threshold: 0 });
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      throttle(fetchNextPage, 200)();
    }
  }, [inView, fetchNextPage, hasNextPage, isFetchingNextPage]);
  const measurementCacheRef = useRef<Record<number, number>>({});

  const virtualizerOnChange = useCallback(
    (virtualizer: Virtualizer<HTMLDivElement, Element>) => {
      if (!virtualizer.isScrolling) {
        setKVirtualIndexes(virtualizer.getVirtualIndexes());
      }
      if (isStackTop && virtualizer.scrollDirection !== 'backward')
        setHeight(virtualizer.getTotalSize());
    },
    [isStackTop]
  );

  const measureFn = useCallback(
    (
      element: Element,
      entry: ResizeObserverEntry | undefined,
      instance: Virtualizer<HTMLDivElement, Element>
    ) => {
      if (!element) return estimateSize;

      const indexKey = Number(element.getAttribute('data-index'));
      const direction = instance.scrollDirection;

      if (direction === 'forward' || direction === null) {
        // 스크롤을 내리는 시점에는 element.scrollHeight를 사용하여 측정 이후 캐싱
        const size = element.scrollHeight;
        measurementCacheRef.current[indexKey] = size;
        return size;
      } else {
        // 스크롤을 올리는 시점에는 캐싱된 값을 사용
        const cachedSize = measurementCacheRef.current[indexKey];
        if (cachedSize) {
          return cachedSize;
        }
        // 만일 캐싱된 값이 없으면 새로 측정 이후 캐싱
        const size = element.scrollHeight;
        measurementCacheRef.current[indexKey] = size;
        return size;
      }
    },
    [estimateSize]
  );

  const virtualizer = useVirtualizer({
    ...virtualizerOptions,
    overscan: virtualizerOptions.overscan ?? 2,
    estimateSize: () => estimateSize,
    count: items.length,
    getScrollElement: () => scrollRef.current,
    observeElementRect: (instance, cb) => {
      cb({ width: 300, height: 600 });
    },
    onChange: virtualizerOnChange,
    // 스택 전환 사이에 스크롤 시점이 변경되지 않도록 보여지는 범위를 스택 전환 시점으로 고정
    rangeExtractor: !isStackTop ? () => kVirtualIndexes : undefined,
    measureElement: measureFn,
  });

  // 실제 사용할 virtualItems 결정
  const virtualItems = virtualizer.getVirtualItems();

  const Loader = useCallback(() => {
    if (!hasNextPage) return null;

    if (isFetchingNextPage)
      return (
        <div className={s.LoadingWrapper}>
          <Spinner />
        </div>
      );

    return <div className={s.Trigger} ref={ref} />;
  }, [isFetchingNextPage, hasNextPage, ref]);

  return (
    <ul
      style={{ height, width: '100%', position: 'relative' }}
      role="list"
      aria-label="scrollable list"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
        }}
        aria-hidden="false"
      >
        {virtualItems.map((item) => {
          const virtualItem = items[item.index];
          const isLast = items.length - 1 === item.index;
          return (
            <div key={item.key} data-index={item.index} ref={virtualizer.measureElement}>
              {render({
                item: virtualItem,
                isLast,
                measureElement: virtualizer.measureElement,
                ...item,
              })}
            </div>
          );
        })}
        <Loader />
      </div>
    </ul>
  );
};
