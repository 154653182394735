import { AsyncBoundary, ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupPostWritePageParams = Pick<PageParams, 'groupId'>;

const GroupPostWritePage: ActivityComponentType<GroupPostWritePageParams> = () => {
  const { groupId } = usePathParams();

  return (
    <AppScreen appBar={{ title: '모임 글쓰기' }}>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <div />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupPostWritePage;
