import { ActionableCallout, Divider, Typography } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { openGroupGuideBookSurvey } from '@/utils/link';

import * as s from './style.css';

const GroupGuideBookSettingSection = () => {
  return (
    <div className={s.Wrapper}>
      <SettingList.Wrapper
        title={
          <div className={s.Title}>
            <Typography typography="title3Bold">모임 운영이 처음이신가요?</Typography>
            <Typography typography="bodyL2Regular" color="gray600">
              멤버 모집 팁과 모임 운영 노하우를 살펴보세요.
            </Typography>
          </div>
        }
      >
        {guideBook.map((item, index) => (
          <>
            <Divider size={index === 0 ? 0 : 1} padding={16} />
            <SettingList.Item
              key={item.title}
              title={item.title}
              className={s.Item}
              onClick={() => (window.location.href = item.link)}
              renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
            />
          </>
        ))}
        <Divider size={2} padding={16} />
      </SettingList.Wrapper>
      <div className={s.Survey}>
        <ActionableCallout width="100%" onClick={openGroupGuideBookSurvey}>
          <Typography typography="bodyL2Regular">
            <span style={{ ...vars.$semantic.typography.bodyL2Bold }}>설문 </span> 모임 가이드에
            대한 의견을 들려주세요.
          </Typography>
        </ActionableCallout>
      </div>
    </div>
  );
};

export default GroupGuideBookSettingSection;

const guideBook = [
  {
    title: '모임 소개를 보기 좋게 설정하는 방법',
    link: 'karrot://minikarrot/router?remote=https%3A%2F%2Flandkit.krrt.io%2Fpages%2F7a2xni%2F%3Futm_campaign%3Dcommunity_guide%26utm_source%3Dsetting_page%26utm_content%3Dintroduce_backgrounds&navbar=true&scrollable=true',
  },
  {
    title: '우리 동네 이웃에게 모임 홍보하는 방법',
    link: 'karrot://minikarrot/router?remote=https%3A%2F%2Flandkit.krrt.io%2Fpages%2Fhkxkpw%2F%3Futm_campaign%3Dcommunity_guide%26utm_source%3Dsetting_page%26utm_content%3Dad_growth&navbar=true&scrollable=true',
  },
  {
    title: '멤버를 정회원, 준회원으로 나눠서 관리하는 방법',
    link: 'karrot://minikarrot/router?remote=https%3A%2F%2Flandkit.krrt.io%2Fpages%2Figqwgj%2F%3Futm_campaign%3Dcommunity_guide%26utm_source%3Dsetting_page%26utm_content%3Dmember_grade&navbar=true&scrollable=true',
  },
  {
    title: '멤버 가입하면 자동으로 인사하는 방법',
    link: 'karrot://minikarrot/router?remote=https%3A%2F%2Flandkit.krrt.io%2Fpages%2F1zgj2q%2F%3Futm_campaign%3Dcommunity_guide%26utm_source%3Dsetting_page%26utm_content%3Dauto_hello&navbar=true&scrollable=true',
  },
  {
    title: '활동 잘 안하는 멤버 관리하는 방법',
    link: 'karrot://minikarrot/router?remote=https%3A%2F%2Flandkit.krrt.io%2Fpages%2Fukkm1p%2F%3Futm_campaign%3Dcommunity_guide%26utm_source%3Dsetting_page%26utm_content%3Dmember_kick&navbar=true&scrollable=true',
  },
  // {
  //   title: '주제별로 게시판 나눠서 사용 방법',
  //   link: 'https://www.google.com',
  // },
  // {
  //   title: '일정 다녀오고 후기 남기는 방법',
  //   link: 'https://www.google.com',
  // },
  // {
  //   title: '핸드폰 첫 화면에 바로가기 만드는방법',
  //   link: 'https://www.google.com',
  // },
];
