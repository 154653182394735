import { groupClient } from '@community-group/api';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { $Values } from 'utility-types';

import { ORDER_MEETUP } from '@/api/base/group';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_MEETUP_QUERY_KEY } from '../queries';

export interface AllTypeMeetupProps {
  regionId: number;
  categoryId?: number;
  meetupStatus?: ('opened' | 'fully_recruited' | 'closed')[];
  order: $Values<typeof ORDER_MEETUP>;
  initialCursor?: string | undefined;
}

export const useReadAllMeetups = ({
  regionId,
  meetupStatus = ['opened'],
  categoryId,
  order,
  initialCursor,
}: AllTypeMeetupProps) => {
  const read = useAsyncRead(groupClient.api.AllMeetupsApi.apiV1AllTypeMeetupsGet);
  return useSuspenseInfiniteQuery({
    queryKey: GROUP_MEETUP_QUERY_KEY.allMeetups(regionId, meetupStatus, order, categoryId),
    queryFn: ({ pageParam = initialCursor }) =>
      read({
        cursor: pageParam ? pageParam : undefined,
        limit: 20,
        regionId,
        statuses: meetupStatus,
        categoryId,
        order,
      }),
    initialPageParam: initialCursor,
    getNextPageParam: ({ hasNext, endCursor }) => (hasNext ? endCursor ?? undefined : undefined),
    select: (data) => data.pages.flatMap((page) => page.meetups),
  });
};
