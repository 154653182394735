import {
  AsyncBoundary,
  isMember,
  Spacing,
  ViewError,
  ViewLoader,
} from '@community-group/components';

import FeedMeetupList from '@/components/group/DetailV2/components/GroupDetailFeed/FeedMeetupList';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from './index.css';

const GroupMeetupList = () => {
  return (
    <AsyncBoundary pendingFallback={<></>} rejectedFallback={<ViewError />}>
      <GroupMeetupListView />
    </AsyncBoundary>
  );
};

const GroupMeetupListView = () => {
  const { groupId = '' } = usePathParams();

  const { data: group } = useReadGroupDetail(groupId);

  const { data: myInfo } = useReadGroupMe({ groupId, refetchOnWindowFocus: true });

  const isNotGroupMember = !isMember(myInfo.role);

  return (
    <section className={s.wrapper}>
      {!isNotGroupMember && <Spacing size={8} />}

      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <FeedMeetupList
          groupId={groupId}
          shouldSetSubNickname={group?.subNicknameSetting.isSettingOn ?? false}
        />
      </AsyncBoundary>
    </section>
  );
};

export default GroupMeetupList;
