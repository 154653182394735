import React from 'react';

import LinkifyContainer from '@/components/common/Linkify';
import OpenGraph from '@/components/common/OpenGraph';
import { isShowOpenGraphContent } from '@/utils/check';

import * as s from './index.css';

type Props = {
  contents: string;
};

const ChallengeContents = ({ contents }: Props) => {
  return (
    <div className={s.Wrapper}>
      <div className={s.TextContent}>
        <LinkifyContainer text={contents} />
      </div>
      {isShowOpenGraphContent(contents) && <OpenGraph text={contents} type="DETAIL" />}
    </div>
  );
};

export default ChallengeContents;
