import { GroupCurrentUserPermissions } from '@community-group/api/lib/group/models';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  AsyncBoundary,
  Dialog,
  isSuperHost,
  useBottomSheet,
  useDialog,
  ViewError,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useDeleteGroupMeetupDetail } from '@/api/hooks/useDeleteGroupMeetupDetail';
import { useDeleteNotice } from '@/api/hooks/useDeleteNotice';
import { useDeletePost } from '@/api/hooks/useDeletePost';
import { usePatchNotice } from '@/api/hooks/usePatchNotice';
import { usePutEditPost } from '@/api/hooks/usePutEditPost';
import { usePutGroupMeetup } from '@/api/hooks/usePutGroupMeetup';
import CheckAccessiblePermission from '@/components/common/CheckAccessiblePermission';
import { RecurringGroupMeetupDeleteBottomSheet } from '@/components/group/Meetup/Detail/components/GroupMeetupRightAppBar/RecurringGroupMeetupDeleteBottomSheet';
import { useBridge } from '@/contexts/Bridge';
import { useDeleteChallenge } from '@/domain/Challenge/hooks/useDeleteChallenge';
import usePatchChallenge from '@/domain/Challenge/hooks/usePatchChallenge';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { getGroupFeedType } from '@/domain/GroupFeed/utils/getGroupFeedType';
import { useQueryGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { trackEvent } from '@/utils/analytics';
import { openGroupChallengeReport, openGroupMeetupReport, openGroupPostReport } from '@/utils/link';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';
import { refetchPostDetail } from '@/utils/refetch/groupPostDetail';
import { refetchHome } from '@/utils/refetch/home';

import { FeedModalProps } from './FeedModal';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

type Props = Required<Pick<FeedModalProps, 'post' | 'groupId' | 'onEditPost'>> &
  Pick<FeedModalProps, 'onEditPostBoardCategory'> & {
    currentUserPermissions: GroupCurrentUserPermissions;
  };

const HigherManagerRoleOptionsBottomSheet = (props: Props) => {
  return (
    <AsyncBoundary pendingFallback={<></>} rejectedFallback={<ViewError />}>
      <HigherManagerRoleOptionsWrapper {...props} />
    </AsyncBoundary>
  );
};

const HigherManagerRoleOptionsWrapper = ({
  post,
  groupId,
  onEditPost,
  onEditPostBoardCategory,
  currentUserPermissions,
}: Props) => {
  const me = post.currentUserInfo;
  const feedType = useMemo(() => getGroupFeedType(post.postType), [post.postType]);
  const { open: openSnackbar } = useSnackbar(); 
  const isAuthor = Number(me?.id) === post.author.id;

  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();
  const { open: openDialog, close: closeDialog } = useDialog();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutateDeletePost } = useDeletePost({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchGroupDetail({ groupId });
    },
  });

  const { mutate: deleteMeetup } = useDeleteGroupMeetupDetail({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchHome();
      refetchGroupDetail({ groupId });
      setTimeout(() => {
        const meetupId = post.postType?.meetupId;
        if (!meetupId) return;

        refetchGroupMeetupDetail({
          groupId,
          meetupId: meetupId.toString(),
        });
      }, 500);
    },
  });

  const [{ data: permissions }, { data: group }] = useSuspenseQueries({
    queries: [useQueryGroupPermissions(groupId), useQueryGroupDetail(groupId)],
  });

  const hasModifyPostGroupOnlyPermission = currentUserPermissions?.modifyPostGroupOnly;
  const { bridge } = useBridge();
  const { mutate: mutatePatchNotice } = usePatchNotice({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchPostDetail({ groupId, postId: post.id.toString() });
      refetchGroupDetail({ groupId });

      bridge.openToast({
        toast: {
          body: '공지로 등록되었어요.',
        },
      });
    },
  });

  const { mutate: mutateDeleteNotice } = useDeleteNotice({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchPostDetail({ groupId, postId: post.id.toString() });
      refetchGroupDetail({ groupId });

      bridge.openToast({
        toast: {
          body: '공지에서 제외되었어요.',
        },
      });
    },
  });

  const { mutate: deleteChallenge } = useDeleteChallenge({
    onError: handleErrorWithToast,
    onSuccess: () => {
      trackEvent({
        event: 'click_delete_challenge',
        params: {
          challengeId: post?.challengeInfo?.id,
          challengeName: post?.challengeInfo?.name,
          challengeStatus: post?.challengeInfo?.status,
          groupId,
          groupName: group?.name,
          categoryId: group?.category.id,
        },
      });

      refetchGroupDetail({ groupId });
      refetchGroupChallenge({ groupId, challengeId: post?.challengeInfo?.id.toString() });

      openSnackbar({
        message: '챌린지가 삭제되었어요.',
      });
    },
  });

  const { mutate: editPost } = usePutEditPost({
    onError: handleErrorWithToast,
    onSuccess: (data) => {
      bridge.openToast({
        toast: {
          body:
            data.data.post.publishType === 'public'
              ? '전체 공개로 변경되었어요.'
              : '모임에만 공개로 변경되었어요.',
        },
      });
      refetchPostDetail({ groupId, postId: post.id.toString() });
      refetchGroupDetail({ groupId });
    },
  });
  const { mutate: editMeetup } = usePutGroupMeetup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      const meetupId = post.postType?.meetupId;
      if (!meetupId) return;

      const prevExposureRange = post.meetupInfo?.exposureRange;
      bridge.openToast({
        toast: {
          body:
            prevExposureRange === 'ALL'
              ? '모임에만 공개로 변경되었어요.'
              : '전체 공개로 변경되었어요.',
        },
      });
      refetchGroupDetail({ groupId });
      refetchGroupMeetupDetail({ groupId, meetupId: meetupId.toString() });
    },
  });

  const { mutate: patchChallenge } = usePatchChallenge({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupDetail({ groupId });
      refetchGroupChallenge({ groupId, challengeId: post?.challengeInfo?.id.toString() });
      openSnackbar({
        message: '챌린지가 종료되었어요.',
      });
    },
  });

  const handleCloseChallenge = () => {
    openDialog({
      element: (
        <Dialog
          title="챌린지를 종료할까요?"
          description="이웃이 더이상 챌린지에 참여할 수 없고, 수정할 수 없어요."
          onPrimaryAction={async () => {
            await closeDialog();
            if (!post?.challengeInfo?.id) return;
            patchChallenge({
              id: Number(groupId),
              challengeId: post?.challengeInfo.id,
              form: {
                status: 'closed',
              },
            });
          }}
          primaryActionLabel="종료"
          onSecondaryAction={closeDialog}
          secondaryActionLabel="취소"
        />
      ),
    });
  };

  const handleDelete = async () => {
    await closeBottomSheet();

    const handleDeletePost = () => {
      openDialog({
        element: (
          <Dialog
            title="게시글을 삭제할까요?"
            description="모든 데이터가 삭제되고 다시 볼 수 없어요."
            primaryActionLabel="삭제"
            secondaryActionLabel="취소"
            onPrimaryAction={async () => {
              await closeDialog();
              await mutateDeletePost(
                { groupId, postId: String(post.id) },
                {
                  onSuccess: () => {
                    trackEvent({
                      event: 'click_delete',
                      params: {
                        type: 'post',
                      },
                    });
                  },
                }
              );
            }}
            onSecondaryAction={async () => {
              trackEvent({
                event: 'click_cancel',
                params: {
                  type: 'delete',
                },
              });
              await closeDialog();
            }}
          />
        ),
      });
    };

    const handleDeleteMeetup = (meetupId: string) => {
      if (post.meetupInfo?.meetupType === 'recurring') {
        openBottomSheet({
          element: (
            <RecurringGroupMeetupDeleteBottomSheet
              onDefaultDelete={async () => {
                await closeBottomSheet();
                await deleteMeetup({
                  groupId,
                  meetupId: meetupId.toString(),
                  option: 'this',
                });
              }}
              onRecurringDelete={async () => {
                await closeBottomSheet();
                await deleteMeetup({
                  groupId,
                  meetupId: meetupId.toString(),
                  option: 'afterAll',
                });
              }}
            />
          ),
        });
        return;
      }

      openDialog({
        element: (
          <Dialog
            title="일정을 삭제할까요?"
            description="모든 데이터가 삭제되고 다시 볼 수 없어요."
            primaryActionLabel="삭제"
            secondaryActionLabel="취소"
            onPrimaryAction={async () => {
              await closeDialog();
              await deleteMeetup(
                {
                  groupId,
                  meetupId: meetupId.toString(),
                },
                {
                  onSuccess: () => {
                    trackEvent({
                      event: 'click_delete',
                      params: {
                        type: 'post',
                      },
                    });
                  },
                }
              );
            }}
            onSecondaryAction={async () => {
              trackEvent({
                event: 'click_cancel',
                params: {
                  type: 'delete',
                },
              });
              await closeDialog();
            }}
          />
        ),
      });
    };

    const handleDeleteChallenge = (challengeId: number) => {
      openDialog({
        element: (
          <Dialog
            title="챌린지를 삭제할까요?"
            description="모든 데이터가 삭제되고 다시 볼 수 없어요."
            primaryActionLabel="삭제"
            secondaryActionLabel="취소"
            onPrimaryAction={async () => {
              await closeDialog();
              deleteChallenge({
                groupId: groupId.toString(),
                challengeId: challengeId.toString(),
              });
            }}
            onSecondaryAction={async () => {
              trackEvent({
                event: 'click_cancel',
                params: {
                  type: 'delete',
                },
              });
              await closeDialog();
            }}
          />
        ),
      });
    };

    if (feedType === 'meetup' && post.postType?.meetupId) {
      handleDeleteMeetup(post.postType.meetupId.toString());
      return;
    }

    if (feedType === 'challenge' && post.challengeInfo?.id) {
      handleDeleteChallenge(post.challengeInfo.id);
      return;
    }

    handleDeletePost();
  };

  const showModifyGroupOnlyButton = useMemo(() => {
    // 챌린지는 공개범위 수정 불가
    if (feedType === 'challenge') return false;
    // 내 글이면 수정 가능
    if (Number(me?.id) === post.author.id) return true;
    // role 정보 or 수정 권한 정보가 없으면 수정 불가
    if (!me?.role || !permissions.modifyPostGroupOnly) return false;

    // 수정 권한이 있는 role이면 수정 가능
    if (hasModifyPostGroupOnlyPermission) return true;

    return false;
  }, [
    feedType,
    me?.id,
    me?.role,
    post.author.id,
    permissions.modifyPostGroupOnly,
    hasModifyPostGroupOnlyPermission,
  ]);

  // 챌린지 신고 기능 배포 전 챌린지 신고 버튼 노출 여부 피쳐플래그 분기
  const showReportButton = Number(me?.id) !== post.author.id;

  const closeChallengePermission =
    (isAuthor || isSuperHost(post.currentUserInfo?.role)) &&
    post.postType?.type === 'challenge' &&
    post.challengeInfo?.status !== 'closed';

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        {/* 탈퇴해서 삭제된 계정의 글은 공지로 지정 불가 */}
        {feedType === 'post' && !post.author.isAccountDeleted && (
          <CheckAccessiblePermission
            permission="addNoticePost"
            currentPermission={currentUserPermissions}
          >
            <ActionListItem
              onClick={async () => {
                await closeBottomSheet();
                post.isNoticed
                  ? mutateDeleteNotice({ id: Number(groupId), postId: Number(post.id) })
                  : mutatePatchNotice({ id: Number(groupId), postId: Number(post.id) });
              }}
            >
              {post.isNoticed ? '공지에서 제외' : '공지로 등록'}
            </ActionListItem>
          </CheckAccessiblePermission>
        )}

        {showModifyGroupOnlyButton && (
          <ActionListItem
            onClick={async () => {
              await closeBottomSheet();
              if (feedType === 'meetup') {
                editMeetup({
                  groupId: parseInt(groupId),
                  meetupId: post.postType?.meetupId ?? 0,
                  meetupModifyForm: {
                    title: post.meetupInfo?.title ?? '',
                    maximumParticipantsNumber: post.meetupInfo?.maximumParticipantsNumber ?? 0,
                    meetupTimeAt: post.meetupInfo?.meetTime ?? '',
                    hasMeetupTimeAtOnlyDate: !post.meetupInfo?.meetTime,
                    exposureRange:
                      post.meetupInfo?.exposureRange === 'ALL' ? 'GROUP_MEMBER' : 'ALL',
                    description: post.content,
                    images: post.images.map((image) => image.id),
                    canJoinAnyMember: post.meetupInfo?.canJoinAnyMember ?? true,
                  },
                });
              } else {
                editPost({
                  id: parseInt(groupId),
                  postId: post.id,
                  postModifyForm: {
                    isGroupOnly: post.publishType === 'public',
                  },
                });
              }
            }}
          >
            {post.publishType === 'public' ? '모임에만 공개로 변경' : '전체 공개로 변경'}
          </ActionListItem>
        )}
        {/* 일정 글 & 챌린지 글은 다른 게시판으로 이동 불가 */}
        {feedType !== 'meetup' && feedType !== 'challenge' && group?.isBoardManaged && (
          <ActionListItem
            onClick={() => {
              onEditPostBoardCategory?.();
            }}
          >
            다른 게시판으로 이동
          </ActionListItem>
        )}
        {isAuthor && (
          <ActionListItem
            onClick={async () => {
              onEditPost();
            }}
          >
            수정
          </ActionListItem>
        )}
        {closeChallengePermission && (
          <ActionListItem
            onClick={async () => {
              await closeBottomSheet();
              handleCloseChallenge();
            }}
          >
            챌린지 종료
          </ActionListItem>
        )}

        <CheckAccessiblePermission
          permission="deletePostAndComment"
          currentPermission={currentUserPermissions}
        >
          <ActionListItem onClick={handleDelete} color={vars.$semantic.color.danger}>
            삭제
          </ActionListItem>
        </CheckAccessiblePermission>

        {/* 남이 작성한 글 */}
        {showReportButton && (
          <ActionListItem
            onClick={() => {
              trackEvent({
                event: 'click_report',
                params: {
                  type: 'post',
                },
              });
              closeBottomSheet();

              if (feedType === 'challenge' && post.challengeInfo?.id) {
                openGroupChallengeReport({
                  challengeId: post.challengeInfo.id.toString(),
                  authorId: post.author.id.toString(),
                });
                return;
              }

              if (feedType === 'meetup' && post.postType?.meetupId) {
                openGroupMeetupReport({
                  meetupId: post.postType.meetupId.toString(),
                  hostId: post.author.id.toString(),
                });
                return;
              }
              openGroupPostReport({
                groupId: groupId.toString(),
                postId: post.id.toString(),
              });
            }}
          >
            신고
          </ActionListItem>
        )}
      </ActionList>
    </ActionSheet>
  );
};

export default HigherManagerRoleOptionsBottomSheet;
