import {
  GroupDetailPresentation,
  GroupLevelPresentation,
} from '@community-group/api/lib/group/models';
import { Divider } from '@community-group/components';

import { GroupStage } from '@/domain/Group/utils/getGroupStage';

import GroupBanner from './GroupBanner';
import GroupCountInfo from './GroupCountInfo';
import GroupDescription from './GroupDescription';
import GroupDetailInfo from './GroupDetailInfo';
import GroupTags from './GroupTags';
import GroupTitle from './GroupTitle';
import * as s from './index.css';
import MeetupPoiMap from './MeetupPoiMap';

type Props = {
  group: GroupDetailPresentation;
  groupLevel: GroupLevelPresentation;
  groupStage: GroupStage;
};

const GroupInfoSection = ({ group, groupLevel, groupStage = 'senior' }: Props) => {
  const {
    description,
    meetupPois,
    createdAt,
    canViewVerifiedUserInfo,
    needVerification,
    ageRangeTag,
  } = group;

  return (
    <section className={s.InformationSection}>
      <GroupTitle
        name={group.name}
        memberCount={group.memberCount}
        region={group.groupCreatedRegion}
        lastActivatedAt={group.lastActivatedAt}
        profileImage={group.profileImage.medium}
        groupLevel={groupLevel.currentLevel}
        groupStage={groupStage}
      />
      <Divider />
      <GroupBanner groupLevel={groupLevel.currentLevel} groupStage={groupStage} />
      <div className={s.InnerLayout}>
        <GroupDetailInfo
          groupId={group.id.toString()}
          createdAt={createdAt}
          ageRangeTag={ageRangeTag}
          canViewVerifiedUserInfo={canViewVerifiedUserInfo}
          needVerification={needVerification}
        />
        <GroupDescription description={description} />
        <GroupTags category={group.category} />
        <GroupCountInfo
          postCount={group.postCount}
          meetupCount={group.meetupCount}
          likeCount={group.watchedCount}
        />
        <MeetupPoiMap meetupPois={meetupPois} />
      </div>
    </section>
  );
};

export default GroupInfoSection;
