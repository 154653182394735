import {
  GroupCurrentUser,
  GroupMemberProfileResponseProfile,
  GroupMemberSummaryWithActivityPresentation,
} from '@community-group/api/lib/group/models';
import { Dialog, useDialog } from '@community-group/components';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useSnackbar } from '@/_app/providers/UIOverlayProvider';
import { getMemberListPath } from '@/api/hooks/useGetMemberList';
import { GROUP_DETAIL_QUERY_KEY } from '@/domain/GroupDetail/queries';
import { GradeId } from '@/domain/GroupMember/constants';
import { usePutGroupMemberGrade } from '@/domain/GroupMember/hooks/usePutGroupMemberGrade';
import { useQueryGroupMemberGrades } from '@/domain/GroupMember/hooks/useReadGroupMemberGrades';
import { queryClient } from '@/shared/api/instance';
import { convertNicknameDisplay } from '@/utils/nickname';

type Props = {
  groupId: string;
  currentUser: GroupCurrentUser;
};

export const useHandleDelegateSuperHost = ({ groupId, currentUser }: Props) => {
  const { open: openDialog, close: closeDialog } = useDialog();
  const { open: openSnackbar } = useSnackbar();

  const [{ data: memberGrades }] = useSuspenseQueries({
    queries: [useQueryGroupMemberGrades(groupId)],
  });

  const superHostGradeId =
    memberGrades.find((grade) => grade.role === 'superHost')?.id ?? GradeId.SUPER_HOST;
  const { mutate: putMemberGrade } = usePutGroupMemberGrade({
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: GROUP_DETAIL_QUERY_KEY.me(groupId),
      });
      queryClient.refetchQueries({
        queryKey: [getMemberListPath(groupId)],
      });
    },
  });

  return {
    handleDelegateSuperHost: useCallback(
      async (
        profileUser: GroupMemberSummaryWithActivityPresentation | GroupMemberProfileResponseProfile
      ) => {
        const profileUserName = convertNicknameDisplay({
          nickname: profileUser.nickname,
          subNickname: profileUser.subNickname,
        });
        const currentUserName = convertNicknameDisplay({
          nickname: currentUser.nickname,
          subNickname: currentUser.subNickname,
        });

        openDialog({
          element: (
            <Dialog
              title="모임장 위임"
              description={`${profileUserName}님에게 모임장을 위임할까요? 위임하면 ${currentUserName}님은 멤버로 변경돼요.`}
              primaryActionLabel="위임"
              secondaryActionLabel="취소"
              onPrimaryAction={async () => {
                await putMemberGrade({
                  groupId,
                  userId: profileUser.id,
                  gradeId: superHostGradeId,
                });
                await closeDialog();
                openSnackbar({
                  message: `${profileUserName}님에게 모임장을 위임했어요.`,
                });
              }}
              onSecondaryAction={closeDialog}
            />
          ),
        });
      },
      [
        closeDialog,
        currentUser,
        groupId,
        openDialog,
        openSnackbar,
        putMemberGrade,
        superHostGradeId,
      ]
    ),
  };
};
