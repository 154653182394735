import type { GroupLevelUpMissions } from '@community-group/api/lib/group/models';
import { isSuperHost } from '@community-group/components';

import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import { MISSION_INFO, MISSION_INFO_EXPERIMENTAL } from '../utils/missions';

type Props = {
  isTreatment?: boolean;
  groupId: string;
};
// 레벨업 미션 클릭 이벤트
const useMissionClickHandler = ({ isTreatment = false, groupId }: Props) => {
  const { push } = useFlow();

  const { data: group } = useReadGroupDetail(groupId);
  const { data: me } = useReadGroupMe({ groupId });
  const { data: level } = useGetGroupLevel({ groupId: Number(groupId) });

  const handleMissionClick = (missionId: keyof GroupLevelUpMissions, onlyHost: boolean) => {
    const missionName = isTreatment
      ? MISSION_INFO_EXPERIMENTAL[missionId]?.title
      : MISSION_INFO[missionId]?.title;

    trackEvent({
      event: 'click_level_mission_item',
      params: {
        currentLevel: level?.currentLevel,
        currentProgressPercentage: level?.forLevelUp.percentage,
        missionId,
        missionName,
        missionStatus: level?.forLevelUp.missions?.[missionId]?.currentCount,
        missionAchieveCount: level?.forLevelUp.missions?.[missionId]?.achieveCount,
        isSuperHost: isSuperHost(me.role),
        groupId,
        userId: me.id.toString(),
        memberCount: group?.memberCount,
        categoryId: group?.category.id,
        categoryName: group?.category.name,
      },
    });

    // 모임장만 접근 가능한 미션 핸들링
    if (!isSuperHost(me.role) && onlyHost) {
      push('SimpleAlertDialog', {
        title: '이용 권한 안내',
        description: '모임장만 이용할 수 있는 기능이에요.',
      });
      return;
    }

    switch (missionId) {
      case 'activityPoint':
      case 'rollbackActivityPoint':
        push('GroupLevelDetailPage', {
          groupId,
          openActivityPoint: 'true',
        });
        return;
      case 'hasGroupDescription':
        push('GroupSettingEditGroupDescription', { groupId });
        return;
      case 'hasGroupThumbnail':
        push('GroupSettingEditGroupInfoPage', { groupId });
        return;
      case 'writeFirstPost':
        push('GroupPostNewPage', { groupId, from: 'levelMission', initialPublishPost: 'true' });
        return;
      case 'writePost':
        push('GroupPostNewPage', { groupId, from: 'levelMission' });
        return;
      case 'writeMeetup':
        push('GroupMeetupNewPage', { groupId, from: 'levelMission' });
        return;
      case 'raiseMember':
        if (!group || !group.permalink) return;
        push('BottomSheet/InviteMemberShareBottomSheet', {
          groupId,
        });
        return;
    }
  };

  return {
    handleMissionClick,
  };
};

export default useMissionClickHandler;
