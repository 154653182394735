import 'dayjs/locale/ko';

import { formatDate, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import React from 'react';

import * as s from './DayCalendarRadio.css';

dayjs.locale('ko');
dayjs.extend(weekday);

type Props = {
  selectedDay: string;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
};

export const DayCalendarRadio = ({ setSelectedDay, selectedDay }: Props) => {
  // With this:
  const now = new Date();
  const days = Array.from({ length: 7 }, (_, i) => {
    const day = new Date(now);
    day.setDate(now.getDate() + i);
    return day;
  });

  const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDay(e.target.value ?? '');
  };

  return (
    <div className={s.Wrapper}>
      {days.map((day) => {
        const checked = selectedDay === formatDate(day, 'yyyy-MM-dd');
        return (
          <label className={s.InputWrapper} key={formatDate(day, 'dd')}>
            <input
              className={s.Input}
              id="calendar-id"
              type="radio"
              name="day"
              value={formatDate(day, 'yyyy-MM-dd')}
              onChange={handleDayChange}
              checked={checked}
            />
            <Typography
              className={s.Text}
              typography="caption2Regular"
              color={checked ? 'gray900' : 'gray600'}
              as="p"
              textAlign="center"
              style={{
                background: checked ? vars.$scale.color.gray100 : 'transparent',
              }}
            >
              {formatDate(day, 'yyyyMMdd') === formatDate(new Date(), 'yyyyMMdd')
                ? '오늘'
                : dayjs(day).format('ddd')}
              <br />
              <Typography
                typography="label1Bold"
                as="strong"
                color={checked ? 'gray900' : 'gray600'}
              >
                {formatDate(day, 'dd')}
              </Typography>
            </Typography>
          </label>
        );
      })}
    </div>
  );
};
