import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_CHALLENGE_QUERY_KEY } from '../queries';

type Props = {
  groupId: number;
  challengeId: number;
};

export const useQueryChallengeMyCertifyActivity = ({ groupId, challengeId }: Props) => {
  const read = useAsyncRead(
    groupClient.api.GroupChallengeMemberApi
      .apiV1GroupsGroupIdChallengesChallengeIdCertifiedActivitiesMeGet
  );
  return {
    queryKey: GROUP_CHALLENGE_QUERY_KEY.myCertifyActivity(
      groupId.toString(),
      challengeId.toString()
    ),
    queryFn: () => read({ groupId: Number(groupId), challengeId: Number(challengeId) }),
  };
};

export const useReadChallengeMyCertifyActivity = ({ groupId, challengeId }: Props) => {
  const query = useQueryChallengeMyCertifyActivity({ groupId, challengeId });
  return useSuspenseQuery({ ...query });
};
