import { BoardCategoryPresentation } from '@community-group/api/lib/group/models';
import { AsyncBoundary, Typography, ViewLoader } from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { IconCheckmarkLine } from '@daangn/react-monochrome-icon';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useState } from 'react';

import { useGetBoardTypeList } from '@/api/hooks/useGetBoardTypeList';
import { getGroupFeedListPath } from '@/api/hooks/useGetGroupFeedList';
import { useGetPostDetail } from '@/api/hooks/useGetPostDetail';
import { usePutEditPost } from '@/api/hooks/usePutEditPost';
import IconWrapper from '@/components/common/Icons';
import { useBridge } from '@/contexts/Bridge';
import { GROUP_FEED_QUERY_KEY } from '@/domain/GroupFeed/queries';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { queryClient } from '@/shared/api/instance';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { refetchPostDetail } from '@/utils/refetch/groupPostDetail';

import * as s from './GroupMoveBoardCategoryBottomSheet.css';

export type GroupMoveBoardCategoryBottomSheetParams = Pick<PageParams, 'groupId' | 'postId'>;

const GroupMoveBoardCategoryBottomSheet: ActivityComponentType<
  GroupMoveBoardCategoryBottomSheetParams
> = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <GroupEditPostBoardCategoryWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const GroupEditPostBoardCategoryWrapper = () => {
  const { groupId, postId } = usePathParams();

  const { data: categories } = useGetBoardTypeList(Number(groupId), 1000000);
  const { post } = useGetPostDetail(groupId, postId);

  const [selectedBoardCategory, setSelectedBoardCategory] =
    useState<BoardCategoryPresentation | null>(post?.boardCategories?.[0] ?? null);

  const handleErrorWithToast = useHandleErrorWithToast();

  const { pop } = useConnectedActions();
  const { bridge } = useBridge();
  const { mutate } = usePutEditPost({
    onError: handleErrorWithToast,
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({
        queryKey: [getGroupFeedListPath(groupId)],
      });
      refetchPostDetail({ groupId, postId });
      queryClient.invalidateQueries({
        queryKey: GROUP_FEED_QUERY_KEY.base(groupId, data.post.author.id.toString()),
      });
      bridge.openToast({
        toast: {
          body: `${data.post.boardCategories[0]?.category} 게시판으로 글을 옮겼어요.`,
        },
      });
      pop();
    },
  });

  const handleClick = (category: BoardCategoryPresentation) => {
    setSelectedBoardCategory(category);
  };

  const handleSubmit = () => {
    handleCreateBoardCategory();
  };

  const handleCreateBoardCategory = () => {
    mutate({
      id: Number(groupId),
      postId: Number(postId),
      postModifyForm: {
        boardCategoryIds: [Number(selectedBoardCategory?.id)],
      },
    });
  };

  // 바텀시트 안 뜨는 이슈 방어로직
  // https://daangn.slack.com/archives/C0324MBCA9Z/p1711446104934199
  const disabled = selectedBoardCategory?.id === post?.boardCategories?.[0]?.id;

  if (!categories?.boardCategories) return <></>;

  return (
    <div className={s.wrapper}>
      <Typography as="h1" typography="title2Bold" color="gray900">
        게시판 이동
      </Typography>
      <Spacing size={16} />
      <ul className={s.sortingList}>
        {categories.boardCategories.map((category) => (
          <li
            className={s.sortingItem({ selected: category.id === selectedBoardCategory?.id })}
            key={category.id}
            onClick={() => {
              handleClick(category);
            }}
          >
            {category.category}
            {category.id === selectedBoardCategory?.id && (
              <IconWrapper>
                <IconCheckmarkLine size={18} color={vars.$scale.color.carrot500} />
              </IconWrapper>
            )}
          </li>
        ))}
      </ul>
      <Spacing size={12} />
      <BoxButton
        width="100%"
        isDisabled={disabled}
        onClick={handleSubmit}
        size="xlarge"
        variant="primary"
      >
        확인
      </BoxButton>
    </div>
  );
};

export default GroupMoveBoardCategoryBottomSheet;
