import { Typography } from '@community-group/components';

import * as s from './style.css';

type Props = {
  icon: React.ReactNode;
  title: string;
};

const SettingItemTitle = ({ icon, title }: Props) => {
  return (
    <div className={s.Wrapper}>
      {icon}
      <Typography typography="subtitle2Bold" color="gray900">
        {title}
      </Typography>
    </div>
  );
};

export default SettingItemTitle;
