import {
  AsyncBoundary,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useGetMainProfile } from '@/api/hooks/useGetMainProfile';
import ReceivedReviewsSection from '@/components/group/profile/MemberProfileDetail/components/RecievedReviewsSection';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import { ProfileMeetupMemberReviewMessageItem } from '../components/MeetupMemberReviewMessageItem';

export type ProfileMeetupMemberReviewMessagesPageParams = Pick<PageParams, 'userId' | 'groupId'>;

import { PaginationList } from '@community-group/components/shared';
import { vars } from '@seed-design/design-token';

import { useReadGroupMeetupMemberReviewMessages } from '@/domain/GroupProfile/hooks/useReadGroupMeetupMemberReviewMessages';

import * as s from './index.css';

// TODO: activity 폴더로 이전 및 feature 단으로 분리 필요
const ProfileMeetupMemberReviewMessagesPage: ActivityComponentType<
  ProfileMeetupMemberReviewMessagesPageParams
> = () => {
  return (
    <AppScreen
      appBar={{
        title: '받은 후기',
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <ProfileMeetupMemberReviewMessagesView />
      </AsyncBoundary>
    </AppScreen>
  );
};

const ProfileMeetupMemberReviewMessagesView = () => {
  const { userId } = usePathParams();

  const { data: mainProfile } = useGetMainProfile({
    userId: Number(userId),
  });
  const isMyProfile = !!mainProfile?.isMyProfile;

  const {
    data: meetupMemberReviewMessages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useReadGroupMeetupMemberReviewMessages({
    userId: Number(userId),
    reviewExposureRange: !isMyProfile ? 'all' : undefined,
  });

  return (
    <section>
      {mainProfile?.meetupMemberReviews && (
        <ReceivedReviewsSection reviews={mainProfile?.meetupMemberReviews} />
      )}
      <ul style={{ paddingTop: '1rem' }}>
        <div style={{ padding: '0 1rem', marginBottom: '1rem' }}>
          <Typography typography="title3Bold">받은 감사 인사</Typography>
        </div>
        {!meetupMemberReviewMessages.length && (
          <div className={s.EmptySection}>
            <Typography typography="subtitle1Regular" color="gray600">
              받은 감사 인사가 없어요.
            </Typography>
          </div>
        )}
        <PaginationList
          items={meetupMemberReviewMessages}
          render={(memberReviewMessage) => (
            <ProfileMeetupMemberReviewMessageItem
              key={memberReviewMessage.id}
              isMyProfile={isMyProfile}
              meetupMemberReviewMessage={memberReviewMessage}
            />
          )}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </ul>
    </section>
  );
};

export default withAsyncBoundary(ProfileMeetupMemberReviewMessagesPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: <ViewError />,
});
