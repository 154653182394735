import { GroupMemberSummaryWithActivityPresentation } from '@community-group/api/lib/group/models';
import { Checkbox, formatDate } from '@community-group/components';
import { PaginationList } from '@community-group/components/shared';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import EmptySection from '@/components/common/Empty';
import MemberSearchBar from '@/components/common/MemberSearchBar';
import ProfileListItem from '@/components/common/Profile/ListItem';
import { useBridge } from '@/contexts/Bridge';
import { useReadGroupInfiniteMembers } from '@/domain/GroupMember/hooks/useReadGroupInfiniteMembers';
import { GroupManagerFormValues } from '@/features/GroupSetting/util/groupManagerForm';

import * as s from './style.css';

type Props = {
  groupId: string;
};
const GroupManagerSettingSection = ({ groupId }: Props) => {
  const { bridge } = useBridge();
  const [searchValue, setSearchValue] = useState('');
  const { watch, setValue } = useFormContext<GroupManagerFormValues>();
  const {
    data: members,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useReadGroupInfiniteMembers({
    id: Number(groupId),
    order: 'joinedAtAscWithRole',
    roles: ['manager', 'member'],
    limit: 10,
    search: searchValue,
  });

  const managerMembers = useMemo(
    () =>
      members
        .filter((member) => member.role === 'manager')
        .map((member) => ({
          id: member.id,
          nickname: member.nickname,
        })),
    [members]
  );

  const newManager = watch('newManager');
  const getAppendDescription = (user: GroupMemberSummaryWithActivityPresentation) => {
    const formattedDate = formatDate(new Date(user.joinedAt), 'yy.MM.dd 가입');
    if (!user.verifiedUserInfoText) return formattedDate;
    return `${formattedDate} ⸱ ${user.verifiedUserInfoText}`;
  };

  const handleCheckedMembers = useCallback(
    (id: number, nickname: string) => {
      const updatedMembers = [...newManager];
      if (updatedMembers.some(({ id: memberId }) => memberId === id)) {
        const filteredMembers = updatedMembers.filter(({ id: memberId }) => memberId !== id);
        setValue('newManager', filteredMembers);
      } else {
        if (updatedMembers.length === 10) {
          return bridge.openToast({ toast: { body: '운영진은 10명 까지만 설정할 수 있어요.' } });
        }
        setValue('newManager', [...updatedMembers, { id, nickname }]);
      }
    },
    [newManager, setValue, bridge]
  );

  const memberList = useMemo(() => {
    if (!members.length)
      return (
        <EmptySection>
          검색 결과가 없어요. <br />
          다른 닉네임으로 검색해보세요.
        </EmptySection>
      );

    return (
      <PaginationList
        items={members}
        render={(item) => (
          <div
            key={item.id}
            className={s.Wrapper}
            onClick={() => handleCheckedMembers(item.id, item.nickname)}
          >
            <Checkbox size="large" isSelected={newManager.some(({ id }) => id === item.id)} />
            <ProfileListItem user={item} appendDescription={getAppendDescription(item)} />
          </div>
        )}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      />
    );
  }, [members, isFetchingNextPage, fetchNextPage, hasNextPage, newManager, handleCheckedMembers]);

  useEffect(() => {
    if (!managerMembers.length) return;
    setValue('initialManager', managerMembers);
    setValue('newManager', managerMembers);
  }, [managerMembers, setValue]);

  return (
    <div>
      <MemberSearchBar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder="닉네임을 검색해보세요."
      />
      {memberList}
    </div>
  );
};

export default GroupManagerSettingSection;
