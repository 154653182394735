import { groupClient } from '@community-group/api';
import { ApiV2GroupsGroupIdMeetupsMeetupIdReviewPutRequestParams } from '@community-group/api/lib/group/api/group-meetup-review-api';

import { useRead } from '@/shared/api/hooks/useRead';
import {
  useThrottleMutation,
  UseThrottleMutationOption,
} from '@/shared/api/hooks/useThrottleMutation';

type Props = UseThrottleMutationOption;

export const usePutGroupMeetupReview = (props: Props) => {
  const fetch = useRead(
    groupClient.api.GroupMeetupReviewApi.apiV2GroupsGroupIdMeetupsMeetupIdReviewPut
  );

  const mutation = async (params: ApiV2GroupsGroupIdMeetupsMeetupIdReviewPutRequestParams) =>
    await fetch(params);

  return useThrottleMutation(mutation, props);
};
