import { Map, MapInstance, Marker, Typography } from '@community-group/components';
import { useEffect, useMemo, useState } from 'react';

import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';

import * as s from './style.css';

type Props = {
  groupId: string;
};
const GroupRegularMeetupPlaceMap = ({ groupId }: Props) => {
  const { data: groupDetail } = useReadGroupDetail(groupId);
  const [map, setMap] = useState<MapInstance | null>(null);
  const currentMeetupPlace = groupDetail?.meetupPois[0];
  const locationCenter = useMemo(() => {
    return {
      lat: currentMeetupPlace.coordinates.latitude,
      lng: currentMeetupPlace.coordinates.longitude,
    };
  }, [currentMeetupPlace]);

  useEffect(() => {
    if (!map) return;
    map.setCenter(locationCenter, {
      animate: true,
    });
  }, [map, locationCenter]);

  return (
    <section className={s.Wrapper}>
      <Map
        initialMapState={{
          center: locationCenter,
          zoom: 16,
        }}
        theme="auto"
        className={s.MapWrapper}
        onLoad={(e) => setMap(e.target)}
        module={false}
      >
        <Marker position={locationCenter} anchor="bottom" />
        <Marker position={locationCenter} anchor="top">
          <Typography
            typography="label5Bold"
            color="gray800"
            style={{ textShadow: '0px 1px 1px rgba(0, 0, 0, 0.15)' }}
          >
            {currentMeetupPlace.name}
          </Typography>
        </Marker>
      </Map>
      <div className={s.PlaceBox}>
        <Typography typography="bodyL1Bold" color="gray800">
          {currentMeetupPlace.name}
        </Typography>
        <Typography typography="label3Regular" color="gray600" ellipsisAfterLines={1}>
          {currentMeetupPlace.address}
        </Typography>
      </div>
    </section>
  );
};

export default GroupRegularMeetupPlaceMap;
