import { groupClient } from '@community-group/api';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_SETTING_QUERY_KEY } from '@/domain/Setting/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

type Props = {
  groupId: string;
};

export const useQueryGroupDeletable = ({ groupId }: Props) => {
  const read = useAsyncRead(groupClient.api.SettingsApi.apiV1GroupsGroupIdSettingsDeletableGet);
  return queryOptions({
    queryKey: GROUP_SETTING_QUERY_KEY.base(groupId),
    queryFn: () => read({ groupId: Number(groupId) }).then((res) => res.deletableState),
  });
};

export const useReadGroupDeletable = ({ groupId }: Props) => {
  const query = useQueryGroupDeletable({ groupId });
  return useSuspenseQuery(query);
};
