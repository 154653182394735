import { groupClient } from '@community-group/api';
import { ApiV1GroupsIdPutRequestParams } from '@community-group/api/lib/group/api/group-api';
import { GroupDetailResponse } from '@community-group/api/lib/group/models';

import { GROUP_DETAIL_QUERY_KEY } from '@/domain/GroupDetail/queries';
import { useRead } from '@/shared/api/hooks/useRead';
import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';
import { GlobalMutationOptions } from '@/shared/types/react-query';

type Props = GlobalMutationOptions<GroupDetailResponse, unknown, ApiV1GroupsIdPutRequestParams>;

export const usePutGroup = (props: Props) => {
  const fetch = useRead(groupClient.api.GroupApi.apiV1GroupsIdPut);

  const mutate = ({ id, groupModifyForm }: ApiV1GroupsIdPutRequestParams) =>
    fetch({ id, groupModifyForm });

  const { shouldInvalidate = true, shouldRefetch = false } = props;

  return useThrottleMutation(mutate, {
    ...props,
    meta: {
      invalidates: shouldInvalidate
        ? (variables) => [GROUP_DETAIL_QUERY_KEY.base(String(variables.id))]
        : undefined,
      refetch: shouldRefetch
        ? (variables) => [GROUP_DETAIL_QUERY_KEY.base(String(variables.id))]
        : undefined,
    },
  });
};
