import { APP_DEEP_LINK_PROTOCOL } from '@/constants/path';

export const SEOUL_RUN_PROMOTION_CONFIG = {
  EVENT_TYPE: 'SEOUL_RUNNING_2025' as const,
  START_DATE: '2025-04-24',
  END_DATE: '2025-05-11',
  // TODO: 교체 필요
  LANDKIT_PAGE:
    globalThis.appConfig.appEnv === 'production'
      ? 'https://landkit.krrt.io/pages/j52vcn'
      : 'https://landkit-alpha.krrt.io/pages/u3hegd',
};

export const getSeoulRunLandkitPageScheme = () => {
  return `${APP_DEEP_LINK_PROTOCOL}://minikarrot/router?remote=${encodeURIComponent(
    SEOUL_RUN_PROMOTION_CONFIG.LANDKIT_PAGE
  )}`;
};
