import { groupClient } from '@community-group/api';
import { GroupMeetupDetailPresentation } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { updateGroupDetailFeedList } from '@/domain/GroupFeed/utils/updateGroupDetailFeedList';
import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';
import { queryClient } from '@/shared/api/instance';

import { getGroupMeetupDetailPath } from './useGetGroupMeetupDetail';

type Props = UseMutationOptions<AxiosResponse, Error, Request>;

type Request = {
  groupId: string;
  meetupId: string;
};

export const useDeleteMeetupEmotion = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const deleteEmotion = groupClient.api.MeetupApi.apiV1GroupsIdMeetupsMeetupIdEmotionsDelete({
    axios: fetchInstance,
  });

  const mutate = async ({ groupId, meetupId }: Request) => {
    const numberGroupId = Number(groupId);
    const numberMeetupId = Number(meetupId);

    const res = await deleteEmotion({ id: numberGroupId, meetupId: numberMeetupId });
    deleteMeetupDetailEmotion(groupId, meetupId);
    updateGroupDetailFeedList({ groupId, targetId: meetupId, type: 'dislike' });
    return res;
  };

  return useThrottleMutation(mutate, {
    onError,
    onSuccess,
  });
};

const deleteMeetupDetailEmotion = (groupId: string, meetupId: string) => {
  const meetupDetailPath = getGroupMeetupDetailPath(groupId, meetupId);

  queryClient.setQueryData<GroupMeetupDetailPresentation>([meetupDetailPath], (prevData) => {
    if (!prevData) return;

    const prevEmotionCount = prevData.emotion.count ?? 1;
    return {
      ...prevData,
      emotion: {
        count: prevEmotionCount - 1,
        myEmotion: undefined,
      },
    };
  });
};
