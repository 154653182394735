import { AvoidSnackbarOverlap, withAsyncBoundary } from '@community-group/components';
import { RefObject } from 'react';

import { useGetPostDetail } from '@/api/hooks/useGetPostDetail';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';

import usePostCommentSubmit from '../hooks/usePostCommentSubmit';
import CommentFormAccessoryBarCore from './CommentFormAccessoryBarCore';

type Props = {
  listRef: RefObject<HTMLDivElement>;
  groupId: string;
  relatedId: string;
  parentCommentId?: string;
  placeholder?: string;
};

const PostCommentFormAccessoryBar = ({
  listRef,
  groupId,
  relatedId,
  parentCommentId,
  placeholder,
}: Props) => {
  const { post } = useGetPostDetail(groupId, relatedId);
  const { data: currentUser } = useReadGroupMe({ groupId });

  const handleSubmit = usePostCommentSubmit({
    listRef,
    groupId,
    postId: relatedId,
    parentCommentId,
    userId: currentUser.id.toString(),
  });

  if (!post || post.author.isAccountDeleted) return null;

  return (
    <AvoidSnackbarOverlap>
      <CommentFormAccessoryBarCore
        groupId={groupId}
        onSubmit={handleSubmit}
        placeholder={placeholder}
      />
    </AvoidSnackbarOverlap>
  );
};

export default withAsyncBoundary(PostCommentFormAccessoryBar, {});
