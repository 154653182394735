import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import useValidMaximumMemberInput from '@/components/group/new/RecruitmentCondition/hooks/use-validate-maximum-member-input';
import { useBridge } from '@/contexts/Bridge';
import { usePutGroup } from '@/domain/Group/hooks/usePutGroup';
import * as stackflow from '@/stackflow';
import { AppBarTextSubmitButton } from '@/stackflow/components/AppBarTextSubmitButton';

type Props = {
  currentMemberCount: number;
  groupId: string;
};

const GroupMaxMemberSettingRenderRight = ({ currentMemberCount, groupId }: Props) => {
  const { bridge } = useBridge();
  const stack = stackflow?.useFlow();
  const { mutate: putGroup, isPending } = usePutGroup({
    onSuccess: () => {
      bridge.openToast({ toast: { body: '최대 가입 인원을 수정했어요.' } });
      stack?.pop();
    },
  });
  const {
    watch,
    formState: { isDirty },
  } = useFormContext();

  const validateMaximumMemberValue = useValidMaximumMemberInput({
    watch,
    currentMemberCount: Number(currentMemberCount),
  });

  const isButtonDisabled = useMemo(() => {
    return !isDirty || !validateMaximumMemberValue().isValid;
  }, [isDirty, validateMaximumMemberValue().isValid]);

  const handleSubmit = () => {
    putGroup({
      id: Number(groupId),
      groupModifyForm: { maximumMemberCount: Number(watch('maximumMember')) },
    });
  };

  return (
    <AppBarTextSubmitButton
      disabled={isButtonDisabled}
      onClick={handleSubmit}
      loading={isPending}
    />
  );
};

export default GroupMaxMemberSettingRenderRight;
