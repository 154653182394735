import { isNotSuperHost, useBottomSheet } from '@community-group/components';
import React from 'react';

import { getGroupPath } from '@/api/base/group';
import { useGetCategories } from '@/api/hooks/useGetCategories';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import { useBridge } from '@/contexts/Bridge';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { queryClient } from '@/shared/api/instance';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import { GroupSelectCategoryBottomSheet } from '../components/SettingSection/GroupSelectCategoryBottomSheet';

interface Props {
  groupId: string;
}

const useCheckNeedToSetCategoryBottomSheet = ({ groupId }: Props) => {
  const { open } = useQueryParams();
  const { open: openBottomSheet } = useBottomSheet();
  const { bridge } = useBridge();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { categories } = useGetCategories();

  const { mutate: putGroup } = usePutGroup({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: ({ data }) => {
      queryClient.setQueryData([getGroupPath(String(data.group?.id))], { data: data });
      bridge.openToast({
        toast: {
          body: `수정되었어요.`,
        },
      });
    },
  });

  const { data: group } = useReadGroupDetail(groupId);
  const currentUser = useCurrentGroupMe();

  const checkNeedToSetSubNicknameBottomSheet: () => Promise<boolean> = React.useCallback(() => {
    return new Promise((resolve) => {
      if (isNotSuperHost(currentUser.role)) return resolve(false);
      if (open === 'category') {
        openBottomSheet({
          element: (
            <GroupSelectCategoryBottomSheet
              categoryList={categories}
              selectedCategoryId={group?.category.id ?? 1}
              setSelectedCategoryId={(selectedCategoryId) => {
                putGroup({
                  id: group?.id.toString() ?? '0',
                  groupModifyForm: {
                    categoryId: selectedCategoryId,
                  },
                });
              }}
            />
          ),
        });
        return resolve(true);
      }
      return resolve(false);
    });
  }, [
    currentUser.role,
    open,
    openBottomSheet,
    categories,
    group?.category.id,
    group?.id,
    putGroup,
  ]);

  return checkNeedToSetSubNicknameBottomSheet;
};

export default useCheckNeedToSetCategoryBottomSheet;
