import { Dialog, useDialog, useEffectOnce } from '@community-group/components';
import { startTransition, useState } from 'react';

type Props = {
  shouldRender?: boolean;
  resetForm: () => void;
};
export const useRenderDefaultPostForm = ({ shouldRender = true, resetForm }: Props) => {
  const { open: openDialog, close: closeDialog } = useDialog();
  const [isUseTemporaryPost, setIsUseTemporaryPost] = useState(shouldRender);

  useEffectOnce(() => {
    if (!shouldRender) return;

    startTransition(() => {
      openDialog({
        element: (
          <Dialog
            title="작성 중인 글이 있어요."
            description={`이어서 쓸까요?`}
            secondaryActionIntent="nonpreferred"
            primaryActionLabel="이어서 쓰기"
            secondaryActionLabel="새로 쓰기"
            onPrimaryAction={async () => {
              await closeDialog();
              setIsUseTemporaryPost(true);
            }}
            onSecondaryAction={async () => {
              await closeDialog();
              resetForm();
              setIsUseTemporaryPost(false);
            }}
          />
        ),
      });
    });
  });

  return isUseTemporaryPost;
};
