import { GroupChallengeCreateForm } from '@community-group/api/lib/group/models';
import clsx from 'clsx';
import React, { useLayoutEffect } from 'react';

import { GroupChallengeFormProps } from '../../types';
import * as s from './style.css';

type Props = GroupChallengeFormProps & {
  dateFieldName: keyof GroupChallengeCreateForm; // Update this type
  onClick?: () => void;
  onFocus?: () => void;
  className?: string;
};

const GroupChallengeFormDescriptionField = ({
  formHandler,
  dateFieldName,
  onClick,
  onFocus,
  className,
}: Props) => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    formHandler.setValue('description', event.target.value);
  };

  const onHeightChange = React.useCallback(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      const prevAlignment = input.style.alignSelf;
      const prevOverflow = input.style.overflow;
      const isFirefox = 'MozAppearance' in input.style;
      if (!isFirefox) {
        input.style.overflow = 'hidden';
      }
      input.style.alignSelf = 'start';
      input.style.height = 'auto';
      input.style.height = `${input.scrollHeight + (input.offsetHeight - input.clientHeight)}px`;
      input.style.overflow = prevOverflow;
      input.style.alignSelf = prevAlignment;
    }
  }, [inputRef]);

  const value = formHandler.watch(dateFieldName);

  useLayoutEffect(() => {
    if (inputRef.current) {
      onHeightChange();
    }
  }, [onHeightChange, value, inputRef]);

  return (
    <div id="description-textarea" onClick={onClick}>
      <textarea
        onFocus={onFocus}
        data-field-name={dateFieldName}
        ref={inputRef}
        className={clsx([className, s.Textarea])}
        defaultValue={formHandler.watch(dateFieldName) as string}
        onChange={handleChange}
        placeholder="챌린지 인증 규칙 및 방법을 알려주세요."
      />
    </div>
  );
};

export default GroupChallengeFormDescriptionField;
