import { useMemo } from 'react';

import { useReadGroupPhotos } from '@/domain/GroupImage/hooks/useReadGroupPhotos';

type Props = {
  groupId: string;
  onlyPublic?: boolean;
};

function useGetIsExistHeroSlider({ groupId, onlyPublic }: Props) {
  const { data: photos } = useReadGroupPhotos({ groupId, onlyPublic });
  const isExistHeroSlider = useMemo(() => photos.length > 0, [photos]);

  return isExistHeroSlider;
}

export default useGetIsExistHeroSlider;
