import { getDateDiffFromNow, GroupAvatar, Typography } from '@community-group/components';
import { IconEyeFill, IconPersonShieldFill } from '@daangn/react-monochrome-icon';
import { InlineAlert } from '@daangn/sprout-components-inline-alert';
import { vars } from '@seed-design/design-token';
import { useSuspenseQueries } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { useQueryGroupProfileUserActivities } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserActivities';
import { useQueryGroupProfileUserInfo } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserInfo';

import * as s from './style.css';

type Props = {
  groupId: string;
  userId: string;
};

const GroupProfileMemberManageActivitySummary = ({ groupId, userId }: Props) => {
  const [{ data: profile }, { data: userActivityData }] = useSuspenseQueries({
    queries: [
      useQueryGroupProfileUserInfo({ groupId, userId }),
      useQueryGroupProfileUserActivities({ groupId, userId }),
    ],
  });
  const { count, activities } = userActivityData;
  return (
    <section className={s.Wrapper}>
      <InlineAlert description="모임장, 운영진만 볼 수 있어요." icon={<IconEyeFill size={16} />} />
      <div className={s.Profile}>
        <GroupAvatar size="large" src={profile.profileImage} />
        <div className={s.Description}>
          <Typography typography="title3Bold">{profile.nickname}</Typography>
          <div className={s.VerifiedInfo}>
            <IconPersonShieldFill size={16} color={vars.$scale.color.gray600} />
            <Typography typography="label3Regular" color="gray600">
              {profile.verifiedUserInfoText === '' ? '본인인증 완료' : profile.verifiedUserInfoText}
            </Typography>
          </div>
        </div>
      </div>
      <div className={s.Title}>활동 요약</div>
      <div className={s.ActivitySummary}>
        <div className={s.ActivityRow}>
          <ActivityBox title="가입" value={dayjs(activities.joinedAt).format('YY.MM.DD')} />
          <ActivityBox
            title="최근 방문"
            value={
              activities.lastVisitedAt
                ? `${getDateDiffFromNow(new Date(activities.lastVisitedAt)).text} 전`
                : '-'
            }
          />
          <ActivityBox title="모임 방문" value={String(activities.groupVisitCount)} />
        </div>
        <div className={s.ActivityRow}>
          <ActivityBox title="게시글" value={String(count.createdArticleCount)} />
          <ActivityBox title="댓글" value={String(count.createdCommentCount)} />
          <ActivityBox title="일정 참여" value={String(count.joinedMeetupCount)} />
        </div>
      </div>
    </section>
  );
};

export default GroupProfileMemberManageActivitySummary;

const ActivityBox = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className={s.ActivityBox}>
      <Typography typography="caption1Regular" color="gray600">
        {title}
      </Typography>
      <Typography typography="label2Regular" color="gray900">
        {value}
      </Typography>
    </div>
  );
};
