import { AsyncBoundary } from '@community-group/components';
import { VirtualList } from '@community-group/components/shared';
import { vars } from '@seed-design/design-token';
import { useActivity } from '@stackflow/react';
import React, { useMemo } from 'react';

import { useGetMyGroupPostList } from '@/api/hooks/useGetMyGroupPostList';
import { useScrollRef } from '@/providers/ScrollRefProvider';

import { MemoizingMyFeedItem } from '../MyFeedItem';

export const MyFeedListWrapper = () => {
  return (
    <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
      <MyFeedList />
    </AsyncBoundary>
  );
};

const MyFeedList = () => {
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useGetMyGroupPostList({});
  const { scrollRef } = useScrollRef();
  const { isTop } = useActivity();
  const flattenedData = useMemo(() => data.pages.flatMap(({ data }) => data.posts) ?? [], [data]);

  return (
    <div
      style={{
        backgroundColor: vars.$semantic.color.paperDefault,
        paddingBottom: 'calc(5rem + env(safe-area-inset-bottom))',
        overflowX: 'hidden',
      }}
    >
      <VirtualList
        estimateSize={500}
        overscan={10}
        isStackTop={isTop}
        scrollRef={scrollRef}
        items={flattenedData}
        render={({ item, index, isLast }) => (
          <MemoizingMyFeedItem post={item} idx={index} isLastItem={isLast} />
        )}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  );
};

export default React.memo(MyFeedList);
