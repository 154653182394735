import { GroupMeetupCreateForm } from '@community-group/api/lib/group/models';
import {
  Divider,
  Render,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import isEmpty from 'lodash-es/isEmpty';
import { useEffect, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { usePostGroupMeetup } from '@/api/hooks/usePostGroupMeetup';
import { getCurrentRunningGrowthStep } from '@/components/group/Detail/components/RunningGrowth/utils/curerntLevel';
import { GroupMeetupFormChatCreateField } from '@/components/group/Meetup/Form/components/Field/ChatCreate';
import { GroupMeetupFormExposureRangeField } from '@/components/group/Meetup/Form/components/Field/ExposureRange';
import { GroupMeetupJoinMemberGradeField } from '@/components/group/Meetup/Form/components/Field/JoinMemberGrade';
import { GroupMeetupFormPublishedField } from '@/components/group/Meetup/Form/components/Field/Published';
import { openListItemFormFieldQuerySelector } from '@/components/group/Meetup/Form/utils/selector';
import { FROM_QUERY_PARAMS } from '@/constants/path';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import {
  useQueryGroupMe,
  useQueryNullableGroupMe,
} from '@/domain/GroupDetail/hooks/useReadGroupMe';
import {
  useQueryGroupMemberGradeStatus,
  useQueryNullableGroupMemberGradeStatus,
} from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { usePostSeoulRunMeetup } from '@/domain/SeoulRunPromotion/hook/usePostSeoulRunMeetup';
import { SEOUL_RUN_PROMOTION_CONFIG } from '@/features/SeoulRunPromotion/config';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { ActivityQueryParams, useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { meetupCreateFormSchema } from '@/utils/validate/formSchema/meetup';
import {
  convertHookFormErrorObjectToArray,
  validateSchemaWithBoolean,
} from '@/utils/validate/util';

import * as s from './Options.css';

export type GroupMeetupNewOptionsPageParams = Pick<
  PageParams,
  'meetupCreateForm' | 'from' | 'calloutType'
> &
  Pick<ActivityQueryParams, 'groupId'>;

const SeoulRunMeetupNewOptionsPage: ActivityComponentType<GroupMeetupNewOptionsPageParams> = () => {
  const { replace, pop, push } = useFlow();
  const { bridge } = useBridge();

  const { meetupCreateForm, from, refer_root, calloutType, groupId } = useQueryParams();

  const {
    userConfig: { userId },
  } = useUserConfig();

  const hookForm = useForm<GroupMeetupCreateForm>({
    resolver: zodResolver(meetupCreateFormSchema),
  });
  const { handleSubmit: handleFormSubmit, watch } = hookForm;

  // 초기값 셋팅
  useEffect(() => {
    try {
      if (meetupCreateForm) {
        const parsedMeetupCreateForm = JSON.parse(meetupCreateForm);

        if (validateSchemaWithBoolean(meetupCreateFormSchema, parsedMeetupCreateForm)) {
          return hookForm.reset(parsedMeetupCreateForm);
        }
      }

      replace('SeoulRunMeetupNewPage', { groupId: groupId ?? '' }, { animate: false });
    } catch {
      replace('SeoulRunMeetupNewPage', { groupId: groupId ?? '' }, { animate: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupCreateForm]);

  const handleErrorWithToast = useHandleErrorWithToast();

  const { mutate: mutateSeoulRunMeetup, isPending: isSeoulRunMeetupPending } =
    usePostSeoulRunMeetup({
      onError: handleErrorWithToast,
      onSuccess: ({ data: { groupId, meetupId } }) => {
        const eventParams = {
          groupMeetupCreationCycle: watch('creationCycle'),
          // TODO: 일정채팅방 개선으로 인해 잠시 true로 변경
          // https://daangn.slack.com/archives/C06D8034RUK/p1708649282388759
          createMeetupChatRoom: true,
          hasPoi: !!watch('poi'),
          referrer: from,
          refer_root,
          from,
          calloutType,
          currentRunningGrowthMission: getCurrentRunningGrowthStep(calloutType),
          userId: (userId ?? 0).toString(),
          canJoinAnyMember: watch('canJoinAnyMember'),
        };
        // TODO: 로깅 데이터 추가 필요

        pop(2);
        setTimeout(() => {
          replace('GroupMeetupDetailPage', {
            groupId: groupId.toString(),
            meetupId: meetupId.toString(),
            from: FROM_QUERY_PARAMS.CREATE_GROUP_MEETUP,
          });
          push('BottomSheet/CreatedSeoulRunMeetupGroupBottomSheet', {});
        }, 250);
      },
    });

  const { mutate: mutateGroupMeetup, isPending: isGroupMeetupPending } = usePostGroupMeetup({
    onError: handleErrorWithToast,
    onSuccess: ({ data: { groupId, meetupId } }) => {
      const eventParams = {
        groupId,
        groupMeetupCreationCycle: watch('creationCycle'),
        // TODO: 일정채팅방 개선으로 인해 잠시 true로 변경
        // https://daangn.slack.com/archives/C06D8034RUK/p1708649282388759
        createMeetupChatRoom: true,
        hasPoi: !!watch('poi'),
        referrer: from,
        refer_root,
        from,
        calloutType,
        currentRunningGrowthMission: getCurrentRunningGrowthStep(calloutType),
        userId: (userId ?? 0).toString(),
        canJoinAnyMember: watch('canJoinAnyMember'),
      };
      trackEvent({ event: 'click_group_meetup_post', params: eventParams });
      trackEvent({
        event: 'click_create_meetup_post',
        params: eventParams,
        loggerType: ['APPSFLYER'],
      });

      // 채팅방에서 일정 생성시 창 닫기
      if (from === 'chat') {
        bridge.closeRouter({});
        return;
      }

      // 500ms 딜레이를 주는 이유 참고: https://daangn.slack.com/archives/C05354CCEBA/p1690868586993619?thread_ts=1690789131.754829&cid=C05354CCEBA
      setTimeout(() => {
        refetchGroupDetail({ groupId: groupId.toString() });
      }, 500);

      pop(2);
      setTimeout(() => {
        replace('GroupMeetupDetailPage', {
          groupId: groupId.toString(),
          meetupId: meetupId.toString(),
          from: FROM_QUERY_PARAMS.CREATE_GROUP_MEETUP,
        });
      }, 250);
    },
  });

  const handleSubmit = () => {
    // 이미 모임이 존재하는 경우
    handleFormSubmit(
      (data) => {
        // 모임&일정 즉시생성 플로우인 경우
        if (!groupId) {
          mutateSeoulRunMeetup({
            body: {
              ...data,
              isPublished: data.exposureRange === 'GROUP_MEMBER' ? false : data.isPublished,
              eventType: SEOUL_RUN_PROMOTION_CONFIG.EVENT_TYPE,
            },
          });
          return;
        }

        mutateGroupMeetup({
          groupId: parseInt(groupId),
          meetupCreateForm: {
            ...data,
            isPublished: data.exposureRange === 'GROUP_MEMBER' ? false : data.isPublished,
            eventType: SEOUL_RUN_PROMOTION_CONFIG.EVENT_TYPE,
          },
        });
      },
      (errors) => {
        const errorList = convertHookFormErrorObjectToArray(errors);
        const firstError = errorList[0];
        bridge.openToast({
          toast: {
            body: firstError.value.message ?? '',
          },
        });
      }
    )();
  };

  const isPending = isSeoulRunMeetupPending || isGroupMeetupPending;
  const isLoading = isSeoulRunMeetupPending || isGroupMeetupPending;

  return (
    <AppScreen
      accessoryBar={
        <div className={s.AccessoryBarWrapper}>
          <BoxButton
            isLoading={isPending || isLoading}
            size="xlarge"
            width="100%"
            onClick={handleSubmit}
          >
            일정 만들기
          </BoxButton>
        </div>
      }
    >
      <form>
        {isEmpty(hookForm.getValues()) ? (
          <></>
        ) : (
          <GroupMeetupNewOptionsView hookForm={hookForm} groupId={groupId} />
        )}
      </form>
    </AppScreen>
  );
};

export default withAsyncBoundary(SeoulRunMeetupNewOptionsPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});

type Props = {
  groupId?: string;
  hookForm: UseFormReturn<GroupMeetupCreateForm>;
};

const GroupMeetupNewOptionsView = withAsyncBoundary(
  ({ groupId, hookForm: { watch, setValue } }: Props) => {
    const [{ data: me }, { data: memberGradeStatus }] = useSuspenseQueries({
      queries: [
        useQueryNullableGroupMe({ groupId }),
        useQueryNullableGroupMemberGradeStatus(groupId),
      ],
    });

    const memberGradeEnabled = useMemo(() => {
      return memberGradeStatus === 'system' && me?.grade.name !== '준회원';
    }, [memberGradeStatus, me]);

    useEffect(() => {
      openListItemFormFieldQuerySelector(memberGradeEnabled ? 'canJoinAnyMember' : 'exposureRange');
    }, [memberGradeEnabled]);

    return (
      <section className={s.Wrapper}>
        <Typography as="h2" typography="title1Bold" color="gray900" style={{ padding: '1rem 0' }}>
          옵션을 선택해주세요
        </Typography>
        <Render condition={memberGradeEnabled}>
          <GroupMeetupJoinMemberGradeField
            name="canJoinAnyMember"
            watch={watch}
            setValue={setValue}
          />
          <Divider />
        </Render>
        <GroupMeetupFormExposureRangeField name="exposureRange" watch={watch} setValue={setValue} />
        <Divider />
        <GroupMeetupFormChatCreateField
          name="withCreateChatRoom"
          watch={watch}
          setValue={setValue}
          isDisabled
        />
        <Divider />
        <Render condition={watch('exposureRange') === 'ALL'}>
          <GroupMeetupFormPublishedField name="isPublished" watch={watch} setValue={setValue} />
          <Divider />
        </Render>
      </section>
    );
  },
  {}
);
