import {
  UserProfileSnsTagPresentation,
  UserProfileSnsTagPresentationSnsType,
} from '@community-group/api/lib/group/models';
import { useBottomSheet } from '@community-group/components';
import { useCallback } from 'react';

import { usePutGroupProfile } from '@/api/hooks/usePutGroupProfile';
import SnsTextButton from '@/domain/GroupProfile/components/SnsTextButton';
import SnsActionSheet from '@/features/GroupProfile/SnsTagSection/SnsActionSheet';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupProfile } from '@/utils/refetch/groupGroupProfile';

import * as s from './style.css';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

type Props = {
  snsTags: UserProfileSnsTagPresentation[];
  nickname: string;
};

const SnsTagMySection = ({ snsTags, nickname }: Props) => {
  const { push } = useFlow();
  const { open: openBottomSheet, closeAsync, close: closeBottomSheet } = useBottomSheet();

  const { open: openSnackbar } = useSnackbar();
  const handleErrorWithToast = useHandleErrorWithToast();

  //TODO: usePutGroupProfile useThrottleMutation 적용
  const { mutate: putMutate } = usePutGroupProfile({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchGroupProfile();
      openSnackbar({
        message: '소셜 계정을 삭제했어요.',
      });
    },
  });

  const handleDeleteSnsTag = useCallback(
    (snsId: string, snsType: string) => {
      const filtered = snsTags.filter((snsTag) => snsTag.snsId !== snsId);
      trackEvent({
        event: 'click_delete_sns_tag',
        params: {
          snsTagId: snsId,
          snsTagType: snsType,
        },
      });
      putMutate({
        groupMainProfileModifyForm: {
          nickname,
          snsTags: filtered,
        },
      });
    },
    [nickname, snsTags, putMutate]
  );

  const handlePushSnsTagSetBottomSheet = () => {
    push('BottomSheet/SnsTagSetBottomSheet', {});
  };

  const onClickEditSnsTag = useCallback(
    async (snsType: UserProfileSnsTagPresentationSnsType) => {
      await closeAsync();
      push('BottomSheet/SnsTagSetBottomSheet', {
        snsSetBottomSheetType: 'edit',
        snsSetBottomInitialToggle: snsType,
      });
    },
    [closeAsync, push]
  );

  const handleOnClickSnsTag = ({
    snsId,
    snsType,
  }: {
    snsId: string;
    snsType: UserProfileSnsTagPresentationSnsType;
  }) => {
    openBottomSheet({
      element: (
        <SnsActionSheet
          snsId={snsId}
          snsType={snsType}
          onClickEditSnsTag={() => onClickEditSnsTag(snsType)}
          onClickDeleteSnsTag={async () => {
            await closeAsync();
            handleDeleteSnsTag(snsId, snsType);
          }}
        />
      ),
      onDimClose: closeBottomSheet,
    });
  };

  return (
    <div className={s.Wrapper}>
      {snsTags.map((snsTag) => (
        <SnsTextButton
          key={snsTag.snsId}
          name={snsTag.snsId}
          iconType={snsTag.snsType}
          onClick={() => {
            handleOnClickSnsTag({
              snsId: snsTag.snsId,
              snsType: snsTag.snsType,
            });
          }}
        />
      ))}
      <SnsTextButton name="소셜 계정 추가" tagType="new" onClick={handlePushSnsTagSetBottomSheet} />
    </div>
  );
};

export default SnsTagMySection;
