import { useSnackbarAdapter } from '@community-group/components';

export const _useSnackbar = () => {
  const snackbar = useSnackbarAdapter();

  const close = () => {
    snackbar.dismiss();
  };

  const open = ({
    message,
    type = 'default',
    timeout = 3000,
    onClick,
  }: Partial<Parameters<typeof snackbar.create>[0]>) => {
    snackbar.create({
      message,
      type,
      timeout,
      onClick: () => {
        if (onClick) {
          onClick();
          return;
        }
        close();
      },
    });
  };

  return {
    open,
    close,
  };
};
