import { isHigherManager, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { useMemo } from 'react';

import { FloatingIcon } from '@/components/common/FloatingIcon';
import { isMissionAchieved } from '@/components/common/GroupLevelBanner/guard';
import { useBridge } from '@/contexts/Bridge';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useReadGroupLevel } from '@/domain/GroupLevel/hooks/useReadGroupLevel';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { type GROUP_DETAIL_ACTIVE_TAB, useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import FixedBottomSection from '../../Detail/components/FixedBottomSection';

type Props = {
  activeTabKey: GROUP_DETAIL_ACTIVE_TAB;
  hideText?: boolean;
};

const GroupDetailFloatingButton = ({ activeTabKey, hideText }: Props) => {
  const { groupId = '' } = usePathParams();
  const { from } = useQueryParams();

  const { push } = useFlow();
  const { bridge } = useBridge();

  const { data: currentUserData } = useReadGroupMe({ groupId });
  const { data: group } = useReadGroupDetail(groupId);
  const { data: levelData } = useReadGroupLevel(groupId);
  // current level이 0이고, 첫 포스트를 작성하지 않았고, 첫 포스트 미션이 존재하는 경우 레벨업 버튼 보여줌
  const isInitialPublishPost = useMemo(() => {
    const isHigherManagerUser = isHigherManager(currentUserData.role);

    return (
      isHigherManagerUser &&
      levelData.currentLevel === 0 &&
      levelData.forLevelUp.missions.writeFirstPost &&
      !isMissionAchieved(levelData.forLevelUp.missions.writeFirstPost)
    );
  }, [currentUserData.role, levelData.currentLevel, levelData.forLevelUp.missions.writeFirstPost]);

  if (activeTabKey === 'meetups') return <></>;
  if (activeTabKey === 'talks') {
    if (!currentUserData.role) {
      return <FixedBottomSection />;
    }
    return null;
  }

  return (
    <FloatingIcon
      type="POST"
      hideText={hideText}
      isInitialPublishPost={isInitialPublishPost}
      onClick={async () => {
        bridge.setHapticSelect({});
        trackEvent({
          event: 'click_write_fab',
          params: {
            from,
            groupId: group?.id.toString(),
            groupCategoryName: group?.category?.name,
            activeTabKey,
          },
          sample: true,
        });

        push('GroupPostNewPage', {
          groupId: group?.id.toString() ?? '',
          initialPublishPost: isInitialPublishPost ? 'true' : 'false',
        });
      }}
    />
  );
};

export default withAsyncBoundary(GroupDetailFloatingButton, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
