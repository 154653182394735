import {
  GroupMemberGradeRequirementPresentation,
  GroupMemberGradeRequirementUpdateForm,
  GroupMemberGradeStatusAndRequirementsUpdateForm,
} from '@community-group/api/lib/group/models';
import {
  ActionableCallout,
  Callout,
  CalloutDescription,
  Dialog,
  Divider,
  Render,
  Spacing,
  Switch,
  Typography,
  useDialog,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconChevronLeftLine, IconQuestionmarkCircleLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType, useStack } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { cloneDeep, isEqual } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { usePutGroupMemberGradeStatusAndRequirements } from '@/domain/GroupMember/hooks/usePutGroupMemberGradeStatusAndRequirements';
import { useQueryGroupMemberGradeRequirements } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeRequirements';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { convertMemberGradeRequirementsToAnalyticsParams } from '@/domain/GroupMember/utils/memberGradeRequirements';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useSubscribeAndroidBackButtonPress } from '@/shared/hooks/useSubscribeAndroidBackButtonPress';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { openMemberGradeFAQ, openMemberGradeSurvey } from '@/utils/link';

import SettingMemberGradeRequirements from '../components/SettingMemberGrade/SettingMemberGradeRequirements';
import * as s from './GroupSettingMemberGradePage.css';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

type Params = Pick<PageParams, 'groupId' | 'from'>;

const GroupSettingMemberGradePage: ActivityComponentType<Params> = () => {
  const { groupId } = usePathParams();
  const { from } = useQueryParams();
  const { activities } = useStack();
  const { push, pop } = useFlow();
  const { open: openDialog, close: closeDialog } = useDialog();

  useEnterTrackEvent({
    event: 'enter_member_grade_setting',
    params: {
      groupId,
      from,
    },
  });

  const [
    { data: group },
    { data: statusData, refetch: refetchMemberGradeStatus },
    { data: requirementsData, refetch: refetchMemberGradeRequirements },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupDetail(groupId),
      useQueryGroupMemberGradeStatus(groupId),
      useQueryGroupMemberGradeRequirements(groupId),
    ],
  });

  const [memberGradeForm, setMemberGradeForm] =
    useState<GroupMemberGradeStatusAndRequirementsUpdateForm>({
      status: statusData,
      requirements: cloneDeep(requirementsData),
    });
  const memberGradeFormEnabled = useMemo(
    () => memberGradeForm.status === 'system',
    [memberGradeForm.status]
  );

  const { open: openSnackbar } = useSnackbar();
  const { mutateAsync: putGroupMemberGradeStatusAndRequirements } =
    usePutGroupMemberGradeStatusAndRequirements({
      onSuccess: () => {
        refetchMemberGradeStatus();
        refetchMemberGradeRequirements();
      },
    });

  const backButtonEnabled = useRef(false);
  useEffect(() => {
    const isNotChanged = isEqual(memberGradeForm, {
      status: statusData,
      requirements: requirementsData,
    });
    const isCurrentPageTop = activities
      .filter((activity) => activity.isActive)
      .find((activity) => activity.name === 'GroupSettingMemberGradePage')?.isTop;

    // 변경된 내용이 없을 때(isNotChanged) 뒤로 가기 버튼이 정상 enabled = true
    // 멤버 등급제가 꺼진 상태 일 때(!memberGradeEnabled) 뒤로가기 버튼이 정상 enabled = true
    // 현재 페이지를 덮은 페이지가 있는 경우(!isCurrentPageTop) 뒤로 가기 버튼이 정상 enabled = true
    // 하나라도 참이면 뒤로 가기 버튼이 정상 enabled = true
    backButtonEnabled.current = isNotChanged || !memberGradeFormEnabled || !isCurrentPageTop;
  }, [memberGradeFormEnabled, memberGradeForm, requirementsData, statusData, activities]);

  const submit = async () => {
    const noRequirementGoalCount = memberGradeForm.requirements.every((r) => r.goalCount === 0);
    if (noRequirementGoalCount) {
      openDialog({
        element: (
          <Dialog
            title="등업 조건을 설정해주세요"
            description="등업 조건이 1개라도 설정되어야 멤버 등급을 사용할 수 있어요."
            primaryActionLabel="설정하기"
            secondaryActionLabel="나가기"
            onPrimaryAction={closeDialog}
            onSecondaryAction={() => {
              closeDialog();
              pop();
            }}
          />
        ),
      });
      return;
    }

    openDialog({
      element: (
        <Dialog
          title="멤버 등급을 사용할까요?"
          description="확인 버튼을 누르면 등업 조건을 만족한 멤버들에게 알림이 보내져요."
          primaryActionLabel="확인"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            const params = convertMemberGradeRequirementsToAnalyticsParams(
              memberGradeForm.requirements
            );
            trackEvent({
              event: 'click_activate_member_grade',
              params: {
                groupId,
                groupName: group.name,
                categoryId: group.category.id,
                categoryName: group.category.name,
                visitGroupCount: params.visit_group,
                createPostCount: params.create_post,
                createCommentCount: params.create_comment,
                joinMeetupCount: params.join_meetup,
              },
              sample: true,
            });

            closeDialog();

            await putGroupMemberGradeStatusAndRequirements({
              groupId,
              ...memberGradeForm,
            });
            pop();

            openSnackbar({
              message: '멤버 등급을 설정했어요.',
            })
          }}
          onSecondaryAction={closeDialog}
        />
      ),
    });
  };

  const handleBackButtonClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();

    if (backButtonEnabled.current) {
      pop();
      return;
    }

    submit();
  };

  const handleMemberGradeChange = async () => {
    if (memberGradeFormEnabled) {
      const defaultRequirements = [
        { activity: 'visit_group', goalCount: 0 },
        { activity: 'create_post', goalCount: 0 },
        { activity: 'create_comment', goalCount: 0 },
        { activity: 'join_meetup', goalCount: 0 },
      ] as GroupMemberGradeRequirementUpdateForm[];

      /** @note 상태가 서버에 저장된 경우에만 다이얼로그를 노출해요 */
      if (statusData === 'system') {
        openDialog({
          element: (
            <Dialog
              title="정말 멤버 등급을 그만 사용할까요?"
              description="멤버 등급과 관련된 모든 정보가 사라지고 다시 되돌릴 수 없어요."
              primaryActionLabel="확인"
              secondaryActionLabel="취소"
              onPrimaryAction={async () => {
                trackEvent({
                  event: 'click_deactivate_member_grade',
                  params: {
                    groupId,
                    groupName: group.name,
                    categoryId: group.category.id,
                    categoryName: group.category.name,
                  },
                  sample: true,
                });

                closeDialog();

                await putGroupMemberGradeStatusAndRequirements({
                  groupId,
                  status: 'inactive',
                  requirements: defaultRequirements,
                });
                setMemberGradeForm({
                  status: 'inactive',
                  requirements: defaultRequirements,
                });
                openSnackbar({
                  message: '멤버 등급을 더 이상 사용하지 않아요.',
                })
              }}
              onSecondaryAction={closeDialog}
            />
          ),
        });
      } else {
        setMemberGradeForm({
          status: 'inactive',
          requirements: defaultRequirements,
        });
      }
      return;
    }

    setMemberGradeForm((prev) => ({
      ...prev,
      status: 'system',
    }));
  };

  const handleMemberPermissionSettingClick = () => {
    push('GroupSettingPermissionPage', {
      groupId,
    });
  };

  useSubscribeAndroidBackButtonPress({
    pressHandler: handleBackButtonClick,
    enabled: false,
  });

  return (
    <AppScreen
      appBar={{
        title: '멤버 등급 관리',
        borderColor: vars.$semantic.color.divider3,
        borderSize: '0.5px',
        closeButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: handleBackButtonClick,
        },
        backButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: handleBackButtonClick,
        },
        renderRight: () => (
          <button onClick={openMemberGradeFAQ} style={{ marginRight: '0.5rem' }}>
            <IconQuestionmarkCircleLine size={24} color={vars.$scale.color.gray900} />
          </button>
        ),
      }}
      preventSwipeBack={true}
    >
      <SettingList.Wrapper>
        <SettingList.Item
          title="멤버 등급 사용"
          renderRight={
            <Switch onChange={handleMemberGradeChange} isSelected={memberGradeFormEnabled} />
          }
        />
      </SettingList.Wrapper>
      <Render condition={memberGradeFormEnabled}>
        <Divider />
        <SettingMemberGradeRequirements
          requirements={memberGradeForm.requirements}
          setRequirements={(requirement: GroupMemberGradeRequirementPresentation) => {
            const next = [...memberGradeForm.requirements];
            const target = next.find((r) => r.activity === requirement.activity);
            if (!target) return;

            target.goalCount = requirement.goalCount;
            setMemberGradeForm((prev) => ({
              ...prev,
              requirements: next,
            }));
          }}
        />
      </Render>
      <Divider />
      <Spacing size={16} />
      <div className={s.CalloutContainer}>
        <div className={s.Label}>
          <Typography typography="label5Regular" color="blue800" className={s.Badge}>
            TIP
          </Typography>
          <Typography typography="bodyL2Regular" color="blue900">
            멤버 등급을 사용하면 어떤 걸 할 수 있나요?
          </Typography>
        </div>
        <Spacing size={12} />
        <Callout width="100%">
          <CalloutDescription>
            <ul>
              <li className={s.CalloutContentsItem}>
                <Typography typography="bodyM2Regular" color="gray900">
                  정회원만 참여할 수 있는 일정을 만들 수 있어요.
                </Typography>
              </li>
              <li className={s.CalloutContentsItem}>
                <Typography typography="bodyM2Regular" color="gray900">
                  정회원부터 일정을 만들 수 있도록 설정할 수 있어요.
                </Typography>
              </li>
            </ul>
            <button onClick={handleMemberPermissionSettingClick}>
              <Typography typography="bodyM2Regular" color="gray600" className={s.CalloutLink}>
                설정하러 가기
              </Typography>
            </button>
          </CalloutDescription>
        </Callout>
      </div>
      <Spacing size={16} />
      <div className={s.CalloutWrapper}>
        <ActionableCallout
          variant="outline"
          width="100%"
          UNSAFE_style={{ backgroundColor: vars.$semantic.color.paperDefault }}
          onClick={openMemberGradeSurvey}
        >
          <Typography typography="bodyM2Regular" color="gray900">
            <b>설문</b> 멤버 등급에 대한 의견을 들려주세요.
          </Typography>
        </ActionableCallout>
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingMemberGradePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
