import { Typography } from '@community-group/components';
import { IconGiftFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { openLink } from '@/utils/link';

import { SEOUL_RUN_PROMOTION_CONFIG } from '../config';
import * as s from './SeoulRunMeetupDetailBreadcrumb.css';

const SeoulRunMeetupDetailBreadcrumb = () => {
  const handleClick = () => {
    openLink(SEOUL_RUN_PROMOTION_CONFIG.LANDKIT_PAGE);
  };
  return (
    <div className={s.Container} onClick={handleClick}>
      <IconGiftFill size={15} color={vars.$scale.color.blue500} />
      <Typography as="caption" typography="caption1Regular" color="blue900">
        한강 보물찾기런 이벤트
      </Typography>
    </div>
  );
};

export default SeoulRunMeetupDetailBreadcrumb;
