import { isArray } from 'lodash-es';

import { ORDER_SEARCH } from '@/api/base/group';
import { SearchFilterParams } from '@/api/hooks/useGetSearchGroupList';

// 필터가 설정되어 있는지 확인
export const isSetFilter = (searchFilter: SearchFilterParams) => {
  return Boolean(
    (isArray(searchFilter.category) && searchFilter.category?.length > 0) ||
      searchFilter.regionRange ||
      searchFilter.minAge !== undefined ||
      searchFilter.maxAge !== undefined ||
      searchFilter.order !== ORDER_SEARCH.RECOMMEND_SCORE_DESC
  );
};
