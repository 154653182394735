import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { IconPerson2Fill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import GroupDetailMainBannerCore from './GroupDetailMainBannerCore';
import * as s from './GroupDetailMainBannerCore.css';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
  joinApplicationCount: number;
  memberGradeApplicationCount: number;
};

const GroupDetailWaitingMemberBanner = ({
  group,
  currentUser,
  joinApplicationCount,
  memberGradeApplicationCount,
}: Props) => {
  const { push } = useFlow();

  const groupId = useMemo(() => group.id.toString(), [group.id]);
  const memberCount = useMemo(
    () => joinApplicationCount + memberGradeApplicationCount,
    [joinApplicationCount, memberGradeApplicationCount]
  );
  const application = useMemo(() => {
    if (joinApplicationCount > 0 && memberGradeApplicationCount > 0) {
      return {
        text: '가입 또는 등업',
        tab: 'pending' as const,
      };
    }

    if (memberGradeApplicationCount > 0) {
      return {
        text: '등업',
        tab: 'memberGradeApplication' as const,
      };
    }

    return {
      text: '가입',
      tab: 'pending' as const,
    };
  }, [joinApplicationCount, memberGradeApplicationCount]);

  const handleClick = () => {
    trackEvent({
      event: 'click_waiting_member_callout',
      params: {
        groupId: groupId,
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUser.role,
        version: '2',
      },
      sample: true,
    });
    push('GroupMemberListForHostPage', {
      groupId,
      tab: application.tab,
    });
  };

  return (
    <GroupDetailMainBannerCore
      title={`${application.text} 요청 확인하기`}
      description={`${memberCount}명의 이웃이 승인을 기다려요`}
      categoryIcon={
        <div className={s.IconWrapper({ color: 'carrot50' })}>
          <IconPerson2Fill size={20} color={vars.$scale.color.carrot500} />
        </div>
      }
      onClick={handleClick}
    />
  );
};

export default GroupDetailWaitingMemberBanner;
