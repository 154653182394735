import { match } from 'ts-pattern';

import { UploadVideoValidationError } from '../hooks/useVideoValidation';
import { FileUploadState, VideoUploadErrorType } from '../types/Video';

export interface ValidationConfig {
  maxVideoCount: number;
  maxVideoSizeMB: number;
  maxVideoDurationMinutes: number;
  allowedFileTypesRegex: string;
}

export const DEFAULT_VALIDATION_CONFIG = {
  maxVideoCount: 10, // 10개 - 초과시 초과되는 파일부터 invalid
  maxVideoSizeMB: 1024, // 1GB
  maxVideoDurationMinutes: 10, // 10 minutes
  allowedFileTypesRegex: '^video/(3gpp|3gp2|3gpp2|mpeg|mp4|x-m4v|quicktime|webm)',
};

export const validationVideoFile = async (
  file: File,
  config: ValidationConfig,
  temporalSentryLogger?: (error: any) => void
): Promise<Omit<UploadVideoValidationError, 'file'> | undefined> => {
  if (!file) {
    return {
      type: 'undefinedFile',
      message: {
        title: '첨부 에러',
        description: '파일이 존재하지 않아요.',
      },
    };
  }

  if (!validateVideoFileType(file.type, config.allowedFileTypesRegex)) {
    return {
      type: 'fileFormat',
      message: {
        title: '첨부 에러',
        description: '지원하지 않는 포맷이에요.',
      },
    };
  }

  if (byteToMegaByte(file.size) >= config.maxVideoSizeMB) {
    return {
      type: 'fileSize',
      message: {
        title: '최대 첨부 용량 초과',
        description: '동영상은 10분 이내, 1기가 이하의 파일만 첨부할 수 있어요.',
      },
    };
  }
  const duration = await getVideoDuration(file, temporalSentryLogger);

  if (duration && duration > config.maxVideoDurationMinutes * 60) {
    return {
      type: 'videoDuration',
      message: {
        title: '최대 첨부 용량 초과',
        description: '동영상은 10분 이내, 1기가 이하의 파일만 첨부할 수 있어요.',
      },
    };
  }
  if (duration === null) {
    return {
      type: 'videoDurationError',
      message: {
        title: '동영상 첨부 실패',
        description: '동영상 첨부에 실패했어요. 잠시후 다시 시도해주세요.',
      },
    };
  }

  return undefined;
};

const validateVideoFileType = (type: string, allowedFileTypesRegex: string): boolean => {
  return new RegExp(allowedFileTypesRegex).test(type);
};

const byteToMegaByte = (bytes: number): number => {
  return bytes / (1024 * 1024);
};

export const getVideoDuration = (file: File, temporalSentryLogger?: (error: any) => void) => {
  return new Promise<number | null>((resolve, reject) => {
    try {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.onerror = function (e) {
        window.URL.revokeObjectURL(video.src);

        const canPlayMp4H264 = video.canPlayType('video/mp4; codecs="avc1.42E01E, mp4a.40.2"');

        // MediaError 객체(코덱 문제 등)가 들어있을 수도 있고, 기기에 따라 비어있을 수도 있음
        const mediaError = video.error;
        const errorInfo: Record<string, any> = {
          event: e,
          canPlayMp4H264, // 참고용
        };

        // MediaError가 존재하면 code와 message를 로깅
        if (mediaError) {
          errorInfo.mediaErrorCode = mediaError.code; // 1, 2, 3, 4
          errorInfo.mediaErrorMessage = mediaError.message;

          // code 값에 따라 추정 원인 추가 (명세상 1~4)
          match(mediaError.code)
            .with(MediaError.MEDIA_ERR_ABORTED, () => {
              errorInfo.possibleReason = '사용자 혹은 브라우저가 재생을 중단했습니다.';
            })
            .with(MediaError.MEDIA_ERR_NETWORK, () => {
              errorInfo.possibleReason = '네트워크 오류로 인해 동영상을 불러오지 못했습니다.';
            })
            .with(MediaError.MEDIA_ERR_DECODE, () => {
              errorInfo.possibleReason =
                '디코딩(코덱) 오류일 가능성이 큽니다. 브라우저가 코덱을 지원하지 않거나 파일이 손상되었을 수 있습니다.';
            })
            .with(MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED, () => {
              errorInfo.possibleReason =
                '지원하지 않는 동영상 포맷/코덱이거나, 파일이 손상된 경우일 수 있습니다.';
            })
            .otherwise(() => {
              errorInfo.possibleReason = '알 수 없는 오류가 발생했습니다.';
            });
        } else {
          errorInfo.possibleReason =
            '브라우저가 명시적인 에러 정보를 제공하지 않음 (video.error가 없음)';
        }

        if (temporalSentryLogger) {
          temporalSentryLogger(errorInfo);
        }
        resolve(null);
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });
};

export const isExistUploadingVideo = (videos: FileUploadState[]): boolean => {
  return videos.some((video) => video.status === 'uploading');
};

export const isExistFailedVideo = (videos: FileUploadState[]): boolean => {
  return videos.some((video) => video.status === 'failed');
};

export const validateUploadingVideos = (videos: FileUploadState[]) => {
  if (isExistUploadingVideo(videos)) {
    return { type: 'uploading', message: '동영상 업로드 중이에요. 잠시만 기다려주세요.' };
  }

  if (isExistFailedVideo(videos)) {
    return { type: 'uploadFailed', message: '동영상 업로드에 실패했어요. 다시 시도해주세요.' };
  }

  return undefined;
};
