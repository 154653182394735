import { isSuperHost, Spacing } from '@community-group/components';
import { IconCheckmarkLine, IconPlusLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';

import { useGetBoardTypeList } from '@/api/hooks/useGetBoardTypeList';
import { NotIncludeBackButtonControlModal } from '@/components/common/Modal/NotIncludeBackButtonControlModal';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useFlow } from '@/stackflow';
import { useStore } from '@/store';
import { MODAL_KEY } from '@/store/modal/modalSliceStore';
import { trackEvent } from '@/utils/analytics';

import * as s from './SelectBoardTypeBottomSheet.css';

interface Props {
  groupId?: string;
  selectedCategory: number;
  setSelectedCategory: (sorting: number) => void;
}

const SelectBoardTypeBottomSheet = ({ selectedCategory, setSelectedCategory, groupId }: Props) => {
  const { data } = useGetBoardTypeList(Number(groupId));
  const currentUser = useCurrentGroupMe();
  const superHost = isSuperHost(currentUser?.role);
  const { push } = useFlow();
  const { setOpenWebviewModal } = useStore();
  const handleClick = (value: number) => {
    setSelectedCategory(value);
  };

  const handleCreateBoard = () => {
    trackEvent({
      event: 'click_board_category_add',
      params: {
        groupId: groupId ?? '',
        from: 'group_detail_feed',
      },
    });
    setOpenWebviewModal(MODAL_KEY.SELECT_BOARD_TYPE_BOTTOM_SHEET, false);
    push('BoardCategorySetting', {
      groupId: groupId ?? '',
    });
  };

  if (!data) return <></>;

  return (
    <NotIncludeBackButtonControlModal
      type={MODAL_KEY.SELECT_BOARD_TYPE_BOTTOM_SHEET}
      innerPaddingBottom={0}
    >
      <div className={s.Wrapper}>
        <h3 className={s.Title}>게시판을 선택해주세요</h3>
        <Spacing size={16} />
        <ul className={s.SortingList}>
          {(data.boardCategories ?? []).map((item) => (
            <li
              className={s.boardTypeItem({ selected: item.id === selectedCategory })}
              key={item.category}
              onClick={() => {
                handleClick(item.id);
              }}
            >
              {item.category}
              {item.id === selectedCategory && (
                <div className={s.IconWrapper}>
                  <IconCheckmarkLine size={24} color={vars.$scale.color.carrot500} />
                </div>
              )}
            </li>
          ))}
          {superHost && (
            <li
              className={clsx(s.boardTypeItem({ selected: false }), s.createBoardSuggestionStyle)}
              key={'custom'}
              onClick={() => {
                handleCreateBoard();
              }}
            >
              <IconPlusLine size={16} color={vars.$scale.color.gray600} />
              게시판 추가
            </li>
          )}
        </ul>
      </div>
    </NotIncludeBackButtonControlModal>
  );
};

export default SelectBoardTypeBottomSheet;
