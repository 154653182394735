import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import { ColorValue, formatDate as formatDateLib } from '@community-group/components';
import { differenceInDays, format, isAfter, startOfMonth, startOfWeek } from 'date-fns';
import ko from 'date-fns/locale/ko/index';
import type { OptionsWithTZ } from 'date-fns-tz';
import { format as formatDateDefault, utcToZonedTime } from 'date-fns-tz';

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DATE = HOUR * 24;

export const KARROT_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
export const KARROT_DATE_FORMAT_REGEX =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/;

export const MEETUP_WITHOUT_MINUTE = "yyyy-MM-dd'T'HH:00:00";
export const MEETUP_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:00";
export const MEETUP_ONLY_TIME_FORMAT = 'a h:mm';

// 과거
export const getDateDiffFromNow = (updatedAt: Date) => {
  const nowDate = new Date();
  nowDate.setHours(0, 0, 0, 0);

  const diffMs = +nowDate - +updatedAt;
  if (diffMs < DATE * 30) {
    return {
      text: `${Math.floor(diffMs / DATE) + 1}일`,
      type: 'date',
      diff: diffMs,
    };
  }

  const nowYear = nowDate.getFullYear();
  const updatedYear = updatedAt.getFullYear();
  const yearDiff = nowYear - updatedYear;
  const nowMonth = nowDate.getMonth() + 1;
  const updatedMonth = updatedAt.getMonth() + 1;

  if (nowMonth === updatedMonth) {
    if (yearDiff) {
      if (nowDate.getDate() < updatedAt.getDate()) {
        return yearDiff === 1
          ? { text: `12개월`, type: 'month', diff: diffMs }
          : { text: `${yearDiff - 1}년`, type: 'year', diff: diffMs };
      } else {
        return { text: `${yearDiff}년`, type: 'year', diff: diffMs };
      }
    } else {
      return { text: `1개월`, type: 'month', diff: diffMs };
    }
  } else if (nowMonth > updatedMonth) {
    if (nowYear > updatedYear) {
      return { text: `${nowYear - updatedYear}년`, type: 'year', diff: diffMs };
    } else {
      return {
        text: `${nowMonth - updatedMonth || 1}개월`,
        type: 'month',
        diff: diffMs,
      };
    }
  } else {
    if (nowYear > updatedYear + 1) {
      return { text: `${nowYear - updatedYear}년`, type: 'year', diff: diffMs };
    } else {
      return {
        text: `${12 - updatedMonth + nowMonth}개월`,
        type: 'month',
        diff: diffMs,
      };
    }
  }
};

// 챌린지 남은 기간 노출
export const getDateDiffFromFuture = (updatedAt: Date) => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const diffDays = differenceInDays(updatedAt, now);

  return {
    text: `${diffDays}일`,
    type: 'date',
    diff: updatedAt.getTime() - now.getTime(),
  };
};

export const formatDate = formatDateLib;

export const formatDateAddTimezoneOffsetSetOnlyDate = (
  date: Date,
  format: string,
  config?: OptionsWithTZ
) => {
  const onlyDate = new Date(formatDate(date, 'yyyy-MM-dd'));

  return formatDateDefault(onlyDate, format, {
    locale: ko,
    ...config,
  });
};

export const isAfterSpecificDate = (specificDate: string) => {
  const today = new Date();

  const KSTTimeZone = 'Asia/Seoul';
  const convertedTodayInKST = utcToZonedTime(today, KSTTimeZone);

  const convertedSpecificDataInKST = utcToZonedTime(specificDate, KSTTimeZone);

  return isAfter(convertedTodayInKST, convertedSpecificDataInKST);
};

export const getWeekOfMonth = (date: Date) => {
  const weekStartsOn = parseInt(formatDate(startOfMonth(date), 'i')) as
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7;

  const baseDate = startOfWeek(date, {
    weekStartsOn: weekStartsOn === 7 ? 0 : weekStartsOn,
  });
  const baseDays = baseDate.getDate() + 1;

  return Math.ceil(baseDays / 7);
};

type ReturnType = {
  text: string;
  color: ColorValue;
  durationText: string;
};

export const getChallengeDateInfo = ({
  startedAt,
  endedAt,
  status,
  totalDays,
}: Pick<
  ChallengeDetailPresentation,
  'startedAt' | 'endedAt' | 'status' | 'totalDays'
>): ReturnType => {
  const startedAtDate = new Date(startedAt);
  const endedAtDate = new Date(endedAt);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (status === 'closed') {
    return {
      text: '종료',
      color: 'gray600',
      durationText: `${format(startedAtDate, 'M월 dd일')} ~ ${format(
        endedAtDate,
        'M월 dd일'
      )} 진행`,
    };
  }

  if (status === 'prepared') {
    const { text } = getDateDiffFromFuture(startedAtDate);

    const diffDate = Math.max(0, differenceInDays(startedAtDate, today));

    return {
      durationText: `${text} 후 시작`,
      text: `D-${diffDate}`,
      color: 'yellow400',
    };
  }

  const { text } = getDateDiffFromNow(startedAtDate);
  return {
    durationText: `진행 ${text}차`, //진행 11일차
    text: `진행 ${text}차`, //진행 11일차
    color: 'primary',
  };
};
