import { BoxButton } from '@community-group/components';

import { useFlow } from '@/stackflow';

type Props = {
  groupId: string;
  userId: string;
};

const GroupProfileMemberGradeButton = ({ groupId, userId }: Props) => {
  const { push } = useFlow();

  return (
    <BoxButton
      size="medium"
      variant="secondary"
      UNSAFE_style={{ width: '100%' }}
      onClick={() => {
        push('GroupMemberGradeDetailPage', {
          groupId,
          userId,
          from: 'myProfile',
        });
      }}
    >
      내 멤버 등급
    </BoxButton>
  );
};

export default GroupProfileMemberGradeButton;
