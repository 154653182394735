import { Divider } from '@community-group/components';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import useCheckNeedToSetCategoryBottomSheet from '@/components/group/Setting/hooks/useCheckNeedToSetCategoryBottomSheet';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import GroupCustomerServiceSettingSection from '@/features/GroupSetting/CustomerServiceSetting';
import GroupDefaultSettingSection from '@/features/GroupSetting/DefaultSetting/GroupDefaultSettingSection';
import GroupExtraSettingSection from '@/features/GroupSetting/ExtraSetting';
import GroupInfoSection from '@/features/GroupSetting/GroupInfo';
import GroupJoinSettingSection from '@/features/GroupSetting/JoinSetting';
import GroupMemberSettingSection from '@/features/GroupSetting/MemberSetting';
import GroupPersonalSettingSection from '@/features/GroupSetting/PersonalSetting';
import useDidMountSequentialProcess from '@/hooks/useDidMountSequentialProcess';
import { useQueryXproperty } from '@/hooks/useXproperty';
import { LayoutWrapper } from '@/stackflow/components/Layout';

import * as s from './style.css';

type Props = {
  groupId: string;
  scrollToBottom?: 'true' | 'false' | undefined;
};
const GroupSettingBase = ({ groupId, scrollToBottom }: Props) => {
  const layoutWrapperRef = useRef(null);
  const [{ data: currentUser }, { data: groupDetail }, { data: permissions }, { data: xProperty }] =
    useSuspenseQueries({
      queries: [
        useQueryGroupMe({ groupId }),
        useQueryGroupDetail(groupId),
        useQueryGroupPermissions(groupId),
        useQueryXproperty(),
      ],
    });
  useEffect(() => {
    if (scrollToBottom === 'true') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      layoutWrapperRef.current.scrollTop = layoutWrapperRef.current.scrollHeight;
    }
  }, [scrollToBottom, layoutWrapperRef]);

  const checkSetCategory = useCheckNeedToSetCategoryBottomSheet({ groupId });

  useDidMountSequentialProcess([checkSetCategory]);

  return (
    <LayoutWrapper ref={layoutWrapperRef} className={s.Wrapper}>
      <GroupInfoSection groupId={groupId} currentUser={currentUser} groupDetail={groupDetail} />
      <Divider size={8} />
      <GroupPersonalSettingSection groupId={groupId} userId={currentUser.id.toString()} />
      <GroupDefaultSettingSection
        role={currentUser.role}
        groupId={groupId}
        groupDetail={groupDetail}
        permissions={permissions}
      />
      <GroupMemberSettingSection role={currentUser.role} groupId={groupId} />
      <GroupJoinSettingSection
        role={currentUser.role}
        groupId={groupId}
        groupDetail={groupDetail}
      />
      <GroupCustomerServiceSettingSection role={currentUser.role} xProperty={xProperty} />
      <GroupExtraSettingSection role={currentUser.role} groupId={groupId} />
    </LayoutWrapper>
  );
};

export default GroupSettingBase;
