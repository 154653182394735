import { GroupMemberGrade } from '@community-group/api/lib/group/models';
import { IconCheckmarkFlowerFill, IconCrownFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { MouseEventHandler, ReactNode } from 'react';

import * as s from './ListItemGradeBadge.css';

type Props = {
  grade: GroupMemberGrade;
  onClick: MouseEventHandler;
  memberGradeEnabled: boolean;
};

export const ListItemGradeBadge = ({ grade, onClick, memberGradeEnabled }: Props) => {
  switch (grade.role) {
    case 'superHost':
      return (
        <GradeIconWrapper onClick={onClick}>
          <IconCrownFill size={14} color="#f7be68" />
        </GradeIconWrapper>
      );
    case 'manager':
      return (
        <GradeIconWrapper onClick={onClick}>
          <IconCrownFill size={14} color="#30c795" />
        </GradeIconWrapper>
      );
    case 'member': {
      // TODO: 커스텀 등급(name을 모임별로 다르게 가능)이 적용되면 수정 필요
      if (memberGradeEnabled && grade.name === '정회원') {
        return (
          <GradeIconWrapper onClick={onClick}>
            <IconCheckmarkFlowerFill size={14} color={vars.$scale.color.gray600} />
          </GradeIconWrapper>
        );
      }
      return null;
    }
    default:
      return null;
  }
};

type WrapperProps = {
  onClick: MouseEventHandler;
  children: ReactNode;
};

const GradeIconWrapper = ({ onClick, children }: WrapperProps) => {
  return (
    <div className={s.Badge} onClick={onClick}>
      <div className={s.Icon}>{children}</div>
    </div>
  );
};
