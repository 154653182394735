import { Divider, Spacing, Typography } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';

import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import { useUserConfig } from '@/contexts/UserConfig';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';

import InviteMemberShareActionButtonGroup from '../Components/InviteMemberShareActionButtonGroup/InviteMemberShareActionButtonGroup';
import * as s from './InviteMemberShareBottomSheet.css';

export type InviteMemberShareBottomSheetParams = Pick<PageParams, 'groupId'>;

const InviteMemberShareBottomSheet: ActivityComponentType<
  InviteMemberShareBottomSheetParams
> = () => {
  const { pop } = useFlow();

  const { groupId = '' } = useQueryParams();

  const { data: group } = useReadGroupDetail(groupId);
  const { data: level } = useGetGroupLevel({ groupId: Number(groupId) });

  const {
    userConfig: { userId },
  } = useUserConfig();

  useSetFixedLayoutSize(false);

  useEnterTrackEvent({
    event: 'enter_invite_member_share_bottom_sheet',
    params: {
      groupId,
      currentLevel: level?.currentLevel,
      currentProgressPercentage: level?.forLevelUp.percentage,
      userId: (userId ?? 0).toString(),
      categoryId: group?.category.id,
      categoryName: group?.category.name,
    },
  });

  return (
    <BottomSheet
      backgroundColor={vars.$semantic.color.paperSheet}
      safeAreaInsetBottomRemove
      style={{
        padding: '0 !important',
      }}
    >
      <div className={s.Wrapper}>
        <div className={s.Header}>
          <div className={s.HeaderText}>
            <Typography color="gray900" typography="title2Bold">
              모임 초대하기
            </Typography>
            <Spacing size={6} />
            <Typography color="gray700" typography="bodyM1Regular">
              함께할 멤버를 초대해보세요.
            </Typography>
          </div>
          <div className={s.CloseIconWrapper} onClick={pop}>
            <IconXmarkLine width={24} height={24} />
          </div>
        </div>
        <Divider />
        <Spacing size={16} />
        {group && group.permalink?.id && (
          <InviteMemberShareActionButtonGroup
            groupId={groupId}
            groupName={group.name}
            publicId={group.permalink.id}
            from="invite_member_share_bottom_sheet"
          />
        )}
      </div>
    </BottomSheet>
  );
};

export default InviteMemberShareBottomSheet;
