import { ChallengeCertifiedActivitiesPresentation } from '@community-group/api/lib/group/models';

import * as s from './style.css';
import TagItem from './TagItem';

type Props = {
  myCertifyActivity: ChallengeCertifiedActivitiesPresentation;
  isCertifyEveryDay: boolean;
};

export type TagType = {
  icon: string;
  label: string;
  value: string;
  isRenderOnlyCertifyEveryDay: boolean;
};

const MyCertifyTags = ({ myCertifyActivity, isCertifyEveryDay }: Props) => {
  const tags: TagType[] = [
    {
      icon: '🔥',
      label: '현재 연속 인증',
      value: `${myCertifyActivity.continuousCertifiedProgress}회`,
      isRenderOnlyCertifyEveryDay: true,
    },
    {
      icon: '🥇',
      label: '최고 기록',
      value: `${myCertifyActivity.maxContinuousCertifiedProgress}회`,
      isRenderOnlyCertifyEveryDay: true,
    },
    {
      icon: '✍️',
      label: '인증 완료율',
      value: `${myCertifyActivity.certifiedRate}%`,
      isRenderOnlyCertifyEveryDay: false,
    },
    {
      icon: '🗓️',
      label: '남은 인증',
      value: `${myCertifyActivity.remainingCertifiedCounts}회`,
      isRenderOnlyCertifyEveryDay: false,
    },
  ];

  const renderTags = tags.filter((tag) => {
    if (isCertifyEveryDay) {
      //주7일 인증 글이라면
      return true;
    }

    //주7일 인증 글이 아니라면
    return !tag.isRenderOnlyCertifyEveryDay;
  });

  return (
    <div className={s.Container}>
      {renderTags.map((tag) => (
        <TagItem key={tag.label} tag={tag} />
      ))}
    </div>
  );
};

export default MyCertifyTags;
