import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import { Divider, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { useMemo } from 'react';

import { useGetGroupPhotoList } from '@/api/hooks/useGetGroupPhotoList';
import { getInfinitiveImagesFlatList } from '@/components/group/ImageViewerPage/utils/imageUtil';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import GroupDetailSummaryMoreButton from '../GroupDetailSummaryMoreButton';
import AlbumSummarySection from './AlbumSummarySection';

type Props = {
  group: GroupDetailPresentation;
  currentUserRole: GroupMemberRoleEnum;
};

const GroupDetailAlbumSummary = ({ group, currentUserRole }: Props) => {
  const { data } = useGetGroupPhotoList({
    groupId: group.id.toString(),
    onlyPublic: true,
  });
  const photoList = useMemo(() => getInfinitiveImagesFlatList(data), [data]);
  const groupId = useMemo(() => group.id.toString(), [group.id]);

  const activity = useFlow();
  const handlePhotoClick = (postId: number, imageIndex: number) => () => {
    trackEvent({
      event: 'click_album_image',
      params: {
        groupId: groupId,
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUserRole,
        version: '2',
        mediaType: photoList?.[imageIndex]?.type,
      },
      sample: true,
    });

    activity.push('ImageViewerPage', {
      content: 'album',
      groupId: groupId,
      initialIndex: imageIndex.toString(),
      screenName: 'groupDetailAlbumImageViewer',
    });
  };

  if (!data || photoList.length === 0) return null;
  return (
    <>
      <Divider size={8} />
      <AlbumSummarySection
        totalCount={photoList.length}
        photos={photoList}
        onPhotoClick={handlePhotoClick}
      >
        <GroupDetailSummaryMoreButton
          group={group}
          summaryType="albums"
          currentUserRole={currentUserRole}
        />
      </AlbumSummarySection>
    </>
  );
};

export default withAsyncBoundary(GroupDetailAlbumSummary, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
