import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_CHALLENGE_QUERY_KEY } from '../queries';

type Props = {
  groupId: number;
};

export const useQueryChallengeBanner = ({ groupId }: Props) => {
  const read = useAsyncRead(
    groupClient.api.GroupChallengeMemberApi.apiV1GroupsGroupIdChallengesBannerGet
  );
  return {
    queryKey: GROUP_CHALLENGE_QUERY_KEY.banner(groupId.toString()),
    queryFn: () => read({ groupId }),
  };
};

export const useReadChallengeBanner = ({ groupId }: Props) => {
  const query = useQueryChallengeBanner({ groupId });
  return useSuspenseQuery({ ...query });
};
