import { useCallback, useState } from 'react';
import { v4 } from 'uuid';

import { uploadImages } from '../utils/image';
import { HookFormImagePlugins } from './useHookFormImages';

export type ImageForm = {
  image?: Blob;
  src?: string;
  id: string;
};

export type PrevImageForm = Required<Pick<ImageForm, 'id' | 'src'>>;

interface Props {
  prevImages?: PrevImageForm[];
  plugins: HookFormImagePlugins;
}

/**
 * @deprecated useHandleUploadImages 사용 권장
 */
export const useHandleImages = ({
  prevImages = [],
  plugins: { bridge, postUploadImageV2 },
}: Props) => {
  const [originImages, setOriginImages] = useState<ImageForm[]>(prevImages);

  const handleUploadImages = useCallback(
    async (images: ImageForm[], currentOriginImages: ImageForm[]) => {
      const blobImages = images.map(({ image }) => image) as Blob[];
      const uploadedImages = await uploadImages(postUploadImageV2, blobImages);

      const mappedUpdatedImages = uploadedImages.map((image, index) => {
        if (image.status === 'fulfilled') {
          return {
            ...image.value,
            blobId: images[index].id,
          };
        }

        return {
          image: blobImages[index],
          id: images[index].id.replace('temp', 'error'),
          blobId: images[index].id,
        };
      });

      const updatedOriginImages = currentOriginImages.map((image: ImageForm) => ({
        ...image,
        id:
          mappedUpdatedImages.find((uploadedImage) => uploadedImage?.blobId === image.id)?.id ??
          image.id,
      }));

      setOriginImages([...updatedOriginImages]);
    },
    [postUploadImageV2, originImages]
  );

  const setImages = useCallback(
    async (blobImages: Blob[]) => {
      if (originImages.length + blobImages.length > 10) {
        return bridge?.openToast({ toast: { body: '사진은 최대 10장까지 첨부할 수 있어요.' } });
      }

      const mappedBlobImages = blobImages.map((blobImage) => ({
        image: blobImage,
        id: `temp-${v4()}`,
      }));

      const currentOriginImages = [...originImages, ...mappedBlobImages];
      setOriginImages(currentOriginImages);
      handleUploadImages(mappedBlobImages, currentOriginImages);
    },
    [bridge, handleUploadImages, originImages]
  );

  const removeImages = useCallback(
    (imageId: string) => {
      const selectedOriginImageIndex = originImages.findIndex((image) => image.id === imageId);

      originImages.splice(selectedOriginImageIndex, 1);
      setOriginImages([...originImages]);
    },
    [originImages]
  );

  const retryImage = useCallback(
    (imageId: string) => {
      const selectedImage = originImages.find((image) => image.id === imageId);

      if (!selectedImage) return;

      const replacedSelectedImage = {
        ...selectedImage,
        id: selectedImage.id.replace('error', 'temp'),
      };

      const replacedOriginImages = originImages.map((image) => {
        if (image.id === imageId) {
          return replacedSelectedImage;
        }

        return image;
      });

      setOriginImages([...replacedOriginImages]);
      handleUploadImages([replacedSelectedImage], replacedOriginImages);
    },
    [originImages]
  );

  return {
    images: originImages,
    setImages,
    removeImages,
    retryImage,
  };
};
