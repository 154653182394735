import {
  Spacing,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import React, { useEffect } from 'react';

import { useQueryMe, useReadMe } from '@/domain/Group/hooks/useReadMe';
import { useTimeout } from '@/hooks/useTimeout';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import * as s from './OpenTreasureBoxPage.css';

type Params = Pick<PageParams, 'placeId' | 'placeName'>;

const DELAY_TIME = 3000;

const OpenTreasureBoxPage: ActivityComponentType<Params> = () => {
  const { placeId } = usePathParams();
  const { placeName } = useQueryParams();
  const [{ data: meData }] = useSuspenseQueries({
    queries: [useQueryMe()],
  });

  const { replace } = useFlow();

  useTimeout(() => {
    // TODO: 수령 페이지로 이동
  }, DELAY_TIME);

  return (
    <AppScreen
      appBar={{
        backButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
        },
        closeButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
        },
      }}
    >
      <div className={s.Container}>
        <div className={s.Badge}>
          <Typography typography="caption2Bold" color="primary" textAlign="center">
            {placeName} 보물찾기 성공
          </Typography>
        </div>
        <Spacing size={12} />
        <Typography typography="h4" color="gray900" textAlign="center">
          두근두근
          <br />
          보물 상자를 열고 있어요
        </Typography>
        <Spacing size={12} />
        <Typography typography="subtitle1Regular" color="gray700" textAlign="center">
          {meData.nickname}님이 찾은 보물은 무엇일까요?!
        </Typography>
        <Spacing size={30} />
        <img
          className={s.TreasureBoxImg}
          src="https://assetstorage.krrt.io/1025317940251365954/6ff3bf18-9ea9-4aab-a3af-20bb66335a8c/width=993,height=993.webp"
          alt="보물 상자"
        />
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(OpenTreasureBoxPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
