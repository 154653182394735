import { Dialog, useDialog } from '@community-group/components';
import { useActivity } from '@stackflow/react';
import { useEffect } from 'react';

import { openVerifyNeighborhood } from '@/constants/path';
import { useCurrentRegionCheckOpend } from '@/hooks/useCurrentRegionCheckOpend';
import { useVisibilityEvent } from '@/hooks/useVisibilityEvent';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

const useHandleCreatePermission = () => {
  const { data, regionCertified, userInfo, isOpenRegion, refetch, isFetching } =
    useCurrentRegionCheckOpend();
  const { isOpen: isOpenDialog, open: openDialog, close: closeDialog } = useDialog();
  const { pop } = useFlow();
  const { isTop } = useActivity();

  // 페이지가 탑이면 동네인증 상태를 다시 조회해온다.
  useVisibilityEvent({
    enabled: isTop,
    callback: (visibilityState) => {
      if (visibilityState === 'visible') {
        refetch();
      }
    },
  });

  // 모임 생성 권한 체크
  useEffect(() => {
    if (!data) return;
    if (isFetching) return;
    if (isOpenDialog) return;

    if (!isOpenRegion) {
      openDialog({
        element: (
          <Dialog
            title="모임 서비스 제한 지역"
            description={`${userInfo.regionCheckIns.regionName}에서는 모임을 이용할 수 없어요.`}
            primaryActionLabel="확인"
            onPrimaryAction={async () => {
              await closeDialog();
              setTimeout(() => {
                pop();
              }, 50);
            }}
          />
        ),
      });
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenRegion, regionCertified, isFetching, isOpenDialog, data, userInfo]);
};

export default useHandleCreatePermission;
