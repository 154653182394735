import { bridge } from '@/contexts/Bridge';
import { extractAppBuildType, isExposeInternalUser } from '@/utils/check';

import {
  initInternalToolVConsolePlugin,
  INTERNAL_TOOL_PLUGIN_ID,
} from './plugin/initInternalToolVConsolePlugin';

const vconsoleConfig = {
  onReady: () => {
    const { appEnv } = globalThis.appConfig;
    const vConsoleSwitch = document.querySelector('.vc-switch') as HTMLElement;
    vConsoleSwitch.style.position = 'fixed';
    vConsoleSwitch.style.top = '64px';
    vConsoleSwitch.style.height = '30px';

    const vConsoleTab = document.querySelector('.vc-tabbar') as HTMLElement;
    vConsoleTab.style.overflowY = 'hidden';

    const hostname = window.location.hostname;
    const isPreview = hostname?.includes('preview');
    if (isPreview) {
      const previewNum = hostname.split('-')[1];
      vConsoleSwitch.innerHTML = `#${previewNum}`;
      vConsoleSwitch.style.fontWeight = 'bold';
      vConsoleSwitch.classList.add('vconsole-rainbow');
    }

    // 프로덕션 환경에서는 vConsole 배경색을 변경
    if (appEnv === 'production') {
      vConsoleSwitch.style.backgroundColor = '#ff6f0f';
    }
  },
};

export const initVConsole = async () => {
  const { default: vConsoleModule } = await import('vconsole');

  const renderVConsole = (appEnv: typeof globalThis.appConfig.appEnv) => {
    const vConsole = new vConsoleModule({
      ...vconsoleConfig,
      defaultPlugins: ['system', 'network', 'storage'],
      pluginOrder: [INTERNAL_TOOL_PLUGIN_ID, 'network', 'logs', 'storage'],
    });

    if (appEnv !== 'production') {
      // InternalTool은 alpha, dev 환경에서만 노출
      const internalToolVConsolePlugin = initInternalToolVConsolePlugin(vConsoleModule);
      vConsole.addPlugin(internalToolVConsolePlugin);
      vConsole.showPlugin(INTERNAL_TOOL_PLUGIN_ID);
    }
  };

  const { appEnv } = globalThis.appConfig;

  const isAlphaStage = appEnv === 'alpha';

  if (isAlphaStage) renderVConsole('alpha');

  const { info } = (await bridge?.getUserInfo?.({})) ?? {};
  const userId = info?.user?.id ?? '';
  const isInternalUser = isExposeInternalUser(userId.toString(), false);

  const isDebugApp = extractAppBuildType(window.navigator.userAgent) === 'debug';

  if (isInternalUser || isDebugApp) {
    renderVConsole('production');
    return;
  }
};
