import { Spacing, Typography } from '@community-group/components';
import { useSuspenseQueries } from '@tanstack/react-query';

import { useQueryChallengeDetail } from '@/domain/Challenge/hooks/useReadChallengeDetail';
import { useQueryChallengeMyCertifyActivity } from '@/domain/Challenge/hooks/useReadChallengeMyCertifyActivity';
import { useQueryMyChallengeCalendar } from '@/domain/Challenge/hooks/useReadMyChallengeCalendar';
import MyCertifyTags from '@/features/GroupChallengeCertify/MyCertifyResult/MyCertifyTags';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import MyChallengeCalendar from './MyChallengeCalendar';
import MyChallengeStatus from './MyChallengeStatus';
import * as s from './style.css';

const MyChallengeInfoSection = () => {
  const { groupId, challengeId } = usePathParams();
  const [{ data: myCertifyActivity }, { data: calendarData }, { data: challengeDetail }] =
    useSuspenseQueries({
      queries: [
        useQueryChallengeMyCertifyActivity({
          groupId: Number(groupId),
          challengeId: Number(challengeId),
        }),
        useQueryMyChallengeCalendar({ groupId: Number(groupId), challengeId: Number(challengeId) }),
        useQueryChallengeDetail({ groupId, challengeId }),
      ],
    });

  const isCertifyEveryDay = challengeDetail.certifyIntervalTargetCount === 7;

  return (
    <div className={s.Wrapper}>
      <Typography typography="title3Bold">내 인증 기록</Typography>
      <Spacing size={20} />
      <MyChallengeStatus
        myCertifyActivity={myCertifyActivity}
        isCertifyEveryDay={challengeDetail.certifyIntervalTargetCount === 7}
      />
      <Spacing size={26} />
      <MyChallengeCalendar calendarData={calendarData} groupId={Number(groupId)} />
      <Spacing size={20} />
      <MyCertifyTags myCertifyActivity={myCertifyActivity} isCertifyEveryDay={isCertifyEveryDay} />
    </div>
  );
};

export default MyChallengeInfoSection;
