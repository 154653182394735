import {
  BottomBasicButton,
  MultilineTextField,
  Spacing,
  Typography,
} from '@community-group/components';
import { IconChevronLeftLine } from '@daangn/react-monochrome-icon';
import { useState } from 'react';

import { useConnectedFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { trackEvent } from '@/utils/analytics';

import * as s from './MemberRejectMessage.css';

export type GroupMemberRejectMessagePageRequest = {
  method: 'close' | 'reject';
  message?: string;
};

const GroupMemberRejectMessagePage = () => {
  const [message, setMessage] = useState('');
  const { pop } = useConnectedFlow();
  const handleClose = () => {
    trackEvent({
      event: 'click_application_reject_with_comment_close',
    });
    pop().send({
      method: 'close',
    });
  };
  const handleReject = () => {
    trackEvent({
      event: 'click_application_reject_with_comment_submit',
    });
    pop().send({
      method: 'reject',
      message,
    });
  };

  return (
    <AppScreen
      appBar={{
        closeButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: handleClose,
        },
        backButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: handleClose,
        },
      }}
      accessoryBar={
        <BottomBasicButton onClick={handleReject} disabled={!message}>
          거절하고 의견 보내기
        </BottomBasicButton>
      }
    >
      <div className={s.Container}>
        <Spacing size={12} />
        <Typography typography="title1Bold">
          거절할 멤버에게
          <br />
          보낼 의견을 입력해주세요
        </Typography>
        <Spacing size={8} />
        <Typography typography="bodyM1Regular">입력하신 내용은 알림으로 전달돼요.</Typography>
        <Spacing size={24} />
        <MultilineTextField
          value={message}
          onChange={(value) => setMessage(value)}
          placeholder="ex) 자기소개에 거주 위치, 취미를 포함해서 다시 가입 신청 부탁드려요!"
          maxLength={100}
          minLength={1}
          minHeight="8.5rem"
        />
      </div>
    </AppScreen>
  );
};

export default GroupMemberRejectMessagePage;
