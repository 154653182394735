import { AsyncBoundary, BoxButton } from '@community-group/components';

import { useGetMe } from '@/api/hooks/useGetMe';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import { SearchTabType } from '../../Page/Result';
import PopularityGroupListSection from '../PopularityGroupListSection';
import * as s from './EmptyView.css';

type Props = {
  isSetFilter?: boolean;
  initFilter?: () => void;
  isMeetupSearch?: boolean;
  currentTab: SearchTabType;
};

const SearchResultEmptyView = ({ isSetFilter, initFilter, isMeetupSearch, currentTab }: Props) => {
  const { query = '' } = usePathParams();

  const { data } = useGetMe();
  const { push } = useFlow();

  const currentRegionName = data?.regionCheckIns.regionName;

  const renderEmptyView = () => {
    if (isMeetupSearch) {
      return (
        <>
          <h3 className={s.Title}>
            {currentRegionName && (
              <>
                앗! {currentRegionName} 근처에는
                <br />
              </>
            )}
            <span>{`'${decodeURIComponent(query)}'`}</span> 검색 결과가 없어요.
          </h3>
          <p className={s.Description}>더 간단한 단어로 검색해 보세요. (예: 빨간 가방→가방)</p>
        </>
      );
    }

    if (isSetFilter) {
      return (
        <>
          <h3 className={s.Title}>
            {currentRegionName && (
              <>
                앗! {currentRegionName} 근처에는
                <br />
              </>
            )}
            <span>설정한 필터</span>에 맞는 검색 결과가 없어요.
          </h3>
          <p className={s.Description}>필터를 조정하거나 초기화 해보세요.</p>
          <BoxButton
            marginTop="1.25rem"
            variant="secondary"
            onClick={() => {
              initFilter?.();
            }}
          >
            필터 초기화
          </BoxButton>
        </>
      );
    }

    return (
      <>
        <h3 className={s.Title}>
          {currentRegionName && (
            <>
              앗! {currentRegionName} 근처에는
              <br />
            </>
          )}
          <span>{`'${decodeURIComponent(query)}'`}</span> 검색 결과가 없어요.
        </h3>
        <p className={s.Description}>찾는 모임이 없나요? 모임을 만들어보세요.</p>
        <BoxButton
          marginTop="1.25rem"
          variant="primary"
          onClick={() => {
            push('GroupNewSetGroupCategoryRecommendPage', {});
          }}
          size="medium"
        >
          모임 만들기
        </BoxButton>
      </>
    );
  };

  return (
    <>
      <div className={s.Wrapper}>{renderEmptyView()}</div>
      {!isMeetupSearch && (
        <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
          <PopularityGroupListSection currentTab={currentTab} />
        </AsyncBoundary>
      )}
    </>
  );
};

export default SearchResultEmptyView;
