import { Render, Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';

import { GroupStage } from '@/domain/Group/utils/getGroupStage';

import * as s from './GroupBanner.css';

const BannerBadgeImage =
  'https://assetstorage.krrt.io/1025317940251365954/964003a2-7bd4-45c8-8975-5c7f9ede4e02/width=210,height=210.png';

const BannerOnboardingImage =
  'https://assetstorage.krrt.io/1107942635587460566/1f51dc34-b1e7-4002-adf8-148aa2c06b27/width=280,height=280.png';

type Props = {
  groupLevel: number;
  groupStage: GroupStage;
};

function GroupBanner({ groupLevel, groupStage }: Props) {
  if (groupStage === 'junior') {
    return (
      <>
        <Spacing size={16} />
        <div className={s.BannerWrapper} style={{ background: vars.$scale.color.blueAlpha50 }}>
          <div className={s.TextInfoWrapper}>
            <Typography typography="subtitle2Bold" color="blue950">
              최근 1개월 내 개설된 모임
            </Typography>
            <Spacing size={4} />
            <Typography typography="caption2Regular" color="blue900">
              모임의 초기 멤버가 되어보세요
            </Typography>
          </div>
          <img className={s.Badge} src={BannerOnboardingImage} />
        </div>
      </>
    );
  }

  return (
    <Render condition={groupLevel === 5}>
      <Spacing size={16} />
      <div className={s.BannerWrapper} style={{ background: vars.$scale.color.carrotAlpha50 }}>
        <div className={s.TextInfoWrapper}>
          <Typography typography="subtitle2Bold" color="carrot950">
            이 달의 우수 모임
          </Typography>
          <Spacing size={4} />
          <Typography typography="caption2Regular" color="carrot900">
            멤버들의 활동이 많은 모임이에요
          </Typography>
        </div>
        <img className={s.Badge} src={BannerBadgeImage} />
      </div>
    </Render>
  );
}

export default GroupBanner;
