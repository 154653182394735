import { ApiV1AllTypeMeetupsGetRequestParams } from '@community-group/api/lib/group/api/all-meetups-api';
import { AsyncBoundary, ViewError, ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { $Values } from 'utility-types';

import { ORDER_HOME, ORDER_MEETUP } from '@/api/base/group';
import { View } from '@/components/common/View';
import { useReadMe } from '@/domain/Group/hooks/useReadMe';
import { SEOUL_RUN_PROMOTION_CONFIG } from '@/features/SeoulRunPromotion/config';
import FloatingCreateSeoulRunButton from '@/features/SeoulRunPromotion/FloatingCreateSeoulRunButton';
import SeoulRunMeetupCalendar from '@/features/SeoulRunPromotion/MeetupCalendar';
import { AppScreen } from '@/stackflow/components/AppScreen';

const SeoulRunMeetupCalendarPage: ActivityComponentType = () => {
  const { data: userInfo } = useReadMe();

  return (
    <AppScreen appBar={{ title: userInfo.regionCheckIns.regionName }}>
      <View>
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
          <GroupMeetupCalendarView />
        </AsyncBoundary>
        <FloatingCreateSeoulRunButton />
      </View>
    </AppScreen>
  );
};

export default SeoulRunMeetupCalendarPage;

export type FilterType = {
  order: $Values<typeof ORDER_HOME>;
  keywordName?: string;
};

const GroupMeetupCalendarView = () => {
  const filter = {
    order: ORDER_MEETUP.MEET_AT_ASC,
    categoryId: undefined,
    statuses: ['opened', 'fully_recruited'],
    //eventType: SEOUL_RUN_PROMOTION_CONFIG.EVENT_TYPE, //TODO: 개발 완료 후 제거
  } as ApiV1AllTypeMeetupsGetRequestParams;

  return (
    <>
      <AsyncBoundary pendingFallback={<ViewLoader />}>
        <SeoulRunMeetupCalendar filter={filter} mode="page" />
      </AsyncBoundary>
      <div
        style={{
          width: '100%',
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      />
    </>
  );
};
