import { DismissableInlineAlert } from '@daangn/sprout-components-inline-alert';

import { openLink } from '@/utils/link';

import { SEOUL_RUN_PROMOTION_CONFIG } from '../config';
import useGroupDetailSeoulRunNoticeBannerClosed from '../hooks/useGroupDetailSeoulRunNoticeBannerClosed';

type Props = { groupId: string };

const GroupDetailSeoulRunNoticeBanner = ({ groupId }: Props) => {
  const handleClick = (event) => {
    const contentDiv = event.currentTarget.querySelector('[data-part="content"]');
    if (!contentDiv || !contentDiv.contains(event.target)) return;
    openLink(SEOUL_RUN_PROMOTION_CONFIG.LANDKIT_PAGE);
  };

  const [_, addMemberGradeNoticeBannerShownCount] =
    useGroupDetailSeoulRunNoticeBannerClosed(groupId);

  return (
    <div onClick={handleClick}>
      <DismissableInlineAlert
        title="이벤트"
        description="한강 보물찾기런 어쩌구저쩌구 참여 안할그여~?"
        variant="info"
        onClose={addMemberGradeNoticeBannerShownCount}
      />
    </div>
  );
};

export default GroupDetailSeoulRunNoticeBanner;
