import {
  AsyncBoundary,
  Switch,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { ActivityComponentType } from '@stackflow/react';
import { Controller, FieldValues } from 'react-hook-form';

import { useGetMe } from '@/api/hooks/useGetMe';
import AccessoryBarButtonGroup from '@/components/common/AccessoryBarButtonGroup/AccessoryBarButtonGroup';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';

import AppBarCloseCreateGroupButton from '../../Common/AppBarCloseCreateGroupButton';
import GroupCreateAppScreen from '../../Common/GroupCreateAppScreen';
import useCreateForm from '../../hooks/useCreateForm';
import { indicatorCountHandler } from '../../utils/indicatorCountHandler';
import VerificationPreview from '../components/VerificationPreview';
import { useHandleVerificationBottomSheet } from '../hooks/useHandleVerificationBottomSheet';
import * as s from './newVerification.css';

export type GroupNewSetVerificationParams = Pick<
  PageParams,
  | 'name'
  | 'categoryId'
  | 'joinType'
  | 'question'
  | 'requiredQuestion'
  | 'haveQuestion'
  | 'createType'
  | 'from'
  | 'description'
  | 'joinableRegionRange'
  | 'joinGrowth'
  | 'joinExerciseGrowth'
  | 'createGroupFlowType'
>;

const GroupNewSetVerificationPage: ActivityComponentType<GroupNewSetVerificationParams> = () => {
  const { push, pop } = useFlow();
  const { push: asyncPush } = useConnectedActions();
  const { from, createGroupFlowType, ...postNewGroupPayload }: GroupNewSetVerificationParams =
    useQueryParams();
  const { data: userMe } = useGetMe();

  const { handleSubmit, watch, control, setValue } = useCreateForm({
    defaultValues: {
      needVerification: false,
    } as FieldValues,
  });

  const { handleVerificationBottomSheet } = useHandleVerificationBottomSheet();

  const needVerificationFieldValue = watch('needVerification');
  const canViewVerifiedUserInfoFieldValue = watch('canViewVerifiedUserInfo');

  useEnterTrackEvent({
    event: 'enter_create_group_step',
    params: {
      step: 'verification',
      createGroupFlowType: createGroupFlowType ?? 'normal',
    },
  });
  useSetFixedLayoutSize(false);
  useUpdateStyleCurrentRouter({ IOS_ONLY_hideKeyboardAccessoryView: false });

  const onSubmit = handleSubmit(async () => {
    push('GroupNewSetSubNicknamePage', {
      needVerification: needVerificationFieldValue ? 'true' : 'false',
      canViewVerifiedUserInfo: canViewVerifiedUserInfoFieldValue ? 'true' : 'false',
      ...postNewGroupPayload,
      from,
      createGroupFlowType,
    });
  });

  const handleNeedVerificationChange = async (value: boolean) => {
    if (!value) {
      setValue('canViewVerifiedUserInfo', false);
      setValue('needVerification', false);
      return;
    }

    const verified = await handleVerificationBottomSheet({
      certificationCandidateType: userMe.verified ? 'pass_verification' : 'create_group',
    });
    setValue('needVerification', verified);
  };

  const handleCanViewVerifiedUserInfoChange = async (value: boolean) => {
    if (!value) {
      setValue('canViewVerifiedUserInfo', false);
      return;
    }

    // 본인인증이 완료되어있지 않은 경우
    if (!userMe.verified) {
      const verified = await handleVerificationBottomSheet({
        certificationCandidateType: userMe.verified
          ? 'pass_verification'
          : 'create_group_with_user_info',
      });

      setValue('canViewVerifiedUserInfo', verified);
      setValue('needVerification', verified);
      return;
    }

    const checked = (await asyncPush(
      'BottomSheet/CheckCanViewVerifiedUserInfoBottomSheet',
      {}
    )) as {
      canViewUser: boolean;
    };

    setValue('canViewVerifiedUserInfo', checked.canViewUser as boolean);
    if (checked.canViewUser) {
      setValue('needVerification', checked.canViewUser as boolean);
    }
  };

  return (
    <GroupCreateAppScreen
      indicator={indicatorCountHandler('GroupNewSetVerificationPage')}
      appBar={{
        backButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
        closeButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
      }}
      accessoryBar={({ appendTop }) => (
        <div className={s.accessoryBarBox}>
          {appendTop ? appendTop : <Spacing size={8} />}
          <AccessoryBarButtonGroup onBack={pop} onSubmit={onSubmit} />
        </div>
      )}
    >
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <LayoutWrapper padding="0 1rem">
          <Typography
            typography="title1Bold"
            color="gray900"
            style={{
              marginTop: '1rem',
              marginBottom: '0.5rem',
            }}
          >
            본인인증이 필요한 모임인가요?
          </Typography>
          <Typography typography="bodyL1Regular" color="gray900">
            본인인증을 완료한 이웃만 모임에 가입할 수 있어요.
          </Typography>
          <Spacing size={36} />
          <div className={s.guideTextBox}>
            <label className={s.guideLabelText}>예시</label>
            <Typography typography="bodyL2Regular" color="blue900">
              인증을 완료하면 프로필에 표시돼요.
            </Typography>
          </div>
          <Spacing size={12} />
          <VerificationPreview verified />
          <Spacing size={16} />
          <div className={s.switchBox}>
            <Typography typography="bodyL1Regular" color="gray900">
              본인인증 사용
            </Typography>
            <Controller
              control={control}
              name="needVerification"
              render={({ field: { name, value } }) => (
                <Switch
                  onChange={(isSelected) => {
                    // onChange(!value);

                    handleNeedVerificationChange(isSelected);
                  }}
                  name={name}
                  isSelected={value}
                />
              )}
            />
          </div>
          <div className={s.switchBox}>
            <div className={s.switchBoxTextBox}>
              <Typography typography="bodyL1Regular" color="gray900">
                나이와 성별 정보 받기
              </Typography>
              <Typography typography="caption1Regular" color="gray600">
                가입하는 이웃이 모임장님의 정보도 볼 수 있어요.
              </Typography>
            </div>
            <Controller
              control={control}
              disabled={!userMe.verified}
              name="canViewVerifiedUserInfo"
              render={({ field: { name, value } }) => (
                <Switch
                  onChange={(isSelected) => {
                    // onChange(!value);

                    handleCanViewVerifiedUserInfoChange(isSelected);
                  }}
                  name={name}
                  isSelected={value}
                />
              )}
            />
          </div>
          <Spacing size={32} />
        </LayoutWrapper>
      </AsyncBoundary>
    </GroupCreateAppScreen>
  );
};

export default withAsyncBoundary(GroupNewSetVerificationPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
