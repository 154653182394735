import { Category } from '@community-group/api/lib/group/models';
import { AsyncBoundary, ViewError, ViewLoader } from '@community-group/components';
import { useState } from 'react';
import { $Values } from 'utility-types';

import { ORDER_SEARCH } from '@/api/base/group';
import { useGetSearchGroupList } from '@/api/hooks/useGetSearchGroupList';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import { AgeRangeType, RegionRangeType } from '../../model';
import { SearchTabType } from '../../Page/Result';
import { convertRegionRange } from '../../utils';
import PopularityGroupListSection from '../PopularityGroupListSection';
import SearchResultGroupFilter, { SearchResultGroupFilterProps } from '../SearchResultGroupFilter';
import SearchResultList from '../SearchResultList';

const SearchResultGroup = ({ currentTab }: { currentTab: SearchTabType }) => {
  const { regionRange, ageRange } = useQueryParams();
  const [selectedRegionRange, setSelectedRegionRange] = useState<RegionRangeType>(
    convertRegionRange(regionRange)
  );
  const [selectedAgeRange, setSelectedAgeRange] = useState<AgeRangeType>(ageRange);
  const [selectedCategoryList, setSelectedCategoryList] = useState<Category[]>([]);
  const [selectedSorting, setSelectedSorting] = useState<$Values<typeof ORDER_SEARCH>>(
    ORDER_SEARCH.RECOMMEND_SCORE_DESC
  );

  const initFilter = () => {
    setSelectedRegionRange(undefined);
    setSelectedAgeRange(undefined);
    setSelectedCategoryList([]);
    setSelectedSorting(ORDER_SEARCH.RECOMMEND_SCORE_DESC);
  };

  return (
    <div>
      <SearchResultGroupFilter
        selectedRegionRange={selectedRegionRange}
        setSelectedRegionRange={setSelectedRegionRange}
        selectedAgeRange={selectedAgeRange}
        setSelectedAgeRange={setSelectedAgeRange}
        selectedCategoryList={selectedCategoryList}
        setSelectedCategoryList={setSelectedCategoryList}
        selectedSorting={selectedSorting}
        setSelectedSorting={setSelectedSorting}
      />
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <SearchResultGroupWrapper
          currentTab={currentTab}
          selectedRegionRange={selectedRegionRange}
          selectedAgeRange={selectedAgeRange}
          selectedSorting={selectedSorting}
          selectedCategoryList={selectedCategoryList}
          initFilter={initFilter}
        />
      </AsyncBoundary>
    </div>
  );
};

type SearchResultGroupWrapperProps = Partial<SearchResultGroupFilterProps> & {
  currentTab: SearchTabType;
  initFilter: () => void;
};

// 검색로깅 - 섹션 구분없이 start Index 통합 처리가 필요해 컴포넌트 분리
const SearchResultGroupWrapper = ({
  currentTab,
  selectedRegionRange,
  selectedAgeRange,
  selectedSorting,
  selectedCategoryList,
  initFilter,
}: SearchResultGroupWrapperProps) => {
  const { query = '' } = usePathParams();
  const { referrer = 'community_group.client', queryId } = useQueryParams();

  const selectedCategoryListNullish = selectedCategoryList ?? [];
  const selectedCategoryIdList = selectedCategoryListNullish.map(({ id }) => id);
  const currentCategoryIdList =
    selectedCategoryIdList.length > 0 ? selectedCategoryIdList : undefined;

  const searchFilter = {
    query,
    category: currentCategoryIdList,
    order: selectedSorting ?? ORDER_SEARCH.RECOMMEND_SCORE_DESC,
    referrer,
    queryId,
    regionRange: selectedRegionRange,
    minAge: selectedAgeRange ? Number(selectedAgeRange) : undefined,
    maxAge: selectedAgeRange ? Number(selectedAgeRange) + 9 : undefined,
  };

  const { data, hasNextPage } = useGetSearchGroupList(searchFilter);

  const flattenSearchResultList = data?.pages.map(({ data }) => data.groups).flat(1) ?? [];

  return (
    <>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <SearchResultList
          currentTab={currentTab}
          initFilter={initFilter}
          groupList={flattenSearchResultList}
          hasNextPage={hasNextPage}
          searchFilter={searchFilter}
        />
      </AsyncBoundary>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <PopularityGroupListSection
          selectedCategoryList={selectedCategoryList}
          selectedRegionRange={selectedRegionRange}
          selectedAgeRange={selectedAgeRange}
          selectedSorting={selectedSorting}
          currentTab={currentTab}
          startIndex={flattenSearchResultList.length}
        />
      </AsyncBoundary>
    </>
  );
};

export default SearchResultGroup;
