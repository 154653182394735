import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_CHALLENGE_QUERY_KEY } from '../queries';

type Props = {
  groupId: number;
  challengeId: number;
};

export const useQueryChallengeNotificationSetting = ({ groupId, challengeId }: Props) => {
  const read = useAsyncRead(
    groupClient.api.GroupChallengeMemberApi
      .apiV1GroupsGroupIdChallengesChallengeIdNotificationSettingsMeGet
  );
  return {
    queryKey: GROUP_CHALLENGE_QUERY_KEY.notificationSetting(
      groupId.toString(),
      challengeId.toString()
    ),
    queryFn: () => read({ groupId, challengeId }),
  };
};

export const useReadChallengeNotificationSetting = ({ groupId, challengeId }: Props) => {
  const query = useQueryChallengeNotificationSetting({ groupId, challengeId });
  return useSuspenseQuery({ ...query });
};
