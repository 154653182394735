import {
  AsyncBoundary,
  CoveredLazyLoadImage,
  Typography,
  ViewLoader,
} from '@community-group/components';
import { IconPlusLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';

import { useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useConnectedFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';

import * as s from './SelectCreateMeetupInMyGroupBottomSheet.css';

const SelectCreateMeetupInMyGroupBottomSheet: ActivityComponentType = () => {
  return (
    <BottomSheet
      style={{
        padding: '1rem 1rem 0 1rem',
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <SelectCreateMeetupInMyGroupBottomSheetView />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const SelectCreateMeetupInMyGroupBottomSheetView = () => {
  const { pop } = useConnectedFlow();
  const { data } = useGetMyGroupList();
  useEnterTrackEvent({
    event: 'enter_create_group_meetup_bottom_sheet',
  });

  return (
    <div className={s.View}>
      <Typography as="h1" typography="title2Bold" color="gray900">
        어떤 모임에서 일정을 만들까요?
      </Typography>
      <ul className={s.Ul}>
        {data?.groups.map((group) => {
          return (
            <li
              className={s.Li}
              key={group?.id}
              onClick={() => {
                pop().send({
                  groupId: group?.id.toString(),
                });
              }}
            >
              <CoveredLazyLoadImage
                src={group?.profileImage.small}
                alt={`${group?.name} 프로필 이미지`}
                width={40}
                height={40}
                isLazy={false}
                style={{
                  borderRadius: '.5rem',
                }}
              />
              <Typography typography="bodyM1Regular" color="gray900">
                {group?.name}
              </Typography>
            </li>
          );
        })}
        <li
          className={s.Li}
          onClick={() => {
            pop().send({
              groupId: undefined,
            });
          }}
        >
          <div className={s.AddNewGroup}>
            <IconPlusLine color={vars.$scale.color.gray400} size={20} />
          </div>
          <Typography typography="bodyM1Regular" color="gray900">
            새로 만들기
          </Typography>
        </li>
        <div className={s.SafeAreaBottom} />
      </ul>
    </div>
  );
};

export default SelectCreateMeetupInMyGroupBottomSheet;
