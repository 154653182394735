import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import { Spacing, Typography, VerticalSpacing } from '@community-group/components';
import {
  IconCalendarFill,
  IconCheckmarkChatbubbleLeftFill,
  IconFlameFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import {
  getCertificationIntervalText,
  getCertificationTotalDaysDurationText,
} from '@/features/Challenge/utils/certification';
import { getChallengeDateInfo } from '@/features/Challenge/utils/date';

import * as s from './style.css';

type Props = Pick<
  ChallengeDetailPresentation,
  | 'certifyIntervalTargetCount'
  | 'totalDays'
  | 'startedAt'
  | 'endedAt'
  | 'status'
  | 'certifyIntervalDays'
>;

const ChallengeDetailInfoList = ({
  status,
  certifyIntervalTargetCount,
  totalDays,
  startedAt,
  endedAt,
  certifyIntervalDays,
}: Props) => {
  // 시작일
  const dateInfo = useMemo(() => {
    const { durationText } = getChallengeDateInfo({
      startedAt,
      endedAt,
      status,
      totalDays,
    });

    return durationText;
  }, [endedAt, startedAt, status, totalDays]);

  // 챌린지 기간
  const totalDaysText = useMemo(() => {
    return getCertificationTotalDaysDurationText(totalDays, certifyIntervalDays);
  }, [certifyIntervalDays, totalDays]);

  // 인증 빈도
  const certifyGoalCountText = useMemo(() => {
    const intervalText = getCertificationIntervalText(certifyIntervalTargetCount, '일');
    return `${intervalText} 인증`;
  }, [certifyIntervalTargetCount]);

  return (
    <div className={s.Container}>
      <div className={s.ItemWrapper}>
        <IconFlameFill size={16} color={vars.$scale.color.gray600} />
        <VerticalSpacing size={8} />
        <Typography typography="subtitle1Regular" color="gray800">
          {dateInfo}
        </Typography>
      </div>
      <Spacing size={8} />
      <div className={s.ItemWrapper}>
        <IconCalendarFill size={16} color={vars.$scale.color.gray600} />
        <VerticalSpacing size={8} />
        <Typography typography="subtitle1Regular" color="gray800">
          {totalDaysText}
        </Typography>
      </div>
      <Spacing size={8} />
      <div className={s.ItemWrapper}>
        <IconCheckmarkChatbubbleLeftFill size={16} color={vars.$scale.color.gray600} />
        <VerticalSpacing size={8} />
        <Typography typography="subtitle1Regular" color="gray800">
          {certifyGoalCountText}
        </Typography>
      </div>
    </div>
  );
};

export default ChallengeDetailInfoList;
