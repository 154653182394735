import { MultilineTextField } from '@community-group/components';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { GroupChallengeEditFormProps } from '@/activities/Group/[groupId]/Challenge/Edit';

import { GroupChallengeFormImagesField } from '../ImagesField';
import * as s from './style.css';

type Props = {
  formHandler: UseFormReturn<GroupChallengeEditFormProps>;
};

const GroupChallengeEditForm = ({ formHandler }: Props) => {
  return (
    <div className={s.Container}>
      <MultilineTextField
        value={formHandler.watch('description')}
        defaultValue={formHandler.watch('description')}
        onChange={(value) => formHandler.setValue('description', value)}
        placeholder="챌린지 인증 규칙 및 방법을 알려주세요."
        minHeight={180}
      />
      <GroupChallengeFormImagesField formHandler={formHandler} />
    </div>
  );
};

export default GroupChallengeEditForm;
