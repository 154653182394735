import { KarrotBridge } from '@daangn/karrotbridge';
import { GroupSearchLogRequestBodySchema, QueryFromType } from '@daangn/search-log-group';
import { useSearchLogger } from '@daangn/search-log-group';

import { useBridge } from '@/contexts/Bridge';

/**
 * @description 검색 로깅을 위한 훅
 * @see {@link https://www.notion.so/daangn/12028c3a9f8f802dbe3dee098e8a288d?v=af1bdebdf87b4735a2e84452d36a0ecf}
 * @returns {SearchLogger}
 */
const useKarrotSearchLogger = () => {
  const { bridge: karrotBridge } = useBridge();
  const searchLogger = useSearchLogger({
    karrotBridge: karrotBridge as KarrotBridge,
    DEV_ONLY_debug: true,
  });

  return {
    groupSearchV1: (params: Parameters<typeof searchLogger.groupSearchV1>[0]) => {
      searchLogger.groupSearchV1(params);
    },
    clickedGroupSearchItemV1: (
      params: Parameters<typeof searchLogger.clickedGroupSearchItemV1>[0]
    ) => {
      searchLogger.clickedGroupSearchItemV1(params);
    },
    impressionGroupSearchItemV1: (
      params: Parameters<typeof searchLogger.impressionGroupSearchItemV1>[0]
    ) => {
      searchLogger.impressionGroupSearchItemV1(params, { useDebounce: false });
    },
  };
};

export default useKarrotSearchLogger;

export const filterSearchFilterParams = (
  filterParams: GroupSearchLogRequestBodySchema['ClickedGroupSearchItemV1RequestBody']['filterParams']
) => {
  if (!filterParams) return undefined;

  return Object.fromEntries(
    Object.entries(filterParams).filter(([_, value]) => value !== undefined)
  );
};

export const getImpressionAreaResetKey = (
  params: GroupSearchLogRequestBodySchema['ClickedGroupSearchItemV1RequestBody']
) => {
  return JSON.stringify(params);
};

export const mappedSearchItemParams = ({
  collection,
  filterParams,
  queryId,
  queryFrom,
  tab,
  index,
  documentId,
  query,
}: Omit<GroupSearchLogRequestBodySchema['ClickedGroupSearchItemV1RequestBody'], 'funnelFrom'>) => {
  return {
    collection,
    documentId,
    queryId,
    index,
    query: decodeURIComponent(query),
    funnelFrom: window?.utm?.utm_medium || 'unknown',
    queryFrom: getSafeQueryFrom(queryFrom),
    filterParams: filterSearchFilterParams(filterParams),
    tab,
  };
};

export const mappedGroupSearchV1Params = ({
  query,
  queryFrom,
  queryId,
  tab,
}: Omit<GroupSearchLogRequestBodySchema['GroupSearchV1RequestBody'], 'funnelFrom'>) => {
  return {
    query: decodeURIComponent(query),
    queryFrom: getSafeQueryFrom(queryFrom),
    funnelFrom: window?.utm?.utm_medium || 'unknown',
    queryId,
    tab,
  };
};

const QUERY_FROM_VALUES = ['unknown', 'scheme', 'typed', 'recent', 'recommend'];

const isValidQueryFrom = (queryFrom?: string): queryFrom is QueryFromType => {
  if (!queryFrom) return false;
  return QUERY_FROM_VALUES.includes(queryFrom);
};

export const getSafeQueryFrom = (queryFrom?: string): QueryFromType => {
  if (isValidQueryFrom(queryFrom)) {
    return queryFrom;
  }
  return 'unknown';
};

export const getCategoryParams = (category?: number | number[]) => {
  if (typeof category === 'number') {
    return [Number(category)];
  }

  return category;
};
