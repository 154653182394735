import { useReadSeoulRunningPromotionCheckJoinableRegionRegion } from '@/domain/SeoulRunPromotion/hook/useReadSeoulRunningPromotionCheckJoinableRegionRegion';

import useEnableSeoulRunPromotion from './useEnableSeoulRunPromotion';

type Props = { regionId: number };

// 한강 프로모션 이벤트 기간 중 한강 프로모션 이벤트 기간 중 해당 프로모션 지역에 포함된 모임인지 확인하는 훅
const useIsSeoulRunPromotionLocation = ({ regionId }: Props) => {
  const enablePromotion = useEnableSeoulRunPromotion();

  const { data: isJoinableRegion } = useReadSeoulRunningPromotionCheckJoinableRegionRegion({
    regionId,
  });

  // TODO: 수도권 확인 기능 필요
  return Boolean(enablePromotion && isJoinableRegion);
};

export default useIsSeoulRunPromotionLocation;
