import { useMemo } from 'react';

import { useReadGroupChallengeList } from '@/domain/Challenge/hooks/useReadGroupChallengeList';

type Props = {
  groupId: number;
};

const useChallengeListData = ({ groupId }: Props) => {
  const {
    data: openedChallengeData,
    fetchNextPage: fetchOpenedChallengeNextPage,
    hasNextPage: hasOpenedChallengeNextPage,
    isFetchingNextPage: isFetchingOpenedChallengeNextPage,
  } = useReadGroupChallengeList({
    id: groupId,
    status: 'opened',
  });

  const {
    data: preparedChallengeData,
    fetchNextPage: fetchPreparedChallengeNextPage,
    hasNextPage: hasPreparedChallengeNextPage,
    isFetchingNextPage: isFetchingPreparedChallengeNextPage,
  } = useReadGroupChallengeList({
    id: groupId,
    status: 'prepared',
  });

  const {
    data: closedChallengeData,
    fetchNextPage: fetchClosedChallengeNextPage,
    hasNextPage: hasClosedChallengeNextPage,
    isFetchingNextPage: isFetchingClosedChallengeNextPage,
  } = useReadGroupChallengeList({
    id: groupId,
    status: 'closed',
  });

  const hasChallengeData = useMemo(() => {
    if (!openedChallengeData || !preparedChallengeData || !closedChallengeData) return false;
    return (
      openedChallengeData.length > 0 ||
      preparedChallengeData.length > 0 ||
      closedChallengeData.length > 0
    );
  }, [openedChallengeData, preparedChallengeData, closedChallengeData]);

  return {
    opened: {
      data: openedChallengeData,
      fetchNextPage: fetchOpenedChallengeNextPage,
      hasNextPage: !!hasOpenedChallengeNextPage,
      isFetchingNextPage: isFetchingOpenedChallengeNextPage,
    },
    prepared: {
      data: preparedChallengeData,
      fetchNextPage: fetchPreparedChallengeNextPage,
      hasNextPage: !!hasPreparedChallengeNextPage,
      isFetchingNextPage: isFetchingPreparedChallengeNextPage,
    },
    closed: {
      data: closedChallengeData,
      fetchNextPage: fetchClosedChallengeNextPage,
      hasNextPage: !!hasClosedChallengeNextPage,
      isFetchingNextPage: isFetchingClosedChallengeNextPage,
    },

    hasChallengeData,
  };
};

export default useChallengeListData;
