import { ViewLoader } from '@community-group/components';

import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import Feed from '../../Detail/components/Feed';

function GroupDetailFeedListPage() {
  const { groupId } = usePathParams();
  const { data: group } = useReadGroupDetail(groupId);
  const { data: currentUser } = useReadGroupMe({ groupId });

  if (!currentUser || !group) return <ViewLoader />;
  return (
    <AppScreen appBar={{ title: '게시글' }} disabledClientOnly>
      <Feed enableCategoryFilterAllTime />
    </AppScreen>
  );
}

export default GroupDetailFeedListPage;
