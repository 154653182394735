import { GroupFeedListResponse, GroupFeedSummary } from '@community-group/api/lib/group/models';
import { InfiniteData } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { getGroupFeedListPath } from '@/api/hooks/useGetGroupFeedList';
import { queryClient } from '@/shared/api/instance';

type Props = {
  groupId?: string;
  targetId?: string;
  type: 'like' | 'dislike';
};
export const updateGroupDetailFeedList = async ({ groupId, targetId, type }: Props) => {
  if (!groupId || !targetId) return;

  const queryKey = [getGroupFeedListPath(groupId)];
  const cache = queryClient.getQueriesData({
    queryKey,
  });

  cache.forEach(([queryKey]) => {
    queryClient.setQueryData(
      queryKey,
      (prev?: InfiniteData<AxiosResponse<GroupFeedListResponse>>) => {
        if (!prev || !prev.pages) return;

        const targetItemId = parseInt(targetId);
        const next = prev.pages.map((page) => {
          const items = page.data?.items?.map((item: GroupFeedSummary) => {
            if (item.id !== targetItemId) return item;

            return {
              ...item,
              emotion: {
                count: type === 'like' ? item.emotion.count + 1 : item.emotion.count - 1,
                myEmotion: type === 'like' ? 'like' : undefined,
              },
            };
          });

          return {
            ...page,
            data: {
              ...page.data,
              items,
            },
          };
        });

        return {
          pages: next,
          pageParams: prev.pageParams,
        };
      }
    );
  });
};
