import {
  Callout,
  CalloutDescription,
  CalloutTitle,
  Spacing,
  Typography,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';

import { useQueryMyGroupList } from '@/api/hooks/useGetMyGroupList';
import PromotionLocationList from '@/features/SeoulRunPromotion/LocationListSection/LocationItemList';
import TransparentNavBar from '@/features/SeoulRunPromotion/LocationListSection/TransparentNavBar';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { ActivityQueryParams, useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import * as s from './style.css';

type Params = Pick<PageParams, 'from'> & Pick<ActivityQueryParams, 'groupId'>;

const SeoulRunLocationListPage: ActivityComponentType<Params> = () => {
  const [{ data: myGroupList }] = useSuspenseQueries({
    queries: [useQueryMyGroupList()],
  });

  const { groupId } = useQueryParams();
  const hasJoinedGroup = myGroupList.data.groups?.length > 0;

  return (
    <AppScreen hasAppBar={false}>
      <div className={s.Container}>
        <TransparentNavBar isTransparent={true} />
        <div className={s.InnerContentsContainer}>
          <div className={s.Box}>
            <Typography typography="title1Bold" color="gray900" textAlign="center">
              어디에 있는 보물을
              <br />
              찾아볼까요?
            </Typography>
            <Spacing size={8} />
            <Typography typography="bodyL1Regular" textAlign="center">
              한강공원 어쩌구저쩌구 샬라살랴라..
            </Typography>
          </div>
          <PromotionLocationList hasJoinedGroup={hasJoinedGroup} groupId={groupId} />

          <div className={s.Box}>
            <Callout width={'100%'}>
              <CalloutTitle>Q. 보물은 어떻게 찾나요?</CalloutTitle>
              <br />
              <CalloutDescription>선택한 한강공원에서 어쩌구저쩌구절구절구</CalloutDescription>
            </Callout>
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default SeoulRunLocationListPage;
