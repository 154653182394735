import { Callout, Typography } from '@community-group/components';
import { IconMapLocationpinFill } from '@daangn/react-monochrome-icon';
import React from 'react';

import * as s from './MeetupFormTopCallout.css';

const MeetupFormTopCallout = ({ poiName }: { poiName: string }) => {
  return (
    <Callout
      UNSAFE_className={s.Callout}
      icon={<IconMapLocationpinFill size={16} color="blue950" style={{ margin: 0 }} />}
      variant="info"
    >
      <Typography typography="bodyM2Regular" color="blue950">
        {poiName}에 숨어있는 보물지도를 함께 찾을 멤버들을 구해보세요.
      </Typography>
    </Callout>
  );
};

export default MeetupFormTopCallout;
