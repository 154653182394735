import { ActionListItem, ActionSheet } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';

import { useConnectedFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

export type CommentMoreMenuBottomSheetParams = Pick<
  PageParams,
  'isEqualCurrentCommentAuthor' | 'hasEmotionCount'
>;

export type CommentMoreMenuBottomSheetPopResponseType = {
  type: 'editComment' | 'showEmotionList' | 'reportComment' | 'deleteComment';
};

const CommentMoreMenuBottomSheet: ActivityComponentType<CommentMoreMenuBottomSheetParams> = () => {
  const { pop } = useConnectedFlow();

  const { isEqualCurrentCommentAuthor, hasEmotionCount, hasPermission } = useQueryParams();

  return (
    <BottomSheet style={{ padding: 0 }}>
      <ActionSheet bottomButton={{ label: '닫기' }}>
        {isEqualCurrentCommentAuthor && (
          <ActionListItem
            onClick={() => {
              pop({ animate: false }).send({
                type: 'editComment',
              });
            }}
          >
            수정
          </ActionListItem>
        )}
        {hasEmotionCount && (
          <ActionListItem
            onClick={() => {
              pop({ animate: false }).send({
                type: 'showEmotionList',
              });
            }}
          >
            공감한 이웃 목록
          </ActionListItem>
        )}
        {!isEqualCurrentCommentAuthor && (
          <ActionListItem
            onClick={() => {
              pop({ animate: false }).send({
                type: 'reportComment',
              });
            }}
          >
            신고
          </ActionListItem>
        )}
        {hasPermission && (
          <ActionListItem
            onClick={() => {
              pop({ animate: false }).send({
                type: 'deleteComment',
              });
            }}
            color={vars.$semantic.color.danger}
          >
            삭제
          </ActionListItem>
        )}
      </ActionSheet>
    </BottomSheet>
  );
};

export default CommentMoreMenuBottomSheet;
