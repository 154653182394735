import { AsyncBoundary, ViewError } from '@community-group/components';
import { useInView } from 'react-intersection-observer';

import { useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { FloatingIcon } from '@/components/common/FloatingIcon';
import { useBridge } from '@/contexts/Bridge';
import { useDeviceConfig } from '@/contexts/DeviceConfig';
import useDidMountSequentialProcess from '@/hooks/useDidMountSequentialProcess';
import { useEnterLocationEventLog } from '@/hooks/useEnterLocationEventLog';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useSetParentingGroupStorage } from '@/hooks/useSetParentingGroupStorage';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useReferQueryParams } from '@/stackflow/hooks/useReferQueryParams';
import { extendAppsflyerLoggerType } from '@/utils/analytics';
import { getDocumentColorScheme } from '@/utils/color';

import { HomeAppScreen } from '../components/HomeAppScreen';
import HomeContentsSections from '../components/HomeContentsSections';
import useActiveHomeTabActiveKey from '../hooks/useActiveHomeTabActiveKey';
import { useUserInfoForTrackEvent } from '../hooks/useUserInfoForTrackEvent';
import * as s from './GroupHome.css';
import GroupHomeSkeleton from './Skeleton';

const HomePage = () => {
  const { deviceConfig } = useDeviceConfig();
  const navigationTopHeight = deviceConfig.theme === 'android' ? '3.5rem' : '2.75rem';

  return (
    <HomeAppScreen>
      <div
        className={s.homeView}
        style={{
          minHeight: `calc(100vh - ${navigationTopHeight} - env(safe-area-inset-top))`,
        }}
      >
        <AsyncBoundary pendingFallback={<GroupHomeSkeleton />} rejectedFallback={<ViewError />}>
          <HomeWrapper />
        </AsyncBoundary>
      </div>
    </HomeAppScreen>
  );
};

export const COOKIE_KEY = 'ON_BOARDING_TALK_MODAL';

const HomeWrapper = () => {
  const { push } = useFlow();
  const { bridge } = useBridge();

  const referParams = useReferQueryParams();
  const { showEntryPointGuide } = useQueryParams();
  const [shownEntryPointGuideBottomSheet] = useStorage('shownEntryPointGuideBottomSheet', false);

  const { ref, inView } = useInView({ rootMargin: '0px', threshold: 0.1, initialInView: true });

  useSetParentingGroupStorage();

  useEnterLocationEventLog({
    eventName: 'enter_group_home',
  });

  const userInfo = useUserInfoForTrackEvent();
  useEnterTrackEvent({
    event: 'enter_home',
    params: {
      ...referParams,
      ...userInfo,
      displayAppearance: getDocumentColorScheme(),
    },
    sample: true,
    loggerType: extendAppsflyerLoggerType,
  });

  const { data: myGroups } = useGetMyGroupList();

  const { activeTabKey, setActiveTabKey } = useActiveHomeTabActiveKey();

  const renderFloatingButton = () => {
    if (activeTabKey === 'myGroupTab') return;
    if (activeTabKey === 'explorerGroupTab') {
      return (
        <FloatingIcon
          onClick={() => {
            bridge.setHapticSelect({});

            push('GroupNewSetGroupCategoryRecommendPage', {});
          }}
          hideText={!inView}
          text={'모임 만들기'}
        />
      );
    }

    return (
      <FloatingIcon
        onClick={() => {
          bridge.setHapticSelect({});
          if (myGroups && myGroups.groups.length < 1) {
            push('GroupNewSetGroupCategoryRecommendPage', {});
            return;
          }
          push('BottomSheet/GroupMeetupCreateBottomSheet', {});
        }}
        type="MEETUP_CREATE"
        hideText={!inView}
      />
    );
  };

  useDidMountSequentialProcess([
    () => {
      // 모임은 동네생활 탭에서 찾을 수 있어요. 안내 바텀시트
      if (showEntryPointGuide === 'true' && !shownEntryPointGuideBottomSheet) {
        push('BottomSheet/GroupEntryPointGuideBottomSheet', {});
        return true;
      }
      return false;
    },
  ]);

  return (
    <>
      <div className={s.homeContainer}>
        <div ref={ref} style={{ height: '1px' }} />
        <HomeContentsSections activeTabKey={activeTabKey} setActiveTabKey={setActiveTabKey} />
      </div>

      {renderFloatingButton()}
    </>
  );
};

export default HomePage;
