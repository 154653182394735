import { CommentDetail } from '@community-group/api/lib/group/models';
import {
  ActionListItem,
  ActionSheet,
  Dialog,
  isNotMember,
  useBottomSheet,
} from '@community-group/components';
import { useDialog, useStickInputStore } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { KeyboardEvent, memo, MouseEvent, PropsWithChildren } from 'react';

import { useDeleteComment } from '@/api/hooks/useDeleteComment';
import { useDeleteCommentEmotion } from '@/api/hooks/useDeleteCommentEmotion';
import { getMeetupCommentPath, getPostCommentPath } from '@/api/hooks/useGetComment';
import { getCommentEmotionsPath } from '@/api/hooks/useGetCommentEmotions';
import { getMeetupCommentsPath, getPostCommentsPath } from '@/api/hooks/useGetComments';
import { usePatchCommentEmotion } from '@/api/hooks/usePatchCommentEmotion';
import CommentFeedItem from '@/components/common/CommentFeedItem/CommentFeedItem';
import SubCommentList from '@/components/common/CommentFeedItem/SubCommentList';
import CommentEmotionCountMessage from '@/components/common/EmotionCountMessage/CommentEmotionCountMessage';
import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useBridge } from '@/contexts/Bridge';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { queryClient } from '@/shared/api/instance';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { GroupRoutes } from '@/utils/analytics/type';
import { openGroupCommentReport } from '@/utils/link';

import * as s from './CommentFeed.css';

type Props = PropsWithChildren<{
  groupId: string;
  relatedId: string;
  commentId: string;
  relatedContentType: 'post' | 'meetup';
  comments: CommentDetail[];
  disabledCommentInput: boolean;
}>;

const CommentFeed = ({
  groupId,
  relatedId,
  commentId,
  comments,
  relatedContentType,
  disabledCommentInput,
  children,
}: Props) => {
  const isCommentDetailPage = Boolean(commentId);
  const checkDisabledCommentInput = (comment: CommentDetail) => {
    const isDeletedComment = Boolean(comment.deletedAt);
    const isDeletedAccount = Boolean(comment.author.isAccountDeleted);

    return disabledCommentInput || isDeletedComment || isDeletedAccount;
  };

  const { setFocused } = useStickInputStore();

  const { data: me } = useReadGroupMe({ groupId });
  const { data: group } = useReadGroupDetail(groupId);

  const handleErrorWithToast = useHandleErrorWithToast();

  const { mutate: mutateDeleteComment } = useDeleteComment({
    relatedContentType,
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      if (relatedContentType === 'post') {
        queryClient.refetchQueries({
          queryKey: [getPostCommentsPath(groupId, relatedId)],
        });
        queryClient.refetchQueries({
          queryKey: [getPostCommentPath(groupId, relatedId, commentId)],
        });
      } else {
        queryClient.refetchQueries({
          queryKey: [getMeetupCommentsPath(groupId, relatedId)],
        });
        queryClient.refetchQueries({
          queryKey: [getMeetupCommentPath(groupId, relatedId, commentId)],
        });
      }
    },
  });

  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();
  const { open: openDialog, close: closeDialog } = useDialog();

  const openCommentEmotionListPage = (targetCommentId: number) => {
    push('CommentEmotionListPage', {
      groupId,
      relatedId,
      commentId: targetCommentId.toString(),
      relatedContentType,
    });

    trackEvent({
      event: 'click_like_list',
      params: {
        type: 'comment',
        contentType: 'comment',
      },
      sample: true,
    });
  };

  const handleMenuClick = (comment: CommentDetail) => async (e: MouseEvent) => {
    e.stopPropagation();

    const commentId = comment.id.toString();
    const currentUserId = me.id;
    const commentAuthorId = comment.author.id;

    const hasEmotionCount = comment.emotion && comment.emotion.count > 0;
    const moveCommentEmotionListPage = async () => {
      await closeBottomSheet();
      openCommentEmotionListPage(comment.id);
    };

    const isEqualCurrentCommentAuthor = currentUserId === commentAuthorId;
    const hasDeletePermission = me.permissions.deletePostAndComment;

    const hasPermission = isEqualCurrentCommentAuthor || hasDeletePermission;

    if (hasPermission) {
      await openBottomSheet({
        element: (
          <ActionSheet
            bottomButton={{
              label: '닫기',
            }}
          >
            {isEqualCurrentCommentAuthor && (
              <ActionListItem
                onClick={async () => {
                  await closeBottomSheet();
                  push('CommentEditPage', {
                    groupId,
                    relatedId: relatedId,
                    commentId,
                    relatedContentType,
                  });
                }}
              >
                수정
              </ActionListItem>
            )}
            {hasEmotionCount && (
              <ActionListItem onClick={moveCommentEmotionListPage}>공감한 이웃 목록</ActionListItem>
            )}
            {!isEqualCurrentCommentAuthor && (
              <ActionListItem
                onClick={async () => {
                  await closeBottomSheet();
                  trackEvent({
                    event: 'click_report',
                    params: {
                      type: 'comment',
                    },
                  });
                  openGroupCommentReport(commentId, commentAuthorId.toString());
                }}
              >
                신고
              </ActionListItem>
            )}
            <ActionListItem
              onClick={async () => {
                await closeBottomSheet();

                openDialog({
                  element: (
                    <Dialog
                      title="댓글을 삭제할까요?"
                      description="모든 데이터가 삭제되고 다시 볼 수 없어요."
                      primaryActionLabel="삭제"
                      secondaryActionLabel="취소"
                      onPrimaryAction={async () => {
                        trackEvent({
                          event: 'click_delete',
                          params: {
                            type: 'comment',
                          },
                        });
                        await closeDialog();
                        await mutateDeleteComment({
                          groupId,
                          relatedId,
                          commentId,
                        });
                      }}
                      onSecondaryAction={async () => {
                        trackEvent({
                          event: 'click_cancel',
                          params: {
                            type: 'delete',
                          },
                        });
                        await closeDialog();
                      }}
                    />
                  ),
                });
              }}
              color={vars.$semantic.color.danger}
            >
              삭제
            </ActionListItem>
          </ActionSheet>
        ),
        onDimClose: closeBottomSheet,
      });

      return;
    }

    await openBottomSheet({
      element: (
        <ActionSheet
          bottomButton={{
            label: '닫기',
          }}
        >
          {hasEmotionCount && (
            <ActionListItem onClick={moveCommentEmotionListPage}>공감한 이웃 목록</ActionListItem>
          )}
          <ActionListItem
            onClick={async () => {
              trackEvent({
                event: 'click_report',
                params: {
                  type: 'comment',
                },
              });
              await closeBottomSheet();
              openGroupCommentReport(commentId, commentAuthorId.toString());
            }}
          >
            신고
          </ActionListItem>
        </ActionSheet>
      ),
      onDimClose: closeBottomSheet,
    });
  };

  const { push } = useFlow();
  const { bridge } = useBridge();
  const handleProfileClick = (item: CommentDetail) => (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation();
    if (item.author.isAccountDeleted) return;

    trackEvent({
      event: 'click_profile',
      params: {
        referrer: GroupRoutes.GROUP_DETAIL,
        clickedUserId: item.author?.id,
        clickedUserRole: item.author?.role,
        clickedUserState: item.author?.state,
        type: 'comment',
      },
    });
    setTimeout(() => {
      push('GroupUserProfileDetailPage', {
        groupId,
        userId: item.author.id.toString(),
      });
    }, 200);
  };

  const { mutateAsync: mutateLikeComment } = usePatchCommentEmotion({
    relatedContentType,
    onError: handleErrorWithToast,
    onSuccess: () => {
      trackEvent({
        event: 'click_like',
        params: {
          contentType: 'comment',
        },
        sample: true,
      });
    },
  });

  const { handleGroupOnly } = useHandleGroupOnly({ groupId });
  const handleCommentLikeClick = (commentId: number) => (e: MouseEvent) => {
    e.stopPropagation();
    bridge.setHapticLightFeedback({});

    const likeComment = async () => {
      await mutateLikeComment({ groupId, relatedId, commentId: commentId.toString() });
      const queryKey = getCommentEmotionsPath(
        Number(groupId),
        Number(relatedId),
        commentId,
        relatedContentType
      );
      queryClient.refetchQueries({
        queryKey: [queryKey],
      });
    };

    const blockedGroupOnly = isNotMember(me.role);
    handleGroupOnly({
      isBlock: !blockedGroupOnly,
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getCommentReaction,
      onSettled: likeComment,
      onSuccess: async () => {
        if (group?.subNicknameSetting.isSettingOn) {
          push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
            groupId,
          });
        }
        await likeComment();
      },
    });
  };

  const { mutateAsync: mutateDislikeComment } = useDeleteCommentEmotion({
    relatedContentType,
    onError: handleErrorWithToast,
    onSuccess: () => {
      trackEvent({
        event: 'click_like',
        params: {
          contentType: 'comment',
        },
        sample: true,
      });
    },
  });
  const handleCommentDislikeClick = (commentId: number) => async (e: MouseEvent) => {
    e.stopPropagation();
    bridge.setHapticLightFeedback({});

    await mutateDislikeComment({ groupId, relatedId, commentId: commentId.toString() });
    const queryKey = getCommentEmotionsPath(Number(groupId), Number(relatedId), commentId, 'post');
    queryClient.refetchQueries({
      queryKey: [queryKey],
    });
  };

  const handleCommentReplyClick = (comment: CommentDetail) => (e: MouseEvent) => {
    if (isCommentDetailPage) {
      e.stopPropagation();
      setFocused(true);
      return;
    }

    openCommentDetailPage(comment)(e);
  };

  const openCommentDetailPage = (comment: CommentDetail) => (e: MouseEvent) => {
    e.stopPropagation();
    if (isCommentDetailPage) return;

    push('CommentDetailPage', {
      groupId,
      relatedId: relatedId,
      commentId: comment.id.toString(),
      relatedContentType,
      commentCount: comment.subCommentCount?.toString(),
      groupName: group?.name,
      disabledCommentInput: checkDisabledCommentInput(comment) ? 'true' : 'false',
      screenName:
        relatedContentType === 'post'
          ? 'groupPostDetailCommentDetail'
          : 'groupMeetupDetailCommentDetail',
    });
  };

  return (
    <div className={s.FeedItemList}>
      {comments.map((comment: CommentDetail) => {
        const isSubComment = Boolean(comment.parentId);
        const isDeletedAccount = Boolean(comment.author.isAccountDeleted);
        const handleContentClick = openCommentDetailPage(comment);

        return (
          <CommentFeedItem
            item={comment}
            key={comment.id}
            onMenuClick={handleMenuClick(comment)}
            onProfileClick={handleProfileClick(comment)}
            onLikeReactionClick={handleCommentLikeClick(comment.id)}
            onDislikeReactionClick={handleCommentDislikeClick(comment.id)}
            onContentClick={handleContentClick}
            onReplyClick={handleCommentReplyClick(comment)}
          >
            <>
              {!isDeletedAccount && !isSubComment && isCommentDetailPage && (
                <CommentEmotionCountMessage
                  groupId={groupId}
                  relatedId={relatedId}
                  commentId={String(comment.id)}
                  relatedContentType={relatedContentType}
                  commentEmotionCount={comment.emotion.count}
                  hasCurrentUserEmotion={Boolean(comment.emotion.myEmotion)}
                  onClick={() => openCommentEmotionListPage(comment.id)}
                />
              )}
              {comment.subComments && comment.subComments.length > 0 && (
                <SubCommentList
                  items={comment.subComments}
                  totalCount={comment.subCommentCount ?? 0}
                  currentUser={me}
                  isCommentDetailPage={isCommentDetailPage}
                  disabledSubCommentInput={checkDisabledCommentInput(comment)}
                  onReadMoreClick={handleContentClick}
                  onSubCommentMenuClick={handleMenuClick}
                  onSubCommentContentClick={handleContentClick}
                  onSubCommentInputClick={handleContentClick}
                  onSubCommentLikeReactionClick={handleCommentLikeClick}
                  onSubCommentDislikeReactionClick={handleCommentDislikeClick}
                  onSubCommentProfileClick={handleProfileClick}
                />
              )}
            </>
          </CommentFeedItem>
        );
      })}
      {children}
    </div>
  );
};

export default memo(CommentFeed);
