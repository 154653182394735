import { cloneDeep, isEqual } from 'lodash-es';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { GroupPostFormHandlerProps } from '@/components/group/PostForm/components/GroupPostForm';
import { GroupTemporalPost } from '@/components/group/PostForm/page/GroupPostNewPage';
import { useBridge } from '@/contexts/Bridge';
import { useStorage } from '@/hooks/useStorage';

import { GROUP_SAVED_POST_KEY } from './useMakeSavedFormValue';

type Props = {
  groupId: string;
  formHandler: UseFormReturn<GroupPostFormHandlerProps, any, undefined>;
  defaultPostFormValues: GroupPostFormHandlerProps;
};

export const useSaveTemporalGroupPost = ({
  groupId,
  formHandler,
  defaultPostFormValues,
}: Props) => {
  const { bridge } = useBridge();
  const [temporalPostMap, setTemporalPostMap] = useStorage(GROUP_SAVED_POST_KEY, {
    [groupId]: {} as GroupTemporalPost,
  });
  useEffect(() => {
    return () => {
      const { watch, getValues } = formHandler;
      const newValues = getValues();
      if (newValues.postType?.type !== 'post') return;
      const temporalPost = cloneDeep({
        ...newValues,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        originImages: watch('originImages')
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .filter(({ id }) => !id.includes('temp') && !id.includes('error'))
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .map(({ id, image, imageUrl }) => ({
            id,
            image: typeof image === 'string' ? image : imageUrl,
          })),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        uploadVideos: watch('uploadVideos').filter(
          ({ status }) => status !== 'failed' && status !== 'uploading'
        ),
      });
      if (isEqual(defaultPostFormValues, temporalPost))
        return setTemporalPostMap({
          ...temporalPostMap,
          [groupId]: {} as GroupTemporalPost,
        });

      setTemporalPostMap({
        ...temporalPostMap,
        [groupId]: temporalPost,
      });
      bridge.openToast({ toast: { body: '게시글이 임시 저장되었어요.' } });
    };
  }, []);
};
