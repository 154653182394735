import {
  Divider,
  Spacing,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { Suspense, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import ImpressionTriggeredComponents from '@/components/common/Impression/ImpressionTriggeredComponents';
import { useQueryChallengeDetail } from '@/domain/Challenge/hooks/useReadChallengeDetail';
import { useQueryChallengeMyCertifyActivity } from '@/domain/Challenge/hooks/useReadChallengeMyCertifyActivity';
import { useQueryMyChallengeCalendar } from '@/domain/Challenge/hooks/useReadMyChallengeCalendar';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import MyChallengeCalendar from '@/features/ChallengeDetail/MyChallengeInfoSection/MyChallengeCalendar';
import MyChallengeStatus from '@/features/ChallengeDetail/MyChallengeInfoSection/MyChallengeStatus';
import CertifyCheerUpText from '@/features/GroupChallengeCertify/MyCertifyResult/CertifyCheerUpText';
import CertifyList from '@/features/GroupChallengeCertify/MyCertifyResult/CertifyList';
import CertifyRankList from '@/features/GroupChallengeCertify/MyCertifyResult/CertifyRankList';
import CertifyResultHeroSection from '@/features/GroupChallengeCertify/MyCertifyResult/CertifyResultHeroSection';
import MyCertifyTags from '@/features/GroupChallengeCertify/MyCertifyResult/MyCertifyTags';
import TransparentNavBar from '@/features/GroupChallengeCertify/MyCertifyResult/TransparentNavBar';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import * as s from './style.css';

type Params = Pick<PageParams, 'groupId' | 'postId' | 'challengeId'>;

const ChallengeMyDailyCertifyResultPage: ActivityComponentType<Params> = () => {
  const { groupId, postId, challengeId } = usePathParams();

  const [
    { data: groupMe },
    { data: myCertifyActivity },
    { data: calendarData },
    { data: challengeDetail },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupMe({ groupId }),
      useQueryChallengeMyCertifyActivity({
        groupId: Number(groupId),
        challengeId: Number(challengeId),
      }),
      useQueryMyChallengeCalendar({ groupId: Number(groupId), challengeId: Number(challengeId) }),
      useQueryChallengeDetail({ groupId, challengeId }),
    ],
  });

  const { ref, inView } = useInView({
    root: null,
    threshold: 1,
    triggerOnce: false,
    initialInView: true,
  });

  const isCertifyEveryDay = useMemo(() => {
    return challengeDetail.certifyIntervalTargetCount === 7;
  }, [challengeDetail.certifyIntervalTargetCount]);

  return (
    <AppScreen hasAppBar={false}>
      <div className={s.Container}>
        <TransparentNavBar isTransparent={inView} />
        <ImpressionTriggeredComponents ref={ref} />
        <CertifyResultHeroSection
          totalCertifyCount={myCertifyActivity.totalCertifiedProgress.current}
        />
        <Spacing size={24} />
        <div className={s.StatusWrapper}>
          <MyChallengeStatus
            myCertifyActivity={myCertifyActivity}
            isCertifyEveryDay={isCertifyEveryDay}
          />
        </div>
        <Spacing size={24} />
        <div className={s.Wrapper}>
          <CertifyList
            groupMe={groupMe}
            groupId={Number(groupId)}
            challengeId={Number(challengeId)}
          />
        </div>
        <Spacing size={24} />
        <Divider size={1} direction="horizontal" background={vars.$semantic.color.divider1} />
        <Spacing size={24} />
        <CertifyCheerUpText
          certifiedRate={myCertifyActivity.certifiedRate}
          isCertifyEveryDay={isCertifyEveryDay}
          continuousCertifiedProgress={myCertifyActivity.continuousCertifiedProgress}
        />

        <Spacing size={20} />
        <div className={s.PaddingX}>
          <MyChallengeCalendar groupId={Number(groupId)} calendarData={calendarData} />
        </div>

        <Spacing size={20} />
        <div className={s.PaddingX}>
          <MyCertifyTags
            myCertifyActivity={myCertifyActivity}
            isCertifyEveryDay={isCertifyEveryDay}
          />
        </div>

        <Spacing size={24} />
        <Divider size={1} direction="horizontal" background={vars.$semantic.color.divider1} />
        <Spacing size={24} />
        <div className={s.PaddingX}>
          <Typography typography="title3Bold" color="gray900">
            전체 인증 현황
          </Typography>
        </div>
        <Spacing size={20} />
        <Suspense>
          <CertifyRankList groupId={Number(groupId)} challengeId={Number(challengeId)} />
        </Suspense>
        <Spacing size={40} />
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(ChallengeMyDailyCertifyResultPage, {
  rejectedFallback: (
    <AppScreen hasAppBar={false}>
      <ViewError />
    </AppScreen>
  ),
  pendingFallback: (
    <AppScreen hasAppBar={false}>
      <ViewLoader />
    </AppScreen>
  ),
});
