import { GroupChallengeCreateForm } from '@community-group/api/lib/group/models';
import { ChipRadio, ChipRadioGroup, Typography } from '@community-group/components';
import { IconCalendarLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import React, { useMemo } from 'react';

import { GroupChallengeFormProps } from '../../types';
import { GroupChallengeFormFieldWrapper } from '../FieldWrapper';
import * as s from './style.css';

type Props = GroupChallengeFormProps & {
  dateFieldName: keyof GroupChallengeCreateForm;
  onClick?: () => void;
};

const GroupChallengeFormDurationField = ({ formHandler, dateFieldName, onClick }: Props) => {
  const dayValue = formHandler.watch(dateFieldName);

  const duration = useMemo(() => {
    const day = Number(dayValue);

    const week = Math.floor(day / 7);
    if (week === 52) return '1년';
    return `${week}주`;
  }, [dayValue]);

  return (
    <GroupChallengeFormFieldWrapper
      onClick={onClick}
      fieldIcon={<IconCalendarLine size={24} color={vars.$scale.color.gray900} />}
      fieldLabel="진행 기간"
      fieldName={dateFieldName}
      fieldValue={
        <Typography textAlign="right" typography="label2Regular" color="gray900">
          {duration}
        </Typography>
      }
    >
      <div className={s.Wrapper}>
        <ChipRadioGroup
          value={formHandler.watch(dateFieldName)?.toString()}
          onChange={(value) => {
            formHandler.setValue(dateFieldName, Number(value));
          }}
        >
          <ChipRadio value="7">1주</ChipRadio>
          <ChipRadio value="14">2주</ChipRadio>
          <ChipRadio value="21">3주</ChipRadio>
          <ChipRadio value="28">4주</ChipRadio>
          <ChipRadio value="364">1년</ChipRadio>
        </ChipRadioGroup>
      </div>
    </GroupChallengeFormFieldWrapper>
  );
};

export default GroupChallengeFormDurationField;
