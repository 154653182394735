import { groupClient } from '@community-group/api';
import { ApiV1GroupsMeetupsWithGroupPostRequestParams } from '@community-group/api/lib/group/api/meetup-api';

import { useRead } from '@/shared/api/hooks/useRead';
import {
  useThrottleMutation,
  UseThrottleMutationOption,
} from '@/shared/api/hooks/useThrottleMutation';

type Props = UseThrottleMutationOption;
export const usePostSeoulRunMeetup = (props: Props) => {
  const fetch = useRead(groupClient.api.MeetupApi.apiV1GroupsMeetupsWithGroupPost);

  const mutation = ({ body }: ApiV1GroupsMeetupsWithGroupPostRequestParams) => fetch({ body });

  return useThrottleMutation(mutation, props);
};
