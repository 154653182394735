import { Image, Spacing, Typography } from '@community-group/components';
import {
  IconClockFill,
  IconExclamationmarkCircleLine,
  IconPerson2Fill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { HTMLAttributes, ReactElement, useCallback } from 'react';

import { formatDate } from '@/utils/date';

import * as s from './index.css';

type Props = {
  title: string;
  label: ReactElement;
  meetDate: string;
  currentParticipantsNumber: number;
  maximumParticipantsNumber: number;
  image?: string;
  meetTime?: string;
  showMonth?: boolean;
  blockedGroupOnly?: boolean;
  isDeleted?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const GroupDetailFeedMeetupItem = ({
  title,
  label,
  meetDate,
  currentParticipantsNumber,
  maximumParticipantsNumber,
  image,
  meetTime,
  showMonth = false,
  blockedGroupOnly,
  isDeleted,
  ...props
}: Props) => {
  const DisplayTitle = useCallback(() => {
    if (!isDeleted && !blockedGroupOnly) return <>{title}</>;

    return (
      <div className={s.GroupOnlyTitle}>
        <IconExclamationmarkCircleLine size={18} fill={vars.$scale.color.gray600} />
        <span>{isDeleted ? '삭제된 일정이에요.' : '모임에만 공개된 일정이에요.'}</span>
      </div>
    );
  }, [isDeleted, blockedGroupOnly, title]);

  return (
    <div {...props} className={clsx([props.className, s.Container])}>
      <div className={s.DateWrapper}>
        {showMonth ? (
          <>
            <Typography typography="caption2Regular" color="gray700">
              {formatDate(new Date(meetDate), 'M월')}
            </Typography>
            <Typography typography="label1Bold" color="gray700">
              {formatDate(new Date(meetDate), 'd')}
            </Typography>
          </>
        ) : (
          <>
            <Typography typography="label1Bold" color="gray700">
              {formatDate(new Date(meetDate), 'd')}
            </Typography>
            <Typography typography="caption2Regular" color="gray700">
              {formatDate(new Date(meetDate), 'E')}
            </Typography>
          </>
        )}
      </div>
      <div className={s.ContentsContainer}>
        <Typography
          typography="subtitle1Bold"
          color="gray900"
          ellipsisAfterLines={1}
          style={{ maxWidth: '100%' }}
        >
          <DisplayTitle />
        </Typography>
        <Spacing size={4} />
        <Typography typography="caption1Regular" color="gray600">
          {label}
        </Typography>
        <Spacing size={8} />
        <div className={s.InfoWrapper}>
          {meetTime && (
            <div className={s.InfoItemWrapper}>
              <IconClockFill
                size={15}
                color={vars.$scale.color.gray500}
                style={{ paddingRight: '4px' }}
              />
              {formatDate(new Date(meetTime), 'aa h:mm', {
                timeZone: 'Asia/Seoul',
              })}
            </div>
          )}
          <div className={s.InfoItemWrapper}>
            <IconPerson2Fill
              size={15}
              color={vars.$scale.color.gray500}
              style={{ paddingRight: '4px' }}
            />
            {currentParticipantsNumber}/{maximumParticipantsNumber}명
          </div>
        </div>
      </div>
      {image && (
        <div className={s.ImageWrapper}>
          <Image src={image} width={72} height={72} />
        </div>
      )}
    </div>
  );
};

export default GroupDetailFeedMeetupItem;
