import {
  UploadVideoValidationError,
  useDialog,
  useVideoFileHandler,
  VideoFileHandlerProps,
  VideoUploadErrorType,
} from '@community-group/components';
import * as Sentry from '@sentry/react';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useUploadVideo } from '@/api/base/uploadVideo';
import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { StackflowTheme } from '@/components/common/TransparentNavScreen/Navbar';
import { useTheme } from '@/stackflow/hooks/useTheme';

import AlertModal from '../components/AlertModal';
import { GroupPostFormHandlerProps } from '../components/GroupPostForm';

type Props = {
  formHandler: UseFormReturn<GroupPostFormHandlerProps>;
} & Partial<VideoFileHandlerProps>;

const MAX_UPLOAD_VIDEO_COUNT = 10;

// 에러 우선순위 설정
const ERROR_PRIORITY: { [key in VideoUploadErrorType]: number } = {
  fileCount: 1, // 파일 개수 초과 에러
  videoDuration: 2, // 동영상 길이 에러
  fileSize: 3, // 파일 크기 에러
  videoDurationError: 4, // 동영상 길이 메타데이터 에러 - 제보 https://daangn.slack.com/archives/C03RY6TNUBD/p1728970900108469
  fileFormat: 5, // 파일 형식 에러
  fileType: 6, // 파일 타입 에러
  undefinedFile: 7, // 파일 없음 에러
  uploadError: 8, // 업로드 에러
};

function useHandleUploadVideo({ formHandler, ...rest }: Props) {
  const { watch, setValue, getValues } = formHandler;

  const fetchInstance = useFetchInstance();
  const { mutateAsync: mutateUploadVideo } = useUploadVideo({ fetchInstance });
  const { open: openDialog, close: closeDialog } = useDialog();

  const uploadedImagesCount = watch('images')?.length ?? 0;

  const { getTheme } = useTheme();
  const deviceType = useMemo(() => getTheme(), []) as StackflowTheme;

  const temporalSentryLogger = (error: any) => {
    Sentry.captureMessage('getVideoDuration error', {
      extra: {
        fileMetaError: error,
      },
    });
  };
  const onValidationError = (error: UploadVideoValidationError[]) => {
    const sortPrimaryError = error.sort((a, b) => ERROR_PRIORITY[a.type] - ERROR_PRIORITY[b.type]);

    const primaryError = sortPrimaryError[0];

    openDialog({
      element: (
        <AlertModal
          title={primaryError.message.title}
          description={primaryError.message.description ?? ''}
          primaryActionLabel={'확인'}
          onPrimaryAction={closeDialog}
          onClose={closeDialog}
        />
      ),
    });
  };

  return useVideoFileHandler({
    theme: deviceType,
    temporalSentryLogger,
    validationConfig: {
      maxVideoCount: MAX_UPLOAD_VIDEO_COUNT - uploadedImagesCount,
    },
    externalStateSelector: {
      externalState: watch('uploadVideos'),
      externalStateHandler: (updater) => {
        const currentUploads = getValues('uploadVideos');
        setValue('uploadVideos', updater(currentUploads));
      },
    },
    uploadVideo: async ({ file, onProgress }) => {
      const data = await mutateUploadVideo({
        file,
        onProgress,
      });
      return data;
    },
    onValidationError,
    ...rest,
  });
}

export default useHandleUploadVideo;
