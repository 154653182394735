import { useCallback, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { ImpressionProps } from '.';

export const useImpression = ({
  onStart = () => {},
  threshold = 0,
  actionType = 'once',
  delay = 0,
  initialInView,
  rootMargin = '0px',
}: Omit<ImpressionProps, 'chlidren'>) => {
  const startCallbackRef = useRef(onStart);

  useEffect(() => {
    startCallbackRef.current = onStart;
  }, [onStart]);

  const handleImpressionStart = useCallback(
    (...args: any) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return startCallbackRef.current(...args);
    },
    [startCallbackRef]
  );

  const action = {
    always: {},
    once: { triggerOnce: true },
    none: { skip: true },
  };

  const { ref, inView } = useInView({
    root: null,
    rootMargin,
    threshold,
    delay,
    initialInView,
    ...action[actionType],
  });

  useEffect(() => {
    if (inView) {
      handleImpressionStart();
    }
  }, [ref, inView, actionType]);

  return ref;
};
