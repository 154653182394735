import { Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { motion } from 'framer-motion';
import { MouseEventHandler, PropsWithChildren, ReactNode, useMemo } from 'react';

import * as s from './index.css';
import MemberMissionCircleProgress from './MemberMissionCircleProgress';

type Props = PropsWithChildren<{
  title: string;
  progress: number;
  size: 'small' | 'large';
  actionButton: ReactNode;
  onClick: MouseEventHandler;
}>;

/** @todo 모임 가입 미션 배너 -> GroupOnboardingMissionBanner로 이름 변경 필요 */
const MemberMissionBanner = ({ title, progress, size, actionButton, onClick, children }: Props) => {
  const isLarge = size === 'large';
  const titleTypography = isLarge ? 'subtitle2Bold' : 'caption1Bold';
  const descriptionTypography = isLarge ? 'caption1Regular' : 'caption2Regular';
  const spacingSize = isLarge ? 16 : 12;

  const progressText = useMemo(() => {
    const progressTextPrefix = isLarge ? '미션 ' : '';

    switch (true) {
      case progress === 100:
        return '축하해요 미션을 모두 달성했어요!';
      case progress === 0:
        return '미션을 달성하고 멤버들과 친해져요!';
      default:
        return `${progressTextPrefix}달성까지 ${100 - progress}% 남았어요!`;
    }
  }, [isLarge, progress]);

  return (
    <motion.div className={s.Container({ size })}>
      <div className={s.Header} onClick={onClick}>
        <MemberMissionCircleProgress progress={progress} size={size} />
        <VerticalSpacing size={spacingSize} />
        <div className={s.HeaderContent}>
          <Typography typography={descriptionTypography} color="gray900">
            {progressText}
          </Typography>
          <Spacing size={2} />
          <Typography typography={titleTypography} color="gray900">
            {title}
          </Typography>
        </div>
        <VerticalSpacing size={spacingSize} />
        {actionButton}
      </div>
      {children}
    </motion.div>
  );
};

export default MemberMissionBanner;
