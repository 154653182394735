import { GroupPostFormHandlerProps } from '@/components/group/PostForm/components/GroupPostForm';
import { GroupTemporalPost } from '@/components/group/PostForm/page/GroupPostNewPage';
import { useStorage } from '@/hooks/useStorage';

type Props = {
  groupId: string;
  defaultPostFormValues: GroupPostFormHandlerProps;
};

export const GROUP_SAVED_POST_KEY = 'groupSavedPost' as const;

export const useMakeSavedFormValue = ({ groupId, defaultPostFormValues }: Props) => {
  const [temporalPostMap] = useStorage(GROUP_SAVED_POST_KEY, {
    [groupId]: {} as GroupTemporalPost,
  });

  const isInitialPublishPost = defaultPostFormValues.isPublished === true;
  const isPostFormIsGroupOnly = defaultPostFormValues.isGroupOnly === true;

  const initialPostOption = isInitialPublishPost
    ? {
        isGroupOnly: false,
        isPublished: true,
      }
    : {
        isGroupOnly: isPostFormIsGroupOnly,
        isPublished: false,
      };

  const temporalPost = temporalPostMap?.[groupId];
  return {
    ...initialPostOption,
    content: temporalPost?.content
      ? temporalPost?.content
      : decodeURIComponent(defaultPostFormValues.content),
    images: temporalPost?.images ?? defaultPostFormValues.images,
    originImages: temporalPost?.originImages ?? [],
    prevImages: temporalPost?.prevImages ?? [],
    isNoticed: temporalPost?.isNoticed ?? defaultPostFormValues.isNoticed,
    mentionedUserIds: temporalPost?.mentionedUserIds ?? [],
    poiStreams: temporalPost?.poiStreams ?? [],
    postType: {
      type: temporalPost?.postType?.type ?? defaultPostFormValues.postType?.type,
      meetupId: temporalPost?.postType?.meetupId ?? defaultPostFormValues.postType?.meetupId,
      challengeId:
        temporalPost?.postType?.challengeId ?? defaultPostFormValues.postType?.challengeId,
    },
    boardCategoryIds: temporalPost?.boardCategoryIds ?? defaultPostFormValues.boardCategoryIds,
    poll: temporalPost?.poll ?? defaultPostFormValues.poll,
    uploadVideos: temporalPost?.uploadVideos ?? defaultPostFormValues.uploadVideos,
  } as GroupPostFormHandlerProps;
};
