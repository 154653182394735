import { groupClient } from '@community-group/api';
import {
  ChallengeListResponse,
  ChallengeWithGroupSummaryPresentation,
} from '@community-group/api/lib/group/models';
import { InfiniteData, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useRead } from '@/shared/api/hooks/useRead';

import { GROUP_CHALLENGE_QUERY_KEY } from '../queries';

// 내가 가입가능한 모임의 챌린지 목록 조회
export const useReadChallengeList = ({
  initialCursor = undefined,
  limit = 30,
}: {
  initialCursor?: string;
  limit?: number;
}) => {
  const read = useRead(groupClient.api.GroupChallengeApi.apiV1ChallengesGet);

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: GROUP_CHALLENGE_QUERY_KEY.challengeList(),
      queryFn: ({ pageParam = initialCursor }) => {
        return read({ cursor: pageParam, limit });
      },
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) =>
        data.pagination?.hasNext ? data?.pagination.endCursor : undefined,
      refetchInterval: 10000,
      select: selectPaginationFlatten,
    });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};

function selectPaginationFlatten(
  data: InfiniteData<{ data: ChallengeListResponse }> | undefined
): ChallengeWithGroupSummaryPresentation[] {
  if (!data) return [];
  return data.pages.flatMap((page) => page.data.contents ?? []);
}
