import {
  ActionableCallout,
  AsyncBoundary,
  BoxButton,
  CalloutDescription,
  Spacing,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconPerson2Openarms } from '@daangn/react-multicolor-icon';
import { ActivityComponentType } from '@stackflow/react';
import { useState } from 'react';
import { $Values } from 'utility-types';

import { ORDER_HOME } from '@/api/base/group';
import { useGetCurationList } from '@/api/hooks/useGetCurationList';
import EmptySection from '@/components/common/Empty';
import { FloatingIcon } from '@/components/common/FloatingIcon';
import { View } from '@/components/common/View';
import ListSkeleton from '@/components/group/Home/components/ExplorerGroupTab/ExplorerGroupListSection/ListSkeleton';
import { SearchResultItem } from '@/components/group/Search/components/SearchResultList/SearchResultItem';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useReferQueryParams } from '@/stackflow/hooks/useReferQueryParams';
import { trackEvent } from '@/utils/analytics';
import { useJuniorGroupExperiment } from '@/utils/analytics/experiment/experiments/useJuniorGroupExperiment';

import CurationFilterSkeleton from './CurationFilterSkeleton';
import { curationKeywords } from './curationKeywords';
import GroupCurationWithKeywordListFilter from './GroupCurationWithKeywordListFilter';
import GroupCurationWithKeywordLoader from './GroupCurationWithKeywordLoader';
import * as s from './index.css';

const LLMGroupCurationWithKeywordPage: ActivityComponentType = () => {
  return (
    <AppScreen appBar={{ title: '모임' }}>
      <View>
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
          <CurationWithKeywordView />
        </AsyncBoundary>
      </View>
    </AppScreen>
  );
};

export default withAsyncBoundary(LLMGroupCurationWithKeywordPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});

export type FilterType = {
  order: $Values<typeof ORDER_HOME>;
  keywordName?: string;
};

const CurationWithKeywordView = () => {
  const { keyword } = useQueryParams();

  const [filter, setFilter] = useState<FilterType>({
    order: ORDER_HOME.RECOMMEND_SCORE_DESC,
    keywordName: keyword,
  });

  const handleSetFilter = (filter: React.SetStateAction<FilterType>) => {
    setFilter(filter);
  };

  return (
    <>
      <AsyncBoundary pendingFallback={<CurationFilterSkeleton />} rejectedFallback={<></>}>
        <GroupCurationWithKeywordListFilter setFilter={handleSetFilter} filter={filter} />
      </AsyncBoundary>
      <AsyncBoundary pendingFallback={<ListSkeleton />} rejectedFallback={<></>}>
        <CurationWithKeywordList filter={filter} />
      </AsyncBoundary>
    </>
  );
};

const CurationWithKeywordList = ({ filter }: { filter: FilterType }) => {
  const { push, replace } = useFlow();

  const query = curationKeywords.find((item) => item.name === filter.keywordName)?.name ?? '';

  const referParams = useReferQueryParams();
  const { from } = useQueryParams();

  useEnterTrackEvent({
    event: 'enter_curation_page',
    params: {
      keyword: query,
      ...referParams,
      from: from ?? '',
    },
    sample: true,
  });

  const { data, hasNextPage } = useGetCurationList({
    topic: query,
  });
  const { isTreatment: enabledJuniorGroupExperiment } = useJuniorGroupExperiment();

  const groupList = data?.pages.map(({ data }) => data.groups) ?? [];
  const flattenGroupList = groupList.flat(1);

  if (flattenGroupList.length === 0) {
    return (
      <EmptySection
        style={{
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography typography="bodyL1Regular" color="gray700">
            추천 결과가 없어요.
          </Typography>
          <Spacing size={8} />
          <div className={s.ButtonWrapper}>
            <BoxButton variant="primary" size="medium" onClick={() => replace('HomePage', {})}>
              다른 모임 보기
            </BoxButton>
          </div>
        </div>
      </EmptySection>
    );
  }

  return (
    <>
      <div className={s.Wrapper}>
        <Spacing size={8} />
        <div className={s.CalloutWrapper}>
          <ActionableCallout
            width="100%"
            variant="warning"
            onClick={() => {
              trackEvent({ event: 'click_curation_group_home', sample: true });
              push('HomePage', {});
            }}
          >
            <div className={s.Callout}>
              <div className={s.IconWrapper}>
                <IconPerson2Openarms size={24} />
              </div>
              <CalloutDescription>더 많은 모임을 보고 싶으신가요?</CalloutDescription>
            </div>
          </ActionableCallout>
        </div>
        <Spacing size={8} />
        {flattenGroupList.map((group, index) => (
          <SearchResultItem
            key={group?.id}
            result={{
              ...group,
              region: group?.region ?? {
                id: 0,
                name: '',
                province: '',
                city: '',
                town: '',
              },
              displayRegionName: group.displayRegionName ?? group.region?.town ?? '',
              highlight: undefined,
            }}
            onClick={() => {
              push('GroupDetailPage', {
                groupId: group?.id.toString(),
              });

              trackEvent({
                event: 'click_curation_group',
                params: {
                  groupId: group?.id,
                  groupName: group?.name,
                  groupCategoryName: group?.category?.name,
                  listItemIndex: index,
                  keyword: query,
                },
                sample: true,
              });
            }}
            enabledJuniorGroupExperiment={enabledJuniorGroupExperiment}
          />
        ))}
        <div className={s.GroupCreateButtonWrapper}>
          <div className={s.Item}>
            <Typography typography="subtitle1Regular" color="gray600">
              찾는 모임이 없나요? <br />
              직접 모임을 만들어보세요.
            </Typography>
            <Spacing size={16} />
            <BoxButton
              size="medium"
              variant="secondary"
              onClick={() => {
                trackEvent({ event: 'click_curation_group_create_button' });
                push('GroupNewSetGroupCategoryRecommendPage', {});
              }}
            >
              모임 만들기
            </BoxButton>
          </div>
        </div>
        {hasNextPage && <GroupCurationWithKeywordLoader topic={query} referrer={query} />}
        <div className={s.SafeAreaBottom} />
      </div>
      <FloatingIcon
        onClick={() => {
          push('GroupNewSetGroupCategoryRecommendPage', {});
        }}
      />
    </>
  );
};
