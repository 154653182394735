import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_CATEGORIES_QUERY_KEY } from '@/domain/GroupCategory/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

export const useQueryGroupCategories = () => {
  const read = useAsyncRead(groupClient.api.CategoryApi.apiV1GroupCategoriesGet);
  return {
    queryKey: GROUP_CATEGORIES_QUERY_KEY.base(),
    queryFn: () => read().then((res) => res.categories),
  };
};

export const useReadGroupCategories = () => {
  const query = useQueryGroupCategories();
  return useSuspenseQuery(query);
};
