import {
  GroupCurrentUser,
  GroupMemberGradeEditSectionPresentation,
  GroupMemberProfileResponseProfile,
  GroupMemberSummaryPresentation,
} from '@community-group/api/lib/group/models';
import { Dialog, useDialog } from '@community-group/components';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { useSnackbar } from '@/_app/providers/UIOverlayProvider';
import { getMemberListPath } from '@/api/hooks/useGetMemberList';
import { usePutGroupMemberGrade } from '@/domain/GroupMember/hooks/usePutGroupMemberGrade';
import { useQueryGroupMemberGradeEditSections } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeEditSections';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { GROUP_MEMBER_QUERY_KEY } from '@/domain/GroupMember/queries';
import { useQueryGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import { queryClient } from '@/shared/api/instance';
import { isAccessibleGrade } from '@/utils/permission';

type Props = {
  groupId: string;
  currentUser: GroupCurrentUser;
  profileUser: GroupMemberSummaryPresentation | GroupMemberProfileResponseProfile;
};

export const useHandleMemberGradeProfile = ({ groupId, currentUser, profileUser }: Props) => {
  const { open: openDialog, close: closeDialog } = useDialog();
  const { open: openSnackbar } = useSnackbar();

  const [
    { data: memberGradeEditSections, refetch: refetchMemberGradeEditSections },
    { data: memberGradeStatus, refetch: refetchMemberGradeStatus },
    { data: groupPermissions },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupMemberGradeEditSections({
        groupId,
        userId: profileUser.id,
      }),
      useQueryGroupMemberGradeStatus(groupId),
      useQueryGroupPermissions(groupId),
    ],
  });

  const { mutate: putMemberGrade } = usePutGroupMemberGrade({
    onSuccess: () => {
      refetchMemberGradeEditSections();
      refetchMemberGradeStatus();
      queryClient.refetchQueries({
        queryKey: GROUP_MEMBER_QUERY_KEY.memberGradeApplications(groupId),
      });
      queryClient.refetchQueries({
        queryKey: GROUP_MEMBER_QUERY_KEY.memberGradeApplicationCount(groupId),
      });
      queryClient.refetchQueries({
        queryKey: [getMemberListPath(groupId)],
      });
    },
  });

  const memberGradeEnabled = useMemo(() => {
    return (
      memberGradeStatus === 'system' &&
      isAccessibleGrade(currentUser.grade, groupPermissions.modifyGroupMemberGrade.currentPriority)
    );
  }, [
    currentUser.grade,
    groupPermissions.modifyGroupMemberGrade.currentPriority,
    memberGradeStatus,
  ]);

  const memberRoleEditSections = useMemo(() => {
    if (memberGradeEnabled) return memberGradeEditSections;
    return memberGradeEditSections.filter((section) =>
      section.displaySectionText.includes('운영진')
    );
  }, [memberGradeEditSections, memberGradeEnabled]);

  return {
    memberGradeEnabled,
    refetchMemberGradeEditSections,
    memberRoleEditSections,
    handleMemberGradeProfile: useCallback(
      async ({ gradeId, displaySectionText }: GroupMemberGradeEditSectionPresentation) => {
        openDialog({
          element: (
            <Dialog
              title={displaySectionText.replace(/으로|에서/, '')}
              description={`${profileUser.nickname}님을 ${displaySectionText}할까요?`}
              primaryActionLabel="확인"
              secondaryActionLabel="취소"
              onPrimaryAction={async () => {
                await putMemberGrade({ groupId, userId: profileUser.id, gradeId });
                await closeDialog();
                openSnackbar({
                  message: `${profileUser.nickname}님을 ${displaySectionText}했어요.`,
                });
              }}
              onSecondaryAction={closeDialog}
            />
          ),
        });
      },
      [
        closeDialog,
        groupId,
        openDialog,
        openSnackbar,
        profileUser.id,
        profileUser.nickname,
        putMemberGrade,
      ]
    ),
  };
};
