import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { BoxButton } from '@community-group/components';

import { useBlockGroupActionByGroupStatus } from '@/features/GroupDetail/hooks/useBlockGroupActionByGroupStatus';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

type Props = {
  groupId: string;
  targetUserId: string;
  targetUserName: string;
  requestUserRole: GroupMemberRoleEnum;
};

export const GroupDirectChatStatusOnRequiredApprovalButton = ({
  groupId,
  targetUserId,
  targetUserName,
  requestUserRole,
}: Props) => {
  const { push } = useFlow();
  const blockGroupActionByGroupStatus = useBlockGroupActionByGroupStatus({
    groupId,
    actionName: 'DirectChat',
  });

  const handleRequestChat = blockGroupActionByGroupStatus(() => {
    trackEvent({
      event: 'click_request_1on1_chat_button',
      params: {
        groupId,
        domain: 'group',
        role: requestUserRole,
        type: 'profile_direct_chat',
      },
      sample: true,
    });
    push('GroupProfileRequestGroupDirectChatNewPage', {
      groupId: groupId.toString(),
      userId: targetUserId.toString(),
      userName: targetUserName,
      directChatType: 'profile_direct_chat',
    });
  });

  return (
    <BoxButton
      size="medium"
      variant="secondary"
      UNSAFE_style={{
        width: '100%',
      }}
      onClick={handleRequestChat}
    >
      채팅하기
    </BoxButton>
  );
};
