import { BoxButton, Spacing, Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useBridge } from '@/contexts/Bridge';
import { getSeoulRunLandkitPageScheme } from '@/features/SeoulRunPromotion/config';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { openLink } from '@/utils/link';

import * as s from './style.css';

const CreatedSeoulRunMeetupGroupBottomSheet: ActivityComponentType = () => {
  const { pop } = useFlow();
  const { bridge } = useBridge();

  return (
    <BottomSheet style={{ padding: 0 }}>
      <div className={s.Container}>
        <Typography typography="title2Bold">보물찾기 모임을 만들었어요!</Typography>
        <Spacing size={6} />
        <Typography typography="subtitle1Regular" color="gray700" className={s.InfoText}>
          이제 보물지도를 확인할 차례예요.
          <br />
          보물지도는 &apos;채팅&apos; 탭에서 다시 확인할 수 있어요.
        </Typography>
        <Spacing size={16} />
        <img
          className={s.Image}
          src="https://assetstorage.krrt.io/1025317940251365954/fdda00d6-6cb8-470b-b034-dee2cdc1075b/width=1029,height=480.webp"
          alt="보물찾기 모임 안내 이미지"
        />
        <Spacing size={28} />
        <BoxButton
          size="xlarge"
          width="100%"
          variant="primary"
          onClick={() => {
            pop();

            bridge.openToast({
              toast: {
                body: '일정을 만들었어요. 이제 아래 ‘대화 중인 채팅방’ 버튼을 누르고 보물지도를 확인해보세요!',
                button: {
                  body: '바로가기',
                  action: {
                    link: {
                      path: getSeoulRunLandkitPageScheme(),
                    },
                  },
                },
              },
            });
          }}
        >
          확인했어요
        </BoxButton>
      </div>
    </BottomSheet>
  );
};

export default CreatedSeoulRunMeetupGroupBottomSheet;
