import {
  GroupMeetupEvaluationResponse,
  MediaUploadForm,
} from '@community-group/api/lib/group/models';

import { groupMeetupReviewCreateRequestInitialValues } from '@/features/GroupMeetupReview/model';

type Props = {
  review: GroupMeetupEvaluationResponse['review'];
};
export const useInitialMeetupReviewForm = ({ review }: Props) => {
  if (!review) return groupMeetupReviewCreateRequestInitialValues;

  const medias = review.reviewPost?.medias;
  const images = medias?.filter((media) => media.type === 'image') ?? [];
  const videos = medias?.filter((media) => media.type === 'video') ?? [];

  const imageAsMediaForm = images.map<MediaUploadForm>((image) => ({
    id: image.media.id,
    type: image.type,
  }));
  const videoAsMediaForm = videos.map<MediaUploadForm>((video) => ({
    id: video.media.id,
    type: video.type,
  }));
  const mediaForms = [...imageAsMediaForm, ...videoAsMediaForm];
  return {
    reviewRating: review.reviewRating,
    reviewPost: {
      ...review.reviewPost,
      content:
        review.reviewRating === 'DISLIKE' ? review.report?.contents : review.reviewPost?.content,
      medias: mediaForms,
    },
  };
};
