import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { useCallback, useMemo } from 'react';
import { match, P } from 'ts-pattern';

import { groupMemberSettingConfig } from '@/features/GroupSetting/util/groupSettingConfig';
import * as stackflow from '@/stackflow';

type Props = {
  role: GroupMemberRoleEnum;
  groupId: string;
};

export const useMemberSettingHandler = ({ role, groupId }: Props) => {
  const stack = stackflow?.useFlow();
  const push = stack?.push;
  const memberSetting = useMemo(
    () => groupMemberSettingConfig.filter((item) => item.roles.some((r) => r === role)),
    [role]
  );

  const handleItemClick = useCallback(
    (item: (typeof memberSetting)[number]) =>
      match([item, role])
        .with([{ displayText: '전체 멤버 목록' }, P.union('superHost', 'manager')], () =>
          push('GroupMemberListForHostPage', { groupId })
        )
        .with([{ displayText: '멤버 권한 관리' }, 'superHost'], () =>
          push('GroupSettingPermissionPage', { groupId })
        )
        .with([{ displayText: '멤버 등급 관리' }, P.union('superHost', 'manager')], () =>
          push('GroupSettingMemberGradePage', { groupId })
        )
        .with([{ displayText: '운영진 설정' }, 'superHost'], () =>
          push('GroupManagerSettingPage', { groupId })
        )
        .with([{ displayText: '본인인증 설정' }, 'superHost'], () =>
          push('GroupSettingEditVerification', { groupId })
        )
        .with([{ displayText: '모임 별명 설정' }, 'superHost'], () =>
          push('GroupSettingEditSubNicknamePage', { groupId })
        )
        .otherwise(() => null),
    [role, push, groupId]
  );
  return { handleItemClick, memberSetting };
};
