import 'dayjs/locale/ko';

import { groupClient } from '@community-group/api';
import { ApiV1AllTypeMeetupsGetRequestParams } from '@community-group/api/lib/group/api/all-meetups-api';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_MEETUP_QUERY_KEY } from '../queries';

export type AllTypeMeetupProps = ApiV1AllTypeMeetupsGetRequestParams & {
  showRenderLimit?: boolean;
  initialCursor?: string | undefined;
};

export const useReadMeetupsWithDayFilter = ({
  regionId,
  statuses = ['opened'],
  categoryId,
  order,
  targetDate,
  limit = 20,
  showRenderLimit = false,
  eventType,
  initialCursor,
}: AllTypeMeetupProps) => {
  const read = useAsyncRead(groupClient.api.AllMeetupsApi.apiV1AllTypeMeetupsGet);

  return useSuspenseInfiniteQuery({
    queryKey: GROUP_MEETUP_QUERY_KEY.meetupsWithDayFilter({
      regionId,
      statuses,
      order,
      targetDate,
      limit,
      showRenderLimit,
      categoryId,
      eventType,
    }),
    queryFn: ({ pageParam = initialCursor }) =>
      read({
        cursor: pageParam ? pageParam : undefined,
        limit,
        regionId,
        statuses,
        categoryId,
        order,
        targetDate,
        eventType,
      }),
    initialPageParam: initialCursor,
    getNextPageParam: ({ hasNext, endCursor }) => (hasNext ? endCursor ?? undefined : undefined),
    select: (data) => {
      const meetups = data.pages.flatMap((page) => page.meetups);

      if (!showRenderLimit) return meetups;

      return meetups.slice(0, 3); // showRenderLimit이 true일 때 최대 3개만 반환
    },
  });
};
