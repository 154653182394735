import {
  BoxButton,
  BulletItem,
  BulletList,
  Checkbox,
  Divider,
  Spacing,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import { IconExclamationmarkCircleFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useState } from 'react';

import { usePostLeftGroup } from '@/api/hooks/usePostLeftGroup';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import useActiveActivities from '@/hooks/useActiveActivities';
import useBackToRootActivity from '@/hooks/useBackToRootActivity';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchHome } from '@/utils/refetch/home';

import * as s from './LeaveGroupBottomSheet.css';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

export type LeaveGroupBottomSheetParams = Pick<PageParams, 'groupId'>;

const LeaveGroupBottomSheet: ActivityComponentType<LeaveGroupBottomSheetParams> = () => {
  const { groupId } = usePathParams();
  const { pop, replace } = useFlow();

  const { data: group } = useReadGroupDetail(groupId);

  const { handleBackToRootActivity } = useBackToRootActivity();
  const { diffActivitiesFromCurrent } = useActiveActivities();
  const handleErrorWithToast = useHandleErrorWithToast();

  const { open: openSnackbar } = useSnackbar();

  // 동의 여부
  const [isAgreed, setIsAgreed] = useState(false);

  useEnterTrackEvent({
    event: 'enter_leave_group_bottom_sheet',
    params: {
      groupId: group?.id.toString(),
      groupCategoryName: group?.category?.name,
      groupName: group?.name,
    },
  });

  const { mutate: leftMutate } = usePostLeftGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      const homePageActivityIndex = diffActivitiesFromCurrent('HomePage');
      if (homePageActivityIndex !== -1) {
        for (let i = 0; i < homePageActivityIndex; i++) pop();
        return;
      }
      handleBackToRootActivity(() => {
        replace('HomePage', {});
      });
    },
  });

  const handleLeaveGroup = () => {
    if (!isAgreed) {
      openSnackbar({
        message: '탈퇴 동의 후 탈퇴할 수 있어요.',
        timeout: 1000,
      });
      return;
    }
    leftMutate(String(groupId), {
      onSuccess: () => {
        trackEvent({
          event: 'click_leave_group',
          params: {
            groupId: group?.id,
            groupCategoryName: group?.category.name,
          },
        });
        // member left 후 update에 delay 문제가 있어서, 500ms 딜레이 추가함
        setTimeout(() => {
          refetchHome();
        }, 500);
      },
    });
  };

  return (
    <BottomSheet style={{ padding: '0' }}>
      <div className={s.Container}>
        <IconExclamationmarkCircleFill size={24} color={vars.$scale.color.gray900} />
        <Spacing size={8} />
        <Typography typography="title2Bold" color="gray900">
          모임 탈퇴 전에 확인해주세요.
        </Typography>
        <Spacing size={16} />
        <BulletList color="gray700" typography="bodyL1Regular">
          <BulletItem bulletIndent="0" gap=".25rem">
            모임에서 나가면, 작성한 게시글과 댓글을 더이상 수정하거나 삭제할 수 없어요.
          </BulletItem>
          <Spacing size={8} />
          <BulletItem bulletIndent="0" gap=".25rem">
            또한 이 모임에서 참여한 모든 채팅방에서 자동으로 나가져요.
          </BulletItem>
        </BulletList>
        <Spacing size={16} />
        <Divider />
        <Spacing size={14} />
        <div className={s.ButtonContainer}>
          {/* checkbox에 marginY 기본값이 설정되어져 있음 */}
          <Checkbox
            shape="circle"
            size="large"
            width="100%"
            isSelected={isAgreed}
            onChange={() => setIsAgreed(!isAgreed)}
          >
            <VerticalSpacing size={8} />
            <Typography typography="subtitle1Bold" color="gray900">
              확인했어요. 이 모임에서 탈퇴할게요.
            </Typography>
          </Checkbox>
        </div>
        <Spacing size={26} />
        <div className={s.ButtonGroup}>
          <BoxButton variant="secondary" flex={1} size="xlarge" onClick={pop}>
            취소
          </BoxButton>
          <VerticalSpacing size={8} />
          <BoxButton
            variant="primary"
            flex={1}
            size="xlarge"
            onClick={handleLeaveGroup}
            isDisabled={!isAgreed}
          >
            탈퇴하기
          </BoxButton>
        </div>
      </div>
    </BottomSheet>
  );
};

export default LeaveGroupBottomSheet;
