import {
  GroupMemberRoleEnum,
  GroupMemberSummaryWithActivityPresentation,
} from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { memo, MouseEventHandler, PropsWithChildren, useMemo } from 'react';

import MemberListItem from './MemberListItem';
import * as s from './MemberSummarySection.css';

type Props = PropsWithChildren<{
  totalCount: number;
  members: GroupMemberSummaryWithActivityPresentation[];
  currentUserId: number;
  currentUserRole: GroupMemberRoleEnum;
  onMemberListItemClick: (memberId: number, memberRole: GroupMemberRoleEnum) => MouseEventHandler;
  onMemberActionButtonClick: (memberId: number) => MouseEventHandler;
  hideMemberCount?: boolean;
}>;

const MemberSummarySection = ({
  totalCount,
  members,
  currentUserId,
  currentUserRole,
  onMemberListItemClick,
  onMemberActionButtonClick,
  hideMemberCount,
  children,
}: Props) => {
  const hasMore = useMemo(() => totalCount > 3, [totalCount]);

  return (
    <section className={s.Container}>
      <Typography typography="title3Bold" color="gray900">
        멤버 <span>{hideMemberCount ? '' : totalCount}</span>
      </Typography>
      <ul className={s.MemberList}>
        {members.map((member) => (
          <MemberListItem
            key={member.id}
            member={member}
            currentUserId={currentUserId}
            currentUserRole={currentUserRole}
            onClick={onMemberListItemClick}
            onActionButtonClick={onMemberActionButtonClick}
          />
        ))}
      </ul>
      {hasMore && children}
    </section>
  );
};

export default memo(MemberSummarySection);
