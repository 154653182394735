import { Spacing } from '@community-group/components';
import React from 'react';

import { TagType } from '.';
import * as s from './TagItem.css';

type Props = {
  tag: TagType;
};

const TagItem = ({ tag }: Props) => {
  return (
    <div className={s.Container}>
      <div>{tag.icon}</div>
      <Spacing size={4} direction="vertical" />
      <div>{tag.label}</div>
      <Spacing size={2} direction="vertical" />
      <div className={s.Value}>{tag.value}</div>
    </div>
  );
};

export default TagItem;
