import { GroupCreateForm, GroupCreateFormJoinType } from '@community-group/api/lib/group/models';
import {
  AsyncBoundary,
  ImageFieldItem,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { IconCameraFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useMemo } from 'react';

import { useGetCategories } from '@/api/hooks/useGetCategories';
import { useGetEventLogMyInfo } from '@/api/hooks/useGetEventLogMyInfo';
import { usePostNewGroup } from '@/api/hooks/usePostNewGroup';
import AccessoryBarButtonGroup from '@/components/common/AccessoryBarButtonGroup/AccessoryBarButtonGroup';
import { Container } from '@/components/common/Container';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import useActiveActivities from '@/hooks/useActiveActivities';
import useBackToActivity from '@/hooks/useBackToActivity';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useHandleSetGroupProfile } from '@/hooks/useHandleSetGroupProfile';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { useStore } from '@/store';
import { trackEvent } from '@/utils/analytics';

import AppBarCloseCreateGroupButton from '../Common/AppBarCloseCreateGroupButton';
import GroupCreateAppScreen from '../Common/GroupCreateAppScreen';
import useCreateForm from '../hooks/useCreateForm';
import { indicatorCountHandler } from '../utils/indicatorCountHandler';
import CreateGroupProfileImagePreview from './CreateGroupProfileImagePreview';
import * as s from './index.css';

export type GroupNewSetProfileImagePageParams = Pick<
  PageParams,
  | 'name'
  | 'categoryId'
  | 'joinType'
  | 'question'
  | 'requiredQuestion'
  | 'haveQuestion'
  | 'maximumMemberCount'
  | 'minAgeTag'
  | 'maxAgeTag'
  | 'joinableRegionRange'
  | 'createType'
  | 'from'
  | 'description'
  | 'subNicknameQuestion'
  | 'hasSubNickname'
  | 'needVerification'
  | 'canViewVerifiedUserInfo'
  | 'joinGrowth'
  | 'joinExerciseGrowth'
  | 'createGroupFlowType'
>;

const GroupNewSetProfileImagePage: ActivityComponentType<
  GroupNewSetProfileImagePageParams
> = () => {
  const { push, pop } = useFlow();
  const { userConfig } = useUserConfig();
  const currentRegionId = userConfig.regionId ?? 0;
  const {
    joinGrowth,
    joinExerciseGrowth,
    createGroupFlowType,
    ...postNewGroupPayload
  }: GroupNewSetProfileImagePageParams = useQueryParams();
  const { bridge } = useBridge();

  const { categories } = useGetCategories();
  const { watch, setValue, resetCreateForm } = useCreateForm({});

  const { activeActivities, isExistSpecificActivities } = useActiveActivities();

  useEnterTrackEvent({
    event: 'enter_create_group_step',
    params: {
      step: 'profile_image',
      createGroupFlowType: createGroupFlowType ?? 'normal',
    },
  });

  const handleBack = useBackToActivity();

  const handleErrorWithToast = useHandleErrorWithToast();
  const {
    mutate: mutateNewGroup,
    isPending: isLoadingNewGroup,
    status: postNewGroupStatus,
  } = usePostNewGroup({
    onError: handleErrorWithToast,
  });

  const selectedCategory = useMemo(() => {
    const selectedCategoryId = Number(postNewGroupPayload?.categoryId ?? 0);
    return categories?.find((category) => category.id === selectedCategoryId);
  }, [categories, postNewGroupPayload?.categoryId]);

  const {
    profileImage,
    originImage: originProfileImage,
    isLoading: isProfileLoading,
    handleSetGroupProfile,
    handleReset: handleResetProfile,
  } = useHandleSetGroupProfile({
    defaultValue: watch('profileImage'),
  });

  // 임시저장
  useEffect(() => {
    setValue('profileImage', profileImage);
  }, [profileImage, setValue]);

  const { data: eventMyInfo } = useGetEventLogMyInfo();

  // 모임 오픈 지역 모임 생성
  const handlePostNewGroup = (payload: GroupCreateForm) => {
    mutateNewGroup(
      {
        joinGrowth,
        joinExerciseGrowth,
        ...payload,
      },
      {
        onSuccess: async ({ data }) => {
          const { group } = data;

          const isFirstCreateGroup = !eventMyInfo?.data.isCreatedGroup;

          const eventParams = {
            groupId: group?.id,
            groupName: group?.name,
            description: group?.description ?? '',
            category: group?.category?.name,
            groupCategoryName: group?.category?.name,
            groupCity: group?.groupCreatedRegion?.city,
            groupProvince: group?.groupCreatedRegion?.province,
            groupTown: group?.groupCreatedRegion?.town,
            isSettingOnSubnickname: group?.subNicknameSetting.isSettingOn,
            publishRegionRange: group?.joinableRegionRange,
            joinableRegionRange: group?.joinableRegionRange,
            needVerification: group?.needVerification,
            isFirstCreateGroup,
            canViewVerifiedUserInfo: group?.canViewVerifiedUserInfo,
            createGroupFlowType: createGroupFlowType ?? 'normal',
          };
          trackEvent({
            event: 'finish_create_group',
            params: eventParams,
            loggerType: ['AMPLITUDE', 'FIREBASE'],
          });

          trackEvent({
            event: 'click_finish_create_group',
            params: eventParams,
            loggerType: ['APPSFLYER', 'KARROT'],
          });
          if (isFirstCreateGroup) {
            trackEvent({
              event: 'click_finish_first_create_group',
              params: eventParams,
              loggerType: ['APPSFLYER'],
            });
          }

          const isExistHomePage = isExistSpecificActivities('HomePage');
          handleBack({ activityName: 'HomePage' });

          setTimeout(async () => {
            resetCreateForm();

            // 전국 운동 그로스 모임 생성 스킴으로 접근시 대응
            if (joinExerciseGrowth) {
              return bridge.pushRouter({
                router: {
                  remote: `${globalThis.appConfig.host}/group/${group?.id}?from=create_exercise_growth&isNewGroup=true`,
                  navbar: false,
                  enableSafeAreaInsets: false,
                  backSwipable: true,
                  scrollable: false,
                },
              });
            }

            // Stack에 HomePage가 없으면 웹뷰가 닫혔을 것이기 때문에 bridge로 push
            if (!isExistHomePage) {
              return bridge.pushRouter({
                router: {
                  remote: `${globalThis.appConfig.host}/group/${group?.id}?isNewGroup=true`,
                  navbar: false,
                  enableSafeAreaInsets: false,
                  backSwipable: true,
                  scrollable: false,
                },
              });
            }

            push('GroupDetailPage', {
              groupId: group?.id.toString() ?? '',
              isNewGroup: 'true', // 신규 모임 생성 여부에 따른 생성 판단 플래그
            });
          }, 300);
        },
      }
    );
  };

  const handleSubmit = () => {
    if (isProfileLoading || postNewGroupStatus === 'success' || isLoadingNewGroup) return;

    const payload = {
      ...parsePostNewGroupPayload(postNewGroupPayload),
      regionId: currentRegionId,
      profileImageId: profileImage?.id,
      subNicknameSetting: {
        isSettingOn: postNewGroupPayload?.hasSubNickname ?? false,
        requestText: postNewGroupPayload?.subNicknameQuestion ?? '',
      },
    } as GroupCreateForm;

    handlePostNewGroup(payload);
  };

  const isDisabledButton = () => {
    if (isProfileLoading) return true;

    return false;
  };

  return (
    <GroupCreateAppScreen
      indicator={indicatorCountHandler('GroupNewSetProfileImagePage')}
      preventSwipeBack={true}
      accessoryBar={({ appendTop }) => (
        <>
          {appendTop}
          <AccessoryBarButtonGroup
            onBack={pop}
            onSubmit={handleSubmit}
            submitText={'모임 만들기'}
            buttonDisabled={isDisabledButton()}
            isLoading={isProfileLoading}
          />
        </>
      )}
      appBar={{
        renderRight: () => (
          <p className={s.SkipLabel} onClick={handleSubmit}>
            건너뛰기
          </p>
        ),
        backButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
        closeButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <div className={s.BodyWrapper}>
          <div className={s.Wrapper} style={{ height: '100%', overflow: 'auto' }}>
            <Container>
              <Typography as="h1" typography="title1Bold">
                대표사진을 등록해주세요
              </Typography>
              <Spacing size={8} />
              <Typography as="p" typography="subtitle1Regular" color="gray900">
                전체 모임 목록에서 보이는 대표 이미지예요.
              </Typography>
              <Spacing size={24} />
              <div className={s.ImageWrapper}>
                <div className={s.ImageBox} onClick={handleSetGroupProfile}>
                  <IconCameraFill size={24} color={vars.$scale.color.gray600} />
                </div>
                {originProfileImage && (
                  <ImageFieldItem
                    image={
                      !isProfileLoading && profileImage?.small
                        ? profileImage.small
                        : originProfileImage
                    }
                    onRemoveClick={handleResetProfile}
                    className={s.ImageBox}
                    imageId={!isProfileLoading && profileImage?.id ? profileImage.id : 'temp'}
                  />
                )}
              </div>

              <Spacing size={32} />

              {selectedCategory && (
                <CreateGroupProfileImagePreview
                  name={postNewGroupPayload.name}
                  profileImage={profileImage}
                  description={postNewGroupPayload.description}
                  defaultImage={selectedCategory}
                  categoryName={selectedCategory.name}
                  regionName={userConfig.regionTownName ?? ''}
                />
              )}
            </Container>
            <Spacing size={20} />
          </div>
        </div>
      </AsyncBoundary>
    </GroupCreateAppScreen>
  );
};

export default withAsyncBoundary(GroupNewSetProfileImagePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});

const parsePostNewGroupPayload = ({
  name = '',
  description = '',
  categoryId,
  joinType,
  question,
  requiredQuestion,
  haveQuestion,
  maximumMemberCount,
  minAgeTag,
  maxAgeTag,
  joinableRegionRange,
  needVerification,
  canViewVerifiedUserInfo,
}: Omit<GroupNewSetProfileImagePageParams, 'from'>): Omit<
  GroupCreateForm,
  'regionId' | 'publishRange'
> => {
  const getApplicationConfig = () => {
    if (!question) return undefined;

    return {
      questions: [
        {
          question,
          required: requiredQuestion ? Boolean(requiredQuestion) : false,
        },
      ],
      haveQuestion: Boolean(haveQuestion),
    };
  };

  const getGroupTag = () => {
    if (!minAgeTag || !maxAgeTag) return [];

    return [
      {
        type: 'AGE',
        min: Number(minAgeTag),
        max: Number(maxAgeTag),
      },
    ];
  };

  const getNeedVerification = () => {
    if (!needVerification) return false;
    if (needVerification === 'true') return true;
    return false;
  };

  const getCanViewVerifiedUserInfo = () => {
    if (!canViewVerifiedUserInfo) return false;
    if (canViewVerifiedUserInfo === 'true') return true;
    return false;
  };

  return {
    name,
    description,
    joinableRegionRange,
    categoryId: Number(categoryId),
    joinType: joinType as GroupCreateFormJoinType,
    applicationConfig: getApplicationConfig(),
    maximumMemberCount: Number(maximumMemberCount),
    groupTag: {
      tags: getGroupTag(),
    },
    needVerification: getNeedVerification(),
    canViewVerifiedUserInfo: getCanViewVerifiedUserInfo(),
  };
};
