import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_CHALLENGE_QUERY_KEY } from '../queries';

type Props = {
  groupId?: string;
  challengeId?: string;
};

export const useQueryNullableChallengeDetail = ({ groupId, challengeId }: Props) => {
  const read = useAsyncRead(
    groupClient.api.GroupChallengeApi.apiV1GroupsGroupIdChallengesChallengeIdDetailGet
  );
  return {
    queryKey:
      challengeId && groupId && challengeId.length > 0 && groupId.length > 0
        ? GROUP_CHALLENGE_QUERY_KEY.detail(groupId.toString(), challengeId.toString())
        : [],
    queryFn: () => {
      if (!challengeId || !groupId) return null;
      return read({ groupId: Number(groupId), challengeId: Number(challengeId) });
    },
  };
};

/**
 * groupId가 nullable한 상태일 때 사용하는 useReadGroupDetail
 * groupId가 존재하는 경우 기존의 훅과 동일한 쿼리키를 바라봐요.
 * @param groupId
 * @param challengeId
 * @returns ChallengeDetailPresentation
 */
export const useReadNullableChallengeDetail = ({ groupId, challengeId }: Props) => {
  const query = useQueryNullableChallengeDetail({ groupId, challengeId });
  return useSuspenseQuery({ ...query });
};
