import { GroupModifyForm } from '@community-group/api/lib/group/models';
import {
  Container,
  ImageLoader,
  MultilineTextField,
  Spacing,
  TextField,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconCameraFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { isEqual } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useSnackbar } from '@/_app/providers/UIOverlayProvider';
import { usePutGroup } from '@/domain/Group/hooks/usePutGroup';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import GroupInfoCategory from '@/features/GroupSetting/GroupInfoCategory';
import { useHandleSetGroupProfile } from '@/hooks/useHandleSetGroupProfile';
import { useFlow } from '@/stackflow';
import { AppBarTextSubmitButton } from '@/stackflow/components/AppBarTextSubmitButton';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { groupFieldSchema } from '@/utils/validate/formSchema/group';
import { validateSchemaWithBoolean, validateSchemaWithMessage } from '@/utils/validate/util';

import useGetIsExistHeroSlider from '../../Detail/hooks/useGetIsExistHeroSlider';
import GroupSettingEditInfoHeroSlider from '../../ImageViewerPage/components/GroupSettingEditInfoHeroSlider';
import EmptyHeroImageBox from '../components/EmptyHeroImageBox';
import * as s from './GroupSettingEditGroupInfoPage.css';

export type GroupSettingEditGroupInfoPageParams = Pick<PageParams, 'groupId'>;

const GroupSettingEditGroupInfoPage: ActivityComponentType<
  GroupSettingEditGroupInfoPageParams
> = () => {
  const { groupId } = usePathParams();
  const { data: groupDetail } = useReadGroupDetail(groupId);
  const { pop } = useFlow();
  const { open: openSnackbar } = useSnackbar();

  const isExistHeroSlider = useGetIsExistHeroSlider({ groupId, onlyPublic: true });

  const groupModifyForm = useMemo(
    () => ({
      name: groupDetail.name ?? '',
      description: groupDetail.description ?? '',
      profileImageId: groupDetail.profileImage.id ?? '',
      backgroundImageId: groupDetail.backgroundImage.id ?? '',
      categoryId: groupDetail.category.id ?? 0,
    }),
    [groupDetail]
  );

  const { handleSubmit, watch, setValue } = useForm<GroupModifyForm>({
    defaultValues: groupModifyForm,
  });

  const handleChangeCategory = useCallback(
    (categoryId: number) => setValue('categoryId', categoryId),
    [setValue]
  );
  const {
    originImage: profileImageOriginImage,
    isLoading: isProfileImageLoading,
    handleSetGroupProfile,
  } = useHandleSetGroupProfile({
    onUpload: (id) => {
      if (id) setValue('profileImageId', id);
    },
  });

  const { mutate, isPending } = usePutGroup({
    onSuccess: () => {
      openSnackbar({
        message: '모임 정보를 수정했어요.',
      });

      pop();
    },
  });

  const nameFieldValue = watch('name') ?? '';
  const descriptionFieldValue = watch('description') ?? '';

  const validateForm = useCallback(() => {
    const validateNameResult = validateSchemaWithMessage(groupFieldSchema.name, nameFieldValue);

    if (!validateNameResult.success) {
      openSnackbar({
        message: validateNameResult.message,
      });
      return false;
    }

    if (descriptionFieldValue) {
      const validateDescriptionResult = validateSchemaWithMessage(
        groupFieldSchema.description,
        descriptionFieldValue
      );

      if (!validateDescriptionResult.success) {
        openSnackbar({
          message: validateDescriptionResult.message,
        });
        return false;
      }
    }

    return true;
  }, [nameFieldValue, descriptionFieldValue, openSnackbar]);

  const isDisable = useCallback(() => {
    if (isEqual(groupModifyForm, watch())) return true;
    if (!validateSchemaWithBoolean(groupFieldSchema.name, nameFieldValue)) return true;
    if (
      !!descriptionFieldValue &&
      !validateSchemaWithBoolean(groupFieldSchema.description, descriptionFieldValue)
    ) {
      return true;
    }
    return false;
  }, [descriptionFieldValue, groupModifyForm, nameFieldValue, watch]);

  const onSubmit = handleSubmit(async (data) => {
    if (!validateForm()) return;

    mutate(
      { id: Number(groupId), groupModifyForm: data },
      {
        onSuccess: () => {
          trackEvent({
            event: 'click_modify_group_info',
            params: { groupId },
          });
        },
      }
    );
  });

  return (
    <AppScreen
      appBar={{
        title: '모임 정보 수정',
        renderRight: () => (
          <AppBarTextSubmitButton
            onClick={() => !isDisable() && !isPending && onSubmit()}
            disabled={isDisable()}
          />
        ),
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      {isExistHeroSlider ? (
        <GroupSettingEditInfoHeroSlider groupId={groupId} initialIndex={0} role={'superHost'} />
      ) : (
        <EmptyHeroImageBox groupId={groupId} />
      )}
      <Container paddingY={24}>
        <Typography typography={'label3Bold'} color={'gray900'}>
          대표사진
        </Typography>
        <Spacing size={4} />
        <Typography typography={'bodyM2Regular'} color={'gray600'}>
          전체 모임 목록에서 보이는 이미지에요.
        </Typography>
        <Spacing size={12} />
        <div className={s.ProfileImageSection}>
          <div
            className={s.ProfileImageWrapper}
            onClick={() => {
              trackEvent({
                event: 'click_change_group_info_image',
                params: {
                  type: 'setting_profile',
                  groupId,
                },
              });
              handleSetGroupProfile();
            }}
          >
            <ImageLoader
              className={s.GroupProfileImage}
              src={
                profileImageOriginImage
                  ? URL.createObjectURL(profileImageOriginImage)
                  : groupDetail?.profileImage.medium
              }
              isLoading={isProfileImageLoading}
              alt="모임 프로필 이미지"
            />
            <div className={s.ProfileCameraWrapper}>
              <IconCameraFill size={26} color={vars.$scale.color.gray600} />
            </div>
          </div>
        </div>
      </Container>
      <Spacing size={16} />
      <div className={s.Wrapper}>
        <TextField
          label="모임명"
          value={watch('name') ?? ''}
          onChange={(textValue) => setValue('name', textValue)}
          placeholder="모임명을 입력해주세요"
          maxLength={24}
          variant={'outlined'}
          isInvalid={!validateSchemaWithBoolean(groupFieldSchema.name, nameFieldValue)}
          errorMessage={
            validateSchemaWithMessage(groupFieldSchema.name, nameFieldValue)?.message ?? ''
          }
          config={{ invalidAfterBlurred: false }}
        />

        <Spacing size={24} />
        <GroupInfoCategory
          categoryId={watch('categoryId')}
          onSelectCategory={handleChangeCategory}
        />
        <Spacing size={51} />
        <MultilineTextField
          label="모임 소개"
          value={watch('description') ?? ''}
          onChange={(textValue) => setValue('description', textValue)}
          placeholder="모임 소개를 입력해주세요"
          maxLength={500}
          isInvalid={
            !validateSchemaWithBoolean(groupFieldSchema.description, descriptionFieldValue)
          }
          errorMessage={
            validateSchemaWithMessage(groupFieldSchema.description, descriptionFieldValue)
              ?.message ?? ''
          }
          config={{ invalidAfterBlurred: false }}
        />
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingEditGroupInfoPage, {
  pendingFallback: (
    <>
      <AppScreen>
        <ViewLoader />
      </AppScreen>
    </>
  ),
  rejectedFallback: (
    <>
      <AppScreen>
        <ViewError />
      </AppScreen>
    </>
  ),
});
