import type { ReactNode, RefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

interface ImpressionAreaImplProps {
  observeTargetRef: RefObject<HTMLElement | null>;
  children: ReactNode;
  threshold?: number;
  exposureTime?: number;
  onLogging: () => void;
}

const ImpressionAreaImpl = ({
  observeTargetRef,
  children,
  threshold = 0,
  exposureTime = 0,
  onLogging,
}: ImpressionAreaImplProps) => {
  const impressionInTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isObserved = useRef(false);

  const onLoggingRef = useRef(onLogging);

  const resetTimer = useCallback(() => {
    if (impressionInTimer.current) {
      clearTimeout(impressionInTimer.current);
      impressionInTimer.current = null;
    }
  }, []);

  useEffect(() => {
    onLoggingRef.current = onLogging;
  }, [onLogging]);

  const io = useMemo(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const { isIntersecting } = entry;

          /**
           * 이미 impression 로그가 처리된 경우 더이상 로깅하지 않아요.
           * */
          if (isObserved.current) {
            observer.unobserve(entry.target);
            return;
          }

          if (isIntersecting) {
            resetTimer();

            impressionInTimer.current = setTimeout(() => {
              onLoggingRef.current();
              isObserved.current = true;
            }, exposureTime);
          } else {
            resetTimer();
          }
        });
      },
      { threshold }
    );

    return observer;
  }, [threshold, exposureTime, resetTimer]);

  useEffect(() => {
    const el = observeTargetRef.current;
    el && io?.observe(el);

    return () => {
      resetTimer();
      isObserved.current = false;
      el && io?.unobserve(el);
    };
  }, [observeTargetRef, io, resetTimer]);

  return <>{children}</>;
};

export const ImpressionArea = ({
  children,
  ...props
}: Omit<ImpressionAreaImplProps, 'observeTargetRef'>) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div ref={ref}>
      <ImpressionAreaImpl observeTargetRef={ref} {...props}>
        {children}
      </ImpressionAreaImpl>
    </div>
  );
};
