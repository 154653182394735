import { Category } from '@community-group/api/lib/group/models/category';
import { Typography } from '@community-group/components';
import clsx from 'clsx';
import { useLayoutEffect, useRef } from 'react';

import { trackEvent } from '@/utils/analytics';

import * as s from './SubCategoryFilter.css';

type Props = {
  selectedCategory: number;
  subCategories: Category['subCategories'];
  selectedSubCategory: string | undefined;
  onClickSubCategory: (id?: string) => void;
};

const SubCategoryFilter = ({
  selectedCategory,
  subCategories,
  selectedSubCategory,
  onClickSubCategory,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // subCategory 선택시 view 중앙으로 스크롤 시키는 로직
  useLayoutEffect(() => {
    if (containerRef.current) {
      const selectedSubCategory = containerRef.current.querySelector(`.selected_sub_category`);

      if (selectedSubCategory) {
        const containerWidth = containerRef.current.getBoundingClientRect().width;
        const containerLeft = containerRef.current.scrollLeft;
        const elementWidth = selectedSubCategory.clientWidth;
        const elementLeft = selectedSubCategory.getBoundingClientRect().left + containerLeft;

        const scrollLeft = elementLeft + elementWidth / 2 - containerWidth / 2;

        containerRef.current.scrollTo({
          left: scrollLeft,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedCategory, selectedSubCategory]);

  const handleClickSubCategory = (id?: string) => {
    trackEvent({
      event: 'click_group_home_sub_category',
      params: {
        subCategory: id,
        mainCategory: selectedCategory,
      },
    });
    onClickSubCategory(id);
  };

  return (
    <div className={s.Container} ref={containerRef}>
      <SubCategoryItem
        key={selectedCategory + '_' + 0}
        id={undefined}
        name="전체"
        icon={
          'https://assetstorage.krrt.io/1025317940251365954/9ca0daff-3a48-4c89-8b9a-dd02e4ecbe53/width=78,height=78.webp'
        }
        isSelected={!selectedSubCategory}
        onClick={() => handleClickSubCategory(undefined)}
      />
      {subCategories.map((subCategory) => (
        <SubCategoryItem
          key={selectedCategory + '_' + subCategory.id}
          {...subCategory}
          isSelected={selectedSubCategory === subCategory.id}
          onClick={() => handleClickSubCategory(subCategory.id ?? undefined)}
        />
      ))}
    </div>
  );
};

export default SubCategoryFilter;

type SubCategoryItemProps = {
  id?: string;
  name: string;
  icon: string;
  isSelected: boolean;
  onClick: () => void;
};

const SubCategoryItem = ({ name, icon, isSelected, onClick }: SubCategoryItemProps) => {
  return (
    <div
      className={clsx(s.SubCategoryItemContainer, isSelected && 'selected_sub_category')}
      onClick={onClick}
    >
      <div className={s.SubCategoryItemIconWrapper({ isSelected })}>
        <div
          className={s.SubCategoryItemIcon}
          style={{
            background: `url(${icon}) no-repeat center center / contain`,
          }}
        />
      </div>
      <Typography
        typography={isSelected ? 'caption2Bold' : 'caption2Regular'}
        className={s.SubCategoryItemText}
      >
        {name}
      </Typography>
    </div>
  );
};
