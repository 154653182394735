import {
  GroupCurrentUser,
  GroupMemberProfileResponseProfile,
} from '@community-group/api/lib/group/models';
import { SettingList } from '@community-group/components/features';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useHandleDelegateSuperHost } from '@/domain/GroupProfile/utils/useHandleDelegateSuperHost';

type Props = {
  groupId: string;
  targetUser: GroupMemberProfileResponseProfile;
  me: GroupCurrentUser;
};

const SuperHostDelegationSettingItem = ({ groupId, targetUser, me }: Props) => {
  const { handleDelegateSuperHost } = useHandleDelegateSuperHost({ groupId, currentUser: me });
  const handleProfileClick = () => {
    handleDelegateSuperHost(targetUser);
  };

  return (
    <SettingList.Item
      title="모임장 위임"
      onClick={handleProfileClick}
      renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
    />
  );
};

export default SuperHostDelegationSettingItem;
