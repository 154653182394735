import { ChallengeSummaryPresentation } from '@community-group/api/lib/group/models';
import { ImageWithBorder, Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';

import ChallengeListItem from '@/features/ChallengeList/ChallengeListItem';

import * as s from './style.css';
type Props = {
  groupId: number;
  challenge: ChallengeSummaryPresentation;
  groupName: string;
  groupProfileImageUrl: string;
  groupRegionName: string;
  groupCategoryId?: number;
};

const ChallengeListItemWithGroupInfo = ({
  groupId,
  challenge,
  groupName,
  groupProfileImageUrl,
  groupRegionName,
  groupCategoryId,
}: Props) => {
  return (
    <div className={s.Container}>
      <div className={s.GroupInfoContainer}>
        <ImageWithBorder
          src={groupProfileImageUrl}
          borderRadius={'.25rem'}
          border={`1px solid ${vars.$scale.color.grayAlpha50}`}
          width={20}
          height={20}
        />
        <VerticalSpacing size={8} />
        <Typography
          typography="caption1Regular"
          color="gray700"
          className={s.Title}
          ellipsisAfterLines={1}
        >
          {groupName}
        </Typography>
        <VerticalSpacing size={4} />
        <Typography typography="caption1Regular" color="gray600">
          ⸱
        </Typography>
        <VerticalSpacing size={4} />
        <Typography typography="caption1Regular" color="gray600">
          {groupRegionName}
        </Typography>
      </div>
      <Spacing size={12} />
      <ChallengeListItem
        padding="0"
        groupId={groupId}
        groupName={groupName}
        groupCategoryId={groupCategoryId}
        challenge={challenge}
      />
    </div>
  );
};

export default ChallengeListItemWithGroupInfo;
