import { Image as ImageType } from '@community-group/api/lib/group/models';
import { withAsyncBoundary } from '@community-group/components';

import ContentImageList from '@/components/common/ContentImageList';
import { useFlow } from '@/stackflow';

type Props = {
  images: ImageType[];
  groupId: string;
  postId: string;
};

const PostDetailImages = ({ images, groupId, postId }: Props) => {
  const { push } = useFlow();

  const handleImageClick = (index: number) => {
    push('ImageViewerPage', {
      content: 'post',
      initialIndex: index.toString(),
      groupId,
      postId,
      screenName: 'groupPostImageViewer',
    });
    return;
  };

  return <ContentImageList images={images} onImageClick={handleImageClick} />;
};

export default withAsyncBoundary(PostDetailImages, {});
