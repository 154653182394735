import murmurhash from 'murmurhash-js';

import {
  AB_TEST_LOCAL_STORAGE_KEY,
  ABC_TEST_LOCAL_STORAGE_KEY,
} from '@/_app/utils/vConsole/plugin/initInternalToolVConsolePlugin';

export type UserExperimentalABCGroup = 'A' | 'B' | 'C';

/**
 * @summary **ABC 테스트**
 */
const ABCGroupSampleRestRate = Math.floor(0.7 / 3);
export const getUserExperimentalABCGroup = (userId: number): UserExperimentalABCGroup => {
  // 내부 유저 테스트를 위해 로컬 스토리지에 저장된 값을 사용
  const InternalABCSettingValue = window?.localStorage.getItem(ABC_TEST_LOCAL_STORAGE_KEY);
  if (InternalABCSettingValue) {
    return InternalABCSettingValue as UserExperimentalABCGroup;
  }

  const hash = murmurhash(String(userId));
  const normalizedHash = hash / 0xffffffff;

  if (normalizedHash < 0.1) {
    return 'A';
  } else if (normalizedHash < 0.2) {
    return 'B';
  } else if (normalizedHash < 0.3) {
    return 'C';
  } else if (normalizedHash < 0.3 + ABCGroupSampleRestRate) {
    return 'A';
  } else if (normalizedHash < 0.3 + ABCGroupSampleRestRate * 2) {
    return 'B';
  }
  return 'C';
};

export type UserExperimentalABGroup = 'A' | 'B';

/**
 * @summary **AB 테스트**
 */
export const getUserExperimentalABGroup = (
  userId: number | string | null,
  sampleRates: { A: number; B: number; C: number; D: number } = AMPLITUDE_AB_EXPERIMENTAL_RATE
): UserExperimentalABGroup => {
  // 내부 유저 테스트를 위해 로컬 스토리지에 저장된 값을 사용
  const InternalABSettingValue = window?.localStorage.getItem(AB_TEST_LOCAL_STORAGE_KEY);
  if (InternalABSettingValue) {
    return InternalABSettingValue as UserExperimentalABGroup;
  }

  if (!userId) return 'A';
  const hash = murmurhash(String(userId));
  const normalizedHash = hash / 0xffffffff;

  // enter_home 같은 이벤트가 샘플링 되어지고 있어서 4구간으로 나눠 로깅
  // 논의 : https://daangn.slack.com/archives/C03RY6TNUBD/p1714119774278299?thread_ts=1714113986.176339&cid=C03RY6TNUBD

  // 샘플링되는 이벤트들의 구간 : 0 ~ 0.3
  // 샘플링 되는 구간을 절반으로 나눠 실험하기 위해 아래와 같이 구간을 설정
  // A 구간 (총 50%): 0 ~ 0.15 & 0.3 ~ 0.65
  // B 구간 (총 50%): 0.15 ~ 0.3 & 0.65 ~ 1.0

  // A 그룹 샘플링
  if (normalizedHash < sampleRates.A) {
    return 'A';
  }
  // B 그룹 샘플링 (A 그룹을 제외한 나머지 중에서 B 그룹 비율 계산)
  else if (normalizedHash < sampleRates.A + sampleRates.B) {
    return 'B';
  }
  // A 그룹 샘플링 (A 그룹을 제외한 나머지 중에서 B 그룹 비율 계산)
  else if (normalizedHash < sampleRates.A + sampleRates.B + sampleRates.C) {
    return 'A';
  }
  // C 그룹 샘플링 (A, B 그룹을 제외한 나머지)
  return 'B';
};

// 샘플링 비율 예시
export const AMPLITUDE_AB_EXPERIMENTAL_RATE = { A: 0.15, B: 0.15, C: 0.35, D: 0.35 };
