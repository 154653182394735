import { Spacing } from '@community-group/components';
import { v4 as uuidv4 } from 'uuid';

import { useGetSearchHotKeywords } from '@/api/hooks/useGetSearchHotKeywords';
import { useFlow } from '@/stackflow';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useStore } from '@/store';
import { trackEvent } from '@/utils/analytics';

import * as s from './HotSearchedListSection.css';

const HotSearchListSection = () => {
  const { push } = useFlow();
  const { utm_medium } = useQueryParams();

  const { data: hotKeywords } = useGetSearchHotKeywords();

  const { addSearchHistoyItem } = useStore();

  const handleClick = (query: string, index: number) => {
    addSearchHistoyItem(query);
    trackEvent({
      event: 'click_hot_searched_item',
      params: {
        listItemIndex: index,
        query,
      },
      sample: true,
    });

    push('SearchResultPage', {
      query: encodeURIComponent(query),
      queryFrom: 'recommend',
      queryId: uuidv4(),
    });
  };

  return (
    <section>
      <h3 className={s.Title}>이웃들이 많이 찾고 있어요!</h3>
      <Spacing size={4} />
      <ul className={s.List}>
        {hotKeywords.map(({ label }, index) => (
          <li className={s.ListItem} key={label} onClick={() => handleClick(label, index)}>
            {label}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default HotSearchListSection;
