import { IconCheckmarkFlowerFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';

import * as s from './MemberGradeMissionCircleProgress.css';

type Props = {
  progress: number;
  size: 'small' | 'large';
};

const MemberGradeMissionCircleProgress = ({ progress, size }: Props) => {
  const isLarge = size === 'large';
  const circleSize = isLarge ? 44 : 32;
  const strokeWidth = isLarge ? 3 : 2;
  const iconSize = isLarge ? 20 : 14;

  const radius = (circleSize - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  return (
    <div className={s.Container({ size })}>
      <svg className={s.Svg} viewBox={`0 0 ${circleSize} ${circleSize}`}>
        <circle className={s.Circle({ size })} cx={circleSize / 2} cy={circleSize / 2} r={radius} />
        <motion.circle
          className={s.Progress({ size })}
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={radius}
          initial={{ strokeDasharray: circumference, strokeDashoffset: circumference }}
          animate={{
            strokeDashoffset: circumference - (progress / 100) * circumference,
          }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        />
      </svg>
      <div className={s.Content}>
        <IconCheckmarkFlowerFill size={iconSize} color={vars.$scale.color.gray700} />
      </div>
    </div>
  );
};

export default MemberGradeMissionCircleProgress;
