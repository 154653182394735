import { GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { useCallback } from 'react';

import { useSubscribedPoiStream } from '@/components/group/PostForm/hooks/useSubscribedPoiStream';
import { useBridge } from '@/contexts/Bridge';
import { usePutGroup } from '@/domain/Group/hooks/usePutGroup';
import { usePlaceSearchClient } from '@/hooks/usePlaceSearchClient';
import { trackEvent } from '@/utils/analytics';

type Props = {
  groupId: string;
};

type ExternalDeleteHandler = {
  onSuccess?: (data: GroupDetailPresentation) => void;
  onError?: (error: Error) => void;
};

export const useHandleGroupRegularMeetupPlace = ({ groupId }: Props) => {
  const { bridge } = useBridge();
  const { mutate: putGroup } = usePutGroup({});
  const { openSearchPoiPage } = usePlaceSearchClient();

  const handleUpdatePoiButtonClick = useCallback(() => {
    trackEvent({
      event: 'click_search_poi_page',
      params: { type: 'meetupPois', section: 'SettingMeetupPoisMenuItem' },
    });
    openSearchPoiPage({});
  }, [openSearchPoiPage]);

  const handleDeletePoiButtonClick = useCallback(
    (externalHandler: ExternalDeleteHandler) => {
      putGroup(
        { id: Number(groupId), groupModifyForm: { meetupPoiItems: [] } },
        {
          onSuccess: (data) => {
            bridge.openToast({ toast: { body: '자주 모이는 장소를 삭제했어요.' } });
            externalHandler.onSuccess?.(data.data.group);
          },
          onError: (error) => {
            if (error instanceof Error) externalHandler.onError?.(error);
          },
        }
      );
    },
    [groupId, putGroup]
  );

  useSubscribedPoiStream({
    callback: (poi) => {
      putGroup(
        {
          id: Number(groupId),
          groupModifyForm: { meetupPoiItems: [{ id: poi.poiId, type: poi.type }] },
        },
        {
          onSuccess: () => {
            trackEvent({
              event: 'update_group_meetup_pois',
              params: {
                section: 'SettingMeetupPoisMenuItem',
              },
            });
            bridge.openToast({ toast: { body: '자주 모이는 장소를 등록했어요.' } });
          },
        }
      );
    },
  });

  return { handleUpdatePoiButtonClick, handleDeletePoiButtonClick };
};
