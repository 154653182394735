import {
  IconCalendarFill,
  IconEyeFill,
  IconHorizline2VerticalChatbubbleRectangularRightFill,
  IconPenHorizlineFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useSuspenseQueries } from '@tanstack/react-query';
import { ReactNode, useMemo } from 'react';

import { useQueryGroupMemberGradeRequirements } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeRequirements';
import { useQueryGroupProfileUserActivities } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserActivities';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

export type MemberGradeMission = {
  icon: ReactNode;
  title: string;
  count: number;
  goalCount: number;
  onClick: () => void;
  isCompleted: boolean;
};

type Props = {
  groupId: string;
  userId: string;
};

const useMemberGradeMissionList = ({ groupId, userId }: Props): MemberGradeMission[] => {
  const { push } = useFlow();

  const [
    {
      data: { count },
    },
    { data: requirements },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupProfileUserActivities({ groupId, userId }),
      useQueryGroupMemberGradeRequirements(groupId),
    ],
  });

  const missionRequirements = requirements.reduce(
    (acc, curr) => {
      const mission = acc[curr.activity];

      mission.count = count[mission.key];
      mission.goalCount = curr.goalCount;
      mission.isCompleted = mission.count >= mission.goalCount;
      return acc;
    },
    {
      visit_group: {
        key: 'groupVisitCount',
        title: '모임 방문하기',
        count: 0,
        goalCount: 0,
        isCompleted: false,
      },
      create_post: {
        key: 'createdArticleCount',
        title: '게시글 작성하기',
        count: 0,
        goalCount: 0,
        isCompleted: false,
      },
      create_comment: {
        key: 'createdCommentCount',
        title: '댓글 작성하기',
        count: 0,
        goalCount: 0,
        isCompleted: false,
      },
      join_meetup: {
        key: 'joinedMeetupCount',
        title: '일정 참여하기',
        count: 0,
        goalCount: 0,
        isCompleted: false,
      },
    }
  );

  const missionList = useMemo(() => {
    const getThemeIconColor = (isCompleted) =>
      isCompleted ? vars.$semantic.color.success : vars.$scale.color.gray900;

    const onTrackEvent = (activityName) => {
      trackEvent({
        event: 'click_member_grade_mission_button',
        params: {
          groupId,
          activityName,
        },
        sample: true,
      });
    };

    return [
      {
        ...missionRequirements.visit_group,
        icon: (
          <IconEyeFill
            size={16}
            color={getThemeIconColor(missionRequirements.visit_group.isCompleted)}
          />
        ),
        onClick: () => {
          onTrackEvent('visitGroupCount');
        },
      },
      {
        ...missionRequirements.create_post,
        icon: (
          <IconPenHorizlineFill
            size={16}
            color={getThemeIconColor(missionRequirements.create_post.isCompleted)}
          />
        ),
        onClick: () => {
          onTrackEvent('createPostCount');
          push('GroupPostNewPage', {
            groupId,
          });
        },
      },
      {
        ...missionRequirements.create_comment,
        icon: (
          <IconHorizline2VerticalChatbubbleRectangularRightFill
            size={16}
            color={getThemeIconColor(missionRequirements.create_comment.isCompleted)}
          />
        ),
        onClick: () => {
          onTrackEvent('createCommentCount');
          push('GroupDetailFeedListPage', { groupId });
        },
      },
      {
        ...missionRequirements.join_meetup,
        icon: (
          <IconCalendarFill
            size={16}
            color={getThemeIconColor(missionRequirements.join_meetup.isCompleted)}
          />
        ),
        onClick: () => {
          onTrackEvent('joinMeetupCount');
          push('GroupDetailMeetupPage', { groupId });
        },
      },
    ].filter((mission) => mission.goalCount > 0);
  }, [missionRequirements, groupId, push]);

  return missionList;
};

export default useMemberGradeMissionList;
