import { AsyncBoundary, ViewLoader } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';

import GroupNotificationSettingSection from '@/features/GroupSetting/GroupNotification';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupNotificationSettingPageParams = Pick<PageParams, 'groupId'>;

const GroupNotificationSettingPage: ActivityComponentType<
  GroupNotificationSettingPageParams
> = () => {
  const { groupId } = usePathParams();

  return (
    <AppScreen
      appBar={{
        title: '모임 알림 설정',
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <GroupNotificationSettingSection isSection={false} groupId={groupId} />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupNotificationSettingPage;
