import { GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { Spacing, TextButton, withAsyncBoundary } from '@community-group/components';
import { IconChevronRightFill, IconChevronUpLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';
import { MouseEvent, MouseEventHandler, useMemo } from 'react';

import GroupMemberGradeMissionBanner from '@/components/common/GroupMemberGradeMissionBanner';
import MemberGradeMissionList from '@/components/common/GroupMemberGradeMissionBanner/MemberGradeMissionList';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import useMainBannerFolded from '../../hooks/useMainBannerFolded';
import useMemberGradeMissionList from '../../hooks/useMemberGradeMissionList';

type Props = {
  group: GroupDetailPresentation;
  userId: string;
};

const GroupDetailMemberGradeMissionBanner = ({ group, userId }: Props) => {
  const { push } = useFlow();

  const missionList = useMemberGradeMissionList({ groupId: group.id.toString(), userId });
  const isMissionCompleted = useMemo(() => {
    return missionList.every(({ isCompleted }) => isCompleted);
  }, [missionList]);

  const { foldedMainBanner, handleToggle } = useMainBannerFolded();
  const isBannerFolded = useMemo(() => {
    return foldedMainBanner || isMissionCompleted;
  }, [foldedMainBanner, isMissionCompleted]);

  const opacityDuration = isBannerFolded ? 0.2 : 0.5;
  const animateConfig = isBannerFolded
    ? { opacity: 0, height: 0, transitionEnd: { display: 'none' } }
    : { opacity: 1, height: 'auto', transitionEnd: { display: 'block' } };

  const title = useMemo(() => {
    if (isMissionCompleted) return '등업 신청하기';

    const defaultTitle = '멤버 등업 미션';
    if (!isBannerFolded) return defaultTitle;

    const mission = missionList.find(({ isCompleted }) => !isCompleted);
    return mission?.title ?? defaultTitle;
  }, [isMissionCompleted, isBannerFolded, missionList]);

  const progress = useMemo(() => {
    const activityCount = missionList.reduce((acc, mission) => {
      const count = mission.isCompleted ? mission.goalCount : mission.count;
      return acc + count;
    }, 0);
    const totalGoalCount = missionList.reduce((acc, mission) => acc + mission.goalCount, 0);
    const value = (activityCount / totalGoalCount) * 100;

    return Math.round(value / 10) * 10;
  }, [missionList]);

  const handleBannerClick = async () => {
    if (isMissionCompleted) {
      trackEvent({
        event: 'click_apply_member_grade_upgrade',
        params: {
          groupId: group.id.toString(),
          userId,
          groupName: group.name,
          categoryId: group.category.id,
          categoryName: group.category.name,
          from: 'banner',
        },
        sample: true,
      });

      push('GroupMemberGradeDetailPage', {
        groupId: group.id.toString(),
        userId: userId.toString(),
        isBannerApplication: 'true',
        from: 'banner',
      });
      return;
    }

    if (!isBannerFolded) return;
    const mission = missionList.find(({ isCompleted }) => !isCompleted);
    mission?.onClick();
  };

  const handleToggleButtonClick = (e) => {
    e.stopPropagation();
    handleToggle();
  };

  const handleGradeButtonClick = (e) => {
    e.stopPropagation();
    push('GroupMemberGradeDetailPage', {
      groupId: group.id.toString(),
      userId,
      from: 'banner',
    });
  };

  if (missionList.length === 0) return null;

  return (
    <GroupMemberGradeMissionBanner
      title={title}
      progress={progress}
      size="large"
      onClick={handleBannerClick}
      actionButton={
        <BannerActionButton
          isBannerFolded={isBannerFolded}
          isMissionCompleted={isMissionCompleted}
          onClick={handleToggleButtonClick}
        />
      }
    >
      <motion.div
        style={{ width: '100%' }}
        animate={animateConfig}
        initial={false}
        transition={{
          opacity: { duration: opacityDuration, type: 'spring', bounce: 0.26 },
          height: { duration: 0.5, type: 'spring', bounce: 0.26 },
        }}
      >
        <Spacing size={16} />
        <MemberGradeMissionList missionList={missionList} />
        <Spacing size={16} />
        <TextButton
          variant="secondaryLow"
          size="small"
          width="100%"
          suffix={<IconChevronRightFill size={14} />}
          onClick={handleGradeButtonClick}
        >
          내 등급 보기
        </TextButton>
      </motion.div>
    </GroupMemberGradeMissionBanner>
  );
};

type BannerActionButtonProps = {
  isBannerFolded: boolean;
  isMissionCompleted: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const BannerActionButton = ({
  isBannerFolded,
  isMissionCompleted,
  onClick,
}: BannerActionButtonProps) => {
  const transform = isMissionCompleted
    ? 'rotate(90deg)'
    : isBannerFolded
    ? 'rotate(180deg)'
    : 'rotate(0deg)';

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (isMissionCompleted) return;
    onClick(e);
  };

  return (
    <button style={{ transform }} onClick={handleClick}>
      <IconChevronUpLine size={20} color={vars.$scale.color.gray600} />
    </button>
  );
};

export default withAsyncBoundary(GroupDetailMemberGradeMissionBanner, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});
