import { groupClient } from '@community-group/api';
import { PaginationQueryParams } from '@community-group/components/shared';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useRead } from '@/shared/api/hooks/useRead';

import { GROUP_CHALLENGE_QUERY_KEY } from '../queries';

type Props = {
  groupId: number;
  challengeId: number;
} & PaginationQueryParams;

export const useReadChallengeParticipantsRank = ({
  groupId,
  challengeId,
  initialCursor,
}: Props) => {
  const read = useRead(
    groupClient.api.GroupChallengeMemberApi.apiV1GroupsGroupIdChallengesChallengeIdRankingsGet
  );

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  } = useSuspenseInfiniteQuery({
    queryKey: GROUP_CHALLENGE_QUERY_KEY.participantsRank(
      groupId.toString(),
      challengeId.toString()
    ),
    queryFn: ({ pageParam = initialCursor }) => {
      return read({
        groupId,
        challengeId,
        cursor: pageParam,
      });
    },
    initialPageParam: initialCursor,
    getNextPageParam: (data) =>
      data.data.pagination?.hasNext ? data.data.pagination.endCursor : undefined,
    select: (data) => ({
      pages: data.pages.flatMap((page) => page.data.contents),
      pageParams: data.pageParams,
    }),
  });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  };
};
