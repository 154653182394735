import useGetIsExistHeroSlider from '@/components/group/Detail/hooks/useGetIsExistHeroSlider';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import GotoGroupHomeBanner from './GotoGroupHomeBanner';

type Props = { groupId: string };

function GroupDetailNotMemberBanner({ groupId }: Props) {
  const { refer_root = '' } = useQueryParams();
  const isExistHeroSliderPhotos = useGetIsExistHeroSlider({ groupId });

  // refer_root가 있는 경우, 외부에서 진입한 경우임으로 모임 홈 진입 배너 표시
  if (refer_root !== '') {
    return <GotoGroupHomeBanner isExistHeroSliderPhotos={isExistHeroSliderPhotos} />;
  }

  return null;
}

export default GroupDetailNotMemberBanner;
