import { Category } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import React, { useMemo } from 'react';

import { useGetCategories } from '@/api/hooks/useGetCategories';
import { FilterType } from '@/components/group/Home/components/ExplorerGroupTab/ExplorerGroupListSection';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './CreateGroupBanner.css';

type Props = {
  filter: FilterType;
};

type IncludeSubCategory = Category;

const CreateGroupBanner = ({ filter }: Props) => {
  const { push } = useFlow();

  const { categories } = useGetCategories();

  const selectedCategoryData = useMemo(() => {
    const selectedCategory = categories?.find((item) => item.id === filter.category);

    if (filter.subCategory) {
      const selectedSubCategory = (selectedCategory as IncludeSubCategory)?.subCategories.find(
        (item) => item.id === filter.subCategory
      );
      if (selectedSubCategory) return selectedSubCategory;
    }

    if (selectedCategory?.id === undefined || selectedCategory?.id === 0) {
      return null;
    }

    return selectedCategory;
  }, [categories, filter.category, filter.subCategory]);

  const openCreateGroupPage = () => {
    trackEvent({
      event: 'click_home_explorer_group_creating_group_banner',
      params: {
        referrer: 'home_bottom_banner',
        mainCategory: filter.category,
        subCategory: filter.subCategory,
      },
    });
    push('GroupNewSetGroupCategoryRecommendPage', {});
  };

  return (
    <div className={s.Container} onClick={openCreateGroupPage}>
      <div className={s.LeftWrapper}>
        <div className={s.IconWrapper}>
          <img src={selectedCategoryData?.icon} className={s.Icon} />
        </div>
        <div className={s.TextWrapper}>
          <Typography typography="subtitle2Bold" color="gray900" ellipsisAfterLines={1}>
            {selectedCategoryData?.name ? `${selectedCategoryData?.name} 모임` : '모임'}을 직접
            만들어보세요
          </Typography>
          <Typography typography="caption2Regular" color="gray700" ellipsisAfterLines={1}>
            비슷한 관심사의 이웃들과 모임을 시작해보세요.
          </Typography>
        </div>
      </div>
      <div className={s.RightIconWrapper}>
        <IconChevronRightLine size={20} color={vars.$scale.color.gray700} />
      </div>
    </div>
  );
};

export default CreateGroupBanner;
