import { AsyncBoundary, ViewError, ViewLoader } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { v4 as uuidv4 } from 'uuid';

import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import HotSearchListSection from '../components/HotSearchedListSection';
import RecentSearchListSection from '../components/RecentSearchedListSection';
import RecommendedGroupListSection from '../components/RecommendedGroupListSection';
import SearchBar from '../components/SearchBar';

const SearchHomePage: ActivityComponentType = () => {
  const { push } = useFlow();
  const { utm_medium } = useQueryParams();

  useEnterTrackEvent({
    event: 'enter_search_home',
    sample: true,
  });

  return (
    <AppScreen
      appBar={{
        renderRight: () => (
          <div
            style={{
              width: 'calc(100vw - 3.25rem - 14px)',
              position: 'relative',
              left: '-8px',
            }}
          >
            <SearchBar
              onSubmit={(value) => {
                push('SearchResultPage', {
                  query: encodeURIComponent(value),
                  queryFrom: 'typed',
                  queryId: uuidv4(),
                });
              }}
              autoFocus
            />
          </div>
        ),
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <SearchHomeView />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default SearchHomePage;

const SearchHomeView = () => {
  return (
    <div
      style={{
        overflowX: 'auto',
      }}
    >
      <Spacing size={8} />
      <HotSearchListSection />
      <Spacing size={24} />
      <RecentSearchListSection />
      <RecommendedGroupListSection />
      <Spacing size={32} />
    </div>
  );
};
