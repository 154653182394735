import { ChallengeBannerPresentationChallenges } from '@community-group/api/lib/group/models';
import { Spacing } from '@community-group/components';
import { motion } from 'framer-motion';
import { useMemo } from 'react';

import useMainBannerFolded from '@/components/group/DetailV2/hooks/useMainBannerFolded';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import {
  getCertificationIntervalText,
  getCertificationTotalDaysText,
} from '@/features/Challenge/utils/certification';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import BannerHeader from '../BannerHeader';
import ChallengeBannerItem from '../ChallengeBannerItem';
import FooterButton from '../FooterButton';
import * as s from './style.css';

type Props = {
  challenge: ChallengeBannerPresentationChallenges;
};

const SingleAttendedChallenge = ({ challenge }: Props) => {
  const { push } = useFlow();
  const { groupId } = usePathParams();
  const title = '이번 주 인증글 남기기';
  const subTitle = useMemo(() => {
    const totalWeeks = getCertificationTotalDaysText(
      challenge.totalDays,
      challenge.certifyIntervalDays
    );

    const certificationInterval = getCertificationIntervalText(challenge.certifyIntervalDays);
    return `${totalWeeks} ⸱ ${certificationInterval} 인증`;
  }, [challenge]);

  const { foldedMainBanner, handleToggle } = useMainBannerFolded();

  const { data: group } = useReadGroupDetail(groupId);
  const handleClickFooterButton = () => {
    trackEvent({
      event: 'click_challenge_banner_item',
      params: {
        groupId,
        challengeId: challenge.id,
        challengeName: challenge.name,
        challengeStatus: challenge.status,
        categoryId: group?.category.id,
        groupName: group?.name,
        type: 'current_rank',
      },
    });
    push('GroupChallengeDetailPage', {
      groupId: groupId.toString(),
      challengeId: challenge.id.toString(),
    });
  };

  const animateConfig = foldedMainBanner
    ? { opacity: 0, height: 0, transitionEnd: { display: 'none' } }
    : {
        opacity: 1,
        height: 'auto',
        transitionEnd: {
          display: 'block',
        },
      };

  const handleChangeCloseChallengeBanner = () => {
    handleToggle();
  };

  return (
    <>
      <BannerHeader
        title={title}
        subTitle={subTitle}
        isOpened={!foldedMainBanner}
        onToggle={handleChangeCloseChallengeBanner}
      />

      <motion.div
        style={{ width: '100%' }}
        animate={animateConfig}
        initial={false}
        transition={{
          opacity: {
            duration: 0.3,
            type: 'just',
            bounce: 0.26,
          },
          height: {
            duration: 0.3,
            type: 'just',
            bounce: 0.26,
          },
        }}
      >
        <div className={s.ChallengeListContainer}>
          <Spacing size={16} />
          <ChallengeBannerItem group={group} key={challenge.id} {...challenge} />
        </div>
      </motion.div>
    </>
  );
};

export default SingleAttendedChallenge;
