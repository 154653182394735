import { groupClient } from '@community-group/api';
import {
  AsyncBoundary,
  ChipFilter,
  ImpressionArea,
  useBottomSheet,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { QueryFromType } from '@daangn/search-log-group';
import { useState } from 'react';

import { ORDER_MEETUP_KR_TYPO } from '@/api/base/group';
import {
  SEARCH_MEETUP_ORDER_TYPE,
  useGetMeetupSearchList,
} from '@/api/hooks/useGetMeetupSearchList';
import SortingMeetupFilterBottomSheet from '@/components/common/SortingFilterBottomSheet/SortingMeetupFilterBottomSheet';
import { MeetupHomeListCard } from '@/components/group/Home/components/MeetupTab/ExplorerMeetupSection/MeetupHomeListCardItem';
import useKarrotSearchLogger, { mappedSearchItemParams } from '@/hooks/useKarrotSearchLogger';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import { SearchTabType } from '../../Page/Result';
import SearchResultEmptyView from '../SearchResultList/EmptyView';
import * as s from './SearchResultMeetup.css';
import { SearchResultMeetupLoader } from './SearchResultMeetupLoader';

const SearchResultMeetup = ({ currentTab }: { currentTab: SearchTabType }) => {
  const [selectedSorting, setSelectedSorting] = useState<SEARCH_MEETUP_ORDER_TYPE>('meet_at_asc');
  const { open: openBottomSheet, close: closeBottomSheet } = useBottomSheet();
  const { query = '' } = usePathParams();
  const { queryId, queryFrom } = useQueryParams();

  const { data, hasNextPage } = useGetMeetupSearchList({
    order: selectedSorting,
    searchQuery: decodeURIComponent(query),
  });

  const meetups = data?.pages.map(({ data }) => data.meetups);
  const flatten = meetups?.flat(1) ?? ([] as groupClient.model.GroupMeetupSearchPresentation[]);

  const { push } = useFlow();

  const searchLogger = useKarrotSearchLogger();

  return (
    <div>
      <div className={s.switchOrderWrapper}>
        <ChipFilter
          label={ORDER_MEETUP_KR_TYPO[selectedSorting]}
          onClick={() => {
            trackEvent({
              event: 'click_sorting_meetup',
              params: {
                selectedSorting: selectedSorting,
                referrer: 'home',
              },
            });
            openBottomSheet({
              element: (
                <SortingMeetupFilterBottomSheet
                  setSelectedSorting={setSelectedSorting}
                  selectedSorting={selectedSorting}
                />
              ),
              onDimClose: closeBottomSheet,
            });
          }}
        />
      </div>
      {flatten.length <= 0 && <SearchResultEmptyView isMeetupSearch currentTab={currentTab} />}
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        {flatten.map((meetup, index) => {
          const searchParams = mappedSearchItemParams({
            collection: 'group_meetup',
            documentId: meetup.meetupId.toString(),
            queryId: queryId,
            index: index,
            query: query,
            queryFrom: queryFrom as QueryFromType,
            filterParams: {
              filterSort: selectedSorting,
            },
            tab: currentTab,
          });

          return (
            <ImpressionArea
              key={JSON.stringify(searchParams)}
              onLogging={() => {
                searchLogger.impressionGroupSearchItemV1(searchParams);
              }}
              exposureTime={300}
              threshold={0.99}
            >
              <MeetupHomeListCard
                title={meetup.title}
                headerLabel={[meetup.groupInfo.region.name]}
                meetupAtDisplayText={meetup.meetupAtDisplayText}
                currentParticipantsCount={meetup.currentParticipantCount}
                maximumParticipantsCount={meetup.maximumParticipantCount}
                image={meetup.image?.medium ?? ''}
                hostInfo={{
                  image: meetup.groupInfo?.profileImage?.small ?? '',
                  displayText: meetup.groupInfo?.name,
                  imageShape: 'square',
                }}
                onClick={() => {
                  searchLogger.clickedGroupSearchItemV1(searchParams);

                  trackEvent({
                    event: 'click_group_meetup_item',
                    params: {
                      meetupId: meetup.meetupId,
                      meetupName: meetup.title,
                      groupId: meetup.groupInfo?.groupId,
                      utm_medium: 'search_result',
                      referrer: 'search_result',
                      query: decodeURIComponent(query ?? ''),
                    },
                    sample: true,
                  });
                  push('GroupMeetupDetailPage', {
                    groupId: (meetup.groupInfo?.groupId ?? '').toString(),
                    meetupId: meetup.meetupId.toString(),
                  });
                }}
                viewCount={meetup.viewCount}
              />
            </ImpressionArea>
          );
        })}
      </AsyncBoundary>
      {hasNextPage && <SearchResultMeetupLoader order={selectedSorting} />}
    </div>
  );
};

export default SearchResultMeetup;
