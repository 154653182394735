import {
  Container,
  Spacing,
  Typography,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useGetMemberList } from '@/api/hooks/useGetMemberList';
import { LoadMoreMemberListContainer } from '@/components/common/LoadMoreContainer';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { useHandleErrorWithThrowAccessStatusPage } from '@/hooks/useHandleErrorWithThrowAccessStatusPage';
import { useFlow } from '@/stackflow';

import MemberProfileSection from '../components/MemberProfileSection';
import * as s from './MemberListForMember.css';

type Props = {
  groupId: string;
  searchValue: string;
};

const MemberListForMember = ({ groupId, searchValue }: Props) => {
  const { push } = useFlow();

  const handleErrorWithThrowErrorStatusPage = useHandleErrorWithThrowAccessStatusPage();
  const { data, hasNextPage } = useGetMemberList({
    groupId,
    keyword: searchValue,
    options: {
      onError: (error) =>
        handleErrorWithThrowErrorStatusPage({
          error,
          serviceType: 'group',
          groupId,
        }),
    },
  });

  const memberList = data?.pages.map(({ data }) => data.members);
  const flattenMemberList = memberList?.flat(1) ?? [];

  const [{ data: memberGradeStatus }] = useSuspenseQueries({
    queries: [useQueryGroupMemberGradeStatus(groupId)],
  });

  const memberGradeEnabled = useMemo(() => {
    return memberGradeStatus === 'system';
  }, [memberGradeStatus]);

  const getMoveGroupUserProfileDetailPage = (userId: number) => () => {
    push('GroupUserProfileDetailPage', {
      groupId,
      userId: userId.toString(),
    });
  };

  if (flattenMemberList.length === 0) {
    return (
      <Container paddingX={54} paddingY={70} className={s.ListContainer}>
        <Typography typography="subtitle1Regular" color="gray600">
          검색 결과가 없어요.
        </Typography>
        <Spacing size={4} />
        <Typography typography="subtitle1Regular" color="gray600">
          다른 닉네임으로 검색해보세요.
        </Typography>
      </Container>
    );
  }

  return (
    <Container paddingX={0} paddingY={0}>
      <ul>
        {flattenMemberList.map((member) => (
          <MemberProfileSection
            key={member.id}
            user={member}
            moveGroupUserProfileDetailPage={getMoveGroupUserProfileDetailPage(member.id)}
            config={{ memberGradeEnabled }}
          />
        ))}
      </ul>
      {hasNextPage && <LoadMoreMemberListContainer searchValue={searchValue} />}
    </Container>
  );
};

export default withAsyncBoundary(MemberListForMember, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
