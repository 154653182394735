import { useCallback, useMemo } from 'react';

import { VideoUploadErrorType } from '../types/Video';
import { DEFAULT_VALIDATION_CONFIG, ValidationConfig, validationVideoFile } from '../utils/video';

export type UploadVideoValidationError = {
  file: File;
  type: VideoUploadErrorType;
  message: {
    title: string;
    description: string;
  };
};

// 유효성 검사를 위한 훅
export const useVideoValidation = ({
  validationConfig = DEFAULT_VALIDATION_CONFIG,
  temporalSentryLogger,
}: {
  validationConfig?: Partial<ValidationConfig>;
  temporalSentryLogger?: (error: any) => void;
}) => {
  const validation = useMemo(() => {
    return { ...DEFAULT_VALIDATION_CONFIG, ...validationConfig };
  }, [validationConfig]);

  // 개별 파일 유효성 검사
  const validateVideoFile = useCallback(
    async (file: File) => {
      const validationResult = await validationVideoFile(file, validation, temporalSentryLogger);

      if (validationResult) {
        return { file, ...validationResult } as UploadVideoValidationError;
      }
      return undefined;
    },
    [validation]
  );

  // 파일 개수 제한 검사
  const validateFileCount = useCallback(
    (selectedFiles: File[], currentFileCount: number) => {
      const maxFileCount = validation.maxVideoCount;
      const availableSlots = maxFileCount - currentFileCount;

      const validFiles = selectedFiles.slice(0, availableSlots);
      const invalidFiles = selectedFiles.slice(availableSlots);

      if (invalidFiles.length > 0) {
        const error: UploadVideoValidationError = {
          file: invalidFiles[0],
          type: 'fileCount',
          message: {
            title: '최대 첨부 개수 초과',
            description: '사진, 동영상은 최대 10장까지 첨부할 수 있어요.',
          },
        };
        return { validFiles, invalidFiles, error };
      }

      return { validFiles, invalidFiles: [], error: undefined };
    },
    [validation.maxVideoCount]
  );

  // 모든 파일 유효성 검사 및 에러 처리
  const validateAllFiles = useCallback(
    async (selectedFiles: File[], currentFileCount: number) => {
      const { validFiles: initialValidFiles, error: fileCountError } = validateFileCount(
        selectedFiles,
        currentFileCount
      );
      const validationErrors: UploadVideoValidationError[] = [];

      // 파일 개수 에러 추가
      if (fileCountError) {
        validationErrors.push(fileCountError);
      }
      // 각 파일에 대한 유효성 검사 수행
      const fileValidationResults = await Promise.all(initialValidFiles.map(validateVideoFile));

      // 유효성 검사를 통과한 파일만 새로운 배열로 필터링
      const validFiles = initialValidFiles.filter((file, index) => {
        const result = fileValidationResults[index];
        if (result) {
          validationErrors.push(result);
          return false;
        }
        return true;
      });
      return { validFiles, validationErrors };
    },
    [validateFileCount, validateVideoFile]
  );

  return { validateVideoFile, validateFileCount, validateAllFiles };
};
