import {
  AsyncBoundary,
  ChipToggleButton,
  Spacing,
  Typography,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useState } from 'react';

import {
  MEETUP_PROGRESS_STATUS_FILTER,
  MEETUP_PROGRESS_STATUS_FILTER_KEYS,
} from '@/api/hooks/useGetMeetupList';
import { useGetMyGroupsMeetupLikes } from '@/api/hooks/useGetMyGroupsMeetupLikes';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { trackEvent } from '@/utils/analytics';

import { MeetupHomeListCard } from '../../Home/components/MeetupTab/ExplorerMeetupSection/MeetupHomeListCardItem';
import * as s from './index.css';
import { LikedMeetupListMoreLoader } from './LikedMeetupListMoreLoader';

const MyInfoLikedGroupMeetupListPage: ActivityComponentType = () => {
  const [selectedMeetupProgressStatusKey, setSelectedMeetupProgressStatusKey] =
    useState<MEETUP_PROGRESS_STATUS_FILTER_KEYS>('OPENED');

  return (
    <AppScreen
      appBar={{
        title: '관심 일정 목록',
      }}
    >
      <div className={s.wrapper}>
        <div className={s.filterWrapper}>
          <ChipToggleButton
            isSelected={selectedMeetupProgressStatusKey === 'OPENED'}
            onClick={() => {
              setSelectedMeetupProgressStatusKey('OPENED');
            }}
          >
            예정된 일정
          </ChipToggleButton>
          <ChipToggleButton
            isSelected={selectedMeetupProgressStatusKey === 'CLOSED'}
            onClick={() => {
              setSelectedMeetupProgressStatusKey('CLOSED');
            }}
          >
            종료된 일정
          </ChipToggleButton>
        </div>
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
          <MyInfoLikedGroupMeetupListView
            selectedMeetupProgressStatusKey={selectedMeetupProgressStatusKey}
          />
        </AsyncBoundary>
      </div>
    </AppScreen>
  );
};

type Props = { selectedMeetupProgressStatusKey: MEETUP_PROGRESS_STATUS_FILTER_KEYS };
const MyInfoLikedGroupMeetupListView = ({ selectedMeetupProgressStatusKey }: Props) => {
  const { push } = useFlow();

  const { data, hasNextPage } = useGetMyGroupsMeetupLikes({
    progressStatuses: MEETUP_PROGRESS_STATUS_FILTER[selectedMeetupProgressStatusKey],
  });

  const meetupList = data?.pages.map(({ data }) => data.meetups) ?? [];
  const flattenMeetupList = meetupList.flat(1);

  if (flattenMeetupList.length <= 0) {
    return (
      <div className={s.emptyView}>
        <Typography typography="subtitle1Regular" color="gray600" textAlign="center">
          관심 일정이 없어요.
          <br />
          관심 일정을 등록하고 알림을 받아보세요.
        </Typography>
        <Spacing size={38} />
        <BoxButton
          size="medium"
          variant="secondary"
          onClick={() => {
            push('HomePage', {
              activeHomeTab: 'meetupTab',
            });
          }}
        >
          일정 둘러보기
        </BoxButton>
      </div>
    );
  }

  return (
    <div className={s.listWrapper}>
      {flattenMeetupList.map((meetup) => {
        if (!meetup) return <></>;

        return (
          <MeetupHomeListCard
            key={meetup.id}
            title={meetup.title}
            headerLabel={[meetup?.groupInfo?.groupCreatedRegion?.name ?? '']}
            meetupAtDisplayText={meetup.meetupTimeAtDisplayText}
            currentParticipantsCount={meetup.currentParticipantsNumber}
            maximumParticipantsCount={meetup.maximumParticipantsNumber}
            image={meetup.image?.medium ?? ''}
            hostInfo={{
              image: meetup.groupInfo?.profileImage?.small ?? '',
              displayText: meetup.groupInfo?.name ?? '',
              imageShape: 'square',
            }}
            onClick={() => {
              push('GroupMeetupDetailPage', {
                groupId: (meetup.groupInfo?.id ?? '').toString(),
                meetupId: meetup.id.toString(),
              });

              trackEvent({
                event: 'click_liked_group_meetup_list_item',
                params: {
                  meetupId: meetup.id.toString(),
                  groupId: (meetup.groupInfo?.id ?? '').toString(),
                },
              });
            }}
          />
        );
      })}
      {hasNextPage && (
        <LikedMeetupListMoreLoader
          selectedMeetupProgressStatusKey={selectedMeetupProgressStatusKey}
        />
      )}
    </div>
  );
};

export default MyInfoLikedGroupMeetupListPage;
