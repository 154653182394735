import { groupClient } from '@community-group/api';
import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { getGroupPath } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export type MemberListOrderType =
  | 'entirePostCountDesc'
  | 'entireMeetupCountDesc'
  | 'joinedAtDesc'
  | 'joinedAtAsc'
  | 'lastVisitedAtDesc';

export const getMemberListPath = (groupId: string) => `${getGroupPath(groupId)}/members`;

export type MemberListParams = {
  groupId?: string;
  order?: MemberListOrderType;
  shownOnlyHost?: boolean;
  gradeIds?: Array<string>;
  roles?: Array<GroupMemberRoleEnum>;
  shownMemberActivities?: boolean;
  shownMemberApplication?: boolean;
  limit?: number;
  options?: {
    enabled?: boolean;
    onError: (error: Error) => void;
  };
  initialCursor?: string;
  keyword?: string;
};

/** @deprecated useReadGroupMembers로 통합될 예정이에요 */
export const useGetMemberList = ({
  groupId,
  order,
  shownOnlyHost,
  gradeIds,
  roles,
  shownMemberActivities,
  shownMemberApplication,
  limit = 30,
  initialCursor,
  keyword,
}: MemberListParams) => {
  const fetchInstance = useFetchInstance();
  const getMemberList = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch, isLoading, isFetching } =
    useSuspenseInfiniteQuery({
      queryKey: [
        getMemberListPath(groupId ?? ''),
        order,
        shownOnlyHost,
        gradeIds,
        roles,
        shownMemberActivities,
        shownMemberApplication,
        keyword,
      ],
      queryFn: async ({ pageParam = initialCursor }) =>
        await getMemberList({
          id: Number(groupId),
          order: order ?? 'joinedAtAscWithRole',
          shownOnlyHost: gradeIds?.length ? undefined : shownOnlyHost ?? false,
          gradeIds: gradeIds ?? undefined, // NOTE: gradeIds와 roles는 동시에 사용할 수 없음, 둘 다 사용하면 gradeIds가 우선권을 가짐
          roles: roles ?? undefined,
          shownMemberActivities: shownMemberActivities ?? false,
          shownMemberApplication: shownMemberApplication ?? false,
          cursor: pageParam ? pageParam : undefined,
          search: keyword,
          limit,
        }),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    });

  useEffect(() => {
    refetch();
  }, [groupId, order, shownOnlyHost]);

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading,
    isFetching,
  };
};

export const MEMBER_LIST_ORDER_TYPE: { [key in MemberListOrderType]: string } = {
  joinedAtDesc: '최근 가입 순',
  joinedAtAsc: '가입 오래된 순',
  lastVisitedAtDesc: '마지막 방문 순',
  entirePostCountDesc: '게시글 많은 순',
  entireMeetupCountDesc: '일정 참여 많은 순',
};
