import { Dialog, useDialog } from '@community-group/components';

import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';

import { BlockableActionNames } from './blockMessageMapModel';
import { useCheckGroupActionAvailableByGroupStatus } from './useCheckGroupActionAvailableByGroupStatus';

type Props = {
  groupId: string;
  actionName: BlockableActionNames;
};

export const useBlockGroupActionByGroupStatus = ({ groupId, actionName }: Props) => {
  const { data: group } = useReadGroupDetail(groupId);
  const { isAvailable, message } = useCheckGroupActionAvailableByGroupStatus({
    groupStatus: group.status,
    actionName,
  });

  const { open: openDialog, close: closeDialog } = useDialog();

  return (handler: (...args: any[]) => void) => {
    if (isAvailable) {
      return handler;
    }

    return () => {
      openDialog({
        element: (
          <Dialog
            description={message}
            secondaryActionIntent="nonpreferred"
            primaryActionLabel="확인"
            onPrimaryAction={closeDialog}
          />
        ),
      });
    };
  };
};
