import { Spacing, Typography, VerticalSpacing } from '@community-group/components';

import * as s from './index.css';

type Props = {
  title?: string;
  description?: string;
  image?: string;
} & React.HTMLAttributes<HTMLDivElement>;

// 모임 상세 > 일정 리스트에서 프로모션 페이지를 진입한 경우 모임 상세 피드 상단에 표시되는 배너
export const MeetupListBanner = ({ title, description, image, ...props }: Props) => {
  return (
    <div className={s.BigTitleWrapper} {...props}>
      <div className={s.BigTitleInnerWrapper}>
        {description && (
          <>
            <Typography typography="subtitle1Bold" color="blue800">
              {description}
            </Typography>
            <Spacing size={2} />
          </>
        )}
        {title && (
          <Typography typography="bodyM2Regular" color="blue800">
            {title}
          </Typography>
        )}
      </div>
      <VerticalSpacing size={14} />
      <img className={s.Image} src={image} width={80} height={80} />
    </div>
  );
};
