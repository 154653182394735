import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { Drawer, Spacing } from '@community-group/components';
import { memo, useMemo } from 'react';

import useGroupDetailSidebar from '../../hooks/useGroupDetailSidebar';
import * as s from './index.css';
import SidebarMenuSection from './SidebarMenuSection';
import SidebarProfile from './SidebarProfile';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
  memberGradeEnabled: boolean;
};

// 모임상세 사이드바 Portal 용 ID
const GROUP_DETAIL_SIDEBAR_ROOT_ID = 'GROUP_DETAIL_SIDEBAR_ROOT_ID';

const GroupDetailSidebar = ({ group, currentUser, memberGradeEnabled }: Props) => {
  const {
    isOpened,
    onClickClose,
    onProfileClick,
    onAlbumMenuClick,
    onMemberMenuClick,
    onSettingMenuClick,
    onApplicationBannerClick,
    onMissionBannerClick,
    onHomeMenuClick,
    onMeetupMenuClick,
    onNoticeMenuClick,
    onEditBoardButtonClick,
    onBoardItemClick,
    onCreateBoardButtonClick,
    onReportButtonClick,
    onGuideBookButtonClick,
    onShareButtonClick,
    onGroupLevelBannerClick,
  } = useGroupDetailSidebar({ group, currentUser });

  const sidebarProfileDescription = useMemo(() => {
    return memberGradeEnabled ? currentUser.grade.name : currentUser.subNickname;
  }, [memberGradeEnabled, currentUser.grade.name, currentUser.subNickname]);

  return (
    <>
      <div id={GROUP_DETAIL_SIDEBAR_ROOT_ID} />
      <Drawer
        isOpened={isOpened}
        onClickClose={onClickClose}
        selector={GROUP_DETAIL_SIDEBAR_ROOT_ID}
      >
        <div className={s.Container}>
          <Spacing size={18} />
          <SidebarProfile
            title={currentUser.nickname}
            imageUrl={currentUser.profileImage}
            description={sidebarProfileDescription}
            role={currentUser.grade.role}
            onProfileClick={onProfileClick}
          />
          <Spacing size={24} />
          <SidebarMenuSection
            groupId={currentUser.groupId}
            currentUser={currentUser}
            onAlbumMenuClick={onAlbumMenuClick}
            onMemberMenuClick={onMemberMenuClick}
            onSettingMenuClick={onSettingMenuClick}
            onApplicationBannerClick={onApplicationBannerClick}
            onMissionBannerClick={onMissionBannerClick}
            onHomeMenuClick={onHomeMenuClick}
            onMeetupMenuClick={onMeetupMenuClick}
            onNoticeMenuClick={onNoticeMenuClick}
            onEditBoardButtonClick={onEditBoardButtonClick}
            onBoardItemClick={onBoardItemClick}
            onCreateBoardButtonClick={onCreateBoardButtonClick}
            onReportButtonClick={onReportButtonClick}
            onGuideBookButtonClick={onGuideBookButtonClick}
            onShareButtonClick={onShareButtonClick}
            onGroupLevelBannerClick={onGroupLevelBannerClick}
          />
        </div>
      </Drawer>
    </>
  );
};

export default memo(GroupDetailSidebar);
