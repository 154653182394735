import { GroupDetailPresentation } from '@community-group/api/lib/group/models';
import {
  AsyncBoundary,
  BottomBasicButton,
  Typography,
  UploadImageType,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { IconXmarkFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';

import { getGroupPath } from '@/api/base/group';
import { useGetSuperHostOnBoardingMission } from '@/api/hooks/useGetSuperHostOnBoardingMission';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import CameraIcon from '@/assets/images/camera.png';
import { Container } from '@/components/common/Container';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useHandleSetGroupBackground } from '@/hooks/useHandleSetGroupBackground';
import { useHandleSetGroupProfile } from '@/hooks/useHandleSetGroupProfile';
import { queryClient } from '@/shared/api/instance';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import CreateGroupProfileImagePreview from '../../new/ProfileImage/CreateGroupProfileImagePreview';
import useCheckUnCompletedQuest from '../hooks/useCheckUnCompletedQuest';
import * as s from './index.css';

export type GroupOnboardProfilePageParams = Pick<PageParams, 'groupId'>;

const GroupOnboardProfilePage: ActivityComponentType<GroupOnboardProfilePageParams> = () => {
  const { pop, replace } = useFlow();

  const { groupId = '0' } = usePathParams();
  const { data: group } = useReadGroupDetail(groupId);
  const { refetch } = useGetSuperHostOnBoardingMission(groupId);

  const hasGroupProfile = !group?.profileImage.isDefault;
  const hasGroupBackgroundImage = !group?.backgroundImage.isDefault;

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate, isPending: isFetchLoading } = usePutGroup({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: ({ data }) => {
      queryClient.setQueryData([getGroupPath(String(data?.group?.id))], { data: data });
    },
  });

  const {
    profileImage,
    isLoading: isProfileLoading,
    handleSetGroupProfile,
    handleReset: handleResetProfile,
  } = useHandleSetGroupProfile({});
  const {
    backgroundImage,
    isLoading: isBackgroundLoading,
    handleSetGroupBackground,
    handleReset: handleResetBackground,
  } = useHandleSetGroupBackground({});

  const uncompletedQuestList = useCheckUnCompletedQuest();

  const handleSubmit = async () => {
    if (isProfileLoading || isBackgroundLoading) return;

    await mutate(
      {
        id: groupId,
        groupModifyForm: {
          backgroundImageId: backgroundImage?.id,
          profileImageId: profileImage?.id,
        },
      },
      {
        onSuccess: () => {
          refetch();
          if (uncompletedQuestList?.[0]) {
            replace(
              uncompletedQuestList[0].activityName,
              {
                groupId,
                calloutType: 'first-post-mission',
              },
              {
                animate: true,
              }
            );
            return;
          }
          pop();
        },
      }
    );
  };

  const isDisabledButton = () => {
    if (hasGroupProfile && hasGroupBackgroundImage) return false;

    const isGroupProfile = !hasGroupProfile && !profileImage;
    const isBackgroundImage = !hasGroupBackgroundImage && !backgroundImage;

    if (isGroupProfile && isBackgroundImage) return true;

    return false;
  };

  return (
    <AppScreen
      preventSwipeBack={true}
      accessoryBar={
        <BottomBasicButton
          disabled={isDisabledButton()}
          loading={isFetchLoading || isProfileLoading || isBackgroundLoading}
          onClick={handleSubmit}
        >
          완료
        </BottomBasicButton>
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <OnBoardSetProfileImage
          handleSetGroupProfile={handleSetGroupProfile}
          profileImage={profileImage}
          handleResetProfile={handleResetProfile}
          handleSetGroupBackground={handleSetGroupBackground}
          backgroundImage={backgroundImage}
          handleResetBackground={handleResetBackground}
          group={group}
        />
      </AsyncBoundary>
    </AppScreen>
  );
};

type ChildProps = {
  handleSetGroupProfile: () => Promise<void>;
  profileImage: UploadImageType | undefined;
  handleResetProfile: () => void;
  handleSetGroupBackground: () => Promise<void>;
  backgroundImage: UploadImageType | undefined;
  handleResetBackground: () => void;
  group: GroupDetailPresentation | undefined;
};

// 히어로 슬라이더 사용시 온보딩
const OnBoardSetProfileImage = ({
  handleSetGroupProfile,
  profileImage,
  handleResetProfile,
  group,
}: ChildProps) => {
  return (
    <div className={s.bodyWrapper}>
      <div className={s.wrapper} style={{ height: '100%', overflow: 'auto' }}>
        <Container>
          <Typography as="h1" typography="title1Bold">
            모임 사진을 등록해주세요
          </Typography>
          <Spacing size={24} />
          <Typography typography={'label3Bold'} color={'gray900'}>
            대표 사진
          </Typography>
          <Spacing size={4} />
          <Typography typography={'bodyM2Regular'} color={'gray600'}>
            모임을 대표할 수 있는 사진을 등록해주세요.
          </Typography>
          <Spacing size={12} />
          <div className={s.imageWrapper}>
            <div className={s.imageBox} onClick={handleSetGroupProfile}>
              <img className={s.cameraIconImage} src={CameraIcon} />
            </div>
            {profileImage?.medium && (
              <div className={s.imageBox}>
                <div className={s.removeButton} onClick={handleResetProfile}>
                  <IconXmarkFill size={12} color={vars.$scale.color.gray00} />
                </div>
                <img className="upload-image" src={profileImage.medium} />
              </div>
            )}
          </div>
          <Spacing size={32} />

          <CreateGroupProfileImagePreview
            name={group?.name}
            profileImage={profileImage}
            defaultImage={{
              profileImage: group?.profileImage.medium ?? '',
              backgroundImage: group?.backgroundImage.medium ?? '',
            }}
            categoryName={group?.category.name}
            regionName={group?.groupCreatedRegion.name}
          />
        </Container>
        <Spacing size={20} />
      </div>
    </div>
  );
};

export default withAsyncBoundary(GroupOnboardProfilePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
