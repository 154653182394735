import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
  GroupPermissionsResponsePermissions,
} from '@community-group/api/lib/group/models';
import { Divider, Render } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconChevronRightLine, IconPerson2OpenarmsFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useDefaultSettingHandler } from '@/features/GroupSetting/hooks/useDefaultSettingHandler';
import SettingItemTitle from '@/features/GroupSetting/SettingItemTitle';

type Props = {
  role: GroupMemberRoleEnum;
  groupId: string;
  groupDetail: GroupDetailPresentation;
  permissions: GroupPermissionsResponsePermissions;
};
const GroupDefaultSettingSection = ({ role, groupId, groupDetail, permissions }: Props) => {
  const { handleItemClick, defaultSetting } = useDefaultSettingHandler({
    role,
    groupId,
    groupDetail,
    permissions,
  });

  return (
    <Render condition={defaultSetting.length > 0}>
      <SettingList.Wrapper
        renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
      >
        <SettingItemTitle icon={<IconPerson2OpenarmsFill size={16} />} title="모임 설정" />
        {defaultSetting.map((item) => (
          <SettingList.Item
            key={item.displayText}
            title={item.displayText}
            onClick={() => handleItemClick(item)}
          />
        ))}
      </SettingList.Wrapper>
      <Divider size={8} />
    </Render>
  );
};

export default GroupDefaultSettingSection;
