import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import { Divider, Render } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconChevronRightLine, IconPersonPlusFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useJoinSettingHandler } from '@/features/GroupSetting/hooks/useJoinSettingHandler';
import SettingItemTitle from '@/features/GroupSetting/SettingItemTitle';

type Props = {
  role: GroupMemberRoleEnum;
  groupId: string;
  groupDetail: GroupDetailPresentation;
};

const GroupJoinSettingSection = ({ role, groupId, groupDetail }: Props) => {
  const { handleItemClick, joinSetting } = useJoinSettingHandler({ role, groupId, groupDetail });
  // 모임장만 보이도록 강한 제약 추가
  if (role !== 'superHost') return null;

  return (
    <Render condition={joinSetting.length > 0}>
      <SettingList.Wrapper
        renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
      >
        <SettingItemTitle icon={<IconPersonPlusFill size={16} />} title="가입 관리" />
        {joinSetting.map((item) => (
          <SettingList.Item
            key={item.displayText}
            title={item.displayText}
            className=""
            onClick={() => handleItemClick(item)}
          />
        ))}
      </SettingList.Wrapper>
      <Divider size={8} />
    </Render>
  );
};

export default GroupJoinSettingSection;
