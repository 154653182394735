import { ChipToggleButton, Render } from '@community-group/components';
import { useFormContext } from 'react-hook-form';

import { AgeRangeHandlerProps } from '@/components/group/new/RecruitmentCondition/ageRange';
import AgeRangeInputWrapper from '@/components/group/new/RecruitmentCondition/components/age-range-input-wrapper';
import { AGE_RANGE_OPTIONS } from '@/components/group/new/RecruitmentCondition/constants/age-range-constants';

import * as s from './style.css';

const GroupAgeRangeSettingSection = () => {
  const { register, watch, setValue } = useFormContext();

  const selectedChipId = watch('selectedChipId');

  const handleSelectChip = ({ id, value }: AgeRangeHandlerProps) => {
    setValue('ageRange', value);
    setValue('selectedChipId', id);
  };

  return (
    <>
      <div className={s.Wrapper}>
        {AGE_RANGE_OPTIONS.map((item) => (
          <ChipToggleButton
            key={item.id}
            onClick={() => handleSelectChip({ id: item.id, value: item.value })}
            onChange={() => register('ageRange').onChange}
            isSelected={item.id === selectedChipId}
          >
            {item.name}
          </ChipToggleButton>
        ))}
      </div>
      <Render condition={selectedChipId === 6}>
        <div className={s.InputWrapper}>
          <AgeRangeInputWrapper
            watch={watch}
            setValue={setValue}
            disable={selectedChipId !== AGE_RANGE_OPTIONS.length - 1}
          />
        </div>
      </Render>
    </>
  );
};

export default GroupAgeRangeSettingSection;
