import { AsyncBoundary, Callout, Typography } from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { Spacing, VerticalSpacing } from '@community-group/components';
import { IconHorizline2VerticalChatbubbleRectangularRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';

import { useReadGroupMemberGradeApplicationDecision } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeApplicationDecision';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';

import * as s from './MemberGradeApplicationRejectedCommentBottomSheet.css';

type Params = Pick<PageParams, 'groupId' | 'memberGradeApplicationId'>;

const MemberGradeApplicationRejectedCommentBottomSheet: ActivityComponentType<Params> = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <MemberGradeApplicationRejectedCommentBottomSheetCore />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const MemberGradeApplicationRejectedCommentBottomSheetCore = () => {
  const { pop } = useFlow();
  const { groupId, memberGradeApplicationId } = usePathParams();
  const {
    data: { message },
  } = useReadGroupMemberGradeApplicationDecision({
    groupId,
    applicationId: memberGradeApplicationId ?? '',
  });

  return (
    <div className={s.Wrapper}>
      <Spacing size={4} />
      <Typography as="h5" typography="title2Bold" color="gray900">
        아쉽지만 등업 요청이 거절되었어요
      </Typography>
      <Spacing size={6} />
      <Typography as="p" typography="subtitle1Regular" color="gray700">
        거절 의견을 확인해주세요.
      </Typography>
      <Spacing size={24} />
      <Callout variant="normal">
        <div className={s.CalloutContainer}>
          <IconHorizline2VerticalChatbubbleRectangularRightFill
            size={16}
            color={vars.$scale.color.gray900}
          />
          <VerticalSpacing size={6} />
          <Typography as="p" typography="bodyM1Regular" color="gray900">
            {message}
          </Typography>
        </div>
      </Callout>
      <Spacing size={28} />
      <div className={s.ButtonContainer}>
        <BoxButton
          variant="primary"
          width="100%"
          size="xlarge"
          onClick={() => {
            trackEvent({
              event: 'click_application_rejected_bottom_sheet_close',
              params: { groupId },
            });
            pop();
          }}
        >
          확인
        </BoxButton>
      </div>
    </div>
  );
};

export default MemberGradeApplicationRejectedCommentBottomSheet;
