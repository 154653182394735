import { groupClient } from '@community-group/api';
import { ApiV1SeoulRunningPromotionUserStateGetRequestParams } from '@community-group/api/lib/group/api/seoul-running-promotion-api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_SEOULRUN_PROMOTION_QUERY_KEY } from '../queries';

export const useQuerySeoulRunUserState = ({
  latitude,
  longitude,
}: ApiV1SeoulRunningPromotionUserStateGetRequestParams) => {
  const read = useAsyncRead(
    groupClient.api.SeoulRunningPromotionApi.apiV1SeoulRunningPromotionUserStateGet
  );
  return {
    queryKey: GROUP_SEOULRUN_PROMOTION_QUERY_KEY.isSeoulRunMeetupPromotion(latitude, longitude),
    queryFn: () => read({ latitude, longitude }),
  };
};

export const useReadSeoulRunUserState = ({
  latitude,
  longitude,
}: ApiV1SeoulRunningPromotionUserStateGetRequestParams) => {
  const query = useQuerySeoulRunUserState({
    latitude,
    longitude,
  });
  return useSuspenseQuery({ ...query });
};
