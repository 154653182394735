import { Divider } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconChevronRightLine, IconPersonFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { usePersonalSettingHandler } from '@/features/GroupSetting/hooks/usePersonalSettingHandler';
import SettingItemTitle from '@/features/GroupSetting/SettingItemTitle';

type Props = {
  groupId: string;
  userId: string;
};
const GroupPersonalSettingSection = ({ groupId, userId }: Props) => {
  const { handleItemClick, personalSetting } = usePersonalSettingHandler({ groupId, userId });
  return (
    <>
      <SettingList.Wrapper
        renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
      >
        <SettingItemTitle icon={<IconPersonFill size={16} />} title="사용자 설정" />
        {personalSetting.map((item) => (
          <SettingList.Item
            key={item.displayText}
            title={item.displayText}
            className="cursor-pointer"
            onClick={() => handleItemClick(item)}
          />
        ))}
      </SettingList.Wrapper>
      <Divider size={8} />
    </>
  );
};

export default GroupPersonalSettingSection;
