import { axiosClient } from '@community-group/api';

import { wrapSSRErrorHandler } from '@/api/instance/axios';

export const useWebServerFetchInstance = () => {
  const isProduction = globalThis.appConfig.appEnv !== 'alpha';

  const parsedHostName =
    typeof window !== 'undefined'
      ? `${window.location.protocol}//${window.location.host}`
      : globalThis.appConfig.host;

  return wrapSSRErrorHandler(
    axiosClient.initFetchInstance(
      {
        baseURL: isProduction ? globalThis.appConfig.host : parsedHostName,
      },
      {}
    )
  );
};
