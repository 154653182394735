import { BoxButton, Spacing, Typography } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';

import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { ActivityQueryParams } from '@/stackflow/hooks/useQueryParams';

import * as s from './KarrotPaymentsCreateAccountSuccessPage.css';
/**
 * 프로모션시 당근페이 가입 성공 페이지
 */
type Params = ActivityQueryParams;

const KarrotPaymentsCreateAccountSuccessPage: ActivityComponentType<Params> = () => {
  const { pop } = useFlow();

  return (
    <AppScreen
      appBar={{
        closeButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
        },
        backButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
        },
      }}
      accessoryBar={
        <div className={s.AccessoryBarWrapper}>
          <BoxButton
            variant="primary"
            width="100%"
            size="xlarge"
            onClick={() => {
              pop();
            }}
          >
            확인
          </BoxButton>
        </div>
      }
    >
      <div className={s.BottomSheetWrapper}>
        <Typography typography="title2Bold">당근페이 가입 완료</Typography>
        <Spacing size={6} />
        <Typography
          typography="bodyM1Regular"
          textAlign="center"
          style={{
            whiteSpace: 'pre-wrap !important',
          }}
          color="gray700"
        >
          당근페이 가입을 완료했어요.
        </Typography>
      </div>
    </AppScreen>
  );
};

export default KarrotPaymentsCreateAccountSuccessPage;
