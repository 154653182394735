import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { getXproperty, getXpropertyPath, XpropertyData } from '@/api/base/xproperty';
import { useXPropertyFetchInstance } from '@/api/hooks/instance/useXPropertyFetchInstance';

export const useQueryXproperty = () => {
  const xpropertyFetchInstance = useXPropertyFetchInstance();

  const getXpropertyFs = async () => {
    return getXproperty({
      xpropertyFetchInstance,
    });
  };

  return queryOptions({
    queryKey: [getXpropertyPath],
    queryFn: getXpropertyFs,
  });
};

export const useXproperty = (): XpropertyData => {
  const query = useQueryXproperty();
  const { data: xpropertys } = useSuspenseQuery(query);

  return xpropertys as XpropertyData;
};
