import { Render } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { SelectBox } from '@/components/common/SelectBox';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import useIsSeoulRunPromotionLocation from '@/features/SeoulRunPromotion/hooks/useIsSeoulRunPromotionLocation';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';

import * as s from './style.css';
type Props = Pick<PageParams, 'groupId' | 'regionId'>;

const CreateSeoulRunGroupMeetupSelectBottomSheet: ActivityComponentType<Props> = () => {
  const { groupId } = usePathParams();
  const { push, pop } = useFlow();

  const { data: group } = useReadGroupDetail(groupId);

  const isSeoulRunPromotionLocation = useIsSeoulRunPromotionLocation({
    regionId: group.groupCreatedRegion.id,
  });

  const handleCreateMeetup = (type: 'promotion' | 'normal') => {
    trackEvent({
      event: 'click_group_meetup_create',
      params: {
        groupId,
        groupName: group.name,
        categoryId: group.category.id,
        categoryName: group.category.name,
        name: 'meetup_section',
      },
    });
    pop();

    if (type === 'promotion') {
      push('SeoulRunPromotionGuidePage', {
        groupId,
      });

      return;
    }

    return push('GroupMeetupNewPage', { groupId });
  };

  return (
    <BottomSheet style={{ padding: 0 }}>
      <div className={s.Container}>
        <SelectBox
          label="일정 만들기"
          description="모임원들과 활동을 함께해보세요."
          onClick={() => handleCreateMeetup('normal')}
        />
        <Render condition={isSeoulRunPromotionLocation}>
          <SelectBox
            label="‘한강 보물찾기런’ 이벤트 참여하기 🏃‍♂️"
            description="6개의 한강공원에서 열리는 이벤트에 참여해보세요."
            onClick={() => handleCreateMeetup('promotion')}
          />
        </Render>
      </div>
    </BottomSheet>
  );
};

export default CreateSeoulRunGroupMeetupSelectBottomSheet;
