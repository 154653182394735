import {
  ActionableCallout,
  formatDate,
  getDateDiffFromNow,
  Typography,
} from '@community-group/components';
import { Spacing, VerticalSpacing } from '@community-group/components';
import {
  IconCalendarLine,
  IconChevronRightLine,
  IconPerson2Line,
  IconPerson2OpenarmsFill,
  IconPersonLine,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import { ProfileImage } from '@/components/common/Image/Profile';
import { getAgeDisplayText } from '@/components/common/TagLabel/AgeLabel';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import GroupSummaryDescription from './GroupSummaryDescription';
import * as s from './index.css';

type Params = {
  groupId: string;
  meetupId: string;
};

export const GroupSummarySection = ({ groupId, meetupId }: Params) => {
  const { data: group } = useReadGroupDetail(groupId);

  const { push } = useFlow();
  const handleMoreButtonClick = () => {
    trackEvent({
      event: 'click_group_meetup_group_information',
      params: {
        groupId,
        meetupId,
      },
      sample: true,
    });

    push('GroupDetailPage', { groupId });
  };
  const handleMoreOtherGroupButtonClick = () => {
    push('HomePage', {});
  };

  const activityLogText = useMemo(() => {
    if (!group?.lastActivatedAt) return null;

    const activityDate = new Date(Date.parse(group.lastActivatedAt));
    const dateDiff = getDateDiffFromNow(activityDate);

    // 10일
    const DAY10 = 1000 * 60 * 60 * 24 * 10;
    if (dateDiff.diff / DAY10 > 1) return null;

    return `${dateDiff.text} 전 활동`;
  }, [group?.lastActivatedAt]);

  const createdAtDisplayText = useMemo(() => {
    if (!group?.createdAt) return null;

    const createdAtDate = new Date(group.createdAt);
    const formatDateText = formatDate(createdAtDate, 'yyyy년 M월 dd일');

    return `${formatDateText} 개설`;
  }, [group?.createdAt]);

  const ageRangeDisplayText = useMemo(() => {
    if (!group?.ageRangeTag) return null;

    return getAgeDisplayText({
      min: group?.ageRangeTag.minAge,
      max: group?.ageRangeTag.maxAge,
    });
  }, [group?.ageRangeTag]);

  const verifyAgeRangeDisplayText = useMemo(() => {
    const text: string[] = [];
    if (group?.needVerification) text.push('본인인증 필요');
    if (group?.canViewVerifiedUserInfo) text.push('나이대 공개');

    if (text.length === 0) return null;
    return text.join(', ');
  }, [group?.canViewVerifiedUserInfo, group?.needVerification]);

  return (
    <section className={s.Section}>
      <div onClick={handleMoreButtonClick}>
        <div className={s.Header}>
          <Typography as="h3" typography="title3Bold" color="gray900">
            모임 정보
          </Typography>
          <button className={s.MoreButton}>
            <IconChevronRightLine size={20} fill={vars.$scale.color.gray900} />
          </button>
        </div>
        <Spacing size={24} />
        <div className={s.Summary}>
          <ProfileImage src={group?.profileImage.small ?? ''} size={48} />
          <VerticalSpacing size={16} />
          <div className={s.SummaryContents}>
            <Typography typography="subtitle1Bold" ellipsisAfterLines={1}>
              {group?.name}
            </Typography>
            <Spacing size={4} />
            <Typography typography="caption1Regular" color="gray600">
              {group?.groupCreatedRegion.town ?? ''}
              {(group?.memberCount ?? 0) > 0 && ` · 멤버 ${group?.memberCount}`}
              {activityLogText && (
                <>
                  {' · '}
                  <Typography typography="caption1Regular" color="carrot600">
                    {activityLogText}
                  </Typography>
                </>
              )}
            </Typography>
          </div>
        </div>
        {group?.createdAt && (
          <>
            <Spacing size={16} />
            <ul className={s.GroupInfoList}>
              <li className={s.GroupInfoItem}>
                <IconCalendarLine width={16} height={16} color={vars.$scale.color.gray900} />
                <VerticalSpacing size={8} />
                <Typography typography="label2Regular" color="gray800" ellipsisAfterLines={1}>
                  {createdAtDisplayText}
                </Typography>
              </li>
              {ageRangeDisplayText && (
                <>
                  <Spacing size={8} />
                  <li className={s.GroupInfoItem}>
                    <IconPersonLine width={16} height={16} color={vars.$scale.color.gray900} />
                    <VerticalSpacing size={8} />
                    <Typography typography="label2Regular" color="gray800" ellipsisAfterLines={1}>
                      {ageRangeDisplayText} 모집
                    </Typography>
                  </li>
                </>
              )}
              {verifyAgeRangeDisplayText && (
                <>
                  <Spacing size={8} />
                  <li className={s.GroupInfoItem}>
                    <IconPerson2Line width={16} height={16} color={vars.$scale.color.gray900} />
                    <VerticalSpacing size={8} />
                    <Typography typography="label2Regular" color="gray800" ellipsisAfterLines={1}>
                      {verifyAgeRangeDisplayText}
                    </Typography>
                  </li>
                </>
              )}
            </ul>
          </>
        )}
      </div>
      {group?.description && (
        <>
          <Spacing size={16} />
          <GroupSummaryDescription description={group.description} />
        </>
      )}
      <Spacing size={16} />
      <ActionableCallout width="100%" height="47px" onClick={handleMoreOtherGroupButtonClick}>
        <div className={s.CalloutContent}>
          <IconPerson2OpenarmsFill size={18} color={vars.$scale.color.gray600} />
          <VerticalSpacing size={8} />
          <Typography typography="bodyM2Regular">다른 모임 더보기</Typography>
        </div>
      </ActionableCallout>
    </section>
  );
};
