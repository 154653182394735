import { GROUP_CHALLENGE_QUERY_KEY } from '@/domain/Challenge/queries';
import { queryClient } from '@/shared/api/instance';

type Params = {
  groupId?: string;
  challengeId?: string;
};

/*
 *  모임 상세 페이지에서 사용하는 쿼리를 한번에 리페치하는 함수
 */
export const refetchGroupChallenge = ({ groupId, challengeId }: Params) => {
  if (!groupId) return;

  // 모임 상세 - 챌린지 상태 배너
  queryClient.refetchQueries({
    queryKey: GROUP_CHALLENGE_QUERY_KEY.banner(groupId),
  });

  // 챌린지 목록 - 각 상태 별 데이터
  queryClient.refetchQueries({
    queryKey: GROUP_CHALLENGE_QUERY_KEY.groupChallengeList(groupId, 'prepared'),
  });
  queryClient.refetchQueries({
    queryKey: GROUP_CHALLENGE_QUERY_KEY.groupChallengeList(groupId, 'opened'),
  });
  queryClient.refetchQueries({
    queryKey: GROUP_CHALLENGE_QUERY_KEY.groupChallengeList(groupId, 'closed'),
  });

  if (challengeId) {
    // 챌린지 상세 - 알림 설정 여부
    queryClient.refetchQueries({
      queryKey: GROUP_CHALLENGE_QUERY_KEY.notificationSetting(groupId, challengeId),
    });

    // 챌린지 상세 - 참여자 랭킹
    queryClient.refetchQueries({
      queryKey: GROUP_CHALLENGE_QUERY_KEY.participantsRank(groupId, challengeId),
    });

    // 챌린지 상세 - 나의 현황 섹션
    queryClient.refetchQueries({
      queryKey: GROUP_CHALLENGE_QUERY_KEY.myCertifyActivity(groupId, challengeId),
    });

    // 챌린지 상세
    queryClient.refetchQueries({
      queryKey: GROUP_CHALLENGE_QUERY_KEY.detail(groupId.toString(), challengeId.toString()),
    });

    // 챌린지 상세 - 나의 챌린지 캘린더
    queryClient.refetchQueries({
      queryKey: GROUP_CHALLENGE_QUERY_KEY.myChallengeCalendar(groupId, challengeId),
    });

    // 챌린지 상세 - 인증 모아보기 피드
    queryClient.refetchQueries({
      queryKey: GROUP_CHALLENGE_QUERY_KEY.feeds(groupId, challengeId),
    });
  }
};
