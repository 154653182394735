import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useRead } from '@/shared/api/hooks/useRead';
import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

type Request = { id: number; challengeId: number; form: model.ChallengeNotificationSettingRequest };

type Props = UseMutationOptions<AxiosResponse, Error, Request>;
const usePatchChallengeNotificationSetting = ({ onError, onSuccess }: Props) => {
  const patchNotificationSetting = useRead(
    groupClient.api.GroupChallengeMemberApi
      .apiV1GroupsGroupIdChallengesChallengeIdNotificationSettingsMePatch
  );

  const patchNotificationSettingWrapperFp = (req: Request) => {
    return patchNotificationSetting({
      groupId: req.id,
      challengeId: req.challengeId,
      challengeNotificationSettingRequest: req.form,
    });
  };

  return useThrottleMutation(patchNotificationSettingWrapperFp, {
    onError,
    onSuccess,
  });
};

export default usePatchChallengeNotificationSetting;
