import { AsyncBoundary, ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import GroupRegularMeetupPlaceAccessoryBar from '@/features/GroupSetting/AppScreen/RegularMeetupPlaceAccessoryBar';
import GroupRegularMeetupPlaceMap from '@/features/GroupSetting/RegularMeetupPlaceMap';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupRegularMeetupPlaceSettingPageParams = Pick<PageParams, 'groupId'>;

const GroupRegularMeetupPlaceSettingPage: ActivityComponentType<
  GroupRegularMeetupPlaceSettingPageParams
> = () => {
  const { groupId } = usePathParams();

  return (
    <AppScreen
      appBar={{ title: '자주 모이는 장소 설정' }}
      accessoryBar={<GroupRegularMeetupPlaceAccessoryBar groupId={groupId} />}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <GroupRegularMeetupPlaceMap groupId={groupId} />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupRegularMeetupPlaceSettingPage;
