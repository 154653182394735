import { GroupLevelPresentation } from '@community-group/api/lib/group/models';
import {
  AsyncBoundary,
  BoxButton,
  Typography,
  VerticalSpacing,
  ViewLoader,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import { useUserConfig } from '@/contexts/UserConfig';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';

import { getLevel3DBadgeImage } from '../../GroupLevel/Components/CurrentLevelAndMission/CurrentLevelBadge';
import * as s from './LevelUpAlertBottomSheet.css';

export type LevelUpAlertBottomSheetParams = Pick<PageParams, 'groupId' | 'level'>;

const LevelUpAlertBottomSheet: ActivityComponentType<LevelUpAlertBottomSheetParams> = () => {
  const { pop } = useFlow();
  const { level } = useQueryParams();
  const { groupId } = usePathParams();
  const { data: levelData } = useGetGroupLevel({ groupId: Number(groupId) });
  const { data: group } = useReadGroupDetail(groupId);

  const {
    userConfig: { userId },
  } = useUserConfig();

  useEnterTrackEvent({
    event: 'enter_level_up_alert_bottom_sheet',
    params: {
      currentLevel: levelData?.currentLevel,
      currentProgressPercentage: levelData?.forLevelUp.percentage,
      groupId,
      userId: (userId ?? 0).toString(),
      categoryId: group?.category.id,
      categoryName: group?.category.name,
    },
  });

  const [shownGroupLevelUpBottomSheetLevel, setShownGroupLevelUpBottomSheetLevel] = useStorage(
    'shownGroupLevelUpBottomSheetLevel',
    {}
  );

  const handleCloseBottomSheet = () => {
    if (level) {
      setShownGroupLevelUpBottomSheetLevel({
        ...shownGroupLevelUpBottomSheetLevel,
        ...{
          [groupId]: Number(level),
        },
      });
    }

    pop();
  };

  return (
    <BottomSheet
      onOutsideClick={() => {
        setShownGroupLevelUpBottomSheetLevel({
          ...shownGroupLevelUpBottomSheetLevel,
          ...{
            [groupId]: Number(level),
          },
        });
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <LevelUpAlertBottomSheetWrapper
          onClose={handleCloseBottomSheet}
          level={level}
          levelData={levelData}
        />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const LevelUpAlertBottomSheetWrapper = ({
  level,
  levelData,
  onClose: handleCloseBottomSheet,
}: {
  level?: string;
  levelData?: GroupLevelPresentation;
  onClose: () => void;
}) => {
  const { groupId } = usePathParams();
  const { data: group } = useReadGroupDetail(groupId);
  const { push } = useFlow();
  const {
    userConfig: { userId },
  } = useUserConfig();

  const handleMoveToCheckGroupLevel = () => {
    trackEvent({
      event: 'click_go_to_group_level',
      params: {
        groupId,
        from: 'LevelUpAlertBottomSheet',
        currentLevel: levelData?.currentLevel,
        currentProgressPercentage: levelData?.forLevelUp.percentage,
        userId: (userId ?? 0).toString(),
        categoryId: group?.category.id,
        categoryName: group?.category.name,
      },
    });
    handleCloseBottomSheet();
    setTimeout(() => {
      push('GroupLevelDetailPage', {
        groupId,
      });
    }, 300);
  };

  const LEVEL_3D_GROUP_IMG = getLevel3DBadgeImage(Number(level), 'front');
  const isMaxLevel = Number(level) === 5;

  return (
    <div className={s.Wrapper}>
      <Typography textAlign="center" as="h3" color="gray900" typography="title2Bold">
        Lv.{level} 모임이 되었어요! 🎉
      </Typography>
      <Spacing size={6} />
      <Typography textAlign="center" color="gray700" typography="subtitle1Regular" as="p">
        레벨 혜택을 확인하고
        <br />
        {isMaxLevel ? '미션을 계속 진행해보세요.' : '다음 레벨도 도전해보세요.'}
      </Typography>
      <Spacing size={16} />
      <img
        style={{ width: '12.0625rem', height: '10.1875rem' }}
        src={LEVEL_3D_GROUP_IMG + `?groupId=${groupId}`} // apng replay를 위한 query string 추가
      />
      <Spacing size={12} />
      <div className={s.BoxButtonWrapper}>
        <BoxButton size="xlarge" width="100%" onClick={handleCloseBottomSheet} variant="secondary">
          닫기
        </BoxButton>
        <VerticalSpacing size={8} />
        <BoxButton size="xlarge" width="100%" onClick={handleMoveToCheckGroupLevel}>
          자세히 보기
        </BoxButton>
      </div>
      <Spacing size={8} />
    </div>
  );
};

export default LevelUpAlertBottomSheet;
