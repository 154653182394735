import { BoxButton } from '@community-group/components';

import { useHandleGroupRegularMeetupPlace } from '@/domain/Group/hooks/useHandleGroupRegularMeetupPlace';
import * as stackflow from '@/stackflow';

import * as s from './style.css';

type Props = {
  groupId: string;
};

const GroupRegularMeetupPlaceAccessoryBar = ({ groupId }: Props) => {
  const { handleDeletePoiButtonClick, handleUpdatePoiButtonClick } =
    useHandleGroupRegularMeetupPlace({ groupId });
  const stack = stackflow?.useFlow();
  const pop = stack?.pop;

  return (
    <div className={s.Wrapper}>
      <BoxButton
        variant="secondary"
        size="xlarge"
        width="100%"
        onClick={() => handleDeletePoiButtonClick({ onSuccess: pop })}
      >
        삭제
      </BoxButton>
      <BoxButton
        variant="primaryLow"
        size="xlarge"
        width="100%"
        onClick={handleUpdatePoiButtonClick}
      >
        수정
      </BoxButton>
    </div>
  );
};

export default GroupRegularMeetupPlaceAccessoryBar;
