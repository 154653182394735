import { ReactNode, useMemo } from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import * as s from './index.css';

// 모임 홈 상단 공지
export const BannerSection = () => {
  const bannerList = useMemo(() => {
    const bannerList: ReactNode[] = [];

    // bannerList.push(<NoticeBanner />);
    return bannerList;
  }, []);

  const hasOnlyOne = useMemo(() => bannerList.length <= 1, [bannerList]);

  if (bannerList.length === 0) return null;

  return (
    <div className={s.SwiperOuter}>
      <Swiper
        pagination={{ dynamicBullets: false }}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className={s.SwiperWrapper({ hasOnlyOne })}
      >
        {bannerList.map((banner, index) => (
          <SwiperSlide className={s.ItemWrapper} key={'banner-' + index}>
            {banner}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
