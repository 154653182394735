import {
  GroupMemberActivityEnum,
  GroupMemberActivityPresentation,
  GroupMemberGradeRequirementPresentation,
  GroupMemberGradeRequirementUpdateForm,
} from '@community-group/api/lib/group/models';
import {
  IconCalendarFill,
  IconEyeFill,
  IconHorizline2VerticalChatbubbleRectangularRightFill,
  IconPenHorizlineFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ReactNode } from 'react';

type Requirement = GroupMemberGradeRequirementUpdateForm | GroupMemberGradeRequirementPresentation;

type RequirementMap = {
  [key in GroupMemberActivityEnum]: {
    label: string;
    order: number;
  };
};

type ActivityCountKey = keyof GroupMemberActivityPresentation;

type RequirementActivityMap = {
  [key in GroupMemberActivityEnum]: {
    key: ActivityCountKey;
    label: string;
    icon: ReactNode;
    count: number;
  };
};

const requirementMap: RequirementMap = {
  visit_group: {
    label: '모임 방문',
    order: 1,
  },
  create_post: {
    label: '게시글 작성',
    order: 2,
  },
  create_comment: {
    label: '댓글 작성',
    order: 3,
  },
  join_meetup: {
    label: '일정 참여',
    order: 4,
  },
};

export const mapMemberGradeRequirements = (requirements: Requirement[]) => {
  return requirements
    .map((requirement) => ({
      ...requirement,
      ...requirementMap[requirement.activity],
    }))
    .sort((a, b) => a.order - b.order);
};

const requirementActivityMap: RequirementActivityMap = {
  visit_group: {
    key: 'groupVisitCount',
    label: '모임 방문',
    icon: <IconEyeFill size={24} color={vars.$scale.color.gray700} />,
    count: 0,
  },
  create_post: {
    key: 'createdArticleCount',
    label: '게시글',
    icon: <IconPenHorizlineFill size={24} color={vars.$scale.color.gray700} />,
    count: 0,
  },
  create_comment: {
    key: 'createdCommentCount',
    label: '댓글',
    icon: (
      <IconHorizline2VerticalChatbubbleRectangularRightFill
        size={24}
        color={vars.$scale.color.gray700}
      />
    ),
    count: 0,
  },
  join_meetup: {
    key: 'joinedMeetupCount',
    label: '일정 참여',
    icon: <IconCalendarFill size={24} color={vars.$scale.color.gray700} />,
    count: 0,
  },
};

export const mapMemberGradeRequirementActivities = (
  requirements: Requirement[],
  activityCount: Omit<GroupMemberActivityPresentation, 'lastUpdatedAt'>
) => {
  return mapMemberGradeRequirements(requirements)
    .map((requirement) => ({
      ...requirement,
      ...requirementActivityMap[requirement.activity],
    }))
    .map((requirement) => ({
      ...requirement,
      count: activityCount[requirement.key],
    }))
    .filter((activity) => activity.goalCount > 0);
};

export const getMemberGradeRequirementsText = (requirements: Requirement[]) => {
  return mapMemberGradeRequirements(requirements)
    .filter((requirement) => requirement.goalCount > 0)
    .map((requirement) => `${requirement.label} <b>${requirement.goalCount}회</b>`)
    .join(', ');
};

export const convertMemberGradeRequirementsToAnalyticsParams = (requirements: Requirement[]) => {
  return requirements.reduce(
    (acc, requirement) => {
      acc[requirement.activity] = requirement.goalCount;
      return acc;
    },
    {
      visit_group: 0,
      create_post: 0,
      create_comment: 0,
      join_meetup: 0,
    }
  );
};
