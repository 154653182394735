import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { Divider, Render } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconChevronRightLine, IconPerson2Fill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useMemberSettingHandler } from '@/features/GroupSetting/hooks/useMemberSettingHandler';
import SettingItemTitle from '@/features/GroupSetting/SettingItemTitle';

type Props = {
  role: GroupMemberRoleEnum;
  groupId: string;
};
const GroupMemberSettingSection = ({ role, groupId }: Props) => {
  const { handleItemClick, memberSetting } = useMemberSettingHandler({ role, groupId });

  return (
    <Render condition={memberSetting.length > 0}>
      <SettingList.Wrapper
        renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
      >
        <SettingItemTitle icon={<IconPerson2Fill size={16} />} title="멤버 관리" />
        {memberSetting.map((item) => (
          <SettingList.Item
            key={item.displayText}
            title={item.displayText}
            onClick={() => handleItemClick(item)}
          />
        ))}
      </SettingList.Wrapper>
      <Divider size={8} />
    </Render>
  );
};

export default GroupMemberSettingSection;
