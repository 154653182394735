import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_CHALLENGE_QUERY_KEY } from '../queries';

type Props = {
  groupId: number;
  challengeId: number;
};

export const useQueryMyChallengeCalendar = ({ groupId, challengeId }: Props) => {
  const read = useAsyncRead(
    groupClient.api.GroupChallengeMemberApi.apiV1GroupsGroupIdChallengesChallengeIdCalendarGet
  );
  return {
    queryKey: GROUP_CHALLENGE_QUERY_KEY.myChallengeCalendar(
      groupId.toString(),
      challengeId.toString()
    ),
    queryFn: () => read({ groupId, challengeId }),
  };
};

export const useReadMyChallengeCalendar = ({ groupId, challengeId }: Props) => {
  const query = useQueryMyChallengeCalendar({ groupId, challengeId });
  return useSuspenseQuery({ ...query });
};
