import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import useValidAgeRangeInput from '@/components/group/new/RecruitmentCondition/hooks/use-validate-age-range-input';
import { useBridge } from '@/contexts/Bridge';
import { usePutGroup } from '@/domain/Group/hooks/usePutGroup';
import * as stackflow from '@/stackflow';
import { AppBarTextSubmitButton } from '@/stackflow/components/AppBarTextSubmitButton';

type Props = {
  groupId: string;
  initialSelectedChipId?: number;
  initialAgeRangeValue: {
    minAge: number | undefined;
    maxAge: number | undefined;
  };
};

const GroupAgeRangeSettingRenderRight = ({
  groupId,
  initialSelectedChipId,
  initialAgeRangeValue,
}: Props) => {
  const { bridge } = useBridge();
  const stack = stackflow?.useFlow();
  const { mutate: putGroup, isPending } = usePutGroup({
    onSuccess: () => {
      bridge.openToast({ toast: { body: '모집 연령대를 수정했어요.' } });
      stack?.pop();
    },
  });

  const {
    watch,
    formState: { isDirty },
  } = useFormContext();

  const { validateMinValue, validateMaxValue } = useValidAgeRangeInput({ watch });

  const ageRangeFieldValue = watch('ageRange');
  const selectedChipId = watch('selectedChipId');

  const isButtonDisabled = useMemo(() => {
    const ALL_AGES_CHIP_ID = 0;

    if (selectedChipId !== ALL_AGES_CHIP_ID) {
      if (!validateMinValue().isValid) return true;
      if (!validateMaxValue().isValid) return true;
    }

    if (selectedChipId === initialSelectedChipId) {
      if (selectedChipId === ALL_AGES_CHIP_ID) return true;

      if (
        ageRangeFieldValue.minAge === initialAgeRangeValue.minAge &&
        ageRangeFieldValue.maxAge === initialAgeRangeValue.maxAge
      )
        return true;
    }

    return false;
  }, [initialSelectedChipId, isDirty, selectedChipId, validateMaxValue, validateMinValue]);

  const handleSubmit = () => {
    putGroup({
      id: Number(groupId),
      groupModifyForm: {
        groupTag: {
          tags:
            ageRangeFieldValue.minAge && ageRangeFieldValue.maxAge
              ? [
                  {
                    type: 'AGE',
                    min: ageRangeFieldValue.minAge,
                    max: ageRangeFieldValue.maxAge,
                  },
                ]
              : [],
        },
      },
    });
  };

  return (
    <AppBarTextSubmitButton
      disabled={isButtonDisabled}
      onClick={handleSubmit}
      loading={isPending}
    />
  );
};

export default GroupAgeRangeSettingRenderRight;
