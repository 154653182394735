import { GroupMeetupReviewCreateRequest } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useMembersForMentions } from '@/hooks/useMembersForMentions';

import { ReviewPostFields } from '../common/ReviewPostFields';
import { ReviewRatingFields } from '../common/ReviewRatingFields';
import * as s from './style.css';

type Props = {
  groupId: string;
  meetupId: string;
  handleMediaUploading: (isUploading: boolean) => void;
};

export const MeetupReviewForm = ({ groupId, meetupId, handleMediaUploading }: Props) => {
  const { data: currentUserData } = useReadGroupMe({ groupId });
  const formHandler = useFormContext<GroupMeetupReviewCreateRequest>();
  const { control } = formHandler;
  const mentionMemberList = useMembersForMentions(groupId);

  const userName = useMemo(() => {
    if (!currentUserData.nickname) return '';
    if (!currentUserData.subNickname) return currentUserData.nickname;

    return `${currentUserData.nickname}(${currentUserData.subNickname})`;
  }, [currentUserData.nickname, currentUserData.subNickname]);

  return (
    <section className={s.Wrapper}>
      <Typography typography="title2Bold" wordBreak="break-word">
        {userName}님,
        <br />
        모임 활동은 어떠셨나요?
      </Typography>
      <Controller
        control={control}
        name="reviewRating"
        render={({ field: { onChange, value } }) => {
          return <ReviewRatingFields value={value} onChange={onChange} />;
        }}
      />
      <ReviewPostFields
        mentionMemberList={mentionMemberList}
        groupId={groupId}
        meetupId={meetupId}
        handleMediaUploading={handleMediaUploading}
      />
    </section>
  );
};
