import { GroupCurrentUser, GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { Dialog, GroupAvatar, isNotMember, useDialog } from '@community-group/components';

import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
type Props = {
  groupId: string;
  userId: string;
  profileImage: string;
  role: GroupMemberRoleEnum;
  currentUser: GroupCurrentUser;
};

const ProfileImageWithHandler = ({ groupId, userId, profileImage, role, currentUser }: Props) => {
  const { open: openDialog, close: closeDialog } = useDialog();
  const { push } = useFlow();
  const { handleGroupOnly } = useHandleGroupOnly({
    groupId,
  });

  const handleImageClick = () => {
    const isBlock = isNotMember(currentUser.role);
    trackEvent({
      event: 'click_user_profile_image',
      params: { groupId: groupId, isJoined: !isBlock, userState: currentUser.state },
      sample: true,
    });
    if (currentUser?.state === 'applied') {
      openDialog({
        element: (
          <Dialog
            title="모임 가입 안내"
            description="가입 승인 후 프로필을 볼 수 있어요. 승인 후 다시 확인해주세요."
            secondaryActionIntent="nonpreferred"
            primaryActionLabel="확인"
            onPrimaryAction={async () => await closeDialog()}
          />
        ),
        onOutsideClick: closeDialog,
      });
      return;
    }

    handleGroupOnly({
      isBlock,
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getMemberProfileImage,
      onSettled: () => {
        push('ImageViewerPage', {
          content: 'profile',
          groupId,
          userId,
          initialIndex: '0',
          screenName: 'groupMemberProfileImageViewer',
        });
      },
      onSuccess: ({ joinApprovalRequireReason }) => {
        //INFO: 가입 조건이 별도로 없는 경우에만 가입 완료 이후 프로필 이미지 확인 가능
        if (joinApprovalRequireReason !== 'none') return;
        push('ImageViewerPage', {
          content: 'profile',
          groupId,
          userId,
          initialIndex: '0',
          screenName: 'groupMemberProfileImageViewer',
        });
      },
    });
  };

  return (
    <div onClick={handleImageClick}>
      <GroupAvatar size="large" src={profileImage} role={role} />
    </div>
  );
};

export default ProfileImageWithHandler;
