import {
  GroupMeetupCreateForm,
  GroupMeetupModifyForm,
} from '@community-group/api/lib/group/models';
import { initMeetupTimeAt } from '@community-group/components';
import { isAfter } from 'date-fns';
import { z } from 'zod';

import { KARROT_DATE_FORMAT_REGEX } from '@/utils/date';

const title = z
  .string()
  .trim()
  .min(1, '제목은 1글자 이상이어야 해요.')
  .max(100, '제목은 100글자 이하이어야 해요.');

const description = z.string().optional();
const images = z.array(z.string()).max(10, '이미지는 10개까지만 올릴 수 있어요.');
const creationCycle = z.enum(['none', 'weekly', 'biWeekly', 'monthlyByDay', 'monthlyByWeekday']);
const poi = z.number().int().positive().optional();
const poiItems = z
  .array(
    z.object({
      id: z.number().int().positive(),
      type: z.enum(['POI', 'BUSINESS_ACCOUNT', 'LOCAL_PROFILE']),
    })
  )
  .optional();
const meetupTimeAt = z
  .string()
  .refine((value) => KARROT_DATE_FORMAT_REGEX.test(value), '날짜 형식이 올바르지 않아요.');
const maximumParticipantsNumber = z.number().int().positive().min(2, '최소 2명 이상이어야 해요.');
const hasMeetupTimeAtOnlyDate = z.boolean();
const exposureRange = z.enum(['ALL', 'GROUP_MEMBER']);
const isPublished = z.boolean();
const withCreateChatRoom = z.boolean().default(true);
const canJoinAnyMember = z.boolean();
export const meetupFieldSchema = {
  title,
  images,
  description,
  creationCycle,
  meetupTimeAt,
  maximumParticipantsNumber,
  hasMeetupTimeAtOnlyDate,
  exposureRange,
  poi,
  isPublished,
  withCreateChatRoom,
  canJoinAnyMember,
};

export const meetupCreateFormSchema: z.ZodType<GroupMeetupCreateForm> = z
  .object({
    title,
    images,
    creationCycle,
    description,
    meetupTimeAt,
    maximumParticipantsNumber,
    hasMeetupTimeAtOnlyDate,
    exposureRange,
    poi,
    poiItems,
    isPublished,
    withCreateChatRoom,
    canJoinAnyMember,
  })
  .refine(
    ({ meetupTimeAt, hasMeetupTimeAtOnlyDate }) =>
      isAfter(new Date(meetupTimeAt), new Date()) || hasMeetupTimeAtOnlyDate,
    '모임 시간과 날짜는 미래만 설정할 수 있어요.'
  );

export const initGroupMeetupCreateFormData: GroupMeetupCreateForm = {
  title: '',
  description: '',
  creationCycle: 'none',
  maximumParticipantsNumber: 4,
  hasMeetupTimeAtOnlyDate: false,
  meetupTimeAt: initMeetupTimeAt(), // 초기 날짜는 현재 시간 기준 1시간 후 정각으로 한다.
  exposureRange: 'ALL',
  poi: undefined,
  poiItems: [],
  images: [],
  isPublished: false,
  canJoinAnyMember: true,
};

export const meetupModifyFormSchema: z.ZodType<GroupMeetupModifyForm> = z
  .object({
    title,
    images,
    description,
    meetupTimeAt,
    maximumParticipantsNumber,
    hasMeetupTimeAtOnlyDate,
    exposureRange,
    poiItems,
    canJoinAnyMember,
  })
  .refine(
    ({ meetupTimeAt, hasMeetupTimeAtOnlyDate }) =>
      isAfter(new Date(meetupTimeAt), new Date()) || hasMeetupTimeAtOnlyDate,
    '모임 시간과 날짜는 미래만 설정할 수 있어요.'
  );
