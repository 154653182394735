import { createContext, ReactNode, useContext } from 'react';
import { useInView } from 'react-intersection-observer';

type ScrollContextType = {
  isHeaderVisible: boolean;
};

const ScrollContext = createContext<ScrollContextType | null>(null);

export const GroupDetailScrollProvider = ({ children }: { children: ReactNode }) => {
  const { ref, inView } = useInView({
    rootMargin: '0px',
    threshold: 0,
    initialInView: true,
  });

  return (
    <ScrollContext.Provider value={{ isHeaderVisible: inView }}>
      <div ref={ref} className="scroll-observer" />
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};
