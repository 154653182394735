import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { useCallback, useMemo } from 'react';
import { match } from 'ts-pattern';

import { XpropertyData } from '@/api/base/xproperty';
import { groupCustomerServiceSettingConfig } from '@/features/GroupSetting/util/groupSettingConfig';
import { openGroupCustomerService, openGroupPolicyFAQ } from '@/utils/link';
import { openLink } from '@/utils/link';

type Props = {
  role: GroupMemberRoleEnum;
  xProperty: XpropertyData;
};
export const useCustomerServiceSettingHandler = ({ role, xProperty }: Props) => {
  const surveyId = useMemo(() => {
    if (role === 'superHost') return 'groupSettingSettingListForSuperHost';
    return 'groupSettingSettingList';
  }, [role]);

  const surveyInfo = useMemo(
    () => xProperty.communityGroupSurveyBanners?.[surveyId],
    [xProperty.communityGroupSurveyBanners, surveyId]
  );

  const openGroupSurvey = useCallback(() => {
    if (!surveyInfo) return;
    openLink(surveyInfo.targetUrl);
  }, [surveyInfo]);

  const handleItemClick = useCallback(
    (item: (typeof groupCustomerServiceSettingConfig)[number]) => {
      match(item)
        .with({ displayText: '고객센터' }, () => openGroupCustomerService())
        .with({ displayText: '모임 서비스 의견 남기기' }, () => openGroupSurvey())
        .with({ displayText: '당근 모임 운영정책' }, () => openGroupPolicyFAQ())
        .exhaustive();
    },
    [openGroupSurvey]
  );
  return handleItemClick;
};
