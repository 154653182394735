import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';

type SettingItemCategory = '사용자 설정' | '모임 설정' | '멤버 관리' | '가입 관리' | '고객센터';
type SettingDisplayText =
  | '모임 알림 설정'
  | '내 모임 프로필'
  | '내 아이 정보'
  | '게시판 관리'
  | '모임장봇 설정'
  | '자주 모이는 장소 설정'
  | '운영 가이드북'
  | '전체 멤버 목록'
  | '멤버 권한 관리'
  | '멤버 등급 관리'
  | '운영진 설정'
  | '본인인증 설정'
  | '모임 별명 설정'
  | '가입 방식 관리'
  | '모집 동네 범위 설정'
  | '최대 가입 인원 설정'
  | '모집 연령대 설정'
  | '고객센터'
  | '모임 서비스 의견 남기기'
  | '당근 모임 운영정책';

export type GroupSettingItem = {
  displayText: SettingDisplayText;
  category: SettingItemCategory;
  roles: GroupMemberRoleEnum[];
};

export const groupPersonalSettingConfig = [
  {
    displayText: '모임 알림 설정',
    category: '사용자 설정',
    roles: ['superHost', 'manager', 'member'],
  },
  {
    displayText: '내 모임 프로필',
    category: '사용자 설정',
    roles: ['superHost', 'manager', 'member'],
  },
  {
    displayText: '내 아이 정보',
    category: '사용자 설정',
    roles: ['superHost', 'manager', 'member'],
  },
] satisfies GroupSettingItem[];

export const groupDefaultSettingConfig = [
  {
    displayText: '게시판 관리',
    category: '모임 설정',
    roles: ['superHost'],
  },
  {
    displayText: '모임장봇 설정',
    category: '모임 설정',
    roles: ['superHost', 'manager'],
  },
  {
    displayText: '자주 모이는 장소 설정',
    category: '모임 설정',
    roles: ['superHost'],
  },
  {
    displayText: '운영 가이드북',
    category: '모임 설정',
    roles: ['superHost', 'manager'],
  },
] satisfies GroupSettingItem[];

export const groupMemberSettingConfig = [
  {
    displayText: '전체 멤버 목록',
    category: '멤버 관리',
    roles: ['superHost', 'manager'],
  },
  {
    displayText: '멤버 권한 관리',
    category: '멤버 관리',
    roles: ['superHost'],
  },
  {
    displayText: '멤버 등급 관리',
    category: '멤버 관리',
    roles: ['superHost'],
  },
  {
    displayText: '운영진 설정',
    category: '멤버 관리',
    roles: ['superHost'],
  },
  {
    displayText: '본인인증 설정',
    category: '멤버 관리',
    roles: ['superHost'],
  },
  {
    displayText: '모임 별명 설정',
    category: '멤버 관리',
    roles: ['superHost'],
  },
] satisfies GroupSettingItem[];

export const groupJoinSettingConfig = [
  {
    displayText: '가입 방식 관리',
    category: '가입 관리',
    roles: ['superHost'],
  },
  {
    displayText: '모집 동네 범위 설정',
    category: '가입 관리',
    roles: ['superHost'],
  },
  {
    displayText: '최대 가입 인원 설정',
    category: '가입 관리',
    roles: ['superHost'],
  },
  {
    displayText: '모집 연령대 설정',
    category: '가입 관리',
    roles: ['superHost'],
  },
] satisfies GroupSettingItem[];

export const groupCustomerServiceSettingConfig = [
  {
    displayText: '고객센터',
    category: '고객센터',
    roles: ['superHost', 'manager', 'member'],
  },
  {
    displayText: '모임 서비스 의견 남기기',
    category: '고객센터',
    roles: ['superHost', 'manager', 'member'],
  },
  {
    displayText: '당근 모임 운영정책',
    category: '고객센터',
    roles: ['superHost', 'manager', 'member'],
  },
] satisfies GroupSettingItem[];
