import { GroupMeetupReviewCreateRequest } from '@community-group/api/lib/group/models';
import { Checkbox, Spacing, Typography } from '@community-group/components';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { MentionMultilineTextField } from '@/components/common/MentionMultilineTextField';
import { GroupMeetupFormImagesField } from '@/components/group/Meetup/Form/components/Field/Images';
import GroupMeetupReviewFormMediasField from '@/features/GroupMeetupReview/components/FormMediasField/GroupMeetupReviewFormMediasField';
import { useMembersForMentions } from '@/hooks/useMembersForMentions';

import * as s from './style.css';

type Props = {
  mentionMemberList: ReturnType<typeof useMembersForMentions>;
  groupId: string;
  meetupId: string;
  handleMediaUploading: (isUploading: boolean) => void;
};

export const ReviewPostFields = ({
  mentionMemberList,
  groupId,
  meetupId,
  handleMediaUploading,
}: Props) => {
  const { control, setValue, register, watch } = useFormContext<GroupMeetupReviewCreateRequest>();

  const selectedReviewRating = useMemo(() => watch('reviewRating'), [watch('reviewRating')]);

  const fieldLabel = useMemo(() => {
    if (selectedReviewRating === 'DISLIKE')
      return {
        title: '아쉬웠던 점을 당근팀에 알려주세요',
        description: '누구에게도 전달되지 않으니 안심하세요.',
      };

    return {
      title: '모임에서의 즐거웠던 순간을 기록해보세요',
      description: '남겨주신 후기는 모임 게시판에 공유돼요.',
    };
  }, [selectedReviewRating]);

  if (!selectedReviewRating) return null;

  return (
    <div className={s.Wrapper}>
      <div className={s.Header}>
        <Typography typography="title2Bold" style={{ whiteSpace: 'pre-line' }} wordBreak="keep-all">
          {fieldLabel.title}
        </Typography>
        <Spacing size={12} />
        <Typography typography="bodyM1Regular" wordBreak="keep-all">
          {fieldLabel.description}
        </Typography>
      </div>
      <div>
        <Spacing size={24} />
        <div style={{ display: selectedReviewRating !== 'DISLIKE' ? 'flex' : 'none' }}>
          <GroupMeetupReviewFormMediasField
            groupId={groupId}
            meetupId={meetupId}
            handleMediaUploading={handleMediaUploading}
          />
        </div>
        <Controller
          control={control}
          name="reviewPost.content"
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            const errorMessage = error?.message ?? '';
            const ref = register('reviewPost.content').ref;
            return (
              <MentionMultilineTextField
                ref={ref}
                placeholder="내용을 작성해주세요. (선택)"
                name="reviewPost.content"
                isInvalid={!!errorMessage}
                errorMessage={errorMessage}
                value={value}
                onChange={(event) => {
                  const value = event.target.value;

                  onChange(value);
                }}
                mentionConfig={[
                  {
                    trigger: '@',
                    name: 'mentionedUserIds',
                    mentionList: mentionMemberList,
                    setMentionList: (currentMentionList: string[]) => {
                      setValue(
                        'reviewPost.mentionedUserIds',
                        currentMentionList.map((id) => parseInt(id))
                      );
                    },
                  },
                ]}
              />
            );
          }}
        />
        <div style={{ paddingTop: '9px' }}>
          {selectedReviewRating !== 'DISLIKE' && (
            <Controller
              control={control}
              name="reviewPost.isGroupOnly"
              render={({ field: { value, onChange } }) => (
                <Checkbox size="large" isSelected={value} onChange={onChange}>
                  모임에만 공개
                </Checkbox>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
