export const getCertificationIntervalText = (certificationIntervalDays: number, suffix = '회') => {
  if (certificationIntervalDays === 0 || certificationIntervalDays === 7) {
    return '매일';
  }
  return `주 ${certificationIntervalDays}${suffix}`; // 주 1회
};

export const getCertificationTotalDaysDurationText = (
  totalDays: number,
  certificationIntervalDays: number
) => {
  const totalWeek = Math.floor(totalDays / certificationIntervalDays);
  if (totalWeek === 52) return '1년 동안';
  return `${totalWeek}주 동안`;
};

export const getCertificationTotalDaysText = (
  totalDays: number,
  certificationIntervalDays: number
) => {
  const totalWeek = Math.floor(totalDays / certificationIntervalDays);
  if (totalWeek === 52) return '1년 챌린지';
  return `${totalWeek}주 챌린지`;
};
