import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useReadFeatureFlag } from '@/hooks/useReadFeatureFlag';

import { SEOUL_RUN_PROMOTION_END_DATE, SEOUL_RUN_PROMOTION_START_DATE } from '../MeetupCalendar';

// 한강 프로모션 이벤트 기간 중 한강 프로모션 이벤트 기간 중 인지 확인하는 훅
const useEnableSeoulRunPromotion = () => {
  const isProduction = globalThis.appConfig.appEnv === 'production';

  const { data: enabledSeoulRunPromotion } = useReadFeatureFlag('isGroupSeoulRunPromotionFlag');

  // TODO: 기간과 수도권 지역 확인 로직 추가 필요
  const isOpenPromotion = useMemo(() => {
    return (
      dayjs().isAfter(SEOUL_RUN_PROMOTION_START_DATE) &&
      dayjs().isBefore(SEOUL_RUN_PROMOTION_END_DATE)
    );
  }, []);

  // TODO: 수도권 확인 기능 필요
  return isProduction
    ? Boolean(enabledSeoulRunPromotion && isOpenPromotion) // 프로덕션인 경우 (이벤트 기간, 수도권, 프로모션 사용 여부)
    : Boolean(enabledSeoulRunPromotion); // 알파인 경우 (프로모션 사용 여부)
};

export default useEnableSeoulRunPromotion;
