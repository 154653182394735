import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import {
  AsyncBoundary,
  Divider,
  Spacing,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from 'react';

import { useQueryChallengeDetail } from '@/domain/Challenge/hooks/useReadChallengeDetail';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { ChallengeDetailAppbarRightButton } from '@/features/ChallengeDetail/ChallegeDetailAppbarRightButton';
import ChallengeAccessoryBar from '@/features/ChallengeDetail/ChallengeAccessoryBar';
import ChallengeInfoSection from '@/features/ChallengeDetail/InfoSection';
import MyChallengeInfoSection from '@/features/ChallengeDetail/MyChallengeInfoSection';
import ParticipantsStatusSection from '@/features/ChallengeDetail/ParticipantsStatusSection';
import RemindNotificationSection from '@/features/ChallengeDetail/RemindNotificationSection';
import useDidMountSequentialProcess from '@/hooks/useDidMountSequentialProcess';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithThrowAccessStatusPage } from '@/hooks/useHandleErrorWithThrowAccessStatusPage';
import { useHandleRestrictedUser } from '@/hooks/useHandleRestrictedUser';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';

import * as s from './style.css';

type Params = Pick<PageParams, 'groupId' | 'challengeId' | 'from'>;

const GroupChallengeDetailPage: ActivityComponentType<Params> = () => {
  const { groupId, challengeId } = usePathParams();
  const [challengeDetail, setChallengeDetail] = useState<ChallengeDetailPresentation>();
  const { from } = useQueryParams();

  const { push } = useFlow();
  const handleClickGroupName = () => {
    push('GroupDetailPage', {
      groupId,
    });
  };

  useHandleRestrictedUser({
    serviceType: 'challenge',
    groupId,
    challengeId,
  });

  const handleErrorWithThrowErrorStatusPage = useHandleErrorWithThrowAccessStatusPage();

  // 페이지 진입 후 순차적으로 처리해야 하는 프로세스 훅
  useDidMountSequentialProcess([
    // 챌린지 모임 생성 플로우로 만들어진 경우 모임에 대한 안내 바텀시트 추가
    () => {
      if (from === 'instantCreateChallenge') {
        push('BottomSheet/CreatedChallengeGroupBottomSheet', {});
        return true;
      }
      return false;
    },
  ]);

  return (
    <AppScreen
      disabledClientOnly
      appBar={{
        title: (
          <div style={{ width: '100%', overflow: 'hidden' }} onClick={handleClickGroupName}>
            <Typography typography="title3Bold" ellipsisAfterLines={1}>
              {challengeDetail?.groupName}
            </Typography>
          </div>
        ),
        renderRight: () =>
          challengeDetail && (
            <Suspense>
              <ChallengeDetailAppbarRightButton
                challengeDetail={challengeDetail}
                groupId={groupId}
              />
            </Suspense>
          ),
      }}
      accessoryBar={challengeDetail && <ChallengeAccessoryBar challengeDetail={challengeDetail} />}
      onPull={() => {
        refetchGroupChallenge({ groupId, challengeId });
      }}
    >
      <AsyncBoundary
        pendingFallback={<ViewLoader />}
        rejectedFallback={<ViewError />}
        onError={(error) =>
          handleErrorWithThrowErrorStatusPage({
            error,
            serviceType: 'challenge',
            groupId,
            challengeId,
          })
        }
      >
        <GroupChallengeDetailCore setChallengeDetail={setChallengeDetail} />
      </AsyncBoundary>
    </AppScreen>
  );
};

const GroupChallengeDetailCore = ({
  setChallengeDetail,
}: {
  setChallengeDetail: Dispatch<SetStateAction<ChallengeDetailPresentation | undefined>>;
}) => {
  const { groupId, challengeId } = usePathParams();
  const { from } = useQueryParams();

  const [{ data: challengeDetail }, { data: group }] = useSuspenseQueries({
    queries: [useQueryChallengeDetail({ groupId, challengeId }), useQueryGroupDetail(groupId)],
  });

  useEnterTrackEvent({
    event: 'enter_challenge_detail_page',
    params: {
      challengeId,
      challengeName: challengeDetail?.name,
      groupId,
      groupName: group?.name,
      categoryId: group?.category.id,
      currentUserChallengeStatus: challengeDetail?.isJoined ? 'joined' : 'not_joined',
      isCreatedChallengeGroup: from === 'instantCreateChallenge',
    },
  });

  // 부모 AppScreen내부에서 챌린지 상세 정보가 필요해서 데이터 전달을 위해 사용
  useEffect(() => {
    if (challengeDetail) setChallengeDetail(challengeDetail);
  }, [challengeDetail, setChallengeDetail]);

  const isStarted = challengeDetail.status && challengeDetail.status !== 'prepared';

  return (
    <div className={s.Wrapper}>
      <ChallengeInfoSection challengeDetail={challengeDetail} groupId={groupId} />

      {challengeDetail?.isJoined && isStarted && (
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
          <Divider padding={0} />
          <RemindNotificationSection groupId={Number(groupId)} challengeDetail={challengeDetail} />
          <Divider padding={0} />
          <MyChallengeInfoSection />
        </AsyncBoundary>
      )}
      {isStarted && (
        <>
          <Divider padding={0} />
          <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
            <ParticipantsStatusSection />
          </AsyncBoundary>
        </>
      )}

      {/* 최하단 여백 40 */}
      <Spacing size={40} />
    </div>
  );
};

export default withAsyncBoundary(GroupChallengeDetailPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
