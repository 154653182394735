import { MainProfile } from '@community-group/api/lib/group/models';
import { Render, Typography } from '@community-group/components';
import { PaginationList } from '@community-group/components/shared';

import { ProfileMeetupMemberReviewMessageItem } from '@/components/group/profile/MemberProfileDetail/components/MeetupMemberReviewMessageItem';
import ReceivedReviewsSection from '@/components/group/profile/MemberProfileDetail/components/RecievedReviewsSection';
import { useReadGroupMeetupMemberReviewMessages } from '@/domain/GroupProfile/hooks/useReadGroupMeetupMemberReviewMessages';

type Props = {
  mainProfile?: MainProfile;
};
const GroupMainProfileReviewSection = ({ mainProfile }: Props) => {
  const isMyProfile = !!mainProfile?.isMyProfile;

  const {
    data: meetupMemberReviewMessages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useReadGroupMeetupMemberReviewMessages({
    userId: Number(mainProfile?.id),
    reviewExposureRange: !isMyProfile ? 'all' : undefined,
  });

  if (!mainProfile) return null;

  return (
    <section style={{ paddingBottom: '7.5rem' }}>
      {mainProfile?.meetupMemberReviews.length > 0 && (
        <ReceivedReviewsSection reviews={mainProfile?.meetupMemberReviews} />
      )}
      <Render condition={meetupMemberReviewMessages.length > 0}>
        <ul style={{ paddingTop: '1rem' }}>
          <div style={{ padding: '0 1rem', marginBottom: '1rem' }}>
            <Typography typography="title3Bold">받은 감사 인사</Typography>
          </div>
          <PaginationList
            items={meetupMemberReviewMessages}
            render={(memberReviewMessage) => (
              <ProfileMeetupMemberReviewMessageItem
                key={memberReviewMessage.id}
                isMyProfile={isMyProfile}
                meetupMemberReviewMessage={memberReviewMessage}
              />
            )}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </ul>
      </Render>
    </section>
  );
};

export default GroupMainProfileReviewSection;
