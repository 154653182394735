import { GroupMeetupEvaluationResponse } from '@community-group/api/lib/group/models';
import { Dialog, useDialog, useEffectOnce } from '@community-group/components';
import { startTransition } from 'react';

import { useBack } from '@/stackflow/hooks/useBack';

type Props = {
  review: GroupMeetupEvaluationResponse['review'];
};

const GroupMeetupReviewWrittenReviewDialog = ({ review }: Props) => {
  const { open: openDialog, close: closeDialog } = useDialog();
  const back = useBack();
  const handleCloseDialog = async () => {
    await closeDialog();
  };

  useEffectOnce(() => {
    if (!review) return;
    const isReviewPostDeleted = review.reviewPost?.isDeleted;
    const title = isReviewPostDeleted ? '작성한 후기를 수정할 수 없어요' : '작성한 후기가 있어요';
    const description = isReviewPostDeleted
      ? '후기 게시글이 이미 삭제되어 더이상 수정할 수 없어요.'
      : '후기 게시글을 수정할까요? 멤버 후기는 수정할 수 없어요.';
    const primaryActionLabel = isReviewPostDeleted ? '확인' : '수정';
    const secondaryActionLabel = isReviewPostDeleted ? undefined : '취소';
    const secondaryActionIntent = isReviewPostDeleted ? 'nonpreferred' : 'neutral';
    openDialog({
      element: (
        <Dialog
          title={title}
          description={description}
          primaryActionLabel={primaryActionLabel}
          secondaryActionLabel={secondaryActionLabel}
          onPrimaryAction={isReviewPostDeleted ? back : handleCloseDialog}
          secondaryActionIntent={secondaryActionIntent}
          onSecondaryAction={back}
        />
      ),
    });
  });
  return null;
};
export default GroupMeetupReviewWrittenReviewDialog;
