import { Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { motion } from 'framer-motion';
import { MouseEventHandler, PropsWithChildren, ReactNode, useMemo } from 'react';

import * as s from './index.css';
import MemberGradeMissionCircleProgress from './MemberGradeMissionCircleProgress';

type Props = PropsWithChildren<{
  title: string;
  progress: number;
  size: 'small' | 'large';
  actionButton: ReactNode;
  onClick: MouseEventHandler;
}>;

const GroupMemberGradeMissionBanner = ({
  title,
  progress,
  size,
  actionButton,
  onClick,
  children,
}: Props) => {
  const isLarge = size === 'large';
  const titleTypography = isLarge ? 'subtitle2Bold' : 'caption1Bold';
  const descriptionTypography = isLarge ? 'caption1Regular' : 'caption2Regular';
  const spacingSize = isLarge ? 16 : 12;

  const progressText = useMemo(() => {
    switch (true) {
      case progress === 100:
        return '등업 조건을 모두 달성했어요!';
      case progress === 0:
        return '등업 조건을 달성하고 정회원이 되어보세요!';
      default:
        return `정회원까지 ${100 - progress}% 남았어요!`;
    }
  }, [progress]);

  return (
    <motion.div className={s.Container({ size })}>
      <div className={s.Header} onClick={onClick}>
        <MemberGradeMissionCircleProgress progress={progress} size={size} />
        <VerticalSpacing size={spacingSize} />
        <div className={s.HeaderContent}>
          <Typography typography={descriptionTypography} color="gray900">
            {progressText}
          </Typography>
          <Spacing size={2} />
          <Typography typography={titleTypography} color="gray900">
            {title}
          </Typography>
        </div>
        <VerticalSpacing size={spacingSize} />
        {actionButton}
      </div>
      {children}
    </motion.div>
  );
};

export default GroupMemberGradeMissionBanner;
