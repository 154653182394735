import {
  AsyncBoundary,
  Tab,
  TabList,
  TabPanel,
  TabPanelGroup,
  Tabs,
  useEffectOnce,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useLayoutEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import useKarrotSearchLogger, {
  getSafeQueryFrom,
  mappedGroupSearchV1Params,
} from '@/hooks/useKarrotSearchLogger';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useReferQueryParams } from '@/stackflow/hooks/useReferQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';

import SearchBar from '../components/SearchBar';
import SearchHomeBanner from '../components/SearchHomeBanner';
import SearchResultAllResult from '../components/SearchResultAllResult';
import SearchResultGroup from '../components/SearchResultGroup';
import SearchResultMeetup from '../components/SearchResultMeetup';
import * as s from './Result.css';

export type SearchResultPageParams = Pick<
  PageParams,
  | 'query'
  | 'searchCurrentTab'
  | 'regionRange'
  | 'queryFrom'
  | 'searchTab'
  | 'queryId'
  | 'utm_medium'
>;

export type SearchTabType = 'all' | 'group' | 'meetup' | 'onlyGroup';

/**
 * 작업시 검색팀 요청 로깅 스키마 확인 필요
 * @see {@link https://www.notion.so/daangn/12028c3a9f8f802dbe3dee098e8a288d?v=af1bdebdf87b4735a2e84452d36a0ecf}
 */
const SearchResultPage: ActivityComponentType<SearchResultPageParams> = () => {
  const { query = '' } = usePathParams();
  const {
    searchCurrentTab = 'group',
    queryId,
    queryFrom = 'unknown',
    ...queryParams
  } = useQueryParams();
  const { replace } = useFlow();

  const [currentTab, setCurrentTab] = useState<SearchTabType>(searchCurrentTab);

  // 검색 관련 로깅을 위한 queryId가 없으면 생성 후 replace
  useLayoutEffect(() => {
    if (!queryId) {
      const newQueryId = uuidv4();
      replace(
        'SearchResultPage',
        {
          ...queryParams,
          query: query,
          queryFrom,
          queryId: newQueryId,
        },
        {
          animate: false,
        }
      );
    }
  }, [query, queryFrom, queryId, queryParams, replace]);

  return (
    <AppScreen
      appBar={{
        renderRight: () => (
          <div
            style={{
              position: 'relative',
              left: '-8px',
              width: 'calc(100vw - 3.25rem - 14px)',
            }}
          >
            <SearchBar
              initValue={decodeURIComponent(query)}
              onSubmit={(value) => {
                replace(
                  'SearchResultPage',
                  {
                    query: encodeURIComponent(value),
                    queryFrom: 'typed',
                    searchCurrentTab: currentTab,
                    queryId: uuidv4(),
                  },
                  { animate: false }
                );
              }}
            />
          </div>
        ),
      }}
    >
      {currentTab === 'onlyGroup' ? (
        <SearchResultGroup currentTab={currentTab} />
      ) : (
        <SearchResultPageWrapper currentTab={currentTab} setCurrentTab={setCurrentTab} />
      )}
    </AppScreen>
  );
};

const SearchResultPageWrapper = ({
  currentTab,
  setCurrentTab,
}: {
  currentTab: SearchTabType;
  setCurrentTab: React.Dispatch<React.SetStateAction<SearchTabType>>;
}) => {
  const { query = '' } = usePathParams();

  const { referrer = 'community_group.client', queryId, queryFrom = 'unknown' } = useQueryParams();
  const referQueryParams = useReferQueryParams();
  const searchLogger = useKarrotSearchLogger();

  const SearchTabContents = [
    {
      value: 'all',
      label: '전체',
      content: (
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
          <SearchResultAllResult setCurrentTab={setCurrentTab} currentTab={currentTab} />
        </AsyncBoundary>
      ),
    },
    {
      value: 'meetup',
      label: '일정',
      content: <SearchResultMeetup currentTab={currentTab} />,
    },
    {
      value: 'group',
      label: '모임',
      content: <SearchResultGroup currentTab={currentTab} />,
    },
  ];

  useEnterTrackEvent({
    event: 'enter_search_result',
    params: {
      keyword: decodeURIComponent(query),
      from: referrer,
      queryId,
      ...referQueryParams,
    },
    sample: true,
    enabled: !!queryId,
  });

  useEffectOnce(() => {
    if (!queryId) return;
    searchLogger.groupSearchV1(
      mappedGroupSearchV1Params({
        query,
        queryId,
        queryFrom: getSafeQueryFrom(queryFrom),
        tab: currentTab,
      })
    );
  });

  const ref = useRef<HTMLDivElement>(null);
  // tab 전환시 최상단으로 스크롤
  const scrollToTabTop = () => {
    const rect = ref.current?.getClientRects()[0];
    if (rect?.top && rect.top < 50) {
      ref.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  };

  return (
    <>
      {referrer !== 'community_group.client' && (
        <>
          <SearchHomeBanner />
        </>
      )}
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <div className={s.tabsWrapper} ref={ref}>
          <Tabs
            layout="hug"
            defaultValue="all"
            value={currentTab}
            isLazy
            lazyMode="unmount"
            isSwipeable={false}
            onChange={(value) => {
              scrollToTabTop();
              trackEvent({
                event: 'click_search_tab',
                params: {
                  tab: value as 'all' | 'group' | 'meetup',
                  keyword: decodeURIComponent(query),
                  from: referrer,
                  ...referQueryParams,
                },
                sample: true,
              });
              setCurrentTab(value as 'all' | 'group' | 'meetup');
            }}
          >
            <TabList>
              {SearchTabContents.map((item) => (
                <Tab key={item.value} value={item.value}>
                  {item.label}
                </Tab>
              ))}
            </TabList>
            <TabPanelGroup>
              {SearchTabContents.map((item) => (
                <TabPanel key={item.value} value={item.value}>
                  {item.content}
                </TabPanel>
              ))}
            </TabPanelGroup>
          </Tabs>
        </div>
      </AsyncBoundary>
    </>
  );
};

export default withAsyncBoundary(SearchResultPage, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <ViewError />,
});
