import { AsyncBoundary, Spacing, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import LeaderBoardAppScreenRightButton from '../components/LeaderBoardAppScreenRightButton';
import LeaderBoardCategoryFilter from '../components/LeaderBoardCategoryFilter';
import LeaderBoardHeader from '../components/LeaderBoardHeader';
import RankGroupList from '../components/RankGroupList';
import * as s from './index.css';

type GroupLeaderBoardParams = Pick<PageParams, 'isCurrentWeek'>;

const GroupLeaderBoard: ActivityComponentType<GroupLeaderBoardParams> = () => {
  const { isCurrentWeek } = useQueryParams();
  const isCurrentWeekBoolean = !isCurrentWeek || isCurrentWeek !== 'false';

  const [categoryId, setCategoryId] = useState(0);

  const [visibleAppBarDivider, setVisibleAppBarDivider] = useState(false);

  const { ref, inView } = useInView({
    root: null,
    rootMargin: '1px',
    threshold: 1,
  });

  useEnterTrackEvent({
    event: 'enter_leaderboard_page',
  });

  useEffect(() => {
    if (inView) {
      setVisibleAppBarDivider(false);
    } else {
      setVisibleAppBarDivider(true);
    }
    return () => {};
  }, [inView]);

  return (
    <AppScreen
      appBar={{
        border: visibleAppBarDivider ? true : false,
        borderColor: vars.$semantic.color.divider1,
        borderSize: '1px',
        renderRight: () => <LeaderBoardAppScreenRightButton isCurrentWeek={isCurrentWeek} />,
      }}
    >
      <div className={s.Wrapper}>
        <div style={{ width: '100%' }} ref={ref} />
        <AsyncBoundary pendingFallback={<ViewLoader />}>
          <LeaderBoardHeader isCurrentWeek={!!isCurrentWeekBoolean} />
        </AsyncBoundary>
        <>
          <LeaderBoardCategoryFilter
            isCurrentWeek={!!isCurrentWeekBoolean}
            setCategoryId={setCategoryId}
            categoryId={categoryId}
          />
          <Spacing size={8} />
        </>
        <AsyncBoundary pendingFallback={<ViewLoader />}>
          <RankGroupList isCurrentWeek={!!isCurrentWeekBoolean} categoryId={categoryId} />
        </AsyncBoundary>
        <Spacing size={60} />
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupLeaderBoard, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
});
