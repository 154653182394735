import { AsyncBoundary, Typography, ViewError, ViewLoader } from '@community-group/components';
import { Dispatch, SetStateAction } from 'react';

import { ORDER_SEARCH } from '@/api/base/group';
import { useGetMeetupSearchList } from '@/api/hooks/useGetMeetupSearchList';
import { useGetSearchGroupList } from '@/api/hooks/useGetSearchGroupList';
import { isSetFilter } from '@/components/group/Search/utils/filter';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import { SearchTabType } from '../../Page/Result';
import PopularityGroupListSection from '../PopularityGroupListSection';
import SearchResultAllFilterMeetup from '../SearchResultAllFilteredMeetup';
import SearchResultList from '../SearchResultList';
import SearchResultEmptyView from '../SearchResultList/EmptyView';

type Props = {
  currentTab: SearchTabType;
  setCurrentTab: Dispatch<SetStateAction<SearchTabType>>;
};

const SearchResultAllResult = ({ currentTab, setCurrentTab }: Props) => {
  const { query = '' } = usePathParams();
  const { referrer = 'community_group.client', queryId } = useQueryParams();

  const { data: meetupSearchResult } = useGetMeetupSearchList({
    order: 'meet_at_asc',
    searchQuery: decodeURIComponent(query),
  });

  const searchFilter = {
    query,
    category: [],
    order: ORDER_SEARCH.RECOMMEND_SCORE_DESC,
    referrer,
    queryId,
  };

  const { data: groupSearchResult, hasNextPage } = useGetSearchGroupList(searchFilter);

  const meetupData = meetupSearchResult?.pages.map(({ data }) => data.meetups);
  const meetupFlatten = meetupData?.flat(1) ?? [];

  const groupData = groupSearchResult?.pages.map(({ data }) => data.groups);
  const groupFlatten = groupData?.flat(1) ?? [];

  const meetupPostCount = meetupFlatten.length > 3 ? 3 : meetupFlatten.length;

  if (meetupFlatten.length <= 0 && groupFlatten.length <= 0)
    return (
      <SearchResultEmptyView
        currentTab={currentTab}
        isSetFilter={isSetFilter(searchFilter)}
        initFilter={() => {}}
      />
    );

  return (
    <div>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <SearchResultAllFilterMeetup
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          searchFilter={searchFilter}
        />
      </AsyncBoundary>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <Typography
          typography="subtitle1Bold"
          style={{
            padding: '1.25rem 1rem 0.5rem 1rem',
          }}
        >
          모임
        </Typography>
        <SearchResultList
          currentTab={currentTab}
          groupList={groupFlatten}
          hasNextPage={hasNextPage}
          searchFilter={searchFilter}
          startIndex={meetupPostCount}
        />
        <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
          <PopularityGroupListSection
            currentTab={currentTab}
            startIndex={meetupPostCount + groupFlatten.length}
          />
        </AsyncBoundary>
      </AsyncBoundary>
    </div>
  );
};

export default SearchResultAllResult;
