import { UseMutationOptions } from '@tanstack/react-query';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { GROUP_URL } from '../base/post';
import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<string | undefined, Error, { groupId?: string; postId?: string }>;

export const useDeletePost = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const deletePost = async ({ groupId, postId }: { groupId?: string; postId?: string }) => {
    await fetchInstance.delete<{ content: string }, undefined>(
      `${GROUP_URL}/${groupId}/posts/${postId}`
    );

    return postId;
  };

  return useThrottleMutation(deletePost, {
    onError,
    onSuccess,
  });
};
