import { Spacing, Typography } from '@community-group/components';
import { IconChevronLeftFill, IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import dayjs from 'dayjs';
import { memo, useCallback, useMemo, useState } from 'react';

import * as s from './Calendar.css';

interface Props {
  startDate?: Date;
  endDate?: Date;
  renderDateItem?: (date: Date, data: CalendarDateItemDataType) => React.ReactNode;
  initialShowMonth?: Date; // 초기 표시 월
}

export type CalendarDateItemDataType = {
  isBeforeStartDate: boolean; // 시작일 이전 - 시작일 지정이 안되어져 있는 경우 false
  isAfterEndDate: boolean; // 종료일 이후 - 종료일 지정이 안되어져 있는 경우 false
};

const Calendar = ({ startDate, endDate, renderDateItem, initialShowMonth = new Date() }: Props) => {
  const [currentDate, setCurrentDate] = useState(() =>
    initialShowMonth ? dayjs(initialShowMonth) : dayjs()
  );

  const calendarDates = useMemo(() => {
    const firstDateOfMonth = currentDate.startOf('month');
    const lastDateOfMonth = currentDate.endOf('month');
    const startDay = firstDateOfMonth.day();

    const dates: (Date | null)[] = Array(startDay).fill(null);

    let currentDay = firstDateOfMonth;
    while (currentDay.isBefore(lastDateOfMonth) || currentDay.isSame(lastDateOfMonth, 'day')) {
      dates.push(currentDay.toDate());
      currentDay = currentDay.add(1, 'day');
    }

    return dates;
  }, [currentDate]);

  const canGoPrev = useMemo(
    () => (startDate ? currentDate.isAfter(dayjs(startDate), 'month') : true),
    [startDate, currentDate]
  );
  const canGoNext = useMemo(
    () => (endDate ? currentDate.isBefore(dayjs(endDate), 'month') : true),
    [endDate, currentDate]
  );

  const handlePrevMonth = useCallback(() => {
    if (!canGoPrev) return;
    setCurrentDate((prev) => prev.subtract(1, 'month'));
  }, [canGoPrev]);

  const handleNextMonth = useCallback(() => {
    if (!canGoNext) return;
    setCurrentDate((prev) => prev.add(1, 'month'));
  }, [canGoNext]);

  const getDateItemData = useCallback(
    (date: Date): CalendarDateItemDataType => {
      const isBeforeStartDate = startDate ? date < startDate : false;
      const isAfterEndDate = endDate ? date > endDate : false;

      return {
        isBeforeStartDate,
        isAfterEndDate,
      };
    },
    [startDate, endDate]
  );

  return (
    <div className={s.CalendarWrapper}>
      <div className={s.CalendarHeader}>
        <button onClick={handlePrevMonth} className={s.NavigationButton} disabled={!canGoPrev}>
          <IconChevronLeftFill
            size={20}
            color={canGoPrev ? vars.$scale.color.gray900 : vars.$scale.color.gray600}
          />
        </button>
        <Typography typography="subtitle1Bold" color="gray700">
          {currentDate.format('YYYY년 M월')}
        </Typography>
        <button onClick={handleNextMonth} className={s.NavigationButton} disabled={!canGoNext}>
          <IconChevronRightFill
            size={20}
            color={canGoNext ? vars.$scale.color.gray900 : vars.$scale.color.gray600}
          />
        </button>
      </div>
      <Spacing size={20} />
      <div className={s.WeekdayHeader}>
        {['일', '월', '화', '수', '목', '금', '토'].map((day) => (
          <Typography key={day} typography="caption1Bold" color="gray600" textAlign="center">
            {day}
          </Typography>
        ))}
      </div>
      <Spacing size={8} />
      <div className={s.DaysGrid}>
        {calendarDates.map((date, index) => (
          <CalendarDay
            key={date?.getTime() ?? index}
            date={date}
            renderDateItem={renderDateItem}
            getDateItemData={getDateItemData}
          />
        ))}
      </div>
    </div>
  );
};

export default Calendar;

type CalendarDayProps = {
  date: Date | null;
  renderDateItem: ((date: Date, data: CalendarDateItemDataType) => React.ReactNode) | undefined;
  getDateItemData: (date: Date) => CalendarDateItemDataType;
};

const CalendarDay = memo(({ date, renderDateItem, getDateItemData }: CalendarDayProps) => {
  if (!date) return <div className={s.DateCell} />;

  return (
    <div className={s.DateCell}>
      {renderDateItem?.(date, getDateItemData(date)) ?? (
        <div className={s.DefaultDateItem}>
          <Typography className={s.DayText} typography="subtitle2Regular" color="gray900">
            {date.getDate()}
          </Typography>
        </div>
      )}
    </div>
  );
});
