import { useSuspenseQueries } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupMemberApplicationCount } from '@/domain/GroupMember/hooks/useReadGroupMemberApplicationCount';
import { useQueryGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import { useQueryGroupSidebar } from '@/domain/GroupSidebar/hooks/useReadGroupSidebar';
import { isAccessibleGrade } from '@/utils/permission';

export const useGetGroupSidebar = (groupId: number) => {
  const groupIdString = groupId.toString();

  const [
    { data: me },
    { data: permissions },
    { data: groupSidebar },
    { data: memberGradeApplicationCount },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupMe({ groupId: groupIdString }),
      useQueryGroupPermissions(groupIdString),
      useQueryGroupSidebar(groupIdString),
      useQueryGroupMemberApplicationCount(groupIdString),
    ],
  });

  const hasGroupMemberGradeWaitingMember = useMemo(
    () =>
      isAccessibleGrade(me.grade, permissions.modifyGroupMemberGrade.currentPriority) &&
      memberGradeApplicationCount > 0,
    [me.grade, memberGradeApplicationCount, permissions.modifyGroupMemberGrade.currentPriority]
  );

  useEffect(() => {
    if (!hasGroupMemberGradeWaitingMember) return;

    groupSidebar.featureRedDots = [...(groupSidebar.featureRedDots || []), 'member'];
  }, [groupSidebar, hasGroupMemberGradeWaitingMember]);

  return { data: groupSidebar };
};
