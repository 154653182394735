import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_FEED_QUERY_KEY } from '../queries';

export const useQueryGroupFeedSummaryMeetup = (groupId: string) => {
  const read = useAsyncRead(groupClient.api.GroupFeedApi.apiV1GroupsIdFeedSummaryMeetupGet);
  return {
    queryKey: GROUP_FEED_QUERY_KEY.feedSummaryMeetup(groupId),
    queryFn: () => read({ id: Number(groupId) }),
  };
};

export const useReadGroupFeedSummaryMeetup = (groupId: string) => {
  const query = useQueryGroupFeedSummaryMeetup(groupId);
  return useSuspenseQuery(query);
};
