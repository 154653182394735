import React from 'react';

import { useConnectedFlow, useFlow } from '@/stackflow';

import * as s from './style.css';

const LOCATION_ITEM_IMG =
  'https://assetstorage.krrt.io/1025317940251365954/17a5ffd5-a675-45a3-a930-e047ccb73ec9/width=435.8,height=342.webp';

const LP_DATA =
  globalThis.appConfig.appEnv === 'production'
    ? [
        {
          name: '반포한강공원 달빛광장',
          id: 15153920,
          type: 'LOCAL_PROFILE',
        },
        {
          name: '뚝섬한강공원 뚝기축제광장',
          id: 15153921,
          type: 'LOCAL_PROFILE',
        },
        {
          name: '서울함공원 다목적광장',
          id: 15153922,
          type: 'LOCAL_PROFILE',
        },
        {
          name: '양화대교 북단 하부 운동기구존',
          id: 15153923,
          type: 'LOCAL_PROFILE',
        },
        {
          name: '잠실한강공원 청소년광장',
          id: 15153924,
          type: 'LOCAL_PROFILE',
        },
      ]
    : [
        {
          name: '반포한강공원 달빛광장',
          id: 10554190,
          type: 'LOCAL_PROFILE',
        },
        {
          name: '뚝섬한강공원 뚝기축제광장',
          id: 10554191,
          type: 'LOCAL_PROFILE',
        },
        {
          name: '서울함공원 다목적광장',
          id: 10554192,
          type: 'LOCAL_PROFILE',
        },
        {
          name: '양화대교 북단 하부 운동기구존',
          id: 10554193,
          type: 'LOCAL_PROFILE',
        },
        {
          name: '잠실한강공원 청소년광장',
          id: 10554194,
          type: 'LOCAL_PROFILE',
        },
      ];

const PromotionLocationList = ({
  hasJoinedGroup,
  groupId,
}: {
  hasJoinedGroup: boolean;
  groupId?: string;
}) => {
  const { push: connectedPush } = useConnectedFlow();
  const { push } = useFlow();

  const handleClickCreateSeoulRunMeetup = async (id: number) => {
    const selectedLpData = LP_DATA.find((el) => el.id === id);

    // 모임 상세 > 일정 리스트에서 프로모션 페이지를 진입한 경우
    if (groupId) {
      push('SeoulRunMeetupNewPage', {
        from: 'SeoulRunLocationListPage',
        groupId,
        cloneGroupMeetupFormData: JSON.stringify({
          poiItems: [selectedLpData],
        }),
        poiName: selectedLpData?.name,
      });
      return;
    }

    // TODO: 이후 실제 location별 template 추가 및 변경 필요
    if (hasJoinedGroup) {
      const response = (await connectedPush(
        'BottomSheet/SelectCreateMeetupInMyGroupBottomSheet',
        {}
      )) as {
        groupId?: string;
      };

      if (response?.groupId) {
        push('SeoulRunMeetupNewPage', {
          groupId: response.groupId.toString(),
          from: 'SeoulRunLocationListPage',
          cloneGroupMeetupFormData: JSON.stringify({
            poiItems: [selectedLpData],
          }),
          poiName: selectedLpData?.name,
        });
        return;
      }
    }

    push('SeoulRunMeetupNewPage', {
      from: 'SeoulRunLocationListPage',
      cloneGroupMeetupFormData: JSON.stringify({
        poiItems: [selectedLpData],
      }),
      poiName: selectedLpData?.name,
    });
  };

  return (
    <div className={s.SelectBoxList}>
      {LP_DATA.map((item) => (
        <img
          key={item.id}
          src={LOCATION_ITEM_IMG}
          className={s.SelectItemImg}
          onClick={() => {
            handleClickCreateSeoulRunMeetup(item.id);
          }}
        />
      ))}
    </div>
  );
};

export default PromotionLocationList;
