import { ChallengeStatusEnum } from '@community-group/api/lib/group/models';

export const GROUP_CHALLENGE_QUERY_KEY = {
  base: () => ['challenge'] as const,
  feeds: (groupId: string, challengeId: string) =>
    [...GROUP_CHALLENGE_QUERY_KEY.base(), groupId, challengeId] as const,
  challengeList: () => [...GROUP_CHALLENGE_QUERY_KEY.base(), 'challengeList'] as const,
  banner: (groupId: string) => [...GROUP_CHALLENGE_QUERY_KEY.base(), groupId, 'banner'] as const,
  detail: (groupId: string, challengeId: string) =>
    [...GROUP_CHALLENGE_QUERY_KEY.base(), groupId, challengeId, 'detail'] as const,
  notificationSetting: (groupId: string, challengeId: string) =>
    [...GROUP_CHALLENGE_QUERY_KEY.base(), groupId, challengeId, 'notificationSetting'] as const,
  myCertifyActivity: (groupId: string, challengeId: string) =>
    [...GROUP_CHALLENGE_QUERY_KEY.base(), groupId, challengeId, 'myCertifyActivity'] as const,
  participantsRank: (groupId: string, challengeId: string) =>
    [...GROUP_CHALLENGE_QUERY_KEY.base(), groupId, challengeId, 'participantsRank'] as const,
  groupChallengeList: (
    groupId: string,
    status: ChallengeStatusEnum,
    initialCursor?: string,
    limit?: number
  ) =>
    [
      ...GROUP_CHALLENGE_QUERY_KEY.base(),
      'groupChallengeList',
      groupId,
      status,
      initialCursor,
      limit,
    ] as const,
  myChallengeCalendar: (groupId: string, challengeId: string) =>
    [...GROUP_CHALLENGE_QUERY_KEY.base(), groupId, challengeId, 'myChallengeCalendar'] as const,
};
