import { groupClient } from '@community-group/api';
import { ApiV1GroupsGroupIdMeetupsMeetupIdPromotionGetRequestParams } from '@community-group/api/lib/group/api/meetup-api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_SEOULRUN_PROMOTION_QUERY_KEY } from '../queries';

export const useQueryIsSeoulRunMeetupPromotion = ({
  groupId,
  meetupId,
}: ApiV1GroupsGroupIdMeetupsMeetupIdPromotionGetRequestParams) => {
  const read = useAsyncRead(
    groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdPromotionGet
  );
  return {
    queryKey: GROUP_SEOULRUN_PROMOTION_QUERY_KEY.isSeoulRunMeetupPromotion(
      groupId.toString(),
      meetupId.toString()
    ),
    queryFn: () => read({ groupId, meetupId }),
  };
};

export const useReadIsSeoulRunMeetupPromotion = ({
  groupId,
  meetupId,
}: ApiV1GroupsGroupIdMeetupsMeetupIdPromotionGetRequestParams) => {
  const query = useQueryIsSeoulRunMeetupPromotion({
    groupId,
    meetupId,
  });
  return useSuspenseQuery({ ...query });
};
