import { createContext, RefObject, useContext } from 'react';

type ScrollContextType = {
  scrollRef: RefObject<HTMLDivElement>;
};

export const ScrollRefContext = createContext<ScrollContextType | null>(null);

export const useScrollRef = () => {
  const context = useContext(ScrollRefContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};
