import { groupClient } from '@community-group/api';
import { ApiV1SeoulRunningPromotionCheckJoinableRegionRegionIdGetRequestParams } from '@community-group/api/lib/group/api/seoul-running-promotion-api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_SEOULRUN_PROMOTION_QUERY_KEY } from '../queries';

export const useQuerySeoulRunningPromotionCheckJoinableRegionRegion = ({
  regionId,
}: ApiV1SeoulRunningPromotionCheckJoinableRegionRegionIdGetRequestParams) => {
  const read = useAsyncRead(
    groupClient.api.SeoulRunningPromotionApi
      .apiV1SeoulRunningPromotionCheckJoinableRegionRegionIdGet
  );
  return {
    queryKey: GROUP_SEOULRUN_PROMOTION_QUERY_KEY.checkJoinableRegionRegion(regionId.toString()),
    queryFn: () => read({ regionId }),
  };
};

export const useReadSeoulRunningPromotionCheckJoinableRegionRegion = ({
  regionId,
}: ApiV1SeoulRunningPromotionCheckJoinableRegionRegionIdGetRequestParams) => {
  const query = useQuerySeoulRunningPromotionCheckJoinableRegionRegion({
    regionId,
  });
  return useSuspenseQuery({ ...query });
};
