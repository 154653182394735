import { TypeActivityNames } from '@/stackflow';

export type BlockableActivityNames = Extract<
  TypeActivityNames,
  | 'GroupPostNewPage'
  | 'GroupPostEditPage'
  | 'GroupMeetupNewPage'
  | 'GroupMeetupEditPage'
  | 'GroupChallengeNewPage'
  | 'ChallengeEditPage'
  | 'TalkRoomNewInformationPage'
  | 'GroupEditMemberSubNicknamePage'
  | 'GroupSettingEditSubNicknamePage'
  | 'CommentEditPage'
>;

export type BlockableActionNames =
  | 'DirectChat'
  | 'JoinChallenge'
  | 'JoinMeetup'
  | 'ApproveApplicationMember'
  | 'RejectApplicationMember'
  | 'CreateComment'
  | 'EditComment'
  | 'JoinChatRoom';

export const blockActivityMessageMap: Map<BlockableActivityNames, string> = new Map([
  ['GroupPostNewPage', '제재된 모임에선 게시글을 작성할 수 없어요.'],
  ['GroupPostEditPage', '제재된 모임에선 게시글을 수정할 수 없어요.'],
  ['GroupMeetupNewPage', '제재된 모임에선 일정을 만들 수 없어요.'],
  ['GroupMeetupEditPage', '제재된 모임에선 일정을 수정할 수 없어요.'],
  ['GroupChallengeNewPage', '제재된 모임에선 챌린지를 만들 수 없어요.'],
  ['ChallengeEditPage', '제재된 모임에선 챌린지를 수정할 수 없어요.'],
  ['TalkRoomNewInformationPage', '제재된 모임에선 채팅을 만들 수 없어요.'],
  ['GroupEditMemberSubNicknamePage', '제재된 모임에선 별명을 수정할 수 없어요.'],
  ['GroupSettingEditSubNicknamePage', '제재된 모임에선 별명을 수정할 수 없어요.'],
  ['CommentEditPage', '제재된 모임에선 댓글을 수정할 수 없어요.'],
]);

export const blockActionMessageMap: Map<BlockableActionNames, string> = new Map([
  ['DirectChat', '제재된 모임에선 채팅을 할 수 없어요.'],
  ['JoinChallenge', '제재된 모임에선 챌린지에 참여할 수 없어요.'],
  ['JoinMeetup', '제재된 모임에선 일정에 참여할 수 없어요.'],
  ['ApproveApplicationMember', '제재된 모임에선 멤버 가입 승인을 할 수 없어요.'],
  ['RejectApplicationMember', '제재된 모임에선 멤버 가입 거절을 할 수 없어요.'],
  ['CreateComment', '제재된 모임에선 댓글을 작성할 수 없어요.'],
  ['EditComment', '제재된 모임에선 댓글을 수정할 수 없어요.'],
  ['JoinChatRoom', '제재된 모임에선 채팅방에 입장할 수 없어요.'],
]);
