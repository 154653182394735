import { activityPages } from '@/stackflow/activity/pages';

type ActivityName = keyof typeof activityPages;

type ActivityParams = {
  screenName?: string;
};

export const parseScreenName = (activityName: ActivityName, activityParams: ActivityParams) => {
  if (activityParams.screenName) return activityParams.screenName;
  if (!activityName) return 'unknownActivity';

  // 예외 케이스를 위한 특별한 매핑
  const specialCaseMapping: Record<string, string> = {
    // 특수한 케이스들 (패턴을 따르지 않는 것들)
    GroupMeetupNewPage: 'groupMeetupCreate',
    GroupProfileDetailV2Page: 'groupUserProfileDetail',
    CommentListPage: 'groupMeetupDetailCommentList',
    GroupPollFormPage: 'groupPollCreate',
    BoardCategorySetting: 'groupSettingBoardCategorySetting',
    TalkRoomNewInformationPage: 'groupChattingGeneralNewInformation',
    TalkRoomNewApprovalPage: 'groupChattingGeneralNewApproval',
    TalkRoomInviteMemberPage: 'groupChattingGeneralInviteMember',
    TalkRoomInviteQRPage: 'groupChattingGeneralInviteQR',
    ChallengeEditPage: 'groupChallengeDetailEdit',
  };

  // 특수 케이스 먼저 체크
  if (specialCaseMapping[activityName]) {
    return specialCaseMapping[activityName];
  }

  // 일반적인 규칙 적용: 'Page' 접미사를 제거하고 첫 글자를 소문자로
  if (activityName.endsWith('Page')) {
    const screenName = activityName.slice(0, -4); // 'Page' 제거
    return screenName.charAt(0).toLowerCase() + screenName.slice(1);
  }

  const screenName = activityName.charAt(0).toLowerCase() + activityName.slice(1);
  return screenName || 'unknownActivity';
};
