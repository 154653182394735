import { GroupPermissionsResponsePermissions } from '@community-group/api/lib/group/models/group-permissions-response-permissions';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  AsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { $Keys } from 'utility-types';

import { useQueryGroupMemberGrades } from '@/domain/GroupMember/hooks/useReadGroupMemberGrades';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { usePatchGroupPermissions } from '@/domain/GroupPermission/hooks/usePatchGroupPermissions';
import { useQueryGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { ActivityQueryParams, useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { filterMemberGradesByPriority, getPermissionGradeName } from '@/utils/permission';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';
import { useSnackbar } from '@/_app/providers/UIOverlayProvider';

export type GroupSettingEditPermissionBottomSheetParams = Pick<
  PageParams & ActivityQueryParams,
  'groupId' | 'targetPermission'
>;

const GroupSettingEditPermissionBottomSheet: ActivityComponentType<
  GroupSettingEditPermissionBottomSheetParams
> = () => {
  return (
    <BottomSheet
      style={{
        padding: '0',
      }}
      safeAreaInsetBottomRemove
    >
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <GroupSettingEditPermissionBottomSheetWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const GroupSettingEditPermissionBottomSheetWrapper = () => {
  const { groupId } = usePathParams();
  const { pop } = useFlow();
  const { targetPermission } = useQueryParams();

  const [
    { data: memberGrades },
    { data: permissions, refetch: refetchPermissions },
    { data: memberGradeStatus },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupMemberGrades(groupId),
      useQueryGroupPermissions(groupId),
      useQueryGroupMemberGradeStatus(groupId),
    ],
  });
  const memberGradeEnabled = useMemo(() => memberGradeStatus === 'system', [memberGradeStatus]);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { open: openSnackbar } = useSnackbar();
  const { mutate: patchGroupPermissions } = usePatchGroupPermissions({
    onSuccess: () => {
      refetchPermissions();
      refetchGroupDetail({ groupId });
      refetchGroupSetting({ groupId });

      openSnackbar({
        message: '권한이 변경되었어요.',
      });
      pop();
    },
    onError: handleErrorWithToast,
  });

  const handleUpdatePermission = (groupMemberGradeId: string) => {
    const permission = targetPermission as $Keys<GroupPermissionsResponsePermissions>;

    patchGroupPermissions(
      {
        groupId,
        request: {
          [permission]: groupMemberGradeId,
        },
      },
      {
        onSuccess: () => {
          const grade = memberGrades.find((grade) => grade.id === groupMemberGradeId);
          if (!grade) return;

          trackEvent({
            event: 'click_edit_permission',
            params: {
              groupId,
              role: grade.role,
              name: grade.name,
              permission,
            },
          });
        },
      }
    );
  };

  const renderRoleList = () => {
    if (!permissions) return null;

    const target = targetPermission as $Keys<GroupPermissionsResponsePermissions>;

    const ableGrades = filterMemberGradesByPriority(
      memberGrades,
      permissions[target].ableMinPriority
    );

    const memberGradeEnabledRoles = memberGradeEnabled
      ? ableGrades
      : ableGrades.filter((grade) => grade.name !== '정회원');

    const filteredRoles =
      target !== 'openDirectChat'
        ? memberGradeEnabledRoles
        : memberGradeEnabledRoles.filter((grade) => grade.name !== '정회원');

    return filteredRoles.map((grade) => {
      const displayGradeName = getPermissionGradeName(ableGrades, grade.priority);

      return (
        <ActionListItem key={grade.id} onClick={() => handleUpdatePermission(grade.id)}>
          {displayGradeName}
        </ActionListItem>
      );
    });
  };

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>{renderRoleList()}</ActionList>
    </ActionSheet>
  );
};

export default GroupSettingEditPermissionBottomSheet;
