import { $Values } from 'utility-types';

import { ORDER_MEETUP } from '@/api/base/group';

export const GROUP_MEETUP_QUERY_KEY = {
  base: () => ['groupMeetup'] as const,
  groupMeetupsByRegion: (regionId: number, meetupStatus: string[]) =>
    [...GROUP_MEETUP_QUERY_KEY.base(), regionId, meetupStatus] as const,
  allMeetups: (regionId: number, meetupStatus: string[], order: string, categoryId?: number) =>
    [
      ...GROUP_MEETUP_QUERY_KEY.base(),
      'allMeetups',
      regionId,
      meetupStatus,
      categoryId,
      order,
    ] as const,
  meetupsWithDayFilter: ({
    regionId,
    statuses,
    order,
    targetDate,
    limit,
    showRenderLimit,
    categoryId,
    eventType,
  }: {
    regionId?: number;
    statuses?: string[];
    order?: $Values<typeof ORDER_MEETUP>;
    targetDate?: string;
    limit?: number;
    showRenderLimit?: boolean;
    categoryId?: number;
    eventType?: string;
  }) =>
    [
      ...GROUP_MEETUP_QUERY_KEY.base(),
      'meetupsWithDayFilter',
      regionId,
      statuses,
      categoryId,
      order,
      targetDate,
      limit,
      showRenderLimit,
      eventType,
    ] as const,
  checkMeetupOpenRegion: (regionId: number) =>
    [...GROUP_MEETUP_QUERY_KEY.base(), 'checkMeetupOpenRegion', regionId] as const,
};
