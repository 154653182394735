import {
  Dialog,
  useDialog,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';
import { Suspense, useEffect } from 'react';

import KarrotPaymentsCreateAccountAccessoryBar from '@/features/KarrotPaymentsCreateAccount/AccessoryBar';
import JoinKarrotPaymentsGuide from '@/features/KarrotPaymentsCreateAccount/JoinKarrotPaymentsGuide';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { ActivityQueryParams, useQueryParams } from '@/stackflow/hooks/useQueryParams';

type Params = Pick<
  ActivityQueryParams,
  'enterEventName' | 'from' | 'promotionName' | 'onSuccessReplaceParams'
> & {
  onSuccessReplacePage: NonNullable<ActivityQueryParams['onSuccessReplacePage']>;
  karrotPaymentsCreateAccountQueryParamsWhere: NonNullable<
    ActivityQueryParams['karrotPaymentsCreateAccountQueryParamsWhere']
  >;
};

/**
 * 당근페이 가입 플로우 진입 페이지
 *
 * 프로모션을 통해 당근페이 가입을 유도하는 페이지입니다.
 * 가입 성공 시 onSuccessReplacePage로 지정된 페이지로 이동합니다.
 *
 * @param {string} onSuccessReplacePage (필수) 가입 성공 시 이동할 페이지
 * @param {string} karrotPaymentsCreateAccountQueryParamsWhere (필수) 당근페이 가입 시 전달할 referrer 파라미터, 당근페이 측에 노티 후 프로모션시마다 다르게 전달 필요
 * @param {string} onSuccessReplaceParams 가입 성공 시 이동할 페이지에 전달할 파라미터
 * @param {string} promotionName 프로모션 이름
 * @param {string} enterEventName 페이지 진입 시 트래킹할 이벤트 이름
 * @param {string} from 진입 경로
 */
const KarrotPaymentsCreateAccountPage: ActivityComponentType<Params> = () => {
  const {
    enterEventName = 'enter_karrotPaymentsCreateAccountPage',
    from,
    promotionName,
    karrotPaymentsCreateAccountQueryParamsWhere = 'group_promotion_create_account',
    onSuccessReplacePage,
    onSuccessReplaceParams = '{}',
  } = useQueryParams();
  const back = useBack();

  useEffect(() => {
    if (!onSuccessReplacePage) {
      throw new Error('onSuccessReplacePage is required');
    }
  }, [onSuccessReplacePage]);

  const { open: openDialog, close: closeDialog } = useDialog();

  useEnterTrackEvent({
    event: enterEventName,
    params: {
      from,
      promotionName,
    },
  });

  const handleCloseButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    openDialog({
      element: (
        <Dialog
          title="정말 나갈까요?"
          description="이대로 나가면 당근머니를 받을 수 없을수도 있어요."
          primaryActionLabel="나가기"
          secondaryActionLabel="취소"
          onSecondaryAction={closeDialog}
          onPrimaryAction={async () => {
            await closeDialog();
            back();
          }}
        />
      ),
    });
  };

  return (
    <AppScreen
      preventSwipeBack
      appBar={{
        closeButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
          onClick: handleCloseButtonClick,
        },
        backButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
          onClick: handleCloseButtonClick,
        },
      }}
      accessoryBar={
        <KarrotPaymentsCreateAccountAccessoryBar
          karrotPaymentsCreateAccountQueryParams={{
            where: karrotPaymentsCreateAccountQueryParamsWhere ?? 'group_promotion_create_account',
          }}
          replaceParams={{
            activity: onSuccessReplacePage ?? 'KarrotPaymentsCreateAccountSuccessPage',
            params: onSuccessReplaceParams ? JSON.parse(onSuccessReplaceParams) : {},
          }}
        />
      }
    >
      <Suspense fallback={<ViewLoader />}>
        <JoinKarrotPaymentsGuide />
      </Suspense>
    </AppScreen>
  );
};

export default withAsyncBoundary(KarrotPaymentsCreateAccountPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
