import { Typography } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';

import { useBack } from '@/stackflow/hooks/useBack';

import * as s from './style.css';

type Props = {
  isTransparent?: boolean;
};

const TransparentNavBar = ({ isTransparent = false }: Props) => {
  const handleBack = useBack();

  return (
    <div className={s.Container}>
      <div className={s.RealNavContainer({ transparent: isTransparent })}>
        <IconXmarkLine size={24} onClick={handleBack} />

        <div className={s.EmptyBox} />
      </div>
      <div className={s.FakeNavContainer}>
        <IconXmarkLine size={24} onClick={handleBack} />
      </div>
    </div>
  );
};

export default TransparentNavBar;
