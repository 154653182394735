import { BottomSheetProvider, DialogProvider, SnackbarProvider } from '@community-group/components';

import Initialize from '../components/group/Initialize';
import { BridgeProvider } from '../contexts/Bridge';
import { DeviceConfig, DeviceConfigProvider } from '../contexts/DeviceConfig';
import { UserConfig, UserConfigProvider } from '../contexts/UserConfig';
import { FixedLayoutProvider } from '../providers/FixedLayout';
import { VirtualKeyboardProvider } from '../providers/VirtualKeyboard';
import { Stack } from '../stackflow';
import { UIOverlayProvider } from './providers/UIOverlayProvider';
type AppProps = {
  url: string;
  theme: 'android' | 'cupertino';
  safeAreaTop?: string;
  userConfig: UserConfig;
  deviceConfig: DeviceConfig;
};

const App = ({ url, safeAreaTop, theme, userConfig, deviceConfig }: AppProps) => {
  if (/token-setting/.test(url)) {
    return (
      <UserConfigProvider {...userConfig}>
        <BridgeProvider>
          <Stack
            initialContext={{
              req: { path: url },
              theme,
              minSafeAreaInsetTop: safeAreaTop,
            }}
          />
        </BridgeProvider>
      </UserConfigProvider>
    );
  }

  return (
    <UserConfigProvider {...userConfig}>
      <DeviceConfigProvider {...deviceConfig}>
        <BridgeProvider>
          <VirtualKeyboardProvider>
            <FixedLayoutProvider>
              <UIOverlayProvider>
                <Initialize />
                <Stack
                  initialContext={{
                    req: { path: url },
                    theme,
                    minSafeAreaInsetTop: safeAreaTop,
                  }}
                />
              </UIOverlayProvider>
            </FixedLayoutProvider>
          </VirtualKeyboardProvider>
        </BridgeProvider>
      </DeviceConfigProvider>
    </UserConfigProvider>
  );
};

export default App;
